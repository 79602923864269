import { PropsWithChildren, ReactNode } from 'react';
import { styled } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import MuiCard from '@mui/material/Card';
import MuiCardHeader from '@mui/material/CardHeader';
import {
    Category,
    getCategoryHex,
    getCategoryIcon,
} from '../../helpers/category';
import { CardCategory, CardType } from '../../API';

export interface CardBaseProps {
    id: string;
    category: Category;
    Icon: React.ElementType;
    headerRight?: ReactNode;
    hideBoxShadow?: boolean;
    expanded?: boolean;
    hideTitle?: boolean;
    name: string;
}

const innerPadding = {
    pl: 5,
    pr: 5,
};

const CardWithAnimation = (props: PropsWithChildren<CardBaseProps>) => {
    return (
        <AnimatePresence>
            <motion.div layout layoutId={props.id} key={props.id}>
                <StyledCardBase {...props} />
            </motion.div>
        </AnimatePresence>
    );
};

const StyledMuiCard = styled(MuiCard)<{ bg: string }>(({ bg }) => {
    return {
        height: '100%',
        borderRadius: '16px',
        backgroundColor: bg,
        color: '#fff',
        boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
    };
});

const StyledDefaultMuiCardHeader = styled(MuiCardHeader)(({ theme }) => {
    return {
        backgroundColor: 'rgba(0,0,0,0.4)',
        textTransform: 'uppercase',
        paddingTop: '',
        paddingBottom: '',
        svg: {
            width: '24px',
            height: '24px',
        },
        '.MuiCardHeader-avatar': {
            marginRight: theme.spacing(1),
        },
    };
});

const StyledExpandedMuiCardHeader = styled(StyledDefaultMuiCardHeader)(() => {
    return {
        paddingTop: '1rem',
        paddingBottom: '1rem',
    };
});

export const CardBase = (props: PropsWithChildren<CardBaseProps>) => {
    const {
        name,
        expanded,
        children,
        headerRight,
        Icon,
        category,
        hideBoxShadow,
        hideTitle,
    } = props;

    const cardBackground = getCategoryHex(category);

    const StyledCardHeader = expanded
        ? StyledExpandedMuiCardHeader
        : StyledDefaultMuiCardHeader;

    return (
        <StyledMuiCard
            bg={cardBackground}
            sx={{ boxShadow: hideBoxShadow ? 'none' : 'inherit' }}
        >
            {!hideTitle && (
                <StyledCardHeader
                    avatar={<Icon />}
                    title={<strong>{name}</strong>}
                    titleTypographyProps={{
                        fontSize: expanded ? '1.5rem' : '',
                    }}
                    action={!expanded ? headerRight : <></>}
                    sx={{ paddingLeft: '35px' }}
                />
            )}

            {children}
        </StyledMuiCard>
    );
};

const StyledCardBase = styled(CardBase)<CardBaseProps>((props) => {
    return {
        borderRadius: '16px',
        backgroundColor: getCategoryHex(props.category),
        color: '#fff',
        boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
    };
});

export default CardWithAnimation;
