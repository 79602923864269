import React, { useEffect, useState } from 'react';
import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactComponent as ListIcon } from '../../../assets/images/process-thumbnails/Playboolk-list-page.svg';
import { getWorksheetThumbnail } from '../../../helpers/worksheets';
import { Worksheet } from '../../../API';
import {
    ExtendedWorksheet,
    ExtendedWorksheetSetting,
    SelectedWorksheet,
} from '../CreateCard/types';

interface WorksheetsProps {
    selectedWorksheets: SelectedWorksheet[] | null;
    setSelectedWorksheets: React.Dispatch<
        React.SetStateAction<SelectedWorksheet[] | null>
    >;
    selectedWorksheetItems: ExtendedWorksheet[];
}
export interface CardTypeIdsMap {
    [key: string]: string[];
}
export const WorksheetAvatar = ({
    worksheet,
}: {
    worksheet: ExtendedWorksheetSetting;
}) => {
    const WorksheetIcon =
        worksheet.t === 'List' ? ListIcon : getWorksheetThumbnail(worksheet.n);
    const greyOutStyle = { filter: 'grayscale(100%)' };

    if (!WorksheetIcon) {
        console.error(`Icon not found for worksheet: ${worksheet}`);
        return null;
    }

    return (
        <Box sx={{ ...(worksheet.e === false && greyOutStyle) }}>
            {worksheet.t === 'List' ? (
                <WorksheetIcon />
            ) : (
                <Box
                    component="img"
                    src={WorksheetIcon as string}
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                ></Box>
            )}
        </Box>
    );
};

const PageTypesColumn = ({
    selectedWorksheets,
    setSelectedWorksheets,
    selectedWorksheetItems,
}: WorksheetsProps) => {
    const theme = useTheme();
    const [mainPageTypes, setMainPageTypes] =
        useState<(ExtendedWorksheetSetting | undefined)[]>();
    const [suggestedWorksheets, setSuggestedWorksheets] =
        useState<(ExtendedWorksheetSetting | undefined)[]>();
    useEffect(() => {
        const mainPageTypesFromSelectedItems = Array.from(
            new Map(
                selectedWorksheetItems
                    ?.map((item) => item.chartTypeWorksheet)
                    .filter(Boolean)
                    .map((chartType) => [chartType?.i, chartType])
            ).values()
        );
        setMainPageTypes(mainPageTypesFromSelectedItems);
        const suggestedWorksheetsFromSelectedItems = Array.from(
            new Map(
                selectedWorksheetItems
                    ?.flatMap((item) => item.suggestedWorksheets)
                    .filter(Boolean)
                    .map((worksheet) => [worksheet?.i, worksheet])
            ).values()
        );
        setSuggestedWorksheets(suggestedWorksheetsFromSelectedItems);
    }, [selectedWorksheetItems]);

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderLeft: 'solid 1px #ccc',
                borderRight: 'solid 1px #ccc',
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    Playbook page types
                </Typography>
            </Box>
            <Box sx={{ padding: 1, overflowY: 'auto', height: '100%' }}>
                <Grid container spacing={1}>
                    {mainPageTypes?.map((mainPageType) => (
                        <Grid item md={4}>
                            <Tooltip
                                placement="top"
                                title={mainPageType?.d}
                                PopperProps={{
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -50],
                                            },
                                        },
                                    ],
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        cursor: mainPageType?.e
                                            ? 'pointer'
                                            : 'default',
                                        border: selectedWorksheets?.some(
                                            (w) => w.n === mainPageType?.n
                                        )
                                            ? `3px solid ${theme.palette.primary.main}`
                                            : `1px solid ${theme.palette.divider}`,
                                        borderRadius: '10px',
                                        overflow: 'hidden',
                                        px: 1,
                                        textAlign: 'center',
                                        color: !mainPageType?.e
                                            ? theme.palette.text.disabled
                                            : 'inherit',
                                        height: '180px',
                                    }}
                                    onClick={() => {
                                        if (mainPageType?.e) {
                                            setSelectedWorksheets((prev) =>
                                                prev
                                                    ? prev.some(
                                                          (w) =>
                                                              w.n ===
                                                              mainPageType.n
                                                      )
                                                        ? prev.filter(
                                                              (w) =>
                                                                  w.n !==
                                                                  mainPageType.n
                                                          )
                                                        : [
                                                              ...prev,
                                                              {
                                                                  ...mainPageType,
                                                                  title: `${mainPageType.parentWorksheetName}-${mainPageType.n}`,
                                                              },
                                                          ]
                                                    : [
                                                          {
                                                              ...mainPageType,
                                                              title: `${mainPageType.parentWorksheetName}-${mainPageType.n}`,
                                                          },
                                                      ]
                                            );
                                        }
                                    }}
                                >
                                    {mainPageType && (
                                        <WorksheetAvatar
                                            worksheet={mainPageType}
                                        />
                                    )}
                                    <Typography
                                        variant="body1"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        {mainPageType?.n}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        </Grid>
                    ))}
                    {suggestedWorksheets && suggestedWorksheets?.length > 0 && (
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Typography variant="h6">
                                Other available playbook page types
                            </Typography>
                        </Grid>
                    )}

                    {suggestedWorksheets?.map((worksheet) => {
                        return (
                            <Grid item md={4} key={worksheet?.i}>
                                {worksheet && (
                                    <Tooltip
                                        placement="top"
                                        title={worksheet?.d}
                                        PopperProps={{
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -50],
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                cursor: worksheet.e
                                                    ? 'pointer'
                                                    : 'default',
                                                border: selectedWorksheets?.some(
                                                    (w) => w.n === worksheet.n
                                                )
                                                    ? `3px solid ${theme.palette.primary.main}`
                                                    : `1px solid ${theme.palette.divider}`,
                                                borderRadius: '10px',
                                                overflow: 'hidden',
                                                px: 1,
                                                height: '180px',
                                                textAlign: 'center',
                                                color: !worksheet.e
                                                    ? theme.palette.text
                                                          .disabled
                                                    : 'inherit',
                                            }}
                                            onClick={() => {
                                                if (worksheet.e) {
                                                    setSelectedWorksheets(
                                                        (prev) =>
                                                            prev
                                                                ? prev.some(
                                                                      (w) =>
                                                                          w.n ===
                                                                          worksheet.n
                                                                  )
                                                                    ? prev.filter(
                                                                          (w) =>
                                                                              w.n !==
                                                                              worksheet.n
                                                                      )
                                                                    : [
                                                                          ...prev,
                                                                          {
                                                                              ...worksheet,
                                                                              title: `${worksheet.parentWorksheetName}-${worksheet.n}`,
                                                                          },
                                                                      ]
                                                                : [
                                                                      {
                                                                          ...worksheet,
                                                                          title: `${worksheet.parentWorksheetName}-${worksheet.n}`,
                                                                      },
                                                                  ]
                                                    );
                                                }
                                            }}
                                        >
                                            <WorksheetAvatar
                                                worksheet={worksheet}
                                            />
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    my: 0.5,
                                                }}
                                            >
                                                {worksheet.n}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Box>
    );
};

export default PageTypesColumn;
