import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
    FilterOptions,
    PageIdentifier,
    WorksheetFilterOptions,
} from '../cardTypes';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
} from '@mui/material';

interface PrimaryFilterColumnProps {
    selectedPrimaryFilter: WorksheetFilterOptions | null;
    setSelectedPrimaryFilter: React.Dispatch<
        React.SetStateAction<WorksheetFilterOptions | null>
    >;
    pageIdentifier: string;
}

const PrimaryFilterColumn = ({
    selectedPrimaryFilter,
    setSelectedPrimaryFilter,
    pageIdentifier,
}: PrimaryFilterColumnProps) => {
    const theme = useTheme();

    const primaryFilters = Object.values(WorksheetFilterOptions);

    return (
        <Box sx={{ borderRight: '1px solid #f0f2f5', height: '100%' }}>
            <Typography variant="body1" sx={{ ml: 2, fontWeight: 'bold' }}>
                Filter by
            </Typography>
            <FormControl>
                <RadioGroup
                    aria-labelledby="worksheet-filter-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedPrimaryFilter}
                    onChange={(event) =>
                        setSelectedPrimaryFilter(
                            event.target.value as WorksheetFilterOptions
                        )
                    }
                >
                    {primaryFilters.map((key, value) => {
                        return (
                            <Box
                                key={value}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 1.8,
                                    cursor: 'pointer',
                                }}
                            >
                                <FormControlLabel
                                    value={key}
                                    control={<Radio />}
                                    label={key}
                                />
                            </Box>
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </Box>
    );
};

export default PrimaryFilterColumn;
