import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Menu,
    MenuItem,
    IconButton,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { truncateText } from '../../../helpers/utils';
import { WorksheetAvatar } from '../../workbooks/WorksheetItem';
import { worksheets } from '../../forms/worksheets';
import { ViewType } from '../cardTypes';
import { worksheetsByParentId } from '../../../graphql/queries';
import {
    ModelSortDirection,
    Worksheet,
    WorksheetsByParentIdQuery,
} from '../../../API';
import { generateClient } from 'aws-amplify/api';

interface WorksheetsCellProps {
    workbookId: string;
    cardId: string;
}

const WorksheetsCell: React.FC<WorksheetsCellProps> = ({
    workbookId,
    cardId,
}) => {
    const [workbookWorksheets, setWorkbookWorksheets] = useState<Worksheet[]>(
        []
    );
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const client = generateClient();

    const getWorksheetsForWorkBook = async (
        workbookId: string
    ): Promise<Worksheet[]> => {
        if (!workbookId) return [];
        try {
            const response = (await client.graphql({
                query: worksheetsByParentId,
                variables: {
                    parentId: workbookId,
                    sortDirection: ModelSortDirection.ASC,
                },
            })) as { data: WorksheetsByParentIdQuery };

            const fetchedWorksheets =
                response.data?.worksheetsByParentId?.items ?? [];
            const nonNullWorksheets: Worksheet[] = fetchedWorksheets.filter(
                (w): w is Worksheet => w !== null
            );
            return nonNullWorksheets.filter(
                (worksheet: Worksheet) =>
                    worksheet.toDataSources?.items?.some(
                        item => item?.targetCardId === cardId
                    ) ?? false
            );
        } catch (e) {
            console.error(e);
            return [];
        }
    };

    useEffect(() => {
        const fetchWorksheets = async () => {
            try {
                const items = await getWorksheetsForWorkBook(workbookId);
                setWorkbookWorksheets(items);
            } catch (error) {
                console.error('Error fetching worksheets:', error);
            }
        };

        fetchWorksheets();
    }, [workbookId, cardId]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                maxHeight: '100px',
                overflowY: 'auto',
                boxSizing: 'border-box',
                '&::-webkit-scrollbar': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '3px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                },
            }}
        >
            {(() => {
                if (!workbookWorksheets[0]) return null;
                const currentWorksheet = worksheets.find(
                    (worksheet) => worksheet.i === workbookWorksheets[0].typeId
                );
                return (
                    <Box
                        key={workbookWorksheets[0].id}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            mb: 1,
                        }}
                    >
                        <WorksheetAvatar
                            worksheet={currentWorksheet?.n as string}
                            selectedViewType={ViewType.LIST}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Tooltip title={workbookWorksheets[0].name}>
                                <Typography variant="body2" sx={{ ml: '2px' }}>
                                    {truncateText(
                                        workbookWorksheets[0].name || '',
                                        20
                                    )}
                                </Typography>
                            </Tooltip>
                            {workbookWorksheets.length > 1 && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        ml: '5px',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                        color: 'primary.main',
                                    }}
                                    onClick={handleMenuOpen}
                                >
                                    +{workbookWorksheets.length - 1} more
                                </Typography>
                            )}
                        </Box>
                    </Box>
                );
            })()}

            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
            >
                {workbookWorksheets.map((item: Worksheet) => {
                    const currentWorksheet = worksheets.find(
                        (worksheet) => worksheet.i === item.typeId
                    );

                    if (!currentWorksheet) return null;

                    return (
                        <MenuItem
                            key={item.id}
                            onClick={handleMenuClose}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            <WorksheetAvatar
                                worksheet={currentWorksheet?.n as string}
                                selectedViewType={ViewType.LIST}
                            />
                            <Tooltip title={item.name}>
                                <Typography variant="body2" sx={{ ml: '8px' }}>
                                    {truncateText(item.name || '', 20)}
                                </Typography>
                            </Tooltip>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
};

export default WorksheetsCell;
