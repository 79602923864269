import { styled } from '@mui/material';

interface StyledPlaybookPageProps {
    carousel?: boolean;
    edit?: boolean;
    backgroundColor?: string;
}

export const StyledPlaybookScrollContainer = styled('div')(({ theme }) => {
    return {
        height: '100%',
        padding: theme.spacing(2),
        overflowY: 'auto',
        overflowX: 'hidden',
        '::-webkit-scrollbar': {
            width: '0.375em',
            paddingRight: '1rem',
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.30)',
            borderRadius: '10px',
        },
    };
});

export const StyledPlaybookContainer = styled('div')(({ theme }) => {
    return {
        height: '100%',
        padding: theme.spacing(4),
        '::-webkit-scrollbar': {
            width: '0.375em',
            paddingRight: '1rem',
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.30)',
            borderRadius: '10px',
        },
    };
});

export const StyledPlaybookPage = styled('div')<StyledPlaybookPageProps>(
    ({ backgroundColor, edit, carousel, theme }) => {
        const bgColor = backgroundColor || '#fff';

        return carousel
            ? {}
            : {
                  backgroundRepeat: 'repeat-y',
                  borderRadius: '16px',
                  backgroundColor: bgColor,
                  boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
                  height: edit ? 'auto' : '28rem',
                  minHeight: edit ? '44.4rem' : '28rem',
                  display: 'flex',
                  padding: '0px 0 0 16px',
                  flexDirection: 'column',
                  '.MuiDivider-root': {
                      borderColor: theme.palette.getContrastText(bgColor),
                      marginTop: theme.spacing(1),
                      marginBottom: theme.spacing(1),
                      opacity: 0.2,
                  },
                  overflow: 'hidden',
                  color: '#000',
              };
    }
);
