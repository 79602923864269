import { useContext } from 'react';
import { AppContext } from '../../contexts';
import { Card, ScoreTarget, ScoreType } from '../../../API';
import { Box } from '@mui/material';
import PlaybookLineChart from '../../charts/PlaybookLineChart';

interface PlaybookScore {
    card: Card;
    frontCard?: boolean;
    pptView?: boolean | undefined;
}

export const PlaybookScore = ({ card, frontCard, pptView }: PlaybookScore) => {
    const { cardTypes } = useContext(AppContext);
    const scoreItems = card?.toScoreData?.items ?? [];
    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100%' }}>
                {scoreItems.map((scoreData, i) => {
                    const scoreDefinition = cardTypes
                        .find(
                            (cardType) => cardType.id == card.cardToCardTypeId
                        )
                        ?.scoreDefinitions.find(
                            (e) => e.id == scoreData?.scoreDefinitionId
                        );
                    if (scoreData && scoreDefinition) {
                        return (
                            <PlaybookLineChart
                                config={{
                                    size: 'small',
                                }}
                                key={i}
                                scoreData={scoreData}
                                scoreDefinition={{
                                    ...scoreDefinition,
                                    __typename: 'ScoreDefinition',
                                    scoreType:
                                        scoreDefinition.scoreType as ScoreType,
                                    target: scoreDefinition.target as ScoreTarget,
                                }}
                                isLastItem={i === scoreItems.length - 1}
                                frontCard={frontCard}
                                pptView={pptView}
                            />
                        );
                    }
                })}
            </Box>
        </Box>
    );
};

export default PlaybookScore;
