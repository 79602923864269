/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateNotification = /* GraphQL */ `subscription OnCreateNotification(
  $filter: ModelSubscriptionNotificationFilterInput
) {
  onCreateNotification(filter: $filter) {
    organisation
    createdAt
    id
    type
    entity
    userId
    deleteAfter
    cardId
    toCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSetId
    cardSetType
    toCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    cardsCardSetsId
    toCardsCardSets {
      organisation
      id
      cardId
      cardSetId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    scoreDefinitionId
    toScoreData {
      cardId
      id
      scoreDefinitionId
      data {
        value
        date
        comment
        commentDate
        commentAuthor
        __typename
      }
      organisation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookPageId
    commentContextId
    commentCreatedAt
    commentId
    toComment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    commentReactionCreatedBy
    toCommentReaction {
      commentId
      commentContextId
      commentContextType
      commentCreatedAt
      organisation
      comment {
        contextId
        id
        organisation
        contextType
        createdAt
        createdBy
        updatedBy
        message
        reactions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      createdAt
      createdBy
      updatedAt
      owner
      __typename
    }
    worksheetId
    toWorksheet {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    relationshipId
    toRelationship {
      organisation
      id
      type
      sourceId
      sourceType
      sourceCardId
      toSourceCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      sourceCardSetId
      toSourceCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      sourceWorksheetId
      toSourceWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      sourcePlaybookPageId
      toSourcePlaybookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      targetId
      targetType
      targetCardId
      toTargetCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      targetCardSetId
      toTargetCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    relationshipInfo
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNotificationSubscriptionVariables,
  APITypes.OnCreateNotificationSubscription
>;
export const onUpdateNotification = /* GraphQL */ `subscription OnUpdateNotification(
  $filter: ModelSubscriptionNotificationFilterInput
) {
  onUpdateNotification(filter: $filter) {
    organisation
    createdAt
    id
    type
    entity
    userId
    deleteAfter
    cardId
    toCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSetId
    cardSetType
    toCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    cardsCardSetsId
    toCardsCardSets {
      organisation
      id
      cardId
      cardSetId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    scoreDefinitionId
    toScoreData {
      cardId
      id
      scoreDefinitionId
      data {
        value
        date
        comment
        commentDate
        commentAuthor
        __typename
      }
      organisation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookPageId
    commentContextId
    commentCreatedAt
    commentId
    toComment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    commentReactionCreatedBy
    toCommentReaction {
      commentId
      commentContextId
      commentContextType
      commentCreatedAt
      organisation
      comment {
        contextId
        id
        organisation
        contextType
        createdAt
        createdBy
        updatedBy
        message
        reactions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      createdAt
      createdBy
      updatedAt
      owner
      __typename
    }
    worksheetId
    toWorksheet {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    relationshipId
    toRelationship {
      organisation
      id
      type
      sourceId
      sourceType
      sourceCardId
      toSourceCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      sourceCardSetId
      toSourceCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      sourceWorksheetId
      toSourceWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      sourcePlaybookPageId
      toSourcePlaybookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      targetId
      targetType
      targetCardId
      toTargetCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      targetCardSetId
      toTargetCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    relationshipInfo
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNotificationSubscriptionVariables,
  APITypes.OnUpdateNotificationSubscription
>;
export const onDeleteNotification = /* GraphQL */ `subscription OnDeleteNotification(
  $filter: ModelSubscriptionNotificationFilterInput
) {
  onDeleteNotification(filter: $filter) {
    organisation
    createdAt
    id
    type
    entity
    userId
    deleteAfter
    cardId
    toCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSetId
    cardSetType
    toCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    cardsCardSetsId
    toCardsCardSets {
      organisation
      id
      cardId
      cardSetId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    scoreDefinitionId
    toScoreData {
      cardId
      id
      scoreDefinitionId
      data {
        value
        date
        comment
        commentDate
        commentAuthor
        __typename
      }
      organisation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookPageId
    commentContextId
    commentCreatedAt
    commentId
    toComment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    commentReactionCreatedBy
    toCommentReaction {
      commentId
      commentContextId
      commentContextType
      commentCreatedAt
      organisation
      comment {
        contextId
        id
        organisation
        contextType
        createdAt
        createdBy
        updatedBy
        message
        reactions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      createdAt
      createdBy
      updatedAt
      owner
      __typename
    }
    worksheetId
    toWorksheet {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    relationshipId
    toRelationship {
      organisation
      id
      type
      sourceId
      sourceType
      sourceCardId
      toSourceCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      sourceCardSetId
      toSourceCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      sourceWorksheetId
      toSourceWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      sourcePlaybookPageId
      toSourcePlaybookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      targetId
      targetType
      targetCardId
      toTargetCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      targetCardSetId
      toTargetCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    relationshipInfo
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationSubscriptionVariables,
  APITypes.OnDeleteNotificationSubscription
>;
