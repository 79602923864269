import { useState, useEffect } from 'react';
import { AppContext } from '../components/contexts';
import {
    WorksheetFilterDateOptions,
    WorksheetFilterOptions,
} from '../components/cards/cardTypes';
import { SecondaryWorksheetFilterOptions } from '../components/cards/FilterWorksheetCards';
import { filter } from 'lodash';

export interface FilterCards {
    cardCategory?: string[];
    cardType?: string[];
    cardSet?: string[];
    workbook?: string[];
    owner?: string[];
}

const defaultFilterStates: FilterCards = {
    cardCategory: [],
    cardType: [],
    cardSet: [],
    workbook: [],
    owner: [],
};

export interface WorksheetFilterCards {
    primary: WorksheetFilterOptions | null;
    secondary: SecondaryWorksheetFilterOptions;
}

const defaultWorksheetFilterStates: WorksheetFilterCards = {
    primary: null,
    secondary: {
        createdDate: {
            value: null,
        },
        owner: [],
    },
};

export const useWorksheetFilterCards = (
    localStorageKey: string
): [
    WorksheetFilterCards,
    React.Dispatch<React.SetStateAction<WorksheetFilterCards>>
] => {
    const [filterCriteria, setFilterCriteria] = useState<WorksheetFilterCards>(
        () => {
            const storedValue = window.localStorage.getItem(localStorageKey);

            return storedValue !== null
                ? JSON.parse(storedValue)
                : defaultWorksheetFilterStates;
        }
    );

    useEffect(() => {
        window.localStorage.setItem(
            localStorageKey,
            JSON.stringify(filterCriteria)
        );
    }, [localStorageKey, filterCriteria]);

    return [filterCriteria, setFilterCriteria];
};

const useFilterCards = (
    localStorageKey: string
): [FilterCards, React.Dispatch<React.SetStateAction<FilterCards>>] => {
    const [filterCriteria, setFilterCriteria] = useState<FilterCards>(() => {
        const storedValue = window.localStorage.getItem(localStorageKey);
        return storedValue !== null
            ? JSON.parse(storedValue)
            : defaultFilterStates;
    });

    useEffect(() => {
        window.localStorage.setItem(
            localStorageKey,
            JSON.stringify(filterCriteria)
        );
    }, [localStorageKey, filterCriteria]);

    return [filterCriteria, setFilterCriteria];
};

export default useFilterCards;
