import { useContext, useEffect, useMemo, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import {
    dateStringFromISO,
    getFutureDate,
    truncateText,
} from '../../../helpers/utils';
import { StyledCardIcon } from '../../StyledIcons';
import TableLayout from '../../layouts/TableLayout';
import {
    CardComponentData,
    CardComponentProps,
    CardComponentType,
    CardPage,
    CardSetComponentData,
    PageIdentifier,
} from '../cardTypes';
import { CardContext } from '../context';
import { Box, SxProps } from '@mui/system';
import { ReactComponent as MenuIcon } from '../../../assets/icons/Menu.svg';
import { ReactComponent as PlaybooksIcon } from '../../../assets/icons/small-playbook.svg';
import { ReactComponent as WorkbooksIcon } from '../../../assets/icons/Small-workbook.svg';
import { StyledModal } from '../../Modal';
import CardMenu, { MenuItem } from '../CardMenu';
import ModalContainer from '../../ModalContainer';
import { UserPermissions } from '../../../globals';
import { getUserPermissions } from '../../../helpers/permissions';
import { GraphQLQuery, generateClient } from 'aws-amplify/api';
import { getUserOrganisation } from '../../../helpers/auth';
import CreateCardSet from '../../forms/CreateCardSet/index';
import { useNavigate, useLocation } from 'react-router-dom';
import UserAvatar from '../../profile/UserAvatar';
import {
    Button,
    CircularProgress,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { Category } from '../../../helpers/category';
import { DrawStates } from './CardTypeLayout';
import { Card } from '../../../API';
import { CardToDelete, cardToCardComponentProps } from '../../../pages/cards';
import { AppContext } from '../../contexts';
import { cardSetToCardComponentProps } from '../../../pages/card-sets';
import { ReactComponent as CardSetIcon } from '../../../assets/icons/Small-card-set.svg';

interface CardListViewProps {
    showDrawer?: DrawStates;
    handleListViewEdit?: (cardId: string, cardPage?: CardPage) => void;
    setCardItems?: React.Dispatch<React.SetStateAction<Card[]>>;
    multiSelectedCards?: CardToDelete[] | [];
    setMultiSelectedCards?: React.Dispatch<
        React.SetStateAction<CardToDelete[] | []>
    >;
    cardsObject?: {
        [key: string]: Card;
    };
    pageIdentifier?: PageIdentifier;
    cardItems?: CardComponentData[];
    mediumCard?: boolean;
    handleSelect?: (item: CardComponentData | CardSetComponentData) => void;
    clickedItems?: CardSetComponentData[];
    tableStyle?: SxProps<Theme>;
    worksheetRowClick?: (id: string) => void;
    worksheetMenuItems?: MenuItem[];
    setMenuCard?: React.Dispatch<React.SetStateAction<Card | null>>;
    onHover?: (id: string | null) => void;
    relationshipForm?: boolean;
}
export const renderIconByComponentType = (
    componentType: string,
    cardCategoryId: Category,
    colorFn?: (id: string) => string
) => {
    switch (componentType) {
        case CardComponentType.WORKBOOK:
            return (
                <WorkbooksIcon className="name-icon" width={24} height={24} />
            );
        case CardComponentType.CARD:
            return (
                <StyledCardIcon
                    category={cardCategoryId}
                    className="name-icon"
                />
            );
        case CardComponentType.CARD_SET:
            return (
                <CardSetIcon
                    className="name-icon"
                    width={26}
                    height={26}
                    color={
                        cardCategoryId && colorFn
                            ? colorFn(cardCategoryId)
                            : '#AD6A1F'
                    }
                />
            );
        case CardComponentType.PLAYBOOK:
            return (
                <PlaybooksIcon className="name-icon" width={24} height={24} />
            );
        case CardComponentType.WORKSHEET:
            return (
                <StyledCardIcon
                    className="name-icon"
                    category={cardCategoryId}
                    width={24}
                    height={24}
                />
            );
        default:
            return null;
    }
};
const CardListView = ({
    showDrawer,
    handleListViewEdit,
    setCardItems,
    multiSelectedCards,
    setMultiSelectedCards,
    cardsObject,
    pageIdentifier,
    cardItems,
    mediumCard,
    handleSelect,
    clickedItems,
    tableStyle,
    worksheetRowClick,
    worksheetMenuItems,
    setMenuCard,
    onHover,
    relationshipForm,
}: CardListViewProps) => {
    const { handleClick, handleDelete, handleCopy, handleRemove, items } =
        useContext(CardContext);
    const itemsToMap = cardItems || items;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showMenu, setShowMenu] = useState(false);
    const [permissions, setPermissions] = useState<UserPermissions[]>([]);
    const [cardComponentType, setCardComponentType] = useState('');
    const [organisation, setOrganisation] = useState('');
    const [cardSetModalOpen, setCardSetModalOpen] = useState(false);
    const [card, setCard] = useState<CardComponentData>();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [numberOfCards, setNumberOfCards] = useState(0);
    const [userId, setUserId] = useState<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const client = generateClient();

    const { user } = useContext(AppContext);
    useEffect(() => {
        if (user) {
            setUserId(user.userSub);
        }
    }, [user]);
    useEffect(() => {
        if (items) {
            setCardComponentType(items[0]?.cardComponentType || '');
        }
    }, [items, card]);

    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setAnchorEl(e.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const updatePermissions = async (data: CardComponentData) => {
        const userPermissions = getUserPermissions(
            user?.tokens?.idToken?.payload?.sub ?? '',
            data
        );
        const organisation = getUserOrganisation(user);
        setOrganisation(organisation);

        setPermissions(userPermissions);
    };
    const setMenuOpen = () => {
        setAnchorEl(null);
        setCardSetModalOpen(true);
    };
    const menuItems = useMemo(() => {
        const items = [];
        const isCardSelected = multiSelectedCards?.some(
            (c) => c.id === card?.id
        );
        if (!isCardSelected && card) {
            if (
                cardComponentType === CardComponentType.CARD &&
                (pageIdentifier === PageIdentifier.CARDS ||
                    pageIdentifier === PageIdentifier.WORKBOOK_INSIDE ||
                    pageIdentifier === PageIdentifier.CARD_SET_INSIDE)
            ) {
                items.push({
                    text: 'Create card set',
                    action: () => setMenuOpen(),
                });
            }
            if (
                handleCopy &&
                !isCardSelected &&
                multiSelectedCards &&
                multiSelectedCards.length > 0
            ) {
                items.push({
                    text: 'Copy',
                    action: () => handleCopy(card.id),
                });
            }
        }
        if (
            cardComponentType === CardComponentType.CARD &&
            (pageIdentifier === PageIdentifier.WORKBOOK_INSIDE ||
                pageIdentifier === PageIdentifier.CARD_SET_INSIDE)
        ) {
            items.push({
                text: `Remove from ${
                    location.pathname.indexOf('/workbooks') !== -1
                        ? 'workbook'
                        : 'card set'
                }`,
                action: () => handleRemove && handleRemove(card?.id ?? ''),
            });
        }

        return worksheetMenuItems ?? items;
    }, [
        cardComponentType,
        pageIdentifier,
        location.pathname,
        multiSelectedCards,
        card,
        handleRemove,
        handleListViewEdit,
        handleCopy,
    ]);

    const onCreateCardSet = (id?: string) => {
        setCardSetModalOpen(false);
        navigate(`/card-sets${id ? `/${id}` : ``}`);
    };

    const ownerColumn: GridColDef = {
        field: 'owner',
        headerName: 'Owner',
        sortable: false,
        width: 150,
        renderCell: (params) => {
            return (
                <UserAvatar
                    userId={params.row.owner}
                    isProfile={false}
                    fullNameOnly={true}
                />
            );
        },
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            sortable: false,
            width: showDrawer ? 220 : 250,

            renderCell: (params) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {renderIconByComponentType(
                            cardComponentType,
                            params.row.cardCategoryId
                        )}
                        {params.value && typeof params.value === 'string' ? (
                            <Tooltip title={params.value}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        ml: '2px',
                                        ':hover': {
                                            textDecoration:
                                                mediumCard ||
                                                worksheetRowClick ||
                                                relationshipForm
                                                    ? 'none'
                                                    : 'underline',
                                            cursor:
                                                mediumCard ||
                                                worksheetRowClick ||
                                                relationshipForm
                                                    ? 'default'
                                                    : 'pointer',
                                        },
                                    }}
                                    onClick={(e) => {
                                        if (!mediumCard && !relationshipForm) {
                                            e.stopPropagation();
                                            handleClick(
                                                params.row.id,
                                                CardPage.DETAILS
                                            );
                                        }
                                    }}
                                >
                                    {truncateText(params.value, 20)}
                                </Typography>
                            </Tooltip>
                        ) : (
                            ''
                        )}
                    </Box>
                );
            },
        },
    ];
    if (!mediumCard && !relationshipForm) {
        columns.unshift({
            field: 'control',
            headerName: '',
            width: 40,
            sortable: false,
            disableColumnMenu: true,

            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <MenuIcon
                            width={35}
                            height={35}
                            color="#808080"
                            onClick={(
                                event: React.MouseEvent<
                                    SVGSVGElement,
                                    MouseEvent
                                >
                            ) => {
                                event.stopPropagation();
                                setAnchorEl(
                                    event.currentTarget as unknown as HTMLElement
                                );
                                updatePermissions(params.row);
                                setCard(params.row);
                                setMenuCard && setMenuCard(params.row);
                                setShowMenu(true);
                            }}
                        />
                        {showMenu && anchorEl && card && (
                            <CardMenu
                                permissions={permissions}
                                anchor={anchorEl}
                                data={{
                                    organisation: card.organisation || '',
                                    id: card.id,
                                }}
                                setMenu={setMenu}
                                handleDelete={() =>
                                    handleDelete(card.id, organisation)
                                }
                                {...(handleCopy &&
                                    multiSelectedCards &&
                                    multiSelectedCards.length === 0 && {
                                        handleCopy: () => handleCopy(card.id),
                                    })}
                                {...(menuItems.length && {
                                    menuItems: menuItems,
                                })}
                                pageIdentifier={pageIdentifier}
                            />
                        )}
                        <StyledModal
                            key="modal"
                            open={cardSetModalOpen}
                            onClose={() => setCardSetModalOpen(false)}
                            sx={{ zIndex: 1401 }}
                        >
                            <Box>
                                {cardSetModalOpen && card && (
                                    <ModalContainer sx={{ maxWidth: '75rem' }}>
                                        <CreateCardSet
                                            cardSetCopy={{
                                                cardSetToCardCategoryId:
                                                    card.cardCategoryId,
                                                cardSetToCardTypeId:
                                                    card.cardTypeId,
                                            }}
                                            cardSet={undefined}
                                            handleClose={onCreateCardSet}
                                            cardId={card.id}
                                        />
                                    </ModalContainer>
                                )}
                            </Box>
                        </StyledModal>
                    </Box>
                );
            },
        });
        columns.push({
            field: 'updatedAt',
            headerName: 'Last modified date',
            sortable: false,
            width: 150,
            valueFormatter: (params) => dateStringFromISO(params),
        });
    }
    if (
        cardComponentType === CardComponentType.CARD ||
        cardComponentType === CardComponentType.WORKBOOK
    ) {
        !showDrawer && columns.splice(2, 0, ownerColumn);
    } else {
        !showDrawer && columns.splice(2, 0, ownerColumn);
    }
    if (!showDrawer) {
        columns.splice(5, 0, {
            field: 'description',
            headerName: 'Description',
            sortable: false,
            width: 450,
        });
    }
    if (
        cardComponentType === CardComponentType.PLAYBOOK ||
        cardComponentType === CardComponentType.WORKBOOK ||
        cardComponentType === CardComponentType.WORKSHEET
    ) {
        columns.splice(7, 0, {
            field: 'createdAt',
            headerName: 'Created on',
            sortable: false,
            width: 150,
            valueFormatter: (params) => dateStringFromISO(params),
        });
    }
    if (
        cardComponentType !== CardComponentType.PLAYBOOK &&
        cardComponentType !== CardComponentType.WORKSHEET
    ) {
        columns.splice(2, 0, {
            field: 'cardTypeName',
            headerName: 'Type',
            sortable: false,
            width: 150,
            valueGetter: (params) => {
                return params;
            },
        });
        columns.splice(3, 0, {
            field: 'cardCategoryName',
            headerName: 'Category',
            sortable: false,
            width: 150,
            valueGetter: (params) => params,
        });
    }

    if (cardComponentType === CardComponentType.WORKBOOK) {
        columns.splice(8, 0, {
            field: 'type',
            headerName: 'Type',
            sortable: false,
            width: 150,
            valueGetter: (params) => {
                return 'Plan';
            },
        });
    }
    if (cardComponentType === CardComponentType.WORKBOOK) {
        columns.splice(9, 0, {
            field: 'scoreValue',
            headerName: 'Cards',
            sortable: false,
            width: 150,
        });
    }
    const handleHeaderClick = async (id: string) => {
        const organisation = getUserOrganisation(user);
        if (
            cardComponentType === 'card-component' &&
            setMultiSelectedCards &&
            cardsObject &&
            organisation
        ) {
            setMultiSelectedCards((prevCards) => {
                const newCard = {
                    id: id,
                    organisation: organisation,
                    cardTypeId: cardsObject[id].toCardType.id,
                    cardCategoryId: cardsObject[id].toCardCategory.id,
                };
                const index = prevCards.findIndex((card) => card.id === id);

                if (index > -1) {
                    return [
                        ...prevCards.slice(0, index),
                        ...prevCards.slice(index + 1),
                    ];
                } else {
                    return [...prevCards, newCard];
                }
            });
        }
    };
    return (
        <>
            <TableLayout
                columns={columns}
                items={itemsToMap}
                onRowClick={(id: string) => {
                    worksheetRowClick && worksheetRowClick(id);
                    handleHeaderClick(id);
                    if (handleSelect) {
                        const selectedItem = itemsToMap.find(
                            (item) => item.id === id
                        );
                        if (selectedItem) {
                            handleSelect(selectedItem);
                        }
                    }
                }}
                cardComponentType={cardComponentType}
                userId={userId}
                multiSelectedCards={multiSelectedCards}
                pageIdentifier={pageIdentifier}
                clickedItems={clickedItems}
                tableStyle={tableStyle}
                onHover={onHover}
            />
            <StyledModal
                key="confirmation-modal"
                open={confirmationModalOpen}
                onClose={() => setConfirmationModalOpen(false)}
                disableEnforceFocus
                sx={{
                    zIndex: 1500,
                }}
            >
                <Box>
                    {confirmationModalOpen && (
                        <ModalContainer
                            sx={{ maxWidth: '40rem', padding: '2rem' }}
                        >
                            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                                {`Are you sure you want to delete ${numberOfCards} card(s)?`}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'red' }}>
                                Deleted cards can be restored from the profile
                                page for up to 30 days after deletion.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexDirection: 'row',
                                    gap: 2,
                                    alignSelf: 'flex-end',
                                }}
                            >
                                <Button
                                    onClick={() =>
                                        setConfirmationModalOpen(false)
                                    }
                                    variant="outlined"
                                    sx={{
                                        mt: 2,
                                        width: 'fit-content',
                                        borderRadius: 2,
                                        px: 4,
                                    }}
                                >
                                    {loading ? (
                                        <CircularProgress
                                            size={18}
                                            color={'inherit'}
                                        />
                                    ) : (
                                        'Cancel'
                                    )}
                                </Button>
                            </Box>
                        </ModalContainer>
                    )}
                </Box>
            </StyledModal>
        </>
    );
};

export default CardListView;
