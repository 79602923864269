import { Box, Dialog, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';
import { useRef, useState } from 'react';
import useViewportHeight from '../../hooks/useAdjustedHeight';
import { PlaybookDataResponse, PlaybookPageDataResponse } from '../../API';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import EditablePlaybookPage from './EditablePlaybookPage';

export const Slideshow = ({
    index,
    pages,
    playbook,
    height,
    logo
}: {
    index: number;
    pages: PlaybookPageDataResponse[];
    playbook: PlaybookDataResponse;
    height?: string;
    logo: string | null;
}) => {
    const [rendered, setRendered] = useState(false); 
    const IndicatorIcons = pages.map((page) => {
        return (
            <Tooltip title={page.title}>
                <Box
                    sx={{
                        width: '12px',
                        height: '12px',
                    }}
                ></Box>
            </Tooltip>
        );
    });
    return (
        <Carousel
            sx={{
                paddingTop: '60px',
                height: '100%',
                width: '95%',
                margin: 'auto',
                overflow: 'hidden',
                button: {
                    background: 'initial',
                    '&:hover': {
                        background: '#fff',
                    },
                },

                next: {
                    right: 0,
                },

                prev: {
                    left: 0,
                },
                indicator: {
                    display: 'none',
                },
            }}
            PrevIcon={<ArrowLeft width="50" height="50" />}
            NextIcon={<ArrowRight width="50" height="50" />}
            navButtonsWrapperProps={{
                className: 'button-container',
                style: {
                    width: '100px',
                },
            }}
            IndicatorIcon={IndicatorIcons}
            changeOnFirstRender={true}
            onChange={() => setRendered(true)}
            cycleNavigation={false}
            animation="slide"
            duration={800}
            autoPlay={false}
            indicatorContainerProps={{
                style: {
                    position: 'absolute',
                    top: 0,
                },
            }}
            navButtonsProps={{
                style: {
                    background: 'initial',
                },
            }}
            navButtonsAlwaysVisible={true}
            indicatorIconButtonProps={{
                style: {
                    background: '#999999',
                    marginRight: '5px',
                },
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    width: 'fit-content',
                    background: '#2979FF',
                },
            }}
            index={index}
        >
            {pages.map((page, i) => (
                <Item key={i} page={page} playbook={playbook} height={height} logo={logo}/>
            ))}
        </Carousel>
    );
};

const Item = ({
    page,
    playbook,
    height,
    logo
}: {
    page: PlaybookPageDataResponse;
    playbook: PlaybookDataResponse;
    height?: string;
    logo: string | null;
}) => {
    return (
        <Box
            sx={{
                width: '90%',
                margin: 'auto',
                height: '700px',
                background: '#fff',
                color: '#000000',
            }}
        >
            <EditablePlaybookPage
                permissions={[]}
                editMode={false}
                playbook={playbook}
                cards={[]}
                setEditMode={(editMode) => {
                    () => {};
                }}
                page={page}
                itemSelected={false}
                carousel={true}
                logo={logo}
            />
        </Box>
    );
};

interface PlaybookCarouselProps {
    index: number | null;
    closeModal: () => void;
    pages: PlaybookPageDataResponse[];
    playbook: PlaybookDataResponse;
    logo: string | null;
}

const PlaybookCarousel = ({
    index,
    pages,
    playbook,
    closeModal,
    logo
}: PlaybookCarouselProps) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const tableWrapperHeight = useViewportHeight(elementRef);

    return (
        <Dialog
            ref={elementRef}
            PaperProps={{
                sx: {
                    borderRadius: 4,
                    maxWidth: '100%',
                    width: '100%',
                    backgroundColor: 'background.default',
                    height: '100vh',
                },
            }}
            scroll="paper"
            key="modal"
            open={index != null}
            onClose={closeModal}
            aria-labelledby=""
            aria-describedby=""
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                }}
            >
                <IconButton
                    color="primary"
                    onClick={closeModal}
                    title="Close"
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        zIndex: 20,
                    }}
                >
                    <CloseIcon
                        sx={{
                            fontSize: '3rem',
                            width: '35px',
                            height: '35px',
                        }}
                    />
                </IconButton>
                <Box
                    sx={{
                        paddingTop: '50px',
                        width: '100%',
                        height: `${tableWrapperHeight + 70}px`,
                    }}
                >
                    <Slideshow
                        index={index as number}
                        pages={pages}
                        playbook={playbook}
                        height={`${tableWrapperHeight}px`}
                        logo={logo}
                    />
                </Box>
            </Box>
        </Dialog>
    );
};

export default PlaybookCarousel;
