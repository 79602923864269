import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Card } from '../../../API';
import { ReactComponent as CardIcon } from '../../../assets/icons/Small-card.svg';
import { getCategoryHex } from '../../../helpers/category';
import { truncateText } from '../../../helpers/utils';
import AddIcon from '@mui/icons-material/Add';
import { AppContext } from '../../contexts';

const CardItem = ({
    card,
    isSelected,
    onClick,
    existingWorkbookCards,
}: {
    card: Card;
    isSelected: boolean;
    onClick: () => void;
    existingWorkbookCards: Card[];
}) => {
    const theme = useTheme();
    const existsInWorkbook = existingWorkbookCards
        .map((item) => item.id)
        .includes(card.id);
    return (
        <Box
            key={card.id}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '42px',
                px: 2,
                py: 1,
                mb: 0.3,
                background: existsInWorkbook
                    ? theme.palette.background.paper
                    : isSelected
                    ? theme.palette.primary.main
                    : null,
                color: existsInWorkbook ? 'grey' : isSelected ? '#fff' : null,
                cursor: existsInWorkbook ? 'default' : 'pointer',
            }}
            onClick={existsInWorkbook ? undefined : onClick}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CardIcon
                    style={{
                        color: card.cardToCardCategoryId
                            ? getCategoryHex(card.cardToCardCategoryId)
                            : 'inherit',
                        width: '25px',
                        height: '30px',
                    }}
                />
                <Tooltip title={card.name}>
                    <Typography variant="body1" sx={{ ml: 1 }}>
                        {truncateText(card.name, 25)}
                    </Typography>
                </Tooltip>
            </Box>
            {!existsInWorkbook && (
                <Checkbox
                    sx={{
                        '&.Mui-checked': {
                            color: '#fff',
                        },
                    }}
                    checked={isSelected}
                    disabled={existsInWorkbook}
                />
            )}
        </Box>
    );
};

interface SecondaryColumnProps {
    selectedCards: Card[];
    setSelectedCards: React.Dispatch<React.SetStateAction<Card[]>>;
    addCardsToWorkbook: () => Promise<void>;
    existingWorkbookCards: Card[];
    loading: boolean;
    cardsToDisplay: Card[];
    handleClear: () => void;
}

const SecondaryColumn = ({
    selectedCards,
    setSelectedCards,
    addCardsToWorkbook,
    existingWorkbookCards,
    loading,
    cardsToDisplay,
    handleClear,
}: SecondaryColumnProps) => {
    const theme = useTheme();

    useEffect(() => {
        const sortedCardsToDisplay = cardsToDisplay.sort((a, b) => {
            const aExists = existingWorkbookCards.some(
                (card) => card.id === a.id
            );
            const bExists = existingWorkbookCards.some(
                (card) => card.id === b.id
            );
            return aExists === bExists ? 0 : aExists ? 1 : -1;
        });

        setSelectedCards(
            sortedCardsToDisplay.filter(
                (card) =>
                    !existingWorkbookCards.some(
                        (existingCard) => existingCard.id === card.id
                    )
            )
        );
    }, [cardsToDisplay, existingWorkbookCards]);
    const { user } = useContext(AppContext);

    return (
        <Box sx={{ height: '100%' }}>
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    Selected cards
                </Typography>
            </Box>
            <Box sx={{ height: '77%', overflow: 'scroll', py: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2" sx={{ m: 1 }}>
                        {selectedCards.length} cards selected.
                    </Typography>
                    {selectedCards.length > 0 && (
                        <Button sx={{ fontSize: '12px' }} onClick={handleClear}>
                            Clear
                        </Button>
                    )}
                </Box>

                {cardsToDisplay?.map((cardItem) => {
                    return cardItem ? (
                        <CardItem
                            key={cardItem.id}
                            card={cardItem}
                            isSelected={
                                (selectedCards?.some(
                                    (card) => card.id === cardItem.id
                                ) &&
                                    !existingWorkbookCards.some(
                                        (existingCard) =>
                                            existingCard.id === cardItem.id
                                    )) ||
                                false
                            }
                            onClick={() =>
                                setSelectedCards((prevSelectedCards) => {
                                    const cardExists = prevSelectedCards?.some(
                                        (card) => card.id === cardItem.id
                                    );
                                    const isExisting =
                                        existingWorkbookCards?.some(
                                            (existingCard) =>
                                                existingCard.id === cardItem.id
                                        );

                                    if (cardExists) {
                                        return prevSelectedCards?.filter(
                                            (card) => card.id !== cardItem.id
                                        );
                                    } else if (!isExisting) {
                                        return [...prevSelectedCards, cardItem];
                                    } else {
                                        return prevSelectedCards;
                                    }
                                })
                            }
                            existingWorkbookCards={existingWorkbookCards}
                        />
                    ) : null;
                })}
            </Box>
            {cardsToDisplay && cardsToDisplay.length > 0 && (
                <Button
                    disabled={loading}
                    variant="contained"
                    type="submit"
                    sx={{
                        justifySelf: 'right',
                        ml: '70%',
                        width: 'fit-content',
                        borderRadius: 2,
                        fontSize: '20px',
                    }}
                    onClick={addCardsToWorkbook}
                >
                    {loading ? (
                        <CircularProgress color="inherit" size={'1rem'} />
                    ) : (
                        <AddIcon />
                    )}
                </Button>
            )}
        </Box>
    );
};

export default SecondaryColumn;
