import { createContext, MouseEvent } from 'react';
import { PlaybookPageDataResponse } from '../../API';
//import { CardType, CardCategory } from '../src/API';

export const PlaybookContext = createContext<{
    handleClose: (PlaybookPage?: PlaybookPageDataResponse) => void;
    handlePageDelete: (e: MouseEvent<HTMLLIElement>, id: string) => void;
    //cardCategoryObject?: {[key: string]: CardCategory}
}>({
    handleClose: () => null,
    handlePageDelete: () => null,
    //cardCategoryObject: {},
});
