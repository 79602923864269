import { Box, Divider, Typography, styled, useTheme } from '@mui/material';
import Spiral from '../../assets/icons/SC-spiral.svg';
import { PlaybookPageDataResponse } from '../../API';
import { Category, getCategoryIcon } from '../../helpers/category';
import { PlaybookPageFormType } from './playbookTypes';
import { CardBase } from './PlaybookBase';
import { useState } from 'react';
import PlaybookMenu from './PlaybookMenu';
import { MoveDirection } from '../../pages/playbooks/[pid]';
import { UserPermissions } from '../../globals';
import { EditablePageValues } from './EditablePlaybookPage';

interface PlaybookPageProps {
    page: PlaybookPageDataResponse;
    playbookPageForm?: EditablePageValues;
    orderPages?: (id: string, direction: MoveDirection) => void;
    permissions: UserPermissions[];
    setEditMode: (editMode: boolean) => void;
    handleDelete?: (e: React.MouseEvent<HTMLLIElement>, id: string) => void;
    first?: boolean;
    last?: boolean;
    carousel?: boolean;
    front?: boolean;
}

const PlaybookSelectSource = ({
    page,
    orderPages,
    permissions,
    handleDelete,
    setEditMode,
    first,
    last,
    carousel,
    front,
}: PlaybookPageProps) => {
    const Icon = getCategoryIcon(Category.PLAYBOOK);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const setMenu = (e: React.BaseSyntheticEvent) => {
        e.stopPropagation();

        if (e) {
            setAnchorEl(e.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };
    const theme = useTheme();

    return (
        <Box
            sx={{ position: 'relative', pl: carousel ? 5 : 0, height: '100%' }}
        >
            {!carousel && front && (
                <Box
                    sx={{
                        width: '50px',
                        height: '418px',
                        background: `url(${Spiral})`,
                        backgroundRepeat: 'repeat-y',
                        position: 'absolute',
                        left: '-10px',
                        top: '15px',
                        backgroundSize: '65%',
                    }}
                />
            )}
            <CardBase
                id={page.id}
                category={Category.PLAYBOOK}
                name={page.title ?? ''}
                Icon={Icon}
                hideBoxShadow={true}
                hideTitle={!front}
            >
                <Box
                    sx={{
                        minHeight: '25rem',
                        ...(carousel && {
                            height: '100%',
                        }),
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                    }}
                >
                    {front && (
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                ...(carousel && {
                                    height: '100%',
                                }),
                            }}
                        >
                            <Box
                                sx={{
                                    padding: 2,
                                    margin: 10,
                                    borderRadius: 3,
                                    border: 'solid 1px #ffffff',
                                }}
                            >
                                <Typography>
                                    {carousel ||
                                    !permissions.includes(UserPermissions.EDIT)
                                        ? 'No cards selected'
                                        : 'Click here to select workbooks or cards'}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {(permissions.includes(UserPermissions.DELETE) ||
                        permissions.includes(UserPermissions.EDIT)) &&
                        front && (
                            <PlaybookMenu
                                setEditMode={setEditMode}
                                id={page?.id ?? ''}
                                setMenu={setMenu}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                                orderPages={orderPages}
                                handleDelete={handleDelete}
                                iconColor="#FFFFFF"
                                first={first}
                                last={last}
                            />
                        )}
                </Box>
            </CardBase>
        </Box>
    );
};

export default PlaybookSelectSource;
