import { Box, Typography, Divider, styled } from '@mui/material';
import {
    Category,
    getCategoryImage,
    getCardTypeImage,
    getCategoryHexText,
} from '../../helpers/category';
import StyledCardContent from './CardContent';
import { truncateText } from '../../helpers/utils'; 

interface CardSummaryProps {
    name: string;
    cardTypeId: string;
    description?: string | null;
    image?: string;
    categoryId: Category;
    mediumCard?: boolean;
    smallCard?: boolean;
    workbookCard?: boolean;
    swotGrid?: boolean;
    swotColor?: string;
}

const DescriptionWrapper = styled('div')(({ theme }) => ({
    flexGrow: 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
}));

const CardSummary = ({
    description,
    image,
    name,
    categoryId,
    cardTypeId,
    mediumCard,
    smallCard,
    swotGrid,
    swotColor,
    workbookCard,
}: CardSummaryProps) => {
    const cardImage =
        cardTypeId === '69'
            ? '/images/spark-card-image.jpg'
            : getCardTypeImage(cardTypeId);
    const textColor = !!swotColor ? '#ffffff' : getCategoryHexText(categoryId);

    return (
        <>
            {!swotGrid && (
                <Box
                    component="img"
                    alt={name}
                    src={image || cardImage || getCategoryImage(categoryId)}
                    width="100%"
                    loading="lazy"
                    className="card-image"
                />
            )}
            <StyledCardContent mediumCard={mediumCard} smallCard={smallCard}>
                <Typography
                    marginBottom={1}
                    variant="h5"
                    color={textColor}
                    sx={{
                        fontSize:
                            mediumCard || smallCard
                                ? '14px'
                                : workbookCard
                                ? '20px'
                                : '24px',
                    }}
                >
                    {truncateText(name, smallCard ? 43 : 22)}
                </Typography>
                <Divider color={textColor} />
                <DescriptionWrapper>
                    <Typography
                        variant="body2"
                        color={textColor}
                        sx={{
                            fontSize: mediumCard || smallCard ? '10px' : '14px',
                        }}
                    >
                        {description &&
                            truncateText(
                                description,
                                smallCard ? 200 : mediumCard ? 70 : 150
                            )}
                    </Typography>
                </DescriptionWrapper>
                {!mediumCard || (!smallCard && <Divider color={textColor} />)}
            </StyledCardContent>
        </>
    );
};

export default CardSummary;
