import { useContext, useEffect, useState } from 'react';
import {
    AccordionComponent,
    AccordionSummaryComponent,
    CustomExpandIcon,
    PagePreview,
} from './WorkbookDrawerPage';
import {
    AccordionDetails,
    Box,
    Button,
    Typography,
    useTheme,
} from '@mui/material';
import {
    CardSet,
    CardSetType,
    CreatePlaybookPageMutation,
    CreateWorksheetExtMutation,
    PlaybookPage,
    PlaybookPageType,
} from '../../API';
import { generateClient } from 'aws-amplify/api';
import {
    createPlaybookPage,
    createWorksheetExt,
    updateCardSet,
} from '../../graphql/mutations';
import { worksheets } from '../forms/worksheets';
import { getChartSettings } from '../../helpers/worksheets';
import { getUserOrganisation } from '../../helpers/auth';
import WorksheetSelector from '../forms/input-fields/WorksheetSelector';
import { FieldType } from '../forms/input-fields/inputFieldTypes';
import { AppContext } from '../contexts';

interface AddWorksheetProps {
    workbookId?: string;
    currentWorkbook?: CardSet;
    refreshWorkbookDrawer?: (worksheet: PlaybookPage) => void;
    playbookDrawerItems: PagePreview[];
    count: number;
}

const AddWorksheetDrawer = ({
    workbookId,
    currentWorkbook,
    refreshWorkbookDrawer,
    playbookDrawerItems,
    count,
}: AddWorksheetProps) => {
    const [open, setOpen] = useState(false);

    const [selectedWorksheet, setSelectedWorksheet] = useState<string | null>(
        null
    );
    const { user } = useContext(AppContext);

    const client = generateClient();
    const theme = useTheme();

    const addBackToDrawer = async () => {
        const worksheets = currentWorkbook?.hiddenWorksheets
            ? [...currentWorkbook?.hiddenWorksheets]
            : [];

        const index = worksheets.indexOf(selectedWorksheet);
        if (index > -1) {
            // only splice array when item is found
            worksheets.splice(index, 1); // 2nd parameter means remove one item only
        }

        const data = {
            id: currentWorkbook?.id ?? '',
            type: 'WB' as CardSetType,
            name: currentWorkbook?.name,
            capitalName: currentWorkbook?.capitalName,
            createdAt: currentWorkbook?.createdAt,
            organisation: currentWorkbook?.organisation ?? '',
            hiddenWorksheets: worksheets,
        };
        try {
            await client.graphql({
                query: updateCardSet,
                variables: {
                    input: data,
                },
            });
        } catch (e) {}
    };

    useEffect(() => {
        if (count === 0) {
            setOpen(true);
        }
    }, [count]);

    const saveWorkSheetType = async (selectedWorksheet: string) => {
        const worksheet = worksheets.find(
            (item) => item.i === selectedWorksheet
        );
        if (!workbookId || !worksheet) return;
        const settings = getChartSettings(worksheet?.n || '');
        let options = {};
        const nodeSizeSelection =
            settings?.options.chartData.nodeSize.selection;
        options = {
            chartData: {
                xAxis: settings?.options.chartData.xAxisOptions[0],
                yAxis: settings?.options.chartData.yAxisOptions[1],
                nodeSize: {
                    selection: nodeSizeSelection[nodeSizeSelection.length - 1],
                },
                nodeColor: {
                    selection:
                        settings?.options.chartData.nodeColor.selection[0],
                },
                hideGaps: settings?.options.chartData.hideGaps,
            },
            layout: [
                {
                    include: settings?.options.include,
                    layout: settings?.options.layout,
                },
            ],
        };
        if (currentWorkbook?.hiddenWorksheets?.includes(selectedWorksheet)) {
            addBackToDrawer();
        }

        const userOrganisation = getUserOrganisation(user);

        const ordinals = playbookDrawerItems.length
            ? (playbookDrawerItems
                  .map((page) => page.ordinal)
                  .filter(
                      (ordinal) => ordinal !== null && ordinal !== undefined
                  ) as number[])
            : [1];

        const lowest = Math.min(...ordinals);

        const query = createWorksheetExt;
        const variables = {
            typeId: worksheet?.i,
            ordinal: lowest != null ? lowest - 1 : 0,
            organisation: userOrganisation,
            parentId: workbookId,
            options: JSON.stringify(options),
            name: worksheet?.n,
        };
        try {
            const res = (await client.graphql({
                query,
                variables: variables,
            })) as { data: CreateWorksheetExtMutation };
            setSelectedWorksheet(null);
            refreshWorkbookDrawer &&
                refreshWorkbookDrawer(res?.data?.createWorksheetExt as any);
        } catch (e) {
            console.log(e);
        }

        setOpen(false);
    };

    return (
        <Box sx={{ pl: '10px' }}>
            <AccordionComponent
                expanded={open}
                onChange={(event) => {
                    setOpen(!open);
                }}
            >
                <AccordionSummaryComponent
                    expandIcon={<CustomExpandIcon />}
                    aria-controls={`panel-add-worksheet-content`}
                    id={`panel-add-worksheet-header`}
                >
                    <Typography variant="h6">Add new worksheet</Typography>
                </AccordionSummaryComponent>

                <AccordionDetails>
                    <Box>
                        <WorksheetSelector
                            name="worksheetSelector"
                            type={FieldType.WORKSHEETSELECTOR}
                            onChange={(event) => {
                                saveWorkSheetType(event.target.value);
                            }}
                            {...(selectedWorksheet && {
                                selectValue: selectedWorksheet,
                            })}
                        />
                    </Box>
                </AccordionDetails>
            </AccordionComponent>
        </Box>
    );
};

export default AddWorksheetDrawer;
