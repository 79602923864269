import { useContext, useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    Snackbar,
    Typography,
} from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { generateClient } from 'aws-amplify/api';
import { GraphQLQuery } from '@aws-amplify/api';
import {
    Card,
    CardCategory,
    CardType,
    CreateCardMutation,
    ListCardCategoriesQuery,
    CardSet,
    PlaybookPageType,
    PlaybookPageDataResponse,
    Worksheet,
} from '../../../API';
import { listCardCategories } from '../../../graphql/queries';
import CategoriesColumn from './CategoriesColumn';
import PagesColumn from '../CreatePlaybookPage/PagesColumn';
import TypesColumn from './TypesColumn';
import AttributesColumn from './AttributesColumn';
import useSnackbar from '../../../hooks/useSnackbar';
import { CreateCardOptions, PageIdentifier } from '../../cards/cardTypes';
import OptionSelector from './OptionSelector';
import ProcessColumn from '../CreatePlaybookPage/ProcessColumn';
import StepColumn from '../CreatePlaybookPage/StepColumn';
import PageTypesColumn from '../CreatePlaybookPage/PageTypesColumn';
import { useLocation, useParams } from 'react-router-dom';
import { worksheets } from '../worksheets';
import { AppContext } from '../../contexts';
import { RadarOption } from '../input-fields/ChartSettings';
import LayoutColumn from '../CreatePlaybookPage/LayoutColumn';
import { CardContext } from '../../cards/context';
import { ProcessAndStep } from '../../worksheets/WorksheetMain';
import WorkbookList from '../CreatePlaybookPage/WorkbookList';
import WorksheetList from '../CreatePlaybookPage/WorksheetList';
import PageListColumn, {
    NewPlaybookPage,
} from '../CreatePlaybookPage/PageListColumn';
import {
    addScores,
    convertSparkCard,
    createNewPlaybookPage,
    saveCard,
} from '../../../helpers/createCards';
import {
    CardScore,
    ExtendedWorksheet,
    LastCreatedFrom,
    NewCard,
    NewWorksheet,
    SelectedWorksheet,
    SparkCard,
    UserPreferences,
} from './types';

interface CreateCardProps {
    close: () => void;
    card?: Card;
    cardFrom?: Card | null;
    cardCategories?: CardCategory[];
    cardTypes?: CardType[];
    handleClose?: (card?: Card) => void;
    cardSet?: string;
    cardSetToCardCategoryId?: string | null;
    cardSetToCardTypeId?: string;
    fetchCards?: (initialLoad?: boolean) => void;
    setSelectedCardIds?: React.Dispatch<React.SetStateAction<string[]>>;
    workbook?: CardSet;
    pageIdentifier?: PageIdentifier;
    playbookId?: string;
    highestOrdinal?: number | null;
    pages?: PlaybookPageDataResponse[];
    cardTypeFromDrawer?: string;
    setCardType?: React.Dispatch<React.SetStateAction<string | null>>;
    pageOptions?: any;
    radarOptions?: RadarOption[] | null;
    selectedAttributes?: any;
    riskScores?: CardScore[] | null;
    convertSparkCardId?: string;
    cardToConvert?: Card | null;
    addCardToWorkbook?: (card: Card, addToWorksheet: boolean) => Promise<void>;
    worksheet?: boolean;
    processAndStep?: ProcessAndStep | null;
    setProcessAndStep?: React.Dispatch<
        React.SetStateAction<ProcessAndStep | null>
    >;
    lastCreatedFrom?: LastCreatedFrom | null;
    setLastCreatedFrom?: React.Dispatch<
        React.SetStateAction<LastCreatedFrom | null>
    >;
    previousOrdinal?: number | null | undefined;
    setPreviousOrdinal?: React.Dispatch<
        React.SetStateAction<number | null | undefined>
    >;
    loadPlaybook?: () => Promise<void>;
    workbookIdsForPlaybook?: (string | null)[] | null | undefined;
}

const CreateCard = ({
    close,
    card,
    cardFrom,
    cardCategories,
    handleClose,
    cardSet,
    cardSetToCardCategoryId,
    cardSetToCardTypeId,
    fetchCards,
    setSelectedCardIds,
    workbook,
    pageIdentifier,
    playbookId,
    highestOrdinal,
    pages,
    cardTypeFromDrawer,
    setCardType,
    pageOptions,
    radarOptions,
    selectedAttributes,
    riskScores,
    convertSparkCardId,
    cardToConvert,
    addCardToWorkbook,
    worksheet,
    processAndStep,
    setProcessAndStep,
    lastCreatedFrom,
    setLastCreatedFrom,
    previousOrdinal,
    setPreviousOrdinal,
    loadPlaybook,
    workbookIdsForPlaybook,
}: CreateCardProps) => {
    const { id } = useParams();
    const location = useLocation();
    const client = generateClient();

    const [scores, setScores] = useState({
        x: 0,
        y: 0,
    });
    const [cardScores, setCardScores] = useState<CardScore[]>([]);
    const { cardTypeObject, userProfileObject, setUserProfileObject, user } =
        useContext(AppContext);
    const { items, refreshCard } = useContext(CardContext);
    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();
    const [loading, setLoading] = useState(false);
    const [cardCats, setCardCats] = useState<CardCategory[] | undefined>(
        cardCategories
    );
    const preferencesString =
        userProfileObject?.getUserProfile?.preferences || '';
    const [preferences, setPreferences] = useState<UserPreferences>(
        preferencesString.trim() !== '' ? JSON.parse(preferencesString) : {}
    );
    const [newCard, setNewCard] = useState<NewCard>({
        description: '',
        cardToCardCategoryId: '',
        cardToCardTypeId: '',
        name: '',
        organisation: '',
    });

    const [newWorksheet, setNewWorksheet] = useState<NewWorksheet>({
        title: '',
        commentary: '',
        playbookPageType: PlaybookPageType.List,
        options: '',
    });
    const [submitType, setSubmitType] = useState<
        | 'create'
        | 'createAnother'
        | 'createPlaybookPage'
        | 'createAnotherPlaybookPage'
    >('create');

    const [urlError, setUrlError] = useState<boolean>(false);
    const [selectedWorkbook, setSelectedWorkbook] = useState<CardSet>();
    const [selectedWorksheetItems, setSelectedWorksheetItems] = useState<
        ExtendedWorksheet[]
    >([]);
    const [storedCreateOption, setStoredCreateOption] =
        useState<CreateCardOptions>(
            () =>
                localStorage.getItem(
                    `createProcess_${pageIdentifier}`
                ) as CreateCardOptions
        );
    const [selectedCreateOption, setSelectedCreateOption] =
        useState<CreateCardOptions>(
            () =>
                (localStorage.getItem(
                    `createProcess_${pageIdentifier}`
                ) as CreateCardOptions) || CreateCardOptions.BY_PROCESS
        );
    const [selectedProcessId, setSelectedProcessId] = useState<string | null>(
        null
    );
    const [selectedStepId, setSelectedStepId] = useState<string | null>(null);
    const [selectedType, setSelectedType] = useState<string | undefined | null>(
        null
    );
    const [selectedWorksheets, setSelectedWorksheets] = useState<
        SelectedWorksheet[] | null
    >([]);
    const [newPlaybookPages, setNewPlaybookPages] = useState<NewPlaybookPage[]>(
        []
    );
    const [workbooksOfPlaybook, setWorkbooksOfPlaybook] = useState<CardSet[]>();

    useEffect(() => {
        if (riskScores) {
            setCardScores(riskScores);
        }
    }, [riskScores]);

    useEffect(() => {
        if (processAndStep) {
            setSelectedProcessId(processAndStep.process);
            setSelectedStepId(processAndStep.step);
        }
    }, [processAndStep]);

    useEffect(() => {
        if (preferences && pageIdentifier !== PageIdentifier.WORKSHEET) {
            setNewCard({
                description: newCard.description,
                cardToCardCategoryId: '',
                cardToCardTypeId: '',
                name: newCard.name,
                organisation: newCard.organisation,
            });
        } else if (
            pageIdentifier === PageIdentifier.WORKSHEET &&
            selectedCreateOption === CreateCardOptions.TITLE_PAGE
        ) {
            const titleconfig = JSON.stringify({
                content: 'middle',
                logo: 'top',
                color: { text: '#ffffff', background: '#414B5A' },
            });

            setNewWorksheet({
                ...newWorksheet,
                playbookPageType: PlaybookPageType.Title,
                options: JSON.stringify({ titleconfig: titleconfig }),
            });
        }
    }, [selectedCreateOption, preferences]);

    const getCardCats = async () => {
        try {
            const { data } = await client.graphql<
                GraphQLQuery<ListCardCategoriesQuery>
            >({
                query: listCardCategories,
            });

            setCardCats(data?.listCardCategories?.items as CardCategory[]);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        !cardCategories && getCardCats();
    }, [cardCategories]);

    useEffect(() => {
        if (cardSetToCardCategoryId && cardSetToCardTypeId) {
            setNewCard({
                ...newCard,
                cardToCardCategoryId: cardSetToCardCategoryId,
                cardToCardTypeId: cardSetToCardTypeId,
            });
        } else if (cardTypeFromDrawer) {
            setNewCard({
                ...newCard,
                cardToCardCategoryId:
                    cardTypeObject[cardTypeFromDrawer].cardCategoryID,
                cardToCardTypeId: cardTypeFromDrawer,
                attributes: selectedAttributes
                    ? selectedAttributes.map((item: any) => item)
                    : [],
            });
        } else {
            setNewCard({
                ...newCard,
                attributes: [],
            });
        }
    }, [
        newCard.cardToCardCategoryId,
        newCard.cardToCardTypeId,
        selectedType,
        cardTypeFromDrawer,
    ]);
    useEffect(() => {
        if (convertSparkCardId || cardToConvert) {
            const card =
                cardToConvert ??
                items.find((item) => item.id === convertSparkCardId);

            if (card) {
                setNewCard({
                    description: card.description,
                    cardToCardCategoryId: card.cardToCardCategoryId || '',
                    cardToCardTypeId: card.cardToCardCategoryId || '',
                    name: card.name,
                    organisation: card.organisation || '',
                });
            }
        }
    }, [convertSparkCardId, cardToConvert]);

    const handleSave = async (onSuccess: Function) => {
        await saveCard({
            locationPath: location.pathname,
            id,
            worksheet,
            card,
            cardFrom,
            newCard,
            cardSet,
            submitType,
            pageOptions,
            scores,
            preferences,
            cardScores,
            selectedCreateOption,
            selectedProcessId,
            selectedStepId,
            userProfileObject,
            user,
            client,
            setLoading,
            setCardType,
            setLastCreatedFrom,
            setPreferences,
            setUserProfileObject,
            handleClose,
            onSuccess,
        });
    };
    const handleConvertSparkCard = async () => {
        const data: SparkCard = { ...newCard, id: convertSparkCardId ?? '' };
        convertSparkCard(data, id);
        refreshCard && refreshCard(data.id, data.organisation);
        !!handleClose && handleClose();
    };

    const onSubmit = async () => {
        if (!!convertSparkCardId) {
            handleConvertSparkCard();
        } else {
            await handleSave((res: { data: CreateCardMutation }) => {
                if (res.data.createCard) {
                    const cardWithScores = addScores(
                        res?.data?.createCard,
                        cardScores
                    );
                    addCardToWorkbook &&
                        addCardToWorkbook(
                            cardWithScores as Card,
                            !!cardTypeFromDrawer
                        );
                }
                setProcessAndStep &&
                    setProcessAndStep({
                        process: selectedProcessId as string,
                        step: selectedStepId as string,
                    });
                !!handleClose && handleClose(res.data.createCard as Card);
            });
        }
    };

    const onSaveAndCreate = async () => {
        await handleSave((res: { data: CreateCardMutation }) => {
            if (res.data.createCard) {
                const cardWithScores = addScores(
                    res?.data?.createCard,
                    cardScores
                );
                addCardToWorkbook &&
                    addCardToWorkbook(
                        cardWithScores as Card,
                        !!cardTypeFromDrawer
                    );
            }
            showSnackbar({
                message: 'Card created successfully',
                severity: 'success',
            });
            setNewCard((prevState) => ({
                ...prevState,
                description: '',
                name: '',
                organisation: '',
            }));
            fetchCards && fetchCards(true);
        });
    };
    const handleFormSubmit = async (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        if (urlError) return;
        if (submitType === 'create') {
            await onSubmit();
        } else if (submitType === 'createAnother') {
            await onSaveAndCreate();
        } else if (
            submitType === 'createPlaybookPage' ||
            submitType === 'createAnotherPlaybookPage'
        ) {
            await handleCreatePlaybookPage();
        }
    };

    const handleCreatePlaybookPage = async () => {
        await createNewPlaybookPage({
            user,
            client,
            highestOrdinal,
            previousOrdinal,
            setPreviousOrdinal,
            newPlaybookPages,
            playbookId,
            worksheets,
            pages,
            loadPlaybook,
            showSnackbar,
            cardTypeObject,
        });

        if (submitType === 'createPlaybookPage') {
            handleClose && handleClose();
        } else {
            setSelectedWorksheets(null);
            setNewWorksheet({
                title: '',
                commentary: '',
                playbookPageType: PlaybookPageType.List,
            });
        }
    };

    useEffect(() => {
        if (card) {
            setNewCard(card);
        }
        if (cardFrom) {
            setSelectedCreateOption(CreateCardOptions.BY_CATEGORY);
            setNewCard({
                ...newCard,
                name: cardFrom.name,
                description: cardFrom.description,
                attributes: cardFrom.attributes,
                cardToCardCategoryId:
                    lastCreatedFrom?.cardToCardCategoryId ?? '',
                cardToCardTypeId: lastCreatedFrom?.cardToCardTypeId ?? '',
            });
        }
    }, [card, cardFrom]);

    const workbookInsideByProcess =
        selectedCreateOption === CreateCardOptions.BY_PROCESS &&
        pageIdentifier === PageIdentifier.WORKBOOK_INSIDE;

    const worksheetByProcess =
        pageIdentifier === PageIdentifier.WORKSHEET &&
        selectedCreateOption === CreateCardOptions.BY_PROCESS;

    const worksheetByCategory =
        pageIdentifier === PageIdentifier.WORKSHEET &&
        selectedCreateOption === CreateCardOptions.BY_CATEGORY;

    const byWorkbookOrByProcess =
        selectedCreateOption === CreateCardOptions.BY_WORKBOOK ||
        selectedCreateOption === CreateCardOptions.BY_PROCESS;

    const worksheetNonTitlePage =
        pageIdentifier === PageIdentifier.WORKSHEET &&
        selectedCreateOption !== CreateCardOptions.TITLE_PAGE;

    return (
        <Box sx={{ overflowY: 'hidden' }}>
            <Snackbar
                open={isOpen}
                autoHideDuration={duration}
                onClose={closeSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                sx={{ marginBottom: '3rem', zIndex: 1600 }}
            >
                <Alert
                    variant="filled"
                    severity={severity}
                    sx={{ width: '100%' }}
                    onClose={closeSnackbar}
                >
                    {message}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                }}
            >
                <Typography
                    variant="h5"
                    sx={{ marginBottom: 0, textAlign: 'left' }}
                >
                    {cardTypeFromDrawer
                        ? `Create ${cardTypeObject[
                              cardTypeFromDrawer
                          ].name?.toLowerCase()} card`
                        : !!convertSparkCardId
                        ? 'Convert to a different card type'
                        : pageIdentifier === PageIdentifier.WORKSHEET
                        ? 'Create a page'
                        : 'Create a card'}
                </Typography>
                {(pageIdentifier === PageIdentifier.WORKBOOK_INSIDE ||
                    pageIdentifier === PageIdentifier.WORKSHEET) && (
                    <OptionSelector
                        selectedCreateOption={selectedCreateOption}
                        setSelectedCreateOption={setSelectedCreateOption}
                        storedCreateOption={storedCreateOption}
                        setStoredCreateOption={setStoredCreateOption}
                        setSelectedStepId={setSelectedStepId}
                        setSelectedProcessId={setSelectedProcessId}
                        setNewCard={setNewCard}
                        setNewWorksheet={setNewWorksheet}
                        setSelectedWorksheets={setSelectedWorksheets}
                        setSelectedType={setSelectedType}
                        pageIdentifier={pageIdentifier}
                        preferences={preferences}
                        workbook={workbook}
                        setSelectedWorksheetItems={setSelectedWorksheetItems}
                        setSelectedWorkbook={setSelectedWorkbook}
                    />
                )}
                {close && (
                    <IconButton onClick={() => close()}>
                        <CloseIcon sx={{ color: 'inherit' }} />
                    </IconButton>
                )}
            </Box>

            <form onSubmit={handleFormSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 0,
                        height: '61vh',
                        maxHeight: '670px',
                    }}
                >
                    <Grid container spacing={0} sx={{ height: '100%' }}>
                        {/* FIRST COLUMN */}
                        {!cardTypeFromDrawer && (
                            <Grid
                                item
                                xs={12}
                                md={
                                    workbookInsideByProcess
                                        ? 3
                                        : worksheetNonTitlePage
                                        ? 2.5
                                        : selectedCreateOption ===
                                          CreateCardOptions.TITLE_PAGE
                                        ? 4
                                        : 3.5
                                }
                                sx={{ height: '100%' }}
                            >
                                {workbookInsideByProcess ||
                                worksheetByProcess ? (
                                    <ProcessColumn
                                        selectedProcessId={selectedProcessId}
                                        setSelectedProcessId={
                                            setSelectedProcessId
                                        }
                                        setSelectedStepId={setSelectedStepId}
                                        setSelectedWorksheets={
                                            setSelectedWorksheets
                                        }
                                        setSelectedType={setSelectedType}
                                        newCard={newCard}
                                        setNewCard={setNewCard}
                                        setSelectedWorksheetItems={
                                            setSelectedWorksheetItems
                                        }
                                    />
                                ) : selectedCreateOption ===
                                  CreateCardOptions.TITLE_PAGE ? (
                                    <PagesColumn />
                                ) : selectedCreateOption ===
                                  CreateCardOptions.BY_WORKBOOK ? (
                                    <WorkbookList
                                        workbookIds={workbookIdsForPlaybook}
                                        selectedWorkbook={selectedWorkbook}
                                        setSelectedWorkbook={
                                            setSelectedWorkbook
                                        }
                                        setSelectedWorksheetItems={
                                            setSelectedWorksheetItems
                                        }
                                        workbooks={workbooksOfPlaybook}
                                        setWorkbooks={setWorkbooksOfPlaybook}
                                    />
                                ) : (
                                    <CategoriesColumn
                                        newCard={newCard}
                                        cardCategories={cardCats}
                                        cardSetToCardCategoryId={
                                            cardSetToCardCategoryId ?? ''
                                        }
                                        setNewCard={(value) =>
                                            setNewCard(value as NewCard)
                                        }
                                        setSelectedType={setSelectedType}
                                        setSelectedWorksheets={
                                            setSelectedWorksheets
                                        }
                                        cardTypeFromDrawer={cardTypeFromDrawer}
                                        setSelectedProcessId={
                                            setSelectedProcessId
                                        }
                                        setSelectedStepId={setSelectedStepId}
                                    />
                                )}
                            </Grid>
                        )}
                        {/* SECOND COLUMN */}
                        {workbookInsideByProcess ? (
                            <Grid item xs={12} md={2.5} sx={{ height: '100%' }}>
                                <StepColumn
                                    selectedProcessId={selectedProcessId}
                                    setSelectedStepId={setSelectedStepId}
                                    selectedStepId={selectedStepId}
                                />
                            </Grid>
                        ) : (
                            pageIdentifier === PageIdentifier.WORKSHEET &&
                            byWorkbookOrByProcess && (
                                <Grid
                                    item
                                    xs={12}
                                    md={
                                        pageIdentifier ===
                                        PageIdentifier.WORKSHEET
                                            ? 2.5
                                            : 3
                                    }
                                    sx={{ height: '100%' }}
                                    key={selectedCreateOption}
                                >
                                    <WorksheetList
                                        workbookIds={workbookIdsForPlaybook}
                                        selectedWorkbook={selectedWorkbook}
                                        selectedWorksheetItems={
                                            selectedWorksheetItems
                                        }
                                        setSelectedWorksheetItems={
                                            setSelectedWorksheetItems
                                        }
                                        selectedProcessId={selectedProcessId}
                                        selectedCreateOption={
                                            selectedCreateOption
                                        }
                                        workbooksOfPlaybook={
                                            workbooksOfPlaybook
                                        }
                                        setSelectedWorkbook={
                                            setSelectedWorkbook
                                        }
                                        setSelectedProcessId={setSelectedProcessId}
                                    />
                                </Grid>
                            )
                        )}
                        {/* THIRD COLUMN */}
                        {worksheetByCategory && (
                            <Grid
                                item
                                xs={12}
                                md={
                                    pageIdentifier === PageIdentifier.WORKSHEET
                                        ? 2.5
                                        : 3
                                }
                                sx={{ height: '100%' }}
                            >
                                <TypesColumn
                                    setSelectedType={setSelectedType}
                                    cardCategories={cardCats}
                                    newCard={newCard}
                                />
                            </Grid>
                        )}
                        {selectedCreateOption !==
                            CreateCardOptions.TITLE_PAGE &&
                            !cardTypeFromDrawer && (
                                <Grid
                                    item
                                    xs={12}
                                    md={workbookInsideByProcess ? 2.5 : 4}
                                    sx={{ height: '100%' }}
                                >
                                    {pageIdentifier ===
                                    PageIdentifier.WORKSHEET ? (
                                        <PageTypesColumn
                                            setSelectedWorksheets={
                                                setSelectedWorksheets
                                            }
                                            selectedWorksheets={
                                                selectedWorksheets
                                            }
                                            selectedWorksheetItems={
                                                selectedWorksheetItems
                                            }
                                        />
                                    ) : (
                                        <TypesColumn
                                            newCard={newCard}
                                            cardSetToCardCategoryId={
                                                cardSetToCardCategoryId ?? ''
                                            }
                                            cardSetToCardTypeId={
                                                cardSetToCardTypeId
                                            }
                                            setNewCard={(value) =>
                                                setNewCard(value as NewCard)
                                            }
                                            cardCategories={cardCats}
                                            selectedStepId={selectedStepId}
                                            cardTypeFromDrawer={
                                                cardTypeFromDrawer
                                            }
                                            pageIdentifier={pageIdentifier}
                                            selectedType={selectedType}
                                            selectedCreateOption={
                                                selectedCreateOption
                                            }
                                        />
                                    )}
                                </Grid>
                            )}

                        {selectedCreateOption ===
                            CreateCardOptions.TITLE_PAGE && (
                            <Grid item xs={12} md={4}>
                                <LayoutColumn
                                    newWorksheet={newWorksheet}
                                    setNewWorksheet={setNewWorksheet}
                                />
                            </Grid>
                        )}
                        {/* LAST COLUMN */}
                        <Grid
                            item
                            xs={12}
                            md={
                                cardTypeFromDrawer
                                    ? 12
                                    : workbookInsideByProcess
                                    ? 4
                                    : worksheetNonTitlePage
                                    ? 3
                                    : selectedCreateOption ===
                                      CreateCardOptions.TITLE_PAGE
                                    ? 4
                                    : 4.5
                            }
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            {worksheetNonTitlePage ? (
                                <PageListColumn
                                    selectedWorksheets={selectedWorksheets}
                                    setSelectedWorksheets={
                                        setSelectedWorksheets
                                    }
                                    selectedWorksheetItems={
                                        selectedWorksheetItems
                                    }
                                    setNewPlaybookPages={setNewPlaybookPages}
                                />
                            ) : (
                                <AttributesColumn
                                    newCard={newCard}
                                    setNewCard={(value) =>
                                        setNewCard(value as NewCard)
                                    }
                                    urlError={urlError}
                                    setUrlError={setUrlError}
                                    pageIdentifier={pageIdentifier}
                                    selectedWorksheets={selectedWorksheets}
                                    newWorksheet={newWorksheet}
                                    setNewWorksheet={setNewWorksheet}
                                    cardTypeFromDrawer={cardTypeFromDrawer}
                                    pageOptions={pageOptions}
                                    scores={scores}
                                    setScores={setScores}
                                    cardScores={cardScores}
                                    setCardScores={setCardScores}
                                    radarOptions={radarOptions}
                                />
                            )}

                            {newCard.cardToCardTypeId && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        pb: 3,
                                        px: 3,
                                    }}
                                >
                                    {!card && !convertSparkCardId && (
                                        <Button
                                            disabled={loading}
                                            variant="contained"
                                            type="submit"
                                            onClick={() => {
                                                setSubmitType('createAnother');
                                            }}
                                            sx={{
                                                justifySelf: 'right',
                                                mt: 2,
                                                mr: 2,
                                                width: 'fit-content',
                                                px: '10px',
                                                fontSize: '12px',
                                                borderRadius: 2,
                                            }}
                                            data-automation-id="create-another-button"
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={'1rem'}
                                                />
                                            ) : (
                                                'Save and create another'
                                            )}
                                        </Button>
                                    )}
                                    <Button
                                        disabled={loading}
                                        variant="contained"
                                        type="submit"
                                        onClick={() => {
                                            setSubmitType('create');
                                        }}
                                        sx={{
                                            justifySelf: 'right',
                                            mt: 2,
                                            width: 'fit-content',
                                            px: '10px',
                                            fontSize: '12px',
                                            borderRadius: 2,
                                        }}
                                        data-automation-id="create-card-button"
                                    >
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={'1rem'}
                                            />
                                        ) : !!convertSparkCardId ? (
                                            'Convert card'
                                        ) : card ? (
                                            'Edit card'
                                        ) : (
                                            'Create card'
                                        )}
                                    </Button>
                                </Box>
                            )}
                            {((!cardTypeFromDrawer && selectedWorksheets) ||
                                newWorksheet.playbookPageType ===
                                    PlaybookPageType.Title) &&
                                pageIdentifier === PageIdentifier.WORKSHEET && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            pb: 3,
                                            px: 3,
                                        }}
                                    >
                                        {' '}
                                        <Button
                                            disabled={loading}
                                            variant="contained"
                                            type="submit"
                                            onClick={() => {
                                                setSubmitType(
                                                    'createAnotherPlaybookPage'
                                                );
                                            }}
                                            sx={{
                                                justifySelf: 'right',
                                                mt: 2,
                                                mr: 2,
                                                width: 'fit-content',
                                                px: '10px',
                                                fontSize: '14px',
                                                borderRadius: 2,
                                                lineHeight: 1.2,
                                            }}
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={'1rem'}
                                                />
                                            ) : (
                                                'Save and create another'
                                            )}
                                        </Button>
                                        <Button
                                            disabled={loading}
                                            variant="contained"
                                            type="submit"
                                            onClick={() => {
                                                setSubmitType(
                                                    'createPlaybookPage'
                                                );
                                            }}
                                            sx={{
                                                fontSize: '14px',
                                                justifySelf: 'right',
                                                mt: 2,
                                                width: 'fit-content',
                                                borderRadius: 2,
                                                lineHeight: 1.2,
                                            }}
                                            data-automation-id="create-worksheet-button"
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={'1rem'}
                                                />
                                            ) : (
                                                'Create page'
                                            )}
                                        </Button>
                                    </Box>
                                )}
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Box>
    );
};

export default CreateCard;
