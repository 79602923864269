import { useState, useEffect, useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { getScoreColorVariable } from '../../../helpers/scores';
import AnalysisModal from './AnalysisModal';
import { Card, CreatePlaybookPageMutation, ScoreDatum } from '../../../API';
import { ReactComponent as MenuIcon } from '../../../assets/icons/Menu.svg';
import { ReactComponent as HelpIcon } from '../../../assets/icons/Help.svg';
import { truncateText } from '../../../helpers/utils';
import { CardTypeId } from '../../../helpers/category';
import CardMenu from '../../cards/CardMenu';
import { UserPermissions } from '../../../globals';
import { CardContext } from '../../cards/context';
import CardHelp from '../../cards/CardHelp';
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { getUserPermissions } from '../../../helpers/permissions';
import { useContainerDimensions } from '../../../hooks/useContainerDimensions';
import CreateCard from '../../forms/CreateCard';
import { StyledModal } from '../../Modal';
import ModalContainer from '../../ModalContainer';
import { GhostGridCard } from './PestleAnalysis';
import { ReactComponent as FilterIcon } from '../../../assets/icons/Filter.svg';
import FlipIcon from '@mui/icons-material/FlipCameraAndroid';
import { setOptions } from 'react-chartjs-2/dist/utils';
import { getUserOrganisation } from '../../../helpers/auth';
import { updatePlaybookPage } from '../../../graphql/mutations'; 
import { AppContext } from '../../contexts';

export enum SwotType {
    STRENGTH = 'Strength',
    WEAKNESS = 'Weakness',
    OPPORTUNITY = 'Opportunity',
    THREAT = 'Threat',
}

export interface OptionsValue {
    value: string;
    enabled: boolean;
}

const SwotGridContainer = styled('div')<{ length?: number }>(
    ({ theme, length }) => {
        return {
            display: 'grid',
            gridTemplateColumns: `3em repeat(4, 1fr)`,
            gridTemplateRows:
                length && length > 4
                    ? `3em repeat(${length / 2}, 1fr) 3em 3em repeat(${
                          length / 2
                      }, 1fr)`
                    : '3em repeat(2, 1fr) 3em 3em',
            gridColumnGap: '0.275em',
            gridRowGap: '0.275em',
            justifyItems: 'center',
            alignItems: 'center',
        };
    }
);
const Lightbox = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(5px)',
    zIndex: 1400,
}));
interface GridItemProps {
    gridColumn: number;
    gridRow: number;
    length?: number;
    thumbnail?: boolean;
    pptView?: boolean;
}

interface CardGridCell {
    backgroundColor?: string;
}

const GridItem = styled('div')<GridItemProps>(
    ({ gridRow, gridColumn, theme }) => {
        return {
            gridArea: `${gridRow} / ${gridColumn} / ${gridRow} / ${gridColumn}`,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0.5em',
            padding: '0.5em',
        };
    }
);

const GridTopHeaders = styled(GridItem)<GridItemProps>(
    ({ gridRow, gridColumn, theme }) => {
        return {
            gridArea: `${gridRow} / ${gridColumn} / ${gridRow} / ${
                gridColumn + 2
            }`,
            backgroundColor: '#8a95a5',
            color: theme.palette.getContrastText('#8a95a5'),
            position: 'relative',
        };
    }
);

const GridVerticalHeaders = styled(GridTopHeaders)(
    ({ gridRow, gridColumn, theme, length, thumbnail }) => {
        return {
            backgroundColor: '#9e9e9e',
            gridArea: `${gridRow} / ${gridColumn} / ${
                length ? gridRow + length / 2 : gridRow
            } / ${gridColumn}`,
            writingMode: 'vertical-lr',
            transform: 'rotate(-180deg)',
            minHeight: thumbnail
                ? 'inherit'
                : length && length > 5
                ? `${96 * (length / 2) + (length / 2 - 1) * 4.39}px`
                : `196.39px`,
        };
    }
);

const GridCell = styled(GridItem)<GridItemProps>(
    ({ gridRow, gridColumn, theme, pptView }) => {
        return {
            gridArea: `${gridRow} / ${gridColumn} / ${gridRow} / ${gridColumn}`,
            padding: '0.750em',
            height: pptView ? '3.5em' : '6em',
        };
    }
);

const EmptyGridCell = styled(GridCell)(({ theme, pptView }) => {
    return {
        backgroundColor: '#f0f2f5',
        border: pptView ? '1px solid grey' : 'none',
    };
});

const GhostCell = ({
    gridRow,
    gridColumn,
    swotType,
    setSelectedType,
    pptView,
}: {
    gridRow: number;
    gridColumn: number;
    swotType: string;
    setSelectedType: (type: string) => void;
    pptView?: boolean;
}) => {
    return (
        <GridCell
            gridRow={gridRow}
            gridColumn={gridColumn}
            sx={{
                background: '#f0f2f5',
                position: 'relative',
                '&:hover .hoverBox': {
                    opacity: 1,
                    visibility: 'visible',
                },
            }}
            pptView={pptView}
        >
            <GhostGridCard onClick={() => setSelectedType(swotType)} top={0} />
        </GridCell>
    );
};

const CardGridCell = styled(GridCell)<CardGridCell>(
    ({ backgroundColor, theme, pptView }) => {
        return {
            position: 'relative',
            backgroundColor: backgroundColor || '#999',
            color: '#fff',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            overflowY: 'hidden',
            paddingTop: '0.500em',
            paddingBottom: '0.500em',
            textOverflow: 'ellipsis',
            height: pptView ? '3.5em' : '',
        };
    }
);

const internal = ['Strengths', 'Weaknesses'];
const external = ['Opportunities', 'Threats'];

const Cell = ({
    card,
    i,
    rowStart,
    columnStart,
    fullWidth,
    width,
    include,
    length,
    negative,
    thumbnail,
    workbook,
    setSelectedCard,
    gridRowPosition,
    gridColumnPosition,
    score,
    minValue,
    maxValue,
    user,
    pptView,
    pageOptions,
}: {
    card: Card;
    i: number;
    rowStart: number;
    columnStart: number;
    width: number;
    include: string[];
    length: number;
    fullWidth?: boolean;
    negative?: boolean;
    thumbnail?: boolean;
    workbook?: boolean;
    setSelectedCard?: React.Dispatch<React.SetStateAction<string | null>>;
    gridRowPosition: number;
    gridColumnPosition: number;
    score: ScoreDatum | undefined;
    minValue: number | null | undefined;
    maxValue: number | null | undefined;
    user: string | null;
    pptView?: boolean;
    pageOptions?: any;
}) => {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
    const { handleDelete, handleRemove, handleCopy } = useContext(CardContext);
    const permissions = user ? getUserPermissions(user, card) : [];

    const menuItems = [];

    handleRemove &&
        menuItems.push({
            text: 'Remove from workbook',
            action: () => {
                handleRemove(card.id);
            },
        });

    handleCopy &&
        menuItems.push({
            text: 'Copy',
            action: () => {
                handleCopy(card.id);
            },
        });

    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            e.stopPropagation();
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };
    const includes: OptionsValue[] = pageOptions?.layout?.[0]?.include ?? null;
    const handleCardClick = (card: Card) => {
        if (setSelectedCard) {
            setSelectedCard(null);
            setTimeout(() => setSelectedCard(card.id), 500);
        }
    };
    return (
        <CardGridCell
            key={card.id}
            gridRow={gridRowPosition}
            gridColumn={gridColumnPosition}
            backgroundColor={
                score?.value
                    ? getScoreColorVariable(
                          score.value,
                          minValue,
                          maxValue,
                          negative
                      )
                    : ''
            }
            sx={{
                cursor: 'pointer',
                overflow: 'hidden',
                transition: 'border 2s, transform 0.5s',
                borderColor: 'transparent',
                borderWidth: '3px',
                borderStyle: 'solid',
                ...(workbook && {
                    ':hover': {
                        borderColor: '#999',
                        transform: 'scale(0.94)',
                    },
                }),
            }}
            onClick={() =>
                setSelectedCard &&
                (workbook || thumbnail === false) &&
                handleCardClick(card)
            }
            pptView={pptView}
        >
            <Box
                sx={{
                    display: width > 300 ? 'flex' : 'none',
                    flexDirection: 'column',
                    maxHeight: '100%',
                    height: '100%',
                    paddingTop: '6px',
                    justifyContent: 'flex-start',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        variant="body1"
                        fontSize={
                            pptView
                                ? '1em'
                                : width > 450 && !pptView
                                ? '0.875em'
                                : '0.7em'
                        }
                        lineHeight="1.250em"
                        sx={{
                            margin: includes?.find(
                                (item) => item.value === 'score'
                            )?.enabled
                                ? '-5px 0 5px 0'
                                : 'inherit',
                        }}
                    >
                        <strong>
                            {truncateText(
                                card.name,
                                pptView
                                    ? 20
                                    : fullWidth && width > 1450
                                    ? 30
                                    : 13
                            )}
                        </strong>
                    </Typography>

                    {includes?.find((item) => item.value === 'score')
                        ?.enabled && (
                        <Box
                            sx={{
                                display: 'flex',
                                height: '100%',
                                pl: 0.5,
                                marginTop: '-15px',
                            }}
                        >
                            <Typography
                                variant="h6"
                                fontSize={pptView ? '1.4em' : '1.5em'}
                            >
                                {score?.value}
                            </Typography>
                        </Box>
                    )}
                </Box>
                {!pptView && (
                    <Typography
                        variant="body2"
                        fontSize={width > 450 ? '0.775em' : '0.7em'}
                        sx={{
                            marginTop: score && !thumbnail ? '-5px' : 0,
                        }}
                    >
                        {width > 811 &&
                            truncateText(
                                card?.description ?? '',
                                fullWidth && width > 1450 ? 140 : 80
                            )}
                    </Typography>
                )}
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: '-10px',
                    width: '50px',
                    height: '50px',
                }}
            >
                {workbook &&
                    (menuItems.length > 0 ||
                        permissions.includes(UserPermissions.EDIT)) && (
                        <IconButton
                            aria-label=""
                            id="menu-button"
                            onClick={setMenu}
                            sx={{ ml: 2, mt: 1, color: '#FFF' }}
                        >
                            <MenuIcon width={'20px'} height={'20px'} />
                        </IconButton>
                    )}
                {menuAnchor && (
                    <CardMenu
                        permissions={[
                            UserPermissions.EDIT,
                            UserPermissions.DELETE,
                        ]}
                        anchor={menuAnchor}
                        data={{
                            organisation: card.organisation || '',
                            id: card.id,
                        }}
                        menuItems={menuItems}
                        setMenu={setMenu}
                        {...(permissions.includes(UserPermissions.EDIT) && {
                            handleDelete: handleDelete,
                        })}
                    />
                )}
            </Box>
        </CardGridCell>
    );
};

interface TwoColumnCardGrid {
    cards?: Card[];
    rowStart: number;
    fullWidth?: boolean;
    columnStart: number;
    width: number;
    include: string[];
    length: number;
    negative?: boolean;
    thumbnail?: boolean;
    workbook?: boolean;
    setSelectedCard?: React.Dispatch<React.SetStateAction<string | null>>;
    setSelectedType: (type: string) => void;
    swotType: string;
    user: string | null;
    pptView?: boolean;
    pageOptions?: any;
}

const TwoColumnCardGrid = ({
    cards,
    rowStart,
    columnStart,
    fullWidth,
    width,
    include,
    length,
    negative,
    thumbnail,
    workbook,
    swotType,
    setSelectedCard,
    setSelectedType,
    user,
    pptView,
    pageOptions,
}: TwoColumnCardGrid) => {
    const columnLength = 2;
    const getRowPosition = (position: number) =>
        Math.floor(position / columnLength) + rowStart;
    const getColumnPosition = (position: number) =>
        position % 2 === 0 ? columnStart : columnStart + 1;
    const { permissions } = useContext(CardContext);
    const { cardTypeObject } = useContext(AppContext);

    if (cards) {
        const cardCells = cards.map((card, i) => {
            const columnLength = 2;
            const getRowPosition = (position: number) =>
                Math.floor(position / columnLength) + rowStart;
            const getColumnPosition = (position: number) =>
                position % 2 === 0 ? columnStart : columnStart + 1;
            const gridRowPosition = i > 0 ? getRowPosition(i) : rowStart;
            const gridColumnPosition =
                i % 2 === 0 ? columnStart : columnStart + 1;

            const primaryScore = card.toScoreData?.items.find(
                (score) =>
                    score?.scoreDefinitionId ===
                    cardTypeObject[card.cardToCardTypeId]
                        ?.defaultScoreDefinitionId
            );
            const primaryScoreDefinition = cardTypeObject[
                card.cardToCardTypeId
            ]?.scoreDefinitions.find(
                (scoreDefinition) =>
                    scoreDefinition.id === primaryScore?.scoreDefinitionId
            );
            const scoreData = primaryScore?.data;
            const maxValue = primaryScoreDefinition?.maximumValue;
            const minValue = primaryScoreDefinition?.minimumValue;
            const score = scoreData?.[scoreData.length - 1];

            return (
                <Cell
                    card={card}
                    i={i}
                    width={width}
                    fullWidth={fullWidth}
                    include={include}
                    length={length}
                    rowStart={rowStart}
                    columnStart={columnStart}
                    thumbnail={thumbnail}
                    negative={negative}
                    score={score}
                    maxValue={maxValue}
                    minValue={minValue}
                    gridColumnPosition={gridColumnPosition}
                    gridRowPosition={gridRowPosition}
                    setSelectedCard={setSelectedCard}
                    workbook={workbook}
                    user={user}
                    pptView={pptView}
                    pageOptions={pageOptions}
                />
            );
        });

        const cardCellsLength = cardCells.length;
        const total = length > 4 ? length : 4;

        for (let i = cardCellsLength; i < total; i++) {
            if (workbook && permissions?.includes(UserPermissions.EDIT)) {
                cardCells.push(
                    <GhostCell
                        gridRow={getRowPosition(i)}
                        gridColumn={getColumnPosition(i)}
                        setSelectedType={setSelectedType}
                        swotType={swotType}
                        pptView={pptView}
                    />
                );
            } else {
                cardCells.push(
                    <EmptyGridCell
                        gridRow={getRowPosition(i)}
                        gridColumn={getColumnPosition(i)}
                        pptView={pptView}
                    />
                );
            }
        }

        return <>{cardCells}</>;
    } else {
        const loadingCells = [];

        for (let i = 0; i < 4; i++) {
            loadingCells.push(
                <EmptyGridCell
                    gridRow={getRowPosition(i)}
                    gridColumn={getColumnPosition(i)}
                />
            );
        }

        return <>{loadingCells}</>;
    }
};

const SwotGrid = ({
    cards,
    include,
    thumbnail,
    workbook,
    fullWidth,
    workbookId,
    pptView,
    slideNumber,
    pageOptions,
    setPageOptions,
    selectedAttribute,
    setSelectedAttribute,
    setFilterModal,
    selectedCard,
    setSelectedCard,
    worksheetId,
}: {
    cards: Card[];
    width: number;
    include: string[];
    thumbnail?: boolean;
    workbook?: boolean;
    fullWidth?: boolean;
    workbookId?: string | undefined;
    pptView?: boolean;
    slideNumber?: number;
    pageOptions?: any;
    setPageOptions?: (pageOptions: any) => void;
    selectedAttribute?: any;
    setSelectedAttribute?: any;
    setFilterModal?: (e: React.MouseEvent<HTMLElement> | null) => void;
    selectedCard?: string | null;
    setSelectedCard?: React.Dispatch<React.SetStateAction<string | null>>;
    worksheetId?: string;
}) => {
    const { handleClose } = useContext(CardContext);
    const [strengthCards, setStrengthCards] = useState<Card[]>();
    const [weaknessesCards, setWeaknessCards] = useState<Card[]>();
    const [helpTextAnchor, setHelpTextAnchor] = useState<HTMLElement | null>();
    const [helpId, setHelpId] = useState<string | null>(null);
    const [opportunityCards, setOpportunityCards] = useState<Card[]>();
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const { permissions } = useContext(CardContext);
    const [threatCards, setThreatCards] = useState<Card[]>();
    const { width } = useContainerDimensions();
    const [user, setUser] = useState<string | null>(null);

    const flip = pageOptions?.flip;

    const client = generateClient();

    const STRENGTH = '2';
    const THREAT = '6';
    const WEAKNESS = '4';
    const OPPORTUNITY = '5';

    const setHelpTextModal = (
        e: React.MouseEvent<HTMLElement> | null,
        id?: string
    ) => {
        if (e) {
            setHelpTextAnchor(e.currentTarget);
            setHelpId(id ?? '');
        } else {
            setHelpTextAnchor(null);
            setHelpId(null);
        }
    };

    const setSwotCards = (cards: Card[]) => {
        const strength: Card[] = [];
        const weakness: Card[] = [];
        const opportunity: Card[] = [];
        const threat: Card[] = [];

        const filteredCards = selectedAttribute
            ? cards.filter(
                  (card) =>
                      card.attributes &&
                      card.attributes.some(
                          (attr) =>
                              attr && selectedAttribute.includes(attr.value)
                      )
              )
            : cards;

        filteredCards.forEach((card) => {
            switch (card.cardToCardTypeId) {
                case CardTypeId.SWOT_STRENGTH:
                    strength.push(card);
                    break;
                case CardTypeId.SWOT_WEAKNESS:
                    weakness.push(card);
                    break;
                case CardTypeId.SWOT_OPPORTUNITY:
                    opportunity.push(card);
                    break;
                case CardTypeId.SWOT_THREAT:
                    threat.push(card);
                    break;
            }
        });

        setStrengthCards(strength);
        setWeaknessCards(weakness);
        setOpportunityCards(opportunity);
        setThreatCards(threat);
    };

    const getLength = (workbook?: boolean) => {
        const array = [
            strengthCards,
            weaknessesCards,
            opportunityCards,
            threatCards,
        ];
        const lengths: number[] = array.map((a) => a?.length ?? 0);
        const longest = array[lengths.indexOf(Math.max(...lengths))];

        let length = longest?.length ? longest.length : 3;
        length =
            workbook && permissions?.includes(UserPermissions.EDIT)
                ? length + 1
                : length;

        return length % 2 === 0 ? length : length + 1;
    };

    useEffect(() => {
        setSwotCards(cards);
    }, [cards, selectedAttribute]);

    useEffect(() => {
        const getUser = async () => {
            const user = await fetchAuthSession();

            setUser(user?.tokens?.idToken?.payload.sub ?? '');
        };
        getUser();
    }, []);

    const length = getLength(workbook);

    const setFlip = async () => {
        const options = { ...pageOptions };

        if (!options.flip) {
            options.flip = true;
        } else {
            options.flip = false;
        }

        setPageOptions && setPageOptions(options);

        const user = await fetchAuthSession();
        const userOrganisation = getUserOrganisation(user);

        const query = updatePlaybookPage;

        const variables: any = {
            input: {
                id: worksheetId,
                organisation: userOrganisation ?? '',
                options: JSON.stringify(options),
            },
        };
        try {
            //Submit to here
            const res = (await client.graphql({
                query,
                variables: variables,
            })) as { data: CreatePlaybookPageMutation };

            if (res) {
                handleClose();
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Box
            sx={{ mt: pptView ? 2 : 0, height: pptView ? '700px' : 'initial' }}
        >
            {helpTextAnchor && <Lightbox />}

            <SwotGridContainer
                length={getLength(workbook)}
                sx={{ mt: pptView ? 8 : 0 }}
            >
                {workbookId && (
                    <Box
                        onClick={(event) => {
                            event.stopPropagation();
                            setFlip();
                        }}
                    >
                        <FlipIcon />
                    </Box>
                )}
                {(!pptView ||
                    (pptView && slideNumber === 1) ||
                    (pptView && !slideNumber)) && (
                    <GridTopHeaders
                        gridRow={1}
                        gridColumn={2}
                        sx={{ position: 'relative' }}
                    >
                        <Typography
                            variant="h5"
                            fontSize={width > 380 ? '1.5em' : '1em'}
                            sx={{
                                width: pptView ? '100%' : 'initial',
                                textAlign: 'center',
                            }}
                        >
                            {`${flip ? 'Opportunities' : 'Strengths'} (${
                                flip
                                    ? opportunityCards?.length
                                    : strengthCards?.length
                            })`}
                        </Typography>
                        {(workbook || thumbnail === false) && (
                            <IconButton
                                aria-label=""
                                id="menu-button"
                                onClick={(e) =>
                                    setHelpTextModal(e, flip ? '5' : '2')
                                }
                                sx={{
                                    ml: 1,
                                    position: 'absolute',
                                    right: 0,
                                    color: '#fff',
                                    width: '50px',
                                    height: '50px',
                                }}
                            >
                                <HelpIcon />
                            </IconButton>
                        )}
                    </GridTopHeaders>
                )}

                {(!pptView ||
                    (pptView && slideNumber === 1) ||
                    (pptView && !slideNumber)) && (
                    <GridTopHeaders gridRow={1} gridColumn={4}>
                        <Typography
                            variant="h5"
                            fontSize={width > 380 ? '1.5em' : '1em'}
                            sx={{
                                width: pptView ? '100%' : 'initial',
                                textAlign: 'center',
                            }}
                        >
                            {`${flip ? 'Threats' : 'Weaknesses'} (${
                                flip
                                    ? threatCards?.length
                                    : weaknessesCards?.length
                            })`}
                        </Typography>
                        {(workbook || thumbnail === false) && (
                            <IconButton
                                aria-label=""
                                id="menu-button"
                                onClick={(e) =>
                                    setHelpTextModal(e, flip ? '6' : '4')
                                }
                                sx={{
                                    ml: 1,
                                    position: 'absolute',
                                    right: 0,
                                    color: '#fff',
                                    width: '50px',
                                    height: '50px',
                                }}
                            >
                                <HelpIcon />
                            </IconButton>
                        )}
                    </GridTopHeaders>
                )}
                {(!pptView ||
                    (pptView && slideNumber === 1) ||
                    (pptView && !slideNumber)) && (
                    <GridVerticalHeaders
                        gridRow={2}
                        gridColumn={1}
                        length={length > 4 ? length : 4}
                        thumbnail={thumbnail}
                    >
                        <Typography
                            variant="h6"
                            fontSize={width > 380 ? '1.5em' : '1em'}
                        >
                            {flip ? 'External' : 'Internal'}
                        </Typography>
                    </GridVerticalHeaders>
                )}
                {(!pptView ||
                    (pptView && slideNumber === 1) ||
                    (pptView && !slideNumber)) && (
                    <TwoColumnCardGrid
                        cards={flip ? opportunityCards : strengthCards}
                        rowStart={2}
                        columnStart={2}
                        width={width}
                        fullWidth={fullWidth}
                        include={include}
                        length={length}
                        thumbnail={thumbnail}
                        setSelectedCard={setSelectedCard}
                        setSelectedType={setSelectedType}
                        swotType={flip ? OPPORTUNITY : STRENGTH}
                        workbook={workbook}
                        user={user}
                        pptView={pptView}
                        pageOptions={pageOptions}
                    />
                )}

                {(!pptView ||
                    (pptView && slideNumber === 1) ||
                    (pptView && !slideNumber)) && (
                    <TwoColumnCardGrid
                        cards={flip ? threatCards : weaknessesCards}
                        rowStart={2}
                        columnStart={4}
                        width={width}
                        fullWidth={fullWidth}
                        include={include}
                        length={length}
                        negative={true}
                        thumbnail={thumbnail}
                        setSelectedCard={setSelectedCard}
                        setSelectedType={setSelectedType}
                        swotType={flip ? OPPORTUNITY : WEAKNESS}
                        workbook={workbook}
                        user={user}
                        pptView={pptView}
                        pageOptions={pageOptions}
                    />
                )}

                {(!pptView ||
                    (pptView && slideNumber === 2) ||
                    (pptView && !slideNumber)) && (
                    <GridTopHeaders
                        gridRow={
                            pptView && slideNumber
                                ? 1
                                : length > 4
                                ? length / 2 + 3
                                : 5
                        }
                        gridColumn={2}
                    >
                        <Typography
                            variant="h5"
                            fontSize={width > 380 ? '1.5em' : '1em'}
                            sx={{
                                width: pptView ? '100%' : 'initial',
                                textAlign: 'center',
                            }}
                        >
                            {`${flip ? 'Strengths' : 'Opportunities'} (${
                                flip
                                    ? strengthCards?.length
                                    : opportunityCards?.length
                            })`}
                        </Typography>
                        {(workbook || thumbnail === false) && (
                            <IconButton
                                aria-label=""
                                id="menu-button"
                                onClick={(e) =>
                                    setHelpTextModal(e, flip ? '2' : '5')
                                }
                                sx={{
                                    ml: 1,
                                    position: 'absolute',
                                    right: 0,
                                    color: '#fff',
                                    width: '50px',
                                    height: '50px',
                                }}
                            >
                                <HelpIcon />
                            </IconButton>
                        )}
                    </GridTopHeaders>
                )}
                {(!pptView ||
                    (pptView && slideNumber === 2) ||
                    (pptView && !slideNumber)) && (
                    <GridTopHeaders
                        gridRow={
                            pptView && slideNumber
                                ? 1
                                : length > 4
                                ? length / 2 + 3
                                : 5
                        }
                        gridColumn={4}
                    >
                        <Typography
                            variant="h5"
                            fontSize={width > 380 ? '1.5em' : '1em'}
                            sx={{
                                width: pptView ? '100%' : 'initial',
                                textAlign: 'center',
                            }}
                        >
                            {`${flip ? 'Weaknesses' : 'Threats'} (${
                                flip
                                    ? weaknessesCards?.length
                                    : threatCards?.length
                            })`}
                        </Typography>
                        {(workbook || thumbnail === false) && (
                            <IconButton
                                aria-label=""
                                id="menu-button"
                                onClick={(e) =>
                                    setHelpTextModal(e, flip ? '4' : '6')
                                }
                                sx={{
                                    ml: 1,
                                    position: 'absolute',
                                    right: 0,
                                    color: '#fff',
                                    width: '50px',
                                    height: '50px',
                                }}
                            >
                                <HelpIcon />
                            </IconButton>
                        )}
                    </GridTopHeaders>
                )}
                {(!pptView ||
                    (pptView && slideNumber === 2) ||
                    (pptView && !slideNumber)) && (
                    <GridVerticalHeaders
                        gridRow={
                            pptView && slideNumber
                                ? 2
                                : length > 4
                                ? 4 + length / 2
                                : 6
                        }
                        gridColumn={1}
                        length={length > 4 ? length : 4}
                        thumbnail={thumbnail}
                    >
                        <Typography
                            variant="h6"
                            fontSize={width > 380 ? '1.5em' : '1em'}
                        >
                            {flip ? 'Internal' : 'External'}
                        </Typography>
                    </GridVerticalHeaders>
                )}

                {(!pptView ||
                    (pptView && slideNumber === 2) ||
                    (pptView && !slideNumber)) && (
                    <TwoColumnCardGrid
                        cards={flip ? strengthCards : opportunityCards}
                        rowStart={
                            pptView && slideNumber
                                ? 2
                                : length > 4
                                ? 4 + length / 2
                                : 6
                        }
                        columnStart={2}
                        width={width}
                        fullWidth={fullWidth}
                        include={include}
                        length={length}
                        thumbnail={thumbnail}
                        setSelectedCard={setSelectedCard}
                        setSelectedType={setSelectedType}
                        swotType={flip ? STRENGTH : OPPORTUNITY}
                        workbook={workbook}
                        user={user}
                        pptView={pptView}
                        pageOptions={pageOptions}
                    />
                )}

                {(!pptView ||
                    (pptView && slideNumber === 2) ||
                    (pptView && !slideNumber)) && (
                    <TwoColumnCardGrid
                        cards={flip ? opportunityCards : threatCards}
                        rowStart={
                            pptView && slideNumber
                                ? 2
                                : length > 4
                                ? 4 + length / 2
                                : 6
                        }
                        columnStart={4}
                        width={width}
                        fullWidth={fullWidth}
                        include={include}
                        length={length}
                        negative={true}
                        thumbnail={thumbnail}
                        setSelectedCard={setSelectedCard}
                        setSelectedType={setSelectedType}
                        swotType={flip ? OPPORTUNITY : THREAT}
                        workbook={workbook}
                        user={user}
                        pptView={pptView}
                        pageOptions={pageOptions}
                    />
                )}
                {selectedCard &&
                    setSelectedCard &&
                    cards.find((card) => card.id === selectedCard) && (
                        <AnalysisModal
                            card={
                                cards.find(
                                    (card) => card.id === selectedCard
                                ) ?? null
                            }
                            handleClose={() => setSelectedCard(null)}
                            fullWidth={fullWidth}
                            setSelectedCard={setSelectedCard}
                        />
                    )}
                <StyledModal
                    key="modal"
                    open={!!selectedType}
                    onClose={() => setSelectedType(null)}
                >
                    <Box>
                        {!!selectedType && (
                            <ModalContainer sx={{ maxWidth: '35rem' }}>
                                <CreateCard
                                    cardTypeFromDrawer={selectedType}
                                    cardSet={workbookId}
                                    handleClose={() => {
                                        setSelectedType(null);
                                        handleClose();
                                    }}
                                    close={() => handleClose()}
                                />
                            </ModalContainer>
                        )}
                    </Box>
                </StyledModal>
                {helpTextAnchor && helpId && (
                    <CardHelp
                        anchor={helpTextAnchor}
                        setHelpTextModal={() => setHelpTextModal(null)}
                        setHelpTextAnchor={setHelpTextAnchor}
                        cardTypeId={helpId}
                        cardCategoryId="2"
                    />
                )}
            </SwotGridContainer>
        </Box>
    );
};

export default SwotGrid;
