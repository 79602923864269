import { useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Menu, MenuItem, useTheme } from '@mui/material';
import { dateStringFromISO, truncateText } from '../../../helpers/utils';
import TableLayout from '../../layouts/TableLayout';
import { CardComponentProps, CardComponentType, ViewType } from '../cardTypes';
import { Box } from '@mui/system';
import { ReactComponent as MenuIcon } from '../../../assets/icons/Menu.svg';
import { ReactComponent as PlaybooksIcon } from '../../../assets/icons/small-playbook.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import UserAvatar from '../../profile/UserAvatar';
import { Tooltip, Typography } from '@mui/material';
import { MoveDirection } from '../../../pages/playbooks/[pid]';
import { UserPermissions } from '../../../globals';
import { WorksheetAvatar } from '../../workbooks/WorksheetItem';
import { worksheets } from '../../forms/worksheets';

interface CardData {
    first: boolean;
    last: boolean;
    id: string;
}

const WorksheetListView = ({
    items,
    orderPages,
    handleDelete,
    configure,
    permissions,
    workbookId,
    selectedViewType,
}: {
    items: CardData[];
    orderPages?: (id: string, direction: MoveDirection) => void;
    handleDelete?: (id: string) => void;
    configure?: (id: string, edit?: boolean) => void;
    permissions: UserPermissions[];
    workbookId?: string;
    selectedViewType?: ViewType;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showMenu, setShowMenu] = useState(false);

    const [cardSetModalOpen, setCardSetModalOpen] = useState(false);
    const [card, setCard] = useState<CardData | null>(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const CardMenu = () => {
        return (
            <Menu
                id="playbook-menu"
                aria-labelledby="menu-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={(e: React.BaseSyntheticEvent) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        boxShadow: `1px 1px 1px 1px ${theme.palette.background.paper}`,
                        backgroundColor: theme.palette.background.default,
                    },
                }}
            >
                {' '}
                {!workbookId && (
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(null);
                            configure && configure(card?.id ?? '', true);
                        }}
                    >
                        Configure
                    </MenuItem>
                )}
                {!card?.first && !workbookId && (
                    <MenuItem
                        onClick={(e) => {
                            setAnchorEl(null);
                            orderPages &&
                                orderPages(card?.id ?? '', MoveDirection.UP);
                        }}
                    >
                        Move up
                    </MenuItem>
                )}
                {!card?.last && !workbookId && (
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(null);
                            orderPages &&
                                orderPages(card?.id ?? '', MoveDirection.DOWN);
                        }}
                    >
                        Move down
                    </MenuItem>
                )}
                <MenuItem
                    onClick={(e) => {
                        setAnchorEl(null);
                        handleDelete && handleDelete(card?.id ?? '');
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>
        );
    };

    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setAnchorEl(e.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };
    const setMenuOpen = () => {
        setAnchorEl(null);
        setCardSetModalOpen(true);
    };

    const ownerColumn: GridColDef = {
        field: 'owner',
        headerName: 'Owner',
        hideable: false,
        sortable: false,
        width: 150,
        renderCell: (params) => {
            return (
                <UserAvatar
                    userId={params.row.owner}
                    isProfile={false}
                    fullNameOnly={true}
                />
            );
        },
    };
    const renderIcon = () => {
        return <PlaybooksIcon className="name-icon" width={24} height={24} />;
    };

    const columns: GridColDef[] = [
        {
            field: 'control',
            hideable: false,
            headerName: '',
            width: 40,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        {(permissions.includes(UserPermissions.DELETE) ||
                            permissions.includes(UserPermissions.EDIT)) && (
                            <MenuIcon
                                width={35}
                                color="#808080"
                                onClick={(
                                    event: React.MouseEvent<
                                        SVGSVGElement,
                                        MouseEvent
                                    >
                                ) => {
                                    event.stopPropagation();
                                    setAnchorEl(
                                        event.currentTarget as unknown as HTMLElement
                                    );

                                    setCard(params.row);
                                    setShowMenu(true);
                                }}
                            />
                        )}
                        {showMenu && anchorEl && card && <CardMenu />}
                    </Box>
                );
            },
        },
        {
            field: workbookId ? 'name' : 'title',
            headerName: 'Name',
            hideable: false,
            sortable: false,
            width: 250,
            renderCell: (
                params: GridRenderCellParams<any, CardComponentProps>
            ) => {
                const currentWorksheet = worksheets.find(
                    (worksheet) => worksheet.i === params.row.typeId
                );
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        {workbookId ? (
                            <WorksheetAvatar
                                worksheet={currentWorksheet?.n as string}
                                selectedViewType={
                                    selectedViewType || ViewType.GRID
                                }
                            />
                        ) : (
                            renderIcon()
                        )}
                        {params.value && typeof params.value === 'string' ? (
                            <Tooltip title={params.value}>
                                <Typography variant="body2" sx={{ ml: '2px' }}>
                                    {truncateText(params.value, 20)}
                                </Typography>
                            </Tooltip>
                        ) : (
                            ''
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'updatedAt',
            headerName: 'Last modified date',
            hideable: false,
            sortable: false,
            width: 200,
            valueFormatter: (params) => dateStringFromISO(params),
        },
    ];
    columns.splice(2, 0, ownerColumn);

    columns.splice(2, 0, {
        field: 'commentary',
        headerName: 'Commentary',
        hideable: false,
        sortable: false,
        width: 450,
    });
    columns.splice(7, 0, {
        field: 'createdAt',
        headerName: 'Created on',
        hideable: false,
        sortable: false,
        width: 200,
        valueFormatter: (params) => dateStringFromISO(params),
    });

    return (
        <Box sx={{ maxHeight: '600px', overflow: 'auto', width: '100%' }}>
            <TableLayout
                columns={columns}
                items={items}
                onRowClick={(id) =>
                    configure
                        ? configure(id)
                        : navigate(`/workbooks/${workbookId}/${id}`)
                }
                cardComponentType={CardComponentType.WORKSHEET}
            />
        </Box>
    );
};

export default WorksheetListView;
