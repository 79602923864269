import { useContext, useEffect, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    Dialog,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { generateClient } from 'aws-amplify/api';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { MouseEvent, useState } from 'react';
import PageAppBar from '../../components/PageAppBar';
import {
    CardComponentData,
    CardComponentType,
    CardPage,
    PageIdentifier,
    ViewType,
} from '../../components/cards/cardTypes';
import EditablePlaybookPage from '../../components/playbooks/EditablePlaybookPage';
import PlaybookPageComponent from '../../components/playbooks/PlaybookPage';

import { PlaybookContext } from '../../components/playbooks/context';
import TileViewIcon from '../../assets/images/menubar/grid-view-unselected.png';
import TileViewIconActive from '../../assets/images/menubar/grid-view.png';
import ListViewIcon from '../../assets/images/menubar/list-view-unselected.png';
import ListViewIconActive from '../../assets/images/menubar/list-view.png';
import { ReactComponent as DownloadIcon } from '../../assets/icons/Download.svg';
import {
    Card,
    CreatePlaybookPageMutation,
    DeletePlaybookMutation,
    DeletePlaybookPageMutation,
    GetPlaybookDataQuery,
    GetPlaybookPageQuery,
    GetPlaybookQuery,
    Playbook,
    PlaybookDataResponse,
    PlaybookPage,
    PlaybookPageDataResponse,
    PlaybookPageType,
} from '../../API';
import { deletePlaybook, deletePlaybookPage } from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';
import { getPlaybook, getPlaybookPage } from '../../graphql/queries';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../components/contexts';
import { CardContext } from '../../components/cards/context';
import { StyledEmptyItem } from '../../components/layouts/GridLayout';
import CardMenu from '../../components/cards/CardMenu';
import { getCardSetPermissions } from '../../helpers/permissions';
import { UserPermissions } from '../../globals';
import CardActions, {
    CardActionItemProps,
} from '../../components/cards/CardActions';
import { StyledModal } from '../../components/Modal';
import ModalContainer from '../../components/ModalContainer';
import CardDelete from '../../components/cards/CardDelete';
import { PlaybookToDelete } from '../playbooks';
import { getPlaybookDataWithScore } from '../../graphql/custom-queries';
import { modalBackdropStyles } from '../../components/cards/CardSetModal';
import CreateCard from '../../components/forms/CreateCard';
import WorksheetListView from '../../components/cards/views/WorksheetListView';
import EditCardModal from '../../components/cards/EditCardModal';
import GhostCard from '../../components/cards/GhostCard';
import PlaybookCarousel from '../../components/playbooks/PlaybookCarousel';
import { getSubscriptions } from '../../helpers/subscriptions';
import useSnackbar from '../../hooks/useSnackbar';
import PptxGenJS from 'pptxgenjs';
import PptLayout from '../../components/playbooks/ppt/PptLayout';
import domtoimage from 'dom-to-image';
import DownloadConfirmation from '../../components/playbooks/ppt/DownloadConfirmation';
import { getUserOrganisation } from '../../helpers/auth';
import { downloadData } from 'aws-amplify/storage';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    MouseSensor,
    DragStartEvent,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { SortableItem } from '../../components/sortable';
import Logo from '../../components/profile/Logo';
import { renderComponentAsImage } from '../../helpers/ppt';

export enum MoveDirection {
    UP = 'up',
    DOWN = 'down',
}

const SinglePlaybook = () => {
    const bottomRef = useRef<HTMLDivElement>(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [previousOrdinal, setPreviousOrdinal] = useState<number | null>();
    const displayGhost = !localStorage.getItem(
        `displayGhost_${PageIdentifier.WORKSHEET}`
    );
    const [highestOrdinal, setHighestOrdinal] = useState<number | null>(null);
    const [playbook, setPlaybook] = useState<PlaybookDataResponse | null>(null);
    const [carouselIndex, setCarouselIndex] = useState<number | null>(null);
    const [pages, setPages] = useState<Array<PlaybookPageDataResponse>>([]);
    const [modalPlaybookPageProps, setModalPlaybookPageProps] =
        useState<PlaybookPageDataResponse | null>();
    const [showCreatePlaybookPageWizard, setShowCreatePlaybookPageWizard] =
        useState<PlaybookPageType | null>(null);
    const [editablePlaybookPage, setEditablePlaybookPage] =
        useState<PlaybookPageDataResponse | null>();
    const [items, setItems] = useState<CardComponentData[]>([]);
    const [grab, setGrab] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
    const [actionsMenuAnchor, setActionsMenuAnchor] =
        useState<HTMLElement | null>();
    const [showDeletePlaybookWizard, setShowDeletePlaybookWizard] =
        useState(false);
    const [showCreateWorksheetWizard, setShowCreateWorksheetWizard] =
        useState(false);
    const [playbookToDelete, setPlaybookToDelete] =
        useState<PlaybookToDelete | null>(null);
    const [worksheetToDelete, setWorksheetToDelete] =
        useState<PlaybookToDelete | null>(null);
    const [cardTypeId, setCardTypeId] = useState('');
    const [permissions, setPermissions] = useState<UserPermissions[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { loaded, setLoaded, users, user } = useContext(AppContext);
    const { viewType, setViewType } = useContext(AppContext);
    const [editPlaybook, setEditPlaybook] = useState<boolean>(false);
    const [activeCardPage, setActiveCardPage] = useState<CardPage>();
    const [singlePlaybook, setSinglePlaybook] = useState<
        Playbook | undefined
    >();
    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();

    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const [showDownloadConfirmationModal, setShowDownloadConfirmationModal] =
        useState(false);
    const [includeCardDetails, setIncludeCardDetails] = useState(false);
    const [slideElements, setSlideElements] =
        useState<HTMLCollectionOf<Element> | null>(
            document.getElementsByClassName('slide-element')
        );
    const [companyLogo, setCompanyLogo] = useState<string | null>(null);
    const [includeLogoInPPT, setIncludeLogoInPPT] = useState(false);
    const [organisationId, setOrganisationId] = useState<string>('');
    const theme = useTheme();

    const { id } = useParams();

    const client = generateClient();

    const handleClose = (PlaybookPage?: PlaybookPageDataResponse) => {
        handleModalClose();
    };

    // const cards: { [key: string]: Card } =
    //     playbook && playbook.cards ? JSON.parse(playbook?.cards) : [];

    const cards = [];

    const cardToCardComponentProps = (
        data: PlaybookPageDataResponse
    ): CardComponentData => {
        return {
            id: data.id,
            cardComponentType: CardComponentType.WORKSHEET,
            description: data.commentary,
            name: data.title ?? '',
            cardCategoryId: '',
            cardTypeId: '',
            cardCategoryName: '',
            cardTypeName: '',

            organisation: '',
            updatedAt: data.updatedAt ?? '',
            createdBy: '',
            owner: playbook?.owner,
            editors: playbook?.editors,
            createdAt: data.createdAt ?? '',
        };
    };

    const loadPlaybook = async () => {
        const playbookData = (await client.graphql({
            query: getPlaybookDataWithScore,
            variables: { playbookId: id },
        })) as { data: GetPlaybookDataQuery };

        setPlaybook(playbookData.data.getPlaybookData as PlaybookDataResponse);
        setPages(
            playbookData.data.getPlaybookData
                ?.pages as PlaybookPageDataResponse[]
        );
        setLoaded && setLoaded(true);
        if (!isFirstLoad && bottomRef?.current && !previousOrdinal) {
            bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }

        setIsFirstLoad(false);
    };

    useEffect(() => {
        if (!playbook) return;
        const getSinglePlaybook = async () => {
            const response = (await client.graphql({
                query: getPlaybook,
                variables: {
                    organisation: playbook.organisation,
                    id: playbook.id,
                },
            })) as { data: GetPlaybookQuery };

            if (response.data.getPlaybook) {
                const userPermissions = getCardSetPermissions(
                    user?.tokens?.idToken?.payload.sub ?? '',
                    response?.data?.getPlaybook as Playbook
                );
                setSinglePlaybook(response.data.getPlaybook as Playbook);
                setPermissions(userPermissions);
            }
        };
        if (user) {
            getSinglePlaybook();
        }
    }, [playbook, user]);

    useEffect(() => {
        loadPlaybook();
    }, []);

    useEffect(() => {
        const cardItems =
            pages?.map((page) => cardToCardComponentProps(page)) ?? [];

        setItems(cardItems);

        if (pages) {
            const ordinals = pages
                .map((page) => page.ordinal)
                .filter(
                    (ordinal) => ordinal !== null && ordinal !== undefined
                ) as number[];
            if (ordinals.length > 0) {
                const highest = Math.max(...ordinals);
                setHighestOrdinal(highest);
            }
        }
    }, [pages]);

    const handleModalClose = () => {
        setModalPlaybookPageProps(null);
        setEditablePlaybookPage(null);
        setPlaybookToDelete(null);
        setWorksheetToDelete(null);
        setShowCreatePlaybookPageWizard(null);
        setShowDeletePlaybookWizard(false);
        setEditMode(false);
    };
    const handlePlaybookModalClose = () => {
        setEditPlaybook(false);
        loadPlaybook();
        navigate(location.pathname);
    };

    const handlePageDelete = async (
        e: MouseEvent<HTMLLIElement>,
        id: string
    ) => {
        e.stopPropagation();

        setShowDeletePlaybookWizard(true);
        setWorksheetToDelete({
            id: id,
            organisation: playbook?.organisation ?? '',
        });
    };
    const removeElementByClassName = (className: string): void => {
        const elements = document.getElementsByClassName(className);
        while (elements.length > 0) {
            elements[0].parentNode?.removeChild(elements[0]);
        }
    };
    const confirmPageDelete = async (id: string, organisation: string) => {
        try {
            (await client.graphql({
                query: deletePlaybookPage,
                variables: {
                    input: {
                        organisation: organisation,
                        id: id,
                    },
                },
            })) as { data: DeletePlaybookPageMutation };
            setPages((prevPages) => prevPages.filter((page) => page.id !== id));
            showSnackbar({
                message: 'Worksheet deleted',
                severity: 'success',
            });
            setWorksheetToDelete(null);
            removeElementByClassName(id);
        } catch (err) {
            console.log(err);
            setPages((prevPages) => prevPages.filter((page) => page.id !== id));
            setWorksheetToDelete(null);
        }
        handleModalClose();
    };

    const getPageCards = (
        page: PlaybookPageDataResponse
    ): Card[] | undefined => {
        const cards = playbook
            ? playbook?.cards?.filter((card) =>
                  page.allCards?.includes(card?.id ?? 'null')
              )
            : [];

        const dedupedCards = cards?.filter(
            (value, index, self) =>
                index === self.findIndex((t) => t?.id === value?.id)
        );

        return dedupedCards as Card[];
    };

    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setMenuAnchor(e.currentTarget);
            setShowCreateWorksheetWizard(true);
        } else {
            setMenuAnchor(null);
        }
    };
    const setActionsMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setActionsMenuAnchor(e.currentTarget);
        } else {
            setActionsMenuAnchor(null);
        }
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const showCardTypePlaybookPageWizard = (cardTypeId: string) => {
        setShowCreatePlaybookPageWizard(PlaybookPageType.List);
        setCardTypeId(cardTypeId);
    };

    const isEditMode = (playbookPage: PlaybookPageDataResponse) => {
        return !!!playbookPage.allCards?.length;
    };

    const modalOpen =
        !!modalPlaybookPageProps ||
        !!showCreatePlaybookPageWizard ||
        !!editablePlaybookPage;

    const handleClick = (playbookId: string, cardPage?: CardPage) => {
        if (cardPage) {
            setActiveCardPage(cardPage);
        }
    };
    const handleDelete = (id: string) => {
        setShowDeletePlaybookWizard(true);
        setWorksheetToDelete({
            id: id,
            organisation: playbook?.organisation ?? '',
        });
    };

    const handleEdit = async (id: string) => {
        loadPlaybook();
        handleClose();
    };
    const handleRemove = () => {};

    const orderPages = async (id: string, direction: MoveDirection) => {
        let playbookPages = [...pages];
        let index = playbookPages.findIndex((page) => page.id === id);
        let ordinal = playbookPages[index].ordinal;
        let newOrdinal;
        let pageId;

        if (direction === MoveDirection.UP) {
            let prevOrdinal = playbookPages[index - 1].ordinal ?? 0;
            playbookPages[index].ordinal = prevOrdinal;

            playbookPages[index - 1].ordinal = ordinal;
            newOrdinal = prevOrdinal;
            pageId = playbookPages[index - 1].id;
        } else {
            let nextOrdinal = playbookPages[index + 1].ordinal ?? 0;
            playbookPages[index].ordinal = nextOrdinal;

            playbookPages[index + 1].ordinal = ordinal;
            newOrdinal = nextOrdinal;
            pageId = playbookPages[index + 1].id;
        }

        setPages(playbookPages);

        const pageQuery = `mutation UpdatePlaybookPage(
            $input1: UpdatePlaybookPageInput!
            $input2: UpdatePlaybookPageInput!
            $condition: ModelPlaybookPageConditionInput
          ) {
            playbook1: updatePlaybookPage(input: $input1, condition: $condition) {
              organisation
              id
            }
            playbook2: updatePlaybookPage(input: $input2, condition: $condition) {
                organisation
                id
              }
          }
          `;
        const res = (await client.graphql({
            query: pageQuery,
            variables: {
                input1: {
                    organisation: playbook?.organisation,
                    id: id,
                    ordinal: newOrdinal,
                },
                input2: {
                    organisation: playbook?.organisation,
                    id: pageId,
                    ordinal: ordinal,
                },
            },
        })) as { data: CreatePlaybookPageMutation };
    };
    const menuItems: CardActionItemProps[] = [];
    const handleSecondaryDelete = (id: string) => {
        if (id && playbook) {
            setShowDeletePlaybookWizard(true);
            setPlaybookToDelete({
                id: id,
                organisation: playbook.organisation,
            });
        }
    };
    const confirmDelete = async (id: string, organisation: string) => {
        try {
            (await client.graphql({
                query: deletePlaybook,
                variables: {
                    input: {
                        organisation,
                        id,
                    },
                },
            })) as { data: DeletePlaybookMutation };
            handleClose();
            navigate('/playbooks');
        } catch (err) {
            handleClose();
        }
    };
    const handleWorksheetClose = () => {
        setShowCreateWorksheetWizard(false);
    };

    const listConfigure = (id: string, edit?: boolean) => {
        const playbookPage = pages.find((page) => page.id === id);

        if (playbookPage) {
            if (edit && permissions.includes(UserPermissions.EDIT)) {
                setEditMode(true);
            }
            setEditablePlaybookPage(playbookPage);
        }
    };
    const handleSecondaryEdit = () => {
        setEditPlaybook(true);
        setActiveCardPage(CardPage.DETAILS);
    };
    const iconStyles = { width: 40, height: 40, display: 'inline' };

    const hasSource = (page: PlaybookPageDataResponse): boolean =>
        page.playbookPageType === PlaybookPageType.Title ||
        (page.allCards?.length ?? 0) > 0;

    useEffect(() => {
        if (users?.length) {
            const createSub = client
                .graphql({ query: subscriptions.onCreateNotification })
                .subscribe({
                    next: ({ data }) => {
                        getSubscriptions({
                            data,
                            setItems: setPages,
                            showSnackbar,
                            users: users,
                            createCard: false,
                            createPlaybook: false,
                            playbookId: id,
                        });
                    },
                    error: (error) => console.warn(error),
                });
            return () => createSub.unsubscribe();
        }
    }, [users]);

    const exportPlaybookToPPT = async () => {
        setDownloadInProgress(true);
        let pptx = new PptxGenJS();
        pptx.layout = 'LAYOUT_WIDE';
        const slideElements = document.getElementsByClassName('slide-element');
        const elementsArray = Array.from(slideElements);
        let slideIndex = 1;
        let progressElement = document.getElementById('progress');
        let progressTextElement = document.getElementById('progress-text');

        if (!progressTextElement) {
            progressTextElement = document.createElement('div');
            progressTextElement.id = 'progress-text';
            progressElement?.after(progressTextElement);
        }

        for (const element of elementsArray) {
            if (!(element instanceof HTMLElement)) {
                continue;
            }
            try {
                const image = await domtoimage.toPng(element, {
                    quality: 1,
                });

                let slide = pptx.addSlide();
                const adjustedWidth = 13.333 - 1.25 - 1;
                const adjustedHeight = 7.5 - 1 - 1;
                slide.addImage({
                    data: image,
                    x: 1.25,
                    y: 1,
                    w: adjustedWidth,
                    h: adjustedHeight,
                });
                slide.addText(`${slideIndex}/${elementsArray.length}`, {
                    x: '90%',
                    y: '95%',
                    fontSize: 12,
                });
                if (includeLogoInPPT) {
                    const logoImage = await renderComponentAsImage(
                        '250px',
                        <Logo thumbnail={false} logo={companyLogo} pptView />
                    );
                    slide.addImage({
                        data: logoImage,
                        x: '5%',
                        y: '90%',
                    });
                }
                slideIndex++;

                let progress = (slideIndex / elementsArray.length) * 100;
                progress = Math.min(progress, 100);
                if (progressElement) {
                    progressElement.style.width = `${progress}%`;
                }
                progressTextElement!.innerText = `${progress.toFixed(
                    0
                )}% completed`;
            } catch (error) {
                console.error('Error converting element to image:', error);
            }
        }
        pptx.writeFile({ fileName: `${playbook?.name}.pptx` }).then(() => {
            if (progressElement) {
                progressElement.style.width = `0%`;
            }
            progressTextElement!.innerText = '';
            setDownloadInProgress(false);
            setShowDownloadConfirmationModal(false);
        });
    };

    useEffect(() => {
        const fetchFile = async () => {
            const userOrganisation = await getUserOrganisation(user);
            setOrganisationId(userOrganisation);
            try {
                const downloadResult: any = await downloadData({
                    key: `uploaded/${userOrganisation}/${userOrganisation}`,
                }).result;
                let image = URL.createObjectURL(downloadResult.body);
                setCompanyLogo(image);
            } catch (error) {
                console.log('Error : ', error);
            }
        };
        if (user) {
            fetchFile();
        }
    }, [user]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 0,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = async (event: DragEndEvent) => {
        setGrab(false);
        const { active, over } = event;

        let playbookPages = [...pages];

        let ordinals = playbookPages.map(
            (playbookPage) => playbookPage.ordinal
        );

        if (!over || active.id === over.id) return;

        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);

        playbookPages = arrayMove(playbookPages, oldIndex, newIndex).map(
            (page, index) => {
                return { ...page, ordinal: ordinals[index] };
            }
        );

        setPages(playbookPages);

        let inputs = '';
        let playbookQueries = '';

        interface Query {
            [key: string]: {};
        }
        let variables: Query = {};

        playbookPages.forEach((page, index) => {
            inputs += `$input${index + 1}: UpdatePlaybookPageInput!`;
            playbookQueries += `playbook${
                index + 1
            }: updatePlaybookPage(input: $input${
                index + 1
            }, condition: $condition) {
                organisation
                id
              }`;

            variables[`input${index + 1}`] = {
                organisation: playbook?.organisation,
                id: page.id,
                ordinal: page.ordinal,
            };
        });

        const pageQuery = `mutation UpdatePlaybookPage(
            ${inputs}
            $condition: ModelPlaybookPageConditionInput
          ) {
           ${playbookQueries}
          }
          `;

        const res = (await client.graphql({
            query: pageQuery,
            variables: variables,
        })) as { data: CreatePlaybookPageMutation };
    };

    const handleDragStart = (event: DragStartEvent) => {
        setGrab(true);
    };

    return (
        <PlaybookContext.Provider value={{ handleClose, handlePageDelete }}>
            <header>
                <PageAppBar title={'Playbooks'}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            svg: {
                                width: '1.8rem',
                                height: '1.8rem',
                            },
                        }}
                    >
                        <IconButton
                            aria-label="Grid View"
                            sx={{
                                svg: {
                                    opacity:
                                        viewType === ViewType.GRID
                                            ? '1'
                                            : '0.5',
                                },
                            }}
                            onClick={() => setViewType(ViewType.GRID)}
                        >
                            <img
                                src={
                                    viewType === ViewType.GRID
                                        ? TileViewIconActive
                                        : TileViewIcon
                                }
                                style={iconStyles}
                            />
                        </IconButton>
                        <IconButton
                            aria-label="List View"
                            sx={{
                                svg: {
                                    opacity:
                                        viewType === ViewType.LIST
                                            ? '1'
                                            : '0.5',
                                },
                            }}
                            onClick={() => setViewType(ViewType.LIST)}
                        >
                            <img
                                src={
                                    viewType === ViewType.LIST
                                        ? ListViewIconActive
                                        : ListViewIcon
                                }
                                style={iconStyles}
                            />
                        </IconButton>
                    </Box>
                </PageAppBar>
                <Box
                    sx={{
                        background: theme.palette.background.default,
                        ml: '5px',
                        position: 'fixed',
                        zIndex: 1100,
                        width: 'calc(100% - 91px)',
                        height: '106px',
                    }}
                >
                    <Box
                        sx={{
                            paddingLeft: '110px',
                            paddingRight: '110px',
                            width: '100%',
                            margin: '0 auto',
                            display: 'flex',
                            maxWidth: '108rem',
                        }}
                    >
                        <Box
                            sx={{
                                py: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontFamily: 'Oswald',
                                        paddingBottom: '0.3rem',
                                    }}
                                >
                                    {playbook?.name}
                                </Typography>

                                <CardActions
                                    textColor={theme.palette.action.active}
                                    setMenu={setActionsMenu}
                                    expanded={true}
                                    items={menuItems}
                                    padding={0}
                                ></CardActions>
                            </Box>

                            {actionsMenuAnchor && playbook && (
                                <CardMenu
                                    permissions={permissions}
                                    anchor={actionsMenuAnchor}
                                    data={{
                                        organisation:
                                            playbook?.organisation || '',
                                        id: playbook?.id || '',
                                    }}
                                    handleSecondaryDelete={() =>
                                        handleSecondaryDelete &&
                                        handleSecondaryDelete(playbook.id)
                                    }
                                    handleSecondaryEdit={handleSecondaryEdit}
                                    setMenu={setActionsMenu}
                                />
                            )}
                            <StyledModal
                                key="delete-card-modal"
                                open={showDeletePlaybookWizard}
                                onClose={() =>
                                    setShowDeletePlaybookWizard(false)
                                }
                                disableEnforceFocus
                                sx={{
                                    zIndex: 1500,
                                }}
                            >
                                <Box>
                                    {showDeletePlaybookWizard &&
                                        (worksheetToDelete ||
                                            playbookToDelete) && (
                                            <ModalContainer
                                                sx={{ maxWidth: '40rem' }}
                                            >
                                                <CardDelete
                                                    id={
                                                        worksheetToDelete
                                                            ? worksheetToDelete?.id
                                                            : (playbookToDelete?.id as string)
                                                    }
                                                    organisation={
                                                        worksheetToDelete
                                                            ? worksheetToDelete?.organisation
                                                            : (playbookToDelete?.organisation as string)
                                                    }
                                                    toggleDeleteWizard={
                                                        setShowDeletePlaybookWizard
                                                    }
                                                    confirmDelete={
                                                        worksheetToDelete
                                                            ? confirmPageDelete
                                                            : confirmDelete
                                                    }
                                                    pageIdentifier={
                                                        worksheetToDelete
                                                            ? PageIdentifier.WORKSHEET
                                                            : PageIdentifier.PLAYBOOKS
                                                    }
                                                />
                                            </ModalContainer>
                                        )}
                                </Box>
                            </StyledModal>
                            <ButtonGroup>
                                {loaded && (
                                    <IconButton
                                        color="primary"
                                        title="Download as ppt"
                                        sx={{
                                            height: '56px',
                                            width: '56px',
                                            marginTop: '10px',
                                        }}
                                        onClick={() =>
                                            setShowDownloadConfirmationModal(
                                                true
                                            )
                                        }
                                    >
                                        <DownloadIcon
                                            width="30px"
                                            height="30px"
                                            fill={theme.palette.primary.main}
                                        />
                                    </IconButton>
                                )}
                                {permissions.includes(UserPermissions.EDIT) && (
                                    <IconButton
                                        color="primary"
                                        onClick={setMenu}
                                        title="Create playbook page"
                                        sx={{
                                            height: '56px',
                                            width: '56px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <AddIcon
                                            sx={{
                                                fontSize: '3rem',
                                                width: '40px',
                                                height: '40px',
                                            }}
                                        />
                                    </IconButton>
                                )}
                                <StyledModal
                                    key="modal"
                                    open={showCreateWorksheetWizard}
                                    onClose={handleWorksheetClose}
                                    sx={modalBackdropStyles}
                                >
                                    <Box>
                                        {!!showCreateWorksheetWizard &&
                                            playbook && (
                                                <ModalContainer
                                                    sx={{ maxWidth: '80rem' }}
                                                >
                                                    <CreateCard
                                                        handleClose={
                                                            handleWorksheetClose
                                                        }
                                                        pageIdentifier={
                                                            PageIdentifier.WORKSHEET
                                                        }
                                                        playbookId={playbook.id}
                                                        highestOrdinal={
                                                            highestOrdinal
                                                        }
                                                        pages={pages}
                                                        close={() =>
                                                            handleWorksheetClose()
                                                        }
                                                        previousOrdinal={
                                                            previousOrdinal
                                                        }
                                                        setPreviousOrdinal={
                                                            setPreviousOrdinal
                                                        }
                                                        loadPlaybook={
                                                            loadPlaybook
                                                        }
                                                        workbookIdsForPlaybook={
                                                            playbook?.workbooks
                                                        }
                                                    />
                                                </ModalContainer>
                                            )}
                                    </Box>
                                </StyledModal>
                            </ButtonGroup>
                            {editPlaybook &&
                                singlePlaybook &&
                                activeCardPage && (
                                    <EditCardModal
                                        open={editPlaybook}
                                        card={singlePlaybook}
                                        handleClose={handlePlaybookModalClose}
                                        data={singlePlaybook}
                                        handleClick={(
                                            cardSetId: string,
                                            cardPage?: CardPage
                                        ) => handleClick(cardSetId, cardPage)}
                                        activeCardPage={activeCardPage}
                                        refreshCard={loadPlaybook}
                                        handleDelete={handleSecondaryDelete}
                                    />
                                )}
                        </Box>
                    </Box>
                </Box>
            </header>
            <Box
                sx={{
                    p: 2,
                    pt: '120px',
                    px: 8,
                    maxWidth: '103rem',
                    m: '0 auto',
                    marginTop: '47px',
                }}
            >
                <AnimatePresence>
                    <CardContext.Provider
                        value={{
                            handleClick,
                            handleClose,
                            handleDelete,
                            handleEdit,
                            handleRemove,
                            items,
                        }}
                    >
                        {viewType === ViewType.GRID ? (
                            <LayoutGroup>
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragStart={handleDragStart}
                                    onDragEnd={handleDragEnd}
                                >
                                    <SortableContext items={items}>
                                        <Grid2 container spacing={4} sm={12}>
                                            {pages &&
                                                pages
                                                    .sort(
                                                        (a, b) =>
                                                            (a.ordinal ?? 1) -
                                                            (b.ordinal ?? 1)
                                                    )
                                                    .map(
                                                        (
                                                            playbookPage,
                                                            index
                                                        ) => (
                                                            <SortableItem
                                                                id={
                                                                    playbookPage.id
                                                                }
                                                            >
                                                                <PlaybookPageComponent
                                                                    setPreviousOrdinal={
                                                                        setPreviousOrdinal
                                                                    }
                                                                    previousOrdinal={
                                                                        previousOrdinal
                                                                    }
                                                                    setShowCreateWorksheetWizard={
                                                                        setShowCreateWorksheetWizard
                                                                    }
                                                                    grab={grab}
                                                                    logo={
                                                                        companyLogo
                                                                    }
                                                                    itemSelected={
                                                                        !!editablePlaybookPage
                                                                    }
                                                                    permissions={
                                                                        permissions
                                                                    }
                                                                    cards={
                                                                        !editablePlaybookPage
                                                                            ? getPageCards(
                                                                                  playbookPage
                                                                              )
                                                                            : []
                                                                    }
                                                                    page={
                                                                        playbookPage
                                                                    }
                                                                    setEditMode={(
                                                                        editMode
                                                                    ) => {
                                                                        setEditMode(
                                                                            editMode
                                                                        );
                                                                        setEditablePlaybookPage(
                                                                            playbookPage
                                                                        );
                                                                    }}
                                                                    onClick={() => {
                                                                        isEditMode(
                                                                            playbookPage
                                                                        ) &&
                                                                            permissions.includes(
                                                                                UserPermissions.EDIT
                                                                            ) &&
                                                                            setEditMode(
                                                                                true
                                                                            );
                                                                        hasSource(
                                                                            playbookPage
                                                                        ) ||
                                                                        !permissions.includes(
                                                                            UserPermissions.EDIT
                                                                        )
                                                                            ? setCarouselIndex(
                                                                                  index
                                                                              )
                                                                            : setEditablePlaybookPage(
                                                                                  playbookPage
                                                                              );
                                                                    }}
                                                                    orderPages={
                                                                        orderPages
                                                                    }
                                                                    {...(index ===
                                                                        0 && {
                                                                        first: true,
                                                                    })}
                                                                    {...(index ===
                                                                        pages.length -
                                                                            1 && {
                                                                        last: true,
                                                                    })}
                                                                    index={
                                                                        index
                                                                    }
                                                                    hoveredIndex={
                                                                        hoveredIndex
                                                                    }
                                                                    setHoveredIndex={
                                                                        setHoveredIndex
                                                                    }
                                                                />
                                                            </SortableItem>
                                                        )
                                                    )}
                                            {pages && (
                                                <Grid2
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={4}
                                                    xl={3}
                                                >
                                                    {loaded &&
                                                        displayGhost &&
                                                        permissions.includes(
                                                            UserPermissions.EDIT
                                                        ) && (
                                                            <GhostCard
                                                                pageIdentifier={
                                                                    PageIdentifier.WORKSHEET
                                                                }
                                                            />
                                                        )}
                                                </Grid2>
                                            )}
                                        </Grid2>
                                    </SortableContext>
                                </DndContext>
                            </LayoutGroup>
                        ) : (
                            <WorksheetListView
                                orderPages={orderPages}
                                items={pages
                                    .sort(
                                        (a, b) =>
                                            (a.ordinal ?? 1) - (b.ordinal ?? 1)
                                    )
                                    .map((page, index) => {
                                        return {
                                            ...page,
                                            first: index === 0 ? true : false,
                                            last:
                                                index === pages.length - 1
                                                    ? true
                                                    : false,
                                        };
                                    })}
                                permissions={permissions}
                                handleDelete={handleDelete}
                                configure={listConfigure}
                            />
                        )}

                        <Box
                            sx={{
                                width: '100vw',
                                height: 'auto',
                                overflow: 'auto',
                            }}
                        >
                            <Dialog
                                PaperProps={{
                                    sx: {
                                        borderRadius: 6,
                                        maxWidth: editMode ? '90rem' : '60rem',
                                        width: editMode
                                            ? '90rem'
                                            : !editablePlaybookPage?.cards
                                            ? '100%'
                                            : '60rem',
                                        backgroundColor: 'background.default',
                                        height: editMode ? '100vh' : 'auto',
                                    },
                                }}
                                scroll={!!editMode ? 'paper' : 'body'}
                                key="modal"
                                open={modalOpen}
                                onClose={handleModalClose}
                                aria-labelledby=""
                                aria-describedby=""
                            >
                                {(showCreatePlaybookPageWizard ||
                                    !!editablePlaybookPage) && (
                                    <>
                                        {!editMode && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '2rem',
                                                    right: '2rem',
                                                    zIndex: 2,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {!editablePlaybookPage && (
                                                    <Button
                                                        sx={{ mr: 1 }}
                                                        onClick={() =>
                                                            setEditMode(
                                                                !editMode
                                                            )
                                                        }
                                                        endIcon={
                                                            <ModeEditIcon />
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                )}
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={handleModalClose}
                                                    sx={{
                                                        color: (theme) =>
                                                            theme.palette
                                                                .grey[600],
                                                        mt: '-2rem',
                                                        mr: '-2rem',
                                                    }}
                                                    size="large"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                        {playbook && (
                                            <EditablePlaybookPage
                                                logo={companyLogo}
                                                permissions={permissions}
                                                initEditMode={
                                                    !!showCreatePlaybookPageWizard
                                                }
                                                editMode={editMode}
                                                playbook={playbook}
                                                cards={[]}
                                                setEditMode={(editMode) => {
                                                    setEditMode;
                                                }}
                                                page={
                                                    editablePlaybookPage ||
                                                    undefined
                                                }
                                                playbookPageType={
                                                    !editablePlaybookPage
                                                        ? showCreatePlaybookPageWizard ||
                                                          undefined
                                                        : undefined
                                                }
                                                playbookPageCardTypeId={
                                                    cardTypeId
                                                }
                                                onUpdate={handleEdit}
                                                itemSelected={
                                                    !!editablePlaybookPage
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </Dialog>
                            {playbook && carouselIndex !== null && (
                                <PlaybookCarousel
                                    index={carouselIndex}
                                    closeModal={() => setCarouselIndex(null)}
                                    pages={pages}
                                    playbook={playbook}
                                    logo={companyLogo}
                                />
                            )}
                        </Box>
                    </CardContext.Provider>
                </AnimatePresence>
                <StyledModal
                    key="modal"
                    open={showDownloadConfirmationModal}
                    onClose={() => setShowDownloadConfirmationModal(false)}
                    sx={modalBackdropStyles}
                >
                    <Box>
                        <ModalContainer sx={{ maxWidth: '35rem', p: 2 }}>
                            <DownloadConfirmation
                                includeCardDetails={includeCardDetails}
                                setIncludeCardDetails={setIncludeCardDetails}
                                slideElements={slideElements}
                                downloadInProgress={downloadInProgress}
                                setShowDownloadConfirmationModal={
                                    setShowDownloadConfirmationModal
                                }
                                exportPlaybookToPPT={exportPlaybookToPPT}
                                setIncludeLogoInPPT={setIncludeLogoInPPT}
                                includeLogoInPPT={includeLogoInPPT}
                            />
                        </ModalContainer>
                    </Box>
                </StyledModal>
            </Box>
            {loaded && (
                <PptLayout
                    playbook={playbook}
                    includeCardDetails={includeCardDetails}
                    logo={companyLogo}
                />
            )}
            <div ref={bottomRef} />
        </PlaybookContext.Provider>
    );
};

export default SinglePlaybook;
