import { Box } from '@mui/material';
import React from 'react';
interface LogoProps {
    thumbnail: boolean;
    logo: string | null;
    pptView: boolean | undefined;
    height?: string | null;
    width?: string | null;
}
const Logo = ({ thumbnail, height, logo, pptView, width }: LogoProps) => {
    return (
        <Box
            sx={{
                width: width
                    ? width
                    : thumbnail && !pptView
                    ? '50px'
                    : pptView
                    ? '250px'
                    : '150px',
                height: height
                    ? height
                    : thumbnail && !pptView
                    ? '50px'
                    : pptView
                    ? '250px'
                    : '150px',
                background: `url(${logo})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPositionY: 'center',
            }}
        />
    );
};

export default Logo;
