import { useState, useEffect } from 'react';
import { AppContext } from '../components/contexts';
import { SortActions, PageIdentifier } from '../components/cards/cardTypes';

const useSortCards = (pageIdentifier: PageIdentifier) => {
    const defaultSortAction = SortActions.LAST_MODIFIED_RECENT_FIRST;
    const defaultSortDirection = 'DESC';

    const sortActionFromStorage = localStorage.getItem(
        `selectedSortAction_${pageIdentifier}`
    );
    const sortDirectionFromStorage = localStorage.getItem(
        `selectedSortDirection_${pageIdentifier}`
    );

    const sortAction =
        sortActionFromStorage === null
            ? defaultSortAction
            : (sortActionFromStorage as SortActions);
    const sortDirection =
        sortDirectionFromStorage === null
            ? defaultSortDirection
            : (sortDirectionFromStorage as 'ASC' | 'DESC');

    return [sortAction, sortDirection];
};

export default useSortCards;
