import React, { useContext } from 'react';
import { AppContext } from '../contexts';
import styled from '@emotion/styled';
import AddIconMaterial from '@mui/icons-material/Add';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { ReactComponent as AddCardToSetSVG } from '../../assets/icons/AddExistingCard.svg';
import { StyledEmptyItem } from '../layouts/GridLayout';
import { PageIdentifier } from './cardTypes';
import { useNavigate } from 'react-router-dom';

interface GhostCardProps {
    pageIdentifier: PageIdentifier;
    action?: (() => void) | undefined;
    height?: string;
    hide?: boolean;
    background?: string;
}
interface CardTextsContent {
    title: string;
    texts: JSX.Element[];
}
export const AddIcon = () => (
    <AddIconMaterial
        sx={{
            width: '1.2rem',
            height: '1.2rem',
            color: 'grey',
            mb: '-5px',
        }}
    />
);
export const AddCardToSet = () => (
    <AddCardToSetSVG
        data-testid="AddCardToSetSVG"
        style={{
            width: '1.2rem',
            height: '1.2rem',
            color: 'grey',
            marginBottom: '-3px',
        }}
    />
);
export const cardTexts: { [key in PageIdentifier]?: CardTextsContent } = {
    [PageIdentifier.CARDS]: {
        title: 'Create a card',
        texts: [
            <>
                You can create a new card or add an existing one by clicking{' '}
                <AddIcon />
            </>,
            <>
                Then click on the new card to add details, scores and members
                who can also update the card
            </>,
        ],
    },
    [PageIdentifier.CARD_SETS]: {
        title: 'Create a card set',
        texts: [
            <>
                You can then create new cards in the card set or add existing
                cards to it
            </>,
        ],
    },
    [PageIdentifier.CARD_SET_INSIDE]: {
        title: 'Build your card set',
        texts: [
            <>
                You can create a new card by clicking <AddIcon />
            </>,
            <>
                You can also click <AddCardToSet /> to add existing cards to the
                card set
            </>,
            <>
                Any new cards, or existing cards to be added, will appear here
                in the card set
            </>,
        ],
    },
    [PageIdentifier.WORKBOOKS]: {
        title: 'Create a workbook',
        texts: [
            <>
                You can create a workbook by clicking <AddIcon /> and optionally
                pick a worksheet to get started
            </>,
            <>
                You can then create cards straight into the workbook or add
                existing cards
            </>,
        ],
    },
    [PageIdentifier.WORKBOOK_INSIDE]: {
        title: 'Build your workbook',
        texts: [
            <>
                You can create a new worksheet by clicking <AddIcon />
            </>,

            <>As you add worksheets, new worksheets will appear here</>,
        ],
    },
    [PageIdentifier.PLAYBOOKS]: {
        title: 'Create a playbook',
        texts: [
            <>
                You can create a new playbook by clicking <AddIcon />
            </>,
            <>You can then create worksheets in the playbook</>,
        ],
    },
    [PageIdentifier.WORKSHEET]: {
        title: 'Create a playbook page',
        texts: [
            <>
                You can add a new playbook page by clicking <AddIcon />
            </>,
            <>
                Once created, click on it to connect it to your cards or
                workbooks to pull in the data you need
            </>,
        ],
    },
};

const GhostCard = ({
    pageIdentifier,
    action,
    height,
    hide,
    background,
}: GhostCardProps) => {
    const { showIllustrations } = useContext(AppContext);
    const cardContent = cardTexts[pageIdentifier] as
        | { title: string; texts: string[] }
        | undefined;
    const navigate = useNavigate();
    const theme = useTheme();
    const handleHideGhost = () => {
        localStorage.setItem(`displayGhost_${pageIdentifier}`, 'hide');
        navigate(location.pathname);
    };
    return (
        <StyledEmptyItem
            height={
                height
                    ? height
                    : showIllustrations &&
                      pageIdentifier !== PageIdentifier.WORKSHEET
                    ? '29rem'
                    : pageIdentifier === PageIdentifier.WORKSHEET
                    ? '28.5rem'
                    : '18rem'
            }
            onClick={() => action && action()}
            sx={{
                cursor: 'pointer',
                background:
                    theme.palette.mode === 'dark' ? '#333333' : '#dddddd',
            }}
        >
            <Typography
                variant="h5"
                sx={{
                    mb: '30px',
                    color: theme.palette.text.primary,
                    fontSize: '1.2rem',
                }}
            >
                {cardContent?.title}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {cardContent?.texts &&
                    cardContent?.texts.map((text: string, index: number) => (
                        <Typography
                            variant="body2"
                            sx={{ my: 1, color: theme.palette.text.primary }}
                            key={index}
                        >
                            {text}
                        </Typography>
                    ))}
            </Box>
            {!hide && (
                <Typography
                    variant="body1"
                    sx={{
                        alignSelf: 'flex-end',
                        mt: 'auto',
                        color: theme.palette.primary.main,
                        cursor: 'pointer',
                    }}
                    onClick={handleHideGhost}
                >
                    Hide
                </Typography>
            )}
        </StyledEmptyItem>
    );
};

export default GhostCard;
