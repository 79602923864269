import { useContext, useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';

import { Alert, AlertProps, Box, Snackbar } from '@mui/material';
import { StyledModal } from '../components/Modal';
import ModalContainer from '../components/ModalContainer';
import CreateCardSet from '../components/forms/CreateCardSet/index';
import { CardContext } from '../components/cards/context';
import CardTypeLayout from '../components/cards/views/CardTypeLayout';
import {
    CardComponentData,
    CardComponentType,
    CardPage,
    SortActions,
    PageIdentifier,
    ViewType,
    sortFieldToResponseMap,
} from '../components/cards/cardTypes';
import useSnackbar from '../hooks/useSnackbar';
import {
    CardSet,
    CardSetType,
    CreateCardSetMutation,
    DeleteCardSetBasicMutation,
    GetCardSetQuery,
} from '../API';
import { getCardSet } from '../graphql/queries';
import CardDelete from '../components/cards/CardDelete';
import { deleteCardSetBasic } from '../graphql/custom-mutations';
import * as subscriptions from '../graphql/subscriptions';
import CardInfo from '../components/cards/CardInfo';
import StyledCardContent from '../components/cards/CardContent';
import CardSetComponent from '../components/cards/CardSetComponent';
import CardDetails from '../components/cards/CardDetails';
import CardRelationships from '../components/cards/CardRelationships';
import useIconState from '../hooks/useIconStates';
import { AppContext } from '../components/contexts';
import { copyCardSet, createCardSet } from '../graphql/mutations';
import { queryData } from '../actions/QueryData';
import useFilterCards from '../hooks/useFilterCards';
import { handleCopyCardSet } from '../actions/CardSets';
import useSortCards from '../hooks/useSort';
import { getUserOrganisation } from '../helpers/auth';
import { useNavigate } from 'react-router-dom';
import { getSubscriptions } from '../helpers/subscriptions';
import CardActivity from '../components/cards/CardActivity';
import CreatePlaybook from '../components/forms/CreatePlaybook';
import { Workbook } from './workbooks';
import DOMPurify from 'dompurify';
import { getCardSetsBasic } from '../graphql/custom-queries';

interface CardSetsProps {
    cardSets?: CardSet[];
}
interface CardSetToDelete {
    id: string;
    organisation: string;
}

export const cardSetToCardComponentProps = (
    data: CardSet
): CardComponentData => {
    return {
        id: data.id,
        description: data.description,
        name: data.name,
        cardCategoryId: data.cardSetToCardCategoryId ?? 'cardset',
        cardCategoryName: data.toCardCategory?.name ?? 'cardset',
        cardComponentType: CardComponentType.CARD_SET,
        cardTypeId: data.toCardType?.id || 'cardset',
        cardTypeName: data.toCardType?.name || 'Varied',
        scoreName: 'cards',
        scoreValue: data.counts?.cards ?? 0,
        organisation: data.organisation,
        link: `/card-sets/${data.id}`,
        updatedAt: data.updatedAt,
        owner: data.owner,
        createdAt: data.createdAt,
        editors: data.editors,
        orgEdit: data.orgEdit,
    };
};

interface MessageResponseProps {
    item?: {
        name: string;
    };
    message: string;
    props: AlertProps;
}

const CardSets = () => {
    const { applyFilter, applySort, setLoaded, viewType, users, user } =
        useContext(AppContext);
    const [activeCardId, setActiveCardId] = useState<string | null>();
    const [showCreateCardSetForm, setShowCreateCardSetForm] = useState(false);
    const [showDeleteCardSetWizard, setShowDeleteCardSetWizard] =
        useState(false);
    const [activeCardPage, setActiveCardPage] = useState<CardPage>();
    const [editableCardSet, setEditableCardSet] = useState<CardSet>();
    const [cardSetToDelete, setCardSetToDelete] =
        useState<CardSetToDelete | null>(null);
    const [cardSetsObject, setCardSetsObject] = useState<{
        [key: string]: CardSet;
    }>({});
    const [cardSets, setCardSets] = useState<CardSet[]>([]);
    const [activeIcons, setActiveIcons] = useIconState('CardSetsControls');
    const [cardSortDirection, setCardSortDirection] = useState<
        'ASC' | 'DESC' | null
    >();
    const [cardSortField, setCardSortField] = useState<SortActions | null>();
    const [filterCriteria, setFilterCriteria] = useFilterCards(
        `filter_${PageIdentifier.CARD_SETS}`
    );
    const client = generateClient();
    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState<string | null>('');
    const [sortAction, sortDirection] = useSortCards(PageIdentifier.CARD_SETS);
    useEffect(() => {
        setCardSortField(sortAction as SortActions);
        setCardSortDirection(sortDirection as 'ASC' | 'DESC');
    }, [applySort]);

    const fetchCardSets = async (initialLoad?: boolean) => {
        if (!cardSortDirection || !cardSortField) return;
        setLoading(true);
        try {
            const { data, nextToken } = await queryData(
                initialLoad ?? false,
                cardSortDirection,
                token,
                activeIcons,
                getCardSetsBasic,
                sortFieldToResponseMap[cardSortField],
                'getCardSets',
                filterCriteria,
                setActiveIcons,
                '',
                user
            );

            if (initialLoad) {
                setCardSets(data as CardSet[]);
            } else {
                setCardSets([...cardSets, ...(data as CardSet[])]);
            }

            setLoading(false);
            setToken(nextToken);

            setLoaded && setLoaded(true);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        setToken('');
        if (user) {
            fetchCardSets(true);
        }
    }, [activeIcons, cardSortDirection, cardSortField, applyFilter, user]);

    useEffect(() => {
        const updatedCardSetsObject = { ...cardSetsObject };
        cardSets?.forEach(
            (cardSet) => (updatedCardSetsObject[cardSet.id] = cardSet)
        );

        setCardSetsObject(updatedCardSetsObject);
    }, [cardSets]);

    const onCreateCardSet = (cardSet?: string) => {
        handleClose();
        if (cardSet) {
            showSnackbar({
                message: 'Card set created successfully',
                severity: 'success',
            });
        }
    };

    const handleClose = () => {
        setActiveCardId(null);
        setShowCreateCardSetForm(false);
        setEditableCardSet(undefined);
    };

    const handleEdit = (cardSetId: string) => {
        setEditableCardSet(cardSetsObject[cardSetId]);
    };

    const handleListViewEdit = (cardSetId: string, cardPage?: CardPage) => {
        if (cardPage) {
            if (!activeCardId || (activeCardId && cardSetId !== activeCardId)) {
                setActiveCardId(cardSetId);
            }
            setActiveCardPage(cardPage);
        }
    };
    const handleDelete = async (id: string, organisation: string) => {
        setShowDeleteCardSetWizard(true);
        setCardSetToDelete({
            id: id,
            organisation: organisation,
        });
    };

    const handleClick = (cardId: string, cardPage?: CardPage) => {
        if (viewType === ViewType.LIST && !activeCardId) {
            navigate(`/card-sets/${cardId}`);
        } else if (cardPage) {
            if (!activeCardId || (activeCardId && cardId !== activeCardId)) {
                setActiveCardId(cardId);
            }
            setActiveCardPage(cardPage);
        }
    };

    const confirmDelete = async (id: string, organisation: string) => {
        try {
            (await client.graphql({
                query: deleteCardSetBasic,
                variables: {
                    input: {
                        organisation,
                        id,
                    },
                },
            })) as { data: DeleteCardSetBasicMutation };
            setCardSets(
                (prevItems) =>
                    prevItems && prevItems.filter((item) => item.id !== id)
            );
            showSnackbar({
                message: 'Card set deleted',
                severity: 'info',
            });
            handleClose();
        } catch (err) {
            handleClose();
            showSnackbar({
                message: 'Error while removing card set',
                severity: 'error',
            });
        }
        setShowDeleteCardSetWizard(false);
    };

    const closeModal = () => {
        setActiveCardId(null);
    };

    const refreshCardSet = async (id: string, organisation: string) => {
        const response = (await client.graphql({
            query: getCardSet,
            variables: {
                id: id,
                organisation: organisation,
            },
        })) as { data: GetCardSetQuery };

        const card = response.data.getCardSet;
        const items = [...(cardSets ?? [])];

        const index = items.findIndex((item) => item.id === id);

        if (!!card && index !== -1) {
            items[index] = card as CardSet;
            setCardSets(items);
        }
    };
    const handleCopy = async (cardSetId: string) => {
        const organisation = getUserOrganisation(user);
        try {
            const id = await handleCopyCardSet(cardSetId);

            const cardSet = (await client.graphql({
                query: getCardSet,
                variables: {
                    organisation: organisation ?? '',
                    id: id ?? '',
                },
            })) as { data: GetCardSetQuery };
            const items = [...(cardSets ?? [])];
            items.unshift(cardSet.data.getCardSet as CardSet);
            setCardSets(items);
            showSnackbar({
                message: 'Card set copied',
                severity: 'success',
            });
        } catch (err) {
            console.log(err);
            showSnackbar({
                message: 'Error while copying the card set',
                severity: 'error',
            });
        }
    };

    useEffect(() => {
        if (users?.length) {
            const createSub = client
                .graphql({ query: subscriptions.onCreateNotification })
                .subscribe({
                    next: ({ data }) => {
                        getSubscriptions({
                            data,
                            setItems: setCardSets,
                            showSnackbar,
                            users: users,
                            createCard: false,
                            location: location,
                            createCardSet: true,
                            filterCriteria: filterCriteria,
                        });
                    },
                    error: (error) => console.warn(error),
                });
            return () => createSub.unsubscribe();
        }
    }, [users]);
    const createWorkbook = async (data: Workbook) => {
        const sanitizedData = {
            name: DOMPurify.sanitize(data.name!) || '',
            description: DOMPurify.sanitize(data.description!) || '',
            organisation: data?.organisation || 'x',
            type: 'CS' as CardSetType,
        };
        const query = createCardSet;

        const res = (await client.graphql({
            query,
            variables: {
                input: sanitizedData,
            },
        })) as { data: CreateCardSetMutation };
    };
    return (
        <>
            <CardContext.Provider
                value={{
                    emptyAction: () => setShowCreateCardSetForm(true),
                    handleClick,
                    handleClose,
                    handleDelete,
                    handleEdit,
                    handleCopy,
                    items:
                        cardSets?.map((cardSet) =>
                            cardSetToCardComponentProps(cardSet) 
                        ) || [],
                }}
            >
                <CardTypeLayout
                    headerTitle="Card sets"
                    viewTitle="All card sets"
                    action={() => setShowCreateCardSetForm(true)}
                    actionLabel="Create card set"
                    viewOptions={{
                        [ViewType.GRID]: true,
                        [ViewType.LIST]: true,
                    }}
                    activeIcons={activeIcons}
                    setActiveIcons={setActiveIcons}
                    pageIdentifier={PageIdentifier.CARD_SETS}
                    onLoadMore={fetchCardSets}
                    loading={loading}
                    token={token}
                    filterCriteria={filterCriteria}
                    setFilterCriteria={setFilterCriteria}
                    handleEdit={handleEdit}
                    handleListViewEdit={handleListViewEdit}
                />
            </CardContext.Provider>

            <Snackbar
                open={isOpen}
                autoHideDuration={duration}
                onClose={closeSnackbar}
                sx={{ zIndex: 1600 }}
            >
                <Alert
                    variant="filled"
                    severity={severity}
                    sx={{ width: '100%' }}
                    onClose={closeSnackbar}
                >
                    {message}
                </Alert>
            </Snackbar>

            <StyledModal
                key="modal"
                open={
                    !!activeCardId || showCreateCardSetForm || !!editableCardSet
                }
                onClose={handleClose}
                aria-labelledby=""
                aria-describedby=""
            >
                <Box>
                    {showCreateCardSetForm && !!!editableCardSet && (
                        <ModalContainer sx={{ maxWidth: '35rem' }}>
                            <CreateCardSet handleClose={onCreateCardSet} />
                        </ModalContainer>
                    )}
                    {!!editableCardSet && (
                        <ModalContainer sx={{ maxWidth: '75rem', p: 4 }}>
                            <CreateCardSet
                                cardSet={editableCardSet}
                                handleClose={onCreateCardSet}
                            />
                        </ModalContainer>
                    )}
                    {activeCardId && (
                        <Box>
                            {!!activeCardPage && (
                                <ModalContainer sx={{ maxWidth: '48rem' }}>
                                    <CardSetComponent
                                        data={cardSetsObject[activeCardId]}
                                        handleClose={handleClose}
                                        showPage={activeCardPage}
                                        onClick={(
                                            cardId: string,
                                            cardPage?: CardPage
                                        ) => handleClick(cardId, cardPage)}
                                        handleDelete={handleDelete}
                                        handleCopy={handleCopy}
                                        expanded
                                        multiType={
                                            !cardSetsObject[activeCardId]
                                                .cardSetToCardTypeId
                                        }
                                    >
                                        <StyledCardContent expanded={true}>
                                            {
                                                {
                                                    [CardPage.DETAILS]: (
                                                        <CardDetails
                                                            card={
                                                                cardSetsObject[
                                                                    activeCardId
                                                                ]
                                                            }
                                                            onUpdate={
                                                                refreshCardSet
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.SCORES]: (
                                                        <Box></Box>
                                                    ),
                                                    [CardPage.ACTIVITY]: (
                                                        <CardActivity
                                                            card={
                                                                cardSetsObject[
                                                                    activeCardId
                                                                ]
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.INFO]: (
                                                        <CardInfo
                                                            card={
                                                                cardSetsObject[
                                                                    activeCardId
                                                                ]
                                                            }
                                                            onUpdate={
                                                                refreshCardSet
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.RELATIONSHIPS]: (
                                                        <CardRelationships
                                                            card={
                                                                cardSetsObject[
                                                                    activeCardId
                                                                ]
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.COMMENTS]: (
                                                        <Box></Box>
                                                    ),
                                                }[activeCardPage]
                                            }
                                        </StyledCardContent>
                                    </CardSetComponent>
                                </ModalContainer>
                            )}
                        </Box>
                    )}
                </Box>
            </StyledModal>
            <StyledModal
                key="delete-card-set-modal"
                open={showDeleteCardSetWizard}
                onClose={() => setShowDeleteCardSetWizard(false)}
                disableEnforceFocus
                sx={{
                    zIndex: 1500,
                }}
            >
                <Box>
                    {showDeleteCardSetWizard && (
                        <ModalContainer sx={{ maxWidth: '40rem' }}>
                            {cardSetToDelete && (
                                <CardDelete
                                    id={cardSetToDelete.id}
                                    organisation={cardSetToDelete.organisation}
                                    toggleDeleteWizard={
                                        setShowDeleteCardSetWizard
                                    }
                                    confirmDelete={confirmDelete}
                                    pageIdentifier={PageIdentifier.CARD_SETS}
                                />
                            )}
                        </ModalContainer>
                    )}
                </Box>
            </StyledModal>
        </>
    );
};

export default CardSets;
