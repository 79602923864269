import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { ReactComponent as HelpIcon } from '../../../assets/icons/Help.svg';
import { ReactComponent as DetailsIcon } from '../../../assets/icons/Details.svg';

interface DownloadConfirmationProps {
    includeCardDetails: boolean;
    setIncludeCardDetails: React.Dispatch<React.SetStateAction<boolean>>;
    slideElements: HTMLCollectionOf<Element> | null | undefined;
    downloadInProgress: boolean;
    setShowDownloadConfirmationModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    exportPlaybookToPPT: () => Promise<void>;
    setIncludeLogoInPPT: React.Dispatch<React.SetStateAction<boolean>>;
    includeLogoInPPT: boolean;
}

const DownloadConfirmation = ({
    includeCardDetails,
    setIncludeCardDetails,
    slideElements,
    downloadInProgress,
    setShowDownloadConfirmationModal,
    exportPlaybookToPPT,
    includeLogoInPPT,
    setIncludeLogoInPPT,
}: DownloadConfirmationProps) => {
    const checkboxHelpText =
        'Individual cards will print 1 per slide and will increase the size  and download time for your presentation.';
    const pptHelpText =
        'Have you considered presenting your playbook in carousel mode direct from the application? Just click on a worksheet to try it out! If you really need it out in power point, be aware there may be some format changes.';
    const [slideCount, setSlideCount] = useState(0);
    const [estimatedTime, setEstimatedTime] = useState(0);
    const theme = useTheme();
    useEffect(() => {
        const updateSlideElements = () => {
            const slideElements =
                document.getElementsByClassName('slide-element');
            setSlideCount(slideElements.length);
            let timeInMinutes = Math.max(
                1,
                Math.round((slideElements.length * 1.5) / 60)
            );
            setEstimatedTime(timeInMinutes);
        };
        updateSlideElements();
    }, [includeCardDetails]);
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    {`Download playpook as PPT`}
                </Typography>
                <Tooltip
                    title={
                        <Typography variant="body2">{pptHelpText}</Typography>
                    }
                    placement="right"
                >
                    <Box sx={{ cursor: 'pointer' }} data-testid="details-icon">
                        <DetailsIcon width="30px" height="30px" color="grey" />
                    </Box>
                </Tooltip>
            </Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                Number of slides :{' '}
                <span style={{ fontWeight: 'normal' }}>{slideCount}</span>
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: -1.2 }}>
                <Checkbox
                    onChange={() => setIncludeCardDetails(!includeCardDetails)}
                    checked={includeCardDetails}
                    size={'small'}
                    data-testid="individual-cards"
                />
                <Typography variant="body1">
                    Include individual cards
                </Typography>
                <Tooltip
                    title={
                        <Typography variant="body2">
                            {checkboxHelpText}
                        </Typography>
                    }
                    placement="right"
                >
                    <Box
                        sx={{ ml: 0.5, mt: 0.5, cursor: 'pointer' }}
                        data-testid="help-icon"
                    >
                        <HelpIcon width="22px" height="22px" color="grey" />
                    </Box>
                </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: -1.2 }}>
                <Checkbox
                    onChange={() => setIncludeLogoInPPT(!includeLogoInPPT)}
                    checked={includeLogoInPPT}
                    size={'small'}
                />
                <Typography variant="body1">Include company logo</Typography>
            </Box>

            <Box
                id="progress"
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    height: '8px',
                    borderRadius: '8px',
                    width: 0,
                    mt: 2,
                    mb: 1,
                }}
            />
            <Typography id="progress-text" variant="body2"></Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    gap: 2,
                    alignSelf: 'flex-end',
                }}
            >
                <Button
                    onClick={() => setShowDownloadConfirmationModal(false)}
                    variant="outlined"
                    sx={{
                        mt: 2,
                        width: 'fit-content',
                        borderRadius: 2,
                        px: 4,
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={exportPlaybookToPPT}
                    variant="contained"
                    sx={{
                        mt: 2,
                        width: 'fit-content',
                        borderRadius: 2,
                        color: 'error.contrastText',
                    }}
                >
                    {downloadInProgress ? (
                        <CircularProgress size={24} sx={{ color: '#fff' }} />
                    ) : (
                        `Download`
                    )}
                </Button>
            </Box>
        </>
    );
};

export default DownloadConfirmation;
