import {
    Box,
    Grid,
    IconButton,
    Menu,
    Typography,
    useTheme,
} from '@mui/material';
import {
    getCategoryHex,
    getCategoryHexLight,
    getCategoryHexText,
} from '../../../helpers/category';
import { useContext } from 'react';
import { AppContext } from '../../contexts';
import CloseIcon from '@mui/icons-material/Close';
import { WorksheetSetting, getHelpText } from '../../../helpers/worksheets';
import HelpHeader from './HelpHeader';
import HelpContent from './HelpContent';
import { WorksheetAvatar } from '../../forms/CreatePlaybookPage/PageTypesColumn';
import { ReactComponent as CardIcon } from '../../../assets/icons/Small-card.svg';

interface CardHelpProps {
    anchor?: HTMLElement;
    setHelpTextModal: (e: React.MouseEvent<HTMLElement> | null) => void;
    setHelpTextAnchor: React.Dispatch<
        React.SetStateAction<HTMLElement | null | undefined>
    >;
    cardTypeId?: string;
    cardCategoryId?: string;
    worksheetSettings?: WorksheetSetting | null;
}

const CardHelp = ({
    anchor,
    setHelpTextModal,
    setHelpTextAnchor,
    cardTypeId,
    cardCategoryId,
    worksheetSettings,
}: CardHelpProps) => {
    const { cardTypeObject } = useContext(AppContext);
    const theme = useTheme();
    const handleClose = () => {
        setHelpTextModal(null);
    };

    const menuOpen = Boolean(anchor);

    const renderContent = () => {
        if (cardCategoryId && cardTypeId) {
            return (
                <HelpContent
                    content={getHelpText(cardTypeId)}
                    textColor="rgba(0, 0, 0, 0.87)"
                    padding={2}
                />
            );
        }

        if (worksheetSettings) {
            return (
                <HelpContent
                    content={worksheetSettings.h}
                    textColor={theme.palette.text.primary}
                    padding={0}
                />
            );
        }

        return null;
    };

    return (
        <Menu
            id="positioned-menu"
            aria-labelledby="menu-button"
            anchorEl={anchor}
            open={menuOpen}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.2)',
                    backgroundColor:
                        cardCategoryId && getCategoryHexLight(cardCategoryId),
                    width: worksheetSettings ? '530px' : ' 330px',
                    borderRadius: '8px',
                },
                '& .MuiMenu-list': {
                    padding: 0,
                },
            }}
        >
            {cardCategoryId && cardTypeId && (
                <>
                    <HelpHeader
                        title={cardTypeObject[cardTypeId].name}
                        color={getCategoryHex(cardCategoryId)}
                        textColor={getCategoryHexText(cardCategoryId)}
                        onClose={() => setHelpTextAnchor(null)}
                    />
                    {renderContent()}
                </>
            )}
            {worksheetSettings && (
                <>
                    <HelpHeader
                        title={worksheetSettings.n}
                        color={getCategoryHex('playbook')}
                        textColor={getCategoryHexText('playbook')}
                        onClose={() => setHelpTextAnchor(null)}
                    />
                    <Grid container>
                        <Grid item xs={12} md={7} sx={{ p: 2 }}>
                            {renderContent()}
                        </Grid>
                        <Grid item xs={12} md={5} sx={{ p: 2 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    borderRadius: '16px',
                                    px: 1,
                                    py: 3,
                                    textAlign: 'center',
                                    backgroundColor: '#fff',
                                }}
                            >
                                <WorksheetAvatar
                                    worksheet={worksheetSettings}
                                />
                                <Typography
                                    variant="body2"
                                    sx={{ width: '80%' }}
                                >
                                    {worksheetSettings.d}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '16px',
                                    px: 2,
                                    py: 2,
                                    mt: 6,
                                    minHeight: '30%',
                                    backgroundColor: '#fff',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 'bold', mb: 1 }}
                                >
                                    Associated cards
                                </Typography>
                                <Box>
                                    {worksheetSettings.auto.map((cardType) => {
                                        return (
                                            <Box
                                                key={cardType}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    mb: 1,
                                                }}
                                            >
                                                <CardIcon
                                                    style={{
                                                        color: getCategoryHex(
                                                            cardTypeObject[
                                                                cardType
                                                            ].cardCategoryID
                                                        ),
                                                        width: '25px',
                                                        height: '30px',
                                                    }}
                                                />
                                                <Typography
                                                    variant="body2"
                                                    sx={{ ml: 1, color:'#000' }}
                                                >
                                                    {
                                                        cardTypeObject[cardType]
                                                            .name
                                                    }
                                                </Typography>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}
        </Menu>
    );
};

export default CardHelp;
