import { Box, Divider, styled, Typography, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { ScoreData, ScoreDefinition } from '../../API';

import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {
    createLineChartData,
    getPlaybookLineChartData,
} from '../../helpers/charts';
import { getLineChartOptions, GlobalChartConfig } from './LineChartOptions';
import ScoreWithTrend from './ScoreDisplay';
import { useContext } from 'react';
import { AppContext } from '../contexts';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title
);

const StyledChartWrapper = styled('div')(({ theme }) => ({
    height: '',
}));

interface LineChartProps {
    scoreData: ScoreData;
    scoreDefinition: ScoreDefinition;
    config?: GlobalChartConfig;
    isLastItem?: boolean;
    frontCard?: boolean;
    pptView?: boolean | undefined;
}

const LineChart = ({
    scoreDefinition,
    scoreData,
    config,
    isLastItem,
    frontCard,
    pptView,
}: LineChartProps) => {
    const { minimumValue, maximumValue, scoreType } = scoreDefinition;
    const { users } = useContext(AppContext);
    const theme = useTheme();
    const min = minimumValue || undefined;
    const max = maximumValue || undefined;
    const reverseMaxMin = scoreDefinition.target === 'Min';

    const chartData = createLineChartData({
        data: getPlaybookLineChartData(scoreData.data),
        reverse: reverseMaxMin,
        limit: 10,
        users,
        scoreType: scoreDefinition.scoreType,
    });

    const { name, description } = scoreDefinition;

    const reverseChart = !!scoreData.data.length && reverseMaxMin;

    return (
        <StyledChartWrapper sx={{ marginBottom: '10px' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            width: pptView ? '100%' : '50%',
                        }}
                    >
                        <Typography
                            variant="h6"
                            pb="0.500em"
                            fontSize={frontCard ? '6px' : '14px'}
                            sx={{ width: pptView ? '100%' : 'initial' }}
                        >
                            {name}
                        </Typography>
                        <Typography
                            variant="body2"
                            marginBottom={frontCard ? 0.5 : 2}
                            sx={{ fontSize: frontCard ? '5px' : '12px' }}
                        >
                            {description}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            maxHeight: frontCard ? '2rem' : '80px',
                            display: 'flex',
                            alignItems: 'center',
                            width: pptView ? '35%' : '40%',
                            pr: 1,
                            pt: 1,
                        }}
                    >
                        {scoreDefinition.scoreType === 'Indicator' ? (
                            <Box sx={{ display: 'flex' }}>
                                {scoreData?.data
                                    .slice(
                                        Math.max(scoreData?.data.length - 3, 1)
                                    )
                                    .map((item) => {
                                        return (
                                            <Box
                                                sx={{
                                                    width: frontCard
                                                        ? '5px'
                                                        : '20px',
                                                    height: frontCard
                                                        ? '5px'
                                                        : '20px',
                                                    background:
                                                        item.value === 1
                                                            ? theme.palette
                                                                  .warning.dark
                                                            : item.value === 2
                                                            ? theme.palette
                                                                  .warning.light
                                                            : theme.palette
                                                                  .success
                                                                  .light,
                                                    borderRadius: '6px',
                                                }}
                                            />
                                        );
                                    })}
                            </Box>
                        ) : (
                            <Line
                                options={getLineChartOptions({
                                    ...config,
                                    reverse: reverseChart,
                                    lineOnly: true,
                                    suggestedMax: max ?? 10,
                                    size: frontCard ? 'small' : 'medium',
                                    small: frontCard,
                                })}
                                data={chartData}
                            />
                        )}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ScoreWithTrend
                            scoreData={scoreData.data}
                            scoreDefinition={scoreDefinition}
                            reverse={reverseChart}
                            playbook={true}
                            small={frontCard}
                            pptView={pptView}
                        />
                    </Box>
                </Box>
            </Box>
            {!isLastItem && <Divider />}
        </StyledChartWrapper>
    );
};

export default LineChart;
