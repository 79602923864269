import {
    Box,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import { generateClient, GraphQLQuery } from 'aws-amplify/api';
import React, { useContext, useEffect, useState } from 'react';
import { getCardSetWithCards } from '../../../graphql/custom-queries';
import { getUserOrganisation } from '../../../helpers/auth';
import { AppContext } from '../../contexts';
import { CardSet, GetCardSetWithCardsQuery, Worksheet } from '../../../API';
import { ReactComponent as WorkbookManagerIcon } from '../../../assets/icons/Workbooks.svg';
import { truncateText } from '../../../helpers/utils';

const client = generateClient();
export const getWorkbook = async (id: string, organisation: string) => {
    const response = await client.graphql<
        GraphQLQuery<GetCardSetWithCardsQuery>
    >({
        query: getCardSetWithCards,
        variables: {
            organisation: organisation,
            id: id,
        },
    });
    return response;
};
interface WorkbookListProps {
    workbookIds: (string | null)[] | null | undefined;
    selectedWorkbook: CardSet | undefined;
    setSelectedWorkbook: React.Dispatch<
        React.SetStateAction<CardSet | undefined>
    >;
    setSelectedWorksheetItems: React.Dispatch<
        React.SetStateAction<Worksheet[]>
    >;
    workbooks: CardSet[] | undefined;
    setWorkbooks: React.Dispatch<React.SetStateAction<CardSet[] | undefined>>;
}

const WorkbookList = ({
    workbookIds,
    selectedWorkbook,
    setSelectedWorkbook,
    setSelectedWorksheetItems,
    workbooks,
    setWorkbooks
}: WorkbookListProps) => {
    const theme = useTheme();
    const { user } = useContext(AppContext);
    const organisation = getUserOrganisation(user);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchWorkbooks = async () => {
            setLoading(true);
            if (workbookIds?.length) {
                const fetchedWorkbooks = await Promise.all(
                    workbookIds.map(async (id) => {
                        if (id) {
                            const response = await getWorkbook(
                                id,
                                organisation
                            );
                            return response?.data?.getCardSet;
                        }
                        return null;
                    })
                );
                setWorkbooks(fetchedWorkbooks.filter(Boolean) as CardSet[]);
            }
            setLoading(false);
        };

        fetchWorkbooks();
    }, [workbookIds]);
    return (
        <Box
            sx={{
                borderRight: 'solid 1px #ccc',
                borderLeft: 'solid 1px #ccc',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    Workbooks
                </Typography>
            </Box>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pt: 20,
                    }}
                >
                    {' '}
                    <CircularProgress size={50} />{' '}
                </Box>
            ) : (
                <List sx={{ width: '100%', overflowY: 'auto', flex: '1' }}>
                    {workbooks?.map((item) => {
                        const isSelected = item.id === selectedWorkbook?.id;
                        return (
                            <ListItem
                                key={item.id}
                                alignItems="center"
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        bgcolor: 'background.paper',
                                    },
                                    bgcolor: isSelected
                                        ? 'background.paper'
                                        : 'none',
                                    py: 0,
                                }}
                                onClick={() => {
                                    setSelectedWorkbook(item);
                                    setSelectedWorksheetItems([]);
                                }}
                            >
                                <ListItemAvatar
                                    sx={{
                                        marginTop: 0,
                                        minWidth: '20px',
                                    }}
                                >
                                    <WorkbookManagerIcon
                                        width={25}
                                        height={25}
                                        color={theme.palette.primary.main}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        padding: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 0,
                                    }}
                                    primary={truncateText(item.name, 45)}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </Box>
    );
};

export default WorkbookList;
