import {
    AppBar,
    Box,
    Divider,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { appBarWidth } from '../helpers/styleConfig';
import CardManager from '../assets/images/menubar/card-manager-unselected.png';
import CardManagerActive from '../assets/images/menubar/card-manager-selected.png';
import CardSetsIcon from '../assets/images/menubar/card-set-manager-unselected.png';
import CardSetsIconActive from '../assets/images/menubar/card-set-manager-selected.png';
import DashboardIcon from '../assets/images/menubar/dashboard-unselected.png';
import DashboardIconActive from '../assets/images/menubar/dashboard-selected.png';
import PlaybooksIcon from '../assets/images/menubar/playbook-manager-unselected.png';
import PlaybooksIconActive from '../assets/images/menubar/playbook-manager-selected.png';
import WorkbooksIcon from '../assets/images/menubar/workbook-manager-unselected.png';
import WorkbooksIconActive from '../assets/images/menubar/workbook-manager-selected.png';
import StrategyIcon from '../assets/images/menubar/strategy-builder-unselected.png';
import StrategyIconActive from '../assets/images/menubar/strategy-builder-selected.png';
import PictureOff from '../assets/icons/Picture-off.svg';
import PictureOn from '../assets/icons/Picture-on.svg';

import ThemeModeSwitch from './ThemeModeSwitch';
import UserAvatar from './profile/UserAvatar';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AppContext } from './contexts';
import HelpIcon from '@mui/icons-material/Help';
import Notification from './notifications';
import { UserProfile } from '../API';
import { generateClient } from 'aws-amplify/api';
import { updateUserProfile } from '../graphql/mutations';

interface IconProps {
    to: string;
    tooltipTitle: string;
    style: React.CSSProperties;
}

const GlobalAppBar = () => {
    const {
        showIllustrations,
        setShowIllustrations,
        userProfileObject,
        setUserProfileObject,
    } = useContext(AppContext);
    const iconStyles = {
        portraitIcons: {
            width: 80,
            height: 80,
            display: 'inline',
        },
        landscapeIcons: {
            width: 70,
            height: 70,
            display: 'inline',
            mr: '5px',
        },
    };
    const client = generateClient();
    const theme = useTheme();

    const toggleIllustrations = async () => {
        const user = { ...userProfileObject?.getUserProfile };
        const preferences = JSON.parse(user?.preferences as string);
        preferences.showIllustrations = !showIllustrations;
        user.preferences = JSON.stringify(preferences);

        setUserProfileObject &&
            setUserProfileObject({
                ...userProfileObject,
                ['getUserProfile']: user as UserProfile,
            });
        await client.graphql({
            query: updateUserProfile,
            variables: {
                input: {
                    organisation: user.organisation ?? 'x',
                    id: user.id ?? '',
                    preferences: JSON.stringify(preferences),
                },
            },
        });

        setShowIllustrations && setShowIllustrations(!showIllustrations);
    };

    const processIcon = (
        DefaultIcon: string,
        ActiveIcon: string,
        mt: string
    ) => {
        return ({ to, tooltipTitle, style }: IconProps) => {
            const [isHovering, setIsHovering] = useState(false);
            const location = useLocation();
            const navigate = useNavigate();
            let isActive;
            if (tooltipTitle === 'Dashboard') {
                isActive = location.pathname === to;
            } else {
                isActive = location.pathname.includes(to);
            }

            const IconToShow =
                isHovering || isActive ? ActiveIcon : DefaultIcon;

            return (
                <Tooltip title={tooltipTitle} placement="right-start">
                    <Box
                        onClick={(e) => {
                            e?.stopPropagation();
                            window.scroll(0, 0);
                            navigate(to);
                        }}
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        style={{ height: 70, marginTop: mt, cursor: 'pointer' }}
                    >
                        <img
                            src={IconToShow}
                            alt={tooltipTitle}
                            style={{ ...style, height: 40, width: 40 }}
                        />
                    </Box>
                </Tooltip>
            );
        };
    };
    const ProcessedDashboardIcon = processIcon(
        DashboardIcon,
        DashboardIconActive,
        '-8px'
    );
    const ProcessedWorkbookIcon = processIcon(
        WorkbooksIcon,
        WorkbooksIconActive,
        '0px'
    );
    const ProcessedPlaybookIcon = processIcon(
        PlaybooksIcon,
        PlaybooksIconActive,
        '-7px'
    );
    const ProcessedCardManagerIcon = processIcon(
        CardManager,
        CardManagerActive,
        '0px'
    );
    const ProcessedCardSetsIcon = processIcon(
        CardSetsIcon,
        CardSetsIconActive,
        '-10px'
    );
    const ProcessedStrategyIcon = processIcon(
        StrategyIcon,
        StrategyIconActive,
        '0px'
    );
    return (
        <AppBar
            sx={{
                bgcolor: theme.palette.mode === 'dark' ? '#000000' : '#2A3646',
                height: '100vh',
                flexDirection: 'column',
                justifyContent: 'space-between',
                left: 0,
                width: appBarWidth,
                p: (theme) => `${theme.spacing(1)} !important`,
                button: {
                    color: 'common.white',
                },
                a: {
                    color: 'common.white',
                    ['&.active']: {
                        color: 'primary.main',
                    },
                },
                svg: {
                    width: '1.7rem',
                    height: '1.7rem',
                },
                zIndex: 1400,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <ProcessedDashboardIcon
                    to="/dashboard"
                    tooltipTitle="Dashboard"
                    style={{
                        ...iconStyles.landscapeIcons,
                        marginLeft: '5px',
                        marginTop: '10px',
                        width: '45px',
                        height: '45px',
                    }}
                />
                <Typography variant="body2" sx={{ fontSize: '10px', mt: -2.5 }}>
                    Dashboard
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <Divider sx={{ borderColor: 'grey' }} />
                </Box>

                <ProcessedWorkbookIcon
                    to="/workbooks"
                    tooltipTitle="Workbook manager"
                    style={{
                        ...iconStyles.portraitIcons,
                        marginLeft: '5px',
                        marginTop: '5px',
                        width: '55px',
                        height: '55px',
                    }}
                />
                <Typography
                    variant="body2"
                    sx={{ fontSize: '10px', mt: -3, mb: 1.5 }}
                >
                    Workbooks
                </Typography>
                <ProcessedPlaybookIcon
                    to="/playbooks"
                    tooltipTitle="Playbook manager"
                    style={{
                        ...iconStyles.portraitIcons,
                        marginLeft: '5px',
                        width: '55px',
                        height: '55px',
                    }}
                />
                <Typography
                    variant="body2"
                    sx={{ fontSize: '10px', mt: -3.7, mb: 1 }}
                >
                    Playbooks
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <Divider sx={{ borderColor: 'grey', mt: '-7px' }} />
                </Box>
                <ProcessedCardManagerIcon
                    to="/cards"
                    tooltipTitle="Card manager"
                    style={{
                        ...iconStyles.portraitIcons,
                        marginLeft: '5px',
                        width: '55px',
                        height: '55px',
                    }}
                />
                <Typography
                    variant="body2"
                    sx={{ fontSize: '10px', mt: -3.5, mb: 2 }}
                >
                    Cards
                </Typography>
                <ProcessedCardSetsIcon
                    to="/card-sets"
                    tooltipTitle="Card set manager"
                    style={{
                        ...iconStyles.portraitIcons,
                        marginLeft: '5px',
                        width: '55px',
                        height: '55px',
                    }}
                />
                <Typography variant="body2" sx={{ fontSize: '10px', mt: -3.5 }}>
                    Card sets
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <Divider sx={{ borderColor: 'grey', mt: '-6px' }} />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {' '}
                <Notification />
                <Tooltip
                    title={
                        showIllustrations ? 'Hide pictures' : 'Show pictures'
                    }
                    placement="right-start"
                >
                    <img
                        onClick={() => toggleIllustrations()}
                        src={showIllustrations ? PictureOn : PictureOff}
                        alt="Illustrations"
                        style={{
                            maxWidth: '100%',
                            display: 'inline',
                            cursor: 'pointer',
                        }}
                    />
                </Tooltip>
                <ThemeModeSwitch />
                <UserAvatar isProfile={true} />
            </Box>
        </AppBar>
    );
};

export default GlobalAppBar;
