import { useContext, useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import DOMPurify from 'dompurify';
import { Alert, AlertProps, Box, Snackbar } from '@mui/material';
import { StyledModal } from '../components/Modal';
import ModalContainer from '../components/ModalContainer';
import CreateCardSet from '../components/forms/CreateCardSet/index';
import { CardContext } from '../components/cards/context';
import CardTypeLayout from '../components/cards/views/CardTypeLayout';
import {
    CardComponentData,
    CardComponentType,
    CardPage,
    SortActions,
    PageIdentifier,
    ViewType,
    sortFieldToResponseMap,
} from '../components/cards/cardTypes';
import useSnackbar from '../hooks/useSnackbar';
import {
    CardSet,
    DeleteCardSetBasicMutation,
    GetCardSetQuery,
    CreateCardSetMutation,
    CreatePlaybookPageMutation,
    PlaybookPageType,
    CardSetType,
} from '../API';
import { getCardSet } from '../graphql/queries';
import CardDelete from '../components/cards/CardDelete';
import {
    copyCardSet,
    createCardSet,
    createPlaybookPage,
} from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import { deleteCardSetBasic } from '../graphql/custom-mutations';
import CardInfo from '../components/cards/CardInfo';
import StyledCardContent from '../components/cards/CardContent';
import CardSetComponent from '../components/cards/CardSetComponent';
import CardDetails from '../components/cards/CardDetails';
import CardRelationships from '../components/cards/CardRelationships';
import CreatePlaybook from '../components/forms/CreatePlaybook';
import { useNavigate } from 'react-router-dom';
import useIconStates from '../hooks/useIconStates';
import { AppContext } from '../components/contexts';
import { queryData } from '../actions/QueryData';
import useFilterCards from '../hooks/useFilterCards';
import useSortCards from '../hooks/useSort';
import { getUserOrganisation } from '../helpers/auth';
import { handleCopyCardSet } from '../actions/CardSets';
import { getSubscriptions } from '../helpers/subscriptions';
import { worksheets } from '../components/forms/worksheets';
import { getChartSettings } from '../helpers/worksheets';
import CardActivity from '../components/cards/CardActivity';
import Copyworkbook, {
    CopyType,
    WorkbookCopy,
} from '../components/workbooks/CopyWorkbook';
import { getWorkbooksBasic } from '../graphql/custom-queries';
import { processSteps } from '../components/forms/processSteps';

interface CardSetsProps {
    cardSets?: CardSet[];
}
interface CardSetToDelete {
    id: string;
    organisation: string;
}

export interface Workbook {
    organisation?: string;
    name?: string;
    description?: string;
    worksheet?: string;
    selectedWorksheet?: string;
}

export const workBookToCardComponentProps = (
    data: CardSet
): CardComponentData => {
    return {
        id: data.id,
        description: data.description,
        name: data.name,
        cardCategoryId: 'workbook',
        cardTypeId: 'workbook',
        cardCategoryName: 'workbook',
        cardComponentType: CardComponentType.WORKBOOK,
        cardTypeName: 'workbook',
        scoreName: 'cards',
        scoreValue: data.counts?.cards ?? 0,
        organisation: data.organisation,
        link: `/workbooks/${data.id}`,
        updatedAt: data.updatedAt,
        owner: data.owner,
        createdAt: data.createdAt,
    };
};

const WorkBooks = () => {
    const { applySort, viewType, users, user } = useContext(AppContext);
    const [activeCardId, setActiveCardId] = useState<string | null>();
    const [showCreateWorkBookForm, setShowCreateWorkBookForm] = useState(false);
    const [showDeleteCardSetWizard, setShowDeleteCardSetWizard] =
        useState(false);
    const [activeCardPage, setActiveCardPage] = useState<CardPage>();
    const [editableWorkbook, setEditableWorkbook] = useState<CardSet>();
    const [cardSetToDelete, setCardSetToDelete] =
        useState<CardSetToDelete | null>(null);
    const [cardSetsObject, setCardSetsObject] = useState<{
        [key: string]: CardSet;
    }>({});
    const [cardSets, setCardSets] = useState<CardSet[]>([]);
    const navigate = useNavigate();
    const [activeIcons, setActiveIcons] = useIconStates('WorkbookControls');
    const [cardSortDirection, setCardSortDirection] = useState<
        'ASC' | 'DESC' | null
    >();
    const [cardSortField, setCardSortField] = useState<SortActions | null>();
    const [filterCriteria, setFilterCriteria] = useFilterCards(
        `filter_${PageIdentifier.WORKBOOKS}`
    );
    const [copyWorkbook, setCopyWorkbook] = useState<string | null>(null);

    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();

    const { setLoaded, applyFilter } = useContext(AppContext);

    const client = generateClient();

    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState<string | null>('');
    const [sortAction, sortDirection] = useSortCards(PageIdentifier.WORKBOOKS);
    useEffect(() => {
        setCardSortField(sortAction as SortActions);
        setCardSortDirection(sortDirection as 'ASC' | 'DESC');
    }, [applySort]);

    const fetchWorkbooks = async (initialLoad?: boolean) => {
        if (!cardSortDirection || !cardSortField) return;
        try {
            const { data, nextToken } = await queryData(
                initialLoad ?? false,
                cardSortDirection,
                token,
                activeIcons,
                getWorkbooksBasic,
                sortFieldToResponseMap[cardSortField],
                'getWorkbooks',
                filterCriteria,
                setActiveIcons,
                '',
                user
            );

            if (initialLoad) {
                setCardSets(data as CardSet[]);
            } else {
                setCardSets([...cardSets, ...(data as CardSet[])]);
            }

            setLoading(false);
            setToken(nextToken);
            setLoaded && setLoaded(true);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        setToken('');
        if (setLoaded && user) {
            fetchWorkbooks(true);
        }
    }, [
        activeIcons,
        cardSortDirection,
        cardSortField,
        setLoaded,
        applyFilter,
        user,
    ]);

    useEffect(() => {
        const updatedCardSetsObject = { ...cardSetsObject };
        cardSets?.forEach((cardSet) => {
            cardSet.cardSetToCardCategoryId = 'workbook';
            cardSet.cardSetToCardTypeId = 'workbook';
            updatedCardSetsObject[cardSet.id] = cardSet;
        });

        setCardSetsObject(updatedCardSetsObject);
    }, [cardSets]);

    const onCreateWorkBook = (cardSet?: string) => {
        handleClose();
        if (cardSet) {
            showSnackbar({
                message: 'Workbook created successfully',
                severity: 'success',
            });
        }
    };

    const handleClose = () => {
        setActiveCardId(null);
        setShowCreateWorkBookForm(false);
        setEditableWorkbook(undefined);
        setCopyWorkbook(null);
    };

    const handleEdit = (cardSetId: string) => {
        setEditableWorkbook(cardSetsObject[cardSetId]);
    };
    const handleListViewEdit = (cardSetId: string, cardPage?: CardPage) => {
        if (cardPage) {
            if (!activeCardId || (activeCardId && cardSetId !== activeCardId)) {
                setActiveCardId(cardSetId);
            }
            setActiveCardPage(cardPage);
        }
    };
    const handleDelete = async (id: string, organisation: string) => {
        setShowDeleteCardSetWizard(true);
        setCardSetToDelete({
            id: id,
            organisation: organisation,
        });
    };

    const handleClick = (cardId: string, cardPage?: CardPage) => {
        if (viewType === ViewType.LIST && !activeCardId) {
            window.scrollTo(0, 0);
            navigate(`/workbooks/${cardId}`);
        }
        if (cardPage) {
            if (!activeCardId || (activeCardId && cardId !== activeCardId)) {
                setActiveCardId(cardId);
            }
            setActiveCardPage(cardPage);
        }
    };

    const confirmDelete = async (id: string, organisation: string) => {
        try {
            (await client.graphql({
                query: deleteCardSetBasic,
                variables: {
                    input: {
                        organisation,
                        id,
                    },
                },
            })) as { data: DeleteCardSetBasicMutation };
            showSnackbar({
                message: 'Workbook deleted',
                severity: 'info',
            });
            handleClose();
        } catch (err) {
            handleClose();
            showSnackbar({
                message: 'Error while removing workbook',
                severity: 'error',
            });
        }
        setShowDeleteCardSetWizard(false);
        setCardSets(
            (prevItems) =>
                prevItems && prevItems.filter((item) => item.id !== id)
        );
    };

    const closeModal = () => {
        setActiveCardId(null);
    };

    const createWorkbook = async (data: Workbook) => {
        const userOrganisation = getUserOrganisation(user);
        const sanitizedData = {
            name: DOMPurify.sanitize(data.name!) || '',
            description: DOMPurify.sanitize(data.description!) || '',
            organisation: data?.organisation || 'x',
            type: 'WB' as CardSetType,
            orgEdit: userOrganisation,
            processProgress: processSteps.map((item) => ({
                stageId: item.id,
                progress: 0,
            })),
        };
        const query = createCardSet;

        const res = (await client.graphql({
            query,
            variables: {
                input: sanitizedData,
            },
        })) as { data: CreateCardSetMutation };

        if (res.data.createCardSet) {
            if (data.worksheet === 'select' && data.selectedWorksheet) {
                const worksheet = worksheets.find(
                    (item) => item.i === data.selectedWorksheet
                );
                const settings = getChartSettings(worksheet?.n || '');
                let options = {};
                const nodeSizeSelection =
                    settings?.options.chartData.nodeSize.selection;

                options = {
                    chartData: {
                        xAxis: settings?.options.chartData.xAxisOptions[0],
                        yAxis: settings?.options.chartData.yAxisOptions[1],
                        nodeSize: {
                            selection:
                                nodeSizeSelection[nodeSizeSelection.length - 1],
                        },
                        nodeColor: {
                            selection:
                                settings?.options.chartData.nodeColor
                                    .selection[0],
                        },
                        hideGaps: settings?.options.chartData.hideGaps,
                    },
                    layout: [
                        {
                            include: settings?.options.include,
                            layout: settings?.options.layout,
                        },
                    ],
                };

                const worksheetData = {
                    organisation: data?.organisation || 'x',
                    workbookId: res.data.createCardSet.id,
                    playbookPageType: PlaybookPageType.Chart,
                    worksheetId: data.selectedWorksheet,
                    options: JSON.stringify(options),
                };
                const query = createPlaybookPage;
                const response = (await client.graphql({
                    query,
                    variables: {
                        input: worksheetData,
                    },
                })) as { data: CreatePlaybookPageMutation };

                if (response.data.createPlaybookPage) {
                    navigate(`/workbooks/${res.data.createCardSet.id}`);
                }
            } else {
                navigate(`/workbooks/${res.data.createCardSet.id}`);
            }
        }
        setShowCreateWorkBookForm(false);
    };

    const refreshCardSet = async (id: string, organisation: string) => {
        const response = (await client.graphql({
            query: getCardSet,
            variables: {
                id: id,
                organisation: organisation,
            },
        })) as { data: GetCardSetQuery };

        const card = response.data.getCardSet;
        const items = [...(cardSets ?? [])];

        const index = items.findIndex((item) => item.id === id);

        if (!!card && index !== -1) {
            items[index] = card as CardSet;
            setCardSets(items);
        }
    };

    const handleCopy = async (workbook: WorkbookCopy) => {
        try {
            const response = await client.graphql({
                query: copyCardSet,
                variables: {
                    originalCardSetId: workbook.id,
                    options: {
                        owner: workbook.owner?.id,
                        name: workbook.name,
                        copyCards:
                            workbook.copyType === CopyType.WORKSHEETSANDACARDS
                                ? true
                                : false,
                        copyWorksheets: true,
                    },
                },
            });

            setCopyWorkbook(null);
            showSnackbar({
                message: 'Workbook copied successfully',
                severity: 'success',
            });
        } catch (err) {
            console.log(err);
            showSnackbar({
                message: 'Error while copying the workbook',
                severity: 'error',
            });
        }
    };
    useEffect(() => {
        if (users?.length) {
            const createSub = client
                .graphql({ query: subscriptions.onCreateNotification })
                .subscribe({
                    next: ({ data }) => {
                        getSubscriptions({
                            data,
                            setItems: setCardSets,
                            showSnackbar,
                            users: users,
                            createCard: false,
                            location: location,
                            createCardSet: true,
                        });
                    },
                    error: (error) => console.warn(error),
                });
            return () => createSub.unsubscribe();
        }
    }, [users]);

    return (
        <>
            <CardContext.Provider
                value={{
                    emptyAction: () => setShowCreateWorkBookForm(true),
                    handleClick,
                    handleClose,
                    handleDelete,
                    handleEdit,
                    handleCopy: setCopyWorkbook,
                    items:
                        cardSets?.map((cardSet) =>
                            workBookToCardComponentProps(cardSet)
                        ) || [],
                }}
            >
                <CardTypeLayout
                    headerTitle="Workbooks"
                    viewTitle="All workbooks"
                    action={() => setShowCreateWorkBookForm(true)}
                    actionLabel="Create workbook"
                    viewOptions={{
                        [ViewType.GRID]: true,
                        [ViewType.LIST]: true,
                    }}
                    activeIcons={activeIcons}
                    setActiveIcons={setActiveIcons}
                    pageIdentifier={PageIdentifier.WORKBOOKS}
                    onLoadMore={fetchWorkbooks}
                    loading={loading}
                    token={token}
                    filterCriteria={filterCriteria}
                    setFilterCriteria={setFilterCriteria}
                    handleListViewEdit={handleListViewEdit}
                />
            </CardContext.Provider>

            <Snackbar
                open={isOpen}
                autoHideDuration={duration}
                onClose={closeSnackbar}
                sx={{ zIndex: 1600 }}
            >
                <Alert
                    variant="filled"
                    severity={severity}
                    sx={{ width: '100%' }}
                    onClose={closeSnackbar}
                >
                    {message}
                </Alert>
            </Snackbar>

            <StyledModal
                key="modal"
                open={
                    !!activeCardId ||
                    showCreateWorkBookForm ||
                    !!editableWorkbook ||
                    !!copyWorkbook
                }
                onClose={handleClose}
                aria-labelledby=""
                aria-describedby=""
            >
                <Box>
                    {showCreateWorkBookForm && !!!editableWorkbook && (
                        <ModalContainer sx={{ maxWidth: '48rem' }}>
                            <CreatePlaybook
                                handleClose={handleClose}
                                createWorkbook={createWorkbook}
                                type="Workbook"
                            />
                        </ModalContainer>
                    )}
                    {!!editableWorkbook && (
                        <ModalContainer sx={{ maxWidth: '48rem', p: 4 }}>
                            <CreateCardSet
                                cardSet={editableWorkbook}
                                handleClose={onCreateWorkBook}
                            />
                        </ModalContainer>
                    )}
                    {activeCardId && (
                        <Box>
                            {!!activeCardPage && (
                                <ModalContainer sx={{ maxWidth: '48rem' }}>
                                    <CardSetComponent
                                        data={cardSetsObject[activeCardId]}
                                        handleClose={handleClose}
                                        showPage={activeCardPage}
                                        onClick={(
                                            cardId: string,
                                            cardPage?: CardPage
                                        ) => handleClick(cardId, cardPage)}
                                        handleDelete={handleDelete}
                                        handleCopy={(workbook) => {
                                            setActiveCardId(null);
                                            setCopyWorkbook(workbook);
                                        }}
                                        expanded
                                    >
                                        <StyledCardContent expanded={true}>
                                            {
                                                {
                                                    [CardPage.DETAILS]: (
                                                        <CardDetails
                                                            card={
                                                                cardSetsObject[
                                                                    activeCardId
                                                                ]
                                                            }
                                                            onUpdate={
                                                                refreshCardSet
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.SCORES]: (
                                                        <Box></Box>
                                                    ),
                                                    [CardPage.ACTIVITY]: (
                                                        <CardActivity
                                                            card={
                                                                cardSetsObject[
                                                                    activeCardId
                                                                ]
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.INFO]: (
                                                        <CardInfo
                                                            card={
                                                                cardSetsObject[
                                                                    activeCardId
                                                                ]
                                                            }
                                                            onUpdate={
                                                                refreshCardSet
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.RELATIONSHIPS]: (
                                                        <CardRelationships
                                                            card={
                                                                cardSetsObject[
                                                                    activeCardId
                                                                ]
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.COMMENTS]: (
                                                        <Box></Box>
                                                    ),
                                                }[activeCardPage]
                                            }
                                        </StyledCardContent>
                                    </CardSetComponent>
                                </ModalContainer>
                            )}
                        </Box>
                    )}
                    {!!copyWorkbook && (
                        <ModalContainer sx={{ maxWidth: '48rem', p: 0 }}>
                            <Copyworkbook
                                workbook={cardSets.find(
                                    (cardSet) => cardSet.id === copyWorkbook
                                )}
                                close={() => setCopyWorkbook(null)}
                                onSubmit={handleCopy}
                            />
                        </ModalContainer>
                    )}
                </Box>
            </StyledModal>
            <StyledModal
                key="delete-card-set-modal"
                open={showDeleteCardSetWizard}
                onClose={() => setShowDeleteCardSetWizard(false)}
                disableEnforceFocus
                sx={{
                    zIndex: 1500,
                }}
            >
                <Box>
                    {showDeleteCardSetWizard && (
                        <ModalContainer sx={{ maxWidth: '40rem' }}>
                            {cardSetToDelete && (
                                <CardDelete
                                    id={cardSetToDelete.id}
                                    organisation={cardSetToDelete.organisation}
                                    toggleDeleteWizard={
                                        setShowDeleteCardSetWizard
                                    }
                                    confirmDelete={confirmDelete}
                                    pageIdentifier={PageIdentifier.WORKBOOKS}
                                />
                            )}
                        </ModalContainer>
                    )}
                </Box>
            </StyledModal>
        </>
    );
};

export default WorkBooks;
