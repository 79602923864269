import { useContext, useEffect, useState } from 'react';
import { styled, IconButton, Box } from '@mui/material';
import { Card, PlaybookPageDataResponse, PlaybookPageType } from '../../../API';
import { RadarOption } from '../../forms/input-fields/ChartSettings';
import { worksheets } from '../../forms/worksheets';
import ChartPage from '../analysis/ChartPage';
import ListPage from '../lists';
import PlaybookTitlePage from '../PlaybookTitlePage';
import { CardTypeId } from '../../../helpers/category';
import PlaybookSelectSource from '../PlaybookSelectSource';

interface PlaybookPageProps {
    page: PlaybookPageDataResponse;
    cards?: Card[];
    pageOptions?: undefined;
    radarOptions?: RadarOption[] | null | undefined;
    carousel: boolean;
    onLoaded?: () => void;
    slideNumber?: number;
    logo: string | null;
}

interface PageContainerProps {
    thumbnail?: boolean;
}
const PageContainer = styled('div')<PageContainerProps>(({ thumbnail }) => {
    return {
        cursor: 'pointer',
        height: '100%',
        fontSize: thumbnail ? '16px' : '6px',
        position: 'relative',
        width: !thumbnail ? '345px' : '',
    };
});

const PlaybookPagePpt = ({
    cards,
    page,
    carousel,
    onLoaded,
    slideNumber,
    logo,
}: PlaybookPageProps) => {
    const [radarOptions, setRadarOptions] = useState<RadarOption[] | null>();
    const cardIds = page?.cards?.length
        ? page.cards
        : page?.cardSets?.length
        ? page.cardSets
        : page?.workbooks?.length
        ? page.workbooks
        : [];
    const worksheetId = page?.worksheetId ?? 1;
    const worksheet = worksheets.find((item) => item.i === worksheetId);
    const pageCards = cards?.filter((item) =>
        worksheet?.auto.includes(item?.cardToCardTypeId)
    );

    useEffect(() => {
        if (page.options) {
            const options = JSON.parse(page.options);
            if (options.chartData?.radarOptions) {
                setRadarOptions(options.chartData.radarOptions);
            }
        }
    }, [page]);
    return (
        <Box sx={{ width: '338mm' }}>
            {cardIds?.length && cardIds.length > 0 ? (
                page.playbookPageType === PlaybookPageType.Chart ? (
                    <ChartPage
                        page={page}
                        cards={pageCards}
                        permissions={[]}
                        itemSelected={false}
                        setEditMode={() => {}}
                        carousel={carousel}
                        pptView={true}
                        radarOptions={radarOptions}
                        slideNumber={slideNumber}
                        logo={logo}
                    />
                ) : (
                    <ListPage
                        page={page}
                        cards={pageCards}
                        permissions={[]}
                        itemSelected={false}
                        setEditMode={() => {}}
                        carousel={carousel}
                        pptView={true}
                        logo={logo}
                    />
                )
            ) : (
                <>
                    {page.playbookPageType === PlaybookPageType.Title ? (
                        <PlaybookTitlePage
                            setEditMode={() => {}}
                            permissions={[]}
                            page={page}
                            carousel={true}
                            logo={logo}
                            pptView={true}
                        />
                    ) : (
                        <PlaybookSelectSource
                            setEditMode={() => {}}
                            permissions={[]}
                            page={page}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default PlaybookPagePpt;
