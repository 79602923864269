import {
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import { processSteps } from '../processSteps';
import { ReactComponent as CreateCardProcessIcon } from '../../../assets/icons/CreateCardProcess.svg';
import {
    ExtendedWorksheet,
    NewCard,
    SelectedWorksheet,
} from '../CreateCard/types';

interface ProcessColumnProps {
    setSelectedProcessId: React.Dispatch<React.SetStateAction<string | null>>;
    selectedProcessId: string | null;
    setSelectedStepId: React.Dispatch<React.SetStateAction<string | null>>;
    setSelectedWorksheets: React.Dispatch<
        React.SetStateAction<SelectedWorksheet[] | null>
    >;
    setSelectedType: React.Dispatch<
        React.SetStateAction<string | null | undefined>
    >;
    setNewCard: React.Dispatch<React.SetStateAction<NewCard>>;
    newCard: NewCard;
    setSelectedWorksheetItems: React.Dispatch<
        React.SetStateAction<ExtendedWorksheet[]>
    >;
}

const ProcessColumn = ({
    setSelectedProcessId,
    selectedProcessId,
    setSelectedStepId,
    setSelectedWorksheets,
    setSelectedType,
    setNewCard,
    newCard,
    setSelectedWorksheetItems,
}: ProcessColumnProps) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    Process
                </Typography>
            </Box>
            <Box sx={{ overflowY: 'auto' }}>
                {processSteps.map((process) => {
                    return (
                        <ListItem
                            key={process.id}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    bgcolor: 'background.paper',
                                },
                                bgcolor:
                                    selectedProcessId === process.id
                                        ? 'background.paper'
                                        : 'inherit',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                setSelectedProcessId(process.id);
                                setSelectedStepId(null);
                                setSelectedWorksheets(null);
                                setSelectedType('');
                                setNewCard({
                                    ...newCard,
                                    cardToCardCategoryId: '',
                                    cardToCardTypeId: '',
                                });
                                setSelectedWorksheetItems([]);
                            }}
                        >
                            <ListItemAvatar
                                sx={{
                                    // marginTop: '0.4rem',
                                    alignSelf: 'flex-start',
                                }}
                            >
                                <CreateCardProcessIcon
                                    style={{
                                        color: theme.palette.primary.main,
                                        width: '40px',
                                        height: '40px',
                                    }}
                                />
                            </ListItemAvatar>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >{`${process.id}. ${process.name}`}</Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: '0.9rem',
                                    }}
                                >
                                    {process.description}
                                </Typography>
                            </Box>
                        </ListItem>
                    );
                })}
            </Box>
        </Box>
    );
};

export default ProcessColumn;
