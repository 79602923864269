import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import {
    Category,
    getCategoryHex,
    getCategoryHexText,
    getCategoryIcon,
} from '../../../helpers/category';
import { CardCategory, CardType, Card, CardSet } from '../../../API';
import { NewCard, NewWorksheet } from '../CreateCard/types';
import { ReactComponent as CardIcon } from '../../../assets/icons/Small-card.svg';

interface PagesColumnProps {
    newWorksheet?: NewWorksheet;
    setNewWorksheet?: (newWorksheet?: NewWorksheet) => void;
}

const PagesColumn = ({ newWorksheet, setNewWorksheet }: PagesColumnProps) => {
    const theme = useTheme();

    const pages = [
        'Title page',
        'Section header',
        'Contents',
        'Commentary',
        'Footer',
    ];

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    Page type
                </Typography>
            </Box>
            <List
                sx={{
                    width: '100%',
                    overflowY: 'auto',
                    flex: '1',
                }}
            >
                {pages.map((page) => {
                    return (
                        <ListItem
                            key={page}
                            sx={{
                                height: '42px',
                                cursor:
                                    page === 'Title page' ? 'pointer' : 'none',
                                '&:hover': {
                                    bgcolor:
                                        page === 'Title page'
                                            ? 'background.paper'
                                            : 'initial',
                                },
                            }}
                        >
                            <ListItemAvatar></ListItemAvatar>

                            <ListItemText
                                sx={{
                                    padding: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 0,
                                    color: 'grey',
                                }}
                                primary={page}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default PagesColumn;
