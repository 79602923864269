import { Menu, MenuItem, useTheme } from '@mui/material';
import { Worksheet } from '../../API';

interface WorksheetMenuProps {
    anchorEl: HTMLElement;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    setDeleteWorksheetConfirmation: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}
const WorksheetMenu = ({
    anchorEl,
    setAnchorEl,
    setDeleteWorksheetConfirmation,
}: WorksheetMenuProps) => {
    const theme = useTheme();
    return (
        <Menu
            id="worksheet-menu"
            aria-labelledby="menu-button"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={(e: React.BaseSyntheticEvent) => {
                e.stopPropagation();
                setAnchorEl(null);
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: `1px 1px 1px 1px ${theme.palette.background.paper}`,
                    backgroundColor: theme.palette.background.default,
                },
                mt: 3,
                ml: 3,
            }}
        >
            <MenuItem
                onClick={(e) => {
                    setAnchorEl(null);
                    setDeleteWorksheetConfirmation(true);
                }}
            >
                Delete
            </MenuItem>
        </Menu>
    );
};

export default WorksheetMenu;
