import Grid2 from '@mui/material/Unstable_Grid2';
import { NumbersCount, SelectionType } from '../../../pages/workbooks/[pid]';
import { v4 as uuidv4 } from 'uuid';
import CardActivity from '../../cards/CardActivity';
import {
    CardSet,
    EntityType,
    UpdateCardMutation,
    UpdateCardSetMutation,
    UserProfile,
    Worksheet,
} from '../../../API';
import CardComments from '../../cards/CardComments';
import {
    Box,
    Button,
    CircularProgress,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserPermissions } from '../../../globals';
import { ViewType, updateMutationMap } from '../../cards/cardTypes';
import { StyledSection } from '../../StyledElements';
import UserAvatar from '../../profile/UserAvatar';
import { getUserOrganisation } from '../../../helpers/auth';
import { AppContext } from '../../contexts';
import { StyledModal } from '../../Modal';
import ModalContainer from '../../ModalContainer';
import CardPermissions from '../../forms/CardPermissions';
import { GraphQLQuery, generateClient } from 'aws-amplify/api';
import WorksheetSection from './WorksheetSection';
import { processSteps } from '../../forms/processSteps';
import Process, { descriptions, ProcessName } from '../Process';
import FoodaImage from './FoodaImage';

interface WorkbookMainProps {
    id: string;
    deleting: boolean;
    setDeleting: React.Dispatch<React.SetStateAction<boolean>>;
    deleteWorksheet: (id: string) => Promise<void>;
    selectionType: SelectionType;
    workbook: CardSet;
    setWorkbook: React.Dispatch<React.SetStateAction<CardSet>>;
    setShowAddWorksheet: React.Dispatch<React.SetStateAction<boolean>>;
    selectedViewType: ViewType;
    worksheetsLoaded: boolean;
    worksheets: Worksheet[];
    setWorksheets: React.Dispatch<React.SetStateAction<Worksheet[]>>;
    permissions: UserPermissions[];
    setSelectionType: React.Dispatch<React.SetStateAction<string | null>>;
    progressArray?:
        | {
              stageId: string;
              progress: number;
          }[]
        | null;
    setProgressArray?: React.Dispatch<
        React.SetStateAction<
            | {
                  stageId: string;
                  progress: number;
              }[]
            | null
        >
    >;
    counts?: NumbersCount;
    setCounts?: React.Dispatch<React.SetStateAction<NumbersCount>>;
}

export const StyledBox = styled(Box)(({ theme }) => {
    return {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
    };
});
const processes = [
    {
        name: ProcessName.ACT,
        color: '#0D47A1',
        placement: {
            position: 'absolute',
            top: 0,
            left: 15,
        },
    },
    {
        name: ProcessName.OBSERVE,
        color: '#64B5F6',
        placement: {
            position: 'absolute',
            top: 0,
            right: 15,
        },
    },
    {
        name: ProcessName.ORIENT,
        color: '#2196F3',
        placement: {
            position: 'absolute',
            bottom: 0,
            right: 15,
        },
    },
    {
        name: ProcessName.DECIDE,
        color: '#1976D2',
        placement: {
            position: 'absolute',
            bottom: 0,
            left: 15,
        },
    },
];
const WorkbookMain = ({
    id,
    deleting,
    setDeleting,
    deleteWorksheet,
    selectionType,
    workbook,
    setWorkbook,
    setShowAddWorksheet,
    selectedViewType,
    worksheetsLoaded,
    worksheets,
    setWorksheets,
    permissions,
    setSelectionType,
    progressArray,
    setProgressArray,
    counts,
    setCounts,
}: WorkbookMainProps) => {
    const { isGlobalAdmin, user } = useContext(AppContext);
    const [selected, setSelected] = useState<UserProfile[]>([]);
    const [selectedEditors, setSelectedEditors] = useState<string[]>([]);
    const [allUsersEnabled, setAllUsersEnabled] = useState(false);
    const [orgId, setOrgId] = useState<string>('');
    const [edit, setEdit] = useState(false);
    const [addEditors, setAddEditors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filteredWorksheets, setFilteredWorksheets] = useState<Worksheet[]>(
        []
    );
    const theme = useTheme();
    const client = generateClient();

    useEffect(() => {
        if (user) {
            const userOrganisation = getUserOrganisation(user);
            setOrgId(userOrganisation);

            if (workbook?.orgEdit) {
                setAllUsersEnabled(true);
            }
        }

        workbook?.editors && setSelectedEditors(workbook.editors);
    }, []);

    const onSelect = (user: UserProfile) => {
        if (addEditors) {
            setSelectedEditors((prevSelectedEditors) => {
                if (prevSelectedEditors.includes(user.id)) {
                    return prevSelectedEditors.filter((id) => id !== user.id);
                } else {
                    return [...prevSelectedEditors, user.id];
                }
            });
        } else {
            setSelected([user]);
        }
    };

    const updateCardDetails = async () => {
        setLoading(true);
        const query = updateMutationMap[workbook.__typename];
        setEdit(false);
        const data = {
            id: workbook.id,
            owner:
                selected.length > 0
                    ? `${selected[0].id}::${selected[0].id}`
                    : `${workbook.owner}::${workbook.owner}`,
            organisation: workbook.organisation,
            editors: selectedEditors,
            ...('type' in workbook ? { type: workbook.type } : {}),
            ...(workbook.__typename === 'CardSet' && {
                capitalName: workbook.name.toUpperCase(),
            }),
            ...(workbook.__typename === 'CardSet' && {
                createdAt: workbook.createdAt,
            }),
            orgEdit: allUsersEnabled ? orgId : null,
        };
        try {
            await client.graphql<
                GraphQLQuery<UpdateCardMutation | UpdateCardSetMutation>
            >({
                query: query,
                variables: {
                    input: data,
                },
            });

            setLoading(false);
        } catch (err) {
            console.log(err);
        }
        setAddEditors(false);
    };

    useEffect(() => {
        const getValidWorksheetIds = () => {
            const step =
                processSteps.find(
                    (p) => p.name.toLowerCase() === selectionType.toLowerCase()
                )?.steps || [];

            return step.flatMap((s) => s.worksheets.map((ws) => ws.i));
        };
        const filterWorksheets = () => {
            const validWorksheetIds = getValidWorksheetIds();
            const filtered = worksheets.filter((ws) =>
                validWorksheetIds.includes(ws.typeId)
            );
            setFilteredWorksheets(filtered);
        };
        filterWorksheets();
    }, [selectionType, worksheets]);
    const frameProcess = progressArray?.find((item) => item?.stageId === '1');
    const frameProgress = frameProcess?.progress ?? 0;
    return (
        <Box sx={{ height: '580px', mb: 10 }}>
            {
                {
                    [SelectionType.WORKSHEETS]: (
                        <WorksheetSection
                            permissions={permissions}
                            setShowAddWorksheet={setShowAddWorksheet}
                            selectedViewType={selectedViewType}
                            worksheetsLoaded={worksheetsLoaded}
                            worksheets={worksheets}
                            setWorksheets={setWorksheets}
                            workbookId={workbook?.id}
                            deleteWorksheet={deleteWorksheet}
                            deleting={deleting}
                            setDeleting={setDeleting}
                            title={'All worksheets'}
                            color={theme.palette.primary.main}
                            selectionType={selectionType}
                            setSelectionType={setSelectionType}
                            workbook={workbook}
                            progressArray={progressArray}
                            setProgressArray={setProgressArray}
                        />
                    ),
                    [SelectionType.ACTIVITIES]: (
                        <Grid2
                            container
                            spacing={2}
                            sx={{ mt: 0.3, height: '660px' }}
                        >
                            <Grid2
                                key={uuidv4()}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={7}
                                xl={7}
                                sx={{
                                    height: '100%',
                                    position: 'relative',
                                }}
                            >
                                {' '}
                                <Box
                                    sx={{
                                        maxWidth: '540px',
                                        margin: '0 auto',
                                    }}
                                >
                                    {' '}
                                    <FoodaImage
                                        selectionType={selectionType}
                                        setSelectionType={setSelectionType}
                                        frameProgress={frameProgress}
                                    />
                                </Box>
                                {processes.map((process) => (
                                    <Process
                                        key={process.name}
                                        processName={process.name}
                                        processId={
                                            processSteps.find(
                                                (item) =>
                                                    item.name === process.name
                                            )?.id
                                        }
                                        setSelectionType={setSelectionType}
                                        headerColor={process.color}
                                        placement={process.placement}
                                        workbook={workbook}
                                        smallBox
                                        progressArray={progressArray}
                                        setProgressArray={setProgressArray}
                                    />
                                ))}
                            </Grid2>

                            <Grid2
                                key={uuidv4()}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                                sx={{
                                    height: '660px',
                                    overflow: 'auto',
                                    background: theme.palette.background.paper,
                                    borderRadius: '12px',
                                    p: 2,
                                }}
                            >
                                <CardActivity
                                    card={workbook as CardSet}
                                    context={EntityType.CardSet}
                                />
                            </Grid2>
                        </Grid2>
                    ),
                    [SelectionType.COMMENTS]: (
                        <Grid2
                            container
                            spacing={2}
                            sx={{ mt: 0.3, height: '660px' }}
                        >
                            <Grid2
                                key={uuidv4()}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={7}
                                xl={7}
                                sx={{
                                    height: '100%',
                                    position: 'relative',
                                }}
                            >
                                {' '}
                                <Box
                                    sx={{
                                        maxWidth: '540px',
                                        margin: '0 auto',
                                    }}
                                >
                                    <FoodaImage
                                        selectionType={selectionType}
                                        setSelectionType={setSelectionType}
                                        frameProgress={frameProgress}
                                    />
                                </Box>
                                {processes.map((process) => (
                                    <Process
                                        key={process.name}
                                        processName={process.name}
                                        processId={
                                            processSteps.find(
                                                (item) =>
                                                    item.name === process.name
                                            )?.id
                                        }
                                        setSelectionType={setSelectionType}
                                        headerColor={process.color}
                                        placement={process.placement}
                                        workbook={workbook}
                                        smallBox
                                        progressArray={progressArray}
                                        setProgressArray={setProgressArray}
                                    />
                                ))}
                            </Grid2>

                            <Grid2
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                                sx={{
                                    height: '660px',
                                    overflow: 'auto',
                                    background: theme.palette.background.paper,
                                    borderRadius: '12px',
                                    p: 2,
                                }}
                            >
                                <CardComments
                                    card={workbook as CardSet}
                                    context={EntityType.CardSet}
                                    counts={counts}
                                    setCounts={setCounts}
                                />
                            </Grid2>
                        </Grid2>
                    ),
                    [SelectionType.MEMBERS]: (
                        <Grid2
                            container
                            spacing={2}
                            sx={{ mt: 0.3, height: '660px' }}
                        >
                            <Grid2
                                xs={12}
                                sm={12}
                                md={12}
                                lg={7}
                                xl={7}
                                sx={{
                                    height: '100%',
                                    position: 'relative',
                                }}
                            >
                                {' '}
                                <Box
                                    sx={{
                                        maxWidth: '540px',
                                        margin: '0 auto',
                                    }}
                                >
                                    {' '}
                                    <FoodaImage
                                        selectionType={selectionType}
                                        setSelectionType={setSelectionType}
                                        frameProgress={frameProgress}
                                    />
                                </Box>
                                {processes.map((process) => (
                                    <Process
                                        key={process.name}
                                        processName={process.name}
                                        processId={
                                            processSteps.find(
                                                (item) =>
                                                    item.name === process.name
                                            )?.id
                                        }
                                        setSelectionType={setSelectionType}
                                        headerColor={process.color}
                                        placement={process.placement}
                                        workbook={workbook}
                                        smallBox
                                        progressArray={progressArray}
                                        setProgressArray={setProgressArray}
                                    />
                                ))}
                            </Grid2>
                            <Grid2
                                key={uuidv4()}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                                sx={{
                                    height: '660px',
                                    overflow: 'auto',
                                    background: theme.palette.background.paper,
                                    borderRadius: '12px',
                                    p: 2,
                                }}
                            >
                                <StyledSection
                                    sx={{ marginTop: '1em', height: '100%' }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            fontSize="1.25em"
                                            sx={{ alignSelf: 'flex-start' }}
                                        >
                                            Members
                                        </Typography>
                                        <Box
                                            sx={{
                                                mt: '20px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexGrow: 1,
                                                position: 'relative',
                                            }}
                                        >
                                            {workbook?.editors &&
                                                !allUsersEnabled &&
                                                workbook.editors.map(
                                                    (editor) => {
                                                        return (
                                                            <Box
                                                                key={editor}
                                                                sx={{ mb: 2 }}
                                                            >
                                                                {' '}
                                                                <UserAvatar
                                                                    userId={
                                                                        editor
                                                                    }
                                                                    isProfile={
                                                                        false
                                                                    }
                                                                    cardCategory="default"
                                                                />
                                                            </Box>
                                                        );
                                                    }
                                                )}

                                            {workbook?.orgEdit && (
                                                <Typography
                                                    variant="body1"
                                                    sx={{ my: '0.5rem' }}
                                                >
                                                    Organization-wide edit
                                                    permissions granted.
                                                </Typography>
                                            )}
                                            {workbook?.owner ===
                                                user?.tokens?.idToken?.payload
                                                    .sub && (
                                                <Button
                                                    onClick={() => {
                                                        setEdit(true);
                                                        setAddEditors(true);
                                                    }}
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={loading}
                                                    size="small"
                                                    sx={{
                                                        alignSelf: 'flex-end',
                                                        width: '191px',
                                                        p: 0,
                                                        position: 'absolute',
                                                        bottom: 20,
                                                    }}
                                                >
                                                    {loading ? (
                                                        <CircularProgress
                                                            size={12}
                                                        />
                                                    ) : (
                                                        'Update members'
                                                    )}
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </StyledSection>
                            </Grid2>
                        </Grid2>
                    ),
                    [SelectionType.OBSERVE]: (
                        <WorksheetSection
                            permissions={permissions}
                            setShowAddWorksheet={setShowAddWorksheet}
                            selectedViewType={selectedViewType}
                            worksheetsLoaded={worksheetsLoaded}
                            worksheets={filteredWorksheets}
                            setWorksheets={setWorksheets}
                            workbookId={workbook?.id}
                            deleteWorksheet={deleteWorksheet}
                            deleting={deleting}
                            setDeleting={setDeleting}
                            title={'Observe'}
                            color={'#64B5F6'}
                            description={descriptions[ProcessName.OBSERVE]}
                            selectionType={selectionType}
                            setSelectionType={setSelectionType}
                            workbook={workbook}
                            progressArray={progressArray}
                            setProgressArray={setProgressArray}
                        />
                    ),
                    [SelectionType.ORIENT]: (
                        <WorksheetSection
                            permissions={permissions}
                            setShowAddWorksheet={setShowAddWorksheet}
                            selectedViewType={selectedViewType}
                            worksheetsLoaded={worksheetsLoaded}
                            worksheets={filteredWorksheets}
                            setWorksheets={setWorksheets}
                            workbookId={workbook?.id}
                            deleteWorksheet={deleteWorksheet}
                            deleting={deleting}
                            setDeleting={setDeleting}
                            title={'Orient'}
                            color={'#2196F3'}
                            description={descriptions[ProcessName.ORIENT]}
                            selectionType={selectionType}
                            setSelectionType={setSelectionType}
                            workbook={workbook}
                            progressArray={progressArray}
                            setProgressArray={setProgressArray}
                        />
                    ),
                    [SelectionType.DECIDE]: (
                        <WorksheetSection
                            permissions={permissions}
                            setShowAddWorksheet={setShowAddWorksheet}
                            selectedViewType={selectedViewType}
                            worksheetsLoaded={worksheetsLoaded}
                            worksheets={filteredWorksheets}
                            setWorksheets={setWorksheets}
                            workbookId={workbook?.id}
                            deleteWorksheet={deleteWorksheet}
                            deleting={deleting}
                            setDeleting={setDeleting}
                            title={'Decide'}
                            color={'#1976D2'}
                            description={descriptions[ProcessName.DECIDE]}
                            selectionType={selectionType}
                            setSelectionType={setSelectionType}
                            workbook={workbook}
                            progressArray={progressArray}
                            setProgressArray={setProgressArray}
                        />
                    ),
                    [SelectionType.ACT]: (
                        <WorksheetSection
                            permissions={permissions}
                            setShowAddWorksheet={setShowAddWorksheet}
                            selectedViewType={selectedViewType}
                            worksheetsLoaded={worksheetsLoaded}
                            worksheets={filteredWorksheets}
                            setWorksheets={setWorksheets}
                            workbookId={workbook?.id}
                            deleteWorksheet={deleteWorksheet}
                            deleting={deleting}
                            setDeleting={setDeleting}
                            title={'Act'}
                            color={'#0D47A1'}
                            description={descriptions[ProcessName.ACT]}
                            selectionType={selectionType}
                            setSelectionType={setSelectionType}
                            workbook={workbook}
                            progressArray={progressArray}
                            setProgressArray={setProgressArray}
                        />
                    ),
                    [SelectionType.FRAME]: (
                        <WorksheetSection
                            permissions={permissions}
                            setShowAddWorksheet={setShowAddWorksheet}
                            selectedViewType={selectedViewType}
                            worksheetsLoaded={worksheetsLoaded}
                            worksheets={filteredWorksheets}
                            setWorksheets={setWorksheets}
                            workbookId={workbook?.id}
                            deleteWorksheet={deleteWorksheet}
                            deleting={deleting}
                            setDeleting={setDeleting}
                            title={'Frame'}
                            color={'#555555'}
                            description={descriptions[ProcessName.FRAME]}
                            selectionType={selectionType}
                            setSelectionType={setSelectionType}
                            workbook={workbook}
                            progressArray={progressArray}
                            setProgressArray={setProgressArray}
                        />
                    ),
                    [SelectionType.INFO]: (
                        <Grid2
                            container
                            spacing={2}
                            sx={{ mt: 0.3, height: '660px' }}
                        >
                            <Grid2
                                key={uuidv4()}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={7}
                                xl={7}
                                sx={{
                                    height: '100%',
                                    position: 'relative',
                                }}
                            >
                                {' '}
                                <Box
                                    sx={{
                                        maxWidth: '540px',
                                        margin: '0 auto',
                                    }}
                                >
                                    <FoodaImage
                                        selectionType={selectionType}
                                        setSelectionType={setSelectionType}
                                        frameProgress={frameProgress}
                                    />
                                </Box>
                                {processes.map((process) => (
                                    <Process
                                        key={process.name}
                                        processName={process.name}
                                        processId={
                                            processSteps.find(
                                                (item) =>
                                                    item.name === process.name
                                            )?.id
                                        }
                                        setSelectionType={setSelectionType}
                                        headerColor={process.color}
                                        placement={process.placement}
                                        workbook={workbook}
                                        smallBox
                                        progressArray={progressArray}
                                        setProgressArray={setProgressArray}
                                    />
                                ))}
                            </Grid2>

                            <Grid2
                                key={uuidv4()}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                                sx={{
                                    height: '660px',
                                    overflow: 'auto',
                                    background: theme.palette.background.paper,
                                    borderRadius: '12px',
                                    p: 2,
                                }}
                            >
                                Workbook info
                            </Grid2>
                        </Grid2>
                    ),
                }[selectionType]
            }
            <StyledModal
                key="edit-card-info"
                open={edit}
                onClose={() => {
                    setEdit(false);
                    setAddEditors(false);
                    workbook.editors && setSelectedEditors(workbook.editors);
                }}
                disableEnforceFocus
                sx={{
                    zIndex: 1500,
                }}
            >
                <Box>
                    {edit && (
                        <ModalContainer sx={{ maxWidth: '48rem' }}>
                            <CardPermissions
                                owner={workbook.owner}
                                addEditors={addEditors}
                                cardCategory="default"
                                selected={selected}
                                selectedEditors={selectedEditors}
                                setSelectedEditors={setSelectedEditors}
                                onSelect={onSelect}
                                onSave={() => {
                                    setWorkbook((workbook) => {
                                        const newWorkbook = { ...workbook };
                                        newWorkbook.editors = selectedEditors;
                                        return newWorkbook;
                                    });
                                    updateCardDetails();
                                }}
                                onClose={() => {
                                    setEdit(false);
                                    setAddEditors(false);
                                    workbook.editors &&
                                        setSelectedEditors(workbook.editors);
                                }}
                                card={workbook}
                                allUsersEnabled={allUsersEnabled}
                                setAllUsersEnabled={setAllUsersEnabled}
                            />
                        </ModalContainer>
                    )}
                </Box>
            </StyledModal>
        </Box>
    );
};

export default WorkbookMain;
