import React, { useContext, useState } from 'react';
import { Attribute, Card, CardSet, CardType } from '../../../API';
import { Box, Grid, Typography } from '@mui/material';
import UserAvatar from '../../profile/UserAvatar';
import { getCategoryHex, getCategoryHexText } from '../../../helpers/category';
import { truncateText } from '../../../helpers/utils';
import { AppContext } from '../../contexts';
import { PlaybookScore } from './PlaybookScore';
import { Link } from 'react-router-dom';
import CardDetailsCard from '../../cards/CardDetails/CardDetailsCard';

interface CardDetailsFromWorksheetProps {
    card: Card;
    frontCard?: boolean;
    pptView?: boolean;
    slideNumber?: number;
    totalSlides?: number;
}
export const removeQuotes = (attr: Attribute) => {
    let valueToDisplay;
    if (
        attr?.value &&
        attr?.value.startsWith('"') &&
        attr.value.endsWith('"')
    ) {
        valueToDisplay = attr.value.slice(1, -1);
    }
    return valueToDisplay;
};
export const getAttributeName = (attr: Attribute, cardTypes: CardType[]) => { 
    if (!attr) return;
    const headerNameParts = attr?.attributeDefinitionID.split('-');
    const cardType =
        headerNameParts &&
        cardTypes.find((item) => item.id === headerNameParts[0]);
    const title = cardType?.attributeDefinitions?.find(
        (item: any) => item && item.id === attr.attributeDefinitionID
    )?.name;
    return title;
};
const CardDetailsFromWorksheet = ({
    card,
    frontCard,
    pptView,
    slideNumber = 1,
    totalSlides,
}: CardDetailsFromWorksheetProps) => {
    const multipleSlides = slideNumber > 1;
    const { cardTypes } = useContext(AppContext);
    const cardBackground = getCategoryHex(card.cardToCardCategoryId);
    const { cardTypeObject, users } = useContext(AppContext);

    const { description, attributes, toScoreData } = card;
    const noData =
        !description &&
        attributes &&
        attributes.length === 0 &&
        toScoreData?.items.length === 0;
    const horizontalLayout =
        attributes &&
        attributes.length === 0 &&
        toScoreData?.items.length === 0;
    const displayOnlyAttributes =
        !description &&
        toScoreData?.items.length === 0;
    const displayOnlyScores =
        !description &&
        attributes?.length === 0 &&
        toScoreData;
    const { attributeDefinitions } =
        cardTypeObject[card.cardToCardTypeId ?? -1] ?? {};

    const styles = {
        borderRadius: frontCard ? '4px' : '16px',
        cardHeight: frontCard ? '145px' : null,
        px: frontCard ? 0.3 : 2,
        py: frontCard ? 0.3 : 1,
        p: frontCard ? 0.5 : 2,
        nameFont: frontCard ? '8px' : 'inherit',
        overflow: frontCard || pptView ? 'hidden' : 'auto',
        spacing: frontCard ? 0.5 : 2,
        fontSize: frontCard ? '5px' : '14px',
        smallFontSize: frontCard ? '5px' : '12px',
        mb: frontCard ? 0.5 : 2,
        smallMb: frontCard ? 0.5 : 1,
        mediumMb: frontCard ? 0.5 : 1.5,
    };

    const getValue = (id: string) => {
        const attributeType = attributeDefinitions?.find(
            (item) => item?.id === id
        )?.attributeType;

        const attr = card?.attributes?.find(
            (attr) => attr?.attributeDefinitionID === id
        );

        if (attr) {
            if (attributeType === 'URL') {
                return (
                    <Link
                        to={attr?.value?.replace(/"/g, '') ?? '#'}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Typography variant="body2" fontSize="0.875em">
                            {attr?.value
                                ?.replace(/"/g, '')
                                .replace(/^https?:\/\//, '')}
                        </Typography>
                    </Link>
                );
            } else if (attributeType === 'User') {
                const user = users.find((user) => user.id === attr?.value);
                if (user) {
                    return `${user.firstName} ${user.lastName}`;
                }
            } else if (
                attributeType === 'Card' ||
                attributeType === 'CardSet'
            ) {
                return (
                    <CardDetailsCard
                        id={removeQuotes(attr) ?? ''}
                        type={attributeType}
                        playbookCard={true}
                    />
                );
            } else {
                return removeQuotes(attr);
            }
        } else {
            return 'No value';
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: styles.borderRadius,
                mb: '1rem',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                maxHeight: '100%',
                overflow: 'hidden',
                height: pptView
                    ? '500px'
                    : noData
                    ? '100px'
                    : horizontalLayout
                    ? '200px'
                    : displayOnlyAttributes
                    ? '250px'
                    : displayOnlyScores && toScoreData?.items?.length <= 2
                    ? '300px'
                    : styles.cardHeight,
                width: '100%',
            }}
        >
            <Box
                sx={{
                    px: styles.px,
                    py: styles.py,
                    display: 'flex',
                    backgroundColor: cardBackground,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTopLeftRadius: styles.borderRadius,
                    borderTopRightRadius: styles.borderRadius,
                    color: getCategoryHexText(card.cardToCardCategoryId),
                    zIndex: 1,
                }}
            >
                <Typography variant="body1" sx={{ fontSize: styles.nameFont }}>
                    {truncateText(card.name, 45)}
                    {totalSlides && totalSlides > 1 && (
                        <span
                            style={{ fontSize: '12px', marginLeft: 2 }}
                        >{`(${slideNumber} of ${totalSlides})`}</span>
                    )}
                </Typography>

                <UserAvatar
                    userId={card.owner}
                    cardCategory="default"
                    smallAvatar={frontCard}
                />
            </Box>
            <Grid
                container
                sx={{
                    p: styles.p,
                    overflow: styles.overflow,
                    height: frontCard ? '100%' : '25rem',
                }}
                spacing={styles.spacing}
            >
                {description  && (
                    <Grid item xs={12} md={horizontalLayout ? 12 : 4}>
                        {!multipleSlides && (
                            <Box
                                sx={{
                                    display: horizontalLayout
                                        ? 'flex'
                                        : 'block',
                                    gap: 2,
                                }}
                            >
                                {card.description && (
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            mb: styles.mb,
                                            fontSize: styles.fontSize, 
                                        }}
                                    >
                                        {truncateText(
                                            card.description,
                                            frontCard ? 50 : 200
                                        )}
                                    </Typography>
                                )}
                                <Box>
                                </Box>
                            </Box>
                        )}
                    </Grid>
                )}
                {attributes && attributes.length > 0 && (
                    <Grid item xs={12} md={3}>
                        {!multipleSlides &&
                            card?.attributes
                                ?.filter(
                                    (attribute) =>
                                        !!cardTypeObject[
                                            card.cardToCardTypeId
                                        ]?.attributeDefinitions?.find(
                                            (attributeDef) =>
                                                attributeDef?.id ===
                                                attribute?.attributeDefinitionID
                                        )
                                )
                                .map((attr) => {
                                    return (
                                        attr && (
                                            <Box
                                                key={
                                                    attr?.attributeDefinitionID
                                                }
                                                sx={{
                                                    mb: styles.mediumMb,
                                                    ml:
                                                        toScoreData?.items
                                                            .length === 0 &&
                                                        !displayOnlyAttributes
                                                            ? 5
                                                            : 0,
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontSize:
                                                            styles.fontSize,
                                                        mb: styles.smallMb,
                                                    }}
                                                >
                                                    {getAttributeName(
                                                        attr,
                                                        cardTypes
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontSize:
                                                            styles.smallFontSize,
                                                    }}
                                                >
                                                    {getValue(
                                                        attr?.attributeDefinitionID as string
                                                    )}
                                                </Typography>
                                            </Box>
                                        )
                                    );
                                })}
                    </Grid>
                )}
                <Grid item xs={12} md={5}>
                    <PlaybookScore
                        card={card}
                        frontCard={frontCard}
                        pptView={pptView}
                    />
                </Grid>
                {noData && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                mt: frontCard ? 0 : 2,
                                fontSize: frontCard ? '10px' : '',
                            }}
                        >
                            No data to display
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Box>
    );
};

export default CardDetailsFromWorksheet;
