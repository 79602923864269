import { Box, InputAdornment, IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { ScoreDatum, ScoreType } from '../../../API';
import { dateFromISO, isValid } from '../../../helpers/utils';
import { useContext } from 'react';
import { AppContext } from '../../contexts';

interface ChartFormProps {
    newScoreDatum: ScoreDatum;
    handleNewDateInputChange?: (date: DateTime | null) => void;
    disableDate: (date: DateTime) => boolean;
    setNewScoreDatum: (data: ScoreDatum) => void;
    reverseMaxMin: boolean;
    insertDate?: () => void;
    scoreType: ScoreType;
    max: number | undefined;
    min: number | undefined;
    playbookPageOptions?: any;
    scoreValue?: number;
    scores?: {
        x: number;
        y: number;
    };
    setScores?: React.Dispatch<
        React.SetStateAction<{
            x: number;
            y: number;
        }>
    >;
    axis?: string;
    cardScore?: number;
    setCardScore?: (value: number) => void;
}

const ChartForm = ({
    newScoreDatum,
    handleNewDateInputChange,
    disableDate,
    setNewScoreDatum,
    reverseMaxMin,
    insertDate,
    scoreType,
    max,
    min,
    playbookPageOptions,
    scoreValue,
    scores,
    setScores,
    cardScore,
    setCardScore,
    axis,
}: ChartFormProps) => {
    const date = new Date();
    const { userProfileObject } = useContext(AppContext);

    const handleScoreUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event) return;
        if (setCardScore) {
            setCardScore(parseInt(event.target.value));
        } else if (scores && setScores) {
            const propertyName = axis === 'x' ? 'x' : 'y';
            setScores((prevScores) => ({
                ...prevScores,
                [propertyName]: parseInt(event.target.value),
            }));
        } else {
            setNewScoreDatum({
                ...newScoreDatum,
                value: parseInt(event.target.value),
            });
        }
    };
    return (
        <>
            <Box
                sx={{
                    pt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            ></Box>
            <Box
                sx={{
                    display: 'flex',
                    flexShrink: 0,
                    alignItems: 'center',
                    pt: 2,
                    pb: 2,
                }}
            >
                {!playbookPageOptions && (
                    <DatePicker
                        label={'Select Date'}
                        views={['month', 'year']}
                        autoFocus
                        value={dateFromISO(newScoreDatum.date)}
                        onChange={(date: DateTime | null) =>
                            handleNewDateInputChange &&
                            handleNewDateInputChange(date)
                        }
                        sx={{
                            '.MuiPickersPopper-root': {
                                zIndex: 2000,
                            },
                            mr: 2,
                        }}
                        slotProps={{
                            popper: {
                                sx: {
                                    zIndex: 2000,
                                },
                            },
                            textField: {
                                size: 'small',
                                InputLabelProps: {
                                    shrink: true,
                                },
                            },
                        }}
                        shouldDisableMonth={(date: DateTime) =>
                            disableDate(date)
                        }
                    />
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <TextField
                        sx={{
                            mr: 1,
                            width: '100%',
                        }}
                        size="small"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => handleScoreUpdate(event)}
                        value={
                            cardScore != null && setCardScore
                                ? cardScore
                                : scores && setScores
                                ? scoreValue || ''
                                : newScoreDatum.value || ''
                        }
                        InputProps={{
                            startAdornment: scoreType ===
                                ScoreType.Currency && (
                                <InputAdornment position="start">
                                    £
                                </InputAdornment>
                            ),
                            type: 'number',
                            inputProps: {
                                min: min,
                                max: max,
                            },
                            'aria-labelledby': 'input-slider',
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={
                            !isValid(
                                newScoreDatum.value,
                                min,
                                max,
                                reverseMaxMin
                            )
                        }
                    />
                </Box>
                {!playbookPageOptions && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <TextField
                            sx={{
                                width: '100%',
                                mr: 1,
                            }}
                            size="small"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                                setNewScoreDatum({
                                    ...newScoreDatum,
                                    comment: event.target.value,
                                    commentDate: date
                                        .toISOString()
                                        .split('T')[0],
                                    commentAuthor:
                                        userProfileObject?.getUserProfile.id,
                                })
                            }
                            value={newScoreDatum.comment}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="Commentary"
                            inputProps={{ maxLength: 1000 }}
                        />
                    </Box>
                )}
                {!playbookPageOptions && (
                    <IconButton
                        onClick={insertDate}
                        title="Insert point"
                        color="primary"
                        disabled={
                            !!!newScoreDatum.date ||
                            newScoreDatum.value == null ||
                            !isValid(
                                newScoreDatum.value,
                                min,
                                max,
                                reverseMaxMin
                            )
                        }
                    >
                        <AddIcon
                            sx={{
                                fontSize: '3rem',
                                width: '40px',
                                height: '40px',
                            }}
                        />
                    </IconButton>
                )}
            </Box>
        </>
    );
};

export default ChartForm;
