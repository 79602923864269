import {
    Box,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ManagerControls from '../../cards/ManagerControls';
import { ViewType } from '../../layouts/PageView';
import CardGridView from '../../cards/views/CardGridView';
import { cardSetToCardComponentProps } from '../../../pages/card-sets';
import { cardToCardComponentProps } from '../../../pages/cards';
import CardListView from '../../cards/views/CardListView';
import {
    CardComponentData,
    CardSetComponentData,
    PageIdentifier,
} from '../../cards/cardTypes';
import { ReactComponent as CardIcon } from '../../../assets/icons/CardManager.svg';
import { ReactComponent as CardSetIcon } from '../../../assets/icons/CardSets.svg';
import TileViewIcon from '../../../assets/images/menubar/grid-view-unselected.png';
import TileViewIconActive from '../../../assets/images/menubar/grid-view.png';
import ListViewIcon from '../../../assets/images/menubar/list-view-unselected.png';
import ListViewIconActive from '../../../assets/images/menubar/list-view.png';
import { IconStates } from '../../../hooks/useIconStates';
import { ActiveIcons } from '../../cards/views/CardTypeLayout';
import { FilterCards } from '../../../hooks/useFilterCards';
import { Card, CardSet } from '../../../API';
import SearchBox from './SearchBox';
import { workBookToCardComponentProps } from '../../../pages/workbooks';
import { RelationshipFormType } from '../../cards/CardRelationships';

interface PrimaryColumnProps {
    selectBy: 'card' | 'card_set' | 'workbook';
    setSelectBy: React.Dispatch<
        React.SetStateAction<'card' | 'card_set' | 'workbook'>
    >;
    activeIcons: IconStates;
    toggleIcon: (
        iconName: keyof ActiveIcons,
        selectedSortAction: string
    ) => void;
    selectedViewType: ViewType;
    setSelectedViewType: React.Dispatch<React.SetStateAction<ViewType>>;
    cardFilterCriteria?: FilterCards;
    setCardFilterCriteria?: React.Dispatch<React.SetStateAction<FilterCards>>;
    cardSetFilterCriteria: FilterCards;
    setCardSetFilterCriteria: React.Dispatch<React.SetStateAction<FilterCards>>;
    cards?: Card[];
    cardSets: CardSet[];
    handleSelect: (item: CardComponentData | CardSetComponentData) => void;
    clickedItems: any;
    token: string | null;
    onLoadMore: (initialLoad?: boolean) => Promise<void>;
    loading: boolean;
    existingWorkbookCards?: Card[];
    searchTerm: string | null;
    setSearchTerm: React.Dispatch<React.SetStateAction<string | null>>;
    title: string;
    relationshipForm?: RelationshipFormType;
}

const PrimaryColumn = ({
    selectBy,
    setSelectBy,
    activeIcons,
    toggleIcon,
    selectedViewType,
    setSelectedViewType,
    cardFilterCriteria,
    setCardFilterCriteria,
    cardSetFilterCriteria,
    setCardSetFilterCriteria,
    cards,
    cardSets,
    handleSelect,
    clickedItems,
    token,
    onLoadMore,
    loading,
    existingWorkbookCards,
    searchTerm,
    setSearchTerm,
    title,
    relationshipForm,
}: PrimaryColumnProps) => {
    const theme = useTheme();
    const iconStyles = { width: 40, height: 40, display: 'inline' };
    useEffect(() => {
        localStorage.setItem('addExistingCards_selectBy', selectBy);
    }, [selectBy]);

    useEffect(() => {
        if (relationshipForm === RelationshipFormType.CARD) {
            setSelectBy('card');
        } else if (relationshipForm === RelationshipFormType.CARD_SET) {
            setSelectBy('card_set');
        } else {
            localStorage.setItem('addExistingCards_viewType', selectedViewType);
        }
    }, [selectedViewType]);

    const [searchOpen, setSearchOpen] = useState(false);
    return (
        <>
            {!!!relationshipForm && (
                <Box
                    sx={{
                        background:
                            theme.palette.mode === 'dark'
                                ? theme.palette.background.default
                                : '#efefef',
                        py: 1,
                        px: 3,
                        borderTop: 'solid 1px #ccc',
                        borderBottom: 'solid 1px #ccc',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
            )}
            <Box sx={{ height: '100%' }}>
                <Box
                    sx={{
                        px: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            mr: selectBy !== 'workbook' ? 1 : 0,
                            display: 'flex',
                        }}
                    >
                        {selectBy !== 'workbook' && (
                            <>
                                {' '}
                                {!!!relationshipForm && (
                                    <Tooltip
                                        title="Select by card set"
                                        placement="top"
                                    >
                                        <IconButton
                                            aria-label=""
                                            id="menu-button"
                                            onClick={() =>
                                                setSelectBy('card_set')
                                            }
                                            sx={{
                                                ml: 0,
                                                color:
                                                    selectBy === 'card_set'
                                                        ? theme.palette.primary
                                                              .main
                                                        : theme.palette
                                                              .grey[500],
                                                svg: {
                                                    height: '30px',
                                                    width: '30px',
                                                },
                                            }}
                                        >
                                            <CardSetIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {!!!relationshipForm && (
                                    <Tooltip
                                        title="Select by card"
                                        placement="top"
                                    >
                                        <IconButton
                                            aria-label=""
                                            id="menu-button"
                                            onClick={() => setSelectBy('card')}
                                            sx={{
                                                ml: -2,
                                                color:
                                                    selectBy === 'card'
                                                        ? theme.palette.primary
                                                              .main
                                                        : theme.palette
                                                              .grey[500],
                                                svg: {
                                                    height: '30px',
                                                    width: '30px',
                                                },
                                            }}
                                        >
                                            <CardIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </>
                        )}
                        <SearchBox
                            setSearchTerm={(term) => setSearchTerm(term)}
                        />
                    </Box>
                    <Box sx={{ ml: -15 }}>
                        <ManagerControls
                            activeIcons={activeIcons}
                            toggleIcon={toggleIcon}
                            pageIdentifier={
                                selectBy === 'card'
                                    ? PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD
                                    : selectBy === 'workbook'
                                    ? PageIdentifier.CONNECT_WORKBOOK_TO_PLAYBOOK_FORM
                                    : PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD_SET
                            }
                            filterCriteria={
                                selectBy === 'card'
                                    ? cardFilterCriteria
                                    : cardSetFilterCriteria
                            }
                            setFilterCriteria={
                                selectBy === 'card'
                                    ? setCardFilterCriteria
                                    : setCardSetFilterCriteria
                            }
                        />
                    </Box>
                    <Box>
                        {
                            <Tooltip title="Grid view">
                                <IconButton
                                    aria-label="Grid View"
                                    onClick={() =>
                                        setSelectedViewType(ViewType.GRID)
                                    }
                                >
                                    <img
                                        src={
                                            selectedViewType === ViewType.GRID
                                                ? TileViewIconActive
                                                : TileViewIcon
                                        }
                                        style={iconStyles}
                                    />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            <Tooltip title="List view">
                                <IconButton
                                    aria-label="List View"
                                    onClick={() =>
                                        setSelectedViewType(ViewType.LIST)
                                    }
                                    sx={{ ml: -3 }}
                                >
                                    <img
                                        src={
                                            selectedViewType === ViewType.LIST
                                                ? ListViewIconActive
                                                : ListViewIcon
                                        }
                                        style={iconStyles}
                                    />
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>
                </Box>
                <Box
                    sx={{
                        py: 1,
                        px: 3,
                        height: 'calc(100% - 100px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    {(!!searchTerm && !cards?.length && selectBy === 'card') ||
                    (!!searchTerm &&
                        !cardSets?.length &&
                        selectBy === 'workbook') ? (
                        <Box
                            sx={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ fontSize: '2rem' }}
                            >
                                No results found
                            </Typography>
                        </Box>
                    ) : selectedViewType === ViewType.GRID ? (
                        <CardGridView
                            pageIdentifier={
                                PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD
                            }
                            cardItems={
                                selectBy === 'workbook'
                                    ? cardSets?.map((cardSet) =>
                                          workBookToCardComponentProps(cardSet)
                                      ) || []
                                    : selectBy === 'card_set' ||
                                      relationshipForm ===
                                          RelationshipFormType.CARD_SET
                                    ? cardSets?.map((cardSet) =>
                                          cardSetToCardComponentProps(cardSet)
                                      ) || []
                                    : cards?.map((card) =>
                                          cardToCardComponentProps(card)
                                      ) || []
                            }
                            mediumCard={true}
                            xl={3}
                            md={3}
                            sm={3}
                            xs={6}
                            lg={3}
                            handleSelect={handleSelect}
                            clickedItems={clickedItems}
                            token={token}
                            loading={loading}
                            onLoadMore={onLoadMore}
                        />
                    ) : (
                        <CardListView
                            pageIdentifier={
                                PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD
                            }
                            cardItems={
                                selectBy === 'workbook'
                                    ? cardSets?.map((cardSet) =>
                                          workBookToCardComponentProps(cardSet)
                                      ) || []
                                    : selectBy === 'card_set' ||
                                      relationshipForm ===
                                          RelationshipFormType.CARD_SET
                                    ? cardSets?.map((cardSet) =>
                                          cardSetToCardComponentProps(cardSet)
                                      ) || []
                                    : cards?.map((card) =>
                                          cardToCardComponentProps(card)
                                      ) || []
                            }
                            mediumCard={true}
                            handleSelect={handleSelect}
                            clickedItems={clickedItems}
                        />
                    )}
                </Box>
            </Box>
        </>
    );
};

export default PrimaryColumn;
