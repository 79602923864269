import {
    PropsWithChildren,
    useState,
    useEffect,
    useMemo,
    useContext,
} from 'react';
import { Box, darken, styled } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';

import {
    Category,
    getCategoryHex,
    getCategoryHexText,
} from '../../helpers/category';
import { getScoreColorSx } from '../../helpers/scores';
import { ReactComponent as ContentIcon } from '../../assets/icons/Content.svg';
import { ReactComponent as DetailsIcon } from '../../assets/icons/Details.svg';
import { ReactComponent as ScoresIcon } from '../../assets/icons/Scores.svg';
import { ReactComponent as RelationshipsIcon } from '../../assets/icons/Relationships.svg';
import { ReactComponent as ActivityIcon } from '../../assets/icons/Event.svg';
import { ReactComponent as CommentsIcon } from '../../assets/icons/Comments.svg';
import CardActions from './CardActions';
import HeaderScore from '../playbooks/HeaderScore';
import CardBase from './CardBase';
import CardMenu from './CardMenu';
import CardSummary from './CardSummary';
import {
    CardComponentProps,
    CardComponentType,
    CardPage,
    PageIdentifier,
} from './cardTypes';
import { getUserPermissions } from '../../helpers/permissions';
import { UserPermissions } from '../../globals';
import { StyledModal } from '../Modal';
import ModalContainer from '../ModalContainer';
import CreateCardSet from '../forms/CreateCardSet/index';
import CardHelp from './CardHelp';
import { getCardMenu } from '../../helpers/card';
import { AppContext, WorksheetContext } from '../contexts';
import { Card } from '../../API';

const StyledLayerOne = styled('div')<{ categoryId: string }>(
    ({ categoryId }) => ({
        borderRadius: '16px',
        backgroundColor: darken(getCategoryHex(categoryId), 0.1),
        boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
        position: 'absolute',
        zIndex: '-1',
        width: '100%',
        height: '100%',
        top: '0.150rem',
        left: '0.250rem',
    })
);

const StyledLayerTwo = styled('div')<{ categoryId: string }>(
    ({ categoryId }) => ({
        borderRadius: '16px',
        backgroundColor: darken(getCategoryHex(categoryId), 0.2),
        boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
        position: 'absolute',
        zIndex: '-2',
        width: '100%',
        height: '100%',
        top: '0.350rem',
        left: '0.450rem',
    })
);

export const Lightbox = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(5px)',
    zIndex: 1300,
}));

const CardComponent = (props: PropsWithChildren<CardComponentProps>) => {
    const { handleRemoveFromWorkbook, handleRemoveFromWorksheet } =
        useContext(WorksheetContext);

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
    const [helpTextAnchor, setHelpTextAnchor] = useState<HTMLElement | null>();
    const [permissions, setPermissions] = useState<UserPermissions[]>([]);
    const [cardSetModalOpen, setCardSetModalOpen] = useState<boolean>(false);
    const { user } = useContext(AppContext);
    const {
        data,
        onClick,
        showPage,
        handleEdit,
        expanded,
        addCardToWorksheet,
        handleDelete,
        handleRemove,
        handleConvert,
        handleCopy,
        handleClose,
        setCardFrom,
        deletedCard,
        multiSelectedCards,
        setMultiSelectedCards,
        cardsObject,
        pageIdentifier,
        mediumCard,
        handleSelect,
        clickedItems,
        worksheetCardsObject,
        workbookPermissions,
        worksheetCard,
    } = props;

    const navigate = useNavigate();
    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };
    const setHelpTextModal = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setHelpTextAnchor(e.currentTarget);
        } else {
            setHelpTextAnchor(null);
        }
    };

    const {
        id,
        description,
        // cardCategory,
        cardComponentType,
        cardCategoryId,
        cardToCardCategoryId,
        cardTypeId,
        cardTypeName,
        link,
        name,
        scoreDefinition,
        scoreValue,
        scoreValues,
        organisation,
        scoreName,
    } = data;
    const isSelected = useMemo(() => {
        if (clickedItems) {
            return clickedItems.some((card) => card.id === id);
        } else if (multiSelectedCards) {
            return multiSelectedCards.some((card) => card.id === id);
        }
    }, [multiSelectedCards, id]);

    const textColor = getCategoryHexText(cardCategoryId);

    const handleClick = (cardPage?: CardPage) => {
        if (!!onClick) {
            onClick(data.id, cardPage);
        }
    };
    const setMenuOpen = () => {
        setMenuAnchor(null);
        setCardSetModalOpen(true);
    };

    const menuItems =
        cardComponentType === CardComponentType.CARD &&
        pageIdentifier !== PageIdentifier.WORKSHEET
            ? [
                  {
                      text: 'Create card set',
                      action: () => setMenuOpen(),
                  },
              ]
            : [];
    addCardToWorksheet &&
        menuItems.push({
            text: 'Add card to worksheet',
            action: () => addCardToWorksheet(),
        });
    setCardFrom &&
        cardsObject &&
        menuItems.push({
            text: 'Create card from',
            action: () => setCardFrom(cardsObject[id] as Card),
        });
    handleRemove &&
        permissions.includes(UserPermissions.EDIT) &&
        menuItems.push({
            text:
                pageIdentifier === PageIdentifier.CARD_SET_INSIDE
                    ? 'Remove card from set'
                    : 'Remove from workbook',
            action: () => handleRemove(id),
        });

    if (handleConvert && permissions.includes(UserPermissions.EDIT)) {
        menuItems.push({
            text: 'Convert card',
            action: () => handleConvert(id),
        });
    }
    handleRemoveFromWorkbook &&
        worksheetCardsObject &&
        workbookPermissions?.includes(UserPermissions.EDIT) &&
        menuItems.push({
            text: 'Remove from workbook',
            action: () => {
                handleRemoveFromWorkbook(worksheetCardsObject[data.id]);
                handleClose && handleClose();
            },
        });
    handleRemoveFromWorksheet &&
        workbookPermissions?.includes(UserPermissions.EDIT) &&
        menuItems.push({
            text: 'Remove from worksheet',
            action: () => {
                handleRemoveFromWorksheet(data.id);
                handleClose && handleClose();
            },
        });
    const onCreateCardSet = (id?: string) => {
        setCardSetModalOpen(false);
        navigate(`/card-sets${id ? `/${id}` : ``}`);
    };

    useEffect(() => {
        if (user) {
            const userPermissions = getUserPermissions(
                user?.tokens?.idToken?.payload?.sub ?? '',
                data
            );

            setPermissions(userPermissions);
        }
    }, [data, user]);

    const menuActions = getCardMenu({
        handleClick,
        link,
        showPage,
        deletedCard,
        cardComponentType,
        scoreDefinition,
    });

    const handleHeaderClick = () => {
        if (
            cardComponentType === CardComponentType.CARD &&
            setMultiSelectedCards &&
            organisation &&
            permissions.includes(UserPermissions.DELETE)
        ) {
            setMultiSelectedCards((prevCards) => {
                const newCard = {
                    id: id,
                    organisation: organisation,
                    cardTypeId: cardsObject
                        ? cardsObject[id].toCardType.id
                        : undefined,
                    cardCategoryId: cardsObject
                        ? cardsObject[id].toCardCategory.id
                        : undefined,
                };
                const index = prevCards.findIndex((card) => card.id === id);

                if (index > -1) {
                    return [
                        ...prevCards.slice(0, index),
                        ...prevCards.slice(index + 1),
                    ];
                } else {
                    return [...prevCards, newCard];
                }
            });
        }
    };

    return (
        <>
            {helpTextAnchor && <Lightbox />}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}
            >
                {isSelected && (
                    <Box
                        onClick={
                            handleSelect
                                ? () => handleSelect(data)
                                : handleHeaderClick
                        }
                        sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            borderRadius: '16px',
                            top: 0,
                            left: 0,
                            backgroundColor: `rgba(50, 50, 50, 0.5)`,
                            zIndex: 1009,
                            cursor: 'pointer',
                        }}
                    />
                )}{' '}
                <CardBase
                    mediumCard={mediumCard}
                    worksheetCard={worksheetCard}
                    headerRight={
                        scoreName && (
                            <HeaderScore
                                borderColor={getScoreColorSx(
                                    data.scoreValue,
                                    data.scoreDefinition?.minimumValue,
                                    data.scoreDefinition?.maximumValue,
                                    typeof scoreValue === 'number' &&
                                        scoreValue < 0,
                                    true
                                )}
                                name={scoreName}
                                value={scoreValue}
                                type={data.scoreDefinition?.scoreType}
                                mediumCard={mediumCard}
                                cardTypeName={cardTypeName}
                            />
                        )
                    }
                    data={{
                        id,
                        name,
                        category: cardCategoryId as Category,
                        cardTypeName,
                        cardComponentType,
                        organisation,
                    }}
                    expanded={expanded}
                    subHeader={showPage}
                    handleClose={handleClose}
                    handleHeaderClick={handleHeaderClick}
                    permissions={permissions}
                    {...(cardComponentType === CardComponentType.CARD && {
                        setHelpTextModal,
                    })}
                >
                    {props.children || (
                        <CardActionArea
                            {...{
                                onClick:
                                    link && !mediumCard
                                        ? () => {
                                              window.scroll(0, 0);
                                              navigate(link);
                                          }
                                        : !link && !mediumCard
                                        ? () => handleClick(CardPage.DETAILS)
                                        : handleSelect
                                        ? () => handleSelect(data)
                                        : undefined,
                            }}
                            sx={{
                                overflow: 'hidden',
                                borderRadius: 'initial',
                                cursor: 'pointer',
                            }}
                        >
                            <CardSummary
                                description={description}
                                name={name}
                                categoryId={cardCategoryId as Category}
                                cardTypeId={cardTypeId}
                                mediumCard={mediumCard}
                            />
                        </CardActionArea>
                    )}

                    {cardComponentType === CardComponentType.CARD_SET && (
                        <>
                            <StyledLayerOne categoryId={cardCategoryId} />
                            {!mediumCard && (
                                <StyledLayerTwo categoryId={cardCategoryId} />
                            )}
                        </>
                    )}

                    {!mediumCard && (
                        <CardActions
                            textColor={textColor}
                            items={menuActions}
                            setMenu={setMenu}
                            expanded={expanded}
                            deletedCard={deletedCard}
                            multiDelete={isSelected}
                            worksheetCard={worksheetCard}
                        />
                    )}

                    {menuAnchor &&
                        (menuItems.length || handleCopy || handleDelete) && (
                            <CardMenu
                                permissions={permissions}
                                anchor={menuAnchor}
                                data={{
                                    organisation: organisation || '',
                                    id,
                                }}
                                {...(menuItems.length && {
                                    menuItems: menuItems,
                                })}
                                {...(!!handleCopy && {
                                    handleCopy: () => handleCopy(id),
                                })}
                                handleEdit={() => handleEdit && handleEdit(id)}
                                handleDelete={handleDelete}
                                setMenu={setMenu}
                                multiDelete={isSelected}
                            />
                        )}
                    {helpTextAnchor && (
                        <CardHelp
                            anchor={helpTextAnchor}
                            setHelpTextModal={setHelpTextModal}
                            setHelpTextAnchor={setHelpTextAnchor}
                            cardTypeId={cardTypeId}
                            cardCategoryId={cardCategoryId}
                        />
                    )}
                </CardBase>
                <StyledModal
                    key="modal"
                    open={cardSetModalOpen}
                    onClose={() => setCardSetModalOpen(false)}
                    sx={{ zIndex: 1401 }}
                >
                    <Box>
                        {cardSetModalOpen && (
                            <ModalContainer sx={{ maxWidth: '35rem' }}>
                                <CreateCardSet
                                    cardSetCopy={{
                                        cardSetToCardCategoryId: cardCategoryId,
                                        cardSetToCardTypeId: cardTypeId,
                                    }}
                                    cardSet={undefined}
                                    handleClose={onCreateCardSet}
                                    cardId={id}
                                    multiSelectedCards={multiSelectedCards}
                                />
                            </ModalContainer>
                        )}
                    </Box>
                </StyledModal>
            </Box>
        </>
    );
};

export default CardComponent;
