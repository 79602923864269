import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { AttributeType, CardSet, UserProfile } from '../../API';
import FieldProps, { FieldType } from '../forms/input-fields/inputFieldTypes';
import InputFromAttribute from '../forms/input-fields/InputFromAttribute';
import { useContext, useEffect, useState } from 'react';
import CardPermissions from '../forms/CardPermissions';
import { AppContext } from '../contexts';
import UserAvatar from '../profile/UserAvatar';

export enum CopyType {
    WORKSHEETS = 'worksheets',
    WORKSHEETSANDACARDS = 'worksheetsandcards',
}

export interface WorkbookCopy {
    id: string;
    name: string;
    owner: null | UserProfile;
    copyType: CopyType;
}

interface CopyWorkbookProps {
    workbook: CardSet | undefined;
    close: () => void;
    onSubmit: (workbookCopy: WorkbookCopy) => void;
}

const Copyworkbook = ({ workbook, close, onSubmit }: CopyWorkbookProps) => {
    const theme = useTheme();
    const { users } = useContext(AppContext);

    const [copyWorkbookObject, setCopyWorkbookObject] = useState<any>({
        name: '',
        owner: null,
        copyType: CopyType.WORKSHEETS,
    });
    const [temporaryOwner, setTemporaryOwner] = useState<UserProfile | null>(
        null
    );
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (workbook && users.length) {
            setCopyWorkbookObject({
                ...copyWorkbookObject,
                id: workbook.id,
                name: `${workbook.name} - Copy`,
                owner: users.find((user) => user.id === workbook.owner) ?? null,
            });
            setTemporaryOwner(
                users.find((user) => user.id === workbook.owner) ?? null
            );
        }
    }, [workbook, users]);

    const copyWorkbookConfiguration: FieldProps[] = [
        {
            type: AttributeType.Text,
            label: 'Workbook name',
            name: 'name',
            required: true,
        },
        {
            type: FieldType.RADIO,
            label: 'Copy Type',
            name: 'copyType',
            defaultValue: 'worksheets',
            options: [
                {
                    value: 'worksheets',
                    label: 'Worksheets only (no cards)',
                    help: 'Copy worksheets only without any cards',
                },
                {
                    value: 'worksheetsandcards',
                    label: 'Worksheets and card associations (with cards)',
                    help: 'Copy worksheets and card relationships',
                },
            ],
        },
    ];

    const updateCardDetails = () => {
        setCopyWorkbookObject({ ...copyWorkbookObject, owner: temporaryOwner });
        setTemporaryOwner(null);
        setEdit(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '460px',
            }}
        >
            {edit ? (
                <CardPermissions
                    owner={copyWorkbookObject?.owner?.id ?? null}
                    addEditors={false}
                    cardCategory="default"
                    selected={temporaryOwner ? [temporaryOwner] : []}
                    selectedEditors={[]}
                    setSelectedEditors={() => {}}
                    onSelect={(user) => {
                        setTemporaryOwner(user);
                    }}
                    onSave={updateCardDetails}
                    onClose={() => {
                        setEdit(false);
                    }}
                    card={workbook as CardSet}
                    allUsersEnabled={false}
                    setAllUsersEnabled={(value) => {}}
                    padding={4}
                />
            ) : (
                <form>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: 4,
                            legend: {
                                color: theme.palette.text.primary,
                                fontWeight: 600,
                            },
                            '.MuiFormGroup-root': {
                                mb: 2,
                                '.MuiFormControlLabel-root': {
                                    '.MuiTypography-root': {
                                        fontWeight: 500,
                                    },
                                    '.MuiRadio-root': {
                                        padding: '6px',
                                        mx: 0.5,
                                    },
                                },
                            },
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{ marginBottom: 2, textAlign: 'left' }}
                        >
                            Copy workbook
                        </Typography>

                        <Divider sx={{ mb: 2 }} />

                        {copyWorkbookConfiguration.map(
                            (attributes: any, i: number) => {
                                return (
                                    <InputFromAttribute
                                        key={attributes.label}
                                        {...attributes}
                                        {...(attributes.type !==
                                            AttributeType.MultiSelect && {
                                            onChange: (event: any) => {
                                                setCopyWorkbookObject({
                                                    ...copyWorkbookObject,
                                                    [event?.target.name]:
                                                        event?.target.value,
                                                });
                                            },
                                        })}
                                        selectValue={
                                            copyWorkbookObject[attributes.name]
                                        }
                                    />
                                );
                            }
                        )}

                        <Box
                            sx={{
                                mb: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    background: 'initial',
                                    color: theme.palette.text.primary,
                                    fontSize: '1.25em',
                                    fontFamily: 'Antonio,sans-serif',
                                }}
                            >
                                Owner
                            </Typography>
                            <Box sx={{ display: 'flex' }}>
                                <UserAvatar
                                    userId={copyWorkbookObject?.owner?.id}
                                    isProfile={false}
                                    cardCategory="workbook"
                                />
                                <Button
                                    onClick={() => setEdit(true)}
                                    variant="contained"
                                    size="small"
                                    sx={{ ml: 5 }}
                                >
                                    Change
                                </Button>
                            </Box>
                        </Box>

                        <Box
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button
                                onClick={close}
                                variant="outlined"
                                color="secondary"
                                sx={{ justifySelf: 'right', mr: 2, px: 4 }}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ justifySelf: 'right' }}
                                disabled={!copyWorkbookObject.name}
                                onClick={(event) => {
                                    event.preventDefault();
                                    onSubmit(copyWorkbookObject);
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </form>
            )}
        </Box>
    );
};

export default Copyworkbook;
