import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import {
    AttributeInput,
    Card,
    CardAttributesReferencingCardQuery,
    CardCategory,
    CardSet,
    CardType,
    CardsCardSetsByCardIdQuery,
    GetCardQuery,
    GetCardSetQuery,
    Playbook,
    ScoreDefinition,
    UpdateCardMutation,
    UserProfile,
} from '../API';
import { cardsCardSetsToDeleteByCardId } from '../graphql/custom-queries';
import {
    CardComponentData,
    CardComponentType,
    CardPage,
    SortActions,
} from '../components/cards/cardTypes';
import { getUserOrganisation } from './auth';
import { ReactComponent as ContentIcon } from '../assets/icons/Content.svg';
import { ReactComponent as DetailsIcon } from '../assets/icons/Details.svg';
import { ReactComponent as ScoresIcon } from '../assets/icons/Scores.svg';
import { ReactComponent as RelationshipsIcon } from '../assets/icons/Relationships.svg';
import { ReactComponent as ActivityIcon } from '../assets/icons/Event.svg';
import { ReactComponent as CommentsIcon } from '../assets/icons/Comments.svg';
import {
    cardAttributesReferencingCard,
    getCard,
    getCardSet,
} from '../graphql/queries';
import { createOrUpdateScoreData, updateCard } from '../graphql/mutations';
import {
    likelihoodScores,
    severityScores,
} from '../components/playbooks/analysis/RiskAnalysis';
import { Over } from '@dnd-kit/core';

const client = generateClient();

export const copyCard = (card: Card, user: string) => {
    return {
        organisation: card.organisation,
        owner: user,
        createdBy: user,
        editors: null,
        name: `${card.name} copy`,
        toCardCategory: card.toCardCategory,
        description: card.description,
        attributes: card.attributes,
        toScoreData: card.toScoreData,
        toCardType: card.toCardType,
        toCardSets: card.toCardSets,
        cardTypeToCardsId: card.cardToCardTypeId,
        cardToCardCategoryId: card.cardToCardCategoryId,
        cardToCardTypeId: card.cardToCardTypeId,
    };
};
export const getCardWithId = async (relatedId: string) => {
    try {
        const user = await fetchAuthSession();
        const userOrganisation = getUserOrganisation(user);
        const response = (await client.graphql({
            query: getCard,
            variables: {
                id: relatedId,
                organisation: userOrganisation,
            },
        })) as { data: GetCardQuery };
        return response?.data?.getCard || null;
    } catch (err) {
        console.log(err);
        return [];
    }
};
export const getCardSetWithId = async (cardSetId: string) => {
    try {
        const user = await fetchAuthSession();
        const userOrganisation = getUserOrganisation(user);
        const response = (await client.graphql({
            query: getCardSet,
            variables: {
                id: cardSetId,
                organisation: userOrganisation,
            },
        })) as { data: GetCardSetQuery };
        return response?.data?.getCardSet || null;
    } catch (err) {
        console.log(err);
        return [];
    }
};
export const getCardWithAttributeReference = async (cardId: string) => {
    try {
        const response = (await client.graphql({
            query: cardAttributesReferencingCard,
            variables: {
                refToCardId: cardId,
            },
        })) as { data: CardAttributesReferencingCardQuery };
        const cards =
            response?.data?.cardAttributesReferencingCard?.items
                .map((item) => item?.card)
                .filter((card) => card !== null && card !== undefined) || [];
        return cards as Card[];
    } catch (err) {
        console.log(err);
        return [];
    }
};
export const getRelatedCardSets = async (id: string) => {
    try {
        const response = (await client.graphql({
            query: cardsCardSetsToDeleteByCardId,
            variables: {
                cardId: id,
            },
        })) as { data: CardsCardSetsByCardIdQuery };

        const relatedCardSets =
            response.data.cardsCardSetsByCardId?.items.map(
                (cardSet) => cardSet!.cardSet
            ) || [];

        return relatedCardSets as CardSet[];
    } catch (err) {
        console.log(err);
        return [];
    }
};
interface IconStates {
    [key: string]: boolean;
}

export const getCardMenu = ({
    handleClick,
    link,
    showPage,
    deletedCard,
    cardComponentType,
    scoreDefinition,
}: {
    handleClick: (cardItem: any) => void;
    link?: string;
    showPage?: CardPage;
    deletedCard?: boolean;
    cardComponentType?: CardComponentType;
    scoreDefinition?: ScoreDefinition | null;
}) => {
    const menuActions = [
        {
            action: handleClick || link,
            value: CardPage.DETAILS,
            Icon: ContentIcon,
            label: 'Details',
            active: showPage === CardPage.DETAILS,
        },
        {
            action: handleClick,
            value: CardPage.SCORES,
            Icon: ScoresIcon,
            label: 'Scores',
            active: showPage === CardPage.SCORES,
        },
        {
            action: handleClick || link,
            value: CardPage.INFO,
            Icon: DetailsIcon,
            label: 'Info',
            active: showPage === CardPage.INFO,
        },
    ];

    if (
        (!deletedCard && cardComponentType === CardComponentType.CARD) ||
        cardComponentType === CardComponentType.PLAYBOOK
    ) {
        menuActions.push({
            action: handleClick || link,
            value: CardPage.RELATIONSHIPS,
            Icon: RelationshipsIcon,
            label: 'Relationships',
            active: showPage === CardPage.RELATIONSHIPS,
        });
    }

    if (
        cardComponentType === CardComponentType.CARD ||
        cardComponentType === CardComponentType.PLAYBOOK
    ) {
        menuActions.splice(2, 0, {
            action: handleClick,
            value: CardPage.COMMENTS,
            Icon: CommentsIcon,
            label: 'Comments',
            active: showPage === CardPage.COMMENTS,
        });
    }
    if (
        cardComponentType === CardComponentType.CARD ||
        cardComponentType === CardComponentType.CARD_SET ||
        cardComponentType === CardComponentType.WORKBOOK
    ) {
        menuActions.splice(3, 0, {
            action: handleClick || link,
            value: CardPage.ACTIVITY,
            Icon: ActivityIcon,
            label: 'Activity',
            active: showPage === CardPage.ACTIVITY,
        });
    }

    if (!scoreDefinition) {
        let index = menuActions.findIndex((item) => item.label === 'Scores');
        if (index !== -1) {
            menuActions.splice(index, 1);
        }
    }

    return menuActions;
};

export const updateRiskCardScoresInWorksheet = async (
    over: any,
    card: Card
) => {
    let riskDropId: string = over?.id as string;
    const parts = riskDropId.split('_')[1].split('-');
    const severityScore = parts[0];
    const likelihoodScore = parts[1];
    const riskScores = [
        {
            scoreDefinition: '7-S-1',
            value: severityScores[severityScore.toString()],
        },
        {
            scoreDefinition: '7-S-2',
            value: likelihoodScores[likelihoodScore.toString()],
        },
    ];
    await Promise.all(
        riskScores.map(async (cardScore) => {
            const currentDate = new Date().toISOString().split('T')[0];
            const scoreData = {
                input: {
                    data: [{ date: currentDate, value: cardScore.value }],
                    cardId: card?.id,
                    organisation: card?.organisation,
                    scoreDefinitionId: cardScore.scoreDefinition || '',
                },
            };

            try {
                await client.graphql({
                    query: createOrUpdateScoreData,
                    variables: scoreData,
                });
            } catch (err) {
                console.log(err);
            }
        })
    );
    const response = (await client.graphql({
        query: getCard,
        variables: {
            id: card.id,
            organisation: card.organisation,
        },
    })) as { data: GetCardQuery };

    return response.data.getCard;
};

export const updateCardAttributesInWorksheet = async (
    data: any,
    attributes: any
) => {
    const query = updateCard;
    const attributesArray = attributes?.map((item: any) => {
        if (item?.value) {
            return {
                attributeDefinitionID: item?.attributeDefinitionID,
                value: JSON.stringify(item?.value),
            };
        }
    });
    const data2 = {
        id: data?.id,
        organisation: data?.organisation,
        createdAt: data.createdAt,
        attributes: attributesArray,
    };
    try {
        const res = (await client.graphql({
            query,
            variables: {
                input: {
                    ...data2,
                },
            },
        })) as { data: UpdateCardMutation };
        return res.data.updateCard;
    } catch (error) {
        console.log(error);
    }
};

export const extractAttributes = (
    dropId: '12' | '11',
    over: Over | null,
    workbookCard?: boolean
) => {
    if (dropId === '12') {
        const droppable = over?.id as string;
        const value = droppable?.split('_')[1].split('-')[0];
        const attributes = [
            {
                value: workbookCard ? value : `\"${value}\"`,
                attributeDefinitionID: '12-A-1',
            },
        ];
        return attributes;
    } else if (dropId === '11') {
        const droppable = over?.id as string;
        const value = droppable?.split('_');
        const [category, timescale] = value[1].split('+').filter(Boolean);
        const attributes = [
            {
                value: workbookCard ? category : `\"${category}\"`,
                attributeDefinitionID: '11-A-2',
            },
            {
                value: workbookCard ? timescale : `\"${timescale}\"`,
                attributeDefinitionID: '11-A-3',
            },
        ];
        return attributes;
    }
    return;
};

export const sortFilteredCards = ({
    term,
    cards,
    cardTypeIds,
    cardCategoryIds,
    userIds,
}: {
    term: string;
    cards: Card[];
    cardTypeIds: string[];
    cardCategoryIds: string[];
    userIds: string[];
}): Card[] => {
    const lowerSearchTerm = term.toLowerCase();

    const cardsWithNameMatch = cards.filter((card) =>
        card.name.toLowerCase().includes(lowerSearchTerm as string)
    );
    const cardsWithTypeMatch = cards.filter((card) =>
        cardTypeIds.includes(card.cardToCardTypeId)
    );
    const cardsWithCategoryMatch = cards.filter((card) =>
        cardCategoryIds.includes(card.cardToCardCategoryId)
    );
    const cardsWithOwnerMatch = cards.filter((card) =>
        userIds.includes(card.owner as string)
    );
    const cardsWithDescriptionMatch = cards.filter((card) =>
        card.description?.toLowerCase().includes(lowerSearchTerm as string)
    );

    const uniq = (a: any): Card[] => {
        return Array.from(new Set(a));
    };
    return uniq(
        cardsWithNameMatch
            .concat(cardsWithTypeMatch)
            .concat(cardsWithCategoryMatch)
            .concat(cardsWithOwnerMatch)
            .concat(cardsWithDescriptionMatch)
    );
};

export const filterCardsByTerm = ({
    cards,
    searchTerm,
    users,
    cardTypes,
    cardCategories,
}: {
    cards: Card[];
    searchTerm: string;
    users: UserProfile[];
    cardTypes: CardType[];
    cardCategories: CardCategory[];
}): Card[] => {
    const lowerSearchTerm = searchTerm?.toLowerCase();

    const filteredCategories = cardCategories
        .filter((category) =>
            category.name.toLowerCase().includes(lowerSearchTerm as string)
        )
        .map((category) => category.id);

    const filteredTypes = cardTypes
        .filter((type) =>
            type.name?.toLowerCase().includes(lowerSearchTerm as string)
        )
        .map((type) => type.id);

    const filteredUsers = users
        .filter((user) =>
            user.firstName
                ?.concat(user.lastName as string)
                .toLowerCase()
                .includes(lowerSearchTerm as string)
        )
        .map((user) => user.id);

    return cards.filter((card) => {
        const name = card.name ?? '';
        const description = card.description ?? '';

        return (
            name.indexOf(searchTerm) !== -1 ||
            name.indexOf(searchTerm?.toUpperCase()) !== -1 ||
            name.indexOf(searchTerm?.toLowerCase()) !== -1 ||
            description?.indexOf(searchTerm) !== -1 ||
            description?.indexOf(searchTerm?.toUpperCase()) !== -1 ||
            description?.indexOf(searchTerm?.toLowerCase()) !== -1 ||
            filteredCategories.includes(card.cardToCardCategoryId) ||
            filteredTypes.includes(card.cardToCardTypeId) ||
            filteredUsers.includes(card.owner as string)
        );
    });

    return cards;
};
export type ScoreCard = Card & { score?: number };
export const colorArrays = [
    ['#ffa600', '#ff7700', '#f10000', '#f10000', '#f10000'],
    ['#ffa600', '#ffa600', '#ff7700', '#ff7700', '#f10000'],
    ['#00cc3f', '#ffa600', '#ff7700', '#ff7700', '#f10000'],
    ['#00a53a', '#00cc3f', '#ffa600', '#ffa600', '#ffa600'],
    ['#00a53a', '#00a53a', '#00cc3f', '#00cc3f', '#00cc3f'],
];
export const getRiskCards = (y: number, x: number, cards: Card[]) => {
    const riskCards: ScoreCard[] = [];
    const impactScores: any = {
        '5': 0,
        '4': 0,
        '3': 1,
        '2': 1,
        '1': 2,
        '0': 2,
        '-1': 2,
        '-2': 3,
        '-3': 2,
        '-4': 4,
        '-5': 4,
    };
    const likelihoodScores: any = {
        '0': 0,
        '1': 0,
        '2': 0,
        '3': 1,
        '4': 1,
        '5': 2,
        '6': 2,
        '7': 3,
        '8': 3,
        '9': 4,
        '10': 4,
    };

    cards.forEach((card) => {
        let impact = card.toScoreData?.items.find(
            (item) => item?.scoreDefinitionId === '7-S-1'
        );
        if (!impact) return;

        let likelihood = card.toScoreData?.items.find(
            (item) => item?.scoreDefinitionId === '7-S-2'
        );
        if (!likelihood) return;

        const scoreImpact = impact?.data?.[impact.data.length - 1];
        const scoreLikelihood = likelihood?.data?.[likelihood.data.length - 1];

        if (
            impactScores[scoreImpact?.value] === y &&
            likelihoodScores[scoreLikelihood?.value] === x
        ) {
            const mitigation = card.toScoreData?.items.find(
                (item) => item?.scoreDefinitionId === '7-S-3'
            );
            const mitigationScore =
                mitigation?.data[mitigation.data.length - 1];

            riskCards.push({
                ...card,
                ...(mitigationScore && {
                    score: mitigationScore?.value,
                }),
            });
        }
    });
    return riskCards;
};

export const getMiniCardHeaderText = (
    item: Card | CardSet | Playbook
): string => {
    const { __typename } = item;

    if (__typename === 'Card') {
        return (item as Card).toCardType?.name?.toUpperCase() || '';
    }
    if (__typename === 'Playbook') {
        return 'PLAYBOOK';
    }
    if (__typename === 'CardSet') {
        const cardSet = item as CardSet;
        if (cardSet.type === 'CS') {
            return cardSet.toCardType?.name?.toUpperCase() || 'VARIED';
        }
        if (cardSet.type === 'WB') {
            return 'WORKBOOK';
        }
    }

    return 'WORKSHEET';
};
