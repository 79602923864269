import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { CardSet } from '../../API';
import { getRelatedCardSets } from '../../helpers/card';
import { truncateText } from '../../helpers/utils';
import { PageIdentifier } from './cardTypes';
import { CardToDelete } from '../../pages/cards';
import { ReactComponent as WorkbookIcon } from '../../assets/icons/Workbooks.svg';
import { ReactComponent as CardSetIcon } from '../../assets/icons/CardSets.svg';

interface CardDeleteProps {
    id?: string;
    organisation?: string;
    toggleDeleteWizard: React.Dispatch<React.SetStateAction<boolean>>;
    confirmDelete?: (id: string, organisation: string) => void;
    cardCategoryId?: string;
    pageIdentifier: PageIdentifier;
    setCardToDelete?: React.Dispatch<React.SetStateAction<CardToDelete | null>>;
    multiSelectedCards?: [] | CardToDelete[];
    confirmMultiDelete?: () => void;
    setMultiSelectedCards?: React.Dispatch<
        React.SetStateAction<[] | CardToDelete[]>
    >;
    deleting?: boolean;
    commentId?: string;
    confirmCommentDelete?: () => void;
    cancel?: () => void;
}
const pageMap = {
    [PageIdentifier.CARDS]: 'card',
    [PageIdentifier.CARD_SETS]: 'card set',
    [PageIdentifier.WORKBOOKS]: 'workbook',
    [PageIdentifier.CARD_SET_INSIDE]: 'card',
    [PageIdentifier.WORKBOOK_INSIDE]: 'card',
    [PageIdentifier.PLAYBOOKS]: 'playbook',
    [PageIdentifier.WORKSHEET]: 'worksheet',
    [PageIdentifier.STRATEGY_BUILDER]: 'strategy builder',
    [PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD]: 'card',
    [PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD_SET]: 'card set',
    [PageIdentifier.CONNECT_WORKBOOK_TO_PLAYBOOK_FORM]: 'workbook',
};
const CardDelete = ({
    id,
    organisation,
    toggleDeleteWizard,
    confirmDelete,
    cardCategoryId,
    pageIdentifier,
    setCardToDelete,
    multiSelectedCards,
    confirmMultiDelete,
    setMultiSelectedCards,
    deleting,
    commentId,
    confirmCommentDelete,
    cancel,
}: CardDeleteProps) => {
    const [cardSets, setCardSets] = useState<CardSet[]>([]);
    const theme = useTheme();
    const Avatar = ({ cardSet }: { cardSet: CardSet }) => {
        const AvatarComponent =
            cardSet.type === 'WB' ? WorkbookIcon : CardSetIcon;
        return <AvatarComponent color={theme.palette.grey[700]} />;
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!id) return;
            const relatedCardSets = await getRelatedCardSets(id);
            setCardSets(relatedCardSets);
        };
        fetchData();
    }, []);
    const handleConfirm = () => {
        if (commentId && confirmCommentDelete) {
            confirmCommentDelete();
            return;
        }
        if (confirmDelete && id && organisation) {
            confirmDelete(id, organisation);
        } else if (confirmMultiDelete) {
            confirmMultiDelete();
        }
    };
    return (
        <Box
            sx={{
                padding: '20px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                {commentId
                    ? `Are you sure you want to delete this comment?`
                    : multiSelectedCards && multiSelectedCards.length > 0
                    ? `Are you sure you want to delete ${multiSelectedCards.length} cards?`
                    : `Are you sure you want to delete this ${pageMap[pageIdentifier]}?`}
            </Typography>

            {cardSets.length > 0 && (
                <>
                    <Typography
                        variant="body1"
                        color="error.main"
                        sx={{
                            marginBottom: 0,
                            textAlign: 'left',
                            fontSize: '1.2rem',
                        }}
                    >
                        This action will remove the card from the following card
                        sets / workbooks.
                    </Typography>

                    <List
                        sx={{
                            maxHeight: '12vh',
                            overflow: 'auto',
                        }}
                    >
                        {cardSets.map((item: CardSet) => (
                            <ListItem key={item.id}>
                                <ListItemAvatar
                                    sx={{
                                        marginTop: 0,
                                        minWidth: '40px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '30px',
                                            height: '30px',
                                            padding: 0.5,
                                        }}
                                    >
                                        <Avatar cardSet={item} />
                                    </Box>
                                </ListItemAvatar>
                                <Tooltip title={item.name}>
                                    <ListItemText
                                        primary={truncateText(item.name, 50)}
                                    />
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                </>
            )}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    gap: 2,
                    alignSelf: 'flex-end',
                }}
            >
                <Button
                    onClick={() => {
                        cancel && cancel();
                        toggleDeleteWizard(false);
                        setCardToDelete && setCardToDelete(null);
                        setMultiSelectedCards && setMultiSelectedCards([]);
                    }}
                    variant="outlined"
                    sx={{
                        mt: 2,
                        width: 'fit-content',
                        borderRadius: 2,
                        px: 4,
                    }}
                >
                    {deleting ? (
                        <CircularProgress size={18} color={'inherit'} />
                    ) : (
                        'Cancel'
                    )}
                </Button>
                <Button
                    onClick={handleConfirm}
                    variant="contained"
                    sx={{
                        mt: 2,
                        width: 'fit-content',
                        borderRadius: 2,
                        bgcolor: 'error.main',
                        color: 'error.contrastText',
                        '&:hover': { bgcolor: 'error.light' },
                    }}
                >
                    {deleting ? (
                        <CircularProgress size={18} color={'inherit'} />
                    ) : (
                        'Delete'
                    )}
                </Button>
            </Box>
        </Box>
    );
};

export default CardDelete;
