import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { StyledBox } from './WorkbookMain';
import {
    Alert,
    Box,
    IconButton,
    LinearProgress,
    Snackbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { UserPermissions } from '../../../globals';
import AddIcon from '@mui/icons-material/Add';
import Grid2 from '@mui/material/Unstable_Grid2';
import { v4 as uuidv4 } from 'uuid';
import WorksheetItem from '../WorksheetItem';
import WorksheetListView from '../../cards/views/WorksheetListView';
import { PageIdentifier, ViewType } from '../../cards/cardTypes';
import GhostCard from '../../cards/GhostCard';
import { CardSet, UpdateCardSetMutation, Worksheet } from '../../../API';
import { ReactComponent as MinimizeIcon } from '../../../assets/icons/Minimize.svg';
import FoodaImage from './FoodaImage';
import { processSteps } from '../../forms/processSteps';
import { generateClient, GraphQLQuery } from 'aws-amplify/api';
import { updateCardSet } from '../../../graphql/mutations';
import useSnackbar from '../../../hooks/useSnackbar';

interface WorksheetSectionProps {
    title: string;
    permissions: UserPermissions[];
    setShowAddWorksheet: React.Dispatch<React.SetStateAction<boolean>>;
    selectedViewType: ViewType;
    worksheetsLoaded: boolean;
    worksheets: Worksheet[];
    setWorksheets: React.Dispatch<React.SetStateAction<Worksheet[]>>;
    workbookId: string;
    deleteWorksheet: (id: string) => Promise<void>;
    deleting: boolean;
    setDeleting: React.Dispatch<React.SetStateAction<boolean>>;
    color?: string;
    selectionType: string;
    setSelectionType: React.Dispatch<React.SetStateAction<string | null>>;
    description?: string;
    workbook: CardSet;
    progressArray?:
        | {
              stageId: string;
              progress: number;
          }[]
        | null;
    setProgressArray?: React.Dispatch<
        React.SetStateAction<
            | {
                  stageId: string;
                  progress: number;
              }[]
            | null
        >
    >;
}
const xs = 12;
const sm = 6;
const md = 4;
const WorksheetSection = ({
    title,
    permissions,
    setShowAddWorksheet,
    selectedViewType,
    worksheetsLoaded,
    worksheets,
    setWorksheets,
    workbookId,
    deleteWorksheet,
    deleting,
    setDeleting,
    color,
    selectionType,
    setSelectionType,
    description,
    workbook,
    progressArray,
    setProgressArray,
}: WorksheetSectionProps) => {
    const theme = useTheme();
    const workbookEditor = permissions?.includes(UserPermissions.EDIT);
    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();
    const client = generateClient();
    const [progress, setProgress] = useState(0);
    const progressRef = useRef(progress);
    const progressBarRef = useRef<HTMLDivElement>(null);
    const processId = useMemo(() => {
        return processSteps.find((item) => item.name === title)?.id;
    }, [title]);

    useEffect(() => {
        const process = progressArray?.find(
            (item) => item?.stageId === processId
        );
        if (process?.progress !== undefined && process?.progress !== null) {
            setProgress(process.progress);
        }
    }, [progressArray, processId]);

    useEffect(() => {
        progressRef.current = progress;
    }, [progress]);

    const updateProgress = async (newProgress: number) => {
        const defaultProgress = [
            { stageId: '1', progress: 0 },
            { stageId: '2', progress: 0 },
            { stageId: '3', progress: 0 },
            { stageId: '4', progress: 0 },
            { stageId: '5', progress: 0 },
        ];
        const updatedProgressArray = progressArray || defaultProgress;
        const updatedProcessProgress = updatedProgressArray?.map((item) => {
            const reformatedItem = {
                stageId: item?.stageId,
                progress: item?.progress,
            };
            if (item?.stageId === processId) {
                return {
                    stageId: reformatedItem?.stageId,
                    progress: newProgress,
                };
            } else {
                return reformatedItem;
            }
        });
        setProgressArray && setProgressArray(updatedProcessProgress);
        const data = {
            processProgress: updatedProcessProgress,
            id: workbook?.id,
            name: workbook?.name,
            description: workbook?.description,
            organisation: workbook?.organisation,
            type: workbook?.type,
            createdAt: workbook?.createdAt,
        };
        try {
            await client.graphql<GraphQLQuery<UpdateCardSetMutation>>({
                query: updateCardSet,
                variables: {
                    input: data,
                },
            });
            showSnackbar({
                message: 'Progress updated',
                severity: 'success',
            });
        } catch (e) {
            showSnackbar({
                message: 'Error while updating the progress',
                severity: 'error',
            });
            console.error('Error updating progress:', e);
        }
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (progressBarRef.current) {
            const rect = progressBarRef.current.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const newValue = Math.min(Math.max((x / rect.width) * 100, 0), 100);
            setProgress(newValue);
            progressRef.current = newValue;
        }
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!workbookEditor) {
            return;
        }
        if (progressBarRef.current) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            handleMouseMove(event as unknown as MouseEvent);
        }
    };

    const handleMouseUp = () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
        updateProgress(Math.round(progressRef.current));
    };
    const frameProcess = progressArray?.find((item) => item?.stageId === '1');
    const frameProgress = frameProcess?.progress ?? 0;
    return (
        <>
            <Grid2 container spacing={2} sx={{ mt: 0.3 }}>
                <Grid2
                    key={selectedViewType}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={5}
                    xl={5}
                >
                    <Box
                        sx={{
                            maxWidth: '540px',
                            margin: '0 auto',
                        }}
                    >
                        {' '}
                        <FoodaImage
                            selectionType={selectionType}
                            setSelectionType={setSelectionType}
                            frameProgress={frameProgress}
                        />
                    </Box>
                </Grid2>
                <Grid2 key={uuidv4()} xs={12} sm={12} md={12} lg={7} xl={7}>
                    <StyledBox>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                background: color ?? '',
                                borderTopLeftRadius: '12px',
                                borderTopRightRadius: '12px',
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    fontSize: '1.5rem',
                                    color: 'white',
                                    px: '25px',
                                }}
                            >
                                {title.toUpperCase()}
                            </Typography>
                            <Box
                                className="expandIconWrapper"
                                sx={{
                                    mr: '20px',
                                    marginTop: '6px',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    setSelectionType && setSelectionType(null)
                                }
                            >
                                <MinimizeIcon
                                    color="#fff"
                                    height="30px"
                                    width="30px"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                padding: '0px 25px 10px 25px',
                                height: '620px',
                            }}
                        >
                            {selectedViewType === ViewType.GRID ? (
                                <>
                                    {description && (
                                        <Box
                                            sx={{
                                                border: `solid 0.5px ${theme.palette.divider}`,
                                                borderRadius: '12px',
                                                height: '60px',
                                                my: 1,
                                                mx: -1,
                                                px: 1,
                                                py: 0.5,
                                            }}
                                        >
                                            <Typography variant="body1">
                                                {description}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    cursor: workbookEditor
                                                        ? 'grab'
                                                        : 'default',
                                                }}
                                                onMouseDown={handleMouseDown}
                                            >
                                                <Tooltip
                                                    title="Click or drag to update progress"
                                                    arrow
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: theme.palette
                                                                .text.secondary,
                                                            textAlign: 'center',
                                                            mt: -1,
                                                        }}
                                                    >
                                                        {Math.round(progress)}%
                                                        completed
                                                    </Typography>
                                                </Tooltip>
                                                <LinearProgress
                                                    ref={progressBarRef}
                                                    variant="determinate"
                                                    value={progress}
                                                    sx={{
                                                        height: 5,
                                                        borderRadius: 5,
                                                    }}
                                                />
                                                {workbookEditor && (
                                                    <Box
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            top: '87%',
                                                            left: `${progress}%`,
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            width: '10px',
                                                            height: '10px',
                                                            backgroundColor:
                                                                'white',
                                                            border: '2px solid',
                                                            borderColor:
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                            borderRadius: '50%',
                                                            cursor: 'grab',
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    )}

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{ fontSize: '18px' }}
                                        >
                                            {description && `Worksheets`}
                                        </Typography>
                                        {permissions?.includes(
                                            UserPermissions.EDIT
                                        ) && (
                                            <IconButton
                                                color="primary"
                                                onClick={() =>
                                                    setShowAddWorksheet(true)
                                                }
                                                title="Create worksheet"
                                                sx={{ mr: -1.5 }}
                                            >
                                                <AddIcon
                                                    sx={{
                                                        fontSize: '3rem',
                                                        width: '30px',
                                                        height: '30px',
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                    </Box>
                                    <Grid2
                                        container
                                        spacing={2}
                                        sx={{
                                            height: '520px',
                                            overflowY: 'auto',
                                            mt: '10px',
                                        }}
                                    >
                                        {worksheetsLoaded &&
                                            !worksheets.length && (
                                                <Grid2
                                                    key={uuidv4()}
                                                    xs={xs}
                                                    sm={sm}
                                                    md={md}
                                                    lg={2.4}
                                                    xl={2.4}
                                                >
                                                    {permissions?.includes(
                                                        UserPermissions.EDIT
                                                    ) && (
                                                        <GhostCard
                                                            pageIdentifier={
                                                                PageIdentifier.WORKBOOK_INSIDE
                                                            }
                                                            height="auto"
                                                            action={() =>
                                                                setShowAddWorksheet(
                                                                    true
                                                                )
                                                            }
                                                            hide={true}
                                                        />
                                                    )}
                                                </Grid2>
                                            )}

                                        {worksheets.map((worksheet: any) => (
                                            <Grid2
                                                key={uuidv4()}
                                                xs={6}
                                                sm={4}
                                                md={4}
                                                lg={2.4}
                                                xl={2.4}
                                            >
                                                <WorksheetItem
                                                    worksheet={worksheet}
                                                    workbookId={
                                                        workbookId ?? ''
                                                    }
                                                    deleteWorksheet={
                                                        deleteWorksheet
                                                    }
                                                    deleting={deleting}
                                                    workbookPermissions={
                                                        permissions
                                                    }
                                                    selectedViewType={
                                                        selectedViewType
                                                    }
                                                />
                                            </Grid2>
                                        ))}
                                    </Grid2>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        height: '500px',
                                        overflowY: 'auto',
                                    }}
                                >
                                    <WorksheetListView
                                        items={worksheets.map(
                                            (page: any, index: any) => {
                                                return {
                                                    ...page,
                                                    first:
                                                        index === 0
                                                            ? true
                                                            : false,
                                                    last:
                                                        index ===
                                                        worksheets.length - 1
                                                            ? true
                                                            : false,
                                                };
                                            }
                                        )}
                                        permissions={permissions}
                                        handleDelete={deleteWorksheet}
                                        workbookId={workbookId}
                                        selectedViewType={selectedViewType}
                                    />
                                </Box>
                            )}
                        </Box>
                    </StyledBox>
                </Grid2>
            </Grid2>
            <Snackbar
                open={isOpen}
                autoHideDuration={duration}
                onClose={closeSnackbar}
                sx={{ zIndex: 1600 }}
            >
                <Alert
                    variant="filled"
                    severity={severity}
                    sx={{ width: '100%' }}
                    onClose={closeSnackbar}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default WorksheetSection;
