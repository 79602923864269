import SwotAnalysisIcon from '../assets/images/process-thumbnails/SWOT-Analysis.png';
import ScoreCardIcon from '../assets/images/process-thumbnails/Scorecard.png';
import StrategyTreeIcon from '../assets/images/process-thumbnails/Strategy-tree.png';
import XMatrixIcon from '../assets/images/process-thumbnails/X-matrix.png';
import PortfolioAnalysisIcon from '../assets/images/process-thumbnails/Markets.png';
import ActionPlanRoadmapIcon from '../assets/images/process-thumbnails/Action-plan-roadmap.png';
import RiskAnalysisIcon from '../assets/images/process-thumbnails/Risk-Analysis.png';
import IdeaAnalysisIcon from '../assets/images/process-thumbnails/Strategic-Ideas.png';
import PlanAnalysisIcon from '../assets/images/process-thumbnails/Plan-Analysis.png';
import KpiSummaryIcon from '../assets/images/process-thumbnails/Strategy-tree.png'; // update
import ProjectSummaryIcon from '../assets/images/process-thumbnails/Plan-Analysis.png'; //update
import ProgramSummaryIcon from '../assets/images/process-thumbnails/Pestle.png'; //update
import PortfolioSummaryIcon from '../assets/images/process-thumbnails/Markets.png'; //update
import ObjectiveSummaryIcon from '../assets/images/process-thumbnails/Strategic_Options.svg'; //update
import StrategySummaryIcon from '../assets/images/process-thumbnails/Strategy-tree.png'; //update
import SharedPurposeIcon from '../assets/images/process-thumbnails/Shared-purpose.png';
import GuardrailsIcon from '../assets/images/process-thumbnails/Guardrails.svg';
import SkillDevelopmentIcon from '../assets/images/process-thumbnails/Markets.png';
import CapabilityDevelopmentIcon from '../assets/images/process-thumbnails/Markets.png';
import CustomerAnalysisIcon from '../assets/images/process-thumbnails/Markets.png';
import CompetitiveLandscapeIcon from '../assets/images/process-thumbnails/Competitors.png';
import PortersFiveForcesIcon from '../assets/images/process-thumbnails/Porters-5-forces.png';
import PestleAnalysisIcon from '../assets/images/process-thumbnails/Pestle.png';
import MarketAnalysisIcon from '../assets/images/process-thumbnails/Markets.png';
import StrategicOptionsIcon from '../assets/images/process-thumbnails/Markets.png';
import StrategicChallengesIcon from '../assets/images/process-thumbnails/Markets.png';
import RetrospectiveIcon from '../assets/images/process-thumbnails/Retrospective.png';
import AssumptionAnalysisIcon from '../assets/images/process-thumbnails/Assumptions.png';
import SummaryScreen from '../assets/images/process-thumbnails/Summary-screen.png';
import { worksheets } from '../components/forms/worksheets';
import {
    AxisOptions,
    NodeColorOptions,
    NodeSizeOptions,
} from '../components/playbooks/playbookTypes';
import FieldProps, {
    CheckboxOptionType,
    FieldType,
    CheckboxFieldProps,
} from '../components/forms/input-fields/inputFieldTypes';
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { getUserOrganisation } from './auth';
import { getCard } from '../graphql/queries';
import {
    Card,
    CardType,
    GetCardQuery,
    ScoreDefinition,
    ScoreType,
    Worksheet,
} from '../API';
import { RadarOption } from '../components/forms/input-fields/ChartSettings';

import { CheckboxFields } from '../components/forms/input-fields/ControlledCheckboxFields';
import { WorksheetFilterCards } from '../hooks/useFilterCards';
import {
    WorksheetFilterDateOptions,
    WorksheetFilterOptions,
} from '../components/cards/cardTypes';
import _ from 'lodash';
import { getPrimaryScoreValue } from './scores';
import { dateFromISO, sortObj } from './utils';
import { DateTime } from 'luxon';
import { ScoreCard, colorArrays, getRiskCards } from './card';
import { Category, getCategoryHexText } from './category';

interface SelectFieldProps {
    selectValue?: string | null;
    helperText?: string;
    handleInputChange?: (name: string, value: string) => void;
}
type AllFieldProps = CheckboxFieldProps & SelectFieldProps;

export enum Field {
    TITLE = 'title',
    SCORE = 'score',
    CHARTS = 'charts',
    COMPARISON_TABLE = 'comparison table',
    DETAILS = 'details',
    VOTES = 'votes',
    ILLUSTRATION = 'illustration',
    DESCRIPTION = 'description',
    SCORE_COLOR = 'score color',
}
export enum WorksheetNames {
    SWOT_ANALYSIS = 'SWOT analysis',
    SCORECARD = 'Scorecard',
    STRATEGY_TREE = 'Strategy tree',
    X_MATRIX = 'X-Matrix',
    PROJECT_ANALYSIS = 'Project analysis',
    ACTION_PLAN_ROADMAP = 'Action plan roadmap',
    RISK_ANALYSIS = 'Risk analysis',
    IDEA_ANALYSIS = 'Idea analysis',
    PLAN_ANALYSIS = 'Plan analysis',
    KPI_SUMMARY = 'KPI summary',
    PROJECT_SUMMARY = 'Project summary',
    PROGRAM_SUMMARY = 'Program summary',
    PORTFOLIO_SUMMARY = 'Portfolio summary',
    OBJECTIVE_SUMMARY = 'Objective summary',
    STRATEGY_SUMMARY = 'Strategy summary',
    SHARED_PURPOSE = 'Shared purpose',
    GUARDRAILS = 'Guardrails',
    SKILL_DEVELOPMENT = 'Skill development',
    CAPABILITY_DEVELOPMENT = 'Capability development',
    CUSTOMER_ANALYSIS = 'Customer analysis',
    COMPETITIVE_LANDSCAPE = 'Competitive landscape',
    PORTERS_FIVE_FORCES = "Porter's Five Forces",
    PESTLE_ANALYSIS = 'PESTLE analysis',
    MARKET_ANALYSIS = 'Market analysis',
    STRATEGIC_OPTIONS = 'Strategic options',
    STRATEGIC_CHALLENGES = 'Strategic challenges',
    RETROSPECTIVE = 'Retrospective',
    ASSUMPTION_ANALYSIS = 'Assumption analysis',
}
export const enum ChartType {
    BLANK = 'blank',
    GRID = 'grid',
    BUBBLES = 'bubbles',
    RETROSPECTIVE = 'retrospective',
    RISK_ANALYSIS = 'risk analysis',
    PESTLE_ANALYSIS = 'PESTLE analysis',
    PORTERS_FIVE_FORCES = "Porter's Five Forces",
    RADAR = 'radar',
}
type WorksheetMap = {
    [key in WorksheetNames]: {
        icon: string;
        chartType: ChartType;
        chartSettings: any;
    };
};
export const swotOptions: CheckboxOptionType[] = [
    {
        value: Field.SCORE,
        label: 'Score',
        enabled: true,
    },
];

const portersOptions: CheckboxOptionType[] = [
    {
        value: Field.ILLUSTRATION,
        label: 'Illustration',
        enabled: true,
    },
    {
        value: Field.DESCRIPTION,
        label: 'Description',
        enabled: true,
    },
];

const listOptions: CheckboxOptionType[] = [
    {
        value: Field.CHARTS,
        label: 'Charts',
        enabled: true,
    },
    {
        value: Field.COMPARISON_TABLE,
        label: 'Comparison table',
        enabled: true,
    },
    {
        value: Field.DETAILS,
        label: 'Details',
        enabled: true,
    },
];

const defaultSettings = {
    axis: [],
    nodeSize: [],
    nodeColor: [],
    hideGaps: {
        enabled: true,
        text: '',
    },
    include: [],
    layout: [
        {
            enabled: true,
            text: 'Include logo',
            value: 'logo',
        },
    ],
};

export const worksheetObject: WorksheetMap = {
    [WorksheetNames.SWOT_ANALYSIS]: {
        icon: SwotAnalysisIcon,
        chartType: ChartType.GRID,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.SCORECARD]: {
        icon: ScoreCardIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.STRATEGY_TREE]: {
        icon: StrategyTreeIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.X_MATRIX]: {
        icon: XMatrixIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.PROJECT_ANALYSIS]: {
        icon: PortfolioAnalysisIcon,
        chartType: ChartType.BUBBLES,
        chartSettings: {
            axis: [
                AxisOptions.Value,
                AxisOptions.StrategicAlignment,
                AxisOptions.PlanCost,
                AxisOptions.ProbabilityOfSuccess,
                AxisOptions.PercentComplete,
            ],
            nodeSize: [
                NodeSizeOptions.PlanCost,
                NodeSizeOptions.Value,
                NodeSizeOptions.DefaultNodeSize,
            ],
            nodeColor: [
                NodeColorOptions.Type,
                NodeColorOptions.ParentProgram,
                NodeColorOptions.Portfolio,
                NodeColorOptions.Organization,
                NodeColorOptions.DisableNodeColor,
            ],
            layout: [
                {
                    enabled: true,
                    text: 'Show grid lines',
                    value: 'grid',
                },
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.ACTION_PLAN_ROADMAP]: {
        icon: ActionPlanRoadmapIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.RISK_ANALYSIS]: {
        icon: RiskAnalysisIcon,
        chartType: ChartType.RISK_ANALYSIS,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.IDEA_ANALYSIS]: {
        icon: IdeaAnalysisIcon,
        chartType: ChartType.BUBBLES,
        chartSettings: {
            axis: [
                AxisOptions.Feasibility,
                AxisOptions.PotentialImpact,
                AxisOptions.InvestmentLevel,
                AxisOptions.StrategicFit,
            ],
            nodeSize: [
                NodeSizeOptions.InvestmentLevel,
                NodeSizeOptions.PotentialImpact,
                NodeSizeOptions.Feasibility,
                NodeSizeOptions.StrategicFit,
                NodeSizeOptions.DefaultNodeSize,
            ],
            nodeColor: [
                NodeColorOptions.Category,
                NodeColorOptions.Type,
                NodeColorOptions.Source,
            ],
            layout: [
                {
                    enabled: true,
                    text: 'Show grid lines',
                    value: 'grid',
                },
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.PLAN_ANALYSIS]: {
        icon: PlanAnalysisIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.KPI_SUMMARY]: {
        icon: KpiSummaryIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.PROJECT_SUMMARY]: {
        icon: ProjectSummaryIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.PROGRAM_SUMMARY]: {
        icon: ProgramSummaryIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.PORTFOLIO_SUMMARY]: {
        icon: PortfolioSummaryIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.OBJECTIVE_SUMMARY]: {
        icon: ObjectiveSummaryIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.STRATEGY_SUMMARY]: {
        icon: StrategySummaryIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.SHARED_PURPOSE]: {
        icon: SharedPurposeIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.GUARDRAILS]: {
        icon: GuardrailsIcon,
        chartType: ChartType.BLANK,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.SKILL_DEVELOPMENT]: {
        icon: SkillDevelopmentIcon,
        chartType: ChartType.BUBBLES,
        chartSettings: {
            axis: [
                AxisOptions.StrategicImportance,
                AxisOptions.CurrentProficiency,
                AxisOptions.EaseOfUpskill,
                AxisOptions.DesiredProficiency,
                AxisOptions.BenchmarkProficiency,
                AxisOptions.SkillGapCurrentVsDesired,
                AxisOptions.SkillGapCurrentVsBenchmark,
                AxisOptions.SkillGapDesiredVsBenchmark,
            ],
            nodeSize: [
                NodeSizeOptions.SkillGapCurrentVsDesired,
                NodeSizeOptions.StrategicImportance,
                NodeSizeOptions.DefaultNodeSize,
            ],
            nodeColor: [
                NodeColorOptions.Category,
                NodeColorOptions.Type,
                NodeColorOptions.StrategicImportance,
                NodeColorOptions.DisableNodeColor,
            ],
            hideGaps: {
                enabled: true,
                text: 'Hide skills with no gaps',
            },
            layout: [
                {
                    enabled: true,
                    text: 'Show grid lines',
                    value: 'grid',
                },
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.CAPABILITY_DEVELOPMENT]: {
        icon: CapabilityDevelopmentIcon,
        chartType: ChartType.BUBBLES,
        chartSettings: {
            axis: [
                AxisOptions.StrategicImportance,
                AxisOptions.CurrentMaturity,
                AxisOptions.EaseOfMaturityImprovement,
                AxisOptions.DesiredMaturity,
                AxisOptions.BenchmarkMaturity,
                AxisOptions.MaturityGapCurrentVsDesired,
                AxisOptions.MaturityGapCurrentVsBenchmark,
                AxisOptions.MaturityGapDesiredVsBenchmark,
            ],
            nodeSize: [
                NodeSizeOptions.MaturityGapCurrentVsDesired,
                NodeSizeOptions.StrategicImportance,
                NodeSizeOptions.DefaultNodeSize,
            ],
            nodeColor: [
                NodeColorOptions.Category,
                NodeColorOptions.Type,
                NodeColorOptions.StrategicImportance,
                NodeColorOptions.DisableNodeColor,
            ],
            hideGaps: {
                enabled: true,
                text: 'Hide capabilities with no gaps',
            },
            layout: [
                {
                    enabled: true,
                    text: 'Show grid lines',
                    value: 'grid',
                },
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.CUSTOMER_ANALYSIS]: {
        icon: CustomerAnalysisIcon,
        chartType: ChartType.BUBBLES,
        chartSettings: {
            axis: [
                AxisOptions.CustomerLifetimeValue,
                AxisOptions.EngagementPurchaseFrequency,
                AxisOptions.AnnualContractValue,
                AxisOptions.Risk,
                AxisOptions.CustomerSatisfaction,
                AxisOptions.NetPromoterScore,
            ],
            nodeSize: [
                NodeSizeOptions.AnnualContractValue,
                NodeSizeOptions.CustomerLifetimeValue,
                NodeSizeOptions.Risk,
                NodeSizeOptions.CustomerSatisfaction,
                NodeSizeOptions.NetPromoterScore,
                NodeSizeOptions.EngagementPurchaseFrequency,
                NodeSizeOptions.DefaultNodeSize,
            ],
            nodeColor: [
                NodeColorOptions.Risk,
                NodeColorOptions.Industry,
                NodeColorOptions.DisableNodeColor,
            ],
            layout: [
                {
                    enabled: true,
                    text: 'Show grid lines',
                    value: 'grid',
                },
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.COMPETITIVE_LANDSCAPE]: {
        icon: CompetitiveLandscapeIcon,
        chartType: ChartType.RADAR,
        chartSettings: defaultSettings,
    },
    [WorksheetNames.PORTERS_FIVE_FORCES]: {
        icon: PortersFiveForcesIcon,
        chartType: ChartType.PORTERS_FIVE_FORCES,
        chartSettings: {
            axis: [],
            nodeSize: [],
            nodeColor: [],
            include: portersOptions,
            layout: [
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.PESTLE_ANALYSIS]: {
        icon: PestleAnalysisIcon,
        chartType: ChartType.PESTLE_ANALYSIS,
        chartSettings: {
            axis: [],
            nodeSize: [],
            nodeColor: [],
            include: [],
            layout: [
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.MARKET_ANALYSIS]: {
        icon: MarketAnalysisIcon,
        chartType: ChartType.BUBBLES,
        chartSettings: {
            axis: [
                AxisOptions.CAGR,
                AxisOptions.MarketProfitability,
                AxisOptions.TAM,
                AxisOptions.SAM,
                AxisOptions.Maturity,
                AxisOptions.CompetitiveIntensity,
                AxisOptions.MarketProductFit,
            ],
            nodeSize: [
                NodeSizeOptions.CompetitiveIntensity,
                NodeSizeOptions.CAGR,
                NodeSizeOptions.MarketProfitability,
                NodeSizeOptions.TAM,
                NodeSizeOptions.SAM,
                NodeSizeOptions.Maturity,
                NodeSizeOptions.MarketProductFit,
                NodeSizeOptions.DefaultNodeSize,
            ],
            nodeColor: [
                NodeColorOptions.Category,
                NodeColorOptions.CompetitiveIntensity,
                NodeColorOptions.DisableNodeColor,
            ],
            layout: [
                {
                    enabled: true,
                    text: 'Show grid lines',
                    value: 'grid',
                },
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.STRATEGIC_OPTIONS]: {
        icon: StrategicOptionsIcon,
        chartType: ChartType.BUBBLES,
        chartSettings: {
            axis: [
                AxisOptions.Feasibility,
                AxisOptions.PotentialImpact,
                AxisOptions.InvestmentLevel,
                AxisOptions.Risk,
                AxisOptions.StrategicFit,
            ],
            nodeSize: [
                NodeSizeOptions.InvestmentLevel,
                NodeSizeOptions.Feasibility,
                NodeSizeOptions.PotentialImpact,
                NodeSizeOptions.Risk,
                NodeSizeOptions.StrategicFit,
                NodeSizeOptions.DefaultNodeSize,
            ],
            nodeColor: [
                NodeColorOptions.Category,
                NodeColorOptions.Type,
                NodeColorOptions.DisableNodeColor,
            ],
            layout: [
                {
                    enabled: true,
                    text: 'Show grid lines',
                    value: 'grid',
                },
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.STRATEGIC_CHALLENGES]: {
        icon: StrategicChallengesIcon,
        chartType: ChartType.BUBBLES,
        chartSettings: {
            axis: [
                AxisOptions.MitigationDifficulty,
                AxisOptions.LikelihoodOfOccurence,
                AxisOptions.PotentialImpact,
                AxisOptions.InvestmentLevel,
            ],
            nodeSize: [
                NodeSizeOptions.MitigationDifficulty,
                NodeSizeOptions.LikelihoodOfOccurence,
                NodeSizeOptions.PotentialImpact,
                NodeSizeOptions.InvestmentLevel,
                NodeSizeOptions.DefaultNodeSize,
            ],
            nodeColor: [
                NodeColorOptions.Category,
                NodeColorOptions.Urgency,
                NodeColorOptions.DisableNodeColor,
            ],
            layout: [
                {
                    enabled: true,
                    text: 'Show grid lines',
                    value: 'grid',
                },
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.RETROSPECTIVE]: {
        icon: RetrospectiveIcon,
        chartType: ChartType.RETROSPECTIVE,
        chartSettings: {
            axis: [],
            nodeSize: [],
            nodeColor: [],
            include: [],
            layout: [
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
    [WorksheetNames.ASSUMPTION_ANALYSIS]: {
        icon: AssumptionAnalysisIcon,
        chartType: ChartType.BUBBLES,
        chartSettings: {
            axis: [
                AxisOptions.Impact,
                AxisOptions.Confidence,
                AxisOptions.InvestmentLevel,
            ],
            nodeSize: [
                NodeSizeOptions.InvestmentLevel,
                NodeSizeOptions.Impact,
                NodeSizeOptions.Confidence,
                NodeSizeOptions.DefaultNodeSize,
            ],
            nodeColor: [
                NodeColorOptions.Category,
                NodeColorOptions.ValidationStatus,
                NodeColorOptions.Timing,
            ],
            layout: [
                {
                    enabled: true,
                    text: 'Show grid lines',
                    value: 'grid',
                },
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    },
};

const getWorksheetEnum = (value: string): WorksheetNames | undefined => {
    return Object.values(WorksheetNames).find(
        (enumValue) => enumValue.toUpperCase() === value.toUpperCase()
    ) as WorksheetNames | undefined;
};

export const getWorksheetThumbnail = (worksheetName: string) => {
    const worksheetEnum = getWorksheetEnum(worksheetName);
    if (!worksheetEnum) {
        console.error(`Invalid worksheet name: ${worksheetName}`);
        return undefined;
    }
    if (worksheetName.indexOf('summary') !== -1) {
        return SummaryScreen;
    }
    if (
        worksheetName === 'Assumption analysis' ||
        worksheetName === 'Idea analysis'
    ) {
        return PortfolioAnalysisIcon;
    }
    return worksheetObject[worksheetEnum]?.icon;
};

export type WorksheetSetting = {
    i: string;
    n: string;
    t: string;
    e: boolean;
    auto: string[];
    d: string;
    src: {
        cards: boolean;
        cardSets: boolean;
        workbooks: boolean;
    };
    lp: any;
    h: {
        s1: string;
        s2: string;
    };
};

export const getWorksheetSettings = (type: string): WorksheetSetting => {
    if (!type) return worksheets[0];

    const worksheet = worksheets.find((worksheet) => worksheet.i === type);
    return worksheet ?? worksheets[0];
};

interface HText {
    s1: string;
    s2: string;
}

interface HText {
    s1: string;
    s2: string;
}

interface HText {
    s1: string;
    s2: string;
}

export const getHelpText = (cardTypeId: string): HText | null => {
    const filteredWorksheets = worksheets.filter(
        (worksheet) => worksheet.t === 'List'
    );
    const matchingWorksheet = filteredWorksheets.find((worksheet) =>
        worksheet.auto.includes(cardTypeId)
    );
    return matchingWorksheet ? matchingWorksheet.h : null;
};

export const getChartType = (worksheetName: string) => {
    const worksheetEnum = getWorksheetEnum(worksheetName);

    if (!worksheetEnum) return;

    return worksheetObject[worksheetEnum]?.chartType;
};
export const getChartSettings = (worksheetName: string) => {
    const worksheetEnum = getWorksheetEnum(worksheetName);

    if (!worksheetEnum) return;
    const settings = worksheetObject[worksheetEnum]?.chartSettings;

    const chartSettings = {
        type: FieldType.CHARTSETTINGS as FieldType.CHARTSETTINGS,
        options: {
            chartData: {
                xAxisOptions: settings.axis,
                yAxisOptions: settings.axis,
                nodeSize: {
                    selection: settings.nodeSize,
                },
                nodeColor: {
                    selection: settings.nodeColor,
                },
                hideGaps: settings.hideGaps,
            },
            include: settings.include,
            layout: settings.layout,
        },
    };
    return chartSettings;
};
export const getListSettings = () => {
    const chartSettings = {
        type: FieldType.CHARTSETTINGS as FieldType.CHARTSETTINGS,
        options: {
            include: listOptions,
            layout: [
                {
                    enabled: true,
                    text: 'Include logo',
                    value: 'logo',
                },
            ],
        },
    };
    return chartSettings;
};

export const getRadarOptions = (cardTypeObject: any) => {
    const scoreDefinitions = cardTypeObject?.scoreDefinitions || [];
    const result: RadarOption[] = scoreDefinitions.map((item: any) => {
        return {
            name: item.name,
            selected: item.scoreType === ScoreType.Number ? true : false,
            scoreType: item.scoreType,
        };
    });
    return result;
};

export interface CardData {
    [scoreDefinitionId: string]: any;
}
export interface CardScoreData {
    originalCard: Card;
    name: string;
    scoreData: CardData;
    attributes: any;
    description: string | null | undefined;
}
const defaultScoreCalculation = (
    cardScoreData: CardData,
    defaultAxis: AxisOptions,
    pageOptions: any
): number => {
    const axis = pageOptions?.chartData?.defaultAxis || defaultAxis;
    return cardScoreData[axis]?.value || 0;
};
const calculateGap = (
    cardScoreData: CardData,
    targetOption: AxisOptions,
    actualOption: AxisOptions
): number => {
    return Math.max(
        0,
        (cardScoreData[targetOption]?.value || 0) -
            (cardScoreData[actualOption]?.value || 0)
    );
};
export const calculateBubbleValue = (
    option: AxisOptions,
    cardScoreData: CardData,
    defaultAxis: AxisOptions,
    pageOptions: any
) => {
    switch (option) {
        case AxisOptions.SkillGapCurrentVsDesired:
            return calculateGap(
                cardScoreData,
                AxisOptions.DesiredProficiency,
                AxisOptions.CurrentProficiency
            );
        case AxisOptions.SkillGapCurrentVsBenchmark:
            return calculateGap(
                cardScoreData,
                AxisOptions.BenchmarkProficiency,
                AxisOptions.CurrentProficiency
            );
        case AxisOptions.SkillGapDesiredVsBenchmark:
            return calculateGap(
                cardScoreData,
                AxisOptions.BenchmarkProficiency,
                AxisOptions.DesiredProficiency
            );
        case AxisOptions.MaturityGapCurrentVsDesired:
            return calculateGap(
                cardScoreData,
                AxisOptions.DesiredMaturity,
                AxisOptions.CurrentMaturity
            );
        case AxisOptions.MaturityGapCurrentVsBenchmark:
            return calculateGap(
                cardScoreData,
                AxisOptions.BenchmarkMaturity,
                AxisOptions.CurrentMaturity
            );
        case AxisOptions.MaturityGapDesiredVsBenchmark:
            return calculateGap(
                cardScoreData,
                AxisOptions.BenchmarkMaturity,
                AxisOptions.DesiredMaturity
            );

        default:
            return defaultScoreCalculation(
                cardScoreData,
                defaultAxis,
                pageOptions
            );
    }
};

export const calculateBubbleRadius = (
    option: NodeSizeOptions,
    cardScoreData: CardData,
    isThumbnail: boolean,
    maxValues: { [key: string]: number }
) => {
    const normalizeValue = (value: number, maxValue: number) => {
        if (maxValue === 0) return 0;
        return (value / maxValue) * 10;
    };
    const calculateRadius = () => {
        let value;

        switch (option) {
            case NodeSizeOptions.SkillGapCurrentVsDesired:
                const current =
                    cardScoreData[AxisOptions.CurrentProficiency]?.value || 0;
                const desired =
                    cardScoreData[AxisOptions.DesiredProficiency]?.value || 0;
                return (desired - current) * 7;

            case NodeSizeOptions.MaturityGapCurrentVsDesired:
                const currentMaturity =
                    cardScoreData[AxisOptions.CurrentMaturity]?.value || 0;
                const desiredMaturity =
                    cardScoreData[AxisOptions.DesiredMaturity]?.value || 0;
                return (desiredMaturity - currentMaturity) * 7;

            case NodeSizeOptions.PlanCost:
                value = cardScoreData[AxisOptions.PlanCost]?.value || 0;
                return normalizeValue(value, maxValues.PlanCost) * 7;

            case NodeSizeOptions.Value:
                value = cardScoreData[AxisOptions.Value]?.value || 0;
                return normalizeValue(value, maxValues.Value) * 7;

            case NodeSizeOptions.AnnualContractValue:
                value =
                    cardScoreData[AxisOptions.AnnualContractValue]?.value || 0;
                return normalizeValue(value, maxValues.AnnualContractValue) * 7;

            case NodeSizeOptions.CustomerLifetimeValue:
                value =
                    cardScoreData[AxisOptions.CustomerLifetimeValue]?.value ||
                    0;
                return (
                    normalizeValue(value, maxValues.CustomerLifetimeValue) * 7
                );

            case NodeSizeOptions.CustomerSatisfaction:
                value =
                    cardScoreData[AxisOptions.CustomerSatisfaction]?.value || 0;
                return (
                    normalizeValue(value, maxValues.CustomerSatisfaction) * 7
                );

            case NodeSizeOptions.DefaultNodeSize:
                return isThumbnail ? 20 : 30;

            default:
                value = cardScoreData[option]?.value || 0;
                return value * 7;
        }
    };

    return calculateRadius();
};

export const checkGaps = (pageOptions: any) => {
    if (!pageOptions?.chartData?.hideGaps) return;
    const gapOptions = new Set([
        AxisOptions.SkillGapCurrentVsBenchmark,
        AxisOptions.SkillGapCurrentVsDesired,
        AxisOptions.SkillGapDesiredVsBenchmark,
        AxisOptions.MaturityGapCurrentVsBenchmark,
        AxisOptions.MaturityGapCurrentVsDesired,
        AxisOptions.MaturityGapDesiredVsBenchmark,
    ]);

    const x = pageOptions?.chartData?.xAxis;
    const y = pageOptions?.chartData?.yAxis;
    const nodeSizeSelection = pageOptions?.chartData?.nodeSize?.selection;
    const hideGapsEnabled = pageOptions?.chartData?.hideGaps.enabled;

    return (
        (gapOptions.has(x) ||
            gapOptions.has(y) ||
            nodeSizeSelection === NodeSizeOptions.SkillGapCurrentVsDesired ||
            nodeSizeSelection ===
                NodeSizeOptions.MaturityGapCurrentVsDesired) &&
        hideGapsEnabled
    );
};
export const getMaxValue = (values: any) => {
    const filteredValues = values.filter((v: any) => v > 0);
    return filteredValues.length > 0 ? Math.max(...filteredValues) : 0;
};

export const getAttributeCard = async (id: string) => {
    const user = await fetchAuthSession();
    const userGroup = getUserOrganisation(user);
    const client = generateClient();

    try {
        const response = (await client.graphql({
            query: getCard,
            variables: {
                id: JSON.parse(id),
                organisation: userGroup,
            },
        })) as { data: GetCardQuery };

        const card = response.data.getCard;

        if (card) {
            return card.name;
        }
    } catch (e) {
        console.log(e);
    }
};

export const processCardData = (
    cards: Card[],
    scoreDefinitions: ScoreDefinition[]
) => {
    const cardsArray: CardScoreData[] = [];
    cards.forEach((card) => {
        if (
            card.name &&
            card.toScoreData &&
            card.toScoreData.items &&
            scoreDefinitions
        ) {
            const scoreData: CardData = {};

            card.toScoreData.items.forEach((item) => {
                if (
                    item &&
                    item.scoreDefinitionId &&
                    item.data &&
                    item.data.length > 0
                ) {
                    const scoreDef = scoreDefinitions.find(
                        (sd) => sd.id === item.scoreDefinitionId
                    );
                    if (scoreDef && scoreDef.name) {
                        const lastDataObject = item.data[item.data.length - 1];
                        scoreData[scoreDef.name] = {
                            ...lastDataObject,
                            scoreType: scoreDef.scoreType,
                        };
                    }
                }
            });
            cardsArray.push({
                originalCard: card,
                name: card.name,
                scoreData: scoreData,
                attributes: card.attributes,
                description: card.description,
            });
        }
    });
    return cardsArray;
};

export const calculateScoreValues = (
    option: AxisOptions,
    cardsArray: CardScoreData[]
) => {
    const optionValues = cardsArray
        .map((card) => {
            const optionScore = card?.scoreData[option]?.value;
            return optionScore !== undefined && optionScore !== null
                ? optionScore.toString()
                : '0';
        })
        .sort((a, b) => parseFloat(b) - parseFloat(a));
    return optionValues;
};

export const filterWorksheetCards = (
    cards: Card[],
    filterCritera: WorksheetFilterCards
) => {
    switch (filterCritera.primary) {
        case WorksheetFilterOptions.TOP_THREE:
            const returnValues: Card[] = [];
            const cardsGroupedByTypeId = _.groupBy(
                cards,
                (card) => card.cardToCardTypeId
            );

            const keys = Object.keys(cardsGroupedByTypeId);

            keys.forEach((key) => {
                const scoreDefinitionId =
                    cardsGroupedByTypeId[key][0].toCardType
                        .defaultScoreDefinitionId;

                const items: any = cardsGroupedByTypeId[key]
                    .map((item) => {
                        return {
                            ...item,
                            score: getPrimaryScoreValue(
                                item.toScoreData?.items,
                                scoreDefinitionId
                            ),
                        };
                    })
                    .sort((a, b) => (b.score ?? 1) - (a.score ?? 1))
                    .splice(0, 3);

                items.forEach((item: Card) => {
                    returnValues.push(item);
                });
            });

            return returnValues;

        case WorksheetFilterOptions.OWNER:
            const owners = filterCritera.secondary.owner;
            if (!owners.length) return cards;

            return cards.filter((card) =>
                owners.includes(card.owner as string)
            );

        case WorksheetFilterOptions.CREATED_DATE:
            switch (filterCritera.secondary.createdDate.value) {
                case WorksheetFilterDateOptions.LAST_HOUR:
                    const time = new Date();
                    time.setHours(time.getHours() - 1);

                    return cards.filter(
                        (card) => new Date(card.createdAt) > time
                    );

                case WorksheetFilterDateOptions.TODAY:
                    let today = new Date();
                    today.setDate(today.getDate() - 1);
                    let todaydatetime = DateTime.fromJSDate(today);

                    return cards.filter(
                        (card) => card.createdAt > todaydatetime.toString()
                    );
                case WorksheetFilterDateOptions.THIS_WEEK:
                    let thisWeek = new Date();
                    thisWeek.setDate(thisWeek.getDate() - 7);
                    let thisWeekdatetime = DateTime.fromJSDate(thisWeek);

                    return cards.filter(
                        (card) => card.createdAt > thisWeekdatetime.toString()
                    );
                case WorksheetFilterDateOptions.CUSTOM_DATE:
                    const start = filterCritera.secondary.createdDate.start;
                    const end = filterCritera.secondary.createdDate.end;
                    if (!start && !end) return cards;

                    if (start && end && start > end) return cards;

                    if (start && (!end || end === 'Invalid DateTime')) {
                        return cards.filter((card) => card.createdAt > start);
                    }
                    if (end && (!start || start === 'Invalid DateTime')) {
                        return cards.filter((card) => end > card.createdAt);
                    }
                    if (start && end) {
                        return cards.filter(
                            (card) =>
                                card.createdAt > start && end > card.createdAt
                        );
                    }
            }

        default:
            return cards;
    }
};

export const getWorksheetBackgroundColor = (
    worksheetItem: Worksheet,
    cardTypeObject: {
        [key: string]: CardType;
    }
) => {
    const currentWorksheet = worksheets.find(
        (worksheet) => worksheet.i === worksheetItem.typeId
    );

    const subCategory = currentWorksheet?.auto[0];
    const categoryId = cardTypeObject[subCategory as string]?.cardCategoryID;

    const Cat: any = Category;
    const category = Object.keys(Cat).find((key) => Cat[key] === categoryId);

    const data = {
        category: Cat[category as string] ?? Category.SWOT,
        id: worksheetItem.id,
        name: currentWorksheet?.n ?? '',
    };

    const textColor = getCategoryHexText(
        Cat[category as string] ?? Category.SWOT
    );

    return { data, textColor };
};

