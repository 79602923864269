import { Box, Typography, useTheme } from '@mui/material';
import { formatScore } from '../../helpers/utils';
import { ScoreType } from '../../API';

interface HeaderScoreProps {
    name: string;
    value?: number;
    borderColor?: string;
    type?: ScoreType;
    mediumCard?: boolean;
    smallCard?: boolean;
    swotColor?: string;
    cardTypeName?: string;
    cardToCardTypeId?: string;
}

const HeaderScore = ({
    value,
    name,
    borderColor,
    type,
    mediumCard,
    smallCard,
    swotColor,
    cardTypeName,
    cardToCardTypeId,
}: HeaderScoreProps) => {
    const theme = useTheme();
    const isTwoWords = (str: string) => str.trim().split(/\s+/).length === 2;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            {!smallCard && (
                <Typography
                    variant="body2"
                    fontSize={mediumCard ? '8px' : smallCard ? '11px' : '11px'}
                    lineHeight={'100%'}
                    color={
                        cardToCardTypeId === '16'
                            ? `rgba(0, 0, 0, 0.87)`
                            : swotColor
                            ? '#ffffff'
                            : 'inherit'
                    }
                    sx={{
                        ...(isTwoWords(name) &&
                            isTwoWords(cardTypeName || '') && {
                                maxWidth: '80px',
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                                lineHeight: '1.2',
                                whiteSpace: 'normal',
                                textAlign: 'right',
                            }),
                    }}
                >
                    {name}
                </Typography>
            )}

            <Box
                sx={
                    value && value.toString().length > 2
                        ? { ml: mediumCard || smallCard ? 0.5 : 1 }
                        : {
                              borderRadius:
                                  type === 'Indicator' ? '6px' : '50%',
                              p: mediumCard || smallCard ? '0.5rem' : '0.75rem',
                              border: '2px solid',
                              borderColor: '#fff',
                              backgroundColor: value ? borderColor : '#9e9e9e',
                              ml: mediumCard || smallCard ? 0.5 : 1,
                              width: 2,
                              height: 2,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              ...(type === 'Indicator' && {
                                  background:
                                      value === 0
                                          ? '#9e9e9e'
                                          : value === 1
                                          ? theme.palette.warning.main
                                          : value === 2
                                          ? theme.palette.warning.light
                                          : value === 3
                                          ? theme.palette.success.light
                                          : '#9e9e9e',
                              }),
                          }
                }
            >
                {type !== 'Indicator' && (
                    <Typography
                        variant="body2"
                        sx={{
                            color: '#fff',
                            fontSize: mediumCard || smallCard ? '10px' : '16px',
                        }}
                    >
                        <strong>
                            {type === ScoreType.Currency && '£'}
                            {value != null && value.toString().length > 2
                                ? `${formatScore(value)}`
                                : value != null
                                ? value
                                : '-'}
                        </strong>
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default HeaderScore;
