import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Card, EntityType, GetCardQuery, UpdateCardMutation } from '../../API';
import { getUserOrganisation } from '../../helpers/auth';
import { AppContext, NotificationContext } from '../contexts';
import { getCard } from '../../graphql/queries';
import { GraphQLQuery, generateClient } from 'aws-amplify/api';
import CardComponent from '../cards/CardComponent';
import StyledCardContent from '../../components/cards/CardContent';
import { CardPage, PageIdentifier } from '../cards/cardTypes';
import CardDetails from '../cards/CardDetails';
import { CardToDelete, cardToCardComponentProps } from '../../pages/cards';
import CardScores from '../cards/CardScores';
import CardInfo from '../cards/CardInfo';
import CardActivity from '../cards/CardActivity';
import CardRelationships from '../cards/CardRelationships';
import CardComments from '../cards/CardComments';
import { INotification } from '.';
import { StyledModal } from '../Modal';
import ModalContainer from '../ModalContainer';
import CardDelete from '../cards/CardDelete';
import { getFutureDate } from '../../helpers/utils';
import { copyCard, updateCard } from '../../graphql/mutations';
import CreateCard from '../forms/CreateCard';

const CardContainer = ({ notification }: { notification: INotification }) => {
    const [card, setCard] = useState<Card | null>(null);
    const [activeCardPage, setActiveCardPage] = useState<CardPage>(
        CardPage.DETAILS
    );
    const [loading, setLoading] = useState(true);
    const { user } = useContext(AppContext);
    const {
        selectedNotification,
        setSelectedNotification,
        modalOpen,
        setModalOpen,
        scoreNames,
    } = useContext(NotificationContext);
    const [multiSelectedCards, setMultiSelectedCards] = useState<
        CardToDelete[] | []
    >([]);
    const [showCreateCardWizard, setShowCreateCardWizard] = useState(false);
    const [showDeleteCardWizard, setShowDeleteCardWizard] = useState(false);
    const [convertSparkCardId, setConvertSparkCardId] = useState('');
    const [cardToDelete, setCardToDelete] = useState<CardToDelete | null>(null);
    const [deleting, setDeleting] = useState(false);

    let score;

    if (notification.subContextType === 'ScoreData') {
        const parts = notification.subContext?.split('#') ?? [];
        score = scoreNames.find((scoreName) => scoreName.id === parts[0]);
    }

    const theme = useTheme();

    useEffect(() => {
        setLoading(true);
        setActiveCardPage(CardPage.DETAILS);
        const getCardInfo = async () => {
            const userGroup = await getUserOrganisation(user);

            const client = generateClient();

            const response = (await client.graphql({
                query: getCard,
                variables: {
                    id: notification.contextId,
                    organisation: userGroup,
                },
            })) as { data: GetCardQuery };

            setCard(response.data.getCard as Card);
            setLoading(false);
        };
        if (notification) {
            if (notification.subContextType === 'Comment') {
                setActiveCardPage(CardPage.COMMENTS);
            } else if (notification.subContextType === 'ScoreData') {
                setActiveCardPage(CardPage.SCORES);
            }
            getCardInfo();
        }
    }, [notification]);

    const handleDelete = async (id: string, organisation: string) => {
        setModalOpen(true);
        setShowDeleteCardWizard(true);
    };

    const refreshCard = async () => {};
    const client = generateClient();

    const confirmDelete = async (
        id: string,
        organisation: string,
        multiDelete?: boolean
    ) => {
        const data = {
            id: id,
            organisation: organisation,
            deleteAfter: getFutureDate(30),
        };
        try {
            const res = await client.graphql<GraphQLQuery<UpdateCardMutation>>({
                query: updateCard,
                variables: {
                    input: data,
                },
            });
            setModalOpen(true);
        } catch (err) {}
        if (!multiDelete) setShowDeleteCardWizard(false);
    };
    const handleCopy = async (cardId: string) => {
        setModalOpen(true);

        try {
            const res = await client.graphql({
                query: copyCard,
                variables: {
                    originalCardId: cardId,
                },
            });
        } catch (err) {}
        setModalOpen(false);
    };
    const handleConvert = async (id: string) => {
        setModalOpen(true);
        setShowCreateCardWizard(true);
        setConvertSparkCardId(id);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '48rem',
            }}
        >
            <Box
                sx={{
                    padding: '15px',
                    backgroundColor:
                        theme.palette.mode === 'dark' ? '#000000' : '#ffffff',
                    borderTopRightRadius: '3px',
                    borderBottomRightRadius: '3px',
                    boxShadow: '10px 3px 6px 2px rgba(0,0,0,0.30)',
                    margin: '20% 0',
                    width: '100%',
                    minHeight: '864px',
                    button: {
                        '.MuiTypography-root': {
                            color: theme.palette.text.primary,
                        },
                        '&.MuiButton-outlinedPrimary': {
                            color: theme.palette.primary.main,
                        },
                    },
                    '.MuiCardContent-root': {
                        color: theme.palette.text.primary,
                    },
                    '.MuiButtonBase-root': {
                        '&.MuiIconButton-root': {
                            color: theme.palette.action.active,
                            '&.MuiIconButton-colorPrimary': {
                                color: theme.palette.primary.main,
                            },
                        },
                    },
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress
                            sx={{
                                width: '100px!important',
                                height: '100px!important',
                                svg: { width: '100px', height: '100px' },
                            }}
                        />
                    </Box>
                ) : card ? (
                    <CardComponent
                        data={cardToCardComponentProps(card)}
                        handleDelete={handleDelete}
                        onClick={(cardId: string, cardPage?: CardPage) =>
                            setActiveCardPage(cardPage as CardPage)
                        }
                        handleCopy={handleCopy}
                        handleClose={() => {
                            setSelectedNotification(null);
                            setModalOpen(false);
                        }}
                        handleConvert={handleConvert}
                        expanded
                    >
                        <StyledCardContent expanded={true}>
                            {
                                {
                                    [CardPage.DETAILS]: (
                                        <CardDetails
                                            card={card}
                                            onUpdate={refreshCard}
                                            isCard={true}
                                            notificationCard={true}
                                            type={
                                                notification.subContext ??
                                                undefined
                                            }
                                        />
                                    ),
                                    [CardPage.SCORES]: (
                                        <CardScores
                                            card={card}
                                            onUpdate={refreshCard}
                                            selectedScore={score?.id}
                                        />
                                    ),
                                    [CardPage.INFO]: (
                                        <CardInfo
                                            card={card}
                                            onUpdate={refreshCard}
                                        />
                                    ),
                                    [CardPage.ACTIVITY]: (
                                        <CardActivity card={card} />
                                    ),
                                    [CardPage.RELATIONSHIPS]: (
                                        <CardRelationships card={card} />
                                    ),
                                    [CardPage.COMMENTS]: (
                                        <CardComments
                                            card={card}
                                            onUpdate={refreshCard}
                                            selected={
                                                notification.subContext ?? ''
                                            }
                                            context={EntityType.Card}
                                        />
                                    ),
                                }[activeCardPage]
                            }
                        </StyledCardContent>
                    </CardComponent>
                ) : (
                    <Box>
                        <Typography variant="h4" sx={{ textAlign: 'center' }}>
                            No card found
                        </Typography>
                    </Box>
                )}
            </Box>
            <StyledModal
                key="delete-card-modal"
                open={showDeleteCardWizard}
                onClose={() => {
                    setModalOpen(false);
                    setShowDeleteCardWizard(false);
                    setCardToDelete(null);
                    setMultiSelectedCards([]);
                }}
                disableEnforceFocus
                sx={{
                    zIndex: 1500,
                }}
            >
                <Box>
                    {showDeleteCardWizard &&
                        (multiSelectedCards || cardToDelete) &&
                        (cardToDelete ? (
                            <ModalContainer sx={{ maxWidth: '40rem' }}>
                                <CardDelete
                                    id={card?.id}
                                    organisation={card?.organisation}
                                    toggleDeleteWizard={setShowDeleteCardWizard}
                                    setCardToDelete={setCardToDelete}
                                    confirmDelete={confirmDelete}
                                    cardCategoryId={cardToDelete.cardCategoryId}
                                    pageIdentifier={PageIdentifier.CARDS}
                                    cancel={() => setModalOpen(false)}
                                />
                            </ModalContainer>
                        ) : (
                            <ModalContainer sx={{ maxWidth: '40rem' }}>
                                <CardDelete
                                    id={card?.id}
                                    organisation={card?.organisation}
                                    toggleDeleteWizard={setShowDeleteCardWizard}
                                    pageIdentifier={PageIdentifier.CARDS}
                                    multiSelectedCards={multiSelectedCards}
                                    setMultiSelectedCards={
                                        setMultiSelectedCards
                                    }
                                    confirmDelete={confirmDelete}
                                    deleting={deleting}
                                    cancel={() => setModalOpen(false)}
                                />
                            </ModalContainer>
                        ))}
                </Box>
            </StyledModal>
            <StyledModal
                key="modal"
                open={showCreateCardWizard}
                onClose={() => {
                    setShowCreateCardWizard(false);
                    setModalOpen(false);
                }}
                aria-labelledby=""
                aria-describedby=""
                data-automation-id="create-card-modal"
                disableEnforceFocus
                sx={{ zIndex: 1402 }}
            >
                <Box>
                    {showCreateCardWizard && (
                        <ModalContainer sx={{ maxWidth: '75rem' }}>
                            {
                                <CreateCard
                                    handleClose={() => {
                                        setShowCreateCardWizard(false);
                                        setModalOpen(false);
                                    }}
                                    pageIdentifier={PageIdentifier.CARDS}
                                    convertSparkCardId={convertSparkCardId}
                                    cardToConvert={card}
                                    close={() => setModalOpen(false)}
                                />
                            }
                        </ModalContainer>
                    )}
                </Box>
            </StyledModal>
        </Box>
    );
};

export default CardContainer;
