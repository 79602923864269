import {
    Box,
    Divider,
    Link,
    Paper,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import UserAvatar from '../profile/UserAvatar';
import { shortDateStringFromISO, timeStringFromISO } from '../../helpers/utils';
import {
    AuditEntry,
    AuditType,
    EventContext,
    RelationshipType,
} from '../../API';
import { INotification } from '.';
import { useContext, useEffect, useState } from 'react';
import { AppContext, NotificationContext } from '../contexts';
import { getIconForEntityType } from '../cards/CardActivity';
import TimelineDot from '@mui/lab/TimelineDot';
import {
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    TimelineSeparator,
} from '@mui/lab';
import { getAttributeName } from '../playbooks/lists/CardDetailsFromWorksheet';
import { getUserName } from '../../helpers/auth';

interface NotificationProps {
    notification: INotification;
    unread?: boolean;
}

const Notification = ({ notification, unread }: NotificationProps) => {
    const theme = useTheme();
    const {
        notificationNames,
        setNotifications,
        setUnreadNotifications,
        unreadNotifications,
        scoreNames,
        selectedNotification,
        setSelectedNotification,
    } = useContext(NotificationContext);
    const { cardTypeObject, users } = useContext(AppContext);

    const getCommentContent = () => {
        return (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    {`${
                        notification.operation === AuditType.ItemCreated
                            ? 'Added the following comment to'
                            : notification.operation === AuditType.ItemUpdated
                            ? 'Updated a comment on'
                            : 'Removed the following comment from'
                    }`}
                    <strong> {notification.name}</strong>
                    {`${
                        notification.operation === AuditType.ItemCreated
                            ? ':'
                            : notification.operation === AuditType.ItemUpdated
                            ? ' with the following changes: '
                            : ':'
                    }`}
                </Typography>
                {notification.operation === AuditType.ItemUpdated ? (
                    <Typography
                        sx={{
                            fontSize: '.9rem',
                            lineHeight: 1.4,
                        }}
                    >
                        Changed: <i>"{notification.old}"</i> to{' '}
                        <i>"{notification.new}"</i>
                    </Typography>
                ) : notification.operation === AuditType.ItemCreated ? (
                    <Typography
                        sx={{
                            fontSize: '.9rem',
                            lineHeight: 1.4,
                            fontStyle: 'italic',
                        }}
                    >
                        "{notification.new}"
                    </Typography>
                ) : (
                    <Typography
                        sx={{
                            fontSize: '.9rem',
                            lineHeight: 1.4,
                            fontStyle: 'italic',
                        }}
                    >
                        {notification.old}
                    </Typography>
                )}
            </Box>
        );
    };
    const getReactionContent = () => {
        return (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    {`${
                        notification.operation === AuditType.ReactionAdded
                            ? 'Liked a comment on '
                            : 'Unliked a comment on '
                    }`}
                    <strong>{notification.name}</strong>
                </Typography>
            </Box>
        );
    };
    const getCardCreated = () => {
        return notification.operation === AuditType.ItemCreatedFrom ? (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    Created card{' '}
                    <span style={{ fontStyle: 'italic' }}>
                        {` ${notificationNames[notification.contextId]}`}
                    </span>
                    {` from `}
                    <span style={{ fontStyle: 'italic' }}>
                        {` ${
                            notificationNames[notification.subContext as string]
                        }`}
                    </span>
                    {notification.eventContext && (
                        <span style={{ fontStyle: 'italic' }}>
                            {` on ${
                                notificationNames[
                                    notification.eventContextId as string
                                ]
                            }`}
                        </span>
                    )}
                </Typography>
            </Box>
        ) : (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    Created card: <strong>{notification.name}</strong>
                </Typography>
            </Box>
        );
    };
    const getCardUpdated = () => {
        return 'card updated';
    };
    const getCardDeleted = () => {
        return (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    Deleted card: <strong>{notification.name}</strong>
                </Typography>
            </Box>
        );
    };
    const getCardTypeUpdated = () => {
        const oldType = cardTypeObject[notification.old ?? 0];
        const newType = cardTypeObject[notification.new ?? 0];

        return (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    Changed card <strong>{notification?.name}</strong>
                </Typography>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    from <i>{oldType?.name}</i> to <i> {newType?.name} </i>
                </Typography>
            </Box>
        );
    };

    const getAttributeName = (attrId: string) => {
        const values = Object.values(cardTypeObject);

        let name = '';

        for (const value of values) {
            let attr = value?.attributeDefinitions?.find(
                (attr) => attr?.id === attrId
            );

            if (attr) {
                name = attr.name;
                break;
            }
        }

        return name;
    };
    const getScoreCreated = () => {
        const parts = notification.subContext?.split('#') ?? [];
        const score = scoreNames.find((scoreName) => scoreName.id === parts[0]);

        const scorePart = notification.new?.split('#') ?? [];

        return (
            <>
                {score ? (
                    <Box>
                        <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                            Added a score of <i>{scorePart[0]}</i> to
                            <i> {score.name}</i> on
                        </Typography>
                        <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                            <strong>{notification.name}</strong>
                        </Typography>
                    </Box>
                ) : (
                    <></>
                )}
            </>
        );
    };
    const getScoreUpdated = () => {
        const parts = notification.subContext?.split('#') ?? [];
        const score = scoreNames.find((scoreName) => scoreName.id === parts[0]);
        const scorePartOld = notification.old?.split('#') ?? [];
        const scorePartNew = notification.new?.split('#') ?? [];
        return (
            <>
                {score ? (
                    <Box>
                        <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                            Changed score from <i>{scorePartOld[0]}</i> to{' '}
                            <i>{scorePartNew[0]}</i> on
                            <i> {score.name}</i> on:
                        </Typography>
                        <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                            <strong>{notification.name}</strong>
                        </Typography>
                    </Box>
                ) : (
                    <Box></Box>
                )}
            </>
        );
    };
    const getScoreDeleted = () => {
        return (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    Deleted score on
                </Typography>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    <strong>{notification.name}</strong>
                </Typography>
            </Box>
        );
    };

    const getPermissionsUpdated = () => {
        return (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    Permissions updated on
                    <strong> {notification.name}</strong>
                </Typography>
            </Box>
        );
    };
    const getCreatedFrom = () => {
        const cards = notification.new?.split('#') ?? [];
        const source = cards[1].substring(0, cards[1].indexOf('_'));
        const target = cards[2];

        return (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    Created card{' '}
                    <span style={{ fontStyle: 'italic' }}>
                        {` ${notificationNames[source]}`}
                    </span>
                    &nbsp;on{' '}
                    <span style={{ fontWeight: 'bold' }}>
                        {shortDateStringFromISO(notification?.createdAt)}
                    </span>{' '}
                    from
                    <span style={{ fontStyle: 'italic' }}>
                        {` ${notificationNames[target]}`}
                    </span>
                </Typography>
            </Box>
        );
    };
    const getItemLinkedContent = () => {
        return (
            <>
                {notification.relationshipType ===
                RelationshipType.CreatedFrom ? (
                    getCreatedFrom()
                ) : (
                    <Box>
                        <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                            Added card to worksheet:
                        </Typography>
                        <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                            <strong> {notification.name}</strong>
                        </Typography>
                    </Box>
                )}
            </>
        );
    };
    const getItemUnLinkedContent = () => {
        return (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    Removed card from worksheet:
                </Typography>

                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    <strong> {notification.name}</strong>
                </Typography>
            </Box>
        );
    };
    const getCardAttributes = () => {
        switch (notification.operation) {
            case 'AttributeUpdated':
                return (
                    <Box>
                        <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                            Changed
                            <strong>
                                {notification.subContext === 'description'
                                    ? ' description '
                                    : ` ${getAttributeName(
                                          notification?.subContext ?? ''
                                      )} `}
                            </strong>
                            on <strong> {notification.name}</strong>
                        </Typography>
                        <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                            from{' '}
                            <i>
                                {' '}
                                {!!notification.old && notification.old !== ''
                                    ? notification.old
                                    : '___'}
                            </i>{' '}
                            to
                            <i>
                                {' '}
                                <i>
                                    {' '}
                                    {!!notification.new &&
                                    notification.new !== ''
                                        ? notification.new
                                        : '___'}
                                </i>{' '}
                            </i>
                        </Typography>
                    </Box>
                );
            default:
                return <Box></Box>;
        }
    };

    const getOwnerChanged = () => {
        return (
            <Box>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    Changed owner from
                </Typography>
                <Typography sx={{ fontSize: '.9rem', lineHeight: 1.4 }}>
                    <i> {getUserName(notification.old ?? '', users)}</i> to{' '}
                    <strong>
                        {' '}
                        {getUserName(notification.new ?? '', users)}
                    </strong>
                </Typography>
            </Box>
        );
    };

    const getCardContent = () => {
        switch (notification.subContextType) {
            case 'Comment':
                switch (notification.operation) {
                    case AuditType.ReactionAdded:
                        return getReactionContent();
                    case AuditType.ReactionRemoved:
                        return getReactionContent();
                    default:
                        return getCommentContent();
                }
            case null:
                switch (notification.operation) {
                    case AuditType.ItemCreated:
                        return getCardCreated();
                    case AuditType.ItemUpdated:
                        return getCardUpdated();
                    default:
                        return notification.operation ===
                            AuditType.CardTypeChanged
                            ? getCardTypeUpdated()
                            : getCardDeleted();
                }
            case 'ScoreData':
                switch (notification.operation) {
                    case AuditType.ItemCreated:
                        return getScoreCreated();
                    case AuditType.ItemUpdated:
                        return getScoreUpdated();
                    default:
                        return getScoreDeleted();
                }
            case 'Relationship':
                switch (notification.operation) {
                    case AuditType.ItemLinked:
                        return getItemLinkedContent();
                    case AuditType.ItemUnlinked:
                        return getItemUnLinkedContent();
                    default:
                        return notification.operation;
                }
            case 'Attribute':
                return getCardAttributes();
            case 'Organisation':
                return notification.operation === AuditType.PermissionGranted ||
                    notification.operation === AuditType.PermissionRevoked
                    ? getPermissionsUpdated()
                    : '';
            case 'UserProfile':
                return notification.operation === AuditType.OwnerChanged
                    ? getOwnerChanged()
                    : '';
            case 'Card':
                return notification.operation === AuditType.ItemCreatedFrom
                    ? getCardCreated()
                    : '';
            default:
                return '';
        }
    };

    const getNotificationContent = () => {
        switch (notification.contextType) {
            case 'Card':
                return getCardContent();
            default:
                return '';
        }
    };

    return (
        <Paper
            data-testid="notification"
            {...(unreadNotifications.findIndex(
                (unread) => unread.id === notification.id
            ) !== -1 && {
                onClick: () => {
                    setUnreadNotifications((unread) => {
                        return unread.filter(
                            (item) => item.id !== notification.id
                        );
                    });
                },
            })}
            key={notification.id}
            sx={{
                mb: '20px',
                cursor:
                    unreadNotifications.findIndex(
                        (unread) => unread.id === notification.id
                    ) !== -1
                        ? 'pointer'
                        : 'inherit',

                ...(selectedNotification?.id === notification.id && {
                    border: 'solid 3px #bed0f3',
                }),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: 1.5,
                    pr: 1.5,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ml: 10.5,
                    }}
                >
                    {notification.user ? (
                        <UserAvatar
                            userId={
                                notification.user?.indexOf(':') !== -1
                                    ? notification.user?.substring(
                                          0,
                                          notification.user.indexOf(':')
                                      )
                                    : notification.user
                            }
                            isProfile={false}
                            cardCategory="default"
                            avatarOnly
                        />
                    ) : (
                        <Box
                            sx={{
                                borderRadius: '50%',
                                backgroundColor: '#3d74de',
                                width: '30px',
                                height: '30px',
                            }}
                        />
                    )}
                </Box>
                <Tooltip
                    title={`${timeStringFromISO(notification?.createdAt)}`}
                    placement="top"
                >
                    <Typography sx={{ fontSize: '.9rem', lineHeight: 2 }}>
                        {shortDateStringFromISO(notification.createdAt)}
                    </Typography>
                </Tooltip>
            </Box>

            <TimelineItem key={notification.id}>
                <TimelineSeparator>
                    <TimelineDot color={'primary'} sx={{ zIndex: 10 }}>
                        {getIconForEntityType(notification)}
                    </TimelineDot>
                    <TimelineConnector
                        sx={{
                            mt: -1.45,
                            mb: -12,
                            ml: '-2px',
                            zIndex: 5,
                        }}
                    />
                </TimelineSeparator>
                <TimelineContent
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            color: 'inherit',
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <Link
                            style={{
                                flex: 1,
                                color: 'inherit',
                            }}
                            onClick={() =>
                                setSelectedNotification(notification)
                            }
                        >
                            <Box sx={{ flex: 1 }}>
                                {getNotificationContent()}
                            </Box>
                        </Link>
                        {unreadNotifications.findIndex(
                            (unread) => unread.id === notification.id
                        ) !== -1 && (
                            <Box
                                data-testid="unread-indicator"
                                sx={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    background: 'red',
                                }}
                            />
                        )}
                    </Box>
                </TimelineContent>
            </TimelineItem>
            <Box sx={{ height: '20px' }} />
        </Paper>
    );
};

export default Notification;
