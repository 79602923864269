import { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Tooltip,
    styled,
    useTheme,
} from '@mui/material';
import { ReactComponent as PencilIcon } from '../../../assets/icons/Pencil.svg';
import { ReactComponent as MinimizeIcon } from '../../../assets/icons/Minimize.svg';
import { ReactComponent as Navigate } from '../../../assets/icons/Navigate.svg';
import { DateTime } from 'luxon';
import { generateClient, GraphQLQuery } from '@aws-amplify/api';
import {
    Card,
    CardSet,
    Playbook,
    UpdateCardMutation,
    UpdateCardSetMutation,
    UpdatePlaybookMutation,
} from '../../../API';
import { StyledSection } from '../../StyledElements';
import {
    updateCard,
    updateCardSet,
    updatePlaybook,
} from '../../../graphql/mutations';
import { getContext, isJsonString } from '../../../helpers/utils';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionsType } from '../../../hooks/usePermissions';

import { Attribute } from '../../../API';
import { UserPermissions } from '../../../globals';
import AttributeSection from './AttributeSection';
import Main from './Main';
import { AppContext } from '../../contexts';
import { CardContext } from '../context';
import UserAvatar from '../../profile/UserAvatar';
import { useLocation, useParams } from 'react-router-dom';

interface MockEvent {
    target: {
        value: string | unknown;
    };
}

type ChangeEvent = MockEvent | null | DateTime;

interface CardDetailsProps {
    card: Card | CardSet | Playbook;
    onUpdate?: (id: string, organisation: string) => void;
    isCard?: boolean;
    deletedCard?: boolean | undefined;
    notificationCard?: boolean;
    type?: string;
    worksheetCard?: any;
}

const CardDetails = ({
    card,
    onUpdate,
    isCard,
    deletedCard,
    notificationCard,
    type,
    worksheetCard,
}: CardDetailsProps) => {
    const { isGlobalAdmin } = useContext(AppContext);
    const { goBack, backCard, handleClose, handleConvert } =
        useContext(CardContext);
    const [cardObject, setCardObject] = useState<Card | CardSet | null>(null);
    const [selectedCard, setSelectedCard] = useState<Card | CardSet | null>(
        null
    );
    const [loading, setLoading] = useState<boolean>(false);
    const permissions = usePermissions(PermissionsType.CARD, card);
    const theme = useTheme();

    const client = generateClient();
    const location = useLocation();
    const { id } = useParams();

    useEffect(() => {
        const cardObj = card as Card;
        if (cardObj.attributes) {
            const atts = cardObj.attributes?.map((item) => {
                if (item?.value) {
                    return {
                        attributeDefinitionID: item?.attributeDefinitionID,
                        value: isJsonString(item.value)
                            ? JSON.parse(item?.value)
                            : item.value,
                    };
                }
            });

            cardObj.attributes = atts as Attribute[];
        }

        setCardObject(cardObj as Card);
    }, [card]);

    const [edit, setEdit] = useState(false);

    const updateAttributes = (event: ChangeEvent, name: string) => {
        if (!!!event || !cardObject || cardObject.__typename === 'CardSet')
            return;

        const card = { ...cardObject };

        card.attributes = card.attributes ?? [];
        const index = card.attributes.findIndex(
            (attribute: Attribute | null) =>
                attribute?.attributeDefinitionID === name
        );

        if (index === -1) {
            card.attributes.push({
                attributeDefinitionID: name,
                value:
                    event instanceof DateTime
                        ? event.toLocaleString()
                        : event.target.value,
            } as Attribute);
        } else {
            card.attributes[index] = {
                attributeDefinitionID: name,
                value:
                    event instanceof DateTime
                        ? event.toLocaleString()
                        : event.target.value,
            } as Attribute;
        }
        setCardObject(card);
    };

    const updateCardDetails = async () => {
        setLoading(true);
        const context = getContext(location.pathname, id);

        const mutation =
            card.__typename === 'CardSet'
                ? updateCardSet
                : card.__typename === 'Playbook'
                ? updatePlaybook
                : updateCard;

        let type = undefined;

        if (card.__typename === 'CardSet') {
            type = card.type === 'WB' ? 'WB' : 'CS';
        }

        const data = {
            id: cardObject?.id,
            name: cardObject?.name,
            description: cardObject?.description,
            ...(cardObject?.__typename !== 'CardSet' && {
                attributes: cardObject?.attributes,
            }),
            organisation: cardObject?.organisation,
            type: type,
            // This is required for the compound key creation in the AppSync VTL resolver
            createdAt: card.createdAt,
            ...(card.__typename === 'Card' && {
                _auditContextType: context.contextType,
                _auditContextId: context.contextId,
            }),
        };
        try {
            await client.graphql<
                GraphQLQuery<
                    | UpdateCardMutation
                    | UpdateCardSetMutation
                    | UpdatePlaybookMutation
                >
            >({
                query: mutation,
                variables: {
                    input: {
                        ...data,
                        attributes: data?.attributes?.map((item) => {
                            if (item?.value) {
                                return {
                                    attributeDefinitionID:
                                        item?.attributeDefinitionID,
                                    value: JSON.stringify(item?.value),
                                };
                            }
                        }),
                        organisation: data?.organisation || 'x',
                    },
                },
            });

            setLoading(false);
            setEdit(false);
            onUpdate &&
                cardObject?.id &&
                onUpdate(cardObject?.id, cardObject?.organisation);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Box sx={{ height: worksheetCard ? 'calc(100vh - 580px)' : '100%' }}>
            <StyledSection>
                <Box
                    sx={{
                        background: theme.palette.background.default,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        alignItems: 'center',
                        top: worksheetCard ? '40px' : '48px',
                        left: 0,
                        px: 6,
                        pt: edit ? 2 : 1,
                        zIndex: 1000,
                    }}
                >
                    {backCard ? (
                        <Tooltip
                            title="Back to previous card"
                            placement="right-start"
                        >
                            <IconButton
                                data-testid="minimize"
                                onClick={() => setSelectedCard(null)}
                                sx={{
                                    cursor: 'pointer',
                                    ml: '40px',
                                    width: '40px',
                                    height: '40px',
                                }}
                            >
                                <Navigate
                                    style={{
                                        width: '30px',
                                        transform: 'scaleX(-1)',
                                    }}
                                    color="#FFF"
                                    onClick={() => goBack && goBack()}
                                />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Box
                            sx={{
                                '.MuiTypography-root': {
                                    fontWeight: 600,
                                },
                                flex: 1,
                            }}
                        >
                            <UserAvatar userId={card.owner} />
                        </Box>
                    )}
                    {selectedCard ? (
                        <Tooltip title="Close card" placement="top">
                            <IconButton
                                data-testid="minimize"
                                onClick={() => setSelectedCard(null)}
                                sx={{
                                    cursor: 'pointer',
                                    width: '60px',
                                    height: '60px',
                                    background: '#000',
                                }}
                            >
                                <MinimizeIcon
                                    style={{ width: '30px' }}
                                    color="#FFF"
                                />
                            </IconButton>
                        </Tooltip>
                    ) : !edit && permissions.includes(UserPermissions.EDIT) ? (
                        !deletedCard && (
                            <Tooltip title="Edit" placement="top">
                                <IconButton
                                    data-testid="edit-button"
                                    onClick={() => setEdit(true)}
                                    sx={{
                                        cursor: 'pointer',
                                        width: '60px',
                                        height: '60px',
                                    }}
                                >
                                    <PencilIcon style={{ width: '30px' }} />
                                </IconButton>
                            </Tooltip>
                        )
                    ) : permissions.includes(UserPermissions.EDIT) ? (
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                justifySelf: 'right',
                                zIndex: 10,
                            }}
                            onClick={() => updateCardDetails()}
                        >
                            {loading ? (
                                <CircularProgress
                                    color="inherit"
                                    size={'1rem'}
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    ) : (
                        <Box
                            sx={{
                                padding: '30px',
                            }}
                        />
                    )}
                    {card.__typename === 'Card' &&
                        !edit &&
                        card.cardToCardTypeId === '69' && (
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ justifySelf: 'right' }}
                                onClick={() => {
                                    handleClose();
                                    setTimeout(() => {
                                        handleConvert && handleConvert(card.id);
                                    }, 200);
                                }}
                            >
                                Convert
                            </Button>
                        )}
                </Box>
            </StyledSection>

            <Main
                selectedCard={selectedCard}
                edit={edit}
                cardObject={cardObject}
                setCardObject={setCardObject}
                isCard={isCard}
                selectedType={type}
                worksheetCard={worksheetCard}
            />
            {cardObject?.__typename !== 'CardSet' && (
                <AttributeSection
                    selectedCard={selectedCard}
                    cardObject={cardObject}
                    edit={edit}
                    card={card}
                    updateAttributes={updateAttributes}
                    selectedType={type}
                    worksheetCard={worksheetCard}
                />
            )}
        </Box>
    );
};

export const StyledCardDetails = styled(CardDetails)(({ theme }) => ({
    color: theme.palette.text.primary,
    flexGrow: 2,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    //backgroundColor: theme.palette.background.default,
}));

export default CardDetails;
