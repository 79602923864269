import { Box, Divider, Typography, styled, useTheme } from '@mui/material';
import Spiral from '../../assets/icons/SC-spiral.svg';
import { PlaybookPageDataResponse } from '../../API';
import {
    Category,
    getCategoryHex,
    getCategoryIcon,
} from '../../helpers/category';
import { StyledPlaybookPage } from './StyledPlaybookComponents';

import { useEffect, useState } from 'react';
import PlaybookMenu from './PlaybookMenu';
import { MoveDirection } from '../../pages/playbooks/[pid]';
import { UserPermissions } from '../../globals';
import Logo from '../profile/Logo';
interface Settings {
    [key: string]: any;
}

interface PlaybookPageProps {
    page: PlaybookPageDataResponse;
    orderPages?: (id: string, direction: MoveDirection) => void;
    permissions: UserPermissions[];
    setEditMode: (editMode: boolean) => void;
    handleDelete?: (e: React.MouseEvent<HTMLLIElement>, id: string) => void;
    first?: boolean;
    last?: boolean;
    carousel?: boolean;
    front?: boolean;
    logo: string | null;
    pptView?: boolean;
}

const backgroundColor = getCategoryHex(Category.PLAYBOOK);

const StyledPlaybookTitlePage = styled(StyledPlaybookPage)(
    ({ backgroundColor, theme }) => {
        return {
            color: theme.palette.getContrastText(
                backgroundColor || theme.palette.background.default
            ),
            justifyContent: 'center',
            padding: theme.spacing(4),
        };
    }
);

const PlaybookTitlePage = ({
    page,
    orderPages,
    permissions,
    handleDelete,
    setEditMode,
    first,
    last,
    carousel,
    front,
    logo,
    pptView,
}: PlaybookPageProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [layoutSettings, setLayoutSettings] = useState<Settings | null>(null);
    const setMenu = (e: React.BaseSyntheticEvent) => {
        e.stopPropagation();

        if (e) {
            setAnchorEl(e.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };
    const theme = useTheme();

    useEffect(() => {
        if (page.options) {
            const options = JSON.parse(page.options);

            if (options.titleconfig) {
                setLayoutSettings(JSON.parse(options.titleconfig));
            }
        }
    }, [page.options]);

    return (
        <>
            {layoutSettings ? (
                <Box
                    sx={{
                        position: 'relative',
                        pl: carousel ? 5 : 0,
                        height: '100%',
                        minHeight: '28rem',
                        background:
                            layoutSettings.color.background ?? '#414B5A',
                        boxShadow: !carousel
                            ? '0px 0px 13px rgba(0,0,0,0.40)'
                            : 'initial',
                        color: layoutSettings.color.text ?? '#FFFFFF',
                        borderRadius: 4,
                    }}
                >
                    {!carousel && front && (
                        <Box
                            sx={{
                                width: '50px',
                                height: '420px',
                                background: `url(${Spiral})`,
                                backgroundRepeat: 'repeat-y',
                                position: 'absolute',
                                left: '-10px',
                                top: '15px',
                                backgroundSize: '65%',
                            }}
                        />
                    )}

                    <Box
                        sx={{
                            minHeight: '28rem',
                            ...(carousel && {
                                height: '100%',
                            }),
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                height: carousel
                                    ? '100px'
                                    : front
                                    ? '45px'
                                    : '100px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2,
                            }}
                        >
                            {logo &&
                                !pptView &&
                                (!layoutSettings.logo ||
                                    layoutSettings.logo === 'top') && (
                                    <Logo
                                        height={
                                            carousel
                                                ? '100px'
                                                : front
                                                ? '45px'
                                                : '100px'
                                        }
                                        width={
                                            carousel
                                                ? '150px'
                                                : front
                                                ? '45px'
                                                : '100px'
                                        }
                                        thumbnail={!!front}
                                        logo={logo}
                                        pptView={pptView}
                                    />
                                )}
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                width: '100%',
                                flexDirection: 'column',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent:
                                    layoutSettings.content === 'top'
                                        ? 'flex-start'
                                        : layoutSettings.content === 'bottom'
                                        ? 'flex-end'
                                        : 'center',
                                py: !front ? '100px' : 0,
                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    fontFamily: 'Antonio,sans-serif',
                                    fontSize: carousel
                                        ? '3.75rem'
                                        : front
                                        ? '1.75rem'
                                        : '3.75rem',
                                    maxWidth: '70%',
                                    textAlign: 'center',
                                }}
                            >
                                {page.title}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    marginTop: '20px',
                                    maxWidth: '70%',
                                    textAlign: 'center',
                                    fontSize: carousel
                                        ? '1rem'
                                        : front
                                        ? '.6rem'
                                        : '1rem',
                                }}
                            >
                                {page.commentary}
                            </Typography>
                        </Box>
                        {!front && (
                            <Box
                                sx={{
                                    height: '100px',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    p: 2,
                                }}
                            >
                                {logo &&
                                    !pptView &&
                                    layoutSettings.logo === 'bottom' &&
                                    !front && (
                                        <Logo
                                            {...(!carousel && {
                                                height: '40px',
                                            })}
                                            thumbnail={!!front}
                                            logo={logo}
                                            pptView={pptView}
                                        />
                                    )}
                            </Box>
                        )}
                        {front && (
                            <Box
                                sx={{
                                    height: !carousel ? '50px' : 'initial',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    pr: carousel ? '20px' : 'inherit',
                                }}
                            >
                                {logo &&
                                    layoutSettings.logo === 'bottom' &&
                                    front && (
                                        <Logo
                                            height={
                                                carousel
                                                    ? '100px'
                                                    : front
                                                    ? '45px'
                                                    : '100px'
                                            }
                                            width={
                                                carousel
                                                    ? '150px'
                                                    : front
                                                    ? '45px'
                                                    : '100px'
                                            }
                                            thumbnail={true}
                                            logo={logo}
                                            pptView={pptView}
                                        />
                                    )}

                                {(permissions.includes(
                                    UserPermissions.DELETE
                                ) ||
                                    permissions.includes(
                                        UserPermissions.EDIT
                                    )) && (
                                    <PlaybookMenu
                                        setEditMode={setEditMode}
                                        id={page?.id ?? ''}
                                        setMenu={setMenu}
                                        anchorEl={anchorEl}
                                        setAnchorEl={setAnchorEl}
                                        orderPages={orderPages}
                                        handleDelete={handleDelete}
                                        iconColor="#FFFFFF"
                                        first={first}
                                        last={last}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <></>
            )}
        </>
    );
};

export default PlaybookTitlePage;
