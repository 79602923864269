import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    useTheme,
} from '@mui/material';
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu.svg';
import { MoveDirection } from '../../pages/playbooks/[pid]';

interface PlaybookMenuProps {
    setMenu: (e: React.BaseSyntheticEvent) => void;
    anchorEl: null | HTMLElement;
    setAnchorEl: (element: null | HTMLElement) => void;
    orderPages?: (id: string, direction: MoveDirection) => void;
    setEditMode: (editMode: boolean) => void;
    id: string;
    handleDelete?: (event: React.MouseEvent<HTMLLIElement>, id: string) => void;
    iconColor?: string;
    first?: boolean;
    last?: boolean;
}

const PlaybookMenu = ({
    id,
    setMenu,
    anchorEl,
    setAnchorEl,
    orderPages,
    handleDelete,
    setEditMode,
    iconColor,
    first,
    last,
}: PlaybookMenuProps) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                height: '50px',
                display: 'flex',
                justifyContent: 'flex-end',
            }}
        >
            <Tooltip title="Actions" placement="top">
                <IconButton
                    aria-label=""
                    id="menu-button"
                    onClick={setMenu}
                    sx={{
                        ml: 0.5,
                        width: '50px',
                        opacity: 0.5,
                        ['&.active, &:hover']: {
                            opacity: 1,
                        },
                    }}
                >
                    <MenuIcon color={iconColor ? iconColor : 'inherit'} />
                </IconButton>
            </Tooltip>

            <Menu
                id="playbook-menu"
                aria-labelledby="menu-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={(e: React.BaseSyntheticEvent) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        boxShadow: `1px 1px 1px 1px ${theme.palette.background.paper}`,
                        backgroundColor: theme.palette.background.default,
                    },
                }}
            >
                {' '}
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(null);
                        setEditMode(true);
                    }}
                >
                    Configure
                </MenuItem>
                {!first && (
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(null);
                            orderPages && orderPages(id, MoveDirection.UP);
                        }}
                    >
                        Move up
                    </MenuItem>
                )}
                {!last && (
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(null);
                            orderPages && orderPages(id, MoveDirection.DOWN);
                        }}
                    >
                        Move down
                    </MenuItem>
                )}
                <MenuItem
                    onClick={(e) => {
                        setAnchorEl(null);
                        handleDelete &&
                            handleDelete(
                                e as React.MouseEvent<HTMLLIElement>,
                                id ?? ''
                            );
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default PlaybookMenu;
