import { useEffect, useRef, useState } from 'react';
import {
    DataGridPremium,
    GridColDef,
    GridEventListener,
    GridOverlay,
    gridClasses,
    useGridApiContext,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license';

import { alpha, SxProps, Theme, useTheme } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import useViewportHeight from '../../hooks/useAdjustedHeight';
import {
    CardComponentType,
    CardPage,
    CardSetComponentData,
    PageIdentifier,
} from '../cards/cardTypes';
import { CardToDelete } from '../../pages/cards';

LicenseInfo.setLicenseKey(
    '38a9cb0961e92896a98bee7ad029cadcTz0xMDYwMDQsRT0xNzY4NTIxNTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
);

const CustomNoRowsOverlay = () => (
    <GridOverlay>
        <Box sx={{ textAlign: 'center', p: '20px', fontSize: '16px' }}>
            No data to display
        </Box>
    </GridOverlay>
);

interface TableProps {
    columns: GridColDef[];
    items: any[];
    onRowClick?: (cardId: string, cardPage: CardPage) => void;
    cardComponentType?: string;
    tableStyle?: SxProps<Theme>;
    rowHeight?: number;
    setSelectedRows?: React.Dispatch<React.SetStateAction<string[] | number[]>>;
    selectMultiple?: boolean;
    userId?: string | undefined;
    multiSelectedCards?: CardToDelete[] | [] | undefined;
    pageIdentifier?: PageIdentifier | undefined;
    clickedItems?: CardSetComponentData[];
    onHover?: (id: string | null) => void;
}

const Table = ({
    items,
    columns,
    onRowClick,
    cardComponentType,
    tableStyle,
    rowHeight,
    setSelectedRows,
    selectMultiple,
    userId,
    multiSelectedCards,
    pageIdentifier,
    clickedItems,
    onHover,
}: TableProps) => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const elementRef = useRef<HTMLDivElement>(null);
    const tableWrapperHeight = useViewportHeight(elementRef);
    const displayColumn = cardComponentType !== CardComponentType.WORKBOOK;

    const apiRef = useGridApiRef();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (items) {
                setIsLoading(false);
            }
        }, 200);

        return () => clearTimeout(timer);
    }, [items]);
    const handleRowClick = (id: string, owner?: string) => {
        if (
            pageIdentifier === PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD ||
            pageIdentifier === PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD_SET
        ) {
            onRowClick && onRowClick(id, CardPage.DETAILS);
        } else {
            onRowClick && owner === userId && onRowClick(id, CardPage.DETAILS);
        }
    };

    return (
        <Box ref={elementRef}>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <DataGridPremium
                    slotProps={{
                        row: {
                            onMouseEnter: (
                                event: React.MouseEvent<HTMLElement>
                            ) => {
                                const rowElement =
                                    event.currentTarget.closest(
                                        '.MuiDataGrid-row'
                                    );
                                if (rowElement) {
                                    const rowId =
                                        rowElement.getAttribute('data-id');

                                    if (rowId && items) {
                                        const rowData = items.find(
                                            (item) => item.id === rowId
                                        );
                                        onHover && onHover(rowId);
                                    }
                                }
                            },
                            onMouseLeave: (
                                event: React.MouseEvent<HTMLElement>
                            ) => {
                                onHover && onHover(null);
                            },
                        },
                    }}
                    {...(rowHeight && { rowHeight })}
                    columnVisibilityModel={{
                        cardTypeName: displayColumn,
                        cardCategoryName: displayColumn,
                    }}
                    columns={columns}
                    density="compact"
                    getRowClassName={(params) => {
                        const isSelected = multiSelectedCards?.some(
                            (card) => card.id === params.row.id
                        );

                        const isClickedCardSet = clickedItems?.some(
                            (cardSet) => cardSet.id === params.row.id
                        );

                        const className = [
                            params.row.status === 'Deleted' && 'deleted',
                            (isSelected || isClickedCardSet) && 'selected',
                            params.row.owner === userId && 'owner',
                            params.indexRelativeToCurrentPage % 2 === 0
                                ? 'even'
                                : 'odd',
                        ]
                            .filter(Boolean)
                            .join(' ');

                        return className;
                    }}
                    hideFooter
                    onRowClick={(params) =>
                        handleRowClick(params.row.id, params.row.owner)
                    }
                    rows={items || []}
                    checkboxSelection={selectMultiple}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectedRows &&
                            setSelectedRows(
                                newSelectionModel.map((id) => id.toString())
                            );
                    }}
                    isRowSelectable={(params) => {
                        const isSelectable = params.row.owner === userId;
                        return isSelectable;
                    }}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnMenu={!!tableStyle}
                    sx={
                        tableStyle
                            ? tableStyle
                            : {
                                  minHeight: '100vh',
                                  '.MuiDataGrid-columnHeaders': {
                                      backgroundColor: 'background.paper',
                                  },
                                  '.MuiDataGrid-container--top [role=row]': {
                                      background: 'initial',
                                  },
                                  '.MuiDataGrid-columnHeaderTitle': {
                                      fontWeight: 800,
                                  },
                                  '& .MuiCheckbox-root': {
                                      color: 'grey',
                                  },
                                  '& .MuiCheckbox-root.Mui-checked': {
                                      color: theme.palette.primary.main,
                                  },
                                  [`.${gridClasses.row}.even:not(.selected)`]: {
                                      backgroundColor: (theme) =>
                                          alpha(
                                              theme.palette.background.paper,
                                              0.3
                                          ),
                                  },
                                  [`.${gridClasses.row}.deleted`]: {
                                      color: 'grey',
                                  },
                                  [`.${gridClasses.row}.deleted`]: {
                                      color: 'grey',
                                  },
                                  '& .selected': {
                                      color: '#000',
                                      backgroundColor: 'grey.200',
                                      '&:hover': {
                                          backgroundColor: 'grey.200',
                                      },
                                  },
                                  '.MuiDataGrid-cell:focus': {
                                      outline: 'none',
                                  },
                                  '& .MuiDataGrid-row': {
                                      cursor: 'default',
                                  },
                                  '& .owner': {
                                      cursor:
                                          pageIdentifier ===
                                          PageIdentifier.CARDS
                                              ? 'pointer'
                                              : 'default',
                                  },
                              }
                    }
                />
            )}
        </Box>
    );
};

export default Table;
