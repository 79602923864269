/* tslint:disable */
/* eslint-disable */

// any queries defined in here will need to be manually updated if the underlying schema changes
// run: amplify codegen
// to update API.ts

export const cardsCardSetsByCardSetIdWithScores = /* GraphQL */ `
    query CardsCardSetsByCardSetIdWithScores(
        $cardSetId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelCardsCardSetsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        cardsCardSetsByCardSetId(
            cardSetId: $cardSetId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                cardId
                cardSetId
                card {
                    id
                    name
                    toCardCategory {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                    }
                    description
                    attributes {
                        attributeDefinitionID
                        value
                    }
                    toScoreData {
                        items {
                            cardId
                            scoreDefinitionId
                            data {
                                value
                                date
                                comment
                                commentDate
                                commentAuthor
                            }
                            organisation
                            createdAt
                            updatedAt
                        }
                        nextToken
                    }
                    toCardType {
                        id
                        name
                        cardCategoryID
                        createdAt
                        updatedAt
                        owner
                        scoreDefinitions {
                            id
                            name
                            shortName
                            description
                            scoreType
                            minimumValue
                            maximumValue
                        }
                        defaultScoreDefinitionId
                    }
                    toCardSets {
                        nextToken
                    }
                    organisation
                    createdAt
                    updatedAt
                    cardTypeToCardsId
                    cardToCardCategoryId
                    cardToCardTypeId
                    owner
                    createdBy
                    updatedBy
                    editors
                    orgEdit
                }
                cardSet {
                    id
                    name
                    description
                    toCards {
                        nextToken
                    }
                    toCardCategory {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                    }
                    toCardType {
                        id
                        name
                        cardCategoryID
                        createdAt
                        updatedAt
                        owner
                        defaultScoreDefinitionId
                    }
                    organisation
                    createdAt
                    updatedAt
                    updatedBy
                    cardSetToCardCategoryId
                    cardSetToCardTypeId
                    owner
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const cardsOrderedByUpdatedAtWithScoreData = /* GraphQL */ `
    query CardsOrderedByUpdatedAtWithScoreData(
        $organisation: ID!
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCardFilterInput
        $limit: Int
        $nextToken: String
    ) {
        cardsOrderedByUpdatedAt(
            organisation: $organisation
            updatedAt: $updatedAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                organisation
                id
                owner
                createdBy
                updatedBy
                editors
                orgEdit
                name
                toCardCategory {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    owner
                }
                description
                attributes {
                    attributeDefinitionID
                    value
                }
                toScoreData {
                    items {
                        cardId
                        scoreDefinitionId
                        data {
                            value
                            date
                            comment
                            commentDate
                            commentAuthor
                        }
                        organisation
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                toCardType {
                    id
                    name
                    scoreDefinitions {
                        id
                        name
                        shortName
                        description
                        scoreType
                        minimumValue
                        maximumValue
                    }
                    defaultScoreDefinitionId
                }
                createdAt
                updatedAt
                cardTypeToCardsId
                cardToCardCategoryId
                cardToCardTypeId
            }
            nextToken
        }
    }
`;

export const cardsOrderedByCreatedAtWithScoreData = /* GraphQL */ `
    query CardsOrderedByCreatedAtWithScoreData(
        $organisation: ID!
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCardFilterInput
        $limit: Int
        $nextToken: String
    ) {
        cardsOrderedByCreatedAt(
            organisation: $organisation
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                organisation
                id
                owner
                createdBy
                updatedBy
                editors
                orgEdit
                name
                toCardCategory {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    owner
                }
                description
                attributes {
                    attributeDefinitionID
                    value
                }
                toScoreData {
                    items {
                        cardId
                        scoreDefinitionId
                        data {
                            value
                            date
                            comment
                            commentDate
                            commentAuthor
                        }
                        organisation
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                toCardType {
                    id
                    name
                    scoreDefinitions {
                        id
                        name
                        shortName
                        description
                        scoreType
                        minimumValue
                        maximumValue
                    }
                    defaultScoreDefinitionId
                }
                createdAt
                updatedAt
                cardTypeToCardsId
                cardToCardCategoryId
                cardToCardTypeId
            }
            nextToken
        }
    }
`;

export const cardsOrderedByNameWithScoreData = /* GraphQL */ `
    query CardsOrderedByNameWithScoreData(
        $organisation: ID!
        $capitalName: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCardFilterInput
        $limit: Int
        $nextToken: String
    ) {
        cardsOrderedByName(
            organisation: $organisation
            capitalName: $capitalName
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                organisation
                id
                owner
                createdBy
                updatedBy
                editors
                orgEdit
                name
                toCardCategory {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    owner
                }
                description
                attributes {
                    attributeDefinitionID
                    value
                }
                toScoreData {
                    items {
                        cardId
                        scoreDefinitionId
                        data {
                            value
                            date
                            comment
                            commentDate
                            commentAuthor
                        }
                        organisation
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                toCardType {
                    id
                    name
                    scoreDefinitions {
                        id
                        name
                        shortName
                        description
                        scoreType
                        minimumValue
                        maximumValue
                    }
                    defaultScoreDefinitionId
                }
                createdAt
                updatedAt
                cardTypeToCardsId
                cardToCardCategoryId
                cardToCardTypeId
            }
            nextToken
        }
    }
`;

export const cardsOrderedByNameOnlyIdAndName = /* GraphQL */ `
    query CardsOrderedByNameOnlyIdAndName(
        $organisation: ID!
        $capitalName: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCardFilterInput
        $limit: Int
        $nextToken: String
    ) {
        cardsOrderedByName(
            organisation: $organisation
            capitalName: $capitalName
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
            }
            nextToken
        }
    }
`;

export const getCardDataWithScoreData = /* GraphQL */ `
    query GetCardDataWithScoreData(
        $sort: CardSort
        $limit: Int
        $nextToken: String
        $filter: CardFilter
        $context: ID
    ) {
        getCardData(
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            filter: $filter
            context: $context
        ) {
            items {
                organisation
                id
                owner
                createdBy
                updatedBy
                editors
                orgEdit
                name
                toCardCategory {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    owner
                }
                description
                attributes {
                    attributeDefinitionID
                    value
                }
                toScoreData {
                    items {
                        cardId
                        scoreDefinitionId
                        data {
                            value
                            date
                            comment
                            commentDate
                            commentAuthor
                        }
                        organisation
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                toCardType {
                    id
                    name
                    scoreDefinitions {
                        id
                        name
                        shortName
                        description
                        scoreType
                        minimumValue
                        maximumValue
                        target
                    }
                    defaultScoreDefinitionId
                }
                createdAt
                updatedAt
                cardTypeToCardsId
                cardToCardCategoryId
                cardToCardTypeId
                createdFromId
                createdFromIds
                __typename
            }
            nextToken
        }
    }
`;

export const getCardDataWithScoreDataBasic = /* GraphQL */ `
    query GetCardDataWithScoreDataBasic(
        $organisation: ID!
        $capitalName: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCardFilterInput
        $limit: Int
        $nextToken: String
    ) {
        cardsOrderedByName(
            organisation: $organisation
            capitalName: $capitalName
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                owner
                name
                description
                toScoreData {
                    items {
                        cardId
                        scoreDefinitionId
                        data {
                            value
                            date
                            comment
                            commentDate
                            commentAuthor
                        }
                        organisation
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                cardToCardCategoryId
                cardToCardTypeId
                __typename
            }
            nextToken
        }
    }
`;

export const cardsCardSetsToDeleteByCardId = /* GraphQL */ `
    query CardsCardSetsToDeleteByCardId(
        $cardId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelCardsCardSetsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        cardsCardSetsByCardId(
            cardId: $cardId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                organisation
                id
                cardId
                cardSetId
                cardSet {
                    organisation
                    id
                    name
                    createdAt
                    updatedAt
                    updatedBy
                    owner
                    type
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const getCardSetWithCards = /* GraphQL */ `
    query GetCardSetWithCards($organisation: ID!, $id: ID!) {
        getCardSet(organisation: $organisation, id: $id) {
            organisation
            id
            type
            name
            description
            hiddenWorksheets
            owner
            createdBy
            editors
            orgEdit
            toCards {
                items {
                    organisation
                    id
                    cardId
                    cardSetId
                    card {
                        id
                        name
                        toCardCategory {
                            id
                            name
                            description
                            createdAt
                            updatedAt
                            owner
                        }
                        description
                        attributes {
                            attributeDefinitionID
                            value
                        }
                        toScoreData {
                            items {
                                cardId
                                scoreDefinitionId
                                data {
                                    value
                                    date
                                    comment
                                    commentDate
                                    commentAuthor
                                }
                                organisation
                                createdAt
                                updatedAt
                            }
                            nextToken
                        }
                        toCardType {
                            id
                            name
                            cardCategoryID
                            createdAt
                            updatedAt
                            owner
                            scoreDefinitions {
                                id
                                name
                                shortName
                                description
                                scoreType
                                minimumValue
                                maximumValue
                            }
                            defaultScoreDefinitionId
                        }
                        toCardSets {
                            nextToken
                        }
                        organisation
                        createdAt
                        updatedAt
                        cardTypeToCardsId
                        cardToCardCategoryId
                        cardToCardTypeId
                        owner
                        createdBy
                        updatedBy
                        editors
                        orgEdit
                    }
                    cardSet {
                        organisation
                        id
                        type
                        name
                        description
                        owner
                        createdBy
                        editors
                        orgEdit
                        orgEdit
                        createdAt
                        updatedAt
                        cardSetToCardCategoryId
                        cardSetToCardTypeId
                        __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                }
                nextToken
                __typename
            }
            toCardCategory {
                id
                name
                description
                toCardTypes {
                    items {
                        id
                        name
                        cardCategoryID
                        createdAt
                        updatedAt
                        owner
                        defaultScoreDefinitionId
                        __typename
                    }
                    nextToken
                    __typename
                }
                createdAt
                updatedAt
                owner
                __typename
            }
            toCardType {
                id
                name
                cardCategoryID
                toCards {
                    items {
                        organisation
                        id
                        owner
                        createdBy
                        editors
                        orgEdit
                        name
                        description
                        createdAt
                        updatedAt
                        cardTypeToCardsId
                        cardToCardCategoryId
                        cardToCardTypeId
                        __typename
                    }
                    nextToken
                    __typename
                }
                attributeDefinitions {
                    id
                    name
                    attributeType
                    configuration
                    __typename
                }
                scoreDefinitions {
                    id
                    name
                    shortName
                    description
                    scoreType
                    minimumValue
                    maximumValue
                    __typename
                }
                defaultScoreDefinitionId
                createdAt
                updatedAt
                owner
                __typename
            }
            createdAt
            updatedAt
            updatedBy
            cardSetToCardCategoryId
            cardSetToCardTypeId
            __typename
            counts {
                comments
                worksheets
                cards
                __typename
            }
            processProgress {
                stageId
                progress
                __typename
            }
        }
    }
`;

export const getPlaybookDataWithScore = /* GraphQL */ `
    query GetPlaybookDataWithScore($playbookId: ID) {
        getPlaybookData(playbookId: $playbookId) {
            owner
            editors
            orgEdit
            organisation
            id
            name
            description
            workbooks
            pages {
                id
                playbookPageType
                title
                commentary
                cardTypeId
                worksheetId
                cardSets
                workbooks
                worksheets
                cards
                allCards
                include
                options
                ordinal
                createdAt
                updatedAt
                __typename
            }
            cards {
                organisation
                id
                owner
                createdBy
                editors
                orgEdit
                name
                capitalName
                description
                attributes {
                    attributeDefinitionID
                    value
                    __typename
                }
                toAttributes {
                    items {
                        cardId
                        attributeDefinitionId
                        organisation
                        value
                        refToCardId
                        refToCardSetId
                        createdAt
                        updatedAt
                        __typename
                    }
                    nextToken
                    __typename
                }
                toScoreData {
                    items {
                        cardId
                        scoreDefinitionId
                        organisation
                        createdAt
                        updatedAt
                        data {
                            value
                            date
                            comment
                            commentDate
                            commentAuthor
                        }
                        __typename
                    }
                    nextToken
                    __typename
                }
                createdAt
                updatedAt
                cardTypeToCardsId
                cardToCardCategoryId
                cardToCardTypeId
                __typename
            }
            __typename
        }
    }
`;

export const relationshipsBySourceIdWithScoreData = /* GraphQL */ `
    query RelationshipsBySourceIdWithScoreData(
        $sourceId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelRelationshipFilterInput
        $limit: Int
        $nextToken: String
    ) {
        relationshipsBySourceId(
            sourceId: $sourceId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                organisation
                id
                sourceId
                sourceType
                sourceCardId
                toSourceCard {
                    organisation
                    id
                    owner
                    createdBy
                    editors
                    orgEdit
                    name
                    capitalName
                    toCardCategory {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                        __typename
                    }
                    description
                    attributes {
                        attributeDefinitionID
                        value
                        __typename
                    }
                    toAttributes {
                        nextToken
                        __typename
                    }
                    toScoreData {
                        nextToken
                        __typename
                    }
                    toCardType {
                        id
                        name
                        cardCategoryID
                        defaultScoreDefinitionId
                        createdAt
                        updatedAt
                        owner
                        __typename
                    }
                    toCardSets {
                        nextToken
                        __typename
                    }
                    createdAt
                    updatedAt
                    updatedBy
                    deleteAfter
                    cardTypeToCardsId
                    cardToCardCategoryId
                    cardToCardTypeId
                    __typename
                }
                sourceCardSetId
                toSourceCardSet {
                    organisation
                    id
                    type
                    name
                    capitalName
                    description
                    owner
                    createdBy
                    editors
                    orgEdit
                    toCards {
                        nextToken
                        __typename
                    }
                    toCardCategory {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                        __typename
                    }
                    toCardType {
                        id
                        name
                        cardCategoryID
                        defaultScoreDefinitionId
                        createdAt
                        updatedAt
                        owner
                        __typename
                    }
                    createdAt
                    updatedAt
                    updatedBy
                    hiddenWorksheets
                    copiedFrom
                    cardSetToCardCategoryId
                    cardSetToCardTypeId
                    __typename
                }
                targetId
                targetType
                targetCardId
                toTargetCard {
                    organisation
                    id
                    owner
                    createdBy
                    editors
                    orgEdit
                    name
                    capitalName
                    toCardCategory {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                        __typename
                    }
                    description
                    attributes {
                        attributeDefinitionID
                        value
                        __typename
                    }
                    toAttributes {
                        nextToken
                        __typename
                    }
                    toScoreData {
                        items {
                            cardId
                            scoreDefinitionId
                            data {
                                value
                                date
                                comment
                                commentDate
                                commentAuthor
                            }
                            organisation
                            createdAt
                            updatedAt
                        }
                        nextToken
                        __typename
                    }
                    toCardType {
                        id
                        name
                        scoreDefinitions {
                            id
                            name
                            shortName
                            description
                            scoreType
                            minimumValue
                            maximumValue
                            target
                        }
                        defaultScoreDefinitionId
                    }
                    toCardSets {
                        nextToken
                        __typename
                    }
                    createdAt
                    updatedAt
                    updatedBy
                    deleteAfter
                    cardTypeToCardsId
                    cardToCardCategoryId
                    cardToCardTypeId
                    __typename
                }
                targetCardSetId
                toTargetCardSet {
                    organisation
                    id
                    type
                    name
                    capitalName
                    description
                    owner
                    createdBy
                    editors
                    orgEdit
                    toCards {
                        nextToken
                        __typename
                    }
                    toCardCategory {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                        __typename
                    }
                    toCardType {
                        id
                        name
                        cardCategoryID
                        defaultScoreDefinitionId
                        createdAt
                        updatedAt
                        owner
                        __typename
                    }
                    createdAt
                    updatedAt
                    updatedBy
                    hiddenWorksheets
                    copiedFrom
                    cardSetToCardCategoryId
                    cardSetToCardTypeId
                    __typename
                }
                createdAt
                createdBy
                updatedAt
                updatedBy
                __typename
            }
            nextToken
            __typename
        }
    }
`;
export const getWorkbooksBasic = /* GraphQL */ `
    query GetWorkbooksBasic(
        $sort: CardSort
        $limit: Int
        $nextToken: String
        $filter: WorkbookFilter
    ) {
        getWorkbooks(
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            filter: $filter
        ) {
            items {
                organisation
                id
                type
                name
                capitalName
                description
                owner
                createdBy
                editors
                orgEdit
                createdAt
                updatedAt
                updatedBy
                hiddenWorksheets
                copiedFrom
                counts {
                    comments
                    worksheets
                    cards
                    __typename
                }
                cardSetToCardCategoryId
                cardSetToCardTypeId
                __typename
            }
            nextToken
            __typename
        }
    }
`;
export const getCardSetsBasic = /* GraphQL */ `
    query GetCardSetsBasic(
        $sort: CardSort
        $limit: Int
        $nextToken: String
        $filter: CardSetFilter
    ) {
        getCardSets(
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            filter: $filter
        ) {
            items {
                organisation
                id
                type
                name
                capitalName
                description
                owner
                createdBy
                editors
                orgEdit
                toCardCategory {
                    id
                    name
                    description
                    toCardTypes {
                        nextToken
                        __typename
                    }
                    createdAt
                    updatedAt
                    owner
                    __typename
                }
                toCardType {
                    id
                    name
                    cardCategoryID
                    toCards {
                        nextToken
                        __typename
                    }
                    attributeDefinitions {
                        id
                        name
                        attributeType
                        configuration
                        __typename
                    }
                    scoreDefinitions {
                        id
                        name
                        shortName
                        description
                        scoreType
                        minimumValue
                        maximumValue
                        target
                        __typename
                    }
                    defaultScoreDefinitionId
                    createdAt
                    updatedAt
                    owner
                    __typename
                }
                createdAt
                updatedAt
                updatedBy
                hiddenWorksheets
                copiedFrom
                counts {
                    comments
                    worksheets
                    cards
                    __typename
                }
                cardSetToCardCategoryId
                cardSetToCardTypeId
                __typename
            }
            nextToken
            __typename
        }
    }
`;
