import { useContext, useRef, useState } from 'react';
import {
    Box,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateTime } from 'luxon';
import isUrl from 'is-url';
import DOMPurify from 'dompurify';

import {
    Attribute,
    AttributeDefinition,
    AttributeType,
    CardCategory,
    CardType,
    PlaybookPageType,
    ScoreDatum,
    ScoreType,
} from '../../../API';
import { Category, getCategoryHexText } from '../../../helpers/category';
import { getCategoryIcon } from '../../../helpers/category';
import InputFromAttribute from '../input-fields/InputFromAttribute';
import TextField from '../input-fields/TextField';
import { getFormSchemaPropType } from '../utils';
import { CardScore, NewCard, NewWorksheet, SelectedWorksheet } from './types';
import { useEffect } from 'react';
import { AppContext } from '../../contexts';
import { PageIdentifier } from '../../cards/cardTypes';
import ChartForm from '../../charts/EditableChart/ChartForm';
import { RadarOption } from '../input-fields/ChartSettings';
import AiIcon from '@mui/icons-material/AutoAwesome';
import { AiRequest } from '../../../actions/AIRequest';
import TypingDots from './TypingDots';

export interface MockEvent {
    target: {
        value: string | unknown;
        name?: string | unknown;
    };
}

export type ChangeEvent = MockEvent | null | DateTime;

interface ThirdProps {
    newCard: NewCard;
    cardCategories?: CardCategory[];
    cardTypes?: CardType[];
    setNewCard: (newCard?: NewCard) => void;
    urlError: boolean;
    setUrlError: (urlError: boolean) => void;
    pageIdentifier?: PageIdentifier;
    selectedWorksheets: SelectedWorksheet[] | null;
    newWorksheet?: NewWorksheet;
    setNewWorksheet?: React.Dispatch<React.SetStateAction<NewWorksheet>>;
    isSwotModal?: boolean;
    setScore?: (score: number | null) => void;
    score?: number | null;
    cardTypeFromDrawer?: string | undefined;
    pageOptions?: any;
    scores?: {
        x: number;
        y: number;
    };
    setScores?: React.Dispatch<
        React.SetStateAction<{
            x: number;
            y: number;
        }>
    >;
    cardScores: CardScore[];
    setCardScores?: React.Dispatch<React.SetStateAction<CardScore[]>>;
    radarOptions?: RadarOption[] | null | undefined;
    selectedAttributes?: any;
}

const AttributesColumn = ({
    newCard,
    setNewCard,
    urlError,
    setUrlError,
    pageIdentifier,
    selectedWorksheets,
    newWorksheet,
    setNewWorksheet,
    isSwotModal,
    setScore,
    score,
    cardTypeFromDrawer,
    pageOptions,
    scores,
    setScores,
    radarOptions,
    cardScores,
    setCardScores,
    selectedAttributes,
}: ThirdProps) => {
    const theme = useTheme();
    const [typing, setTyping] = useState(false);
    const [aiError, setAiError] = useState(false);
    const { cardTypeObject, organisationProfile } = useContext(AppContext);
    const descriptionRef = useRef<HTMLInputElement>(null);
    const aiKeyExists = !!organisationProfile?.aiKey;
    const cardType = cardTypeObject[newCard.cardToCardTypeId]?.name;
    const scoreDefinitions =
        cardTypeObject[newCard.cardToCardTypeId]?.scoreDefinitions;
    const xScore = scoreDefinitions?.find(
        (item) => item.name === pageOptions?.chartData?.xAxis
    );
    const yScore = scoreDefinitions?.find(
        (item) => item.name === pageOptions?.chartData?.yAxis
    );

    const scoreTypes = {
        x: {
            value: xScore?.scoreType,
            min: xScore?.minimumValue || undefined,
            max: xScore?.maximumValue || undefined,
        },
        y: {
            value: yScore?.scoreType,
            min: yScore?.minimumValue || undefined,
            max: yScore?.maximumValue || undefined,
        },
    };
    const Avatar = ({ categoryId }: { categoryId: string }) => {
        const AvatarComponent = getCategoryIcon(categoryId as Category);

        return <AvatarComponent color={getCategoryHexText(categoryId)} />;
    };

    const updateAttributes = (event: ChangeEvent, name: string) => {
        if (!event) return;

        const _card = { ...newCard };

        _card.attributes = _card.attributes ?? [];
        const index = _card.attributes.findIndex(
            (attribute: Attribute | null) =>
                attribute?.attributeDefinitionID === name
        );

        if (index === -1) {
            _card.attributes.push({
                attributeDefinitionID: name,
                value:
                    event instanceof DateTime
                        ? event.toLocaleString()
                        : event.target.name === 'Cards'
                        ? JSON.stringify(event.target.value)
                        : sanitizeInput(event.target.value as string),
            } as Attribute);
        } else {
            _card.attributes[index] = {
                attributeDefinitionID: name,
                value:
                    event instanceof DateTime
                        ? event.toLocaleString()
                        : event.target.name === 'Cards'
                        ? JSON.stringify(event.target.value)
                        : sanitizeInput(event.target.value as string),
            } as Attribute;
        }

        setNewCard(_card);
    };

    const sanitizeInput = (inputValue: string) => {
        return DOMPurify.sanitize(inputValue);
    };

    const getSelectValue = (id: string): string => {
        if (selectedAttributes) {
            const attr = selectedAttributes.find(
                (item: any) => item.attributeDefinitionID === id
            );
            return attr?.value?.replace(/^"(.*)"$/, '$1');
        }
        if (!newCard.attributes || !newCard.attributes.length) return '';

        const index = newCard.attributes?.findIndex(
            (attribute: Attribute | null) =>
                attribute?.attributeDefinitionID === id
        );
        if (index === -1) return '';

        return newCard.attributes[index]?.value ?? '';
    };
    useEffect(() => {
        //Set a default value for scores
        const scores =
            cardTypeObject[newCard.cardToCardTypeId]?.scoreDefinitions;

        scores?.length && setNewCard(newCard);
    }, [newCard]);
    const [newScoreDatum, setNewScoreDatum] = useState<ScoreDatum>({
        __typename: 'ScoreDatum',
        date: '',
        value: 0,
    });
    const disableDate = (date: DateTime): boolean => {
        return false;
    };

    const getAiSuggestion = async () => {
        setNewCard({
            ...newCard,
            name: '',
        });
        setTyping(true);
        await AiRequest(
            `Suggest a short title no longer than 
             60 characters based on the description: ${newCard.description}.The description describe a ${cardType} in business strategy formulation.`,
            'asst_YOefY5cO6lBgDw37XyFJnQGf'
        )
            .then((response) => {
                let contentObj;
                try {
                    contentObj = JSON.parse(response.content || '');
                } catch (e) {
                    setTyping(false);
                    setAiError(true);
                    console.error('Error parsing AI response content:', e);
                    return;
                }
                setNewCard({
                    ...newCard,
                    name: contentObj.name || '',
                });
                setTyping(false);
                setAiError(false);
            })
            .catch((error) => {
                console.error('Error fetching AI suggestion:', error);
                setTyping(false);
                setAiError(true);
            });
    };

    useEffect(() => {
        if (descriptionRef.current && aiKeyExists) {
            descriptionRef.current.focus();
        }
    }, []);

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
            }}
        >
            {!isSwotModal && !cardTypeFromDrawer && (
                <Box
                    sx={{
                        background:
                            theme.palette.mode === 'dark'
                                ? theme.palette.background.default
                                : '#efefef',
                        py: 1,
                        px: 3,
                        borderTop: 'solid 1px #ccc',
                        borderBottom: 'solid 1px #ccc',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                    >
                        {pageIdentifier === PageIdentifier.WORKSHEET
                            ? 'Page details'
                            : 'Card details'}
                    </Typography>
                </Box>
            )}
            {((!cardTypeFromDrawer &&
                selectedWorksheets &&
                setNewWorksheet &&
                newWorksheet) ||
                (newWorksheet &&
                    newWorksheet?.playbookPageType ===
                        PlaybookPageType.Title)) &&
                pageIdentifier === PageIdentifier.WORKSHEET && (
                    <Box
                        sx={{
                            p: 3,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'auto',
                        }}
                    >
                        <TextField
                            onChange={(event: any) =>
                                setNewWorksheet &&
                                setNewWorksheet({
                                    ...newWorksheet,
                                    title: event.target.value,
                                })
                            }
                            required={true}
                            label="Title"
                            name="title"
                            type={AttributeType.Text}
                            value={newWorksheet?.title}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                maxLength: 256,
                            }}
                        />
                        <TextField
                            onChange={(event: any) =>
                                setNewWorksheet &&
                                setNewWorksheet({
                                    ...newWorksheet,
                                    commentary: event.target.value,
                                })
                            }
                            label="Commentary"
                            name="commentary"
                            type={AttributeType.Text}
                            rows={5}
                            multiline
                            value={newWorksheet.commentary}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                maxLength: 20000,
                            }}
                        />
                    </Box>
                )}
            {newCard.cardToCardTypeId && (
                <Box
                    sx={{
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                        position: 'relative',
                    }}
                    data-automation-id="card-attributes"
                >
                    <TextField
                        onChange={(event: any) =>
                            setNewCard({
                                ...newCard,
                                name: event.target.value,
                            })
                        }
                        type={AttributeType.Text}
                        name="name"
                        required={true}
                        label="Name"
                        value={newCard?.name}
                        size="small"
                        InputLabelProps={{
                            shrink: false,
                        }}
                        inputProps={{
                            maxLength: 256,
                        }}
                        description={newCard.description}
                        getAiSuggestion={getAiSuggestion}
                        typing={typing}
                        aiKeyExists={aiKeyExists}
                    />
                    {aiError && !typing && (
                        <Typography
                            variant="body2"
                            sx={{
                                color: theme.palette.error.main,
                                mt: -2.2,
                                mb: 1,
                                ml: 1.5,
                                fontSize: '12px',
                            }}
                        >
                            There was an error generating a name. Please try
                            again.
                        </Typography>
                    )}
                    <TextField
                        inputRef={descriptionRef}
                        onChange={(event: any) =>
                            setNewCard({
                                ...newCard,
                                description: event.target.value,
                            })
                        }
                        type={AttributeType.Text}
                        name="description"
                        label="Description"
                        value={newCard.description ?? ''}
                        size="small"
                        rows={3}
                        multiline
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            maxLength: 512,
                        }}
                    />
                    {cardTypeObject[
                        newCard.cardToCardTypeId
                    ].attributeDefinitions?.map((attribute, i: number) => {
                        return (
                            attribute && (
                                <Box
                                    key={attribute.id}
                                    sx={{
                                        '.MuiFormControl-root': {
                                            width: '100%',
                                        },
                                    }}
                                >
                                    <Input
                                        type="hidden"
                                        defaultValue={attribute?.id}
                                        disableUnderline={true}
                                    />
                                    {
                                        <InputFromAttribute
                                            {...getFormSchemaPropType(
                                                attribute as AttributeDefinition
                                            )}
                                            {...(attribute.attributeType !==
                                                AttributeType.MultiSelect && {
                                                onChange: (
                                                    event: ChangeEvent
                                                ) => {
                                                    updateAttributes(
                                                        event,
                                                        attribute.id
                                                    );
                                                },
                                            })}
                                            {...(attribute.attributeType ===
                                                AttributeType.SingleSelect && {
                                                selectValue: getSelectValue(
                                                    attribute.id
                                                ),
                                            })}
                                            {...(attribute.attributeType ===
                                                AttributeType.SingleSelect && {
                                                selectValue: getSelectValue(
                                                    attribute.id
                                                ),
                                            })}
                                            {...((attribute.attributeType ===
                                                AttributeType.Text ||
                                                attribute.attributeType ===
                                                    AttributeType.MultilineText) && {
                                                selectValue: getSelectValue(
                                                    attribute.id
                                                ),
                                            })}
                                            {...(attribute.attributeType ===
                                                AttributeType.Card && {
                                                selectValue: getSelectValue(
                                                    attribute.id
                                                ),
                                            })}
                                            {...(attribute.attributeType ===
                                                AttributeType.User && {
                                                selectValue: getSelectValue(
                                                    attribute.id
                                                ),
                                            })}
                                            {...(attribute.attributeType ===
                                                AttributeType.Cards && {
                                                selectValue: getSelectValue(
                                                    attribute.id
                                                ),
                                            })}
                                            {...(attribute.attributeType ===
                                                AttributeType.CardSet && {
                                                selectValue: getSelectValue(
                                                    attribute.id
                                                ),
                                            })}
                                            {...(attribute.attributeType ===
                                                AttributeType.URL && {
                                                selectValue: getSelectValue(
                                                    attribute.id
                                                ),
                                                onChange: (
                                                    event: ChangeEvent
                                                ) => {
                                                    if (
                                                        event &&
                                                        'target' in event
                                                    ) {
                                                        const urlValue = event
                                                            .target
                                                            .value as string;
                                                        if (
                                                            isUrl(urlValue) ||
                                                            !urlValue
                                                        ) {
                                                            updateAttributes(
                                                                event,
                                                                attribute.id
                                                            );
                                                            setUrlError(false);
                                                        } else {
                                                            setUrlError(true);
                                                        }
                                                    }
                                                },
                                                error: urlError,
                                                helperText: urlError
                                                    ? 'Please enter a valid URL.'
                                                    : '',
                                            })}
                                            name={`attributes.${i}.value`}
                                        />
                                    }
                                </Box>
                            )
                        );
                    })}
                    {cardTypeFromDrawer &&
                        scores &&
                        pageOptions &&
                        !radarOptions && (
                            <Box>
                                <Box>
                                    <Input
                                        type="hidden"
                                        defaultValue={''}
                                        disableUnderline={true}
                                    />
                                    <InputLabel
                                        shrink
                                        htmlFor="styled-input"
                                        sx={{
                                            background: 'initial',
                                            color: theme.palette.text.primary,
                                            fontSize: '1.6em',
                                            fontFamily: 'Antonio,sans-serif',
                                            '&.Mui-focused': {
                                                color: theme.palette.text
                                                    .primary,
                                            },
                                        }}
                                    >
                                        Scores
                                    </InputLabel>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            sx={{ mb: '-40px' }}
                                        >
                                            {pageOptions?.chartData?.xAxis}
                                            {` (${scoreTypes.x.min ?? 0}
                                                            ${
                                                                scoreTypes.x.max
                                                                    ? '-'
                                                                    : '+'
                                                            }
                                                            ${
                                                                scoreTypes.x
                                                                    .max ?? ''
                                                            })`}
                                        </Typography>
                                        <ChartForm
                                            newScoreDatum={newScoreDatum}
                                            disableDate={disableDate}
                                            setNewScoreDatum={setNewScoreDatum}
                                            reverseMaxMin={false}
                                            scoreType={
                                                scoreTypes.x.value
                                                    ? scoreTypes.x.value
                                                    : ScoreType.Number
                                            }
                                            min={scoreTypes.x.min}
                                            max={scoreTypes.x.max}
                                            playbookPageOptions={pageOptions}
                                            scores={scores}
                                            setScores={setScores}
                                            scoreValue={scores.x}
                                            axis="x"
                                        />
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            sx={{ mb: '-40px' }}
                                        >
                                            {pageOptions?.chartData?.yAxis}
                                            {` (${scoreTypes.y.min ?? 0}
                                                            ${
                                                                scoreTypes.y.max
                                                                    ? '-'
                                                                    : '+'
                                                            }
                                                            ${
                                                                scoreTypes.y
                                                                    .max ?? ''
                                                            })`}
                                        </Typography>
                                        <ChartForm
                                            newScoreDatum={newScoreDatum}
                                            disableDate={disableDate}
                                            setNewScoreDatum={setNewScoreDatum}
                                            reverseMaxMin={false}
                                            scoreType={
                                                scoreTypes.y.value
                                                    ? scoreTypes.y.value
                                                    : ScoreType.Number
                                            }
                                            min={scoreTypes.y.min}
                                            max={scoreTypes.y.max}
                                            playbookPageOptions={pageOptions}
                                            scores={scores}
                                            setScores={setScores}
                                            scoreValue={scores.y}
                                            axis="y"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    {cardTypeFromDrawer &&
                        scores &&
                        !pageOptions &&
                        !radarOptions && (
                            <Box sx={{ mt: '-30px' }}>
                                <Box>
                                    <Input
                                        type="hidden"
                                        defaultValue={''}
                                        disableUnderline={true}
                                    />
                                    <InputLabel
                                        shrink
                                        htmlFor="styled-input"
                                        sx={{
                                            background: 'initial',
                                            color: theme.palette.text.primary,
                                            fontSize: '1.6em',
                                            fontFamily: 'Antonio,sans-serif',
                                            '&.Mui-focused': {
                                                color: theme.palette.text
                                                    .primary,
                                            },
                                        }}
                                    >
                                        Scores
                                    </InputLabel>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{ height: '100%' }}
                                    >
                                        {scoreDefinitions
                                            .slice(0, 3)
                                            .map((scoreDefinition) => {
                                                return (
                                                    <Grid item xs={4} md={4}>
                                                        <Box>
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    mb: '-50px',
                                                                }}
                                                            >
                                                                {
                                                                    scoreDefinition.name
                                                                }
                                                                {` (${
                                                                    scoreDefinition.minimumValue ??
                                                                    0
                                                                }
                                                            ${
                                                                scoreDefinition.maximumValue
                                                                    ? '-'
                                                                    : '+'
                                                            }
                                                            ${
                                                                scoreDefinition.maximumValue ??
                                                                ''
                                                            })`}
                                                            </Typography>
                                                            <ChartForm
                                                                newScoreDatum={
                                                                    newScoreDatum
                                                                }
                                                                disableDate={
                                                                    disableDate
                                                                }
                                                                setNewScoreDatum={
                                                                    setNewScoreDatum
                                                                }
                                                                reverseMaxMin={
                                                                    scoreDefinition.target ===
                                                                    'Min'
                                                                        ? true
                                                                        : false
                                                                }
                                                                scoreType={
                                                                    scoreDefinition.scoreType
                                                                }
                                                                min={
                                                                    scoreDefinition.minimumValue ??
                                                                    undefined
                                                                }
                                                                max={
                                                                    scoreDefinition.maximumValue ??
                                                                    undefined
                                                                }
                                                                playbookPageOptions={
                                                                    true
                                                                }
                                                                cardScore={
                                                                    cardScores.find(
                                                                        (
                                                                            cardScore
                                                                        ) =>
                                                                            cardScore.scoreDefinition ===
                                                                            scoreDefinition.id
                                                                    )?.value
                                                                }
                                                                setCardScore={(
                                                                    value
                                                                ) => {
                                                                    const scores =
                                                                        [
                                                                            ...cardScores,
                                                                        ];
                                                                    const index =
                                                                        scores.findIndex(
                                                                            (
                                                                                score
                                                                            ) =>
                                                                                score.scoreDefinition ===
                                                                                scoreDefinition.id
                                                                        );
                                                                    if (
                                                                        index !==
                                                                        -1
                                                                    ) {
                                                                        scores[
                                                                            index
                                                                        ] = {
                                                                            value: value,
                                                                            scoreDefinition:
                                                                                scoreDefinition.id,
                                                                        };
                                                                    } else {
                                                                        scores.push(
                                                                            {
                                                                                value: value,
                                                                                scoreDefinition:
                                                                                    scoreDefinition.id,
                                                                            }
                                                                        );
                                                                    }

                                                                    setCardScores &&
                                                                        setCardScores(
                                                                            scores
                                                                        );
                                                                }}
                                                                scoreValue={
                                                                    scores.x
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </Box>
                            </Box>
                        )}
                    {cardTypeFromDrawer === '3' && (
                        <Box sx={{ mt: '-30px' }}>
                            <Box>
                                <Input
                                    type="hidden"
                                    defaultValue={''}
                                    disableUnderline={true}
                                />
                                <InputLabel
                                    shrink
                                    htmlFor="styled-input"
                                    sx={{
                                        background: 'initial',
                                        color: theme.palette.text.primary,
                                        fontSize: '1.6em',
                                        fontFamily: 'Antonio,sans-serif',
                                        '&.Mui-focused': {
                                            color: theme.palette.text.primary,
                                        },
                                    }}
                                >
                                    Scores
                                </InputLabel>
                            </Box>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {scoreDefinitions
                                    .filter(
                                        (item) =>
                                            item.scoreType !==
                                            ScoreType.Currency
                                    )
                                    .map((scoreDefinition) => {
                                        return (
                                            <Box sx={{ width: '50%' }}>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ mb: '-50px' }}
                                                >
                                                    {scoreDefinition.name}
                                                    {` (${
                                                        scoreDefinition.minimumValue ??
                                                        0
                                                    }
                                                            ${
                                                                scoreDefinition.maximumValue
                                                                    ? '-'
                                                                    : '+'
                                                            }
                                                            ${
                                                                scoreDefinition.maximumValue ??
                                                                ''
                                                            })`}
                                                </Typography>
                                                <ChartForm
                                                    newScoreDatum={
                                                        newScoreDatum
                                                    }
                                                    disableDate={disableDate}
                                                    setNewScoreDatum={
                                                        setNewScoreDatum
                                                    }
                                                    reverseMaxMin={
                                                        scoreDefinition.target ===
                                                        'Min'
                                                            ? true
                                                            : false
                                                    }
                                                    scoreType={
                                                        scoreDefinition.scoreType
                                                    }
                                                    min={
                                                        scoreDefinition.minimumValue ??
                                                        undefined
                                                    }
                                                    max={
                                                        scoreDefinition.maximumValue ??
                                                        undefined
                                                    }
                                                    playbookPageOptions={true}
                                                    cardScore={
                                                        cardScores.find(
                                                            (cardScore) =>
                                                                cardScore.scoreDefinition ===
                                                                scoreDefinition.id
                                                        )?.value
                                                    }
                                                    setCardScore={(value) => {
                                                        const scores = [
                                                            ...cardScores,
                                                        ];
                                                        const index =
                                                            scores.findIndex(
                                                                (score) =>
                                                                    score.scoreDefinition ===
                                                                    scoreDefinition.id
                                                            );
                                                        if (index !== -1) {
                                                            scores[index] = {
                                                                value: value,
                                                                scoreDefinition:
                                                                    scoreDefinition.id,
                                                            };
                                                        } else {
                                                            scores.push({
                                                                value: value,
                                                                scoreDefinition:
                                                                    scoreDefinition.id,
                                                            });
                                                        }

                                                        setCardScores &&
                                                            setCardScores(
                                                                scores
                                                            );
                                                    }}
                                                    scoreValue={scores?.x}
                                                />
                                            </Box>
                                        );
                                    })}
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default AttributesColumn;
