import {
    Box,
    Button,
    ButtonBase,
    Grid,
    Menu,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
    FilterOptions,
    PageIdentifier,
    WorksheetFilterDateOptions,
    WorksheetFilterOptions,
} from '../cardTypes';
import useFilterCards, {
    FilterCards as FilterCardsType,
    WorksheetFilterCards,
} from '../../../hooks/useFilterCards';
import { AppContext } from '../../contexts';
import PrimaryFilterColumn from './PrimaryFilterColumn';
import SecondaryFilterColumn from './SecondaryFilterColumn';
import { DateTime } from 'luxon/src/datetime';

interface FilterCardsProps {
    anchorEl: HTMLElement;
    handleClose: () => void;
    filterCriteria: WorksheetFilterCards;
    setFilterCriteria: React.Dispatch<
        React.SetStateAction<WorksheetFilterCards>
    >;
    pageIdentifier: string;
}

export interface SecondaryWorksheetFilterOptions {
    createdDate: {
        value: WorksheetFilterDateOptions | null;
        start?: string;
        end?: string;
    };
    owner: string[];
}

const FilterWorksheetCards = ({
    anchorEl,
    handleClose,
    filterCriteria,
    setFilterCriteria,
    pageIdentifier,
}: FilterCardsProps) => {
    const theme = useTheme();
    const { applyFilter, setApplyFilter } = useContext(AppContext);
    const [selectedPrimaryFilter, setSelectedPrimaryFilter] =
        useState<WorksheetFilterOptions | null>(filterCriteria.primary);
    const [selectedSecondaryFilter, setSelectedSecondaryFilter] =
        useState<SecondaryWorksheetFilterOptions>({
            createdDate: {
                value: filterCriteria.secondary?.createdDate?.value,
                start: filterCriteria.secondary?.createdDate?.start,
                end: filterCriteria.secondary?.createdDate?.end,
            },
            owner: filterCriteria.secondary?.owner,
        });

    const handleFilter = () => {
        setFilterCriteria({
            primary: selectedPrimaryFilter,
            secondary: selectedSecondaryFilter,
        });
        setApplyFilter(!applyFilter);
        handleClose();
    };
    const handleClear = () => {
        setSelectedPrimaryFilter(null);
        setSelectedSecondaryFilter({
            createdDate: { value: WorksheetFilterDateOptions.LAST_HOUR },
            owner: [],
        });
    };

    return (
        <Menu
            aria-labelledby="menu-button"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: `1px 1px 1px 1px ${theme.palette.background.paper}, 
            1px -1px 1px 1px ${theme.palette.background.paper}`,
                    backgroundColor: theme.palette.background.default,
                    marginTop: '-25px',
                    marginLeft: '-75px',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    py: '1.2rem',
                    width: '45rem',
                    height: '25rem',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: '1.5rem',
                    }}
                >
                    <Typography variant="h5" sx={{ mb: 2, ml: 1 }}>
                        Filter worksheet cards
                    </Typography>
                    <ButtonBase onClick={handleClear} disableRipple>
                        <Typography
                            sx={{
                                cursor: 'pointer',
                                color: `${theme.palette.primary.main}`,
                            }}
                            variant="body2"
                        >
                            Clear all
                        </Typography>
                    </ButtonBase>
                </Box>

                <Grid
                    container
                    spacing={0}
                    sx={{ height: '100%', px: '1.2rem' }}
                >
                    <Grid item xs={12} md={3.5} sx={{ height: '17rem' }}>
                        <PrimaryFilterColumn
                            selectedPrimaryFilter={selectedPrimaryFilter}
                            setSelectedPrimaryFilter={setSelectedPrimaryFilter}
                            pageIdentifier={pageIdentifier}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8.5}
                        sx={{ height: '17rem', overflowY: 'hidden' }}
                    >
                        <SecondaryFilterColumn
                            selectedPrimaryFilter={selectedPrimaryFilter}
                            selectedSecondaryFilter={selectedSecondaryFilter}
                            setSelectedSecondaryFilter={
                                setSelectedSecondaryFilter
                            }
                        />
                    </Grid>
                </Grid>
                <Button
                    onClick={handleFilter}
                    variant="contained"
                    sx={{
                        mt: 2,
                        mr: '1.5rem',
                        px: 3,
                        width: 'fit-content',
                        borderRadius: 1,
                        alignSelf: 'flex-end',
                    }}
                >
                    Apply
                </Button>
            </Box>
        </Menu>
    );
};

export default FilterWorksheetCards;
