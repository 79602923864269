import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import {
    Category,
    getCategoryHex,
    getCategoryHexText,
    getCategoryIcon,
} from '../../../helpers/category';
import { CardCategory, Card, CardSet } from '../../../API';
import { NewCard, SelectedWorksheet } from './types';

interface CategoriesColumnProps {
    newCard?: NewCard;
    setNewCard?: (newCard?: NewCard) => void;
    cardSetToCardCategoryId?: string;
    setCardsFromCardSet?: React.Dispatch<
        React.SetStateAction<Card[] | undefined>
    >;
    cardCategories?: CardCategory[];
    filteredCategories?: CardCategory[];
    setIsTypeSelected?: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCategory?: string;
    setSelectedCategory?: React.Dispatch<
        React.SetStateAction<string | undefined>
    >;
    cardSet?: CardSet;
    cardSetCopy?: Pick<
        CardSet,
        'cardSetToCardCategoryId' | 'cardSetToCardTypeId'
    >;
    setSelectedType?: React.Dispatch<
        React.SetStateAction<string | undefined | null>
    >;
    setSelectedWorksheets: React.Dispatch<
        React.SetStateAction<SelectedWorksheet[] | null>
    >;
    cardTypeFromDrawer?: string | undefined;
    setSelectedProcessId?: React.Dispatch<React.SetStateAction<string | null>>;
    setSelectedStepId?: React.Dispatch<React.SetStateAction<string | null>>;
}

const CategoriesColumn = ({
    cardCategories,
    newCard,
    setNewCard,
    cardSetToCardCategoryId,
    setCardsFromCardSet,
    filteredCategories,
    setIsTypeSelected,
    cardSet,
    cardSetCopy,
    setSelectedCategory,
    selectedCategory,
    setSelectedType,
    setSelectedWorksheets,
    cardTypeFromDrawer,
    setSelectedProcessId,
    setSelectedStepId,
}: CategoriesColumnProps) => {
    const theme = useTheme();
    const disabled =
        cardSetToCardCategoryId || cardSet || cardSetCopy || cardTypeFromDrawer;
    const Avatar = ({ categoryId }: { categoryId: string }) => {
        const AvatarComponent = getCategoryIcon(categoryId as Category);
        return <AvatarComponent color={getCategoryHexText(categoryId)} />;
    };

    const categoriesToShow =
        filteredCategories ||
        cardCategories?.filter(
            (category) =>
                category.toCardTypes != null &&
                category.toCardTypes.items.length > 0
        );

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    Card {filteredCategories ? 'set' : ''} categories
                </Typography>
            </Box>
            <List
                data-automation-id="card-category-list"
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    overflowY: 'auto',
                    flex: '1',
                }}
            >
                {categoriesToShow
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((category) => {
                        const isSelected =
                            newCard?.cardToCardCategoryId === category.id ||
                            cardSetToCardCategoryId === category.id ||
                            cardSetCopy?.cardSetToCardCategoryId ===
                                category.id ||
                            selectedCategory === category.id;
                        return (
                            <ListItem
                                onClick={() => {
                                    if (setNewCard) {
                                        setNewCard({
                                            ...newCard!,
                                            cardToCardCategoryId: category.id,
                                            cardToCardTypeId: '',
                                        });
                                        setCardsFromCardSet &&
                                            setCardsFromCardSet([]);
                                    }
                                    setSelectedCategory &&
                                        setSelectedCategory(category.id);
                                    setIsTypeSelected &&
                                        setIsTypeSelected(false);
                                    setSelectedType && setSelectedType(null);
                                    setSelectedWorksheets &&
                                        setSelectedWorksheets(null);
                                    setSelectedProcessId &&
                                        setSelectedProcessId(null);
                                    setSelectedStepId &&
                                        setSelectedStepId(null);
                                }}
                                key={category.id}
                                data-automation-id={category.id}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        bgcolor: 'background.paper',
                                    },
                                    bgcolor: isSelected
                                        ? 'background.paper'
                                        : 'none',
                                    pointerEvents: disabled ? 'none' : 'auto',
                                }}
                            >
                                <ListItemAvatar
                                    sx={{
                                        marginTop: 0,
                                        minWidth: '20px',
                                        filter:
                                            (!isSelected &&
                                                cardTypeFromDrawer) ||
                                            (!isSelected &&
                                                cardSetToCardCategoryId)
                                                ? 'grayscale(100%)'
                                                : '',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            padding: 1,
                                            borderRadius: 1,
                                            background: getCategoryHex(
                                                category.id
                                            ),
                                        }}
                                    >
                                        <Avatar categoryId={category.id} />
                                    </Box>
                                </ListItemAvatar>

                                <ListItemText
                                    sx={{
                                        padding: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 0,
                                        color:
                                            (!isSelected &&
                                                cardTypeFromDrawer) ||
                                            (!isSelected &&
                                                cardSetToCardCategoryId)
                                                ? 'grey'
                                                : '',
                                    }}
                                    primary={category.name}
                                />
                            </ListItem>
                        );
                    })}
            </List>
        </Box>
    );
};

export default CategoriesColumn;
