import React, { useContext } from 'react';
import { Attribute, Card, PlaybookPageDataResponse } from '../../../API';
import { worksheets } from '../../forms/worksheets';
import { Box } from '@mui/material';
import UserAvatar from '../../profile/UserAvatar';
import { GridColDef } from '@mui/x-data-grid';
import TableLayout from '../../../components/layouts/TableLayout';
import { AppContext } from '../../contexts';
import { removeQuotes } from './CardDetailsFromWorksheet';
import { EditablePageValues } from '../EditablePlaybookPage';

interface ComparisonTableProps {
    page: PlaybookPageDataResponse | undefined;
    livePageCards: any;
    playbookPageForm?: EditablePageValues;
    leftChartId: string;
    rightChartId: string;
    include: any;
    pptView?: boolean;
}
const ComparisonTable = ({
    page,
    livePageCards,
    playbookPageForm,
    leftChartId,
    rightChartId,
    include,
    pptView,
}: ComparisonTableProps) => {
    const worksheet = worksheets.find((item) => item.i === page?.worksheetId);
    const columnTitles = worksheet?.lp?.tbl || [];
    const { cardTypes } = useContext(AppContext);

    const columnsFromCharts = [
        {
            t: 'A',
            id: leftChartId,
        },
        {
            t: 'A',
            id: rightChartId,
        },
    ];

    const combinedColumnsMap = new Map(
        columnTitles.concat(columnsFromCharts).map((item) => [item.id, item])
    );
    const combinedColumns = Array.from(combinedColumnsMap.values());
    const tableColumns = include?.find(
        (item: { value: string }) => item.value === 'charts'
    )?.enabled
        ? columnTitles
        : combinedColumns;

    const existingColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: pptView ? 270 : !playbookPageForm ? 70 : 150,
            sortable: false,
        },
        {
            field: 'owner',
            headerName: 'Owner',
            width: pptView ? 200 : 80,
            sortable: false,
            renderCell: (params) => {
                return (
                    <UserAvatar
                        avatarOnly
                        userId={params.value}
                        cardCategory="default"
                    />
                );
            },
        },
        {
            field: 'description',
            headerName: 'Description',
            width: pptView ? 0 : !playbookPageForm ? 100 : 250,
            sortable: false,
        },
    ];

    const existingColumnNames = new Set(
        existingColumns.map((col) =>
            col.headerName ? col.headerName.toLowerCase() : ''
        )
    );
    let filteredColumns = existingColumns;
    if (!playbookPageForm && !pptView) {
        filteredColumns = existingColumns.filter(
            (col) => col.field !== 'owner'
        );
    }
    if (pptView) {
        filteredColumns = existingColumns.filter(
            (col) => col.field !== 'description'
        );
    }
    const columnsFromAttributes = tableColumns
        .filter(
            (titleObj): titleObj is { id: string; t: string } =>
                typeof titleObj === 'object' &&
                titleObj !== null &&
                titleObj.t !== 'S' &&
                titleObj.id != null
        )
        .reduce<GridColDef[]>((acc, titleObj) => {
            const columnName = titleObj.id.toLowerCase();
            if (
                !existingColumnNames.has(columnName) &&
                !acc.some((col) => col.field === columnName)
            ) {
                const headerNameParts = titleObj.id.split('-');
                const cardType = cardTypes.find(
                    (item) => item.id === headerNameParts[0]
                );
                const headerNameText = cardType?.attributeDefinitions?.find(
                    (item) => item && item.id === titleObj.id
                )?.name;
                acc.push({
                    field: columnName,
                    headerName: headerNameText,
                    width: pptView ? 270 : !playbookPageForm ? 50 : 125,
                    sortable: false,
                    valueGetter: (params: any) => {
                        if (!params?.row?.attributes) {
                            return '';
                        }

                        const attribute = params.row.attributes.find(
                            (attr: Attribute) =>
                                attr &&
                                attr.attributeDefinitionID === titleObj.id
                        );

                        const valueToDisplay = attribute
                            ? removeQuotes(attribute)
                            : '';
                        return valueToDisplay;
                    },
                });
            }
            return acc;
        }, []);

    const columns = [filteredColumns[0]];
    columns.push(...columnsFromAttributes);
    if (filteredColumns.length > 1) {
        columns.push(...filteredColumns.slice(1));
    }
    const tableStyle: any = {
        '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 800,
            fontSize: pptView ? '20px' : !playbookPageForm ? '6px' : null,
            height: pptView ? 50 : !playbookPageForm ? 25 : null,
        },
        '.MuiDataGrid-cell': {
            fontSize: pptView ? '20px' : !playbookPageForm ? '6px' : null,
            borderTop: pptView ? '1px solid #000' : 'none',
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent',
        },
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
            borderRight: 'none',
        },
        '& .MuiDataGrid-columnHeader': {
            borderRight: 'none',
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingRight: 0,
        },
        color: '#000',
        '& .MuiDataGrid-virtualScroller': {
            overflow: !playbookPageForm ? 'hidden' : 'auto',
        },
    };

    return (
        <Box>
            <TableLayout
                columns={columns}
                items={livePageCards.filter((item: Card) =>
                    worksheet?.auto.includes(item.cardToCardTypeId)
                )}
                tableStyle={tableStyle}
                {...(!playbookPageForm
                    ? { rowHeight: pptView ? 70 : 20 }
                    : undefined)}
            />
        </Box>
    );
};

export default ComparisonTable;
