import { Box, useTheme } from '@mui/material';
import PieChart from '../../charts/PieChart';
import CustomLegend from '../../charts/CustomLegend';
import { PlaybookPageFormType } from '../playbookTypes';
import { EditablePageValues } from '../EditablePlaybookPage';

interface PieChartBoxProps {
    chartId: string;
    pieLabels: string[];
    pieData: number[];
    chartTitle: string;
    playbookPageForm: EditablePageValues | undefined;
    adjustedCategoryColors: string[] | undefined;
    pptView?: boolean;
}

const PieChartBox = ({
    chartId,
    pieLabels,
    pieData,
    chartTitle,
    playbookPageForm,
    adjustedCategoryColors,
    pptView,
}: PieChartBoxProps) => {
    if (!chartId) return null;
    const theme = useTheme();
    const styles = {
        width: !playbookPageForm ? '60%' : '160px',
        height: pptView ? '12rem' : !playbookPageForm ? '4rem' : null,
        mb: playbookPageForm ? 2 : 0,
        mr: pptView ? 3 : playbookPageForm ? 0 : 1,
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mr: !playbookPageForm ? 3 : 5,
                height: '100%',
            }}
        >
            <Box sx={styles}>
                <PieChart
                    labels={pieLabels}
                    data={pieData}
                    defaultLabel="Uncategorized"
                    colors={adjustedCategoryColors}
                    title={`By ${chartTitle}`}
                    playbookPageForm={playbookPageForm}
                    pptView={pptView}
                />
            </Box>
            <CustomLegend
                labels={pieLabels}
                data={pieData}
                colors={adjustedCategoryColors}
                title={`By ${chartTitle}`}
                sx={{ color: theme.palette.grey[600] }}
                thumbnail={!playbookPageForm}
                border
                pptView={pptView}
            />
        </Box>
    );
};

export default PieChartBox;
