import { AppBar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { appBarWidth } from '../../../helpers/styleConfig';
import { CardSet } from '../../../API';
import { useNavigate } from 'react-router-dom';
import TileViewIcon from '../../../assets/images/menubar/grid-view-unselected.png';
import TileViewIconActive from '../../../assets/images/menubar/grid-view.png';
import ListViewIcon from '../../../assets/images/menubar/list-view-unselected.png';
import ListViewIconActive from '../../../assets/images/menubar/list-view.png';
import { ViewType } from '../../cards/cardTypes';
const iconStyles = { width: 40, height: 40, display: 'inline' };

interface WorkbookHeaderProps {
    workbook: CardSet;
    setViewOption: React.Dispatch<React.SetStateAction<ViewType>>;
    selectedViewType: ViewType;
}

const WorkbookHeader = ({
    workbook,
    setViewOption,
    selectedViewType,
}: WorkbookHeaderProps) => {
    const navigate = useNavigate();

    const viewOptions = {
        [ViewType.GRID]: true,
        [ViewType.LIST]: true,
    };

    return (
        <AppBar
            variant="outlined"
            position="fixed"
            elevation={0}
            sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                backdropFilter: 'blur(5px)',
                pl: `calc(${appBarWidth} + 1rem)`,
                pr: 3,
                height: '3rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: 'none',
                svg: {
                    width: '2rem',
                    height: '2rem',
                },
            }}
        >
            {workbook && (
                <Typography variant="h5" sx={{ color: 'text.secondary' }}>
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/workbooks');
                        }}
                    >
                        Workbooks
                    </span>
                    {' > '} <span>{workbook?.name}</span>{' '}
                </Typography>
            )}
            {!!setViewOption && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    {viewOptions?.[ViewType.GRID] && (
                        <Tooltip title="Grid view">
                            <IconButton
                                aria-label="Grid View"
                                onClick={() => setViewOption(ViewType.GRID)}
                            >
                                <img
                                    src={
                                        selectedViewType === ViewType.GRID
                                            ? TileViewIconActive
                                            : TileViewIcon
                                    }
                                    style={iconStyles}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                    {viewOptions?.[ViewType.LIST] && (
                        <Tooltip title="List view">
                            <IconButton
                                aria-label="List View"
                                onClick={() => setViewOption(ViewType.LIST)}
                            >
                                <img
                                    src={
                                        selectedViewType === ViewType.LIST
                                            ? ListViewIconActive
                                            : ListViewIcon
                                    }
                                    style={iconStyles}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            )}
        </AppBar>
    );
};

export default WorkbookHeader;
