import { useState, useEffect, useContext } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { getUserOrganisation } from '../../../helpers/auth';
import { generateClient } from 'aws-amplify/api';
import { GraphQLQuery } from '@aws-amplify/api';
import {
    CardSelectorFieldProps,
    FieldType, 
    StringOptionType,
} from './inputFieldTypes';
import RadioField from './RadioField';
import CheckboxFields from './CheckboxFields';
import {
    Card,
    CardSet,
    CardSetsOrderedByTypeAndNameQuery,
    CardsOrderedByNameQuery,
} from '../../../API';
import {
    cardSetsOrderedByTypeAndName,
    cardsOrderedByName,
} from '../../../graphql/queries';
import { AppContext } from '../../contexts';

export enum CardType {
    CARDS = 'Cards',
    CARDSETS = 'Cardsets',
    WORKBOOK = 'Workbook',
}

interface CardOptions {
    label: string;
    value: string;
}

interface WorkbookCard {
    card: {
        cardToCardCategoryId: string;
        id: string;
        name?: string;
    };
}

interface WorkbookItem {
    id: string;
    name: string;
    toCards: {
        items: WorkbookCard[];
    };
}

const CardSelector = (props: CardSelectorFieldProps) => {
    const theme = useTheme();
    const [cards, setCards] = useState<CardOptions[]>([]);
    const [cardSets, setCardSets] = useState<CardOptions[]>([]);
    const [workbooks, setWorkbooks] = useState<CardOptions[]>([]);

    const { user } = useContext(AppContext);

    const [loading, setLoading] = useState(false);

    const { onChangeCards } = props;

    const { cardSelector } = props;

    const client = generateClient();

    const cardQuery = `query CardsOrderedByName(
        $organisation: ID!
        $filter: ModelCardFilterInput
        $limit: Int
        $nextToken: String
      ) {
        cardsOrderedByName(
          organisation: $organisation
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            name
          }
        }
    }`;

    const cardSetQuery = /* GraphQL */ `
        query CardSetsOrderedByTypeAndName(
            $organisation: ID!
            $filter: ModelCardSetFilterInput
            $limit: Int
            $nextToken: String
        ) {
            cardSetsOrderedByTypeAndName(
                organisation: $organisation
                filter: $filter
                limit: $limit
                nextToken: $nextToken
            ) {
                items {
                    id
                    name
                }
            } 
        }
    `;

    type CustomWorkBookQuery = {
        cardSetsOrderedByTypeAndName?: {
            __typename: 'ModelCardSetConnection';
            items: Array<{
                __typename: 'CardSet';
                organisation: string;
                id: string;
                name: string;
                capitalName?: string | null;
                description?: string | null;
                owner?: string | null;
                createdBy?: string | null;
                editors?: Array<string> | null;
                toCards?: {
                    __typename: 'ModelCardsCardSetsConnection';
                    items: Array<{
                        card: {
                            __typename: 'CardsCardSets';
                            organisation: string;
                            id: string;
                            cardId: string;
                            cardSetId: string;
                            createdAt: string;
                            updatedAt: string;
                            owner?: string | null;
                            cardToCardCategoryId: string;
                            cardToCardTypeId: string;
                        };
                    } | null>;
                    nextToken?: string | null;
                } | null;
            } | null>;
            nextToken?: string | null;
        } | null;
    };

    const workbookQuery = /* GraphQL */ `
        query CardSetsOrderedByTypeAndName(
            $organisation: ID!
            $filter: ModelCardSetFilterInput
            $limit: Int
            $nextToken: String
        ) {
            cardSetsOrderedByTypeAndName(
                organisation: $organisation
                filter: $filter
                limit: $limit
                nextToken: $nextToken
            ) {
                items {
                    id
                    name
                    toCards {
                        items {
                            card {
                                id
                                name
                                cardToCardCategoryId
                                cardToCardTypeId
                            }
                        }
                    }
                }
            }
        }
    `;

    const options: StringOptionType[] = [];
    if (cardSelector?.src?.cards) {
        options.push({
            label: 'Select cards',
            value: CardType.CARDS,
        });
    }
    if (cardSelector?.src?.cardSets) {
        options.push({
            label: 'Select card set',
            value: CardType.CARDSETS,
        });
    }
    if (cardSelector?.src?.workbooks) {
        options.push({
            label: 'Select workbook',
            value: CardType.WORKBOOK,
        });
    }

    useEffect(() => {
        const getAllCards = async () => {
            setLoading(true);
            const userGroup = await getUserOrganisation(user);

            const cardFilter: {
                cardToCardTypeId: { eq: string };
                deleteAfter: { attributeExists: boolean };
            }[] = [];
            const cardSetFilter: { cardSetToCardTypeId: { eq: string } }[] = [];

            if (cardSelector?.auto) {
                cardSelector.auto.forEach((cardType) => {
                    cardFilter.push({
                        cardToCardTypeId: { eq: cardType },
                        deleteAfter: { attributeExists: false },
                    });
                    cardSetFilter.push({
                        cardSetToCardTypeId: { eq: cardType },
                    });
                });
            }
            const getCards = async () => {
                let allCards: Card[] = [];
                let nextToken = null;

                do {
                    const response = await client.graphql({
                        query: cardsOrderedByName,
                        variables: {
                            organisation: userGroup,
                            filter:
                                cardFilter.length > 0 ? { or: cardFilter } : {},
                            nextToken: nextToken,
                        },
                    });
                    const result = response as {
                        data: CardsOrderedByNameQuery;
                    };

                    allCards = [
                        ...allCards,
                        ...(result?.data?.cardsOrderedByName?.items as Card[]),
                    ];
                    nextToken = result?.data?.cardsOrderedByName?.nextToken;
                } while (nextToken);

                return allCards;
            };

            const getCardSets = async () => {
                let allCardSets: CardSet[] = [];
                let nextToken = null;
                do {
                    const response = await client.graphql({
                        query: cardSetsOrderedByTypeAndName,
                        variables: {
                            organisation: userGroup,
                            filter:
                                cardSetFilter.length > 0
                                    ? { or: cardSetFilter }
                                    : {},
                            nextToken: nextToken,
                        },
                    });
                    const result = response as {
                        data: CardSetsOrderedByTypeAndNameQuery;
                    };

                    allCardSets = [
                        ...allCardSets,
                        ...(result?.data?.cardSetsOrderedByTypeAndName
                            ?.items as CardSet[]),
                    ];
                    nextToken =
                        result?.data?.cardSetsOrderedByTypeAndName?.nextToken;
                } while (nextToken);

                return allCardSets;
            };

            const getWorkbooks = async () =>
                (await client.graphql<GraphQLQuery<CustomWorkBookQuery>>({
                    query: workbookQuery,
                    variables: {
                        organisation: userGroup,
                        filter: { type: { eq: 'WB' } },
                    },
                })) as { data: CustomWorkBookQuery };

            const res = await Promise.all([
                getCards(),
                getCardSets(),
                getWorkbooks(),
            ]);

            setCards(
                res[0].map((item) => {
                    return { label: item?.name, value: item?.id };
                }) as CardOptions[]
            );
            const cardSets = res[1].filter(
                (item) => item?.toCards?.items?.length
            );

            setCardSets(
                cardSets.map((item) => {
                    return { label: item?.name, value: item?.id };
                }) as CardOptions[]
            );

            const workbooks = cardSelector?.auto?.length
                ? res[2].data?.cardSetsOrderedByTypeAndName?.items.filter(
                      (item) =>
                          item?.toCards?.items.length &&
                          item?.toCards?.items.filter((item) =>
                              cardSelector?.auto?.includes(
                                  item?.card?.cardToCardTypeId as string
                              )
                          ).length > 0
                  )
                : res[2].data?.cardSetsOrderedByTypeAndName?.items ?? [];

            setWorkbooks(
                workbooks?.map((item) => {
                    return { label: item?.name, value: item?.id };
                }) as CardOptions[]
            );
            setLoading(false);
        };
        if (cardSelector?.src && user) {
            getAllCards();
        }
        if (!cardSelector?.src?.cards) {
            onChangeCards && onChangeCards('typeselector', CardType.CARDSETS);
        } else if (!cardSelector?.src.cardSets) {
            onChangeCards && onChangeCards('typeselector', CardType.WORKBOOK);
        }
    }, [cardSelector?.src, user]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {cardSelector?.src ? (
                <>
                    <RadioField
                        type={FieldType.RADIO}
                        name="typeselector"
                        options={options}
                        sx={{ flexDirection: 'row', padding: '6px' }}
                        onChange={(event) => {
                            onChangeCards &&
                                onChangeCards(
                                    'typeselector',
                                    event?.target?.value
                                );
                        }}
                        value={cardSelector?.type}
                    />
                    <Box
                        sx={{
                            background: theme.palette.background.paper,
                            maxHeight: '200px',
                            padding: '1rem 1rem 1rem 0',
                            borderRadius: 2,
                            mt: 1,
                            mb: 3,
                        }}
                    >
                        <Box
                            sx={{
                                height: '160px',
                                width: '100%',
                                overflowY: 'scroll',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    paddingLeft: 2,
                                    alignItems: loading
                                        ? 'center'
                                        : 'flex-start',
                                    justifyContent: loading
                                        ? 'center'
                                        : 'flex-start',
                                }}
                            >
                                {!loading ? (
                                    <>
                                        {(cardSelector?.type ===
                                            CardType.CARDS &&
                                            cards.length > 0) ||
                                        (cardSelector?.type ===
                                            CardType.CARDSETS &&
                                            cardSets.length > 0) ||
                                        (cardSelector?.type ===
                                            CardType.WORKBOOK &&
                                            workbooks.length > 0) ? (
                                            <CheckboxFields
                                                type={FieldType.CHECKBOX}
                                                name="cardselector"
                                                options={
                                                    cardSelector?.type ===
                                                    CardType.WORKBOOK
                                                        ? workbooks
                                                        : cardSelector?.type ===
                                                          CardType.CARDSETS
                                                        ? cardSets
                                                        : cards
                                                }
                                                onChangeCheckbox={(
                                                    name,
                                                    value
                                                ) => {
                                                    onChangeCards &&
                                                        onChangeCards(
                                                            name,
                                                            value
                                                        );
                                                }}
                                                values={cardSelector?.cards}
                                            />
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    height: '100%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    No applicable cards are
                                                    detected
                                                </Typography>
                                            </Box>
                                        )}
                                    </>
                                ) : (
                                    <CircularProgress size={50} />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </>
            ) : (
                <CircularProgress size={100} />
            )}
        </Box>
    );
};

export default CardSelector;
