import React from 'react';
import { CreateCardOptions, PageIdentifier } from '../../cards/cardTypes';
import { ReactComponent as CreateCardProcessIcon } from '../../../assets/icons/CreateCardProcess.svg';
import { ReactComponent as CardManagerIcon } from '../../../assets/icons/CardManager.svg';
import { ReactComponent as WorkbookManagerIcon } from '../../../assets/icons/Workbooks.svg';
import {
    Box,
    Button,
    IconButton,
    SvgIconTypeMap,
    Tooltip,
    useTheme,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { NewCard, NewWorksheet, SelectedWorksheet, UserPreferences } from './types';
import { CardSet, PlaybookPageType, Worksheet } from '../../../API';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface OptionSelectorProps {
    selectedCreateOption: CreateCardOptions;
    setSelectedCreateOption: React.Dispatch<
        React.SetStateAction<CreateCardOptions>
    >;
    storedCreateOption: CreateCardOptions;
    setStoredCreateOption: React.Dispatch<
        React.SetStateAction<CreateCardOptions>
    >;
    setSelectedStepId: React.Dispatch<React.SetStateAction<string | null>>;
    setSelectedProcessId: React.Dispatch<React.SetStateAction<string | null>>;
    setNewCard: React.Dispatch<React.SetStateAction<NewCard>>;
    setNewWorksheet: React.Dispatch<React.SetStateAction<NewWorksheet>>;
    setSelectedWorksheets: React.Dispatch<
        React.SetStateAction<SelectedWorksheet[] | null>
    >;
    setSelectedType: React.Dispatch<
        React.SetStateAction<string | null | undefined>
    >;
    pageIdentifier: PageIdentifier;
    preferences: UserPreferences;
    workbook?: CardSet;
    setSelectedWorksheetItems: React.Dispatch<
        React.SetStateAction<Worksheet[]>
    >;
    setSelectedWorkbook: React.Dispatch<
        React.SetStateAction<CardSet | undefined>
    >;
}

const OptionSelector = ({
    selectedCreateOption,
    setSelectedCreateOption,
    storedCreateOption,
    setStoredCreateOption,
    setSelectedStepId,
    setSelectedProcessId,
    setNewCard,
    setNewWorksheet,
    setSelectedWorksheets,
    setSelectedType,
    pageIdentifier,
    preferences,
    workbook,
    setSelectedWorksheetItems,
    setSelectedWorkbook,
}: OptionSelectorProps) => {
    const theme = useTheme();
    const storeSelectedCreateOption = () => {
        localStorage.setItem(
            `createProcess_${pageIdentifier}`,
            selectedCreateOption
        );
        setStoredCreateOption(selectedCreateOption);
    };
    interface CreateOptions {
        name: CreateCardOptions;
        tooltip: string;
        IconComponent:
            | React.FC<React.SVGProps<SVGSVGElement>>
            | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
                  muiName: string;
              });
        iconStyle: { width: string; height: string };
    }
    const createOptions: CreateOptions[] = [
        {
            name: CreateCardOptions.BY_PROCESS,
            tooltip: 'Select by process',
            IconComponent: CreateCardProcessIcon,
            iconStyle: { width: '31px', height: '31px' },
        },
    ];
    if (pageIdentifier !== PageIdentifier.WORKSHEET) {
        createOptions.push({
            name: CreateCardOptions.BY_CATEGORY,
            tooltip: 'Select by category',
            IconComponent: CardManagerIcon,
            iconStyle: { width: '28px', height: '28px' },
        });
    }
    if (pageIdentifier === PageIdentifier.WORKSHEET) {
        // "WORKSHEET" here refers to playbook pages, as worksheets and playbook pages were originally treated the same.
        createOptions.push({
            name: CreateCardOptions.BY_WORKBOOK,
            tooltip: 'Select by workbook',
            IconComponent: WorkbookManagerIcon,
            iconStyle: { width: '28px', height: '28px' },
        });
        createOptions.push({
            name: CreateCardOptions.TITLE_PAGE,
            tooltip: 'Add title page',
            IconComponent: DescriptionIcon,
            iconStyle: { width: '28px', height: '28px' },
        });
    }
    const handleOptionChange = (optionName: CreateCardOptions) => {
        setSelectedCreateOption(optionName);
        setSelectedStepId(null);
        setSelectedProcessId(null);
        setSelectedWorksheetItems([]);
        setSelectedWorkbook(undefined);
        const { workbook } = preferences?.latestActivity || {};
        const notWorksheet = pageIdentifier !== PageIdentifier.WORKSHEET;
        const cardToCardCategoryId = notWorksheet
            ? workbook?.cardCategoryId ?? ''
            : '';
        const cardToCardTypeId = notWorksheet ? workbook?.cardTypeId ?? '' : '';

        setNewCard({
            description: '',
            cardToCardCategoryId,
            cardToCardTypeId,
            name: '',
            organisation: '',
            attributes: [],
        });
        setNewWorksheet({
            title: '',
            commentary: '',
            playbookPageType: PlaybookPageType.List,
        });
        setSelectedWorksheets && setSelectedWorksheets(null);
        setSelectedType && setSelectedType(null);
    };
    return (
        <>
            <Box>
                {createOptions.map((option) => {
                    return (
                        <Tooltip key={option.name} title={option.tooltip}>
                            <IconButton
                                sx={{
                                    color: (theme) =>
                                        theme.palette.action.active,
                                }}
                                onClick={() => handleOptionChange(option.name)}
                                data-testid={option.tooltip}
                            >
                                <option.IconComponent
                                    style={{
                                        ...option.iconStyle,
                                        color:
                                            selectedCreateOption === option.name
                                                ? theme.palette.primary.main
                                                : theme.palette.grey[500],
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    );
                })}
                <Button
                    onClick={storeSelectedCreateOption}
                    disabled={storedCreateOption === selectedCreateOption}
                    variant="text"
                >
                    Set as default
                </Button>
            </Box>
        </>
    );
};

export default OptionSelector;
