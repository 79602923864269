import { LinearProgress, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { CardSet } from '../../API';

export enum ProcessName {
    OBSERVE = 'Observe',
    ORIENT = 'Orient',
    DECIDE = 'Decide',
    ACT = 'Act',
    FRAME = 'Frame',
}
export const descriptions: { [key in ProcessName]: string } = {
    [ProcessName.OBSERVE]: 'Assess your current state',
    [ProcessName.ORIENT]: 'Determine your strategic position',
    [ProcessName.DECIDE]: 'Assess strategic options & choose a way forward',
    [ProcessName.ACT]: 'Set the wheels of your strategy in motion',
    [ProcessName.FRAME]: 'Set your strategic purpose and direction',
};
interface ProcessProps {
    placement?: {
        top?: string | number;
        left?: string | number;
        bottom?: string | number;
        right?: string | number;
        position?: string;
    };
    processName: ProcessName;
    processId?: string;
    setSelectionType: React.Dispatch<React.SetStateAction<string | null>>;
    headerColor: string;
    smallBox?: boolean;
    workbook?: CardSet;
    progressArray?:
        | {
              stageId: string;
              progress: number;
          }[]
        | null;
    setProgressArray?: React.Dispatch<
        React.SetStateAction<
            | {
                  stageId: string;
                  progress: number;
              }[]
            | null
        >
    >;
}

const Process = ({
    placement,
    processName,
    processId,
    setSelectionType,
    headerColor,
    smallBox,
    workbook,
    progressArray,
    setProgressArray,
}: ProcessProps) => {
    const theme = useTheme();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const process = progressArray?.find(
            (item) => item?.stageId === processId
        );
        if (process?.progress !== undefined && process?.progress !== null) {
            setProgress(process.progress);
        }
    }, [progressArray]);

    const description = descriptions[processName];
    const textAlignment =
        processName === ProcessName.ACT || processName === ProcessName.DECIDE
            ? 'right'
            : 'left';
    return (
        <Box
            sx={{
                ...placement,
                width: smallBox ? '260px' : '420px',
                height: smallBox ? '120px' : '140px',
                borderRadius: '12px',
                background: theme.palette.background.paper,
                cursor: 'pointer',
            }}
            onClick={() => setSelectionType(processName)}
        >
            <Box
                sx={{
                    background: headerColor,
                    px: 2,
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                }}
            >
                <Typography variant="h6" sx={{ color: 'white' }}>
                    {processName.toUpperCase()}
                </Typography>
            </Box>
            <Typography
                variant="body1"
                sx={{ px: 2, py: 1, fontSize: smallBox ? '16px' : '18px' }}
            >
                {description}
            </Typography>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '10px',
                    width: '100%',
                    px: 2,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        color: theme.palette.text.secondary,
                        textAlign: 'center',
                    }}
                >
                    {Math.round(progress)}% completed
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ height: 6, borderRadius: 5 }}
                />
            </Box>
        </Box>
    );
};

export default Process;
