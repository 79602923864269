import { Chart, ChartArea, ChartData, ChartOptions, Scale } from 'chart.js';
import { PlaybookPageFormType } from '../playbooks/playbookTypes';
import { CustomDataPoint } from './BubbleChart';
import { Tick } from 'chart.js';
import { EditablePageValues } from '../playbooks/EditablePlaybookPage';

export const getPieChartOptions = (
    title: string,
    data: ChartData<'pie'>,
    playbookPageForm?: EditablePageValues
) => {
    const uncategorisedColor = 'grey';

    if (data.labels && data.datasets) {
        data.datasets.forEach((dataset) => {
            let backgroundColors: string[] = [];

            if (Array.isArray(dataset.backgroundColor)) {
                backgroundColors = [...dataset.backgroundColor];
            } else if (typeof dataset.backgroundColor === 'string') {
                backgroundColors = new Array(data.labels?.length).fill(
                    dataset.backgroundColor
                );
            } else {
                backgroundColors = new Array(data.labels?.length).fill('blue');
            }

            dataset.backgroundColor = data.labels?.map((label, index) => {
                return label === 'Uncategorized'
                    ? uncategorisedColor
                    : backgroundColors[index];
            });
        });
    }

    const pieChartOptions: ChartOptions<'pie'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
                title: {
                    display: true,
                    text: title,
                    font: {
                        size: playbookPageForm ? 12 : 6,
                    },
                    position: 'start',
                    padding: {
                        left: 20,
                    },
                },
                position: 'right',
                align: 'start',
                labels: {
                    generateLabels(chart) {
                        const data = chart.data;
                        return (
                            data.labels?.map((label, i) => {
                                const meta = chart.getDatasetMeta(0);
                                const style = meta.controller.getStyle(
                                    i,
                                    false
                                );

                                return {
                                    text: `${label}: ${data.datasets[0].data[i]}`,
                                    fillStyle: style.backgroundColor,
                                    strokeStyle: style.borderColor,
                                    lineWidth: style.borderWidth,
                                    hidden: !chart.getDataVisibility(i),
                                    index: i,
                                };
                            }) || []
                        );
                    },
                    font: {
                        size: playbookPageForm ? 12 : 6,
                    },
                    boxWidth: playbookPageForm ? 15 : 6,
                    boxHeight: playbookPageForm ? 15 : 6,
                },
            },
        },
    };

    return pieChartOptions;
};
