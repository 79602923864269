import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { CardComponentType } from './cardTypes';
import { CardToDelete } from '../../pages/cards';

interface RemoveCardProps {
    id?: string;
    organisation: string;
    toggleRemoveWizard: React.Dispatch<React.SetStateAction<boolean>>;
    confirmRemove?: (id: string, multiDelete?: boolean) => Promise<void>;
    confirmMultiRemove?: () => Promise<void>;
    cardComponentType: string;
    multiSelectedCards?: [] | CardToDelete[];
    inProgress?: boolean;
}

const RemoveCard = ({
    id,
    organisation,
    toggleRemoveWizard,
    confirmRemove,
    confirmMultiRemove,
    cardComponentType,
    multiSelectedCards,
    inProgress,
}: RemoveCardProps) => {
    const handleRemoveClick = async () => {
        if (multiSelectedCards && multiSelectedCards.length > 0) {
            if (confirmMultiRemove) {
                await confirmMultiRemove();
            }
        } else if (confirmRemove && id) {
            confirmRemove(id);
        }
    };
    return (
        <Box
            sx={{
                padding: '20px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                {multiSelectedCards && multiSelectedCards.length > 0
                    ? `Are you sure you want to remove ${
                          multiSelectedCards.length
                      } cards from the ${
                          cardComponentType === CardComponentType.CARD_SET
                              ? 'card set'
                              : 'workbook'
                      }?`
                    : `Are you sure you want to remove this card from the ${
                          cardComponentType === CardComponentType.CARD_SET
                              ? 'card set'
                              : 'workbook'
                      }?`}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    gap: 2,
                    alignSelf: 'flex-end',
                }}
            >
                <Button
                    onClick={() => toggleRemoveWizard(false)}
                    variant="outlined"
                    sx={{
                        mt: 2,
                        width: 'fit-content',
                        borderRadius: 2,
                        px: 4,
                    }}
                >
                    {inProgress ? (
                        <CircularProgress size={18} color={'inherit'} />
                    ) : (
                        'Cancel'
                    )}
                </Button>
                <Button
                    data-testid="remove-card"
                    onClick={handleRemoveClick}
                    variant="contained"
                    sx={{
                        mt: 2,
                        width: 'fit-content',
                        borderRadius: 2,
                        bgcolor: 'error.main',
                        color: 'error.contrastText',
                        '&:hover': { bgcolor: 'error.light' },
                    }}
                >
                    {inProgress ? (
                        <CircularProgress size={18} color={'inherit'} />
                    ) : (
                        'Remove'
                    )}
                </Button>
            </Box>
        </Box>
    );
};

export default RemoveCard;
