import {
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { CardSet, PlaybookPage, Worksheet } from '../../../API';
import { truncateText } from '../../../helpers/utils';
import { Avatar } from '../../forms/CreatePlaybookPage/WorksheetList';
import { worksheets } from '../../forms/worksheets';

interface RemoveWorkbookProps {
    toggleDeleteWizard: React.Dispatch<React.SetStateAction<boolean>>;
    confirmDelete: () => void;
    setCardToDelete?: React.Dispatch<React.SetStateAction<CardSet | null>>;
    cancel?: () => void;
    playbookPages: PlaybookPage[] | undefined;
}
const RemoveWorkbook = ({
    toggleDeleteWizard,
    confirmDelete,
    setCardToDelete,
    cancel,
    playbookPages,
}: RemoveWorkbookProps) => {
    const handleConfirm = () => {
        confirmDelete();
    };
    return (
        <Box
            sx={{
                padding: '20px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                Are you sure you want to remove this workbook?
            </Typography>

            {playbookPages && playbookPages?.length > 0 ? (
                <>
                    <Typography
                        variant="body1"
                        color="error.main"
                        sx={{
                            marginBottom: 0,
                            textAlign: 'left',
                            fontSize: '1.2rem',
                        }}
                    >
                        This action will remove the following playbook pages
                        from the playbook.
                    </Typography>

                    <List
                        sx={{
                            maxHeight: '12vh',
                            overflow: 'auto',
                        }}
                    >
                        {playbookPages?.map((item) => {
                            const worksheetItem = worksheets.find(
                                (ws) => ws.i === item?.worksheetId
                            );

                            return (
                                <ListItem
                                    key={item.id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            marginBottom: 0,
                                            minWidth: '40px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '30px',
                                                height: '30px',
                                                mb: 1,
                                            }}
                                        >
                                            <Avatar
                                                worksheet={
                                                    worksheetItem?.n || ''
                                                }
                                            />
                                        </Box>
                                    </ListItemAvatar>
                                    <Tooltip title={item?.title}>
                                        <ListItemText
                                            primary={truncateText(
                                                item?.title || '',
                                                50
                                            )}
                                        />
                                    </Tooltip>
                                </ListItem>
                            );
                        })}
                    </List>
                </>
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    gap: 2,
                    alignSelf: 'flex-end',
                }}
            >
                <Button
                    onClick={() => {
                        cancel && cancel();
                        toggleDeleteWizard(false);
                        setCardToDelete && setCardToDelete(null);
                    }}
                    variant="outlined"
                    sx={{
                        mt: 2,
                        width: 'fit-content',
                        borderRadius: 2,
                        px: 4,
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleConfirm}
                    variant="contained"
                    sx={{
                        mt: 2,
                        width: 'fit-content',
                        borderRadius: 2,
                        bgcolor: 'error.main',
                        color: 'error.contrastText',
                        '&:hover': { bgcolor: 'error.light' },
                    }}
                >
                    Remove
                </Button>
            </Box>
        </Box>
    );
};

export default RemoveWorkbook;
