import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { StyledSection } from '../../StyledElements';
import ComparisonTable from '../lists/ComparisonTable';
import {
    Card,
    PlaybookDataResponse,
    PlaybookPageDataResponse,
    PlaybookPageType,
} from '../../../API';
import PlaybookPagePpt from './PlaybookPagePpt';
import { worksheets } from '../../forms/worksheets';
import CardDetailsFromWorksheet from '../lists/CardDetailsFromWorksheet';
import { Field } from '../../../helpers/worksheets';

interface PptLayoutProps {
    playbook: PlaybookDataResponse | null;
    includeCardDetails: boolean;
    logo: string | null;
}

interface CardGroup {
    [key: string]: Card[];
}

const PptLayout = ({ playbook, includeCardDetails, logo }: PptLayoutProps) => {
    const getPageCards = (
        page: PlaybookPageDataResponse
    ): Card[] | undefined => {
        const cards = playbook
            ? playbook?.cards?.filter((card) =>
                  page.allCards?.includes(card?.id ?? 'null')
              )
            : [];
        const worksheet = worksheets.find(
            (item) => item.i === page.worksheetId
        );
        const pageCards = cards?.filter(
            (item) => item && worksheet?.auto.includes(item?.cardToCardTypeId)
        );

        const dedupedCards = pageCards?.filter(
            (value, index, self) =>
                index === self.findIndex((t) => t?.id === value?.id)
        );
        return dedupedCards as Card[];
    };

    const splitSlide = (cards?: Card[], worksheetId?: string) => {
        if (!cards) return false;
        const worksheet = worksheets.find((item) => item.i === worksheetId);
        const pageCards = cards?.filter((item) =>
            worksheet?.auto.includes(item?.cardToCardTypeId)
        );
        const cardGroups: CardGroup = {};
        pageCards.forEach((card) => {
            const typeId = card.cardToCardTypeId;
            if (!cardGroups[typeId]) {
                cardGroups[typeId] = [];
            }
            cardGroups[typeId].push(card);
        });
        const maxCardCount = worksheetId === '1' ? 8 : 4;
        return Object.values(cardGroups).some(
            (group) => group.length > maxCardCount
        );
    };
    const splitCardSlide = (array: any) => {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += 4) {
            chunkedArr.push(array.slice(i, i + 4));
        }
        return chunkedArr;
    };
    return (
        <Box
            style={{
                position: 'absolute',
                left: '-10000px',
                top: '-10000px',
            }}
        >
            {playbook?.pages?.map((page, index) => {
                const pageCards = getPageCards(page) || [];
                return page.playbookPageType === PlaybookPageType.List ? (
                    <>
                        <Box
                            key={index}
                            className={
                                pageCards.length > 0
                                    ? `slide-element ${page.id}`
                                    : 'blank-slide'
                            }
                        >
                            <PlaybookPagePpt
                                page={page}
                                cards={getPageCards(page)}
                                carousel={true}
                                logo={logo}
                            />
                        </Box>
                        {page?.include?.includes(Field.COMPARISON_TABLE) &&
                            pageCards.length > 0 && (
                                <Box
                                    sx={{ padding: '1rem' }}
                                    className={
                                        pageCards.length > 0
                                            ? `slide-element ${page.id}`
                                            : 'blank-slide'
                                    }
                                >
                                    <Box
                                        sx={{
                                            height: '60px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography
                                            variant="h4"
                                            fontSize="2.21em"
                                            sx={{ width: '100%' }}
                                        >
                                            {page?.title}{' '}
                                            <span
                                                style={{ fontSize: '24px' }}
                                            >{`(2 of 2)`}</span>
                                        </Typography>
                                    </Box>
                                    <StyledSection
                                        sx={{
                                            height:
                                                pageCards.length < 5
                                                    ? '30rem'
                                                    : pageCards.length < 7
                                                    ? '35rem'
                                                    : 'initial',
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                mt: 0,
                                                mb: 2,
                                                fontSize: '24px',
                                            }}
                                        >
                                            Comparison table
                                        </Typography>

                                        <ComparisonTable
                                            page={page}
                                            livePageCards={
                                                page.include.includes(
                                                    Field.DETAILS
                                                ) && getPageCards(page)
                                            }
                                            leftChartId={''}
                                            rightChartId={''}
                                            include={page.include}
                                            pptView={true}
                                        />
                                    </StyledSection>
                                </Box>
                            )}
                        {page?.include?.includes(Field.DETAILS) &&
                            includeCardDetails &&
                            pageCards?.length > 0 &&
                            pageCards.map((card, cardIndex) => {
                                const items = card?.toScoreData?.items || [];
                                if (items.length > 4) {
                                    return splitCardSlide(items).map(
                                        (itemsChunk, index) => (
                                            <Box
                                                key={index}
                                                width={'800px'}
                                                height={'450px'}
                                                className={
                                                    pageCards?.length > 0
                                                        ? `slide-element ${page.id}`
                                                        : 'blank-slide'
                                                }
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-end',
                                                }}
                                            >
                                                <CardDetailsFromWorksheet
                                                    card={{
                                                        ...card,
                                                        toScoreData: {
                                                            ...card.toScoreData,
                                                            items: itemsChunk,
                                                            __typename:
                                                                card
                                                                    ?.toScoreData
                                                                    ?.__typename ||
                                                                'ModelScoreDataConnection',
                                                        },
                                                    }}
                                                    pptView
                                                    slideNumber={index + 1}
                                                    totalSlides={
                                                        splitCardSlide(items)
                                                            .length
                                                    }
                                                />
                                            </Box>
                                        )
                                    );
                                } else {
                                    return (
                                        <Box
                                            key={cardIndex}
                                            width={'800px'}
                                            height={'450px'}
                                            className={`slide-element ${page.id}`}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <CardDetailsFromWorksheet
                                                card={card}
                                                pptView
                                                slideNumber={1}
                                                totalSlides={1}
                                            />
                                        </Box>
                                    );
                                }
                                return null;
                            })}
                    </>
                ) : (
                    <>
                        {page.worksheetId === '1' ||
                        page.worksheetId === '69' ? (
                            <>
                                {splitSlide(
                                    getPageCards(page),
                                    page.worksheetId
                                ) ? (
                                    <>
                                        <Box
                                            key={index}
                                            className={
                                                pageCards.length > 0
                                                    ? `slide-element ${page.id}`
                                                    : 'blank-slide'
                                            }
                                        >
                                            <PlaybookPagePpt
                                                page={page}
                                                cards={getPageCards(page)}
                                                carousel={true}
                                                slideNumber={1}
                                                logo={logo}
                                            />
                                        </Box>
                                        <Box
                                            key={index}
                                            className={
                                                pageCards.length > 0
                                                    ? `slide-element ${page.id}`
                                                    : 'blank-slide'
                                            }
                                        >
                                            <PlaybookPagePpt
                                                page={page}
                                                cards={getPageCards(page)}
                                                carousel={true}
                                                slideNumber={2}
                                                logo={logo}
                                            />
                                        </Box>
                                    </>
                                ) : (
                                    <Box
                                        key={index}
                                        className={
                                            pageCards.length > 0
                                                ? `slide-element ${page.id}`
                                                : 'blank-slide'
                                        }
                                    >
                                        <PlaybookPagePpt
                                            page={page}
                                            cards={getPageCards(page)}
                                            carousel={true}
                                            logo={logo}
                                        />
                                    </Box>
                                )}
                            </>
                        ) : (
                            <Box
                                key={index}
                                className={
                                    pageCards.length > 0 ||
                                    page.playbookPageType ===
                                        PlaybookPageType.Title
                                        ? `slide-element ${page.id}`
                                        : 'blank-slide'
                                }
                            >
                                <PlaybookPagePpt
                                    page={page}
                                    cards={getPageCards(page)}
                                    carousel={true}
                                    logo={logo}
                                />
                            </Box>
                        )}
                    </>
                );
            })}
        </Box>
    );
};

export default PptLayout;
