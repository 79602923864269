import { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
    Card,
    PlaybookDataResponse,
    PlaybookPageDataResponse,
    PlaybookPageType,
    UpdatePlaybookPageMutation,
} from '../../API';
import PlaybookPageComponent from './PlaybookPage';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AnimatePresence } from 'framer-motion';
import { CardType } from '../forms/input-fields/CardSelector';
import PlaybookConfigurationForm from './forms/PlaybookConfigurationForm';
import { generateClient } from 'aws-amplify/api';
import {
    updatePlaybookPage,
    createPlaybookPage,
} from '../../graphql/mutations';
import { UserPermissions } from '../../globals';
import {
    WorksheetSetting,
    getWorksheetSettings,
} from '../../helpers/worksheets';
import { AppContext } from '../contexts';
import styled from '@emotion/styled';
import { RadarOption } from '../forms/input-fields/ChartSettings';
import PlaybookTitleTypeForm from './forms/PlaybookTitleTypeForm';
import { PlaybookContext } from './context';

interface EditablePlaybookProps {
    playbook: PlaybookDataResponse;
    cards: Card[];
    editMode: boolean;
    initEditMode?: boolean;
    permissions: UserPermissions[];
    page?: PlaybookPageDataResponse;
    setEditMode: (editMode: boolean) => void;
    playbookPageType?: PlaybookPageType;
    playbookPageCardTypeId?: string;
    onUpdate?: (id: string, organisation: string) => void;
    itemSelected: boolean;
    carousel?: boolean;
    logo: string | null;
}

const temporaryPage: PlaybookPageDataResponse = {
    __typename: 'PlaybookPageDataResponse',
    id: '',
    cardTypeId: '',
    commentary: '',
    playbookPageType: PlaybookPageType.List,
    title: '',
};

export type CardSettings = {
    type: CardType;
    cards: string[];
    auto?: string[];
    src?: {
        cards: boolean;
        cardSets: boolean;
        workbooks: boolean;
    };
};
export interface EditablePageValues {
    title: string;
    commentary?: string;
    include?: string[];
    cardIds: string[];
    workbookIds: string[];
    cardSetIds: string[];
}
interface PageOptions {
    layout: any;
}
const Lightbox = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(5px)',
    zIndex: 1500,
}));
const EditablePlaybook = ({
    cards,
    editMode,
    page,
    playbook,
    permissions,
    playbookPageType,
    setEditMode,
    onUpdate,
    playbookPageCardTypeId,
    itemSelected,
    carousel,
    logo,
}: EditablePlaybookProps) => {
    const parsedCardSets = page?.cardSets;

    const [formValues, setFormValues] = useState<EditablePageValues>({
        title: page?.title || '[My new page]',
        commentary: page?.commentary || '',
        include: (page?.include as string[]) || [
            'charts',
            'list',
            'scores',
            'content',
        ],
        cardIds: [],
        workbookIds: [],
        cardSetIds: parsedCardSets?.map((item) => item ?? '') || [],
    });
    const { handleClose } = useContext(PlaybookContext);
    const [editablePage, setEditablePage] =
        useState<PlaybookPageDataResponse>(temporaryPage);
    const [cardSettings, setCardSettings] = useState<CardSettings>({
        type: CardType.CARDS,
        cards: [],
    });
    const [worksheetSettings, setWorksheetSettings] =
        useState<WorksheetSetting | null>(null);
    const { cardTypes } = useContext(AppContext);
    const [pageOptions, setPageOptions] = useState<any>();
    const [radarOptions, setRadarOptions] = useState<RadarOption[] | null>();

    const client = generateClient();

    const [helpTextAnchor, setHelpTextAnchor] = useState<HTMLElement | null>();

    if (playbookPageType) {
        temporaryPage.playbookPageType = playbookPageType;
    }

    if (playbookPageCardTypeId) {
        temporaryPage.cardTypeId = playbookPageCardTypeId;
    }

    useEffect(() => {
        if (page?.options) {
            setPageOptions(JSON.parse(page.options));
            setRadarOptions(JSON.parse(page.options).chartData?.radarOptions);
        } else if (page?.playbookPageType === PlaybookPageType.List) {
            setPageOptions({
                layout: [
                    {
                        include: [
                            { value: 'charts', label: 'Charts', enabled: true },
                        ],
                    },
                ],
            });
        } else {
            setPageOptions({ layout: [] });
        }
    }, [page]);

    useEffect(() => {
        if (page) {
            setEditablePage(page);

            if (page.playbookPageType === PlaybookPageType.Title) {
                setWorksheetSettings({
                    i: '',
                    n: 'Title page',
                    t: '',
                    e: true,
                    auto: [],
                    d: '',
                    src: {
                        cards: false,
                        cardSets: false,
                        workbooks: false,
                    },
                    lp: '',
                    h: {
                        s1: '',
                        s2: '',
                    },
                });
            } else {
                setWorksheetSettings(
                    getWorksheetSettings(page.worksheetId ?? '')
                );
            }

            setCardSettings({
                ...cardSettings,
                type: page.workbooks?.length
                    ? CardType.WORKBOOK
                    : page.cardSets?.length
                    ? CardType.CARDSETS
                    : CardType.CARDS,
                cards: page.allCards?.length
                    ? (page.allCards.filter(
                          (item) => item !== null
                      ) as string[])
                    : page.cardSets?.length
                    ? (page.cardSets.filter(
                          (item) => item !== null
                      ) as string[])
                    : page.workbooks?.length
                    ? (page.workbooks.filter(
                          (item) => item !== null
                      ) as string[])
                    : [],
            });

            if (page.allCards?.length) {
                setFormValues({
                    ...formValues,
                    cardIds: page.allCards as string[],
                });
            } else if (page.cardSets?.length) {
                setFormValues({
                    ...formValues,
                    cardSetIds: page.cardSets as string[],
                });
            } else if (page.workbooks?.length) {
                setFormValues({
                    ...formValues,
                    workbookIds: page.workbooks as string[],
                });
            }
        }
    }, [page]);

    const updateValue = (name: string, value: string) => {
        if (name === 'titleconfig') {
            const page = { ...editablePage };

            let options = page.options ? JSON.parse(page?.options) : {};
            options.titleconfig = value;
            setEditablePage({
                ...editablePage,
                options: JSON.stringify(options),
            });
        } else if (name === 'include') {
            const include = editablePage.include
                ? [...editablePage.include]
                : [];
            if (!include.includes(value)) {
                include.push(value);
            } else {
                const index = include.findIndex((item) => item === value);
                include.splice(index, 1);
            }
            setEditablePage({
                ...editablePage,
                include: include,
            });
        } else if (name !== 'typeselector' && name !== 'cardselector') {
            setEditablePage({
                ...editablePage,
                [name]: value,
            });
        } else {
            if (name === 'typeselector') {
                setCardSettings({ type: value as CardType, cards: [] });
                setFormValues({
                    ...formValues,
                    cardSetIds: [],
                    workbookIds: [],
                    cardIds: [],
                });
            } else {
                const cards = [...cardSettings.cards];
                if (!cards.includes(value)) {
                    cards.push(value);
                } else {
                    const index = cards.indexOf(value);
                    cards.splice(index, 1);
                }
                setCardSettings({
                    ...cardSettings,
                    cards: cards,
                });
                if (cardSettings.type === CardType.CARDS) {
                    setFormValues({
                        ...formValues,
                        cardSetIds: [],
                        workbookIds: [],
                        cardIds: cards,
                    });
                } else if (cardSettings.type === CardType.CARDSETS) {
                    setFormValues({
                        ...formValues,
                        cardIds: [],
                        workbookIds: [],
                        cardSetIds: cards,
                    });
                } else {
                    setFormValues({
                        ...formValues,
                        cardIds: [],
                        cardSetIds: [],
                        workbookIds: cards,
                    });
                }
            }
        }
    };

    const onSubmit = async (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        const currentOptions: any = pageOptions || {};
        const currentChartData: any = currentOptions.chartData || {};
        const updatedChartData = {
            ...currentChartData,
            radarOptions: radarOptions,
        };
        const combinedOptions = {
            ...currentOptions,
            chartData: updatedChartData,
        };
        const res = (await client.graphql({
            query: editablePage?.id ? updatePlaybookPage : createPlaybookPage,
            variables: {
                input: {
                    organisation: playbook.organisation,
                    id: editablePage?.id || undefined,
                    playbookId: playbook.id,
                    playbookPageType: editablePage.playbookPageType,
                    title: editablePage?.title,
                    commentary: editablePage?.commentary,
                    ordinal: editablePage?.ordinal,
                    include: editablePage?.include,
                    cards:
                        cardSettings.type === CardType.CARDS
                            ? cardSettings.cards
                            : [],
                    cardSets:
                        cardSettings.type === CardType.CARDSETS
                            ? cardSettings.cards
                            : [],
                    workbooks:
                        cardSettings.type === CardType.WORKBOOK
                            ? cardSettings.cards
                            : [],
                    options: JSON.stringify(combinedOptions),
                },
            },
        })) as { data: UpdatePlaybookPageMutation };
        if (res) {
            onUpdate && onUpdate(editablePage.id, playbook.organisation);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
            }}
        >
            {editMode && (
                <Box sx={{ position: 'absolute', right: 20, top: 20 }}>
                    <IconButton onClick={() => handleClose()}>
                        <CloseIcon sx={{ color: 'inherit' }} />
                    </IconButton>
                </Box>
            )}
            {helpTextAnchor && <Lightbox />}
            <AnimatePresence>
                <Box
                    sx={{
                        py: editMode ? 4 : 0,
                        px: editMode ? 5 : 0,
                        height: '100%',
                        display: 'flex',
                        overflow: 'auto',
                    }}
                >
                    {editMode && (
                        <Box
                            sx={{
                                position: 'sticky',
                                top: 0,
                                flexShrink: 0,
                                mr: 4,
                                width: '40%',
                                overflow: 'auto',
                            }}
                        >
                            {!page &&
                                playbookPageType === PlaybookPageType.List && (
                                    <Typography variant="h1" sx={{ mb: 4 }}>
                                        {`Create a  'Playbook'Page`}
                                    </Typography>
                                )}

                            {page?.playbookPageType ===
                            PlaybookPageType.Title ? (
                                <PlaybookTitleTypeForm
                                    playbook={playbook}
                                    updateValue={updateValue}
                                    page={editablePage}
                                    onUpdate={onUpdate}
                                />
                            ) : (
                                <PlaybookConfigurationForm
                                    playbook={playbook}
                                    page={editablePage}
                                    updateValue={updateValue}
                                    cardSettings={cardSettings}
                                    worksheetSettings={worksheetSettings}
                                    onSubmit={onSubmit}
                                    helpTextAnchor={helpTextAnchor}
                                    setHelpTextAnchor={setHelpTextAnchor}
                                    pageOptions={pageOptions}
                                    setPageOptions={setPageOptions}
                                    radarOptions={radarOptions}
                                    setRadarOptions={setRadarOptions}
                                />
                            )}
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        {editMode && (
                            <Typography variant="body1">
                                <strong>Preview</strong>
                            </Typography>
                        )}
                        <PlaybookPageComponent
                            permissions={permissions}
                            setEditMode={setEditMode}
                            cards={cards}
                            page={editablePage}
                            playbookPageForm={formValues}
                            edit={editMode}
                            itemSelected={itemSelected}
                            carousel={carousel}
                            pageOptions={pageOptions}
                            setPageOptions={setPageOptions}
                            radarOptions={radarOptions}
                            setRadarOptions={setRadarOptions}
                            logo={logo}
                        />
                    </Box>
                </Box>
            </AnimatePresence>
        </Box>
    );
};

export default EditablePlaybook;
