/* tslint:disable */
/* eslint-disable */

// any mutations defined in here will need to be manually updated if the underlying schema changes
// run: amplify codegen
// to update API.ts

// NPA: A simplified deletion mutation for cards as the generated one would error
// when trying to retrieve card-set memberships if these had been removed asynchronously.
// There's no reason I can think of to return all that information when deleting an item, so
// this just returns the primary key info.
// The codegen wasn't happy if I tried disabling the generated delete mutation so that unfortunately lives on for now.
export const deleteCardBasic = /* GraphQL */ `
    mutation DeleteCardBasic(
        $input: DeleteCardInput!
        $condition: ModelCardConditionInput
    ) {
        deleteCard(input: $input, condition: $condition) {
            organisation
            id
        }
    }
`;

// Simplified delete mutation to stop error as detailed in comment for deleteCardBasic
export const deleteCardSetBasic = /* GraphQL */ `
    mutation DeleteCardSetBasic(
        $input: DeleteCardSetInput!
        $condition: ModelCardSetConditionInput
    ) {
        deleteCardSet(input: $input, condition: $condition) {
            organisation
            id
        }
    }
`;
