import { generateClient } from 'aws-amplify/api';
import { GraphQLQuery } from '@aws-amplify/api';
import { Card, CardCategory, CardType, UserProfile } from '../API';
import { queryDataWithFilter } from './QueryData';
import { getCardDataWithScoreDataBasic } from '../graphql/custom-queries';
import { sortFilteredCards } from '../helpers/card';

export const filterCardsBySearchNameTypeCat = async ({
    searchTerm,
    organistion,
    cardCategories,
    cardTypes,
    users,
}: {
    searchTerm: string;
    organistion: string;
    cardCategories: CardCategory[];
    cardTypes: CardType[];
    users: UserProfile[];
}): Promise<any> => {
    const lowerSearchTerm = searchTerm?.toLowerCase();

    const filteredCategories = cardCategories.filter((category) =>
        category.name.toLowerCase().includes(lowerSearchTerm as string)
    );

    const filteredTypes = cardTypes.filter((type) =>
        type.name?.toLowerCase().includes(lowerSearchTerm as string)
    );

    const filteredUsers = users.filter((user) =>
        user.firstName
            ?.concat(user.lastName as string)
            .toLowerCase()
            .includes(lowerSearchTerm as string)
    );

    const filter: { or: { [key: string]: {} }[] } = {
        or: [
            { name: { contains: searchTerm } },
            { name: { contains: searchTerm?.toUpperCase() } },
            { name: { contains: searchTerm?.toLowerCase() } },
        ],
    };

    filteredTypes.forEach((filteredType) => {
        filter.or.push({ cardToCardTypeId: { eq: filteredType.id } });
    });

    filteredCategories.forEach((filteredCategory) => {
        filter.or.push({
            cardToCardCategoryId: { eq: filteredCategory.id },
        });
    });
    filteredUsers.forEach((user) => {
        filter.or.push({ owner: { eq: user.id } });
    });
    filter.or.push({
        description: { contains: searchTerm },
    });
    filter.or.push({
        description: { contains: searchTerm?.toUpperCase() },
    });
    filter.or.push({
        description: { contains: searchTerm?.toLowerCase() },
    });

    try {
        const { data, nextToken } = await queryDataWithFilter({
            query: getCardDataWithScoreDataBasic,
            responseType: 'cardsOrderedByName',
            filter: filter,
            organisation: organistion,
            limit: true,
        });

        let filteredData = sortFilteredCards({
            term: searchTerm,
            cards: data,
            cardTypeIds: filteredTypes.map((type) => type.id as string),
            cardCategoryIds: filteredCategories.map((cat) => cat.id),
            userIds: filteredUsers.map((user) => user.id),
        });

        return { data: filteredData, nextToken };
    } catch (err) {
        console.log(err);
    }
};
