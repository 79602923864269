import { useContext, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import RadioFields from '../forms/input-fields/RadioField';
import SelectField from '../forms/input-fields/SelectField';
import { FieldType } from '../forms/input-fields/inputFieldTypes';
import {
    AttributeType,
    CreatePlaybookMutation,
    ListPlaybooksQuery,
    Playbook,
    PlaybookPageType,
} from '../../API';

import { generateClient } from 'aws-amplify/api';
import { listPlaybooks } from '../../graphql/queries';
import { getUserOrganisation } from '../../helpers/auth';
import TextField from '../forms/input-fields/TextField';
import { PagePreview } from './WorkbookDrawerPage';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { AppContext } from '../contexts';
import { RadarOption } from '../forms/input-fields/ChartSettings';

export enum PlaybookType {
    ADD = 'add',
    CREATE = 'create',
}

interface NewPlaybook {
    name: string;
    description: string;
}
const SavePlaybookPage = ({
    onCancel,
    page,
    pageOptions,
    radarOptions,
}: {
    onCancel: () => void;
    page: PagePreview;
    pageOptions: any;
    radarOptions: RadarOption[] | null | undefined;
}) => {
    const [playbooks, setPlaybooks] = useState<Playbook[]>();
    const [newPlaybook, setNewPlaybook] = useState<NewPlaybook>({
        name: '',
        description: '',
    });
    const [selectedPlaybook, setSelectedPlaybook] = useState<string | null>(
        null
    );
    const [playbookType, setPlaybookType] = useState<PlaybookType>( 
        PlaybookType.ADD
    );
    const { user } = useContext(AppContext);

    const { id } = useParams();

    const client = generateClient();

    const getOrdinal = () => {
        const playbook = playbooks?.find(
            (item) => item.id === selectedPlaybook
        );

        const pages = playbook?.toPlaybookPages?.items
            .filter((item) => item?.ordinal != null)
            .sort((a, b) => (a?.ordinal ?? 0) - (b?.ordinal ?? 0));

        if (!pages?.length) return 1;

        const currentLowest = pages[0]?.ordinal;

        return currentLowest ? currentLowest - 1 : -1;
    };

    const submitPlaybookPage = async () => {
        const userOrganisation = getUserOrganisation(user);

        const playbookInput =
            playbookType === PlaybookType.CREATE
                ? `$playbook: CreatePlaybookInput!\n$playbookCondition: ModelPlaybookConditionInput`
                : ``;
        const playbookRequest =
            playbookType === PlaybookType.CREATE
                ? ` createPlaybook(input: $playbook, condition: $playbookCondition) {
            organisation
            id
            name
            description
        }`
                : ``;

        const query = `
            mutation createPlaybookWithPage(
                ${playbookInput}
                $page: CreatePlaybookPageInput!
                $pageCondition: ModelPlaybookPageConditionInput
            ) {
                ${playbookRequest}
                createPlaybookPage(input: $page, condition: $pageCondition) {
                    organisation
                    id
                    commentary
                    playbookPageType
                }
            }
        `;
        const currentOptions: any = pageOptions || {};
        const currentChartData: any = currentOptions.chartData || {};
        const updatedChartData = {
            ...currentChartData,
            radarOptions: radarOptions,
        };
        const combinedOptions = {
            ...currentOptions,
            chartData: updatedChartData,
        };
        try {
            const playbookId =
                playbookType === PlaybookType.CREATE
                    ? uuidv4()
                    : selectedPlaybook;
            const pageId = uuidv4();

            const variables: { [key: string]: any } = {
                ...(playbookType === PlaybookType.CREATE && {
                    playbook: {
                        description: newPlaybook.description,
                        name: newPlaybook.name,
                        id: playbookId,
                        organisation: userOrganisation,
                    },
                }),
                page: {
                    id: pageId,
                    playbookPageType: PlaybookPageType.Chart,
                    ordinal:
                        playbookType === PlaybookType.CREATE ? 1 : getOrdinal(),
                    organisation: userOrganisation,
                    title: page.title,
                    commentary: page.commentary,
                    include: page.include,
                    playbookId: playbookId,
                    options: pageOptions
                        ? JSON.stringify(combinedOptions)
                        : null,
                    worksheetId: page.worksheetId,
                    workbooks: [id],
                },
            };

            const res = (await client.graphql({
                query,
                variables: variables,
            })) as { data: CreatePlaybookMutation };

            onCancel();
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const getPlaybooks = async () => {
            const userGroup = getUserOrganisation(user);

            let variables: {
                organisation: string;
                filter?: { owner: { eq: string } };
            } = {
                organisation: userGroup,
            };

            const response = (await client.graphql({
                query: listPlaybooks,
                variables: variables,
            })) as { data: ListPlaybooksQuery };

            setPlaybooks(response.data.listPlaybooks?.items as Playbook[]);
        };
        if (user) {
            getPlaybooks();
        }
    }, [user]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h1" sx={{ mb: 4 }}>
                Save to playbook
            </Typography>
            <Box>
                <RadioFields
                    type={FieldType.RADIO}
                    name="Add"
                    value={playbookType === PlaybookType.ADD ? 'add' : ''}
                    options={[
                        { label: 'Add to existing playbook', value: 'add' },
                    ]}
                    onChange={() => setPlaybookType(PlaybookType.ADD)}
                />
                <SelectField
                    type={AttributeType.SingleSelect}
                    name="select"
                    label="Select playbook"
                    options={playbooks?.map((playbook) => {
                        return { value: playbook.id, label: playbook.name };
                    })}
                    onChange={(event) =>
                        setSelectedPlaybook(event.target.value as string)
                    }
                    value={selectedPlaybook}
                    disabled={playbookType !== PlaybookType.ADD}
                />
                <RadioFields
                    type={FieldType.RADIO}
                    name="Create"
                    value={playbookType === PlaybookType.CREATE ? 'create' : ''}
                    options={[
                        { label: 'Create new playbook', value: 'create' },
                    ]}
                    onChange={() => setPlaybookType(PlaybookType.CREATE)}
                />
                <Box
                    sx={{
                        '.MuiFormLabel-root': {
                            color: '#000',
                        },
                    }}
                >
                    <TextField
                        type={AttributeType.Text}
                        name="name"
                        label="Name"
                        value={newPlaybook.name}
                        required={true}
                        onChange={(event) =>
                            setNewPlaybook({
                                ...newPlaybook,
                                name: event.target.value,
                            })
                        }
                        disabled={playbookType === PlaybookType.ADD}
                    />
                    <TextField
                        type={AttributeType.MultilineText}
                        label="Description"
                        name="description"
                        value={newPlaybook.description}
                        onChange={(event) =>
                            setNewPlaybook({
                                ...newPlaybook,
                                description: event.target.value,
                            })
                        }
                        disabled={playbookType === PlaybookType.ADD}
                        multiline
                        rows={5}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="outlined"
                            onClick={onCancel}
                            sx={{ justifySelf: 'right', mr: 2, px: 4 }}
                        >
                            {'Cancel'}
                        </Button>

                        <Button
                            variant="contained"
                            type="submit"
                            sx={{ justifySelf: 'right' }}
                            onClick={() => submitPlaybookPage()}
                            disabled={
                                (!newPlaybook.name &&
                                    playbookType === PlaybookType.CREATE) ||
                                (playbookType === PlaybookType.ADD &&
                                    !selectedPlaybook)
                            }
                        >
                            {'Save'}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SavePlaybookPage;
