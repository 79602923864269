import React, { useContext, useEffect, useState } from 'react';
import { GraphQLQuery } from '@aws-amplify/api';
import { CardCategory, ListCardCategoriesQuery } from '../../../../API';
import { listCardCategories } from '../../../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Typography,
    useTheme,
} from '@mui/material';
import { getCategoryHex } from '../../../../helpers/category';
import { FilterCards } from '../../../../hooks/useFilterCards';
import { FilterOptions } from '../../cardTypes';
import { AppContext } from '../../../contexts';
import { getCardSetWithId } from '../../../../helpers/card';
interface CategoryListProps {
    localFilters?: FilterCards;
    setLocalFilters?: React.Dispatch<
        React.SetStateAction<FilterCards | undefined>
    >;
    selectedCategoryId?: string;
    setSelectedCategoryId?: React.Dispatch<React.SetStateAction<string>>;
}
const CategoryList = ({
    localFilters,
    setLocalFilters,
    selectedCategoryId,
    setSelectedCategoryId,
}: CategoryListProps) => {
    const theme = useTheme();
    const [cardCategories, setCardCategories] = useState<CardCategory[]>();
    const [cardCategoriesFromSet, setCardCategoriesFromSet] = useState<
        string[]
    >([]);
    const [checkedStates, setCheckedStates] = useState<{
        [key: string]: boolean;
    }>({});

    const client = generateClient();

    useEffect(() => {
        if (cardCategories) {
            const newCheckedStates = cardCategories.reduce(
                (states, category) => {
                    const isCategorySelected =
                        localFilters?.cardType?.includes(category.id) ?? false;
                    states[category.id] = isCategorySelected;
                    return states;
                },
                {} as { [key: string]: boolean }
            );
            setCheckedStates(newCheckedStates);
        }
    }, [cardCategories]);
    const getCardCats = async () => {
        try {
            const { data } = await client.graphql<
                GraphQLQuery<ListCardCategoriesQuery>
            >({
                query: listCardCategories,
            });
            const filteredCategories = data?.listCardCategories?.items?.filter(
                (category) =>
                    category != null &&
                    category.toCardTypes != null &&
                    category.toCardTypes.items.length > 0
            );
            setCardCategories(filteredCategories as CardCategory[]);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        !cardCategories && getCardCats();
    }, [cardCategories]);

    const selectCategory = (categoryId: string) => {
        if (setSelectedCategoryId) {
            setSelectedCategoryId(categoryId);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            const relatedCardPromises = (localFilters?.cardSet || []).map(
                async (item) => {
                    try {
                        return await getCardSetWithId(item);
                    } catch (error) {
                        console.error('Error fetching related card:', error);
                        return null;
                    }
                }
            );

            const fetchedRelatedCardSets = await Promise.all(
                relatedCardPromises
            );
            const types = fetchedRelatedCardSets.map((cardSet: any) => {
                return cardSet?.cardSetToCardCategoryId;
            });
            setCardCategoriesFromSet(types);
        };
        fetchData();
    }, []);
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
            }}
        >
            <Typography variant="body1" sx={{ ml: 2, fontWeight: 'bold' }}>
                Card categories
            </Typography>

            {cardCategories &&
                cardCategories
                    .sort((a, b) => {
                        const firstInSet = cardCategoriesFromSet.includes(a.id);
                        const secondInSet = cardCategoriesFromSet.includes(
                            b.id
                        );

                        if (firstInSet && !secondInSet) {
                            return -1;
                        } else if (!firstInSet && secondInSet) {
                            return 1;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    })
                    .map((category) => {
                        const insideSelectedCardSet =
                            cardCategoriesFromSet.includes(category.id);
                        return (
                            <FormControlLabel
                                onClick={() =>
                                    insideSelectedCardSet ||
                                    localFilters?.cardSet?.length === 0
                                        ? selectCategory(category.id)
                                        : null
                                }
                                key={category.id}
                                control={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                my: setSelectedCategoryId
                                                    ? 1
                                                    : 0,
                                                width: '18px',
                                                height: '24px',
                                                padding: 1,
                                                borderRadius: 1,
                                                background:
                                                    insideSelectedCardSet ||
                                                    localFilters?.cardSet
                                                        ?.length === 0
                                                        ? getCategoryHex(
                                                              category.id
                                                          )
                                                        : 'grey',
                                                marginRight: 1,
                                            }}
                                        ></Box>
                                    </div>
                                }
                                sx={{
                                    height: '35px',
                                    width: '100%',
                                    paddingLeft: 1.8,
                                    margin: 0,
                                    color:
                                        selectedCategoryId === category.id
                                            ? '#fff'
                                            : 'inherit',
                                    backgroundColor:
                                        selectedCategoryId === category.id
                                            ? theme.palette.primary.main
                                            : category.toCardTypes?.items?.some(
                                                  (item) =>
                                                      item !== null &&
                                                      localFilters?.cardType?.includes(
                                                          item.id
                                                      )
                                              )
                                            ? theme.palette.background.paper
                                            : 'transparent',
                                    cursor:
                                        insideSelectedCardSet ||
                                        localFilters?.cardSet?.length === 0
                                            ? 'pointer'
                                            : 'default',
                                    '&:hover': {
                                        backgroundColor:
                                            !checkedStates[category.id] &&
                                            setSelectedCategoryId
                                                ? 'primary'
                                                : theme.palette.background
                                                      .paper,
                                    },
                                }}
                                label={category.name}
                            />
                        );
                    })}
        </Box>
    );
};
export default CategoryList;
