import {
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Typography,
    useTheme,
} from '@mui/material';
import { CardCategory, CardType, CardSet } from '../../../API';
import {
    Category,
    getCategoryHex,
    getCategoryHexText,
    getCategoryIcon,
} from '../../../helpers/category';
import {
    getCardTypesFromCategory,
    sortAlphabetically,
} from '../../../helpers/utils';
import { NewCard } from './types';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts';
import { processSteps } from '../processSteps';
import { CreateCardStep } from '../formTypes';
import { CreateCardOptions, PageIdentifier } from '../../cards/cardTypes';

interface TypesProps {
    newCard?: NewCard;
    cardCategories?: CardCategory[];
    setNewCard?: (newCard?: NewCard) => void;
    cardSetToCardCategoryId?: string;
    cardSetToCardTypeId?: string;
    filteredTypes?: CardType[];
    setSelectedCategory?: (category: string | undefined) => void;
    setSelectedType?: React.Dispatch<
        React.SetStateAction<string | undefined | null>
    >;
    selectedCategory?: string;
    selectedType?: string | null | undefined;
    setIsTypeSelected?: React.Dispatch<React.SetStateAction<boolean>>;
    cardSetCopy?: Pick<
        CardSet,
        'cardSetToCardCategoryId' | 'cardSetToCardTypeId'
    >;
    selectedStepId?: string | null;
    cardTypeFromDrawer?: string | undefined;
    pageIdentifier?: PageIdentifier;
    selectedCreateOption?: CreateCardOptions;
}

const TypesColumn = ({
    cardCategories,
    newCard,
    setNewCard,
    cardSetToCardCategoryId,
    filteredTypes,
    setSelectedType,
    selectedType,
    setIsTypeSelected,
    cardSetCopy,
    selectedStepId,
    cardTypeFromDrawer,
    pageIdentifier,
    selectedCreateOption,
}: TypesProps) => {
    const theme = useTheme();
    const { cardTypeObject } = useContext(AppContext);
    const [typesFromProcess, setTypesFromProcess] = useState<CardType[]>();
    const Avatar = ({ categoryId }: { categoryId: string }) => {
        const AvatarComponent = getCategoryIcon(categoryId as Category);
        return <AvatarComponent color={getCategoryHexText(categoryId)} />;
    };
    const [suggestions, setSuggestions] = useState<CardType[]>([]);

    const disabled =
        cardSetToCardCategoryId || cardSetCopy || cardTypeFromDrawer;

    const [searchTerm, setSearchTerm] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const { cardTypes } = useContext(AppContext);

    useEffect(() => {
        if (selectedStepId) {
            const allSteps = processSteps.reduce(
                (acc: CreateCardStep[], item) => {
                    return acc.concat(item.steps);
                },
                []
            );

            const processTypes = allSteps
                .filter((item: CreateCardStep) => item.id === selectedStepId)[0]
                .cardTypes.map((item: string) => {
                    return cardTypeObject[item];
                });
            setTypesFromProcess(processTypes);
        }
    }, [cardTypeObject, selectedStepId, selectedType]);

    useEffect(() => {
        if (searchTerm) {
            const items = cardTypes.filter((cardType) =>
                cardType.name?.toLowerCase().includes(searchTerm.toLowerCase())
            );

            setSuggestions(items);
        } else {
            setSuggestions([]);
        }
    }, [searchTerm]);

    useEffect(() => {
        setSuggestions([]);
    }, [newCard?.cardToCardCategoryId]);

    const typesToShow =
        selectedStepId && pageIdentifier === PageIdentifier.WORKBOOK_INSIDE
            ? typesFromProcess
            : filteredTypes ||
              (newCard?.cardToCardCategoryId &&
                  getCardTypesFromCategory(
                      cardCategories?.find(
                          (category) =>
                              category.id === newCard.cardToCardCategoryId
                      )
                  ));

    return (
        <Box
            sx={{
                borderRight: 'solid 1px #ccc',
                borderLeft: 'solid 1px #ccc',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                    display: 'flex',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, fontSize: 16 }}
                >
                    {filteredTypes ? 'Card set types' : 'Card types'}
                </Typography>

                <Box
                    sx={{
                        position: 'relative',
                        display: 'inline-block',
                        margin: '4px 2px',
                        width: '30px',
                        verticalAlign: 'bottom',
                        pl: '20px',
                    }}
                    onClick={() => setSearchOpen(true)}
                >
                    <form action="/search" method="get">
                        <input
                            className="search"
                            id="searchleft"
                            type="search"
                            name="q"
                            placeholder="Search by name"
                            onChange={(event) =>
                                setSearchTerm(event.target.value)
                            }
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                }
                            }}
                            style={{
                                background: 'initial',
                                width: searchOpen ? '200px' : 0,
                                left: '45px',
                            }}
                        />
                        <label
                            className="button searchbutton"
                            style={{
                                background: 'initial',
                                color: 'rgb(158, 158, 158)',
                                left: '10px',
                                top: '-18px',
                            }}
                            htmlFor="searchleft"
                        >
                            <span className="mglass">&#9906;</span>
                        </label>
                    </form>
                </Box>
            </Box>

            {suggestions.length ? (
                <List
                    sx={{ width: '100%', overflowY: 'auto', flex: '1' }}
                    data-automation-id="card-type-list"
                >
                    {suggestions.sort(sortAlphabetically()).map((type) => {
                        const isSelected =
                            cardSetCopy?.cardSetToCardTypeId === type.id ||
                            newCard?.cardToCardTypeId === type.id ||
                            selectedType === type.id;
                        return (
                            <ListItem
                                key={type.id}
                                data-automation-id={type.id}
                                alignItems="center"
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        bgcolor: 'background.paper',
                                    },
                                    bgcolor: isSelected
                                        ? 'background.paper'
                                        : 'none',
                                    pointerEvents: disabled ? 'none' : 'auto',
                                }}
                                onClick={() => {
                                    if (setNewCard && newCard) {
                                        setNewCard({
                                            ...newCard,
                                            cardToCardTypeId: type.id,
                                            cardToCardCategoryId:
                                                type.cardCategoryID,
                                        });
                                    }
                                    setSelectedType && setSelectedType(type.id);
                                    setIsTypeSelected &&
                                        setIsTypeSelected(true);
                                }}
                            >
                                <ListItemAvatar
                                    sx={{
                                        marginTop: 0,
                                        minWidth: '20px',
                                        filter:
                                            (!isSelected &&
                                                cardTypeFromDrawer) ||
                                            (!isSelected &&
                                                cardSetToCardCategoryId)
                                                ? 'grayscale(100%)'
                                                : '',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            padding: 1,
                                            borderRadius: 1,
                                            background: getCategoryHex(
                                                type.cardCategoryID ??
                                                    newCard?.cardToCardCategoryId ??
                                                    ''
                                            ),
                                        }}
                                    >
                                        <Avatar
                                            categoryId={
                                                type.cardCategoryID ||
                                                newCard?.cardToCardCategoryId ||
                                                ''
                                            }
                                        />
                                    </Box>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        padding: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 0,
                                        color:
                                            (!isSelected &&
                                                cardTypeFromDrawer) ||
                                            (!isSelected &&
                                                cardSetToCardCategoryId)
                                                ? 'grey'
                                                : '',
                                    }}
                                    primary={type.name}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            ) : (
                typesToShow && (
                    <List
                        sx={{ width: '100%', overflowY: 'auto', flex: '1' }}
                        data-automation-id="card-type-list"
                    >
                        {typesToShow.sort(sortAlphabetically()).map((type) => {
                            const isSelected =
                                cardSetCopy?.cardSetToCardTypeId === type.id ||
                                newCard?.cardToCardTypeId === type.id ||
                                selectedType === type.id;
                            return (
                                <ListItem
                                    key={type.id}
                                    data-automation-id={type.id}
                                    alignItems="center"
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            bgcolor: 'background.paper',
                                        },
                                        bgcolor: isSelected
                                            ? 'background.paper'
                                            : 'none',
                                        pointerEvents: disabled
                                            ? 'none'
                                            : 'auto',
                                    }}
                                    onClick={() => {
                                        if (setNewCard && newCard) {
                                            setNewCard({
                                                ...newCard,
                                                cardToCardTypeId: type.id,
                                                cardToCardCategoryId:
                                                    type.cardCategoryID,
                                            });
                                        }
                                        setSelectedType &&
                                            setSelectedType(type.id);
                                        setIsTypeSelected &&
                                            setIsTypeSelected(true);
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            marginTop: 0,
                                            minWidth: '20px',
                                            filter:
                                                (!isSelected &&
                                                    cardTypeFromDrawer) ||
                                                (!isSelected &&
                                                    cardSetToCardCategoryId)
                                                    ? 'grayscale(100%)'
                                                    : '',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '40px',
                                                height: '40px',
                                                padding: 1,
                                                borderRadius: 1,
                                                background: getCategoryHex(
                                                    type.cardCategoryID ??
                                                        newCard?.cardToCardCategoryId ??
                                                        ''
                                                ),
                                            }}
                                        >
                                            <Avatar
                                                categoryId={
                                                    type.cardCategoryID ||
                                                    newCard?.cardToCardCategoryId ||
                                                    ''
                                                }
                                            />
                                        </Box>
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            padding: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: 0,
                                            color:
                                                (!isSelected &&
                                                    cardTypeFromDrawer) ||
                                                (!isSelected &&
                                                    cardSetToCardCategoryId)
                                                    ? 'grey'
                                                    : '',
                                        }}
                                        primary={type.name}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                )
            )}
        </Box>
    );
};

export default TypesColumn;
