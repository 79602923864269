import ReactDOM from 'react-dom';
import domtoimage from 'dom-to-image';

export const renderComponentAsImage = async (
    size: string,
    component?: React.ReactElement | React.ReactElement[]
) => {
    if (!component) {
        throw new Error('Component is undefined');
    }
    const container = document.createElement('div');
    container.style.width = size;
    container.style.height = size;
    document.body.appendChild(container);
    if (Array.isArray(component)) {
        component.forEach((element) => {
            ReactDOM.render(element, container);
        });
    } else {
        ReactDOM.render(component, container);
    }
    const image = await domtoimage.toPng(container);
    document.body.removeChild(container);
    return image;
};