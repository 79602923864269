import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { ReactComponent as AllProcessesSVG } from '../../../assets/images/fooda/OBSERVE-ORIENT-DECIDE-ACT.svg';
import { ReactComponent as DecideSVG } from '../../../assets/images/fooda/DECIDE.svg';
import { ReactComponent as OrientSVG } from '../../../assets/images/fooda/ORIENT.svg';
import { ReactComponent as ActSVG } from '../../../assets/images/fooda/ACT.svg';
import { ReactComponent as ObserveSVG } from '../../../assets/images/fooda/OBSERVE.svg';
import { ReactComponent as FrameSVG } from '../../../assets/images/fooda/FRAME.svg';
import { ProcessName } from '../Process';
import { SelectionType } from '../../../pages/workbooks/[pid]';
import { CardSet } from '../../../API';

const classToProcessMap: { [key: string]: ProcessName } = {
    orient: ProcessName.ORIENT,
    decide: ProcessName.DECIDE,
    act: ProcessName.ACT,
    observe: ProcessName.OBSERVE,
    frame: ProcessName.FRAME,
};

interface FoodaImageProps {
    selectionType: string | null;
    setSelectionType: React.Dispatch<React.SetStateAction<string | null>>;
    frameProgress?: number;
}

const FoodaImage = React.memo(
    ({ selectionType, setSelectionType, frameProgress }: FoodaImageProps) => {
        const [isAnimating, setIsAnimating] = useState(false);
        const [rotationKey, setRotationKey] = useState(0);
        const theme = useTheme();

        const getSVGComponent = () => {
            switch (selectionType) {
                case SelectionType.ACT:
                    return (
                        <ActSVG
                            width="600px"
                            color={theme.palette.background.default}
                        />
                    );
                case SelectionType.DECIDE:
                    return (
                        <DecideSVG
                            width="600px"
                            color={theme.palette.background.default}
                        />
                    );
                case SelectionType.OBSERVE:
                    return (
                        <ObserveSVG
                            width="600px"
                            color={theme.palette.background.default}
                        />
                    );
                case SelectionType.ORIENT:
                    return (
                        <OrientSVG
                            width="600px"
                            color={theme.palette.background.default}
                        />
                    );
                case SelectionType.FRAME:
                    return (
                        <FrameSVG
                            width="600px"
                            color={theme.palette.background.default}
                        />
                    );
                default:
                    return (
                        <AllProcessesSVG
                            width="600px"
                            color={theme.palette.background.default}
                        />
                    );
            }
        };

        useEffect(() => {
            if (selectionType) {
                setIsAnimating(true);
                setRotationKey((prevKey) => prevKey + 1);
                setTimeout(() => setIsAnimating(false), 1000);
            }
        }, [selectionType]);

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            if (isAnimating) return;

            const target = event.target as HTMLElement;
            const selector = Object.keys(classToProcessMap)
                .map((cls) => `.${cls}`)
                .join(', ');
            const closestElement = target.closest(
                selector
            ) as HTMLElement | null;

            if (closestElement) {
                for (const className in classToProcessMap) {
                    if (closestElement.classList.contains(className)) {
                        setSelectionType(classToProcessMap[className]);
                        break;
                    }
                }
            }
        };

        return (
            <Box sx={{ position: 'relative' }}>
                <motion.div
                    key={rotationKey}
                    onClick={handleClick}
                    initial={{ rotate: 0 }}
                    animate={
                        selectionType &&
                        [
                            'Act',
                            'Decide',
                            'Observe',
                            'Orient',
                            'Frame',
                        ].includes(selectionType)
                            ? { rotate: 360 }
                            : { rotate: 0 }
                    }
                    transition={{
                        duration: 1,
                        ease: 'easeInOut',
                    }}
                    style={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        marginLeft: -10,
                    }}
                >
                    {getSVGComponent()}
                </motion.div>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 150,
                        left: 140,
                        cursor: 'pointer',
                        width: '300px',
                        height: '300px',
                        borderRadius: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                    onClick={() => setSelectionType(ProcessName.FRAME)}
                >
                    <Box
                        position="relative"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: 50,
                        }}
                    >
                        <CircularProgress
                            variant="determinate"
                            value={100}
                            size={280}
                            thickness={1}
                            sx={{
                                color: 'rgba(181, 202, 242,0.7);',
                                position: 'absolute',
                            }}
                        />
                        <CircularProgress
                            variant="determinate"
                            value={frameProgress}
                            size={280}
                            thickness={1}
                            sx={{
                                color: theme.palette.primary.main,
                                position: 'absolute',
                            }}
                        />
                        <Typography
                            variant="caption"
                            sx={{
                                position: 'absolute',
                                top: 70,
                                color: theme.palette.primary.main,
                            }}
                        >
                            {frameProgress && Math.round(frameProgress)}%
                            completed
                        </Typography>
                    </Box>
                    <Typography
                        variant="h3"
                        sx={{
                            fontWeight: '500',
                            color:
                                selectionType === SelectionType.OBSERVE ||
                                selectionType === SelectionType.ORIENT ||
                                selectionType === SelectionType.DECIDE ||
                                selectionType === SelectionType.ACT
                                    ? 'rgba(85, 85, 85, 0.5)'
                                    : '#555555',
                        }}
                    >
                        FRAME
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color:
                                selectionType === SelectionType.OBSERVE ||
                                selectionType === SelectionType.ORIENT ||
                                selectionType === SelectionType.DECIDE ||
                                selectionType === SelectionType.ACT
                                    ? 'rgba(85, 85, 85, 0.5)'
                                    : '#555555',
                            maxWidth: '180px',
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            lineHeight: 1.4,
                        }}
                    >
                        Set your strategic purpose and direction
                    </Typography>
                </Box>
            </Box>
        );
    }
);

export default FoodaImage;
