import { Box, Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Card, PlaybookPageDataResponse } from '../../../API';
import { truncateText } from '../../../helpers/utils';
import { StyledEmptyItem } from '../../layouts/GridLayout';
import { useContext, useState } from 'react';
import { StyledModal } from '../../Modal';
import ModalContainer from '../../ModalContainer';
import CreateCard from '../../forms/CreateCard';
import { CardContext } from '../../cards/context';
import AnalysisModal from './AnalysisModal';
import { PagePreview } from '../../workbooks/WorkbookDrawerPage';
import { CardPage } from '../../cards/cardTypes';
import { UserPermissions, WorksheetSize } from '../../../globals';
import { OptionsValue } from './SwotGrid';

interface RetrospectiveProps {
    cards: Card[]; 
    size: WorksheetSize;
    workbookId?: string;
    page: PlaybookPageDataResponse | PagePreview | undefined;
    pptView?: boolean;
    slideNumber?: number;
    pageOptions?: any;
    selectedCard?: string | null;
    setSelectedCard?: React.Dispatch<React.SetStateAction<string | null>>;
    fullWidth?: boolean | undefined;
    addCardToWorkbook?: (card: Card, addToWorksheet: boolean) => Promise<void>;
}

const RetroCard = ({
    card,
    color,
    size,
    id,
    page,
    setSelectedCard,
    setCardPage,
    max,
    workbook,
    pptView,
    pageOptions,
}: {
    card: Card;
    color: string;
    size: WorksheetSize;
    id: string;
    page: PlaybookPageDataResponse | PagePreview | undefined;
    setSelectedCard?: (id: string | null) => void;
    setCardPage: (cardPage: CardPage) => void;
    max: number;
    workbook: boolean;
    pptView?: boolean;
    pageOptions?: any;
}) => {
    const scoreItem = {
        ...card.toScoreData?.items.find(
            (item) => item?.scoreDefinitionId === `${id}-S-1`
        ),
    };

    const scoreData = scoreItem?.data?.length
        ? scoreItem?.data[scoreItem?.data?.length - 1]
        : null;

    const includes: OptionsValue[] = pageOptions?.layout[0].include ?? null;
    const handleCardClick = (card: Card) => {
        if (setSelectedCard) {
            setSelectedCard(null);
            setTimeout(() => setSelectedCard(card.id), 500);
        }
    };
    return (
        <Box
            sx={{
                width: '100%',
                background: '#f0f2f5',
                borderRadius: size === WorksheetSize.SMALL ? '4px' : '10px',
                pb: '10px',
                mt: pptView ? 1 : 0,
                height: pptView ? '200px' : 'initial',
                transition: 'border 2s, transform 0.1s',
                borderColor: 'transparent',
                borderWidth: '3px',
                borderStyle: 'solid',
                ...(workbook && {
                    ':hover': {
                        borderColor: '#999',
                        transform: 'scale(0.94)',
                    },
                    '&:hover .card-header': {
                        borderRadius: '4px',
                    },
                }),
            }}
        >
            <Box
                sx={{
                    padding: pptView
                        ? '10px 15px'
                        : size === WorksheetSize.SMALL && !pptView
                        ? '4px'
                        : '5px 15px',
                    width: '100%',
                    background: color,
                    borderTopLeftRadius:
                        size === WorksheetSize.SMALL ? '4px' : '10px',
                    borderTopRightRadius:
                        size === WorksheetSize.SMALL ? '4px' : '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                }}
                {...(workbook && {
                    onClick: () => {
                        setCardPage(CardPage.DETAILS);
                        setSelectedCard && setSelectedCard(card.id);
                    },
                })}
                className="card-header"
            >
                <Typography
                    color="white"
                    sx={{
                        fontSize: pptView
                            ? '18px'
                            : size === WorksheetSize.SMALL && !pptView
                            ? '6px'
                            : '14px',
                        fontWeight: 'bold',
                    }}
                >
                    {truncateText(
                        card.name,
                        pptView
                            ? 18
                            : size === WorksheetSize.SMALL
                            ? 10
                            : size === WorksheetSize.CAROUSEL
                            ? 15
                            : 15
                    )}
                </Typography>
            </Box>
            <Box
                sx={{
                    padding: pptView
                        ? '10px'
                        : size === WorksheetSize.SMALL && !pptView
                        ? '5px'
                        : '15px',
                    height: pptView
                        ? '70px'
                        : size === WorksheetSize.SMALL && !pptView
                        ? '40px'
                        : '120px',
                    cursor: 'pointer',
                }}
                {...(workbook && {
                    onClick: () => {
                        setCardPage(CardPage.DETAILS);
                        handleCardClick(card);
                    },
                })}
            >
                <Typography
                    color="rgba(0, 0, 0, 0.87)"
                    sx={{
                        fontSize: pptView
                            ? '16px'
                            : size === WorksheetSize.SMALL && !pptView
                            ? '4px'
                            : '12px',
                    }}
                >
                    {truncateText(
                        card.description ?? '',
                        pptView
                            ? 70
                            : size === WorksheetSize.CAROUSEL && !pptView
                            ? 380
                            : 140
                    )}
                </Typography>
            </Box>
            {includes?.find((item) => item.value === 'votes')?.enabled && (
                <Box
                    sx={{
                        p: '10px 10px 0 10px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                    }}
                    {...(workbook && {
                        onClick: () => {
                            setCardPage(CardPage.SCORES);
                            setSelectedCard && setSelectedCard(card.id);
                        },
                    })}
                >
                    <Typography
                        color="rgba(0, 0, 0, 0.87)"
                        sx={{
                            fontSize: pptView
                                ? '18px'
                                : size === WorksheetSize.SMALL && !pptView
                                ? '6px'
                                : '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        Votes
                    </Typography>
                    <Box
                        sx={{
                            width: '100%',
                            borderRadius:
                                size === WorksheetSize.SMALL ? '10px' : '50px',
                            height: pptView
                                ? '30px'
                                : size === WorksheetSize.SMALL && !pptView
                                ? '5px'
                                : '20px',
                            background: '#cccccc',
                        }}
                    >
                        {scoreData && (
                            <Box
                                className="vote-score"
                                sx={{
                                    width: !scoreData.value
                                        ? 0
                                        : `${(100 / max) * scoreData.value}%`,
                                    borderRadius:
                                        size === WorksheetSize.SMALL
                                            ? '10px'
                                            : '50px',
                                    height: pptView
                                        ? '30px'
                                        : size === WorksheetSize.SMALL &&
                                          !pptView
                                        ? '5px'
                                        : '20px',
                                    background: '#959595',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    paddingRight: '10px',
                                    border: 'solid 1px #888888',
                                }}
                            >
                                {size !== WorksheetSize.SMALL && (
                                    <Typography
                                        sx={{
                                            fontSize: pptView ? '20px' : '12px',
                                            fontWeight: 'bold',
                                            color: '#ffffff',
                                        }}
                                    >
                                        {scoreData.value}
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const Retrospective = ({
    cards,
    size,
    workbookId,
    page,
    pptView,
    slideNumber,
    pageOptions,
    selectedCard,
    setSelectedCard,
    fullWidth,
    addCardToWorkbook,
}: RetrospectiveProps) => {
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [cardPage, setCardPage] = useState<CardPage>(CardPage.DETAILS);
    const { permissions, handleClose } = useContext(CardContext);

    const retrospectives = [
        { title: 'What Worked', id: '13', color: '#018035' },
        { title: "What didn't work ", id: '14', color: '#ff6f00' },
        { title: 'What we can improve', id: '15', color: '#1668f7' },
        { title: 'To do', id: '16', color: '#f7e300' },
    ];
    const startIndex = (slideNumber && (slideNumber - 1) * 2) || 0;
    const filteredRetrospectives =
        !pptView || (pptView && !slideNumber)
            ? retrospectives
            : retrospectives.slice(startIndex, startIndex + 2);
    const getMaxScore = (id: string) => {
        const scoreCards = cards
            .filter((card) => card.cardToCardTypeId === id)
            .map((card) => {
                const scoreItem = {
                    ...card.toScoreData?.items.find(
                        (item) => item?.scoreDefinitionId === `${id}-S-1`
                    ),
                };

                const scoreData = scoreItem?.data?.length
                    ? scoreItem?.data[scoreItem?.data?.length - 1]
                    : null;

                return scoreData?.value ?? 0;
            });

        return Math.max(...scoreCards);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: pptView ? 2 : 0,
                minHeight: pptView ? '700px' : 'initial',
            }}
        >
            {filteredRetrospectives.map((retrospective) => {
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            variant="h5"
                            sx={{
                                mt: '20px',
                                mb: '10px',
                                fontSize:
                                    size === WorksheetSize.SMALL
                                        ? '0.8rem'
                                        : '1.5rem',
                            }}
                        >
                            {retrospective.title}
                        </Typography>
                        <Box
                            sx={{
                                minHeight:
                                    size === WorksheetSize.SMALL
                                        ? '25px'
                                        : '100px',
                            }}
                        >
                            <Grid container spacing={1}>
                                {cards
                                    .filter(
                                        (card) =>
                                            card.cardToCardTypeId ===
                                            retrospective.id
                                    )
                                    .map((card) => {
                                        return (
                                            <Grid item xs={12} md={3}>
                                                <RetroCard
                                                    workbook={!!workbookId}
                                                    page={page}
                                                    id={retrospective.id}
                                                    card={card}
                                                    color={retrospective.color}
                                                    {...(setSelectedCard && {
                                                        setSelectedCard,
                                                    })}
                                                    setCardPage={setCardPage}
                                                    size={size}
                                                    max={getMaxScore(
                                                        retrospective.id
                                                    )}
                                                    pptView={pptView}
                                                    pageOptions={pageOptions}
                                                />
                                            </Grid>
                                        );
                                    })}
                                {!!workbookId &&
                                    permissions?.includes(
                                        UserPermissions.EDIT
                                    ) && (
                                        <Grid
                                            item
                                            xs={12}
                                            md={3}
                                            sx={{
                                                minHeight: !!workbookId
                                                    ? '169px'
                                                    : 'inherit',
                                            }}
                                        >
                                            <StyledEmptyItem
                                                height="100%"
                                                sx={{ width: '100%' }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        height: '100%',
                                                        ml: 0,
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            setSelectedType(
                                                                retrospective.id
                                                            )
                                                        }
                                                    >
                                                        <AddIcon
                                                            sx={{
                                                                fontSize:
                                                                    '3rem',
                                                                width: '40px',
                                                                height: '40px',
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </StyledEmptyItem>
                                        </Grid>
                                    )}
                            </Grid>
                        </Box>
                    </Box>
                );
            })}
            {!!workbookId && (
                <StyledModal
                    key="modal"
                    open={!!selectedType}
                    onClose={() => setSelectedType(null)}
                >
                    <Box>
                        {!!selectedType && (
                            <ModalContainer sx={{ maxWidth: '35rem' }}>
                                <CreateCard
                                    cardTypeFromDrawer={selectedType}
                                    cardSet={workbookId}
                                    handleClose={() => {
                                        setSelectedType(null);
                                        handleClose();
                                    }}
                                    close={() => handleClose()}
                                />
                            </ModalContainer>
                        )}
                    </Box>
                </StyledModal>
            )}
            {selectedCard && cards.find((card) => card.id === selectedCard) && (
                <AnalysisModal
                    card={
                        cards.find((card) => card.id === selectedCard) ?? null
                    }
                    handleClose={() => {
                        setSelectedCard && setSelectedCard(null);
                        setCardPage(CardPage.DETAILS);
                    }}
                    cardPage={cardPage}
                    fullWidth={fullWidth}
                    setSelectedCard={setSelectedCard}
                />
            )}
        </Box>
    );
};

export default Retrospective;
