import {
    Box,
    Button,
    ButtonBase,
    Grid,
    Menu,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import PrimaryFilterColumn from './PrimaryFilterColumn';
import { FilterOptions, PageIdentifier } from '../cardTypes';
import SecondaryFilterColumn from './SecondaryFilterColumn';
import useFilterCards, {
    FilterCards as FilterCardsType,
} from '../../../hooks/useFilterCards';
import { AppContext } from '../../contexts';
import TypeList from './secondary-filters/TypeList';

interface FilterCardsProps {
    anchorEl: HTMLElement;
    handleClose: () => void;
    filterCriteria?: FilterCardsType;
    setFilterCriteria?: React.Dispatch<React.SetStateAction<FilterCardsType>>;
    pageIdentifier: string;
}

const FilterCards = ({
    anchorEl,
    handleClose,
    filterCriteria,
    setFilterCriteria,
    pageIdentifier,
}: FilterCardsProps) => {
    const theme = useTheme();
    const { applyFilter, setApplyFilter } = useContext(AppContext);
    const title =
        pageIdentifier === PageIdentifier.CARD_SET_INSIDE ||
        pageIdentifier === PageIdentifier.WORKBOOK_INSIDE
            ? 'cards'
            : pageIdentifier ===
              PageIdentifier.CONNECT_WORKBOOK_TO_PLAYBOOK_FORM
            ? 'workbooks'
            : pageIdentifier;
    const [selectedPrimaryFilter, setSelectedPrimaryFilter] =
        useState<FilterOptions | null>(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [checkedCategories, setCheckedCategories] = useState<string[]>([]);
    const displayLastColumn = selectedPrimaryFilter === FilterOptions.CARD_TYPE;
    const [localFilters, setLocalFilters] = useState(filterCriteria);
    const [checked, setChecked] = useState<Record<FilterOptions, boolean>>({
        [FilterOptions.CARD_TYPE]: (filterCriteria?.cardType?.length ?? 0) > 0,
        [FilterOptions.CARD_SET]: (filterCriteria?.cardSet?.length ?? 0) > 0,
        [FilterOptions.WORKBOOK]: (filterCriteria?.workbook?.length ?? 0) > 0,
        [FilterOptions.OWNER]: (filterCriteria?.owner?.length ?? 0) > 0,
    });

    useEffect(() => {
        setChecked({
            [FilterOptions.CARD_TYPE]:
                (localFilters?.cardType?.length ?? 0) > 0,
            [FilterOptions.CARD_SET]: (localFilters?.cardSet?.length ?? 0) > 0,
            [FilterOptions.WORKBOOK]: (localFilters?.workbook?.length ?? 0) > 0,
            [FilterOptions.OWNER]: (localFilters?.owner?.length ?? 0) > 0,
        });
    }, [localFilters]);

    const handleFilter = () => {
        if (localFilters && setFilterCriteria) {
            setFilterCriteria(localFilters);
        }

        setApplyFilter(!applyFilter);
        handleClose();
    };
    const handleClear = () => {
        setLocalFilters({
            cardType: [],
            cardSet: [],
            workbook: [],
            owner: [],
        });
    };

    return (
        <Menu
            aria-labelledby="menu-button"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: `1px 1px 1px 1px ${theme.palette.background.paper}, 
            1px -1px 1px 1px ${theme.palette.background.paper}`,
                    backgroundColor: theme.palette.background.default,
                    marginTop: '-25px',
                    marginLeft: '-75px',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // px: '1.5rem',
                    py: '1.2rem',
                    width: '40rem',
                    height: '25rem',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: '1.5rem',
                    }}
                >
                    <Typography variant="h5" sx={{ mb: 2, ml: 1 }}>
                        Filter {title.replace(/_/g, ' ')}
                    </Typography>
                    <ButtonBase onClick={handleClear} disableRipple>
                        <Typography
                            sx={{
                                cursor: 'pointer',
                                color: `${theme.palette.primary.main}`,
                            }}
                            variant="body2"
                        >
                            Clear all
                        </Typography>
                    </ButtonBase>
                </Box>

                <Grid container spacing={0} sx={{ height: '100%' }}>
                    <Grid item xs={12} md={3} sx={{ height: '17rem' }}>
                        <PrimaryFilterColumn
                            checked={checked}
                            selectedPrimaryFilter={selectedPrimaryFilter}
                            setSelectedPrimaryFilter={setSelectedPrimaryFilter}
                            pageIdentifier={pageIdentifier}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={displayLastColumn ? 4 : 6}
                        sx={{ height: '17rem', overflowY: 'hidden' }}
                    >
                        <SecondaryFilterColumn
                            selectedPrimaryFilter={selectedPrimaryFilter}
                            localFilters={localFilters}
                            setLocalFilters={setLocalFilters}
                            selectedCategoryId={selectedCategoryId}
                            setSelectedCategoryId={setSelectedCategoryId}
                        />
                    </Grid>
                    {displayLastColumn && (
                        <Grid item xs={12} md={5} sx={{ height: '17rem' }}>
                            <TypeList
                                selectedCategoryId={selectedCategoryId}
                                localFilters={localFilters}
                                setLocalFilters={setLocalFilters}
                            />
                        </Grid>
                    )}
                </Grid>

                <Button
                    onClick={handleFilter}
                    variant="contained"
                    sx={{
                        mt: 2,
                        mr: '1.5rem',
                        px: 3,
                        width: 'fit-content',
                        borderRadius: 1,
                        alignSelf: 'flex-end',
                    }}
                >
                    Apply
                </Button>
            </Box>
        </Menu>
    );
};

export default FilterCards;
