import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FilterOptions, WorksheetFilterOptions } from '../cardTypes';
import OwnerList from '../FilterCards/secondary-filters/OwnerList';
import { FilterCards } from '../../../hooks/useFilterCards';
import { generateClient } from 'aws-amplify/api';
import { getUserOrganisation } from '../../../helpers/auth';
import { cardSetsOrderedByTypeAndUpdated } from '../../../graphql/queries';
import { CardSet, CardSetsOrderedByTypeAndUpdatedQuery } from '../../../API';
import { AppContext } from '../../contexts';
import DateFilter from './secondary-filters/DateFilter';
import { SecondaryWorksheetFilterOptions } from '.';

interface SecondaryFilterColumnProps {
    selectedPrimaryFilter: string | null;
    selectedSecondaryFilter: SecondaryWorksheetFilterOptions;
    setSelectedSecondaryFilter: React.Dispatch<
        React.SetStateAction<SecondaryWorksheetFilterOptions>
    >;
}

const SecondaryFilterColumn = ({
    selectedPrimaryFilter,
    selectedSecondaryFilter,
    setSelectedSecondaryFilter,
}: SecondaryFilterColumnProps) => {
    return (
        <Box
            sx={{
                height: '100%',
                overflowY: 'scroll',
            }}
        >
            {selectedPrimaryFilter != null &&
                {
                    [WorksheetFilterOptions.TOP_THREE]: (
                        <Typography
                            variant="h6"
                            sx={{ margin: '40px', textAlign: 'center' }}
                        >
                            Show cards with the highest score within their
                            respective category
                        </Typography>
                    ),

                    [WorksheetFilterOptions.OWNER]: (
                        <OwnerList
                            localFilters={selectedSecondaryFilter}
                            setLocalFilters={(state) =>
                                setSelectedSecondaryFilter(
                                    state as SecondaryWorksheetFilterOptions
                                )
                            }
                            title="Select card owner(s)"
                        />
                    ),
                    [WorksheetFilterOptions.CREATED_DATE]: (
                        <DateFilter
                            selectedSecondaryFilter={selectedSecondaryFilter}
                            setSelectedSecondaryFilter={
                                setSelectedSecondaryFilter
                            }
                        />
                    ),
                }[selectedPrimaryFilter]}
        </Box>
    );
};

export default SecondaryFilterColumn;
