import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { worksheets } from '../worksheets';
import {
    WorksheetSetting,
    getWorksheetThumbnail,
} from '../../../helpers/worksheets';
import { WorksheetSelectorFieldProps } from './inputFieldTypes';
import { useEffect, useState } from 'react';
import { processSteps } from '../processSteps';
import { SelectionType } from '../../../pages/workbooks/[pid]';

const WorksheetSelector = (props: WorksheetSelectorFieldProps) => {
    const theme = useTheme();
    const { workbookForm, selectionType } = props;
    const [availableWorksheets, setAvailableWorksheets] = useState<
        WorksheetSetting[] | []
    >([]);
    const Avatar = ({ worksheet }: { worksheet: string }) => {
        const WorksheetIcon = getWorksheetThumbnail(worksheet);
        if (!WorksheetIcon) {
            console.error(`Icon not found for worksheet: ${worksheet}`);
            return null;
        }
        return (
            <Box
                component="img"
                src={WorksheetIcon as string}
                sx={{
                    width: '80%',
                }}
            ></Box>
        );
    };

    useEffect(() => {
        const selectedProcess = processSteps.find(
            (item) => item.name.toLowerCase() === selectionType?.toLowerCase()
        );

        const relevantCardTypes =
            selectedProcess?.steps?.flatMap((step) => step.cardTypes) || [];

        const filteredWorksheets = worksheets.filter(
            (worksheet) =>
                worksheet.auto.some((autoItem) =>
                    relevantCardTypes.includes(autoItem)
                ) &&
                worksheet.t === 'Chart' &&
                worksheet.e
        );

        const relevantWorksheets =
            selectionType === SelectionType.WORKSHEETS
                ? worksheets.filter(
                      (worksheet) => worksheet.t === 'Chart' && worksheet.e
                  )
                : filteredWorksheets;

        setAvailableWorksheets(relevantWorksheets);
    }, [worksheets]);

    const renderWorksheetBox = (worksheet: WorksheetSetting) => {
        const isSelectable = !props.loading;
        const boxContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: isSelectable ? 'pointer' : 'default',
                    border:
                        props.selectValue === worksheet.i
                            ? `3px solid ${theme.palette.primary.main}`
                            : 'inherit',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    px: 1,
                    textAlign: 'center',
                    color: isSelectable
                        ? 'inherit'
                        : theme.palette.text.disabled,
                    '&:hover': {
                        border:
                            isSelectable && !workbookForm
                                ? `3px solid ${theme.palette.primary.main}`
                                : props.selectValue === worksheet.i
                                ? `3px solid ${theme.palette.primary.main}`
                                : 'inherit',
                    },
                }}
                onClick={() => {
                    if (isSelectable) {
                        props.onChange &&
                            props.onChange({
                                target: { value: worksheet.i },
                            });
                    }
                }}
            >
                <Box sx={{ opacity: isSelectable ? 1 : 0.5 }}>
                    <Avatar worksheet={worksheet.n} />
                </Box>
                <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', my: 0.5 }}
                >
                    {worksheet.n}
                </Typography>
                <Typography variant="body2">{worksheet.d}</Typography>
            </Box>
        );
        return boxContent;
    };

    return (
        <Box
            sx={{
                mb: 2,
                pr: 1,
                height: workbookForm ? '200px' : '100%',
                overflow: 'auto',
            }}
        >
            <Grid container spacing={1} sx={{ height: '100%' }}>
                {availableWorksheets.map((worksheet: any) => (
                    <Grid item md={4} key={worksheet.i}>
                        {renderWorksheetBox(worksheet)}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default WorksheetSelector;
