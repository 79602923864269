import {
    Box,
    Tooltip,
    Typography,
    useTheme,
    alpha,
    IconButton,
    Theme,
    SxProps,
} from '@mui/material';
import { dateStringFromISO, truncateText } from '../../../helpers/utils';
import TableLayout from '../../layouts/TableLayout';
import { CardComponentProps, CardComponentType } from '../cardTypes';
import {
    gridClasses,
    GridColDef,
    GridRenderCellParams,
} from '@mui/x-data-grid';
import UserAvatar from '../../profile/UserAvatar';
import { renderIconByComponentType } from '../views/CardListView';
import WorksheetsCell from './WorksheetsCell';
import AddIcon from '@mui/icons-material/Add';
import { Card, CardSet, Playbook, Relationship, Worksheet } from '../../../API';
import { Category } from '../../../helpers/category';
import { useContext, useState } from 'react';
import { AppContext } from '../../contexts';
import { getWorksheetBackgroundColor } from '../../../helpers/worksheets';
import { ReactComponent as MenuIcon } from '../../../assets/icons/Menu.svg';
import CardMenu from '../CardMenu';
import { MenuData } from './RelationshipGridView';
import { UserPermissions } from '../../../globals';

interface RelationshipListViewProps {
    title: string;
    items: (Relationship | Card | CardSet | Playbook | Worksheet)[] | undefined;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    colorFn?: (id: string) => string;
    cardComponentType: CardComponentType;
    cardId: string;
    addAction?: () => void;
    copiedCardIds?: string[] | undefined;
    actionLabel?: string;
    card: Playbook | Card | CardSet;
    workbooksOfPlaybook: CardSet[];
    permissions: UserPermissions[] | undefined;
    handleRemoveCardSet: (cardSet: CardSet) => Promise<void>;
    handleRemoveWorkbook: (cardSet: CardSet) => Promise<void>;
    handleRemoveCard: (item: Card) => void;
}

const RelationshipListView = ({
    title,
    items,
    Icon,
    colorFn,
    cardComponentType,
    cardId,
    addAction,
    copiedCardIds,
    actionLabel,
    card,
    workbooksOfPlaybook,
    permissions,
    handleRemoveCardSet,
    handleRemoveWorkbook,
    handleRemoveCard,
}: RelationshipListViewProps) => {
    const theme = useTheme();
    const { cardTypeObject } = useContext(AppContext);
    const [menuData, setMenuData] = useState<MenuData>({
        anchor: null,
        item: null,
    });
    const setMenu = (e: any, clickedItem?: Card | CardSet | Playbook) => {
        if (e) {
            e.stopPropagation();
            setMenuData({
                anchor: e.currentTarget,
                item: clickedItem || null,
            });
        } else {
            setMenuData({ anchor: null, item: null });
        }
    };
    const tableStyle: SxProps<Theme> = {
        height: '100%',
        '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 800,
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiDataGrid-columnHeaders': {
            borderRight: 'none',
        },
        '& .MuiDataGrid-columnHeader': {
            borderRight: 'none',
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingRight: 0,
        },
        '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto',
        },
        '.MuiDataGrid-columnHeaders': {
            backgroundColor: 'background.paper',
        },
        '& .MuiCheckbox-root': {
            color: 'grey',
        },
        '& .MuiCheckbox-root.Mui-checked': {
            color: theme.palette.primary.main,
        },
        [`.${gridClasses.row}.even:not(.selected)`]: {
            backgroundColor: (theme: Theme) =>
                alpha(theme.palette.background.paper, 0.3),
        },
        '& .selected': {
            color: '#000',
            backgroundColor: 'grey.200',
            '&:hover': {
                backgroundColor: 'grey.200',
            },
        },
        '.MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
        },
    };

    const ownerColumn: GridColDef = {
        field: 'owner',
        headerName: 'Owner',
        hideable: true,
        sortable: true,
        width: 150,
        renderCell: (params) => {
            return (
                <Box sx={{ py: 0.5 }}>
                    <UserAvatar
                        userId={params?.row?.owner}
                        isProfile={false}
                        smallAvatar
                    />
                </Box>
            );
        },
    };

    let columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            sortable: true,
            width: 250,
            renderCell: (
                params: GridRenderCellParams<any, CardComponentProps>
            ) => {
                const { data } = getWorksheetBackgroundColor(
                    params?.row,
                    cardTypeObject
                );

                return (
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', py: 0.5 }}
                    >
                        {renderIconByComponentType(
                            cardComponentType,
                            (params?.row?.cardToCardCategoryId as Category) ||
                                data.category,
                            colorFn
                        )}
                        {params.value && typeof params?.value === 'string' ? (
                            <Tooltip title={params?.value}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        ml: '2px',
                                    }}
                                >
                                    {truncateText(params?.value, 20)}
                                </Typography>
                            </Tooltip>
                        ) : (
                            ''
                        )}
                    </Box>
                );
            },
        },
    ];

    if (cardComponentType === CardComponentType.CARD) {
        columns.push(
            {
                field: 'toCardType',
                headerName: 'Type',
                sortable: true,
                width: 130,
                valueGetter: (params:Card) => {
                    return params.name || 'Varied';
                },
            },
            {
                field: 'toCardCategory',
                headerName: 'Category',
                sortable: true,
                width: 130,
                valueGetter: (params:Card) => {
                    return params.name || 'Varied';
                },
            },
            ownerColumn,
            {
                field: 'createdAt',
                headerName: 'Created on',
                sortable: true,
                width: 150,
                valueFormatter: (params) => dateStringFromISO(params),
            },
            {
                field: 'copy',
                headerName: '',
                sortable: true,
                width: 60,
                valueGetter: (params) => {
                    return copiedCardIds?.includes(params) ? 'Copy' : '';
                },
            }
        );
    } else if (cardComponentType === CardComponentType.CARD_SET) {
        columns.push(ownerColumn, {
            field: 'createdAt',
            headerName: 'Created on',
            sortable: true,
            width: 150,
            valueFormatter: (params) => dateStringFromISO(params),
        });
    } else if (cardComponentType === CardComponentType.WORKBOOK) {
        columns.push(ownerColumn);

        if (card.__typename === 'Card') {
            columns.push({
                field: '',
                headerName: 'Worksheets',
                hideable: true,
                sortable: false,
                width: 250,
                renderCell: (params) => (
                    <WorksheetsCell
                        workbookId={params?.row?.id}
                        cardId={cardId}
                    />
                ),
            });
        }

        columns.push(
            {
                field: 'createdAt',
                headerName: 'Created on',
                sortable: true,
                width: 150,
                valueFormatter: (params) => dateStringFromISO(params),
            },
            {
                field: 'processProgress',
                headerName: 'Completeness',
                sortable: true,
                width: 130,
                valueGetter: (params) => {
                    const processProgress: [] = params ?? [];

                    if (
                        !Array.isArray(processProgress) ||
                        processProgress.length === 0
                    ) {
                        return '0%';
                    }

                    const totalProgress = processProgress.reduce(
                        (
                            sum,
                            stage: {
                                stageId: string;
                                progress: number;
                            }
                        ) => {
                            const stageProgress = Number(stage.progress);
                            return !isNaN(stageProgress)
                                ? sum + stageProgress
                                : sum;
                        },
                        0
                    );

                    const numberOfStages = processProgress.length;

                    const overallProgress =
                        numberOfStages > 0 ? totalProgress / numberOfStages : 0;

                    const cappedProgress = !isNaN(overallProgress)
                        ? Math.min(Math.max(overallProgress, 0), 100)
                        : 0;

                    return `${cappedProgress.toFixed(0)}%`;
                },
            }
        );
    } else if (cardComponentType === CardComponentType.PLAYBOOK) {
        columns.push(
            {
                field: 'owner',
                headerName: 'Owner',
                hideable: true,
                sortable: true,
                width: 150,
                renderCell: (
                    params: GridRenderCellParams<CardComponentProps>
                ) => {
                    return (
                        <UserAvatar
                            userId={params?.row?.data?.owner}
                            isProfile={false}
                            smallAvatar
                        />
                    );
                },
            },
            {
                field: 'createdAt',
                headerName: 'Created on',
                sortable: true,
                width: 150,
                valueFormatter: (params) => dateStringFromISO(params),
            },
            {
                field: 'createdBy',
                headerName: 'Added by',
                hideable: true,
                sortable: true,
                width: 150,
                renderCell: (
                    params: GridRenderCellParams<any, CardComponentProps>
                ) => {
                    return (
                        <UserAvatar
                            userId={
                                params?.row?.toSourcePlaybookPage?.toPlaybook
                                    ?.owner
                            }
                            isProfile={false}
                            smallAvatar
                        />
                    );
                },
            }
        );
    } else if (cardComponentType === CardComponentType.WORKSHEET) {
        columns.push({
            field: 'parentId',
            headerName: 'Workbook',
            hideable: true,
            sortable: true,
            width: 150,
            renderCell: (
                params: GridRenderCellParams<any, CardComponentProps>
            ) => {
                return (
                    <Typography variant="body2" sx={{ pt: 0.5 }}>
                        {
                            workbooksOfPlaybook?.find(
                                (item) => item.id === params?.row?.parentId
                            )?.name
                        }
                    </Typography>
                );
            },
        });
    }
    if (
        cardComponentType === CardComponentType.CARD ||
        cardComponentType === CardComponentType.CARD_SET ||
        (cardComponentType === CardComponentType.WORKBOOK &&
            card.__typename === 'Playbook')
    ) {
        columns.unshift({
            field: 'control',
            headerName: '',
            width: 20,
            sortable: false,
            disableColumnMenu: true,

            renderCell: (params) => {
                const isWorkbook =
                    params?.row?.__typename === 'CardSet' &&
                    params?.row?.type === 'WB';
                const isCardSet =
                    params?.row?.__typename === 'CardSet' &&
                    params?.row?.type === 'CS';
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <MenuIcon
                            width={30}
                            height={30}
                            color="#808080"
                            onClick={(
                                event: React.MouseEvent<
                                    SVGSVGElement,
                                    MouseEvent
                                >
                            ) => {
                                event.stopPropagation();
                                setMenu(event, params?.row);
                            }}
                        />
                        {menuData.anchor && (
                            <CardMenu
                                permissions={permissions ?? []}
                                anchor={menuData.anchor}
                                data={{
                                    organisation:
                                        menuData?.item[0]?.organisation || '',
                                    id: menuData.item?.id,
                                }}
                                menuItems={[
                                    {
                                        text: isWorkbook
                                            ? `Remove workbook`
                                            : isCardSet
                                            ? 'Remove card from card set'
                                            : 'Remove card',
                                        action: () => {
                                            if (isWorkbook) {
                                                handleRemoveWorkbook(
                                                    menuData.item
                                                );
                                            } else if (isCardSet) {
                                                handleRemoveCardSet(
                                                    menuData.item
                                                );
                                            } else {
                                                handleRemoveCard(menuData.item);
                                            }
                                        },
                                    },
                                ]}
                                setMenu={setMenu}
                            />
                        )}
                    </Box>
                );
            },
        });
        columns.push({
            field: 'updatedAt',
            headerName: 'Last modified date',
            sortable: false,
            width: 150,
            valueFormatter: (params) => dateStringFromISO(params),
        });
    }
    return (
        <Box sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {addAction && (
                        <Tooltip placement="top" title={actionLabel}>
                            <IconButton color="primary" onClick={addAction}>
                                <AddIcon
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Box
                        sx={{
                            background: theme.palette.background.paper,
                            height: '30px',
                            width: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '6px',
                        }}
                    >
                        <Typography variant="body1">{items?.length}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    py: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative',
                    zIndex: 1200,
                }}
            >
                {items && items.length > 0 ? (
                    <Box
                        sx={{
                            maxHeight: '200px',
                            overflow: 'auto',
                            width: '100%',
                        }}
                    >
                        <TableLayout
                            columns={columns}
                            items={items}
                            cardComponentType={CardComponentType.CARD}
                            tableStyle={tableStyle}
                        />
                    </Box>
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        No items
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default RelationshipListView;
