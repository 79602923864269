import { Typography, useTheme } from '@mui/material';
import { StyledSection } from '../../StyledElements';
import { StyledTextField } from '../../forms/FormElements';
import { AttributeType, Card, CardSet } from '../../../API';
import TextField from '../../forms/input-fields/TextField';
import React from 'react';

interface MainProps {
    selectedCard?: Card | CardSet | null;
    cardObject?: Card | CardSet | null;
    edit?: boolean;
    setCardObject: (card: Card | CardSet | null) => void;
    isCard?: boolean;
    selectedType?: string;
    worksheetCard?: any;
}
const Main = ({
    selectedCard,
    edit,
    cardObject,
    setCardObject,
    isCard,
    selectedType,
    worksheetCard,
}: MainProps) => {
    const theme = useTheme();
    return (
        <>
            {selectedCard ? (
                <>
                    <StyledSection
                        sx={{
                            border:
                                selectedType === 'name'
                                    ? 'solid 2px blue'
                                    : 'inherit',
                        }}
                    >
                        <Typography
                            variant="h6"
                            fontSize={worksheetCard ? '1.15em' : '1.25em'}
                        >
                            Name
                        </Typography>

                        <Typography
                            variant="body2"
                            fontSize="0.875em"
                            style={{
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                whiteSpace: 'normal',
                            }}
                        >
                            {selectedCard?.name}
                        </Typography>
                    </StyledSection>

                    <StyledSection>
                        <Typography
                            variant="h6"
                            fontSize={worksheetCard ? '1.15em' : '1.25em'}
                        >
                            Description
                        </Typography>

                        <Typography variant="body2" fontSize="0.875em">
                            {selectedCard?.description}
                        </Typography>
                    </StyledSection>
                </>
            ) : (
                <>
                    <StyledSection
                        {...(selectedType === 'name' && {
                            sx: {
                                padding: '10px',
                                border: `solid 2px ${theme.palette.primary.main}`,
                            },
                        })}
                    >
                        {!edit || !cardObject ? (
                            <>
                                <Typography
                                    variant="h6"
                                    fontSize={
                                        worksheetCard ? '1.15em' : '1.25em'
                                    }
                                >
                                    Name
                                </Typography>
                                <Typography
                                    variant="body2"
                                    fontSize="0.875em"
                                    style={{
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    {cardObject?.name}
                                </Typography>
                            </>
                        ) : (
                            <TextField
                                onChange={(event) =>
                                    setCardObject({
                                        ...cardObject,
                                        name: event.target.value,
                                    })
                                }
                                type={AttributeType.Text}
                                name="name"
                                label="Name"
                                required={true}
                                value={cardObject.name}
                                size="small"
                            />
                        )}
                    </StyledSection>

                    <StyledSection
                        {...(selectedType === 'description' && {
                            sx: {
                                padding: '10px',
                                border: `solid 2px ${theme.palette.primary.main}`,
                            },
                        })}
                    >
                        {!edit || !cardObject ? (
                            <>
                                <Typography
                                    variant="h6"
                                    fontSize={
                                        worksheetCard ? '1.15em' : '1.25em'
                                    }
                                >
                                    Description
                                </Typography>
                                <Typography variant="body2" fontSize="0.875em">
                                    {cardObject?.description
                                        ? cardObject?.description
                                        : 'No value'}
                                </Typography>
                            </>
                        ) : (
                            <TextField
                                onChange={(event) =>
                                    setCardObject({
                                        ...cardObject,
                                        description: event.target.value,
                                    })
                                }
                                type={AttributeType.Text}
                                value={cardObject.description ?? ''}
                                name="description"
                                label="Description"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    </StyledSection>
                </>
            )}
        </>
    );
};

export default Main;
