/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type DeleteCardInput = {
  organisation: string,
  id: string,
};

export type ModelCardConditionInput = {
  owner?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  orgEdit?: ModelStringInput | null,
  name?: ModelStringInput | null,
  capitalName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  deleteAfter?: ModelIntInput | null,
  _auditContextType?: ModelEventContextInput | null,
  _auditContextId?: ModelStringInput | null,
  createdFromId?: ModelIDInput | null,
  createdFromIds?: ModelIDInput | null,
  and?: Array< ModelCardConditionInput | null > | null,
  or?: Array< ModelCardConditionInput | null > | null,
  not?: ModelCardConditionInput | null,
  cardTypeToCardsId?: ModelIDInput | null,
  cardToCardCategoryId?: ModelIDInput | null,
  cardToCardTypeId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelEventContextInput = {
  eq?: EventContext | null,
  ne?: EventContext | null,
};

export enum EventContext {
  CardManager = "CardManager",
  CardSetManager = "CardSetManager",
  WorkbookManager = "WorkbookManager",
  PlaybookManager = "PlaybookManager",
  CardSet = "CardSet",
  Workbook = "Workbook",
  Worksheet = "Worksheet",
  WorksheetDirect = "WorksheetDirect",
  Playbook = "Playbook",
  ManageDeletedCards = "ManageDeletedCards",
  System = "System",
  Unknown = "Unknown",
}


export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Card = {
  __typename: "Card",
  organisation: string,
  id: string,
  owner?: string | null,
  createdBy?: string | null,
  editors?: Array< string > | null,
  orgEdit?: string | null,
  name: string,
  capitalName?: string | null,
  toCardCategory: CardCategory,
  description?: string | null,
  attributes?:  Array<Attribute | null > | null,
  toAttributes?: ModelCardAttributeConnection | null,
  toScoreData?: ModelScoreDataConnection | null,
  toCardType: CardType,
  toCardSets?: ModelCardsCardSetsConnection | null,
  createdAt: string,
  updatedAt: string,
  updatedBy?: string | null,
  deleteAfter?: number | null,
  _auditContextType?: EventContext | null,
  _auditContextId?: string | null,
  createdFromId?: string | null,
  createdFromIds?: Array< string | null > | null,
  cardTypeToCardsId?: string | null,
  cardToCardCategoryId: string,
  cardToCardTypeId: string,
};

export type CardCategory = {
  __typename: "CardCategory",
  id: string,
  name: string,
  description?: string | null,
  toCardTypes?: ModelCardTypeConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelCardTypeConnection = {
  __typename: "ModelCardTypeConnection",
  items:  Array<CardType | null >,
  nextToken?: string | null,
};

export type CardType = {
  __typename: "CardType",
  id: string,
  name?: string | null,
  cardCategoryID: string,
  toCards?: ModelCardConnection | null,
  attributeDefinitions?:  Array<AttributeDefinition | null > | null,
  scoreDefinitions:  Array<ScoreDefinition >,
  defaultScoreDefinitionId?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelCardConnection = {
  __typename: "ModelCardConnection",
  items:  Array<Card | null >,
  nextToken?: string | null,
};

export type AttributeDefinition = {
  __typename: "AttributeDefinition",
  id: string,
  name: string,
  attributeType: AttributeType,
  configuration?: string | null,
};

export enum AttributeType {
  Text = "Text",
  MultilineText = "MultilineText",
  SingleSelect = "SingleSelect",
  SingleSelectWithAssociatedText = "SingleSelectWithAssociatedText",
  MultiSelect = "MultiSelect",
  MultiSelectWithAssociatedText = "MultiSelectWithAssociatedText",
  Number = "Number",
  Integer = "Integer",
  Currency = "Currency",
  Date = "Date",
  Percentage = "Percentage",
  URL = "URL",
  Checkbox = "Checkbox",
  Radio = "Radio",
  User = "User",
  Card = "Card",
  CardSet = "CardSet",
  Cards = "Cards",
}


export type ScoreDefinition = {
  __typename: "ScoreDefinition",
  id: string,
  name: string,
  shortName?: string | null,
  description?: string | null,
  scoreType: ScoreType,
  minimumValue?: number | null,
  maximumValue?: number | null,
  target?: ScoreTarget | null,
};

export enum ScoreType {
  Number = "Number",
  Integer = "Integer",
  Percentage = "Percentage",
  Currency = "Currency",
  Indicator = "Indicator",
}


export enum ScoreTarget {
  Max = "Max",
  Min = "Min",
}


export type Attribute = {
  __typename: "Attribute",
  attributeDefinitionID: string,
  value?: string | null,
};

export type ModelCardAttributeConnection = {
  __typename: "ModelCardAttributeConnection",
  items:  Array<CardAttribute | null >,
  nextToken?: string | null,
};

export type CardAttribute = {
  __typename: "CardAttribute",
  cardId: string,
  card?: Card | null,
  attributeDefinitionId: string,
  organisation: string,
  value?: string | null,
  refToCardId?: string | null,
  refCard?: Card | null,
  refToCardSetId?: string | null,
  refCardSet?: CardSet | null,
  createdAt: string,
  updatedAt: string,
};

export type CardSet = {
  __typename: "CardSet",
  organisation: string,
  id: string,
  type: CardSetType,
  name: string,
  capitalName?: string | null,
  description?: string | null,
  owner?: string | null,
  createdBy?: string | null,
  editors?: Array< string > | null,
  orgEdit?: string | null,
  toCards?: ModelCardsCardSetsConnection | null,
  toCardCategory?: CardCategory | null,
  toCardType?: CardType | null,
  createdAt: string,
  updatedAt: string,
  updatedBy?: string | null,
  hiddenWorksheets?: Array< string | null > | null,
  copiedFrom?: string | null,
  counts?: CountsResponse | null,
  processProgress?:  Array<ProcessStageProgress | null > | null,
  cardSetToCardCategoryId?: string | null,
  cardSetToCardTypeId?: string | null,
};

export enum CardSetType {
  CS = "CS",
  WB = "WB",
}


export type ModelCardsCardSetsConnection = {
  __typename: "ModelCardsCardSetsConnection",
  items:  Array<CardsCardSets | null >,
  nextToken?: string | null,
};

export type CardsCardSets = {
  __typename: "CardsCardSets",
  organisation: string,
  id: string,
  cardId: string,
  cardSetId: string,
  card: Card,
  cardSet: CardSet,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type CountsResponse = {
  __typename: "CountsResponse",
  comments?: number | null,
  worksheets?: number | null,
  cards?: number | null,
};

export type ProcessStageProgress = {
  __typename: "ProcessStageProgress",
  stageId: string,
  progress?: number | null,
};

export type ModelScoreDataConnection = {
  __typename: "ModelScoreDataConnection",
  items:  Array<ScoreData | null >,
  nextToken?: string | null,
};

export type ScoreData = {
  __typename: "ScoreData",
  cardId: string,
  id: string,
  scoreDefinitionId: string,
  data:  Array<ScoreDatum >,
  organisation: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ScoreDatum = {
  __typename: "ScoreDatum",
  value: number,
  date: string,
  comment?: string | null,
  commentDate?: string | null,
  commentAuthor?: string | null,
};

export type DeleteCardSetInput = {
  organisation: string,
  id: string,
};

export type ModelCardSetConditionInput = {
  type?: ModelCardSetTypeInput | null,
  name?: ModelStringInput | null,
  capitalName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  orgEdit?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  hiddenWorksheets?: ModelStringInput | null,
  copiedFrom?: ModelIDInput | null,
  and?: Array< ModelCardSetConditionInput | null > | null,
  or?: Array< ModelCardSetConditionInput | null > | null,
  not?: ModelCardSetConditionInput | null,
  cardSetToCardCategoryId?: ModelIDInput | null,
  cardSetToCardTypeId?: ModelIDInput | null,
};

export type ModelCardSetTypeInput = {
  eq?: CardSetType | null,
  ne?: CardSetType | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCardsCardSetsFilterInput = {
  organisation?: ModelIDInput | null,
  id?: ModelIDInput | null,
  cardId?: ModelIDInput | null,
  cardSetId?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCardsCardSetsFilterInput | null > | null,
  or?: Array< ModelCardsCardSetsFilterInput | null > | null,
  not?: ModelCardsCardSetsFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCardFilterInput = {
  organisation?: ModelIDInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  orgEdit?: ModelStringInput | null,
  name?: ModelStringInput | null,
  capitalName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  deleteAfter?: ModelIntInput | null,
  _auditContextType?: ModelEventContextInput | null,
  _auditContextId?: ModelStringInput | null,
  createdFromId?: ModelIDInput | null,
  createdFromIds?: ModelIDInput | null,
  and?: Array< ModelCardFilterInput | null > | null,
  or?: Array< ModelCardFilterInput | null > | null,
  not?: ModelCardFilterInput | null,
  cardTypeToCardsId?: ModelIDInput | null,
  cardToCardCategoryId?: ModelIDInput | null,
  cardToCardTypeId?: ModelIDInput | null,
};

export type CardSort = {
  field: CardSortFields,
  order: CardSortOrder,
};

export enum CardSortFields {
  Name = "Name",
  CreatedAt = "CreatedAt",
  UpdatedAt = "UpdatedAt",
}


export enum CardSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}


export type CardFilter = {
  categories?: Array< string | null > | null,
  types?: Array< string | null > | null,
  cardSets?: Array< string | null > | null,
  workbooks?: Array< string | null > | null,
  owner?: Array< string | null > | null,
  deleted?: boolean | null,
};

export type CardDataResponse = {
  __typename: "CardDataResponse",
  items?:  Array<Card | null > | null,
  nextToken?: string | null,
};

export type PlaybookDataResponse = {
  __typename: "PlaybookDataResponse",
  organisation: string,
  id: string,
  name?: string | null,
  description?: string | null,
  pages:  Array<PlaybookPageDataResponse >,
  cards?:  Array<Card | null > | null,
  owner?: string | null,
  editors?: Array< string > | null,
  orgEdit?: string | null,
  workbooks?: Array< string | null > | null,
};

export type PlaybookPageDataResponse = {
  __typename: "PlaybookPageDataResponse",
  id: string,
  playbookPageType: PlaybookPageType,
  worksheetId?: string | null,
  title?: string | null,
  commentary?: string | null,
  cardTypeId?: string | null,
  cardSets?: Array< string | null > | null,
  workbooks?: Array< string | null > | null,
  worksheets?: Array< string | null > | null,
  cards?: Array< string | null > | null,
  allCards?: Array< string | null > | null,
  include?: Array< string | null > | null,
  options?: string | null,
  ordinal?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
};

export enum PlaybookPageType {
  Chart = "Chart",
  Title = "Title",
  List = "List",
}


export type ModelRelationshipFilterInput = {
  organisation?: ModelIDInput | null,
  id?: ModelIDInput | null,
  type?: ModelRelationshipTypeInput | null,
  sourceId?: ModelIDInput | null,
  sourceType?: ModelEntityTypeInput | null,
  sourceCardId?: ModelIDInput | null,
  sourceCardSetId?: ModelIDInput | null,
  sourceWorksheetId?: ModelIDInput | null,
  sourcePlaybookPageId?: ModelIDInput | null,
  targetId?: ModelIDInput | null,
  targetType?: ModelEntityTypeInput | null,
  targetCardId?: ModelIDInput | null,
  targetCardSetId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelRelationshipFilterInput | null > | null,
  or?: Array< ModelRelationshipFilterInput | null > | null,
  not?: ModelRelationshipFilterInput | null,
};

export type ModelRelationshipTypeInput = {
  eq?: RelationshipType | null,
  ne?: RelationshipType | null,
};

export enum RelationshipType {
  Link = "Link",
  CopiedFrom = "CopiedFrom",
  CreatedFrom = "CreatedFrom",
  Member = "Member",
}


export type ModelEntityTypeInput = {
  eq?: EntityType | null,
  ne?: EntityType | null,
};

export enum EntityType {
  Attribute = "Attribute",
  Card = "Card",
  CardsCardSets = "CardsCardSets",
  CardSet = "CardSet",
  Comment = "Comment",
  CommentReaction = "CommentReaction",
  Organisation = "Organisation",
  Playbook = "Playbook",
  PlaybookPage = "PlaybookPage",
  Process = "Process",
  ProcessStage = "ProcessStage",
  Relationship = "Relationship",
  Score = "Score",
  ScoreData = "ScoreData",
  UserProfile = "UserProfile",
  Worksheet = "Worksheet",
}


export type ModelRelationshipConnection = {
  __typename: "ModelRelationshipConnection",
  items:  Array<Relationship | null >,
  nextToken?: string | null,
};

export type Relationship = {
  __typename: "Relationship",
  organisation: string,
  id: string,
  type?: RelationshipType | null,
  sourceId: string,
  sourceType?: EntityType | null,
  sourceCardId?: string | null,
  toSourceCard?: Card | null,
  sourceCardSetId?: string | null,
  toSourceCardSet?: CardSet | null,
  sourceWorksheetId?: string | null,
  toSourceWorksheet?: Worksheet | null,
  sourcePlaybookPageId?: string | null,
  toSourcePlaybookPage?: PlaybookPage | null,
  targetId: string,
  targetType?: EntityType | null,
  targetCardId?: string | null,
  toTargetCard?: Card | null,
  targetCardSetId?: string | null,
  toTargetCardSet?: CardSet | null,
  createdAt: string,
  createdBy?: string | null,
  updatedAt: string,
  updatedBy?: string | null,
};

export type Worksheet = {
  __typename: "Worksheet",
  organisation: string,
  id: string,
  parentId: string,
  name?: string | null,
  commentary?: string | null,
  typeId: string,
  ordinal?: number | null,
  createdAt: string,
  createdBy?: string | null,
  updatedAt: string,
  updatedBy?: string | null,
  toDataSources?: ModelRelationshipConnection | null,
  options?: string | null,
};

export type PlaybookPage = {
  __typename: "PlaybookPage",
  organisation: string,
  id: string,
  cardType?: CardType | null,
  commentary?: string | null,
  playbookPageType: PlaybookPageType,
  worksheetId?: string | null,
  ordinal?: number | null,
  playbookId?: string | null,
  toPlaybook?: Playbook | null,
  workbookId?: string | null,
  title?: string | null,
  toCardSets?: ModelPlaybookPageCardSetsConnection | null,
  include?: Array< string | null > | null,
  options?: string | null,
  createdAt: string,
  createdBy?: string | null,
  updatedAt: string,
  updatedBy?: string | null,
  cardSets?: Array< string | null > | null,
  workbooks?: Array< string | null > | null,
  cards?: Array< string | null > | null,
  worksheets?: Array< string | null > | null,
  playbookPageCardTypeId?: string | null,
};

export type Playbook = {
  __typename: "Playbook",
  organisation: string,
  id: string,
  name: string,
  description?: string | null,
  toPlaybookPages?: ModelPlaybookPageConnection | null,
  owner?: string | null,
  editors?: Array< string > | null,
  orgEdit?: string | null,
  createdAt: string,
  createdBy?: string | null,
  updatedAt: string,
  updatedBy?: string | null,
  capitalName?: string | null,
  workbooks?: Array< string | null > | null,
};

export type ModelPlaybookPageConnection = {
  __typename: "ModelPlaybookPageConnection",
  items:  Array<PlaybookPage | null >,
  nextToken?: string | null,
};

export type ModelPlaybookPageCardSetsConnection = {
  __typename: "ModelPlaybookPageCardSetsConnection",
  items:  Array<PlaybookPageCardSets | null >,
  nextToken?: string | null,
};

export type PlaybookPageCardSets = {
  __typename: "PlaybookPageCardSets",
  organisation: string,
  id: string,
  cardSetId?: string | null,
  cardId?: string | null,
  playbookPageId: string,
  cardSet?: CardSet | null,
  card?: Card | null,
  playbookPage: PlaybookPage,
  createdAt: string,
  updatedAt: string,
};

export type WorkbookFilter = {
  owner?: Array< string | null > | null,
  nameContains?: string | null,
};

export type CardSetDataResponse = {
  __typename: "CardSetDataResponse",
  items?:  Array<CardSet | null > | null,
  nextToken?: string | null,
};

export type CardSetFilter = {
  owner?: Array< string | null > | null,
  categories?: Array< string | null > | null,
  types?: Array< string | null > | null,
  nameContains?: string | null,
};

export type CreateCardTypeInput = {
  id?: string | null,
  name?: string | null,
  cardCategoryID: string,
  attributeDefinitions?: Array< AttributeDefinitionInput | null > | null,
  scoreDefinitions: Array< ScoreDefinitionInput >,
  defaultScoreDefinitionId?: string | null,
};

export type AttributeDefinitionInput = {
  id: string,
  name: string,
  attributeType: AttributeType,
  configuration?: string | null,
};

export type ScoreDefinitionInput = {
  id: string,
  name: string,
  shortName?: string | null,
  description?: string | null,
  scoreType: ScoreType,
  minimumValue?: number | null,
  maximumValue?: number | null,
  target?: ScoreTarget | null,
};

export type ModelCardTypeConditionInput = {
  name?: ModelStringInput | null,
  cardCategoryID?: ModelIDInput | null,
  defaultScoreDefinitionId?: ModelIDInput | null,
  and?: Array< ModelCardTypeConditionInput | null > | null,
  or?: Array< ModelCardTypeConditionInput | null > | null,
  not?: ModelCardTypeConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateCardTypeInput = {
  id: string,
  name?: string | null,
  cardCategoryID?: string | null,
  attributeDefinitions?: Array< AttributeDefinitionInput | null > | null,
  scoreDefinitions?: Array< ScoreDefinitionInput > | null,
  defaultScoreDefinitionId?: string | null,
};

export type DeleteCardTypeInput = {
  id: string,
};

export type CreateCardCategoryInput = {
  id?: string | null,
  name: string,
  description?: string | null,
};

export type ModelCardCategoryConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCardCategoryConditionInput | null > | null,
  or?: Array< ModelCardCategoryConditionInput | null > | null,
  not?: ModelCardCategoryConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateCardCategoryInput = {
  id: string,
  name?: string | null,
  description?: string | null,
};

export type DeleteCardCategoryInput = {
  id: string,
};

export type CreateCardInput = {
  organisation: string,
  id?: string | null,
  owner?: string | null,
  createdBy?: string | null,
  editors?: Array< string > | null,
  orgEdit?: string | null,
  name: string,
  capitalName?: string | null,
  description?: string | null,
  attributes?: Array< AttributeInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  deleteAfter?: number | null,
  _auditContextType?: EventContext | null,
  _auditContextId?: string | null,
  createdFromId?: string | null,
  createdFromIds?: Array< string | null > | null,
  cardTypeToCardsId?: string | null,
  cardToCardCategoryId: string,
  cardToCardTypeId: string,
};

export type AttributeInput = {
  attributeDefinitionID: string,
  value?: string | null,
};

export type UpdateCardInput = {
  organisation: string,
  id: string,
  owner?: string | null,
  createdBy?: string | null,
  editors?: Array< string > | null,
  orgEdit?: string | null,
  name?: string | null,
  capitalName?: string | null,
  description?: string | null,
  attributes?: Array< AttributeInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  deleteAfter?: number | null,
  _auditContextType?: EventContext | null,
  _auditContextId?: string | null,
  createdFromId?: string | null,
  createdFromIds?: Array< string | null > | null,
  cardTypeToCardsId?: string | null,
  cardToCardCategoryId?: string | null,
  cardToCardTypeId?: string | null,
};

export type CreateCardAttributeInput = {
  cardId: string,
  attributeDefinitionId: string,
  organisation: string,
  value?: string | null,
  refToCardId?: string | null,
  refToCardSetId?: string | null,
};

export type ModelCardAttributeConditionInput = {
  organisation?: ModelIDInput | null,
  value?: ModelStringInput | null,
  refToCardId?: ModelIDInput | null,
  refToCardSetId?: ModelIDInput | null,
  and?: Array< ModelCardAttributeConditionInput | null > | null,
  or?: Array< ModelCardAttributeConditionInput | null > | null,
  not?: ModelCardAttributeConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateCardAttributeInput = {
  cardId: string,
  attributeDefinitionId: string,
  organisation?: string | null,
  value?: string | null,
  refToCardId?: string | null,
  refToCardSetId?: string | null,
};

export type DeleteCardAttributeInput = {
  cardId: string,
  attributeDefinitionId: string,
};

export type CreateCardSetInput = {
  organisation: string,
  id?: string | null,
  type: CardSetType,
  name: string,
  capitalName?: string | null,
  description?: string | null,
  owner?: string | null,
  createdBy?: string | null,
  editors?: Array< string > | null,
  orgEdit?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  hiddenWorksheets?: Array< string | null > | null,
  copiedFrom?: string | null,
  counts?: CountsResponseInput | null,
  processProgress?: Array< ProcessStageProgressInput | null > | null,
  cardSetToCardCategoryId?: string | null,
  cardSetToCardTypeId?: string | null,
};

export type CountsResponseInput = {
  comments?: number | null,
  worksheets?: number | null,
  cards?: number | null,
};

export type ProcessStageProgressInput = {
  stageId: string,
  progress?: number | null,
};

export type UpdateCardSetInput = {
  organisation: string,
  id: string,
  type?: CardSetType | null,
  name?: string | null,
  capitalName?: string | null,
  description?: string | null,
  owner?: string | null,
  createdBy?: string | null,
  editors?: Array< string > | null,
  orgEdit?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  hiddenWorksheets?: Array< string | null > | null,
  copiedFrom?: string | null,
  counts?: CountsResponseInput | null,
  processProgress?: Array< ProcessStageProgressInput | null > | null,
  cardSetToCardCategoryId?: string | null,
  cardSetToCardTypeId?: string | null,
};

export type CreateCardsCardSetsInput = {
  organisation: string,
  id?: string | null,
  cardId: string,
  cardSetId: string,
  createdBy?: string | null,
  updatedBy?: string | null,
};

export type ModelCardsCardSetsConditionInput = {
  cardId?: ModelIDInput | null,
  cardSetId?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCardsCardSetsConditionInput | null > | null,
  or?: Array< ModelCardsCardSetsConditionInput | null > | null,
  not?: ModelCardsCardSetsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateCardsCardSetsInput = {
  organisation: string,
  id: string,
  cardId?: string | null,
  cardSetId?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
};

export type DeleteCardsCardSetsInput = {
  organisation: string,
  id: string,
};

export type CreateCommentInput = {
  contextId: string,
  id?: string | null,
  organisation: string,
  contextType?: EntityType | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  message?: string | null,
};

export type ModelCommentConditionInput = {
  organisation?: ModelIDInput | null,
  contextType?: ModelEntityTypeInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  message?: ModelStringInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Comment = {
  __typename: "Comment",
  contextId: string,
  id: string,
  organisation: string,
  contextType?: EntityType | null,
  createdAt: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  message?: string | null,
  reactions?: ModelCommentReactionConnection | null,
  updatedAt: string,
};

export type ModelCommentReactionConnection = {
  __typename: "ModelCommentReactionConnection",
  items:  Array<CommentReaction | null >,
  nextToken?: string | null,
};

export type CommentReaction = {
  __typename: "CommentReaction",
  commentId: string,
  commentContextId: string,
  commentContextType?: EntityType | null,
  commentCreatedAt: string,
  organisation: string,
  comment?: Comment | null,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateCommentInput = {
  contextId: string,
  id: string,
  organisation?: string | null,
  contextType?: EntityType | null,
  createdAt: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  message?: string | null,
};

export type CreateCommentReactionInput = {
  commentId: string,
  commentContextId: string,
  commentContextType?: EntityType | null,
  commentCreatedAt: string,
  organisation: string,
  createdAt?: string | null,
  createdBy: string,
};

export type ModelCommentReactionConditionInput = {
  commentContextId?: ModelIDInput | null,
  commentContextType?: ModelEntityTypeInput | null,
  commentCreatedAt?: ModelStringInput | null,
  organisation?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCommentReactionConditionInput | null > | null,
  or?: Array< ModelCommentReactionConditionInput | null > | null,
  not?: ModelCommentReactionConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateCommentReactionInput = {
  commentId: string,
  commentContextId?: string | null,
  commentContextType?: EntityType | null,
  commentCreatedAt?: string | null,
  organisation?: string | null,
  createdAt?: string | null,
  createdBy: string,
};

export type DeleteCommentReactionInput = {
  commentId: string,
  createdBy: string,
};

export type CreateNotificationInput = {
  organisation: string,
  createdAt?: string | null,
  id?: string | null,
  type: NotificationType,
  entity: EntityType,
  userId?: string | null,
  deleteAfter?: number | null,
  cardId?: string | null,
  cardSetId?: string | null,
  cardSetType?: CardSetType | null,
  cardsCardSetsId?: string | null,
  playbookId?: string | null,
  scoreDefinitionId?: string | null,
  playbookPageId?: string | null,
  commentContextId?: string | null,
  commentCreatedAt?: string | null,
  commentId?: string | null,
  commentReactionCreatedBy?: string | null,
  worksheetId?: string | null,
  relationshipId?: string | null,
  relationshipInfo?: string | null,
};

export enum NotificationType {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}


export type ModelNotificationConditionInput = {
  type?: ModelNotificationTypeInput | null,
  entity?: ModelEntityTypeInput | null,
  userId?: ModelIDInput | null,
  deleteAfter?: ModelIntInput | null,
  cardId?: ModelIDInput | null,
  cardSetId?: ModelIDInput | null,
  cardSetType?: ModelCardSetTypeInput | null,
  cardsCardSetsId?: ModelIDInput | null,
  playbookId?: ModelIDInput | null,
  scoreDefinitionId?: ModelIDInput | null,
  playbookPageId?: ModelIDInput | null,
  commentContextId?: ModelIDInput | null,
  commentCreatedAt?: ModelStringInput | null,
  commentId?: ModelIDInput | null,
  commentReactionCreatedBy?: ModelStringInput | null,
  worksheetId?: ModelIDInput | null,
  relationshipId?: ModelIDInput | null,
  relationshipInfo?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type Notification = {
  __typename: "Notification",
  organisation: string,
  createdAt: string,
  id: string,
  type: NotificationType,
  entity: EntityType,
  userId?: string | null,
  deleteAfter?: number | null,
  cardId?: string | null,
  toCard?: Card | null,
  cardSetId?: string | null,
  cardSetType?: CardSetType | null,
  toCardSet?: CardSet | null,
  cardsCardSetsId?: string | null,
  toCardsCardSets?: CardsCardSets | null,
  playbookId?: string | null,
  toPlaybook?: Playbook | null,
  scoreDefinitionId?: string | null,
  toScoreData?: ScoreData | null,
  playbookPageId?: string | null,
  commentContextId?: string | null,
  commentCreatedAt?: string | null,
  commentId?: string | null,
  toComment?: Comment | null,
  commentReactionCreatedBy?: string | null,
  toCommentReaction?: CommentReaction | null,
  worksheetId?: string | null,
  toWorksheet?: Worksheet | null,
  relationshipId?: string | null,
  toRelationship?: Relationship | null,
  relationshipInfo?: string | null,
  updatedAt: string,
};

export type UpdateNotificationInput = {
  organisation: string,
  createdAt: string,
  id: string,
  type?: NotificationType | null,
  entity?: EntityType | null,
  userId?: string | null,
  deleteAfter?: number | null,
  cardId?: string | null,
  cardSetId?: string | null,
  cardSetType?: CardSetType | null,
  cardsCardSetsId?: string | null,
  playbookId?: string | null,
  scoreDefinitionId?: string | null,
  playbookPageId?: string | null,
  commentContextId?: string | null,
  commentCreatedAt?: string | null,
  commentId?: string | null,
  commentReactionCreatedBy?: string | null,
  worksheetId?: string | null,
  relationshipId?: string | null,
  relationshipInfo?: string | null,
};

export type DeleteNotificationInput = {
  organisation: string,
  createdAt: string,
  id: string,
};

export type CreatePlaybookInput = {
  organisation: string,
  id?: string | null,
  name: string,
  description?: string | null,
  owner?: string | null,
  editors?: Array< string > | null,
  orgEdit?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  capitalName?: string | null,
  workbooks?: Array< string | null > | null,
};

export type ModelPlaybookConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  orgEdit?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  capitalName?: ModelStringInput | null,
  workbooks?: ModelIDInput | null,
  and?: Array< ModelPlaybookConditionInput | null > | null,
  or?: Array< ModelPlaybookConditionInput | null > | null,
  not?: ModelPlaybookConditionInput | null,
};

export type UpdatePlaybookInput = {
  organisation: string,
  id: string,
  name?: string | null,
  description?: string | null,
  owner?: string | null,
  editors?: Array< string > | null,
  orgEdit?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  capitalName?: string | null,
  workbooks?: Array< string | null > | null,
};

export type DeletePlaybookInput = {
  organisation: string,
  id: string,
};

export type CreatePlaybookPageInput = {
  organisation: string,
  id?: string | null,
  commentary?: string | null,
  playbookPageType: PlaybookPageType,
  worksheetId?: string | null,
  ordinal?: number | null,
  playbookId?: string | null,
  workbookId?: string | null,
  title?: string | null,
  include?: Array< string | null > | null,
  options?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  cardSets?: Array< string | null > | null,
  workbooks?: Array< string | null > | null,
  cards?: Array< string | null > | null,
  worksheets?: Array< string | null > | null,
  playbookPageCardTypeId?: string | null,
};

export type ModelPlaybookPageConditionInput = {
  commentary?: ModelStringInput | null,
  playbookPageType?: ModelPlaybookPageTypeInput | null,
  worksheetId?: ModelStringInput | null,
  ordinal?: ModelIntInput | null,
  playbookId?: ModelIDInput | null,
  workbookId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  include?: ModelStringInput | null,
  options?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  cardSets?: ModelIDInput | null,
  workbooks?: ModelIDInput | null,
  cards?: ModelIDInput | null,
  worksheets?: ModelIDInput | null,
  and?: Array< ModelPlaybookPageConditionInput | null > | null,
  or?: Array< ModelPlaybookPageConditionInput | null > | null,
  not?: ModelPlaybookPageConditionInput | null,
  playbookPageCardTypeId?: ModelIDInput | null,
};

export type ModelPlaybookPageTypeInput = {
  eq?: PlaybookPageType | null,
  ne?: PlaybookPageType | null,
};

export type UpdatePlaybookPageInput = {
  organisation: string,
  id: string,
  commentary?: string | null,
  playbookPageType?: PlaybookPageType | null,
  worksheetId?: string | null,
  ordinal?: number | null,
  playbookId?: string | null,
  workbookId?: string | null,
  title?: string | null,
  include?: Array< string | null > | null,
  options?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  cardSets?: Array< string | null > | null,
  workbooks?: Array< string | null > | null,
  cards?: Array< string | null > | null,
  worksheets?: Array< string | null > | null,
  playbookPageCardTypeId?: string | null,
};

export type DeletePlaybookPageInput = {
  organisation: string,
  id: string,
};

export type CreatePlaybookPageCardSetsInput = {
  organisation: string,
  id?: string | null,
  cardSetId?: string | null,
  cardId?: string | null,
  playbookPageId: string,
};

export type ModelPlaybookPageCardSetsConditionInput = {
  cardSetId?: ModelIDInput | null,
  cardId?: ModelIDInput | null,
  playbookPageId?: ModelIDInput | null,
  and?: Array< ModelPlaybookPageCardSetsConditionInput | null > | null,
  or?: Array< ModelPlaybookPageCardSetsConditionInput | null > | null,
  not?: ModelPlaybookPageCardSetsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdatePlaybookPageCardSetsInput = {
  organisation: string,
  id: string,
  cardSetId?: string | null,
  cardId?: string | null,
  playbookPageId?: string | null,
};

export type DeletePlaybookPageCardSetsInput = {
  organisation: string,
  id: string,
};

export type CreateOrganisationInput = {
  id?: string | null,
  name: string,
  owningUserProfileID: string,
  edition?: Edition | null,
  logo?: string | null,
  aiKey?: string | null,
};

export enum Edition {
  Starter = "Starter",
  Standard = "Standard",
  Enterprise = "Enterprise",
}


export type ModelOrganisationConditionInput = {
  name?: ModelStringInput | null,
  owningUserProfileID?: ModelIDInput | null,
  edition?: ModelEditionInput | null,
  logo?: ModelStringInput | null,
  aiKey?: ModelStringInput | null,
  and?: Array< ModelOrganisationConditionInput | null > | null,
  or?: Array< ModelOrganisationConditionInput | null > | null,
  not?: ModelOrganisationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelEditionInput = {
  eq?: Edition | null,
  ne?: Edition | null,
};

export type Organisation = {
  __typename: "Organisation",
  id: string,
  name: string,
  owningUserProfileID: string,
  edition?: Edition | null,
  logo?: string | null,
  aiKey?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOrganisationInput = {
  id: string,
  name?: string | null,
  owningUserProfileID?: string | null,
  edition?: Edition | null,
  logo?: string | null,
  aiKey?: string | null,
};

export type DeleteOrganisationInput = {
  id: string,
};

export type CreateUserProfileInput = {
  organisation: string,
  id?: string | null,
  email: string,
  firstName?: string | null,
  lastName?: string | null,
  telephone?: string | null,
  title?: string | null,
  department?: string | null,
  location?: string | null,
  status?: UserStatus | null,
  lastStatusChange?: string | null,
  preferences?: string | null,
  avatar?: string | null,
  lastNotificationSeen?: string | null,
};

export enum UserStatus {
  Active = "Active",
  Invited = "Invited",
  Deleted = "Deleted",
  Disabled = "Disabled",
}


export type ModelUserProfileConditionInput = {
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  telephone?: ModelStringInput | null,
  title?: ModelStringInput | null,
  department?: ModelStringInput | null,
  location?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  lastStatusChange?: ModelStringInput | null,
  preferences?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  lastNotificationSeen?: ModelStringInput | null,
  and?: Array< ModelUserProfileConditionInput | null > | null,
  or?: Array< ModelUserProfileConditionInput | null > | null,
  not?: ModelUserProfileConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type UserProfile = {
  __typename: "UserProfile",
  organisation: string,
  id: string,
  email: string,
  firstName?: string | null,
  lastName?: string | null,
  telephone?: string | null,
  title?: string | null,
  department?: string | null,
  location?: string | null,
  status?: UserStatus | null,
  lastStatusChange?: string | null,
  preferences?: string | null,
  avatar?: string | null,
  lastNotificationSeen?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateUserProfileInput = {
  organisation: string,
  id: string,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  telephone?: string | null,
  title?: string | null,
  department?: string | null,
  location?: string | null,
  status?: UserStatus | null,
  lastStatusChange?: string | null,
  preferences?: string | null,
  avatar?: string | null,
  lastNotificationSeen?: string | null,
};

export type DeleteUserProfileInput = {
  organisation: string,
  id: string,
};

export type CopyCardSetOptions = {
  owner?: string | null,
  name?: string | null,
  copyCards?: boolean | null,
  copyWorksheets?: boolean | null,
};

export type CreateOrUpdateScoreDataInput = {
  cardId: string,
  scoreDefinitionId: string,
  data?: Array< ScoreDatumInput > | null,
  organisation?: string | null,
};

export type ScoreDatumInput = {
  value: number,
  date: string,
  comment?: string | null,
  commentDate?: string | null,
  commentAuthor?: string | null,
};

export type GenerateDataParam = {
  tableName: string,
  fileName: string,
};

export type AiRequestParams = {
  query: string,
  assistantId: string,
  threadId?: string | null,
};

export type AiResponse = {
  __typename: "AiResponse",
  content?: string | null,
  threadId?: string | null,
};

export type GetPlaybooksResponse = {
  __typename: "GetPlaybooksResponse",
  items?:  Array<Playbook | null > | null,
  nextToken?: string | null,
};

export type AuditEntry = {
  __typename: "AuditEntry",
  contextId: string,
  createdAt: string,
  id: string,
  organisation: string,
  deleteAfter?: number | null,
  operation?: AuditType | null,
  subContext?: string | null,
  contextType?: EntityType | null,
  subContextType?: EntityType | null,
  user?: string | null,
  system?: boolean | null,
  old?: string | null,
  new?: string | null,
  batchId?: string | null,
  eventContext?: EventContext | null,
  eventContextId?: string | null,
  relationshipType?: RelationshipType | null,
  updatedAt: string,
};

export enum AuditType {
  ItemCreated = "ItemCreated",
  ItemCreatedFrom = "ItemCreatedFrom",
  ItemUpdated = "ItemUpdated",
  ItemSoftDeleted = "ItemSoftDeleted",
  ItemHardDeleted = "ItemHardDeleted",
  ItemRestored = "ItemRestored",
  ItemAddedTo = "ItemAddedTo",
  ItemRemovedFrom = "ItemRemovedFrom",
  ItemLinked = "ItemLinked",
  ItemUnlinked = "ItemUnlinked",
  AttributeUpdated = "AttributeUpdated",
  ScoreUpdated = "ScoreUpdated",
  OwnerChanged = "OwnerChanged",
  PermissionGranted = "PermissionGranted",
  PermissionRevoked = "PermissionRevoked",
  ReactionAdded = "ReactionAdded",
  ReactionRemoved = "ReactionRemoved",
  CardTypeChanged = "CardTypeChanged",
  ProgressUpdated = "ProgressUpdated",
}


export type ModelAuditEntryPrimaryCompositeKeyConditionInput = {
  eq?: ModelAuditEntryPrimaryCompositeKeyInput | null,
  le?: ModelAuditEntryPrimaryCompositeKeyInput | null,
  lt?: ModelAuditEntryPrimaryCompositeKeyInput | null,
  ge?: ModelAuditEntryPrimaryCompositeKeyInput | null,
  gt?: ModelAuditEntryPrimaryCompositeKeyInput | null,
  between?: Array< ModelAuditEntryPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelAuditEntryPrimaryCompositeKeyInput | null,
};

export type ModelAuditEntryPrimaryCompositeKeyInput = {
  createdAt?: string | null,
  id?: string | null,
};

export type ModelAuditEntryFilterInput = {
  contextId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  organisation?: ModelIDInput | null,
  deleteAfter?: ModelIntInput | null,
  operation?: ModelAuditTypeInput | null,
  subContext?: ModelStringInput | null,
  contextType?: ModelEntityTypeInput | null,
  subContextType?: ModelEntityTypeInput | null,
  user?: ModelStringInput | null,
  system?: ModelBooleanInput | null,
  old?: ModelStringInput | null,
  new?: ModelStringInput | null,
  batchId?: ModelStringInput | null,
  eventContext?: ModelEventContextInput | null,
  eventContextId?: ModelStringInput | null,
  relationshipType?: ModelRelationshipTypeInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAuditEntryFilterInput | null > | null,
  or?: Array< ModelAuditEntryFilterInput | null > | null,
  not?: ModelAuditEntryFilterInput | null,
};

export type ModelAuditTypeInput = {
  eq?: AuditType | null,
  ne?: AuditType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelAuditEntryConnection = {
  __typename: "ModelAuditEntryConnection",
  items:  Array<AuditEntry | null >,
  nextToken?: string | null,
};

export type ModelAuditEntryByOrganisationCompositeKeyConditionInput = {
  eq?: ModelAuditEntryByOrganisationCompositeKeyInput | null,
  le?: ModelAuditEntryByOrganisationCompositeKeyInput | null,
  lt?: ModelAuditEntryByOrganisationCompositeKeyInput | null,
  ge?: ModelAuditEntryByOrganisationCompositeKeyInput | null,
  gt?: ModelAuditEntryByOrganisationCompositeKeyInput | null,
  between?: Array< ModelAuditEntryByOrganisationCompositeKeyInput | null > | null,
  beginsWith?: ModelAuditEntryByOrganisationCompositeKeyInput | null,
};

export type ModelAuditEntryByOrganisationCompositeKeyInput = {
  createdAt?: string | null,
  id?: string | null,
};

export type ModelAuditEntryByUserCompositeKeyConditionInput = {
  eq?: ModelAuditEntryByUserCompositeKeyInput | null,
  le?: ModelAuditEntryByUserCompositeKeyInput | null,
  lt?: ModelAuditEntryByUserCompositeKeyInput | null,
  ge?: ModelAuditEntryByUserCompositeKeyInput | null,
  gt?: ModelAuditEntryByUserCompositeKeyInput | null,
  between?: Array< ModelAuditEntryByUserCompositeKeyInput | null > | null,
  beginsWith?: ModelAuditEntryByUserCompositeKeyInput | null,
};

export type ModelAuditEntryByUserCompositeKeyInput = {
  createdAt?: string | null,
  id?: string | null,
};

export type ModelCardTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  cardCategoryID?: ModelIDInput | null,
  defaultScoreDefinitionId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCardTypeFilterInput | null > | null,
  or?: Array< ModelCardTypeFilterInput | null > | null,
  not?: ModelCardTypeFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCardCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCardCategoryFilterInput | null > | null,
  or?: Array< ModelCardCategoryFilterInput | null > | null,
  not?: ModelCardCategoryFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCardCategoryConnection = {
  __typename: "ModelCardCategoryConnection",
  items:  Array<CardCategory | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCardAttributeFilterInput = {
  cardId?: ModelIDInput | null,
  attributeDefinitionId?: ModelIDInput | null,
  organisation?: ModelIDInput | null,
  value?: ModelStringInput | null,
  refToCardId?: ModelIDInput | null,
  refToCardSetId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCardAttributeFilterInput | null > | null,
  or?: Array< ModelCardAttributeFilterInput | null > | null,
  not?: ModelCardAttributeFilterInput | null,
};

export type ModelScoreDataFilterInput = {
  cardId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  scoreDefinitionId?: ModelIDInput | null,
  organisation?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelScoreDataFilterInput | null > | null,
  or?: Array< ModelScoreDataFilterInput | null > | null,
  not?: ModelScoreDataFilterInput | null,
};

export type ModelCardSetFilterInput = {
  organisation?: ModelIDInput | null,
  id?: ModelIDInput | null,
  type?: ModelCardSetTypeInput | null,
  name?: ModelStringInput | null,
  capitalName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  orgEdit?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  hiddenWorksheets?: ModelStringInput | null,
  copiedFrom?: ModelIDInput | null,
  and?: Array< ModelCardSetFilterInput | null > | null,
  or?: Array< ModelCardSetFilterInput | null > | null,
  not?: ModelCardSetFilterInput | null,
  cardSetToCardCategoryId?: ModelIDInput | null,
  cardSetToCardTypeId?: ModelIDInput | null,
};

export type ModelCardSetConnection = {
  __typename: "ModelCardSetConnection",
  items:  Array<CardSet | null >,
  nextToken?: string | null,
};

export type ModelCardSetByOrgSortedByTypeAndNameCompositeKeyConditionInput = {
  eq?: ModelCardSetByOrgSortedByTypeAndNameCompositeKeyInput | null,
  le?: ModelCardSetByOrgSortedByTypeAndNameCompositeKeyInput | null,
  lt?: ModelCardSetByOrgSortedByTypeAndNameCompositeKeyInput | null,
  ge?: ModelCardSetByOrgSortedByTypeAndNameCompositeKeyInput | null,
  gt?: ModelCardSetByOrgSortedByTypeAndNameCompositeKeyInput | null,
  between?: Array< ModelCardSetByOrgSortedByTypeAndNameCompositeKeyInput | null > | null,
  beginsWith?: ModelCardSetByOrgSortedByTypeAndNameCompositeKeyInput | null,
};

export type ModelCardSetByOrgSortedByTypeAndNameCompositeKeyInput = {
  type?: CardSetType | null,
  capitalName?: string | null,
};

export type ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyConditionInput = {
  eq?: ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyInput | null,
  le?: ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyInput | null,
  lt?: ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyInput | null,
  ge?: ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyInput | null,
  gt?: ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyInput | null,
  between?: Array< ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyInput | null > | null,
  beginsWith?: ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyInput | null,
};

export type ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyInput = {
  type?: CardSetType | null,
  createdAt?: string | null,
};

export type ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyConditionInput = {
  eq?: ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyInput | null,
  le?: ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyInput | null,
  lt?: ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyInput | null,
  ge?: ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyInput | null,
  gt?: ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyInput | null,
  between?: Array< ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyInput | null > | null,
  beginsWith?: ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyInput | null,
};

export type ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyInput = {
  type?: CardSetType | null,
  updatedAt?: string | null,
};

export type ModelCommentPrimaryCompositeKeyConditionInput = {
  eq?: ModelCommentPrimaryCompositeKeyInput | null,
  le?: ModelCommentPrimaryCompositeKeyInput | null,
  lt?: ModelCommentPrimaryCompositeKeyInput | null,
  ge?: ModelCommentPrimaryCompositeKeyInput | null,
  gt?: ModelCommentPrimaryCompositeKeyInput | null,
  between?: Array< ModelCommentPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelCommentPrimaryCompositeKeyInput | null,
};

export type ModelCommentPrimaryCompositeKeyInput = {
  createdAt?: string | null,
  id?: string | null,
};

export type ModelCommentFilterInput = {
  contextId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  organisation?: ModelIDInput | null,
  contextType?: ModelEntityTypeInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  message?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type ModelCommentReactionFilterInput = {
  commentId?: ModelIDInput | null,
  commentContextId?: ModelIDInput | null,
  commentContextType?: ModelEntityTypeInput | null,
  commentCreatedAt?: ModelStringInput | null,
  organisation?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  id?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCommentReactionFilterInput | null > | null,
  or?: Array< ModelCommentReactionFilterInput | null > | null,
  not?: ModelCommentReactionFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelNotificationPrimaryCompositeKeyConditionInput = {
  eq?: ModelNotificationPrimaryCompositeKeyInput | null,
  le?: ModelNotificationPrimaryCompositeKeyInput | null,
  lt?: ModelNotificationPrimaryCompositeKeyInput | null,
  ge?: ModelNotificationPrimaryCompositeKeyInput | null,
  gt?: ModelNotificationPrimaryCompositeKeyInput | null,
  between?: Array< ModelNotificationPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelNotificationPrimaryCompositeKeyInput | null,
};

export type ModelNotificationPrimaryCompositeKeyInput = {
  createdAt?: string | null,
  id?: string | null,
};

export type ModelNotificationFilterInput = {
  organisation?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  type?: ModelNotificationTypeInput | null,
  entity?: ModelEntityTypeInput | null,
  userId?: ModelIDInput | null,
  deleteAfter?: ModelIntInput | null,
  cardId?: ModelIDInput | null,
  cardSetId?: ModelIDInput | null,
  cardSetType?: ModelCardSetTypeInput | null,
  cardsCardSetsId?: ModelIDInput | null,
  playbookId?: ModelIDInput | null,
  scoreDefinitionId?: ModelIDInput | null,
  playbookPageId?: ModelIDInput | null,
  commentContextId?: ModelIDInput | null,
  commentCreatedAt?: ModelStringInput | null,
  commentId?: ModelIDInput | null,
  commentReactionCreatedBy?: ModelStringInput | null,
  worksheetId?: ModelIDInput | null,
  relationshipId?: ModelIDInput | null,
  relationshipInfo?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelPlaybookFilterInput = {
  organisation?: ModelIDInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  orgEdit?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  capitalName?: ModelStringInput | null,
  workbooks?: ModelIDInput | null,
  and?: Array< ModelPlaybookFilterInput | null > | null,
  or?: Array< ModelPlaybookFilterInput | null > | null,
  not?: ModelPlaybookFilterInput | null,
};

export type ModelPlaybookConnection = {
  __typename: "ModelPlaybookConnection",
  items:  Array<Playbook | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelPlaybookPageFilterInput = {
  organisation?: ModelIDInput | null,
  id?: ModelIDInput | null,
  commentary?: ModelStringInput | null,
  playbookPageType?: ModelPlaybookPageTypeInput | null,
  worksheetId?: ModelStringInput | null,
  ordinal?: ModelIntInput | null,
  playbookId?: ModelIDInput | null,
  workbookId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  include?: ModelStringInput | null,
  options?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  cardSets?: ModelIDInput | null,
  workbooks?: ModelIDInput | null,
  cards?: ModelIDInput | null,
  worksheets?: ModelIDInput | null,
  and?: Array< ModelPlaybookPageFilterInput | null > | null,
  or?: Array< ModelPlaybookPageFilterInput | null > | null,
  not?: ModelPlaybookPageFilterInput | null,
  playbookPageCardTypeId?: ModelIDInput | null,
};

export type ModelPlaybookPageCardSetsFilterInput = {
  organisation?: ModelIDInput | null,
  id?: ModelIDInput | null,
  cardSetId?: ModelIDInput | null,
  cardId?: ModelIDInput | null,
  playbookPageId?: ModelIDInput | null,
  and?: Array< ModelPlaybookPageCardSetsFilterInput | null > | null,
  or?: Array< ModelPlaybookPageCardSetsFilterInput | null > | null,
  not?: ModelPlaybookPageCardSetsFilterInput | null,
};

export type ModelOrganisationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  owningUserProfileID?: ModelIDInput | null,
  edition?: ModelEditionInput | null,
  logo?: ModelStringInput | null,
  aiKey?: ModelStringInput | null,
  and?: Array< ModelOrganisationFilterInput | null > | null,
  or?: Array< ModelOrganisationFilterInput | null > | null,
  not?: ModelOrganisationFilterInput | null,
};

export type ModelOrganisationConnection = {
  __typename: "ModelOrganisationConnection",
  items:  Array<Organisation | null >,
  nextToken?: string | null,
};

export type ModelUserProfileFilterInput = {
  organisation?: ModelIDInput | null,
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  telephone?: ModelStringInput | null,
  title?: ModelStringInput | null,
  department?: ModelStringInput | null,
  location?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  lastStatusChange?: ModelStringInput | null,
  preferences?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  lastNotificationSeen?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserProfileFilterInput | null > | null,
  or?: Array< ModelUserProfileFilterInput | null > | null,
  not?: ModelUserProfileFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserProfileConnection = {
  __typename: "ModelUserProfileConnection",
  items:  Array<UserProfile | null >,
  nextToken?: string | null,
};

export type ModelWorksheetFilterInput = {
  organisation?: ModelIDInput | null,
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  commentary?: ModelStringInput | null,
  typeId?: ModelStringInput | null,
  ordinal?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  options?: ModelStringInput | null,
  and?: Array< ModelWorksheetFilterInput | null > | null,
  or?: Array< ModelWorksheetFilterInput | null > | null,
  not?: ModelWorksheetFilterInput | null,
};

export type ModelWorksheetConnection = {
  __typename: "ModelWorksheetConnection",
  items:  Array<Worksheet | null >,
  nextToken?: string | null,
};

export type AuditFilter = {
  attributes?: boolean | null,
  comments?: boolean | null,
  currentUser?: boolean | null,
  membership?: boolean | null,
  relationships?: boolean | null,
  scores?: boolean | null,
  startDate?: string | null,
};

export type GetAuditDataResponse = {
  __typename: "GetAuditDataResponse",
  names?: string | null,
  items?:  Array<AuditEntry | null > | null,
  nextTokens?: string | null,
};

export type UserNotificationFilter = {
  owner?: boolean | null,
  member?: boolean | null,
  creator?: boolean | null,
};

export type UserNotificationResponse = {
  __typename: "UserNotificationResponse",
  names?: string | null,
  entries?:  Array<AuditEntry | null > | null,
  nextToken?: string | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  createdAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  entity?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  deleteAfter?: ModelSubscriptionIntInput | null,
  cardId?: ModelSubscriptionIDInput | null,
  cardSetId?: ModelSubscriptionIDInput | null,
  cardSetType?: ModelSubscriptionStringInput | null,
  cardsCardSetsId?: ModelSubscriptionIDInput | null,
  playbookId?: ModelSubscriptionIDInput | null,
  scoreDefinitionId?: ModelSubscriptionIDInput | null,
  playbookPageId?: ModelSubscriptionIDInput | null,
  commentContextId?: ModelSubscriptionIDInput | null,
  commentCreatedAt?: ModelSubscriptionStringInput | null,
  commentId?: ModelSubscriptionIDInput | null,
  commentReactionCreatedBy?: ModelSubscriptionStringInput | null,
  worksheetId?: ModelSubscriptionIDInput | null,
  relationshipId?: ModelSubscriptionIDInput | null,
  relationshipInfo?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type DeleteCardBasicMutationVariables = {
  input: DeleteCardInput,
  condition?: ModelCardConditionInput | null,
};

export type DeleteCardBasicMutation = {
  deleteCard?:  {
    __typename: "Card",
    organisation: string,
    id: string,
  } | null,
};

export type DeleteCardSetBasicMutationVariables = {
  input: DeleteCardSetInput,
  condition?: ModelCardSetConditionInput | null,
};

export type DeleteCardSetBasicMutation = {
  deleteCardSet?:  {
    __typename: "CardSet",
    organisation: string,
    id: string,
  } | null,
};

export type CardsCardSetsByCardSetIdWithScoresQueryVariables = {
  cardSetId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardsCardSetsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsCardSetsByCardSetIdWithScoresQuery = {
  cardsCardSetsByCardSetId?:  {
    __typename: "ModelCardsCardSetsConnection",
    items:  Array< {
      __typename: "CardsCardSets",
      id: string,
      cardId: string,
      cardSetId: string,
      card:  {
        __typename: "Card",
        id: string,
        name: string,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          items:  Array< {
            __typename: "ScoreData",
            cardId: string,
            scoreDefinitionId: string,
            data:  Array< {
              __typename: "ScoreDatum",
              value: number,
              date: string,
              comment?: string | null,
              commentDate?: string | null,
              commentAuthor?: string | null,
            } >,
            organisation: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          scoreDefinitions:  Array< {
            __typename: "ScoreDefinition",
            id: string,
            name: string,
            shortName?: string | null,
            description?: string | null,
            scoreType: ScoreType,
            minimumValue?: number | null,
            maximumValue?: number | null,
          } >,
          defaultScoreDefinitionId?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        organisation: string,
        createdAt: string,
        updatedAt: string,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
        owner?: string | null,
        createdBy?: string | null,
        updatedBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
      },
      cardSet:  {
        __typename: "CardSet",
        id: string,
        name: string,
        description?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          defaultScoreDefinitionId?: string | null,
        } | null,
        organisation: string,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardsOrderedByUpdatedAtWithScoreDataQueryVariables = {
  organisation: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsOrderedByUpdatedAtWithScoreDataQuery = {
  cardsOrderedByUpdatedAt?:  {
    __typename: "ModelCardConnection",
    items:  Array< {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          scoreDefinitionId: string,
          data:  Array< {
            __typename: "ScoreDatum",
            value: number,
            date: string,
            comment?: string | null,
            commentDate?: string | null,
            commentAuthor?: string | null,
          } >,
          organisation: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
        } >,
        defaultScoreDefinitionId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardsOrderedByCreatedAtWithScoreDataQueryVariables = {
  organisation: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsOrderedByCreatedAtWithScoreDataQuery = {
  cardsOrderedByCreatedAt?:  {
    __typename: "ModelCardConnection",
    items:  Array< {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          scoreDefinitionId: string,
          data:  Array< {
            __typename: "ScoreDatum",
            value: number,
            date: string,
            comment?: string | null,
            commentDate?: string | null,
            commentAuthor?: string | null,
          } >,
          organisation: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
        } >,
        defaultScoreDefinitionId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardsOrderedByNameWithScoreDataQueryVariables = {
  organisation: string,
  capitalName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsOrderedByNameWithScoreDataQuery = {
  cardsOrderedByName?:  {
    __typename: "ModelCardConnection",
    items:  Array< {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          scoreDefinitionId: string,
          data:  Array< {
            __typename: "ScoreDatum",
            value: number,
            date: string,
            comment?: string | null,
            commentDate?: string | null,
            commentAuthor?: string | null,
          } >,
          organisation: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
        } >,
        defaultScoreDefinitionId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardsOrderedByNameOnlyIdAndNameQueryVariables = {
  organisation: string,
  capitalName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsOrderedByNameOnlyIdAndNameQuery = {
  cardsOrderedByName?:  {
    __typename: "ModelCardConnection",
    items:  Array< {
      __typename: "Card",
      id: string,
      name: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCardDataWithScoreDataQueryVariables = {
  sort?: CardSort | null,
  limit?: number | null,
  nextToken?: string | null,
  filter?: CardFilter | null,
  context?: string | null,
};

export type GetCardDataWithScoreDataQuery = {
  getCardData?:  {
    __typename: "CardDataResponse",
    items?:  Array< {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          scoreDefinitionId: string,
          data:  Array< {
            __typename: "ScoreDatum",
            value: number,
            date: string,
            comment?: string | null,
            commentDate?: string | null,
            commentAuthor?: string | null,
          } >,
          organisation: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCardDataWithScoreDataBasicQueryVariables = {
  organisation: string,
  capitalName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCardDataWithScoreDataBasicQuery = {
  cardsOrderedByName?:  {
    __typename: "ModelCardConnection",
    items:  Array< {
      __typename: "Card",
      id: string,
      owner?: string | null,
      name: string,
      description?: string | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          scoreDefinitionId: string,
          data:  Array< {
            __typename: "ScoreDatum",
            value: number,
            date: string,
            comment?: string | null,
            commentDate?: string | null,
            commentAuthor?: string | null,
          } >,
          organisation: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardsCardSetsToDeleteByCardIdQueryVariables = {
  cardId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardsCardSetsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsCardSetsToDeleteByCardIdQuery = {
  cardsCardSetsByCardId?:  {
    __typename: "ModelCardsCardSetsConnection",
    items:  Array< {
      __typename: "CardsCardSets",
      organisation: string,
      id: string,
      cardId: string,
      cardSetId: string,
      cardSet:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        owner?: string | null,
        type: CardSetType,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCardSetWithCardsQueryVariables = {
  organisation: string,
  id: string,
};

export type GetCardSetWithCardsQuery = {
  getCardSet?:  {
    __typename: "CardSet",
    organisation: string,
    id: string,
    type: CardSetType,
    name: string,
    description?: string | null,
    hiddenWorksheets?: Array< string | null > | null,
    owner?: string | null,
    createdBy?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    toCards?:  {
      __typename: "ModelCardsCardSetsConnection",
      items:  Array< {
        __typename: "CardsCardSets",
        organisation: string,
        id: string,
        cardId: string,
        cardSetId: string,
        card:  {
          __typename: "Card",
          id: string,
          name: string,
          toCardCategory:  {
            __typename: "CardCategory",
            id: string,
            name: string,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          description?: string | null,
          attributes?:  Array< {
            __typename: "Attribute",
            attributeDefinitionID: string,
            value?: string | null,
          } | null > | null,
          toScoreData?:  {
            __typename: "ModelScoreDataConnection",
            items:  Array< {
              __typename: "ScoreData",
              cardId: string,
              scoreDefinitionId: string,
              data:  Array< {
                __typename: "ScoreDatum",
                value: number,
                date: string,
                comment?: string | null,
                commentDate?: string | null,
                commentAuthor?: string | null,
              } >,
              organisation: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          toCardType:  {
            __typename: "CardType",
            id: string,
            name?: string | null,
            cardCategoryID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            scoreDefinitions:  Array< {
              __typename: "ScoreDefinition",
              id: string,
              name: string,
              shortName?: string | null,
              description?: string | null,
              scoreType: ScoreType,
              minimumValue?: number | null,
              maximumValue?: number | null,
            } >,
            defaultScoreDefinitionId?: string | null,
          },
          toCardSets?:  {
            __typename: "ModelCardsCardSetsConnection",
            nextToken?: string | null,
          } | null,
          organisation: string,
          createdAt: string,
          updatedAt: string,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
          owner?: string | null,
          createdBy?: string | null,
          updatedBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
        },
        cardSet:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toCardCategory?:  {
      __typename: "CardCategory",
      id: string,
      name: string,
      description?: string | null,
      toCardTypes?:  {
        __typename: "ModelCardTypeConnection",
        items:  Array< {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          defaultScoreDefinitionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    toCardType?:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    updatedBy?: string | null,
    cardSetToCardCategoryId?: string | null,
    cardSetToCardTypeId?: string | null,
    counts?:  {
      __typename: "CountsResponse",
      comments?: number | null,
      worksheets?: number | null,
      cards?: number | null,
    } | null,
    processProgress?:  Array< {
      __typename: "ProcessStageProgress",
      stageId: string,
      progress?: number | null,
    } | null > | null,
  } | null,
};

export type GetPlaybookDataWithScoreQueryVariables = {
  playbookId?: string | null,
};

export type GetPlaybookDataWithScoreQuery = {
  getPlaybookData?:  {
    __typename: "PlaybookDataResponse",
    owner?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    organisation: string,
    id: string,
    name?: string | null,
    description?: string | null,
    workbooks?: Array< string | null > | null,
    pages:  Array< {
      __typename: "PlaybookPageDataResponse",
      id: string,
      playbookPageType: PlaybookPageType,
      title?: string | null,
      commentary?: string | null,
      cardTypeId?: string | null,
      worksheetId?: string | null,
      cardSets?: Array< string | null > | null,
      workbooks?: Array< string | null > | null,
      worksheets?: Array< string | null > | null,
      cards?: Array< string | null > | null,
      allCards?: Array< string | null > | null,
      include?: Array< string | null > | null,
      options?: string | null,
      ordinal?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } >,
    cards?:  Array< {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          scoreDefinitionId: string,
          organisation: string,
          createdAt: string,
          updatedAt: string,
          data:  Array< {
            __typename: "ScoreDatum",
            value: number,
            date: string,
            comment?: string | null,
            commentDate?: string | null,
            commentAuthor?: string | null,
          } >,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null > | null,
  } | null,
};

export type RelationshipsBySourceIdWithScoreDataQueryVariables = {
  sourceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RelationshipsBySourceIdWithScoreDataQuery = {
  relationshipsBySourceId?:  {
    __typename: "ModelRelationshipConnection",
    items:  Array< {
      __typename: "Relationship",
      organisation: string,
      id: string,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          items:  Array< {
            __typename: "ScoreData",
            cardId: string,
            scoreDefinitionId: string,
            data:  Array< {
              __typename: "ScoreDatum",
              value: number,
              date: string,
              comment?: string | null,
              commentDate?: string | null,
              commentAuthor?: string | null,
            } >,
            organisation: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          scoreDefinitions:  Array< {
            __typename: "ScoreDefinition",
            id: string,
            name: string,
            shortName?: string | null,
            description?: string | null,
            scoreType: ScoreType,
            minimumValue?: number | null,
            maximumValue?: number | null,
            target?: ScoreTarget | null,
          } >,
          defaultScoreDefinitionId?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWorkbooksBasicQueryVariables = {
  sort?: CardSort | null,
  limit?: number | null,
  nextToken?: string | null,
  filter?: WorkbookFilter | null,
};

export type GetWorkbooksBasicQuery = {
  getWorkbooks?:  {
    __typename: "CardSetDataResponse",
    items?:  Array< {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCardSetsBasicQueryVariables = {
  sort?: CardSort | null,
  limit?: number | null,
  nextToken?: string | null,
  filter?: CardSetFilter | null,
};

export type GetCardSetsBasicQuery = {
  getCardSets?:  {
    __typename: "CardSetDataResponse",
    items?:  Array< {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type AddOrRemoveCardsFromSetMutationVariables = {
  cardSetId: string,
  cardIds?: Array< string > | null,
};

export type AddOrRemoveCardsFromSetMutation = {
  addOrRemoveCardsFromSet?: string | null,
};

export type CreateCardTypeMutationVariables = {
  input: CreateCardTypeInput,
  condition?: ModelCardTypeConditionInput | null,
};

export type CreateCardTypeMutation = {
  createCardType?:  {
    __typename: "CardType",
    id: string,
    name?: string | null,
    cardCategoryID: string,
    toCards?:  {
      __typename: "ModelCardConnection",
      items:  Array< {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributeDefinitions?:  Array< {
      __typename: "AttributeDefinition",
      id: string,
      name: string,
      attributeType: AttributeType,
      configuration?: string | null,
    } | null > | null,
    scoreDefinitions:  Array< {
      __typename: "ScoreDefinition",
      id: string,
      name: string,
      shortName?: string | null,
      description?: string | null,
      scoreType: ScoreType,
      minimumValue?: number | null,
      maximumValue?: number | null,
      target?: ScoreTarget | null,
    } >,
    defaultScoreDefinitionId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCardTypeMutationVariables = {
  input: UpdateCardTypeInput,
  condition?: ModelCardTypeConditionInput | null,
};

export type UpdateCardTypeMutation = {
  updateCardType?:  {
    __typename: "CardType",
    id: string,
    name?: string | null,
    cardCategoryID: string,
    toCards?:  {
      __typename: "ModelCardConnection",
      items:  Array< {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributeDefinitions?:  Array< {
      __typename: "AttributeDefinition",
      id: string,
      name: string,
      attributeType: AttributeType,
      configuration?: string | null,
    } | null > | null,
    scoreDefinitions:  Array< {
      __typename: "ScoreDefinition",
      id: string,
      name: string,
      shortName?: string | null,
      description?: string | null,
      scoreType: ScoreType,
      minimumValue?: number | null,
      maximumValue?: number | null,
      target?: ScoreTarget | null,
    } >,
    defaultScoreDefinitionId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCardTypeMutationVariables = {
  input: DeleteCardTypeInput,
  condition?: ModelCardTypeConditionInput | null,
};

export type DeleteCardTypeMutation = {
  deleteCardType?:  {
    __typename: "CardType",
    id: string,
    name?: string | null,
    cardCategoryID: string,
    toCards?:  {
      __typename: "ModelCardConnection",
      items:  Array< {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributeDefinitions?:  Array< {
      __typename: "AttributeDefinition",
      id: string,
      name: string,
      attributeType: AttributeType,
      configuration?: string | null,
    } | null > | null,
    scoreDefinitions:  Array< {
      __typename: "ScoreDefinition",
      id: string,
      name: string,
      shortName?: string | null,
      description?: string | null,
      scoreType: ScoreType,
      minimumValue?: number | null,
      maximumValue?: number | null,
      target?: ScoreTarget | null,
    } >,
    defaultScoreDefinitionId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCardCategoryMutationVariables = {
  input: CreateCardCategoryInput,
  condition?: ModelCardCategoryConditionInput | null,
};

export type CreateCardCategoryMutation = {
  createCardCategory?:  {
    __typename: "CardCategory",
    id: string,
    name: string,
    description?: string | null,
    toCardTypes?:  {
      __typename: "ModelCardTypeConnection",
      items:  Array< {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCardCategoryMutationVariables = {
  input: UpdateCardCategoryInput,
  condition?: ModelCardCategoryConditionInput | null,
};

export type UpdateCardCategoryMutation = {
  updateCardCategory?:  {
    __typename: "CardCategory",
    id: string,
    name: string,
    description?: string | null,
    toCardTypes?:  {
      __typename: "ModelCardTypeConnection",
      items:  Array< {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCardCategoryMutationVariables = {
  input: DeleteCardCategoryInput,
  condition?: ModelCardCategoryConditionInput | null,
};

export type DeleteCardCategoryMutation = {
  deleteCardCategory?:  {
    __typename: "CardCategory",
    id: string,
    name: string,
    description?: string | null,
    toCardTypes?:  {
      __typename: "ModelCardTypeConnection",
      items:  Array< {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCardMutationVariables = {
  input: CreateCardInput,
  condition?: ModelCardConditionInput | null,
};

export type CreateCardMutation = {
  createCard?:  {
    __typename: "Card",
    organisation: string,
    id: string,
    owner?: string | null,
    createdBy?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    name: string,
    capitalName?: string | null,
    toCardCategory:  {
      __typename: "CardCategory",
      id: string,
      name: string,
      description?: string | null,
      toCardTypes?:  {
        __typename: "ModelCardTypeConnection",
        items:  Array< {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    description?: string | null,
    attributes?:  Array< {
      __typename: "Attribute",
      attributeDefinitionID: string,
      value?: string | null,
    } | null > | null,
    toAttributes?:  {
      __typename: "ModelCardAttributeConnection",
      items:  Array< {
        __typename: "CardAttribute",
        cardId: string,
        card?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        attributeDefinitionId: string,
        organisation: string,
        value?: string | null,
        refToCardId?: string | null,
        refCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        refToCardSetId?: string | null,
        refCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toScoreData?:  {
      __typename: "ModelScoreDataConnection",
      items:  Array< {
        __typename: "ScoreData",
        cardId: string,
        id: string,
        scoreDefinitionId: string,
        data:  Array< {
          __typename: "ScoreDatum",
          value: number,
          date: string,
          comment?: string | null,
          commentDate?: string | null,
          commentAuthor?: string | null,
        } >,
        organisation: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toCardType:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    toCardSets?:  {
      __typename: "ModelCardsCardSetsConnection",
      items:  Array< {
        __typename: "CardsCardSets",
        organisation: string,
        id: string,
        cardId: string,
        cardSetId: string,
        card:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        },
        cardSet:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        },
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    updatedBy?: string | null,
    deleteAfter?: number | null,
    _auditContextType?: EventContext | null,
    _auditContextId?: string | null,
    createdFromId?: string | null,
    createdFromIds?: Array< string | null > | null,
    cardTypeToCardsId?: string | null,
    cardToCardCategoryId: string,
    cardToCardTypeId: string,
  } | null,
};

export type UpdateCardMutationVariables = {
  input: UpdateCardInput,
  condition?: ModelCardConditionInput | null,
};

export type UpdateCardMutation = {
  updateCard?:  {
    __typename: "Card",
    organisation: string,
    id: string,
    owner?: string | null,
    createdBy?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    name: string,
    capitalName?: string | null,
    toCardCategory:  {
      __typename: "CardCategory",
      id: string,
      name: string,
      description?: string | null,
      toCardTypes?:  {
        __typename: "ModelCardTypeConnection",
        items:  Array< {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    description?: string | null,
    attributes?:  Array< {
      __typename: "Attribute",
      attributeDefinitionID: string,
      value?: string | null,
    } | null > | null,
    toAttributes?:  {
      __typename: "ModelCardAttributeConnection",
      items:  Array< {
        __typename: "CardAttribute",
        cardId: string,
        card?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        attributeDefinitionId: string,
        organisation: string,
        value?: string | null,
        refToCardId?: string | null,
        refCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        refToCardSetId?: string | null,
        refCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toScoreData?:  {
      __typename: "ModelScoreDataConnection",
      items:  Array< {
        __typename: "ScoreData",
        cardId: string,
        id: string,
        scoreDefinitionId: string,
        data:  Array< {
          __typename: "ScoreDatum",
          value: number,
          date: string,
          comment?: string | null,
          commentDate?: string | null,
          commentAuthor?: string | null,
        } >,
        organisation: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toCardType:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    toCardSets?:  {
      __typename: "ModelCardsCardSetsConnection",
      items:  Array< {
        __typename: "CardsCardSets",
        organisation: string,
        id: string,
        cardId: string,
        cardSetId: string,
        card:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        },
        cardSet:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        },
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    updatedBy?: string | null,
    deleteAfter?: number | null,
    _auditContextType?: EventContext | null,
    _auditContextId?: string | null,
    createdFromId?: string | null,
    createdFromIds?: Array< string | null > | null,
    cardTypeToCardsId?: string | null,
    cardToCardCategoryId: string,
    cardToCardTypeId: string,
  } | null,
};

export type DeleteCardMutationVariables = {
  input: DeleteCardInput,
  condition?: ModelCardConditionInput | null,
};

export type DeleteCardMutation = {
  deleteCard?:  {
    __typename: "Card",
    organisation: string,
    id: string,
    owner?: string | null,
    createdBy?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    name: string,
    capitalName?: string | null,
    toCardCategory:  {
      __typename: "CardCategory",
      id: string,
      name: string,
      description?: string | null,
      toCardTypes?:  {
        __typename: "ModelCardTypeConnection",
        items:  Array< {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    description?: string | null,
    attributes?:  Array< {
      __typename: "Attribute",
      attributeDefinitionID: string,
      value?: string | null,
    } | null > | null,
    toAttributes?:  {
      __typename: "ModelCardAttributeConnection",
      items:  Array< {
        __typename: "CardAttribute",
        cardId: string,
        card?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        attributeDefinitionId: string,
        organisation: string,
        value?: string | null,
        refToCardId?: string | null,
        refCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        refToCardSetId?: string | null,
        refCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toScoreData?:  {
      __typename: "ModelScoreDataConnection",
      items:  Array< {
        __typename: "ScoreData",
        cardId: string,
        id: string,
        scoreDefinitionId: string,
        data:  Array< {
          __typename: "ScoreDatum",
          value: number,
          date: string,
          comment?: string | null,
          commentDate?: string | null,
          commentAuthor?: string | null,
        } >,
        organisation: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toCardType:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    toCardSets?:  {
      __typename: "ModelCardsCardSetsConnection",
      items:  Array< {
        __typename: "CardsCardSets",
        organisation: string,
        id: string,
        cardId: string,
        cardSetId: string,
        card:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        },
        cardSet:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        },
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    updatedBy?: string | null,
    deleteAfter?: number | null,
    _auditContextType?: EventContext | null,
    _auditContextId?: string | null,
    createdFromId?: string | null,
    createdFromIds?: Array< string | null > | null,
    cardTypeToCardsId?: string | null,
    cardToCardCategoryId: string,
    cardToCardTypeId: string,
  } | null,
};

export type CreateCardAttributeMutationVariables = {
  input: CreateCardAttributeInput,
  condition?: ModelCardAttributeConditionInput | null,
};

export type CreateCardAttributeMutation = {
  createCardAttribute?:  {
    __typename: "CardAttribute",
    cardId: string,
    card?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    attributeDefinitionId: string,
    organisation: string,
    value?: string | null,
    refToCardId?: string | null,
    refCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    refToCardSetId?: string | null,
    refCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCardAttributeMutationVariables = {
  input: UpdateCardAttributeInput,
  condition?: ModelCardAttributeConditionInput | null,
};

export type UpdateCardAttributeMutation = {
  updateCardAttribute?:  {
    __typename: "CardAttribute",
    cardId: string,
    card?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    attributeDefinitionId: string,
    organisation: string,
    value?: string | null,
    refToCardId?: string | null,
    refCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    refToCardSetId?: string | null,
    refCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCardAttributeMutationVariables = {
  input: DeleteCardAttributeInput,
  condition?: ModelCardAttributeConditionInput | null,
};

export type DeleteCardAttributeMutation = {
  deleteCardAttribute?:  {
    __typename: "CardAttribute",
    cardId: string,
    card?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    attributeDefinitionId: string,
    organisation: string,
    value?: string | null,
    refToCardId?: string | null,
    refCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    refToCardSetId?: string | null,
    refCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCardSetMutationVariables = {
  input: CreateCardSetInput,
  condition?: ModelCardSetConditionInput | null,
};

export type CreateCardSetMutation = {
  createCardSet?:  {
    __typename: "CardSet",
    organisation: string,
    id: string,
    type: CardSetType,
    name: string,
    capitalName?: string | null,
    description?: string | null,
    owner?: string | null,
    createdBy?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    toCards?:  {
      __typename: "ModelCardsCardSetsConnection",
      items:  Array< {
        __typename: "CardsCardSets",
        organisation: string,
        id: string,
        cardId: string,
        cardSetId: string,
        card:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        },
        cardSet:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        },
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toCardCategory?:  {
      __typename: "CardCategory",
      id: string,
      name: string,
      description?: string | null,
      toCardTypes?:  {
        __typename: "ModelCardTypeConnection",
        items:  Array< {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    toCardType?:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    updatedBy?: string | null,
    hiddenWorksheets?: Array< string | null > | null,
    copiedFrom?: string | null,
    counts?:  {
      __typename: "CountsResponse",
      comments?: number | null,
      worksheets?: number | null,
      cards?: number | null,
    } | null,
    processProgress?:  Array< {
      __typename: "ProcessStageProgress",
      stageId: string,
      progress?: number | null,
    } | null > | null,
    cardSetToCardCategoryId?: string | null,
    cardSetToCardTypeId?: string | null,
  } | null,
};

export type UpdateCardSetMutationVariables = {
  input: UpdateCardSetInput,
  condition?: ModelCardSetConditionInput | null,
};

export type UpdateCardSetMutation = {
  updateCardSet?:  {
    __typename: "CardSet",
    organisation: string,
    id: string,
    type: CardSetType,
    name: string,
    capitalName?: string | null,
    description?: string | null,
    owner?: string | null,
    createdBy?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    toCards?:  {
      __typename: "ModelCardsCardSetsConnection",
      items:  Array< {
        __typename: "CardsCardSets",
        organisation: string,
        id: string,
        cardId: string,
        cardSetId: string,
        card:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        },
        cardSet:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        },
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toCardCategory?:  {
      __typename: "CardCategory",
      id: string,
      name: string,
      description?: string | null,
      toCardTypes?:  {
        __typename: "ModelCardTypeConnection",
        items:  Array< {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    toCardType?:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    updatedBy?: string | null,
    hiddenWorksheets?: Array< string | null > | null,
    copiedFrom?: string | null,
    counts?:  {
      __typename: "CountsResponse",
      comments?: number | null,
      worksheets?: number | null,
      cards?: number | null,
    } | null,
    processProgress?:  Array< {
      __typename: "ProcessStageProgress",
      stageId: string,
      progress?: number | null,
    } | null > | null,
    cardSetToCardCategoryId?: string | null,
    cardSetToCardTypeId?: string | null,
  } | null,
};

export type DeleteCardSetMutationVariables = {
  input: DeleteCardSetInput,
  condition?: ModelCardSetConditionInput | null,
};

export type DeleteCardSetMutation = {
  deleteCardSet?:  {
    __typename: "CardSet",
    organisation: string,
    id: string,
    type: CardSetType,
    name: string,
    capitalName?: string | null,
    description?: string | null,
    owner?: string | null,
    createdBy?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    toCards?:  {
      __typename: "ModelCardsCardSetsConnection",
      items:  Array< {
        __typename: "CardsCardSets",
        organisation: string,
        id: string,
        cardId: string,
        cardSetId: string,
        card:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        },
        cardSet:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        },
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toCardCategory?:  {
      __typename: "CardCategory",
      id: string,
      name: string,
      description?: string | null,
      toCardTypes?:  {
        __typename: "ModelCardTypeConnection",
        items:  Array< {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    toCardType?:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    updatedBy?: string | null,
    hiddenWorksheets?: Array< string | null > | null,
    copiedFrom?: string | null,
    counts?:  {
      __typename: "CountsResponse",
      comments?: number | null,
      worksheets?: number | null,
      cards?: number | null,
    } | null,
    processProgress?:  Array< {
      __typename: "ProcessStageProgress",
      stageId: string,
      progress?: number | null,
    } | null > | null,
    cardSetToCardCategoryId?: string | null,
    cardSetToCardTypeId?: string | null,
  } | null,
};

export type CreateCardsCardSetsMutationVariables = {
  input: CreateCardsCardSetsInput,
  condition?: ModelCardsCardSetsConditionInput | null,
};

export type CreateCardsCardSetsMutation = {
  createCardsCardSets?:  {
    __typename: "CardsCardSets",
    organisation: string,
    id: string,
    cardId: string,
    cardSetId: string,
    card:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    },
    cardSet:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    },
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCardsCardSetsMutationVariables = {
  input: UpdateCardsCardSetsInput,
  condition?: ModelCardsCardSetsConditionInput | null,
};

export type UpdateCardsCardSetsMutation = {
  updateCardsCardSets?:  {
    __typename: "CardsCardSets",
    organisation: string,
    id: string,
    cardId: string,
    cardSetId: string,
    card:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    },
    cardSet:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    },
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCardsCardSetsMutationVariables = {
  input: DeleteCardsCardSetsInput,
  condition?: ModelCardsCardSetsConditionInput | null,
};

export type DeleteCardsCardSetsMutation = {
  deleteCardsCardSets?:  {
    __typename: "CardsCardSets",
    organisation: string,
    id: string,
    cardId: string,
    cardSetId: string,
    card:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    },
    cardSet:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    },
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    contextId: string,
    id: string,
    organisation: string,
    contextType?: EntityType | null,
    createdAt: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    message?: string | null,
    reactions?:  {
      __typename: "ModelCommentReactionConnection",
      items:  Array< {
        __typename: "CommentReaction",
        commentId: string,
        commentContextId: string,
        commentContextType?: EntityType | null,
        commentCreatedAt: string,
        organisation: string,
        comment?:  {
          __typename: "Comment",
          contextId: string,
          id: string,
          organisation: string,
          contextType?: EntityType | null,
          createdAt: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          message?: string | null,
          updatedAt: string,
        } | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    contextId: string,
    id: string,
    organisation: string,
    contextType?: EntityType | null,
    createdAt: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    message?: string | null,
    reactions?:  {
      __typename: "ModelCommentReactionConnection",
      items:  Array< {
        __typename: "CommentReaction",
        commentId: string,
        commentContextId: string,
        commentContextType?: EntityType | null,
        commentCreatedAt: string,
        organisation: string,
        comment?:  {
          __typename: "Comment",
          contextId: string,
          id: string,
          organisation: string,
          contextType?: EntityType | null,
          createdAt: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          message?: string | null,
          updatedAt: string,
        } | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateCommentReactionMutationVariables = {
  input: CreateCommentReactionInput,
  condition?: ModelCommentReactionConditionInput | null,
};

export type CreateCommentReactionMutation = {
  createCommentReaction?:  {
    __typename: "CommentReaction",
    commentId: string,
    commentContextId: string,
    commentContextType?: EntityType | null,
    commentCreatedAt: string,
    organisation: string,
    comment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCommentReactionMutationVariables = {
  input: UpdateCommentReactionInput,
  condition?: ModelCommentReactionConditionInput | null,
};

export type UpdateCommentReactionMutation = {
  updateCommentReaction?:  {
    __typename: "CommentReaction",
    commentId: string,
    commentContextId: string,
    commentContextType?: EntityType | null,
    commentCreatedAt: string,
    organisation: string,
    comment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCommentReactionMutationVariables = {
  input: DeleteCommentReactionInput,
  condition?: ModelCommentReactionConditionInput | null,
};

export type DeleteCommentReactionMutation = {
  deleteCommentReaction?:  {
    __typename: "CommentReaction",
    commentId: string,
    commentContextId: string,
    commentContextType?: EntityType | null,
    commentCreatedAt: string,
    organisation: string,
    comment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    organisation: string,
    createdAt: string,
    id: string,
    type: NotificationType,
    entity: EntityType,
    userId?: string | null,
    deleteAfter?: number | null,
    cardId?: string | null,
    toCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    cardSetId?: string | null,
    cardSetType?: CardSetType | null,
    toCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    cardsCardSetsId?: string | null,
    toCardsCardSets?:  {
      __typename: "CardsCardSets",
      organisation: string,
      id: string,
      cardId: string,
      cardSetId: string,
      card:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      },
      cardSet:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      },
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    scoreDefinitionId?: string | null,
    toScoreData?:  {
      __typename: "ScoreData",
      cardId: string,
      id: string,
      scoreDefinitionId: string,
      data:  Array< {
        __typename: "ScoreDatum",
        value: number,
        date: string,
        comment?: string | null,
        commentDate?: string | null,
        commentAuthor?: string | null,
      } >,
      organisation: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookPageId?: string | null,
    commentContextId?: string | null,
    commentCreatedAt?: string | null,
    commentId?: string | null,
    toComment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    commentReactionCreatedBy?: string | null,
    toCommentReaction?:  {
      __typename: "CommentReaction",
      commentId: string,
      commentContextId: string,
      commentContextType?: EntityType | null,
      commentCreatedAt: string,
      organisation: string,
      comment?:  {
        __typename: "Comment",
        contextId: string,
        id: string,
        organisation: string,
        contextType?: EntityType | null,
        createdAt: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        message?: string | null,
        reactions?:  {
          __typename: "ModelCommentReactionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    worksheetId?: string | null,
    toWorksheet?:  {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null,
    relationshipId?: string | null,
    toRelationship?:  {
      __typename: "Relationship",
      organisation: string,
      id: string,
      type?: RelationshipType | null,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      sourceWorksheetId?: string | null,
      toSourceWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      sourcePlaybookPageId?: string | null,
      toSourcePlaybookPage?:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null,
    relationshipInfo?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    organisation: string,
    createdAt: string,
    id: string,
    type: NotificationType,
    entity: EntityType,
    userId?: string | null,
    deleteAfter?: number | null,
    cardId?: string | null,
    toCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    cardSetId?: string | null,
    cardSetType?: CardSetType | null,
    toCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    cardsCardSetsId?: string | null,
    toCardsCardSets?:  {
      __typename: "CardsCardSets",
      organisation: string,
      id: string,
      cardId: string,
      cardSetId: string,
      card:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      },
      cardSet:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      },
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    scoreDefinitionId?: string | null,
    toScoreData?:  {
      __typename: "ScoreData",
      cardId: string,
      id: string,
      scoreDefinitionId: string,
      data:  Array< {
        __typename: "ScoreDatum",
        value: number,
        date: string,
        comment?: string | null,
        commentDate?: string | null,
        commentAuthor?: string | null,
      } >,
      organisation: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookPageId?: string | null,
    commentContextId?: string | null,
    commentCreatedAt?: string | null,
    commentId?: string | null,
    toComment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    commentReactionCreatedBy?: string | null,
    toCommentReaction?:  {
      __typename: "CommentReaction",
      commentId: string,
      commentContextId: string,
      commentContextType?: EntityType | null,
      commentCreatedAt: string,
      organisation: string,
      comment?:  {
        __typename: "Comment",
        contextId: string,
        id: string,
        organisation: string,
        contextType?: EntityType | null,
        createdAt: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        message?: string | null,
        reactions?:  {
          __typename: "ModelCommentReactionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    worksheetId?: string | null,
    toWorksheet?:  {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null,
    relationshipId?: string | null,
    toRelationship?:  {
      __typename: "Relationship",
      organisation: string,
      id: string,
      type?: RelationshipType | null,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      sourceWorksheetId?: string | null,
      toSourceWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      sourcePlaybookPageId?: string | null,
      toSourcePlaybookPage?:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null,
    relationshipInfo?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    organisation: string,
    createdAt: string,
    id: string,
    type: NotificationType,
    entity: EntityType,
    userId?: string | null,
    deleteAfter?: number | null,
    cardId?: string | null,
    toCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    cardSetId?: string | null,
    cardSetType?: CardSetType | null,
    toCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    cardsCardSetsId?: string | null,
    toCardsCardSets?:  {
      __typename: "CardsCardSets",
      organisation: string,
      id: string,
      cardId: string,
      cardSetId: string,
      card:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      },
      cardSet:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      },
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    scoreDefinitionId?: string | null,
    toScoreData?:  {
      __typename: "ScoreData",
      cardId: string,
      id: string,
      scoreDefinitionId: string,
      data:  Array< {
        __typename: "ScoreDatum",
        value: number,
        date: string,
        comment?: string | null,
        commentDate?: string | null,
        commentAuthor?: string | null,
      } >,
      organisation: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookPageId?: string | null,
    commentContextId?: string | null,
    commentCreatedAt?: string | null,
    commentId?: string | null,
    toComment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    commentReactionCreatedBy?: string | null,
    toCommentReaction?:  {
      __typename: "CommentReaction",
      commentId: string,
      commentContextId: string,
      commentContextType?: EntityType | null,
      commentCreatedAt: string,
      organisation: string,
      comment?:  {
        __typename: "Comment",
        contextId: string,
        id: string,
        organisation: string,
        contextType?: EntityType | null,
        createdAt: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        message?: string | null,
        reactions?:  {
          __typename: "ModelCommentReactionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    worksheetId?: string | null,
    toWorksheet?:  {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null,
    relationshipId?: string | null,
    toRelationship?:  {
      __typename: "Relationship",
      organisation: string,
      id: string,
      type?: RelationshipType | null,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      sourceWorksheetId?: string | null,
      toSourceWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      sourcePlaybookPageId?: string | null,
      toSourcePlaybookPage?:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null,
    relationshipInfo?: string | null,
    updatedAt: string,
  } | null,
};

export type CreatePlaybookMutationVariables = {
  input: CreatePlaybookInput,
  condition?: ModelPlaybookConditionInput | null,
};

export type CreatePlaybookMutation = {
  createPlaybook?:  {
    __typename: "Playbook",
    organisation: string,
    id: string,
    name: string,
    description?: string | null,
    toPlaybookPages?:  {
      __typename: "ModelPlaybookPageConnection",
      items:  Array< {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    capitalName?: string | null,
    workbooks?: Array< string | null > | null,
  } | null,
};

export type UpdatePlaybookMutationVariables = {
  input: UpdatePlaybookInput,
  condition?: ModelPlaybookConditionInput | null,
};

export type UpdatePlaybookMutation = {
  updatePlaybook?:  {
    __typename: "Playbook",
    organisation: string,
    id: string,
    name: string,
    description?: string | null,
    toPlaybookPages?:  {
      __typename: "ModelPlaybookPageConnection",
      items:  Array< {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    capitalName?: string | null,
    workbooks?: Array< string | null > | null,
  } | null,
};

export type DeletePlaybookMutationVariables = {
  input: DeletePlaybookInput,
  condition?: ModelPlaybookConditionInput | null,
};

export type DeletePlaybookMutation = {
  deletePlaybook?:  {
    __typename: "Playbook",
    organisation: string,
    id: string,
    name: string,
    description?: string | null,
    toPlaybookPages?:  {
      __typename: "ModelPlaybookPageConnection",
      items:  Array< {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    capitalName?: string | null,
    workbooks?: Array< string | null > | null,
  } | null,
};

export type CreatePlaybookPageMutationVariables = {
  input: CreatePlaybookPageInput,
  condition?: ModelPlaybookPageConditionInput | null,
};

export type CreatePlaybookPageMutation = {
  createPlaybookPage?:  {
    __typename: "PlaybookPage",
    organisation: string,
    id: string,
    cardType?:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    commentary?: string | null,
    playbookPageType: PlaybookPageType,
    worksheetId?: string | null,
    ordinal?: number | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    workbookId?: string | null,
    title?: string | null,
    toCardSets?:  {
      __typename: "ModelPlaybookPageCardSetsConnection",
      items:  Array< {
        __typename: "PlaybookPageCardSets",
        organisation: string,
        id: string,
        cardSetId?: string | null,
        cardId?: string | null,
        playbookPageId: string,
        cardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        card?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        playbookPage:  {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    include?: Array< string | null > | null,
    options?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    cardSets?: Array< string | null > | null,
    workbooks?: Array< string | null > | null,
    cards?: Array< string | null > | null,
    worksheets?: Array< string | null > | null,
    playbookPageCardTypeId?: string | null,
  } | null,
};

export type UpdatePlaybookPageMutationVariables = {
  input: UpdatePlaybookPageInput,
  condition?: ModelPlaybookPageConditionInput | null,
};

export type UpdatePlaybookPageMutation = {
  updatePlaybookPage?:  {
    __typename: "PlaybookPage",
    organisation: string,
    id: string,
    cardType?:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    commentary?: string | null,
    playbookPageType: PlaybookPageType,
    worksheetId?: string | null,
    ordinal?: number | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    workbookId?: string | null,
    title?: string | null,
    toCardSets?:  {
      __typename: "ModelPlaybookPageCardSetsConnection",
      items:  Array< {
        __typename: "PlaybookPageCardSets",
        organisation: string,
        id: string,
        cardSetId?: string | null,
        cardId?: string | null,
        playbookPageId: string,
        cardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        card?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        playbookPage:  {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    include?: Array< string | null > | null,
    options?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    cardSets?: Array< string | null > | null,
    workbooks?: Array< string | null > | null,
    cards?: Array< string | null > | null,
    worksheets?: Array< string | null > | null,
    playbookPageCardTypeId?: string | null,
  } | null,
};

export type DeletePlaybookPageMutationVariables = {
  input: DeletePlaybookPageInput,
  condition?: ModelPlaybookPageConditionInput | null,
};

export type DeletePlaybookPageMutation = {
  deletePlaybookPage?:  {
    __typename: "PlaybookPage",
    organisation: string,
    id: string,
    cardType?:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    commentary?: string | null,
    playbookPageType: PlaybookPageType,
    worksheetId?: string | null,
    ordinal?: number | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    workbookId?: string | null,
    title?: string | null,
    toCardSets?:  {
      __typename: "ModelPlaybookPageCardSetsConnection",
      items:  Array< {
        __typename: "PlaybookPageCardSets",
        organisation: string,
        id: string,
        cardSetId?: string | null,
        cardId?: string | null,
        playbookPageId: string,
        cardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        card?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        playbookPage:  {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    include?: Array< string | null > | null,
    options?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    cardSets?: Array< string | null > | null,
    workbooks?: Array< string | null > | null,
    cards?: Array< string | null > | null,
    worksheets?: Array< string | null > | null,
    playbookPageCardTypeId?: string | null,
  } | null,
};

export type CreatePlaybookPageCardSetsMutationVariables = {
  input: CreatePlaybookPageCardSetsInput,
  condition?: ModelPlaybookPageCardSetsConditionInput | null,
};

export type CreatePlaybookPageCardSetsMutation = {
  createPlaybookPageCardSets?:  {
    __typename: "PlaybookPageCardSets",
    organisation: string,
    id: string,
    cardSetId?: string | null,
    cardId?: string | null,
    playbookPageId: string,
    cardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    card?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    playbookPage:  {
      __typename: "PlaybookPage",
      organisation: string,
      id: string,
      cardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      commentary?: string | null,
      playbookPageType: PlaybookPageType,
      worksheetId?: string | null,
      ordinal?: number | null,
      playbookId?: string | null,
      toPlaybook?:  {
        __typename: "Playbook",
        organisation: string,
        id: string,
        name: string,
        description?: string | null,
        toPlaybookPages?:  {
          __typename: "ModelPlaybookPageConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        capitalName?: string | null,
        workbooks?: Array< string | null > | null,
      } | null,
      workbookId?: string | null,
      title?: string | null,
      toCardSets?:  {
        __typename: "ModelPlaybookPageCardSetsConnection",
        items:  Array< {
          __typename: "PlaybookPageCardSets",
          organisation: string,
          id: string,
          cardSetId?: string | null,
          cardId?: string | null,
          playbookPageId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      include?: Array< string | null > | null,
      options?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      cardSets?: Array< string | null > | null,
      workbooks?: Array< string | null > | null,
      cards?: Array< string | null > | null,
      worksheets?: Array< string | null > | null,
      playbookPageCardTypeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePlaybookPageCardSetsMutationVariables = {
  input: UpdatePlaybookPageCardSetsInput,
  condition?: ModelPlaybookPageCardSetsConditionInput | null,
};

export type UpdatePlaybookPageCardSetsMutation = {
  updatePlaybookPageCardSets?:  {
    __typename: "PlaybookPageCardSets",
    organisation: string,
    id: string,
    cardSetId?: string | null,
    cardId?: string | null,
    playbookPageId: string,
    cardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    card?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    playbookPage:  {
      __typename: "PlaybookPage",
      organisation: string,
      id: string,
      cardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      commentary?: string | null,
      playbookPageType: PlaybookPageType,
      worksheetId?: string | null,
      ordinal?: number | null,
      playbookId?: string | null,
      toPlaybook?:  {
        __typename: "Playbook",
        organisation: string,
        id: string,
        name: string,
        description?: string | null,
        toPlaybookPages?:  {
          __typename: "ModelPlaybookPageConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        capitalName?: string | null,
        workbooks?: Array< string | null > | null,
      } | null,
      workbookId?: string | null,
      title?: string | null,
      toCardSets?:  {
        __typename: "ModelPlaybookPageCardSetsConnection",
        items:  Array< {
          __typename: "PlaybookPageCardSets",
          organisation: string,
          id: string,
          cardSetId?: string | null,
          cardId?: string | null,
          playbookPageId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      include?: Array< string | null > | null,
      options?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      cardSets?: Array< string | null > | null,
      workbooks?: Array< string | null > | null,
      cards?: Array< string | null > | null,
      worksheets?: Array< string | null > | null,
      playbookPageCardTypeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePlaybookPageCardSetsMutationVariables = {
  input: DeletePlaybookPageCardSetsInput,
  condition?: ModelPlaybookPageCardSetsConditionInput | null,
};

export type DeletePlaybookPageCardSetsMutation = {
  deletePlaybookPageCardSets?:  {
    __typename: "PlaybookPageCardSets",
    organisation: string,
    id: string,
    cardSetId?: string | null,
    cardId?: string | null,
    playbookPageId: string,
    cardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    card?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    playbookPage:  {
      __typename: "PlaybookPage",
      organisation: string,
      id: string,
      cardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      commentary?: string | null,
      playbookPageType: PlaybookPageType,
      worksheetId?: string | null,
      ordinal?: number | null,
      playbookId?: string | null,
      toPlaybook?:  {
        __typename: "Playbook",
        organisation: string,
        id: string,
        name: string,
        description?: string | null,
        toPlaybookPages?:  {
          __typename: "ModelPlaybookPageConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        capitalName?: string | null,
        workbooks?: Array< string | null > | null,
      } | null,
      workbookId?: string | null,
      title?: string | null,
      toCardSets?:  {
        __typename: "ModelPlaybookPageCardSetsConnection",
        items:  Array< {
          __typename: "PlaybookPageCardSets",
          organisation: string,
          id: string,
          cardSetId?: string | null,
          cardId?: string | null,
          playbookPageId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      include?: Array< string | null > | null,
      options?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      cardSets?: Array< string | null > | null,
      workbooks?: Array< string | null > | null,
      cards?: Array< string | null > | null,
      worksheets?: Array< string | null > | null,
      playbookPageCardTypeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrganisationMutationVariables = {
  input: CreateOrganisationInput,
  condition?: ModelOrganisationConditionInput | null,
};

export type CreateOrganisationMutation = {
  createOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name: string,
    owningUserProfileID: string,
    edition?: Edition | null,
    logo?: string | null,
    aiKey?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrganisationMutationVariables = {
  input: UpdateOrganisationInput,
  condition?: ModelOrganisationConditionInput | null,
};

export type UpdateOrganisationMutation = {
  updateOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name: string,
    owningUserProfileID: string,
    edition?: Edition | null,
    logo?: string | null,
    aiKey?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrganisationMutationVariables = {
  input: DeleteOrganisationInput,
  condition?: ModelOrganisationConditionInput | null,
};

export type DeleteOrganisationMutation = {
  deleteOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name: string,
    owningUserProfileID: string,
    edition?: Edition | null,
    logo?: string | null,
    aiKey?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserProfileMutationVariables = {
  input: CreateUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type CreateUserProfileMutation = {
  createUserProfile?:  {
    __typename: "UserProfile",
    organisation: string,
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    telephone?: string | null,
    title?: string | null,
    department?: string | null,
    location?: string | null,
    status?: UserStatus | null,
    lastStatusChange?: string | null,
    preferences?: string | null,
    avatar?: string | null,
    lastNotificationSeen?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserProfileMutationVariables = {
  input: UpdateUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type UpdateUserProfileMutation = {
  updateUserProfile?:  {
    __typename: "UserProfile",
    organisation: string,
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    telephone?: string | null,
    title?: string | null,
    department?: string | null,
    location?: string | null,
    status?: UserStatus | null,
    lastStatusChange?: string | null,
    preferences?: string | null,
    avatar?: string | null,
    lastNotificationSeen?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserProfileMutationVariables = {
  input: DeleteUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type DeleteUserProfileMutation = {
  deleteUserProfile?:  {
    __typename: "UserProfile",
    organisation: string,
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    telephone?: string | null,
    title?: string | null,
    department?: string | null,
    location?: string | null,
    status?: UserStatus | null,
    lastStatusChange?: string | null,
    preferences?: string | null,
    avatar?: string | null,
    lastNotificationSeen?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type AddUserMutationVariables = {
  email: string,
  firstName?: string | null,
  lastName?: string | null,
};

export type AddUserMutation = {
  addUser?: string | null,
};

export type ConvertCardMutationVariables = {
  cardId: string,
  newCardTypeId: string,
  name?: string | null,
  description?: string | null,
  attributes?: Array< AttributeInput | null > | null,
  _auditContextType?: EventContext | null,
  _auditContextId?: string | null,
};

export type ConvertCardMutation = {
  convertCard?: string | null,
};

export type CopyCardMutationVariables = {
  originalCardId?: string | null,
  _auditContextType?: EventContext | null,
  _auditContextId?: string | null,
};

export type CopyCardMutation = {
  copyCard?: string | null,
};

export type CopyCardSetMutationVariables = {
  originalCardSetId?: string | null,
  options?: CopyCardSetOptions | null,
};

export type CopyCardSetMutation = {
  copyCardSet?: string | null,
};

export type CreateOrUpdateScoreDataMutationVariables = {
  input?: CreateOrUpdateScoreDataInput | null,
};

export type CreateOrUpdateScoreDataMutation = {
  createOrUpdateScoreData?: string | null,
};

export type CreateRelationshipExtMutationVariables = {
  sourceId: string,
  sourceType: EntityType,
  targetId: string,
  targetType: EntityType,
};

export type CreateRelationshipExtMutation = {
  createRelationshipExt?:  {
    __typename: "Relationship",
    organisation: string,
    id: string,
    type?: RelationshipType | null,
    sourceId: string,
    sourceType?: EntityType | null,
    sourceCardId?: string | null,
    toSourceCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    sourceCardSetId?: string | null,
    toSourceCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    sourceWorksheetId?: string | null,
    toSourceWorksheet?:  {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null,
    sourcePlaybookPageId?: string | null,
    toSourcePlaybookPage?:  {
      __typename: "PlaybookPage",
      organisation: string,
      id: string,
      cardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      commentary?: string | null,
      playbookPageType: PlaybookPageType,
      worksheetId?: string | null,
      ordinal?: number | null,
      playbookId?: string | null,
      toPlaybook?:  {
        __typename: "Playbook",
        organisation: string,
        id: string,
        name: string,
        description?: string | null,
        toPlaybookPages?:  {
          __typename: "ModelPlaybookPageConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        capitalName?: string | null,
        workbooks?: Array< string | null > | null,
      } | null,
      workbookId?: string | null,
      title?: string | null,
      toCardSets?:  {
        __typename: "ModelPlaybookPageCardSetsConnection",
        items:  Array< {
          __typename: "PlaybookPageCardSets",
          organisation: string,
          id: string,
          cardSetId?: string | null,
          cardId?: string | null,
          playbookPageId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      include?: Array< string | null > | null,
      options?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      cardSets?: Array< string | null > | null,
      workbooks?: Array< string | null > | null,
      cards?: Array< string | null > | null,
      worksheets?: Array< string | null > | null,
      playbookPageCardTypeId?: string | null,
    } | null,
    targetId: string,
    targetType?: EntityType | null,
    targetCardId?: string | null,
    toTargetCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    targetCardSetId?: string | null,
    toTargetCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
  } | null,
};

export type CreateWorksheetExtMutationVariables = {
  parentId: string,
  typeId: string,
  ordinal: number,
  name?: string | null,
  commentary?: string | null,
  options?: string | null,
};

export type CreateWorksheetExtMutation = {
  createWorksheetExt?:  {
    __typename: "Worksheet",
    organisation: string,
    id: string,
    parentId: string,
    name?: string | null,
    commentary?: string | null,
    typeId: string,
    ordinal?: number | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    toDataSources?:  {
      __typename: "ModelRelationshipConnection",
      items:  Array< {
        __typename: "Relationship",
        organisation: string,
        id: string,
        type?: RelationshipType | null,
        sourceId: string,
        sourceType?: EntityType | null,
        sourceCardId?: string | null,
        toSourceCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        sourceCardSetId?: string | null,
        toSourceCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        sourceWorksheetId?: string | null,
        toSourceWorksheet?:  {
          __typename: "Worksheet",
          organisation: string,
          id: string,
          parentId: string,
          name?: string | null,
          commentary?: string | null,
          typeId: string,
          ordinal?: number | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          options?: string | null,
        } | null,
        sourcePlaybookPageId?: string | null,
        toSourcePlaybookPage?:  {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null,
        targetId: string,
        targetType?: EntityType | null,
        targetCardId?: string | null,
        toTargetCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        targetCardSetId?: string | null,
        toTargetCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    options?: string | null,
  } | null,
};

export type DeleteCommentExtMutationVariables = {
  contextId: string,
  createdAt: string,
  id: string,
};

export type DeleteCommentExtMutation = {
  deleteCommentExt?: string | null,
};

export type DeleteRelationshipExtMutationVariables = {
  id: string,
};

export type DeleteRelationshipExtMutation = {
  deleteRelationshipExt?: string | null,
};

export type DeleteUserMutationVariables = {
  userToDeleteId: string,
  newOwnerId?: string | null,
};

export type DeleteUserMutation = {
  deleteUser?: string | null,
};

export type DeleteWorksheetExtMutationVariables = {
  id: string,
};

export type DeleteWorksheetExtMutation = {
  deleteWorksheetExt?: string | null,
};

export type UpdateWorksheetExtMutationVariables = {
  id: string,
  ordinal?: number | null,
  name?: string | null,
  commentary?: string | null,
  options?: string | null,
};

export type UpdateWorksheetExtMutation = {
  updateWorksheetExt?:  {
    __typename: "Worksheet",
    organisation: string,
    id: string,
    parentId: string,
    name?: string | null,
    commentary?: string | null,
    typeId: string,
    ordinal?: number | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    toDataSources?:  {
      __typename: "ModelRelationshipConnection",
      items:  Array< {
        __typename: "Relationship",
        organisation: string,
        id: string,
        type?: RelationshipType | null,
        sourceId: string,
        sourceType?: EntityType | null,
        sourceCardId?: string | null,
        toSourceCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        sourceCardSetId?: string | null,
        toSourceCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        sourceWorksheetId?: string | null,
        toSourceWorksheet?:  {
          __typename: "Worksheet",
          organisation: string,
          id: string,
          parentId: string,
          name?: string | null,
          commentary?: string | null,
          typeId: string,
          ordinal?: number | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          options?: string | null,
        } | null,
        sourcePlaybookPageId?: string | null,
        toSourcePlaybookPage?:  {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null,
        targetId: string,
        targetType?: EntityType | null,
        targetCardId?: string | null,
        toTargetCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        targetCardSetId?: string | null,
        toTargetCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    options?: string | null,
  } | null,
};

export type GenerateSystemDataMutationVariables = {
  params?: Array< GenerateDataParam | null > | null,
};

export type GenerateSystemDataMutation = {
  generateSystemData?: string | null,
};

export type AiRequestQueryVariables = {
  params?: AiRequestParams | null,
};

export type AiRequestQuery = {
  aiRequest?:  {
    __typename: "AiResponse",
    content?: string | null,
    threadId?: string | null,
  } | null,
};

export type GetCardSetsQueryVariables = {
  sort?: CardSort | null,
  limit?: number | null,
  nextToken?: string | null,
  filter?: CardSetFilter | null,
};

export type GetCardSetsQuery = {
  getCardSets?:  {
    __typename: "CardSetDataResponse",
    items?:  Array< {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetWorkbooksQueryVariables = {
  sort?: CardSort | null,
  limit?: number | null,
  nextToken?: string | null,
  filter?: WorkbookFilter | null,
};

export type GetWorkbooksQuery = {
  getWorkbooks?:  {
    __typename: "CardSetDataResponse",
    items?:  Array< {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetPlaybooksQueryVariables = {
  sort?: CardSort | null,
  limit?: number | null,
  nextToken?: string | null,
  filter?: WorkbookFilter | null,
};

export type GetPlaybooksQuery = {
  getPlaybooks?:  {
    __typename: "GetPlaybooksResponse",
    items?:  Array< {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAuditEntryQueryVariables = {
  contextId: string,
  createdAt: string,
  id: string,
};

export type GetAuditEntryQuery = {
  getAuditEntry?:  {
    __typename: "AuditEntry",
    contextId: string,
    createdAt: string,
    id: string,
    organisation: string,
    deleteAfter?: number | null,
    operation?: AuditType | null,
    subContext?: string | null,
    contextType?: EntityType | null,
    subContextType?: EntityType | null,
    user?: string | null,
    system?: boolean | null,
    old?: string | null,
    new?: string | null,
    batchId?: string | null,
    eventContext?: EventContext | null,
    eventContextId?: string | null,
    relationshipType?: RelationshipType | null,
    updatedAt: string,
  } | null,
};

export type ListAuditEntriesQueryVariables = {
  contextId?: string | null,
  createdAtId?: ModelAuditEntryPrimaryCompositeKeyConditionInput | null,
  filter?: ModelAuditEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAuditEntriesQuery = {
  listAuditEntries?:  {
    __typename: "ModelAuditEntryConnection",
    items:  Array< {
      __typename: "AuditEntry",
      contextId: string,
      createdAt: string,
      id: string,
      organisation: string,
      deleteAfter?: number | null,
      operation?: AuditType | null,
      subContext?: string | null,
      contextType?: EntityType | null,
      subContextType?: EntityType | null,
      user?: string | null,
      system?: boolean | null,
      old?: string | null,
      new?: string | null,
      batchId?: string | null,
      eventContext?: EventContext | null,
      eventContextId?: string | null,
      relationshipType?: RelationshipType | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AuditEntriesByOrganisationQueryVariables = {
  organisation: string,
  createdAtId?: ModelAuditEntryByOrganisationCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAuditEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AuditEntriesByOrganisationQuery = {
  auditEntriesByOrganisation?:  {
    __typename: "ModelAuditEntryConnection",
    items:  Array< {
      __typename: "AuditEntry",
      contextId: string,
      createdAt: string,
      id: string,
      organisation: string,
      deleteAfter?: number | null,
      operation?: AuditType | null,
      subContext?: string | null,
      contextType?: EntityType | null,
      subContextType?: EntityType | null,
      user?: string | null,
      system?: boolean | null,
      old?: string | null,
      new?: string | null,
      batchId?: string | null,
      eventContext?: EventContext | null,
      eventContextId?: string | null,
      relationshipType?: RelationshipType | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AuditEntriesByUserQueryVariables = {
  user: string,
  createdAtId?: ModelAuditEntryByUserCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAuditEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AuditEntriesByUserQuery = {
  auditEntriesByUser?:  {
    __typename: "ModelAuditEntryConnection",
    items:  Array< {
      __typename: "AuditEntry",
      contextId: string,
      createdAt: string,
      id: string,
      organisation: string,
      deleteAfter?: number | null,
      operation?: AuditType | null,
      subContext?: string | null,
      contextType?: EntityType | null,
      subContextType?: EntityType | null,
      user?: string | null,
      system?: boolean | null,
      old?: string | null,
      new?: string | null,
      batchId?: string | null,
      eventContext?: EventContext | null,
      eventContextId?: string | null,
      relationshipType?: RelationshipType | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCardTypeQueryVariables = {
  id: string,
};

export type GetCardTypeQuery = {
  getCardType?:  {
    __typename: "CardType",
    id: string,
    name?: string | null,
    cardCategoryID: string,
    toCards?:  {
      __typename: "ModelCardConnection",
      items:  Array< {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributeDefinitions?:  Array< {
      __typename: "AttributeDefinition",
      id: string,
      name: string,
      attributeType: AttributeType,
      configuration?: string | null,
    } | null > | null,
    scoreDefinitions:  Array< {
      __typename: "ScoreDefinition",
      id: string,
      name: string,
      shortName?: string | null,
      description?: string | null,
      scoreType: ScoreType,
      minimumValue?: number | null,
      maximumValue?: number | null,
      target?: ScoreTarget | null,
    } >,
    defaultScoreDefinitionId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCardTypesQueryVariables = {
  filter?: ModelCardTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCardTypesQuery = {
  listCardTypes?:  {
    __typename: "ModelCardTypeConnection",
    items:  Array< {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardTypesByCardCategoryIDQueryVariables = {
  cardCategoryID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardTypesByCardCategoryIDQuery = {
  cardTypesByCardCategoryID?:  {
    __typename: "ModelCardTypeConnection",
    items:  Array< {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCardCategoryQueryVariables = {
  id: string,
};

export type GetCardCategoryQuery = {
  getCardCategory?:  {
    __typename: "CardCategory",
    id: string,
    name: string,
    description?: string | null,
    toCardTypes?:  {
      __typename: "ModelCardTypeConnection",
      items:  Array< {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCardCategoriesQueryVariables = {
  filter?: ModelCardCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCardCategoriesQuery = {
  listCardCategories?:  {
    __typename: "ModelCardCategoryConnection",
    items:  Array< {
      __typename: "CardCategory",
      id: string,
      name: string,
      description?: string | null,
      toCardTypes?:  {
        __typename: "ModelCardTypeConnection",
        items:  Array< {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCardQueryVariables = {
  organisation: string,
  id: string,
};

export type GetCardQuery = {
  getCard?:  {
    __typename: "Card",
    organisation: string,
    id: string,
    owner?: string | null,
    createdBy?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    name: string,
    capitalName?: string | null,
    toCardCategory:  {
      __typename: "CardCategory",
      id: string,
      name: string,
      description?: string | null,
      toCardTypes?:  {
        __typename: "ModelCardTypeConnection",
        items:  Array< {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    description?: string | null,
    attributes?:  Array< {
      __typename: "Attribute",
      attributeDefinitionID: string,
      value?: string | null,
    } | null > | null,
    toAttributes?:  {
      __typename: "ModelCardAttributeConnection",
      items:  Array< {
        __typename: "CardAttribute",
        cardId: string,
        card?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        attributeDefinitionId: string,
        organisation: string,
        value?: string | null,
        refToCardId?: string | null,
        refCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        refToCardSetId?: string | null,
        refCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toScoreData?:  {
      __typename: "ModelScoreDataConnection",
      items:  Array< {
        __typename: "ScoreData",
        cardId: string,
        id: string,
        scoreDefinitionId: string,
        data:  Array< {
          __typename: "ScoreDatum",
          value: number,
          date: string,
          comment?: string | null,
          commentDate?: string | null,
          commentAuthor?: string | null,
        } >,
        organisation: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toCardType:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    toCardSets?:  {
      __typename: "ModelCardsCardSetsConnection",
      items:  Array< {
        __typename: "CardsCardSets",
        organisation: string,
        id: string,
        cardId: string,
        cardSetId: string,
        card:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        },
        cardSet:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        },
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    updatedBy?: string | null,
    deleteAfter?: number | null,
    _auditContextType?: EventContext | null,
    _auditContextId?: string | null,
    createdFromId?: string | null,
    createdFromIds?: Array< string | null > | null,
    cardTypeToCardsId?: string | null,
    cardToCardCategoryId: string,
    cardToCardTypeId: string,
  } | null,
};

export type CardsOrderedByUpdatedAtQueryVariables = {
  organisation: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsOrderedByUpdatedAtQuery = {
  cardsOrderedByUpdatedAt?:  {
    __typename: "ModelCardConnection",
    items:  Array< {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardsOrderedByNameQueryVariables = {
  organisation: string,
  capitalName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsOrderedByNameQuery = {
  cardsOrderedByName?:  {
    __typename: "ModelCardConnection",
    items:  Array< {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardsOrderedByCreatedAtQueryVariables = {
  organisation: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsOrderedByCreatedAtQuery = {
  cardsOrderedByCreatedAt?:  {
    __typename: "ModelCardConnection",
    items:  Array< {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCardAttributeQueryVariables = {
  cardId: string,
  attributeDefinitionId: string,
};

export type GetCardAttributeQuery = {
  getCardAttribute?:  {
    __typename: "CardAttribute",
    cardId: string,
    card?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    attributeDefinitionId: string,
    organisation: string,
    value?: string | null,
    refToCardId?: string | null,
    refCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    refToCardSetId?: string | null,
    refCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCardAttributesQueryVariables = {
  cardId?: string | null,
  attributeDefinitionId?: ModelIDKeyConditionInput | null,
  filter?: ModelCardAttributeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCardAttributesQuery = {
  listCardAttributes?:  {
    __typename: "ModelCardAttributeConnection",
    items:  Array< {
      __typename: "CardAttribute",
      cardId: string,
      card?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      attributeDefinitionId: string,
      organisation: string,
      value?: string | null,
      refToCardId?: string | null,
      refCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      refToCardSetId?: string | null,
      refCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardAttributesReferencingCardQueryVariables = {
  refToCardId: string,
  attributeDefinitionId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardAttributeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardAttributesReferencingCardQuery = {
  cardAttributesReferencingCard?:  {
    __typename: "ModelCardAttributeConnection",
    items:  Array< {
      __typename: "CardAttribute",
      cardId: string,
      card?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      attributeDefinitionId: string,
      organisation: string,
      value?: string | null,
      refToCardId?: string | null,
      refCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      refToCardSetId?: string | null,
      refCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardAttributesReferencingCardSetQueryVariables = {
  refToCardSetId: string,
  attributeDefinitionId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardAttributeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardAttributesReferencingCardSetQuery = {
  cardAttributesReferencingCardSet?:  {
    __typename: "ModelCardAttributeConnection",
    items:  Array< {
      __typename: "CardAttribute",
      cardId: string,
      card?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      attributeDefinitionId: string,
      organisation: string,
      value?: string | null,
      refToCardId?: string | null,
      refCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      refToCardSetId?: string | null,
      refCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetScoreDataQueryVariables = {
  cardId: string,
  scoreDefinitionId: string,
};

export type GetScoreDataQuery = {
  getScoreData?:  {
    __typename: "ScoreData",
    cardId: string,
    id: string,
    scoreDefinitionId: string,
    data:  Array< {
      __typename: "ScoreDatum",
      value: number,
      date: string,
      comment?: string | null,
      commentDate?: string | null,
      commentAuthor?: string | null,
    } >,
    organisation: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListScoreDataQueryVariables = {
  cardId?: string | null,
  scoreDefinitionId?: ModelIDKeyConditionInput | null,
  filter?: ModelScoreDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListScoreDataQuery = {
  listScoreData?:  {
    __typename: "ModelScoreDataConnection",
    items:  Array< {
      __typename: "ScoreData",
      cardId: string,
      id: string,
      scoreDefinitionId: string,
      data:  Array< {
        __typename: "ScoreDatum",
        value: number,
        date: string,
        comment?: string | null,
        commentDate?: string | null,
        commentAuthor?: string | null,
      } >,
      organisation: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ScoreDataByCardIdQueryVariables = {
  cardId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelScoreDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ScoreDataByCardIdQuery = {
  scoreDataByCardId?:  {
    __typename: "ModelScoreDataConnection",
    items:  Array< {
      __typename: "ScoreData",
      cardId: string,
      id: string,
      scoreDefinitionId: string,
      data:  Array< {
        __typename: "ScoreDatum",
        value: number,
        date: string,
        comment?: string | null,
        commentDate?: string | null,
        commentAuthor?: string | null,
      } >,
      organisation: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ScoreDataByOrganisationQueryVariables = {
  organisation: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelScoreDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ScoreDataByOrganisationQuery = {
  scoreDataByOrganisation?:  {
    __typename: "ModelScoreDataConnection",
    items:  Array< {
      __typename: "ScoreData",
      cardId: string,
      id: string,
      scoreDefinitionId: string,
      data:  Array< {
        __typename: "ScoreDatum",
        value: number,
        date: string,
        comment?: string | null,
        commentDate?: string | null,
        commentAuthor?: string | null,
      } >,
      organisation: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCardSetQueryVariables = {
  organisation: string,
  id: string,
};

export type GetCardSetQuery = {
  getCardSet?:  {
    __typename: "CardSet",
    organisation: string,
    id: string,
    type: CardSetType,
    name: string,
    capitalName?: string | null,
    description?: string | null,
    owner?: string | null,
    createdBy?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    toCards?:  {
      __typename: "ModelCardsCardSetsConnection",
      items:  Array< {
        __typename: "CardsCardSets",
        organisation: string,
        id: string,
        cardId: string,
        cardSetId: string,
        card:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        },
        cardSet:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        },
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    toCardCategory?:  {
      __typename: "CardCategory",
      id: string,
      name: string,
      description?: string | null,
      toCardTypes?:  {
        __typename: "ModelCardTypeConnection",
        items:  Array< {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    toCardType?:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    updatedBy?: string | null,
    hiddenWorksheets?: Array< string | null > | null,
    copiedFrom?: string | null,
    counts?:  {
      __typename: "CountsResponse",
      comments?: number | null,
      worksheets?: number | null,
      cards?: number | null,
    } | null,
    processProgress?:  Array< {
      __typename: "ProcessStageProgress",
      stageId: string,
      progress?: number | null,
    } | null > | null,
    cardSetToCardCategoryId?: string | null,
    cardSetToCardTypeId?: string | null,
  } | null,
};

export type ListCardSetsQueryVariables = {
  organisation?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelCardSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCardSetsQuery = {
  listCardSets?:  {
    __typename: "ModelCardSetConnection",
    items:  Array< {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardSetsOrderedByTypeAndNameQueryVariables = {
  organisation: string,
  typeCapitalName?: ModelCardSetByOrgSortedByTypeAndNameCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardSetsOrderedByTypeAndNameQuery = {
  cardSetsOrderedByTypeAndName?:  {
    __typename: "ModelCardSetConnection",
    items:  Array< {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardSetsOrderedByTypeAndCreatedQueryVariables = {
  organisation: string,
  typeCreatedAt?: ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardSetsOrderedByTypeAndCreatedQuery = {
  cardSetsOrderedByTypeAndCreated?:  {
    __typename: "ModelCardSetConnection",
    items:  Array< {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardSetsOrderedByTypeAndUpdatedQueryVariables = {
  organisation: string,
  typeUpdatedAt?: ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardSetsOrderedByTypeAndUpdatedQuery = {
  cardSetsOrderedByTypeAndUpdated?:  {
    __typename: "ModelCardSetConnection",
    items:  Array< {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCardsCardSetsQueryVariables = {
  organisation: string,
  id: string,
};

export type GetCardsCardSetsQuery = {
  getCardsCardSets?:  {
    __typename: "CardsCardSets",
    organisation: string,
    id: string,
    cardId: string,
    cardSetId: string,
    card:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    },
    cardSet:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    },
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CardsCardSetsByCardIdQueryVariables = {
  cardId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardsCardSetsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsCardSetsByCardIdQuery = {
  cardsCardSetsByCardId?:  {
    __typename: "ModelCardsCardSetsConnection",
    items:  Array< {
      __typename: "CardsCardSets",
      organisation: string,
      id: string,
      cardId: string,
      cardSetId: string,
      card:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      },
      cardSet:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      },
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CardsCardSetsByCardSetIdQueryVariables = {
  cardSetId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardsCardSetsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CardsCardSetsByCardSetIdQuery = {
  cardsCardSetsByCardSetId?:  {
    __typename: "ModelCardsCardSetsConnection",
    items:  Array< {
      __typename: "CardsCardSets",
      organisation: string,
      id: string,
      cardId: string,
      cardSetId: string,
      card:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      },
      cardSet:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      },
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  contextId: string,
  createdAt: string,
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    contextId: string,
    id: string,
    organisation: string,
    contextType?: EntityType | null,
    createdAt: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    message?: string | null,
    reactions?:  {
      __typename: "ModelCommentReactionConnection",
      items:  Array< {
        __typename: "CommentReaction",
        commentId: string,
        commentContextId: string,
        commentContextType?: EntityType | null,
        commentCreatedAt: string,
        organisation: string,
        comment?:  {
          __typename: "Comment",
          contextId: string,
          id: string,
          organisation: string,
          contextType?: EntityType | null,
          createdAt: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          message?: string | null,
          updatedAt: string,
        } | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListCommentsQueryVariables = {
  contextId?: string | null,
  createdAtId?: ModelCommentPrimaryCompositeKeyConditionInput | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByOrganisationAndIdQueryVariables = {
  organisation: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByOrganisationAndIdQuery = {
  commentsByOrganisationAndId?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentReactionQueryVariables = {
  commentId: string,
  createdBy: string,
};

export type GetCommentReactionQuery = {
  getCommentReaction?:  {
    __typename: "CommentReaction",
    commentId: string,
    commentContextId: string,
    commentContextType?: EntityType | null,
    commentCreatedAt: string,
    organisation: string,
    comment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCommentReactionsQueryVariables = {
  commentId?: string | null,
  createdBy?: ModelStringKeyConditionInput | null,
  filter?: ModelCommentReactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCommentReactionsQuery = {
  listCommentReactions?:  {
    __typename: "ModelCommentReactionConnection",
    items:  Array< {
      __typename: "CommentReaction",
      commentId: string,
      commentContextId: string,
      commentContextType?: EntityType | null,
      commentCreatedAt: string,
      organisation: string,
      comment?:  {
        __typename: "Comment",
        contextId: string,
        id: string,
        organisation: string,
        contextType?: EntityType | null,
        createdAt: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        message?: string | null,
        reactions?:  {
          __typename: "ModelCommentReactionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  organisation: string,
  createdAt: string,
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    organisation: string,
    createdAt: string,
    id: string,
    type: NotificationType,
    entity: EntityType,
    userId?: string | null,
    deleteAfter?: number | null,
    cardId?: string | null,
    toCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    cardSetId?: string | null,
    cardSetType?: CardSetType | null,
    toCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    cardsCardSetsId?: string | null,
    toCardsCardSets?:  {
      __typename: "CardsCardSets",
      organisation: string,
      id: string,
      cardId: string,
      cardSetId: string,
      card:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      },
      cardSet:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      },
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    scoreDefinitionId?: string | null,
    toScoreData?:  {
      __typename: "ScoreData",
      cardId: string,
      id: string,
      scoreDefinitionId: string,
      data:  Array< {
        __typename: "ScoreDatum",
        value: number,
        date: string,
        comment?: string | null,
        commentDate?: string | null,
        commentAuthor?: string | null,
      } >,
      organisation: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookPageId?: string | null,
    commentContextId?: string | null,
    commentCreatedAt?: string | null,
    commentId?: string | null,
    toComment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    commentReactionCreatedBy?: string | null,
    toCommentReaction?:  {
      __typename: "CommentReaction",
      commentId: string,
      commentContextId: string,
      commentContextType?: EntityType | null,
      commentCreatedAt: string,
      organisation: string,
      comment?:  {
        __typename: "Comment",
        contextId: string,
        id: string,
        organisation: string,
        contextType?: EntityType | null,
        createdAt: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        message?: string | null,
        reactions?:  {
          __typename: "ModelCommentReactionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    worksheetId?: string | null,
    toWorksheet?:  {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null,
    relationshipId?: string | null,
    toRelationship?:  {
      __typename: "Relationship",
      organisation: string,
      id: string,
      type?: RelationshipType | null,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      sourceWorksheetId?: string | null,
      toSourceWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      sourcePlaybookPageId?: string | null,
      toSourcePlaybookPage?:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null,
    relationshipInfo?: string | null,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  organisation?: string | null,
  createdAtId?: ModelNotificationPrimaryCompositeKeyConditionInput | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      organisation: string,
      createdAt: string,
      id: string,
      type: NotificationType,
      entity: EntityType,
      userId?: string | null,
      deleteAfter?: number | null,
      cardId?: string | null,
      toCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      cardSetId?: string | null,
      cardSetType?: CardSetType | null,
      toCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      cardsCardSetsId?: string | null,
      toCardsCardSets?:  {
        __typename: "CardsCardSets",
        organisation: string,
        id: string,
        cardId: string,
        cardSetId: string,
        card:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        },
        cardSet:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        },
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      playbookId?: string | null,
      toPlaybook?:  {
        __typename: "Playbook",
        organisation: string,
        id: string,
        name: string,
        description?: string | null,
        toPlaybookPages?:  {
          __typename: "ModelPlaybookPageConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        capitalName?: string | null,
        workbooks?: Array< string | null > | null,
      } | null,
      scoreDefinitionId?: string | null,
      toScoreData?:  {
        __typename: "ScoreData",
        cardId: string,
        id: string,
        scoreDefinitionId: string,
        data:  Array< {
          __typename: "ScoreDatum",
          value: number,
          date: string,
          comment?: string | null,
          commentDate?: string | null,
          commentAuthor?: string | null,
        } >,
        organisation: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      playbookPageId?: string | null,
      commentContextId?: string | null,
      commentCreatedAt?: string | null,
      commentId?: string | null,
      toComment?:  {
        __typename: "Comment",
        contextId: string,
        id: string,
        organisation: string,
        contextType?: EntityType | null,
        createdAt: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        message?: string | null,
        reactions?:  {
          __typename: "ModelCommentReactionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      commentReactionCreatedBy?: string | null,
      toCommentReaction?:  {
        __typename: "CommentReaction",
        commentId: string,
        commentContextId: string,
        commentContextType?: EntityType | null,
        commentCreatedAt: string,
        organisation: string,
        comment?:  {
          __typename: "Comment",
          contextId: string,
          id: string,
          organisation: string,
          contextType?: EntityType | null,
          createdAt: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          message?: string | null,
          updatedAt: string,
        } | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      worksheetId?: string | null,
      toWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      relationshipId?: string | null,
      toRelationship?:  {
        __typename: "Relationship",
        organisation: string,
        id: string,
        type?: RelationshipType | null,
        sourceId: string,
        sourceType?: EntityType | null,
        sourceCardId?: string | null,
        toSourceCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        sourceCardSetId?: string | null,
        toSourceCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        sourceWorksheetId?: string | null,
        toSourceWorksheet?:  {
          __typename: "Worksheet",
          organisation: string,
          id: string,
          parentId: string,
          name?: string | null,
          commentary?: string | null,
          typeId: string,
          ordinal?: number | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          options?: string | null,
        } | null,
        sourcePlaybookPageId?: string | null,
        toSourcePlaybookPage?:  {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null,
        targetId: string,
        targetType?: EntityType | null,
        targetCardId?: string | null,
        toTargetCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        targetCardSetId?: string | null,
        toTargetCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
      } | null,
      relationshipInfo?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlaybookQueryVariables = {
  organisation: string,
  id: string,
};

export type GetPlaybookQuery = {
  getPlaybook?:  {
    __typename: "Playbook",
    organisation: string,
    id: string,
    name: string,
    description?: string | null,
    toPlaybookPages?:  {
      __typename: "ModelPlaybookPageConnection",
      items:  Array< {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    capitalName?: string | null,
    workbooks?: Array< string | null > | null,
  } | null,
};

export type ListPlaybooksQueryVariables = {
  organisation?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelPlaybookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPlaybooksQuery = {
  listPlaybooks?:  {
    __typename: "ModelPlaybookConnection",
    items:  Array< {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlaybooksOrderedByNameQueryVariables = {
  organisation: string,
  capitalName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaybookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlaybooksOrderedByNameQuery = {
  playbooksOrderedByName?:  {
    __typename: "ModelPlaybookConnection",
    items:  Array< {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlaybooksOrderedByCreatedAtQueryVariables = {
  organisation: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaybookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlaybooksOrderedByCreatedAtQuery = {
  playbooksOrderedByCreatedAt?:  {
    __typename: "ModelPlaybookConnection",
    items:  Array< {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlaybooksOrderedByUpdatedAtQueryVariables = {
  organisation: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaybookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlaybooksOrderedByUpdatedAtQuery = {
  playbooksOrderedByUpdatedAt?:  {
    __typename: "ModelPlaybookConnection",
    items:  Array< {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlaybookPageQueryVariables = {
  organisation: string,
  id: string,
};

export type GetPlaybookPageQuery = {
  getPlaybookPage?:  {
    __typename: "PlaybookPage",
    organisation: string,
    id: string,
    cardType?:  {
      __typename: "CardType",
      id: string,
      name?: string | null,
      cardCategoryID: string,
      toCards?:  {
        __typename: "ModelCardConnection",
        items:  Array< {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributeDefinitions?:  Array< {
        __typename: "AttributeDefinition",
        id: string,
        name: string,
        attributeType: AttributeType,
        configuration?: string | null,
      } | null > | null,
      scoreDefinitions:  Array< {
        __typename: "ScoreDefinition",
        id: string,
        name: string,
        shortName?: string | null,
        description?: string | null,
        scoreType: ScoreType,
        minimumValue?: number | null,
        maximumValue?: number | null,
        target?: ScoreTarget | null,
      } >,
      defaultScoreDefinitionId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    commentary?: string | null,
    playbookPageType: PlaybookPageType,
    worksheetId?: string | null,
    ordinal?: number | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    workbookId?: string | null,
    title?: string | null,
    toCardSets?:  {
      __typename: "ModelPlaybookPageCardSetsConnection",
      items:  Array< {
        __typename: "PlaybookPageCardSets",
        organisation: string,
        id: string,
        cardSetId?: string | null,
        cardId?: string | null,
        playbookPageId: string,
        cardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        card?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        playbookPage:  {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    include?: Array< string | null > | null,
    options?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    cardSets?: Array< string | null > | null,
    workbooks?: Array< string | null > | null,
    cards?: Array< string | null > | null,
    worksheets?: Array< string | null > | null,
    playbookPageCardTypeId?: string | null,
  } | null,
};

export type PlaybookPagesByPlaybookIdQueryVariables = {
  playbookId: string,
  ordinal?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaybookPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlaybookPagesByPlaybookIdQuery = {
  playbookPagesByPlaybookId?:  {
    __typename: "ModelPlaybookPageConnection",
    items:  Array< {
      __typename: "PlaybookPage",
      organisation: string,
      id: string,
      cardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      commentary?: string | null,
      playbookPageType: PlaybookPageType,
      worksheetId?: string | null,
      ordinal?: number | null,
      playbookId?: string | null,
      toPlaybook?:  {
        __typename: "Playbook",
        organisation: string,
        id: string,
        name: string,
        description?: string | null,
        toPlaybookPages?:  {
          __typename: "ModelPlaybookPageConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        capitalName?: string | null,
        workbooks?: Array< string | null > | null,
      } | null,
      workbookId?: string | null,
      title?: string | null,
      toCardSets?:  {
        __typename: "ModelPlaybookPageCardSetsConnection",
        items:  Array< {
          __typename: "PlaybookPageCardSets",
          organisation: string,
          id: string,
          cardSetId?: string | null,
          cardId?: string | null,
          playbookPageId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      include?: Array< string | null > | null,
      options?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      cardSets?: Array< string | null > | null,
      workbooks?: Array< string | null > | null,
      cards?: Array< string | null > | null,
      worksheets?: Array< string | null > | null,
      playbookPageCardTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlaybookPagesByWorkbookIdQueryVariables = {
  workbookId: string,
  ordinal?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaybookPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlaybookPagesByWorkbookIdQuery = {
  playbookPagesByWorkbookId?:  {
    __typename: "ModelPlaybookPageConnection",
    items:  Array< {
      __typename: "PlaybookPage",
      organisation: string,
      id: string,
      cardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      commentary?: string | null,
      playbookPageType: PlaybookPageType,
      worksheetId?: string | null,
      ordinal?: number | null,
      playbookId?: string | null,
      toPlaybook?:  {
        __typename: "Playbook",
        organisation: string,
        id: string,
        name: string,
        description?: string | null,
        toPlaybookPages?:  {
          __typename: "ModelPlaybookPageConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        capitalName?: string | null,
        workbooks?: Array< string | null > | null,
      } | null,
      workbookId?: string | null,
      title?: string | null,
      toCardSets?:  {
        __typename: "ModelPlaybookPageCardSetsConnection",
        items:  Array< {
          __typename: "PlaybookPageCardSets",
          organisation: string,
          id: string,
          cardSetId?: string | null,
          cardId?: string | null,
          playbookPageId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      include?: Array< string | null > | null,
      options?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      cardSets?: Array< string | null > | null,
      workbooks?: Array< string | null > | null,
      cards?: Array< string | null > | null,
      worksheets?: Array< string | null > | null,
      playbookPageCardTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlaybookPageCardSetsQueryVariables = {
  organisation: string,
  id: string,
};

export type GetPlaybookPageCardSetsQuery = {
  getPlaybookPageCardSets?:  {
    __typename: "PlaybookPageCardSets",
    organisation: string,
    id: string,
    cardSetId?: string | null,
    cardId?: string | null,
    playbookPageId: string,
    cardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    card?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    playbookPage:  {
      __typename: "PlaybookPage",
      organisation: string,
      id: string,
      cardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      commentary?: string | null,
      playbookPageType: PlaybookPageType,
      worksheetId?: string | null,
      ordinal?: number | null,
      playbookId?: string | null,
      toPlaybook?:  {
        __typename: "Playbook",
        organisation: string,
        id: string,
        name: string,
        description?: string | null,
        toPlaybookPages?:  {
          __typename: "ModelPlaybookPageConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        capitalName?: string | null,
        workbooks?: Array< string | null > | null,
      } | null,
      workbookId?: string | null,
      title?: string | null,
      toCardSets?:  {
        __typename: "ModelPlaybookPageCardSetsConnection",
        items:  Array< {
          __typename: "PlaybookPageCardSets",
          organisation: string,
          id: string,
          cardSetId?: string | null,
          cardId?: string | null,
          playbookPageId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      include?: Array< string | null > | null,
      options?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      cardSets?: Array< string | null > | null,
      workbooks?: Array< string | null > | null,
      cards?: Array< string | null > | null,
      worksheets?: Array< string | null > | null,
      playbookPageCardTypeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type PlaybookPageCardSetsByCardSetIdQueryVariables = {
  cardSetId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaybookPageCardSetsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlaybookPageCardSetsByCardSetIdQuery = {
  playbookPageCardSetsByCardSetId?:  {
    __typename: "ModelPlaybookPageCardSetsConnection",
    items:  Array< {
      __typename: "PlaybookPageCardSets",
      organisation: string,
      id: string,
      cardSetId?: string | null,
      cardId?: string | null,
      playbookPageId: string,
      cardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      card?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      playbookPage:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlaybookPageCardSetsByCardIdQueryVariables = {
  cardId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaybookPageCardSetsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlaybookPageCardSetsByCardIdQuery = {
  playbookPageCardSetsByCardId?:  {
    __typename: "ModelPlaybookPageCardSetsConnection",
    items:  Array< {
      __typename: "PlaybookPageCardSets",
      organisation: string,
      id: string,
      cardSetId?: string | null,
      cardId?: string | null,
      playbookPageId: string,
      cardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      card?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      playbookPage:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlaybookPageCardSetsByPlaybookPageIdQueryVariables = {
  playbookPageId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaybookPageCardSetsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlaybookPageCardSetsByPlaybookPageIdQuery = {
  playbookPageCardSetsByPlaybookPageId?:  {
    __typename: "ModelPlaybookPageCardSetsConnection",
    items:  Array< {
      __typename: "PlaybookPageCardSets",
      organisation: string,
      id: string,
      cardSetId?: string | null,
      cardId?: string | null,
      playbookPageId: string,
      cardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      card?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      playbookPage:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRelationshipQueryVariables = {
  organisation: string,
  id: string,
};

export type GetRelationshipQuery = {
  getRelationship?:  {
    __typename: "Relationship",
    organisation: string,
    id: string,
    type?: RelationshipType | null,
    sourceId: string,
    sourceType?: EntityType | null,
    sourceCardId?: string | null,
    toSourceCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    sourceCardSetId?: string | null,
    toSourceCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    sourceWorksheetId?: string | null,
    toSourceWorksheet?:  {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null,
    sourcePlaybookPageId?: string | null,
    toSourcePlaybookPage?:  {
      __typename: "PlaybookPage",
      organisation: string,
      id: string,
      cardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      commentary?: string | null,
      playbookPageType: PlaybookPageType,
      worksheetId?: string | null,
      ordinal?: number | null,
      playbookId?: string | null,
      toPlaybook?:  {
        __typename: "Playbook",
        organisation: string,
        id: string,
        name: string,
        description?: string | null,
        toPlaybookPages?:  {
          __typename: "ModelPlaybookPageConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        capitalName?: string | null,
        workbooks?: Array< string | null > | null,
      } | null,
      workbookId?: string | null,
      title?: string | null,
      toCardSets?:  {
        __typename: "ModelPlaybookPageCardSetsConnection",
        items:  Array< {
          __typename: "PlaybookPageCardSets",
          organisation: string,
          id: string,
          cardSetId?: string | null,
          cardId?: string | null,
          playbookPageId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      include?: Array< string | null > | null,
      options?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      cardSets?: Array< string | null > | null,
      workbooks?: Array< string | null > | null,
      cards?: Array< string | null > | null,
      worksheets?: Array< string | null > | null,
      playbookPageCardTypeId?: string | null,
    } | null,
    targetId: string,
    targetType?: EntityType | null,
    targetCardId?: string | null,
    toTargetCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    targetCardSetId?: string | null,
    toTargetCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
  } | null,
};

export type ListRelationshipsQueryVariables = {
  organisation?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRelationshipsQuery = {
  listRelationships?:  {
    __typename: "ModelRelationshipConnection",
    items:  Array< {
      __typename: "Relationship",
      organisation: string,
      id: string,
      type?: RelationshipType | null,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      sourceWorksheetId?: string | null,
      toSourceWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      sourcePlaybookPageId?: string | null,
      toSourcePlaybookPage?:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RelationshipsBySourceIdQueryVariables = {
  sourceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RelationshipsBySourceIdQuery = {
  relationshipsBySourceId?:  {
    __typename: "ModelRelationshipConnection",
    items:  Array< {
      __typename: "Relationship",
      organisation: string,
      id: string,
      type?: RelationshipType | null,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      sourceWorksheetId?: string | null,
      toSourceWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      sourcePlaybookPageId?: string | null,
      toSourcePlaybookPage?:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RelationshipsByTargetIdQueryVariables = {
  targetId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RelationshipsByTargetIdQuery = {
  relationshipsByTargetId?:  {
    __typename: "ModelRelationshipConnection",
    items:  Array< {
      __typename: "Relationship",
      organisation: string,
      id: string,
      type?: RelationshipType | null,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      sourceWorksheetId?: string | null,
      toSourceWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      sourcePlaybookPageId?: string | null,
      toSourcePlaybookPage?:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganisationQueryVariables = {
  id: string,
};

export type GetOrganisationQuery = {
  getOrganisation?:  {
    __typename: "Organisation",
    id: string,
    name: string,
    owningUserProfileID: string,
    edition?: Edition | null,
    logo?: string | null,
    aiKey?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OrganisationsByOwningUserProfileIDQueryVariables = {
  owningUserProfileID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganisationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganisationsByOwningUserProfileIDQuery = {
  organisationsByOwningUserProfileID?:  {
    __typename: "ModelOrganisationConnection",
    items:  Array< {
      __typename: "Organisation",
      id: string,
      name: string,
      owningUserProfileID: string,
      edition?: Edition | null,
      logo?: string | null,
      aiKey?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserProfileQueryVariables = {
  organisation: string,
  id: string,
};

export type GetUserProfileQuery = {
  getUserProfile?:  {
    __typename: "UserProfile",
    organisation: string,
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    telephone?: string | null,
    title?: string | null,
    department?: string | null,
    location?: string | null,
    status?: UserStatus | null,
    lastStatusChange?: string | null,
    preferences?: string | null,
    avatar?: string | null,
    lastNotificationSeen?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserProfilesQueryVariables = {
  organisation?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelUserProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserProfilesQuery = {
  listUserProfiles?:  {
    __typename: "ModelUserProfileConnection",
    items:  Array< {
      __typename: "UserProfile",
      organisation: string,
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      telephone?: string | null,
      title?: string | null,
      department?: string | null,
      location?: string | null,
      status?: UserStatus | null,
      lastStatusChange?: string | null,
      preferences?: string | null,
      avatar?: string | null,
      lastNotificationSeen?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWorksheetQueryVariables = {
  organisation: string,
  id: string,
};

export type GetWorksheetQuery = {
  getWorksheet?:  {
    __typename: "Worksheet",
    organisation: string,
    id: string,
    parentId: string,
    name?: string | null,
    commentary?: string | null,
    typeId: string,
    ordinal?: number | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    toDataSources?:  {
      __typename: "ModelRelationshipConnection",
      items:  Array< {
        __typename: "Relationship",
        organisation: string,
        id: string,
        type?: RelationshipType | null,
        sourceId: string,
        sourceType?: EntityType | null,
        sourceCardId?: string | null,
        toSourceCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        sourceCardSetId?: string | null,
        toSourceCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        sourceWorksheetId?: string | null,
        toSourceWorksheet?:  {
          __typename: "Worksheet",
          organisation: string,
          id: string,
          parentId: string,
          name?: string | null,
          commentary?: string | null,
          typeId: string,
          ordinal?: number | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          options?: string | null,
        } | null,
        sourcePlaybookPageId?: string | null,
        toSourcePlaybookPage?:  {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null,
        targetId: string,
        targetType?: EntityType | null,
        targetCardId?: string | null,
        toTargetCard?:  {
          __typename: "Card",
          organisation: string,
          id: string,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          deleteAfter?: number | null,
          _auditContextType?: EventContext | null,
          _auditContextId?: string | null,
          createdFromId?: string | null,
          createdFromIds?: Array< string | null > | null,
          cardTypeToCardsId?: string | null,
          cardToCardCategoryId: string,
          cardToCardTypeId: string,
        } | null,
        targetCardSetId?: string | null,
        toTargetCardSet?:  {
          __typename: "CardSet",
          organisation: string,
          id: string,
          type: CardSetType,
          name: string,
          capitalName?: string | null,
          description?: string | null,
          owner?: string | null,
          createdBy?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          updatedAt: string,
          updatedBy?: string | null,
          hiddenWorksheets?: Array< string | null > | null,
          copiedFrom?: string | null,
          cardSetToCardCategoryId?: string | null,
          cardSetToCardTypeId?: string | null,
        } | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    options?: string | null,
  } | null,
};

export type ListWorksheetsQueryVariables = {
  organisation?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelWorksheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWorksheetsQuery = {
  listWorksheets?:  {
    __typename: "ModelWorksheetConnection",
    items:  Array< {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WorksheetsByParentIdQueryVariables = {
  parentId: string,
  ordinal?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorksheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WorksheetsByParentIdQuery = {
  worksheetsByParentId?:  {
    __typename: "ModelWorksheetConnection",
    items:  Array< {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAuditDataQueryVariables = {
  contextIds?: Array< string | null > | null,
  nextTokens?: string | null,
  filter?: AuditFilter | null,
  limit?: number | null,
};

export type GetAuditDataQuery = {
  getAuditData?:  {
    __typename: "GetAuditDataResponse",
    names?: string | null,
    items?:  Array< {
      __typename: "AuditEntry",
      contextId: string,
      createdAt: string,
      id: string,
      organisation: string,
      deleteAfter?: number | null,
      operation?: AuditType | null,
      subContext?: string | null,
      contextType?: EntityType | null,
      subContextType?: EntityType | null,
      user?: string | null,
      system?: boolean | null,
      old?: string | null,
      new?: string | null,
      batchId?: string | null,
      eventContext?: EventContext | null,
      eventContextId?: string | null,
      relationshipType?: RelationshipType | null,
      updatedAt: string,
    } | null > | null,
    nextTokens?: string | null,
  } | null,
};

export type GetCardDataQueryVariables = {
  sort?: CardSort | null,
  limit?: number | null,
  nextToken?: string | null,
  filter?: CardFilter | null,
  context?: string | null,
};

export type GetCardDataQuery = {
  getCardData?:  {
    __typename: "CardDataResponse",
    items?:  Array< {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetPlaybookDataQueryVariables = {
  playbookId?: string | null,
};

export type GetPlaybookDataQuery = {
  getPlaybookData?:  {
    __typename: "PlaybookDataResponse",
    organisation: string,
    id: string,
    name?: string | null,
    description?: string | null,
    pages:  Array< {
      __typename: "PlaybookPageDataResponse",
      id: string,
      playbookPageType: PlaybookPageType,
      worksheetId?: string | null,
      title?: string | null,
      commentary?: string | null,
      cardTypeId?: string | null,
      cardSets?: Array< string | null > | null,
      workbooks?: Array< string | null > | null,
      worksheets?: Array< string | null > | null,
      cards?: Array< string | null > | null,
      allCards?: Array< string | null > | null,
      include?: Array< string | null > | null,
      options?: string | null,
      ordinal?: number | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      createdBy?: string | null,
    } >,
    cards?:  Array< {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null > | null,
    owner?: string | null,
    editors?: Array< string > | null,
    orgEdit?: string | null,
    workbooks?: Array< string | null > | null,
  } | null,
};

export type GetUserNotificationsQueryVariables = {
  nextToken?: string | null,
  limit?: number | null,
  cutoffTS?: string | null,
  filter?: UserNotificationFilter | null,
};

export type GetUserNotificationsQuery = {
  getUserNotifications?:  {
    __typename: "UserNotificationResponse",
    names?: string | null,
    entries?:  Array< {
      __typename: "AuditEntry",
      contextId: string,
      createdAt: string,
      id: string,
      organisation: string,
      deleteAfter?: number | null,
      operation?: AuditType | null,
      subContext?: string | null,
      contextType?: EntityType | null,
      subContextType?: EntityType | null,
      user?: string | null,
      system?: boolean | null,
      old?: string | null,
      new?: string | null,
      batchId?: string | null,
      eventContext?: EventContext | null,
      eventContextId?: string | null,
      relationshipType?: RelationshipType | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    organisation: string,
    createdAt: string,
    id: string,
    type: NotificationType,
    entity: EntityType,
    userId?: string | null,
    deleteAfter?: number | null,
    cardId?: string | null,
    toCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    cardSetId?: string | null,
    cardSetType?: CardSetType | null,
    toCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    cardsCardSetsId?: string | null,
    toCardsCardSets?:  {
      __typename: "CardsCardSets",
      organisation: string,
      id: string,
      cardId: string,
      cardSetId: string,
      card:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      },
      cardSet:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      },
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    scoreDefinitionId?: string | null,
    toScoreData?:  {
      __typename: "ScoreData",
      cardId: string,
      id: string,
      scoreDefinitionId: string,
      data:  Array< {
        __typename: "ScoreDatum",
        value: number,
        date: string,
        comment?: string | null,
        commentDate?: string | null,
        commentAuthor?: string | null,
      } >,
      organisation: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookPageId?: string | null,
    commentContextId?: string | null,
    commentCreatedAt?: string | null,
    commentId?: string | null,
    toComment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    commentReactionCreatedBy?: string | null,
    toCommentReaction?:  {
      __typename: "CommentReaction",
      commentId: string,
      commentContextId: string,
      commentContextType?: EntityType | null,
      commentCreatedAt: string,
      organisation: string,
      comment?:  {
        __typename: "Comment",
        contextId: string,
        id: string,
        organisation: string,
        contextType?: EntityType | null,
        createdAt: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        message?: string | null,
        reactions?:  {
          __typename: "ModelCommentReactionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    worksheetId?: string | null,
    toWorksheet?:  {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null,
    relationshipId?: string | null,
    toRelationship?:  {
      __typename: "Relationship",
      organisation: string,
      id: string,
      type?: RelationshipType | null,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      sourceWorksheetId?: string | null,
      toSourceWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      sourcePlaybookPageId?: string | null,
      toSourcePlaybookPage?:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null,
    relationshipInfo?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    organisation: string,
    createdAt: string,
    id: string,
    type: NotificationType,
    entity: EntityType,
    userId?: string | null,
    deleteAfter?: number | null,
    cardId?: string | null,
    toCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    cardSetId?: string | null,
    cardSetType?: CardSetType | null,
    toCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    cardsCardSetsId?: string | null,
    toCardsCardSets?:  {
      __typename: "CardsCardSets",
      organisation: string,
      id: string,
      cardId: string,
      cardSetId: string,
      card:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      },
      cardSet:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      },
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    scoreDefinitionId?: string | null,
    toScoreData?:  {
      __typename: "ScoreData",
      cardId: string,
      id: string,
      scoreDefinitionId: string,
      data:  Array< {
        __typename: "ScoreDatum",
        value: number,
        date: string,
        comment?: string | null,
        commentDate?: string | null,
        commentAuthor?: string | null,
      } >,
      organisation: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookPageId?: string | null,
    commentContextId?: string | null,
    commentCreatedAt?: string | null,
    commentId?: string | null,
    toComment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    commentReactionCreatedBy?: string | null,
    toCommentReaction?:  {
      __typename: "CommentReaction",
      commentId: string,
      commentContextId: string,
      commentContextType?: EntityType | null,
      commentCreatedAt: string,
      organisation: string,
      comment?:  {
        __typename: "Comment",
        contextId: string,
        id: string,
        organisation: string,
        contextType?: EntityType | null,
        createdAt: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        message?: string | null,
        reactions?:  {
          __typename: "ModelCommentReactionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    worksheetId?: string | null,
    toWorksheet?:  {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null,
    relationshipId?: string | null,
    toRelationship?:  {
      __typename: "Relationship",
      organisation: string,
      id: string,
      type?: RelationshipType | null,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      sourceWorksheetId?: string | null,
      toSourceWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      sourcePlaybookPageId?: string | null,
      toSourcePlaybookPage?:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null,
    relationshipInfo?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    organisation: string,
    createdAt: string,
    id: string,
    type: NotificationType,
    entity: EntityType,
    userId?: string | null,
    deleteAfter?: number | null,
    cardId?: string | null,
    toCard?:  {
      __typename: "Card",
      organisation: string,
      id: string,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      name: string,
      capitalName?: string | null,
      toCardCategory:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      description?: string | null,
      attributes?:  Array< {
        __typename: "Attribute",
        attributeDefinitionID: string,
        value?: string | null,
      } | null > | null,
      toAttributes?:  {
        __typename: "ModelCardAttributeConnection",
        items:  Array< {
          __typename: "CardAttribute",
          cardId: string,
          attributeDefinitionId: string,
          organisation: string,
          value?: string | null,
          refToCardId?: string | null,
          refToCardSetId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toScoreData?:  {
        __typename: "ModelScoreDataConnection",
        items:  Array< {
          __typename: "ScoreData",
          cardId: string,
          id: string,
          scoreDefinitionId: string,
          organisation: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardType:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      toCardSets?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      deleteAfter?: number | null,
      _auditContextType?: EventContext | null,
      _auditContextId?: string | null,
      createdFromId?: string | null,
      createdFromIds?: Array< string | null > | null,
      cardTypeToCardsId?: string | null,
      cardToCardCategoryId: string,
      cardToCardTypeId: string,
    } | null,
    cardSetId?: string | null,
    cardSetType?: CardSetType | null,
    toCardSet?:  {
      __typename: "CardSet",
      organisation: string,
      id: string,
      type: CardSetType,
      name: string,
      capitalName?: string | null,
      description?: string | null,
      owner?: string | null,
      createdBy?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      toCards?:  {
        __typename: "ModelCardsCardSetsConnection",
        items:  Array< {
          __typename: "CardsCardSets",
          organisation: string,
          id: string,
          cardId: string,
          cardSetId: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      toCardCategory?:  {
        __typename: "CardCategory",
        id: string,
        name: string,
        description?: string | null,
        toCardTypes?:  {
          __typename: "ModelCardTypeConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      toCardType?:  {
        __typename: "CardType",
        id: string,
        name?: string | null,
        cardCategoryID: string,
        toCards?:  {
          __typename: "ModelCardConnection",
          nextToken?: string | null,
        } | null,
        attributeDefinitions?:  Array< {
          __typename: "AttributeDefinition",
          id: string,
          name: string,
          attributeType: AttributeType,
          configuration?: string | null,
        } | null > | null,
        scoreDefinitions:  Array< {
          __typename: "ScoreDefinition",
          id: string,
          name: string,
          shortName?: string | null,
          description?: string | null,
          scoreType: ScoreType,
          minimumValue?: number | null,
          maximumValue?: number | null,
          target?: ScoreTarget | null,
        } >,
        defaultScoreDefinitionId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
      hiddenWorksheets?: Array< string | null > | null,
      copiedFrom?: string | null,
      counts?:  {
        __typename: "CountsResponse",
        comments?: number | null,
        worksheets?: number | null,
        cards?: number | null,
      } | null,
      processProgress?:  Array< {
        __typename: "ProcessStageProgress",
        stageId: string,
        progress?: number | null,
      } | null > | null,
      cardSetToCardCategoryId?: string | null,
      cardSetToCardTypeId?: string | null,
    } | null,
    cardsCardSetsId?: string | null,
    toCardsCardSets?:  {
      __typename: "CardsCardSets",
      organisation: string,
      id: string,
      cardId: string,
      cardSetId: string,
      card:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      },
      cardSet:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      },
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookId?: string | null,
    toPlaybook?:  {
      __typename: "Playbook",
      organisation: string,
      id: string,
      name: string,
      description?: string | null,
      toPlaybookPages?:  {
        __typename: "ModelPlaybookPageConnection",
        items:  Array< {
          __typename: "PlaybookPage",
          organisation: string,
          id: string,
          commentary?: string | null,
          playbookPageType: PlaybookPageType,
          worksheetId?: string | null,
          ordinal?: number | null,
          playbookId?: string | null,
          workbookId?: string | null,
          title?: string | null,
          include?: Array< string | null > | null,
          options?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          cardSets?: Array< string | null > | null,
          workbooks?: Array< string | null > | null,
          cards?: Array< string | null > | null,
          worksheets?: Array< string | null > | null,
          playbookPageCardTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string > | null,
      orgEdit?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      capitalName?: string | null,
      workbooks?: Array< string | null > | null,
    } | null,
    scoreDefinitionId?: string | null,
    toScoreData?:  {
      __typename: "ScoreData",
      cardId: string,
      id: string,
      scoreDefinitionId: string,
      data:  Array< {
        __typename: "ScoreDatum",
        value: number,
        date: string,
        comment?: string | null,
        commentDate?: string | null,
        commentAuthor?: string | null,
      } >,
      organisation: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    playbookPageId?: string | null,
    commentContextId?: string | null,
    commentCreatedAt?: string | null,
    commentId?: string | null,
    toComment?:  {
      __typename: "Comment",
      contextId: string,
      id: string,
      organisation: string,
      contextType?: EntityType | null,
      createdAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      message?: string | null,
      reactions?:  {
        __typename: "ModelCommentReactionConnection",
        items:  Array< {
          __typename: "CommentReaction",
          commentId: string,
          commentContextId: string,
          commentContextType?: EntityType | null,
          commentCreatedAt: string,
          organisation: string,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    commentReactionCreatedBy?: string | null,
    toCommentReaction?:  {
      __typename: "CommentReaction",
      commentId: string,
      commentContextId: string,
      commentContextType?: EntityType | null,
      commentCreatedAt: string,
      organisation: string,
      comment?:  {
        __typename: "Comment",
        contextId: string,
        id: string,
        organisation: string,
        contextType?: EntityType | null,
        createdAt: string,
        createdBy?: string | null,
        updatedBy?: string | null,
        message?: string | null,
        reactions?:  {
          __typename: "ModelCommentReactionConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    worksheetId?: string | null,
    toWorksheet?:  {
      __typename: "Worksheet",
      organisation: string,
      id: string,
      parentId: string,
      name?: string | null,
      commentary?: string | null,
      typeId: string,
      ordinal?: number | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      toDataSources?:  {
        __typename: "ModelRelationshipConnection",
        items:  Array< {
          __typename: "Relationship",
          organisation: string,
          id: string,
          type?: RelationshipType | null,
          sourceId: string,
          sourceType?: EntityType | null,
          sourceCardId?: string | null,
          sourceCardSetId?: string | null,
          sourceWorksheetId?: string | null,
          sourcePlaybookPageId?: string | null,
          targetId: string,
          targetType?: EntityType | null,
          targetCardId?: string | null,
          targetCardSetId?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      options?: string | null,
    } | null,
    relationshipId?: string | null,
    toRelationship?:  {
      __typename: "Relationship",
      organisation: string,
      id: string,
      type?: RelationshipType | null,
      sourceId: string,
      sourceType?: EntityType | null,
      sourceCardId?: string | null,
      toSourceCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      sourceCardSetId?: string | null,
      toSourceCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      sourceWorksheetId?: string | null,
      toSourceWorksheet?:  {
        __typename: "Worksheet",
        organisation: string,
        id: string,
        parentId: string,
        name?: string | null,
        commentary?: string | null,
        typeId: string,
        ordinal?: number | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        toDataSources?:  {
          __typename: "ModelRelationshipConnection",
          nextToken?: string | null,
        } | null,
        options?: string | null,
      } | null,
      sourcePlaybookPageId?: string | null,
      toSourcePlaybookPage?:  {
        __typename: "PlaybookPage",
        organisation: string,
        id: string,
        cardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        commentary?: string | null,
        playbookPageType: PlaybookPageType,
        worksheetId?: string | null,
        ordinal?: number | null,
        playbookId?: string | null,
        toPlaybook?:  {
          __typename: "Playbook",
          organisation: string,
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string > | null,
          orgEdit?: string | null,
          createdAt: string,
          createdBy?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          capitalName?: string | null,
          workbooks?: Array< string | null > | null,
        } | null,
        workbookId?: string | null,
        title?: string | null,
        toCardSets?:  {
          __typename: "ModelPlaybookPageCardSetsConnection",
          nextToken?: string | null,
        } | null,
        include?: Array< string | null > | null,
        options?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        cardSets?: Array< string | null > | null,
        workbooks?: Array< string | null > | null,
        cards?: Array< string | null > | null,
        worksheets?: Array< string | null > | null,
        playbookPageCardTypeId?: string | null,
      } | null,
      targetId: string,
      targetType?: EntityType | null,
      targetCardId?: string | null,
      toTargetCard?:  {
        __typename: "Card",
        organisation: string,
        id: string,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        name: string,
        capitalName?: string | null,
        toCardCategory:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        description?: string | null,
        attributes?:  Array< {
          __typename: "Attribute",
          attributeDefinitionID: string,
          value?: string | null,
        } | null > | null,
        toAttributes?:  {
          __typename: "ModelCardAttributeConnection",
          nextToken?: string | null,
        } | null,
        toScoreData?:  {
          __typename: "ModelScoreDataConnection",
          nextToken?: string | null,
        } | null,
        toCardType:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        toCardSets?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        deleteAfter?: number | null,
        _auditContextType?: EventContext | null,
        _auditContextId?: string | null,
        createdFromId?: string | null,
        createdFromIds?: Array< string | null > | null,
        cardTypeToCardsId?: string | null,
        cardToCardCategoryId: string,
        cardToCardTypeId: string,
      } | null,
      targetCardSetId?: string | null,
      toTargetCardSet?:  {
        __typename: "CardSet",
        organisation: string,
        id: string,
        type: CardSetType,
        name: string,
        capitalName?: string | null,
        description?: string | null,
        owner?: string | null,
        createdBy?: string | null,
        editors?: Array< string > | null,
        orgEdit?: string | null,
        toCards?:  {
          __typename: "ModelCardsCardSetsConnection",
          nextToken?: string | null,
        } | null,
        toCardCategory?:  {
          __typename: "CardCategory",
          id: string,
          name: string,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        toCardType?:  {
          __typename: "CardType",
          id: string,
          name?: string | null,
          cardCategoryID: string,
          defaultScoreDefinitionId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        updatedBy?: string | null,
        hiddenWorksheets?: Array< string | null > | null,
        copiedFrom?: string | null,
        counts?:  {
          __typename: "CountsResponse",
          comments?: number | null,
          worksheets?: number | null,
          cards?: number | null,
        } | null,
        processProgress?:  Array< {
          __typename: "ProcessStageProgress",
          stageId: string,
          progress?: number | null,
        } | null > | null,
        cardSetToCardCategoryId?: string | null,
        cardSetToCardTypeId?: string | null,
      } | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null,
    relationshipInfo?: string | null,
    updatedAt: string,
  } | null,
};
