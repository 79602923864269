import { useContext, useEffect, useState } from 'react';
import { styled, IconButton, Tooltip, Box, useTheme } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { PlaybookContext } from './context';
import ChartPage from './analysis/ChartPage';
import { Card, PlaybookPageDataResponse, PlaybookPageType } from '../../API';
import PlaybookCardTypePage from './PlaybookCardTypePage';
import PlaybookTitlePage from './PlaybookTitlePage';
import { PlaybookPageFormType } from './playbookTypes';
import { MoveDirection } from '../../pages/playbooks/[pid]';
import { UserPermissions } from '../../globals';
import ListPage from './lists';
import { worksheets } from '../forms/worksheets';
import { RadarOption } from '../forms/input-fields/ChartSettings';
import PlaybookSelectSource from './PlaybookSelectSource';
import { EditablePageValues } from './EditablePlaybookPage';
import AddIcon from '@mui/icons-material/Add';

interface PlaybookPageProps {
    page: PlaybookPageDataResponse;
    cards?: Card[];
    onClick?: (page: PlaybookPageDataResponse) => void;
    orderPages?: (id: string, direction: MoveDirection) => void;
    itemSelected?: boolean;
    permissions: UserPermissions[];
    setEditMode: (editMode: boolean) => void;
    playbookPageForm?: EditablePageValues;
    edit?: boolean;
    first?: boolean;
    last?: boolean;
    carousel?: boolean;
    pageOptions?: undefined;
    setPageOptions?: React.Dispatch<React.SetStateAction<undefined>>;
    radarOptions?: RadarOption[] | null | undefined;
    setRadarOptions?: React.Dispatch<
        React.SetStateAction<RadarOption[] | null | undefined>
    >;
    logo: string | null;
    grab?: boolean;
    setPreviousOrdinal?: React.Dispatch<
        React.SetStateAction<number | null | undefined>
    >;
    setShowCreateWorksheetWizard?: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    previousOrdinal?: number | null | undefined;
    index?: number;
    hoveredIndex?: number | null;
    setHoveredIndex?: React.Dispatch<React.SetStateAction<number | null>>;
}

interface PageContainerProps {
    thumbnail?: boolean;
}
const PageContainer = styled('div')<PageContainerProps>(({ thumbnail }) => {
    return {
        cursor: 'pointer',
        height: '100%',
        fontSize: thumbnail ? '16px' : '6px',
        position: 'relative',
        width: thumbnail ? '' : '100%',
    };
});
const StyledIconButton = styled(IconButton)(({ theme }) => {
    return {
        position: 'absolute',
        right: '-22px',
        top: '-22px',
    };
});

const StyledCancelOutlinedIcon = styled(CancelOutlinedIcon)(({ theme }) => {
    return {
        background: theme.palette.background.default,
        borderRadius: '50%',
    };
});

const PlaybookPageComponent = ({
    cards,
    page,
    onClick,
    playbookPageForm,
    permissions,
    orderPages,
    edit,
    first,
    last,
    setEditMode,
    itemSelected,
    carousel,
    pageOptions,
    setPageOptions,
    radarOptions,
    setRadarOptions,
    logo,
    grab,
    setPreviousOrdinal,
    setShowCreateWorksheetWizard,
    previousOrdinal,
    index,
    hoveredIndex,
    setHoveredIndex,
}: PlaybookPageProps) => {
    const theme = useTheme();
    const { handlePageDelete } = useContext(PlaybookContext);
    const [localRadarOptions, setLocalRadarOptions] = useState<
        RadarOption[] | null
    >();
    const cardIds = page?.allCards?.length
        ? page.allCards
        : page?.cardSets?.length
        ? page.cardSets
        : page?.workbooks?.length
        ? page.workbooks
        : [];
    const worksheetId = page?.worksheetId ?? 1;
    const worksheet = worksheets.find((item) => item.i === worksheetId);
    useEffect(() => {
        if (page.options) {
            const options = JSON.parse(page.options);
            if (options.chartData?.radarOptions) {
                setLocalRadarOptions(options.chartData.radarOptions);
            }
        }
    }, [page]);

    const radarOptionsState = !!playbookPageForm
        ? radarOptions
        : localRadarOptions;
    const radarOptionsSetter = !!playbookPageForm
        ? setRadarOptions
        : setLocalRadarOptions;

    return (
        <Tooltip
            title={!edit && !carousel ? 'Click and hold to drag the page' : ''}
            placement="bottom"
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -50],
                        },
                    },
                ],
            }}
        >
            <Box
                sx={{
                    ml:
                        !playbookPageForm &&
                        index &&
                        hoveredIndex &&
                        index === hoveredIndex + 1
                            ? 1
                            : 0,
                    height: '100%',
                    position: 'relative',
                    '&:hover .hover-icon': {
                        opacity: !playbookPageForm ? 1 : 0,
                    },
                    '&:hover': {
                        width: !playbookPageForm ? '97%' : '100%',
                        transition: 'width 10s ease 10s',
                    },
                }}
                onMouseEnter={() => {
                    index && setHoveredIndex && setHoveredIndex(index);
                }}
                onMouseLeave={() => {
                    setHoveredIndex && setHoveredIndex(null);
                }}
            >
                <Box
                    className="hover-icon"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '106.5%',
                        transform: 'translate(-50%, -50%)',
                        opacity: 0,
                        transition: 'opacity 0.3s',
                        height: '90%',
                        width: '45px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '12px',
                    }}
                >
                    <Box
                        sx={{
                            width: '2px',
                            height: '100px',
                            backgroundColor: 'primary.main',
                            marginBottom: '5px',
                        }}
                    />
                    <Tooltip placement="top" title="Create playbook page">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setPreviousOrdinal &&
                                    setPreviousOrdinal(page.ordinal);
                                setShowCreateWorksheetWizard &&
                                    setShowCreateWorksheetWizard(true);
                            }}
                        >
                            <AddIcon
                                sx={{
                                    width: '30px',
                                    height: '30px',
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Box
                        sx={{
                            width: '2px',
                            height: '100px',
                            backgroundColor: 'primary.main',
                            marginTop: '5px',
                        }}
                    />
                </Box>
                <PageContainer
                    sx={{ cursor: grab ? 'grabbing' : 'pointer' }}
                    thumbnail={!playbookPageForm}
                    onClick={() => !!onClick && onClick(page)}
                >
                    {(cardIds?.length && cardIds.length > 0) || edit ? (
                        page.playbookPageType === PlaybookPageType.Chart ? (
                            <ChartPage
                                page={page}
                                cards={cards?.filter((item) =>
                                    worksheet?.auto.includes(
                                        item?.cardToCardTypeId
                                    )
                                )}
                                permissions={permissions}
                                orderPages={orderPages}
                                playbookPageForm={playbookPageForm}
                                handleDelete={handlePageDelete}
                                itemSelected={!!itemSelected}
                                setEditMode={setEditMode}
                                first={first}
                                last={last}
                                edit={edit}
                                carousel={carousel}
                                pageOptions={pageOptions}
                                setPageOptions={setPageOptions}
                                radarOptions={radarOptionsState}
                                setRadarOptions={radarOptionsSetter}
                                pptView={false}
                                logo={logo}
                            />
                        ) : page.playbookPageType === PlaybookPageType.List ? (
                            <ListPage
                                page={page}
                                cards={cards?.filter((item) =>
                                    worksheet?.auto.includes(
                                        item?.cardToCardTypeId
                                    )
                                )}
                                permissions={permissions}
                                itemSelected={!!itemSelected}
                                orderPages={orderPages}
                                playbookPageForm={playbookPageForm}
                                pageOptions={pageOptions}
                                handleDelete={handlePageDelete}
                                setEditMode={setEditMode}
                                first={first}
                                last={last}
                                edit={edit}
                                carousel={carousel}
                                pptView={false}
                                logo={logo}
                            />
                        ) : (
                            <PlaybookTitlePage
                                setEditMode={setEditMode}
                                permissions={permissions}
                                page={page}
                                orderPages={orderPages}
                                handleDelete={handlePageDelete}
                                first={first}
                                last={last}
                                carousel={carousel}
                                front={!edit}
                                logo={logo}
                                pptView={false}
                            />
                        )
                    ) : (
                        <>
                            {page.playbookPageType ===
                            PlaybookPageType.Title ? (
                                <PlaybookTitlePage
                                    setEditMode={setEditMode}
                                    permissions={permissions}
                                    page={page}
                                    orderPages={orderPages}
                                    handleDelete={handlePageDelete}
                                    first={first}
                                    last={last}
                                    carousel={carousel}
                                    front={!edit}
                                    logo={logo}
                                    pptView={false}
                                />
                            ) : (
                                <PlaybookSelectSource
                                    setEditMode={setEditMode}
                                    permissions={permissions}
                                    page={page}
                                    orderPages={orderPages}
                                    playbookPageForm={playbookPageForm}
                                    handleDelete={handlePageDelete}
                                    first={first}
                                    last={last}
                                    carousel={carousel}
                                    front={!edit}
                                />
                            )}
                        </>
                    )}
                </PageContainer>
            </Box>
        </Tooltip>
    );
};

export default PlaybookPageComponent;
