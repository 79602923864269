import { useContext, useEffect, useState } from 'react';
import {
    Typography,
    SxProps,
    Box,
    Tooltip,
    IconButton,
    useTheme,
} from '@mui/material';

import Form from '../../forms/Form';
import FieldProps, {
    CheckboxOptionType,
    FieldType,
} from '../../forms/input-fields/inputFieldTypes';
import { PlaybookFormProps, PlaybookPageFormType } from '../playbookTypes';
import { PlaybookContext } from '../context';
import {
    AttributeType,
    GetWorksheetQuery,
    PlaybookPageType,
    Worksheet,
} from '../../../API';
import { CardContext } from '../../cards/context';
import { worksheets } from '../../forms/worksheets';
import { ReactComponent as HelpIcon } from '../../../assets/icons/Help.svg';
import CardHelp from '../../cards/CardHelp';

import {
    ChartType,
    Field,
    WorksheetNames,
    getChartSettings,
    getChartType,
    getListSettings,
    swotOptions,
} from '../../../helpers/worksheets';
import { getUserOrganisation } from '../../../helpers/auth';
import { getWorksheet } from '../../../graphql/queries';
import { AppContext } from '../../contexts';
import { generateClient } from 'aws-amplify/api';
import { Avatar } from '../../forms/CreatePlaybookPage/WorksheetList';
import { truncateText } from '../../../helpers/utils';

const listOptions: CheckboxOptionType[] = [
    {
        value: Field.CHARTS,
        label: 'Charts',
    },
    {
        value: Field.COMPARISON_TABLE,
        label: 'Comparison table',
    },
    {
        value: Field.DETAILS,
        label: 'Details',
    },
];
//This is used for handling old SWOT worksheets that were entered before they had an id
const defaultWorksheetId = '1';

const PlaybookConfigurationForm = ({
    page,
    playbook,
    updateValue,
    cardSettings,
    onSubmit,
    worksheetSettings,
    helpTextAnchor,
    setHelpTextAnchor,
    pageOptions,
    setPageOptions,
    radarOptions,
    setRadarOptions,
}: PlaybookFormProps) => {
    const theme = useTheme();
    const { user } = useContext(AppContext);
    const client = generateClient();
    const [relatedWorksheets, setRelatedWorksheets] = useState<Worksheet[]>([]);
    const worksheetId = page?.worksheetId ?? defaultWorksheetId;
    const worksheet = worksheets.find((item) => item.i === worksheetId);
    const chartSettings =
        worksheet?.t === 'List'
            ? getListSettings()
            : getChartSettings(worksheet?.n || '');
    const leftChartId = worksheet?.lp?.l as string;
    const rightChartId = worksheet?.lp?.r as string;
    const modifiedListOptions =
        !leftChartId && !rightChartId
            ? listOptions.filter((option) => option.value !== Field.CHARTS)
            : listOptions;
    const chartType = worksheet && getChartType(worksheet.n);

    const { handleClose } = useContext(PlaybookContext);
    const { handleEdit, items } = useContext(CardContext);

    const RadioCheckboxBackrounds: SxProps = {
        backgroundColor: 'background.paper',
        px: 2,
        borderRadius: 2,
    };

    const basePlaybookForm: Array<FieldProps | FieldProps[]> = [
        {
            name: 'title',
            label: 'Title',
            type: AttributeType.Text,
            required: true,
        },

        {
            name: 'commentary',
            label: 'Commentary',
            type: AttributeType.MultilineText,
        },
    ];

    const setHelpTextModal = (e: React.MouseEvent<HTMLElement> | null) => {
        if (!setHelpTextAnchor) return;
        if (e) {
            setHelpTextAnchor(e.currentTarget);
        } else {
            setHelpTextAnchor(null);
        }
    };
    const getWorksheetById = async (id: string) => {
        const userOrganisation = getUserOrganisation(user);
        const query = getWorksheet;
        const res = (await client.graphql({
            query,
            variables: {
                id: id,
                organisation: userOrganisation,
            },
        })) as { data: GetWorksheetQuery };

        return res.data.getWorksheet;
    };
    useEffect(() => {
        if (page?.worksheets?.length) {
            const validWorksheetIds = page?.worksheets?.filter(
                (wsId): wsId is string => wsId !== null
            );
            Promise.all(
                validWorksheetIds.map((wsId) => getWorksheetById(wsId))
            ).then((results) => {
                setRelatedWorksheets(results as Worksheet[]);
            });
        }
    }, [page]);

    return (
        <Box sx={{ px: 1.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', flex: 1 }}>
                <Typography variant="h1" sx={{ mb: 4 }}>
                    {worksheetSettings?.n ?? ''}
                </Typography>
                {worksheetSettings?.t === 'Chart' && (
                    <Tooltip title="Help" placement="top">
                        <IconButton
                            aria-label=""
                            id="menu-button"
                            onClick={setHelpTextModal}
                            sx={{ ml: 1 }}
                        >
                            <HelpIcon
                                width="2rem"
                                height="2rem"
                                color={theme.palette.grey[500]}
                            />
                        </IconButton>
                    </Tooltip>
                )}
                {helpTextAnchor && setHelpTextAnchor && (
                    <CardHelp
                        anchor={helpTextAnchor}
                        setHelpTextModal={setHelpTextModal}
                        setHelpTextAnchor={setHelpTextAnchor}
                        worksheetSettings={worksheetSettings}
                    />
                )}
            </Box>
            <Box sx={{ mb: 2 }}>
                <Typography
                    variant="h6"
                    sx={{ fontSize: '1.3em', fontWeight: '300' }}
                >
                    Worksheets
                </Typography>
                {relatedWorksheets?.map((item) => {
                    const worksheetItem = worksheets.find(
                        (ws) => ws.i === item.typeId
                    );
                    return (
                        <Box>
                            {worksheetItem && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar worksheet={worksheetItem?.n} />
                                    <Tooltip title={item.name} placement="top">
                                        <Typography variant="body1">
                                            {item.name &&
                                                truncateText(item.name, 30)}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Box>
            <Form
                chartType={chartType}
                formConfiguration={basePlaybookForm}
                handleCancel={handleClose}
                handleSubmit={onSubmit}
                disableSubmit={false}
                onChange={updateValue}
                values={{
                    title: page?.title ?? '',
                    commentary: page?.commentary ?? '',
                }}
                checkboxValues={
                    page?.include
                        ? page?.include
                        : page?.id
                        ? []
                        : ['title', 'score']
                }
                cardSelector={{
                    ...cardSettings,
                    auto: worksheetSettings?.auto,
                    src: worksheetSettings?.src,
                }}
                chartSettings={chartSettings}
                page={page}
                pageOptions={pageOptions}
                setPageOptions={setPageOptions}
                radarOptions={radarOptions}
                setRadarOptions={setRadarOptions}
            />
        </Box>
    );
};

export default PlaybookConfigurationForm;
