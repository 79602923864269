import { generateClient } from 'aws-amplify/api';
import { getContext } from './utils';
import {
    addOrRemoveCardsFromSet,
    convertCard,
    createCard,
    createOrUpdateScoreData,
    createPlaybookPage,
    updateCard,
    updatePlaybookPage,
    updateUserProfile,
} from '../graphql/mutations';
import {
    AttributeInput,
    CreateCardMutation,
    CreatePlaybookPageMutation,
    PlaybookPageDataResponse,
    PlaybookPageType,
} from '../API';
import { getUserOrganisation } from './auth';
import {
    getChartSettings,
    getListSettings,
    getRadarOptions,
} from './worksheets';
import {
    ExtendedWorksheetSetting,
    SelectedWorksheet,
    UserPreferences,
} from '../components/forms/CreateCard/types';
import { NewPlaybookPage } from '../components/forms/CreatePlaybookPage/PageListColumn';

const client = generateClient();

export const convertSparkCard = async (data: any, id: string | undefined) => {
    const context = getContext(location.pathname, id);
    try {
        const res = await client.graphql({
            query: convertCard,
            variables: {
                cardId: data.id,
                newCardTypeId: data.cardToCardTypeId,
                name: data.name,
                description: data.description,
                attributes: data?.attributes?.length
                    ? data?.attributes?.map((item: any) => {
                          if (item?.value) {
                              return {
                                  attributeDefinitionID:
                                      item?.attributeDefinitionID,
                                  value: JSON.stringify(item?.value) ?? '',
                              } as AttributeInput;
                          } else {
                              return null;
                          }
                      })
                    : [],
                _auditContextType: context.contextType,
                _auditContextId: context.contextId,
            },
        });
    } catch (e) {
        console;
    }
};

export const addScores = (card: any, cardScores: any) => {
    const currentDate = new Date().toISOString().split('T')[0];
    const scoreGroups = cardScores.reduce((groups: any, data: any) => {
        if (!groups[data.scoreDefinition]) {
            groups[data.scoreDefinition] = [];
        }
        groups[data.scoreDefinition].push({
            value: data.value,
            date: new Date().toISOString(),
            comment: null,
            commentDate: null,
            commentAuthor: null,
        });
        return groups;
    }, {});
    const existingScores = card.toScoreData.items.filter(
        (item: any) => item.createdAt.split('T')[0] === currentDate
    );

    if (existingScores.length > 0) {
        existingScores.forEach((existingScore: any) => {
            const scoreDefinitionId = existingScore.scoreDefinitionId;
            if (scoreGroups[scoreDefinitionId]) {
                existingScore.data.push(...scoreGroups[scoreDefinitionId]);
                existingScore.updatedAt = new Date().toISOString();
            }
        });
    } else {
        const mappedItems = Object.keys(scoreGroups).map(
            (scoreDefinitionId) => ({
                cardId: card.id,
                scoreDefinitionId: scoreDefinitionId,
                data: scoreGroups[scoreDefinitionId],
                organisation: card.organisation,
                createdBy: card.createdBy,
                updatedBy: card.updatedBy,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                __typename: 'ScoreDatum',
            })
        );
        card.toScoreData?.items.push(...(mappedItems as any));
    }

    return card;
};

interface CreateWorksheetParams {
    user: any;
    client: any;
    highestOrdinal: any;
    previousOrdinal: number | null | undefined;
    setPreviousOrdinal?: React.Dispatch<
        React.SetStateAction<number | null | undefined>
    >;
    newPlaybookPages: NewPlaybookPage[];
    playbookId: string | undefined;
    worksheets: ExtendedWorksheetSetting[];
    pages: PlaybookPageDataResponse[] | undefined;
    loadPlaybook?: () => Promise<void>;
    showSnackbar: (args: {
        message: string;
        severity: 'success' | 'error';
    }) => void;
    cardTypeObject: any;
}

export async function createNewPlaybookPage({
    user,
    client,
    highestOrdinal,
    previousOrdinal,
    setPreviousOrdinal,
    newPlaybookPages,
    playbookId,
    worksheets,
    pages,
    loadPlaybook,
    showSnackbar,
    cardTypeObject,
}: CreateWorksheetParams) {
    const userOrganisation = getUserOrganisation(user);
    let newOrdinal;
    if (highestOrdinal != null) {
        newOrdinal = highestOrdinal + 1;
    }
    const worksheetsToProcess = newPlaybookPages || [];
    try {
        for (const selectedWorksheet of worksheetsToProcess) {
            const worksheet = worksheets.find(
                (item) => item.i === selectedWorksheet.sw.i
            );
            const settings: any =
                selectedWorksheet?.sw.t === PlaybookPageType.List
                    ? getListSettings()
                    : getChartSettings(worksheet?.n || '');
            let options = {};
            if (
                selectedWorksheet?.sw.t === PlaybookPageType.Chart ||
                selectedWorksheet?.sw.t === PlaybookPageType.List
            ) {
                const nodeSizeSelection =
                    settings?.options?.chartData?.nodeSize?.selection;
                options =
                    selectedWorksheet?.sw.t === PlaybookPageType.List
                        ? {
                              layout: [
                                  {
                                      include: settings?.options.include,
                                      layout: settings?.options.layout,
                                  },
                              ],
                          }
                        : {
                              chartData: {
                                  xAxis: settings?.options.chartData
                                      .xAxisOptions[0],
                                  yAxis: settings?.options.chartData
                                      .yAxisOptions[1],
                                  nodeSize: {
                                      selection:
                                          nodeSizeSelection[
                                              nodeSizeSelection.length - 1
                                          ],
                                  },
                                  nodeColor: {
                                      selection:
                                          settings?.options.chartData.nodeColor
                                              .selection[0],
                                  },
                                  hideGaps:
                                      settings?.options.chartData.hideGaps,
                                  radarOptions:
                                      worksheet?.n === 'Competitive landscape'
                                          ? getRadarOptions(cardTypeObject['3'])
                                          : null,
                              },
                              layout: [
                                  {
                                      include: settings?.options.include,
                                      layout: settings?.options.layout,
                                  },
                              ],
                          };
            }

            const variables = {
                input: {
                    playbookPageType: selectedWorksheet?.sw.t,
                    organisation: userOrganisation,
                    title: selectedWorksheet?.pageTitle,
                    playbookId: playbookId,
                    options: JSON.stringify(options),
                    ordinal: previousOrdinal ? previousOrdinal + 1 : newOrdinal,
                    worksheetId: selectedWorksheet?.sw.i,
                    worksheets: [selectedWorksheet?.ew.id],
                },
            };

            const res = (await client.graphql({
                query: createPlaybookPage,
                variables: variables,
            })) as { data: CreatePlaybookPageMutation };

            if (res && previousOrdinal && setPreviousOrdinal) {
                const pagesWithHigherOrdinal = pages?.filter(
                    (item) =>
                        item?.ordinal && item?.ordinal >= previousOrdinal + 1
                );

                for (const page of pagesWithHigherOrdinal || []) {
                    const updatedPageOrdinal =
                        page?.ordinal && page?.ordinal + 1;
                    try {
                        await client.graphql({
                            query: updatePlaybookPage,
                            variables: {
                                input: {
                                    organisation: userOrganisation,
                                    id: page.id,
                                    ordinal: updatedPageOrdinal,
                                },
                            },
                        });
                    } catch (error) {
                        console.error(`Error updating page ${page.id}`, error);
                    }
                }
                setPreviousOrdinal(null);
            }
        }

        loadPlaybook && loadPlaybook();
        showSnackbar({
            message: 'Playbook page created successfully',
            severity: 'success',
        });
    } catch (error) {
        console.error(error);
        showSnackbar({
            message: 'Error while creating playbook page',
            severity: 'error',
        });
    }
}

interface SaveCardParams {
    locationPath: string;
    id: string | undefined;
    worksheet: boolean | undefined;
    card: any;
    cardFrom: any;
    newCard: any;
    cardSet: string | undefined;
    submitType: string;
    pageOptions: any;
    scores: { x: number; y: number };
    preferences: UserPreferences;
    cardScores: any[];
    selectedCreateOption: string;
    selectedProcessId: string | null;
    selectedStepId: string | null;
    userProfileObject: any;
    user: any;
    client: any;
    setLoading: (loading: boolean) => void;
    setCardType?: React.Dispatch<React.SetStateAction<string | null>>;
    setLastCreatedFrom?: React.Dispatch<React.SetStateAction<any>>;
    setPreferences: (preferences: UserPreferences) => void;
    setUserProfileObject?: (profile: any) => void;
    handleClose?: (card?: any) => void;
    onSuccess: any;
}

export async function saveCard({
    locationPath,
    id,
    worksheet,
    card,
    cardFrom,
    newCard,
    cardSet,
    submitType,
    pageOptions,
    scores,
    preferences,
    cardScores,
    selectedCreateOption,
    selectedProcessId,
    selectedStepId,
    userProfileObject,
    user,
    client,
    setLoading,
    setCardType,
    setLastCreatedFrom,
    setPreferences,
    setUserProfileObject,
    handleClose,
    onSuccess,
}: SaveCardParams) {
    setLoading(true);
    const context = getContext(locationPath, id, worksheet);
    const query = card ? updateCard : createCard;
    const userOrganisation = getUserOrganisation(user);
    const data = newCard;

    try {
        const res = (await client.graphql({
            query,
            variables: {
                input: {
                    ...data,
                    attributes: data?.attributes?.length
                        ? data?.attributes?.map((item: any) => {
                              if (item?.value) {
                                  return {
                                      attributeDefinitionID:
                                          item?.attributeDefinitionID,
                                      value: JSON.stringify(item?.value) ?? '',
                                  } as AttributeInput;
                              }
                              return null;
                          })
                        : [],
                    organisation: data?.organisation || 'x',
                    orgEdit: userOrganisation,
                    owner: user?.userSub,
                    _auditContextType: context.contextType,
                    _auditContextId: context.contextId,
                    ...(cardFrom && { createdFromId: cardFrom.id }),
                },
            },
        })) as { data: CreateCardMutation };

        if (cardFrom && setLastCreatedFrom) {
            setLastCreatedFrom({
                cardToCardCategoryId: data.cardToCardCategoryId,
                cardToCardTypeId: data.cardToCardTypeId,
            });
        }

        // Save card scores logic
        if (res?.data?.createCard?.id && cardScores.length) {
            const currentDate = new Date().toISOString().split('T')[0];
            for (const cardScore of cardScores) {
                const scoreData = {
                    input: {
                        data: [{ date: currentDate, value: cardScore.value }],
                        cardId: res.data.createCard.id,
                        organisation: res.data.createCard.organisation,
                        scoreDefinitionId: cardScore.scoreDefinition || '',
                    },
                };
                await client.graphql({
                    query: createOrUpdateScoreData,
                    variables: scoreData,
                });
            }
        }

        // Add card to set
        if (cardSet && res?.data?.createCard?.id) {
            const cardId = res.data.createCard.id;
            await client.graphql({
                query: addOrRemoveCardsFromSet,
                variables: { cardSetId: cardSet, cardIds: [cardId] },
            });
        }

        // Update user preferences
        const userPreferences: UserPreferences = {
            latestActivity: {
                workbook: {
                    processId:
                        selectedCreateOption === 'BY_PROCESS'
                            ? selectedProcessId
                            : preferences?.latestActivity?.workbook?.processId,
                    stepId:
                        selectedCreateOption === 'BY_PROCESS'
                            ? selectedStepId
                            : preferences?.latestActivity?.workbook?.stepId,
                    cardTypeId: newCard.cardToCardTypeId,
                    cardCategoryId: newCard.cardToCardCategoryId,
                },
                cards: {
                    cardTypeId: newCard.cardToCardTypeId,
                    cardCategoryId: newCard.cardToCardCategoryId,
                },
            },
        };

        await client.graphql({
            query: updateUserProfile,
            variables: {
                input: {
                    id: userProfileObject?.getUserProfile?.id ?? '',
                    organisation:
                        userProfileObject?.getUserProfile?.organisation ?? '',
                    preferences: JSON.stringify(userPreferences),
                },
            },
        });

        onSuccess(res);
        setLoading(false);
    } catch (err) {
        console.error(err);
        if (handleClose) handleClose();
        setLoading(false);
        if (setCardType) setCardType(null);
    }
}
