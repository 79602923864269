import {
    Card,
    CardSet,
    Playbook,
    ScoreDatum,
    ScoreDefinition,
} from '../../API';
import { UserPermissions } from '../../globals';
import {
    updateCard,
    updateCardSet,
    updatePlaybook,
} from '../../graphql/mutations';
import { CardToDelete } from '../../pages/cards';

export enum CardPage {
    DETAILS = 'details',
    SCORES = 'scores',
    INFO = 'info',
    RELATIONSHIPS = 'relationships',
    ACTIVITY = 'activity',
    COMMENTS = 'comments',
}
export enum CardAPITypeName {
    Card = 'Card',
    CardSet = 'CardSet',
    Playbook = 'Playbook',
    Worksheet = 'Worksheet',
}

export const updateMutationMap = {
    [CardAPITypeName.CardSet]: updateCardSet,
    [CardAPITypeName.Card]: updateCard,
    [CardAPITypeName.Playbook]: updatePlaybook,
};
export interface CardProps {
    card: Card | CardSet | Playbook;
    //expanded?: boolean;
    showPage?: CardPage;
    onClick: (cardPage?: CardPage) => void;
    handleEdit?: (data: Card) => void;
    onDelete?: (id?: string) => void;
    handleDelete?: (organisation: string, id: string) => void;
    expanded?: boolean;
}

export enum CardComponentType {
    CARD_SET = 'card-set-component',
    CARD = 'card-component',
    PLAYBOOK = 'playbook-component',
    WORKBOOK = 'workbook-component',
    WORKSHEET = 'worksheet',
}

export interface CardComponentData {
    id: string;
    name: string;
    cardCategoryId: string;
    cardCategoryName: string;
    cardTypeId: string;
    cardToCardCategoryId?: string;
    cardTypeName: string;
    createdBy?: string | null;
    editors?: Array<string> | null;
    owner?: string | null;
    description?: string | null;
    cardComponentType?: CardComponentType;
    link?: string;
    scoreDefinition?: ScoreDefinition | null;
    scoreName?: string;
    scoreValues?: (ScoreDatum | null)[];
    scoreValue?: number;
    organisation?: string | null;
    updatedAt: string;
    createdAt: string;
    orgEdit?: string | null;
}
export interface CardSetComponentData {
    __typename?: string;
    id: string;
    name: string;
    cardSetToCardCategoryId?: string | null;
    createdBy?: string | null;
    editors?: Array<string> | null;
    owner?: string | null;
    description?: string | null;
    cardComponentType?: CardComponentType;
    link?: string;
    scoreDefinition?: ScoreDefinition | null;
    scoreName?: string;
    scoreValues?: (ScoreDatum | null)[];
    scoreValue?: number;
    organisation?: string | null;
    updatedAt: string;
    orgEdit?: string | null;
}
export interface CardComponentProps {
    data: CardComponentData;
    showPage?: CardPage;
    onClick?: (cardId: string, cardPage?: CardPage) => void;
    handleCardClick?: (card: CardComponentData) => void;
    setCardFrom?: (card: Card) => void;
    createFrom?: () => void;
    handleEdit?: (cardId: string) => void;
    onDelete?: (id?: string) => void;
    addCardToWorksheet?: () => void;
    handleDelete?: (id: string, organisation: string) => void;
    handleConvert?: (id: string) => void;
    handleRemove?: (id: string) => void;
    handleCopy?: (cardId: string) => void;
    handleClose?: () => void;
    expanded?: boolean;
    deletedCard?: boolean | undefined;
    multiSelectedCards?: CardToDelete[] | [];
    setMultiSelectedCards?: React.Dispatch<
        React.SetStateAction<CardToDelete[] | []>
    >;
    cardsObject?: {
        [key: string]: Card;
    };
    pageIdentifier?: PageIdentifier;
    mediumCard?: boolean;
    smallCard?: boolean;
    handleSelect?:
        | ((item: CardComponentData | CardSetComponentData) => void)
        | undefined;
    clickedItems?: CardSetComponentData[];
    swotGrid?: boolean;
    swotColor?: string;
    worksheetCardsObject?: {
        [key: string]: Card;
    };
    hideHelp?: boolean;
    activeId?: string | null;
    permissions?: UserPermissions[] | undefined;
    workbookPermissions?: UserPermissions[] | undefined;
    worksheetCard?: boolean;
    worksheetGridCard?: boolean;
    setActiveBottomPanelCardId?: React.Dispatch<
        React.SetStateAction<string | null | undefined>
    >;
    onHover?: (id: string | null) => void;
    highlighted?: boolean;
    headerClick?: (id?: string) => void;
    showMenu?: boolean;
}
export interface CardSetComponentProps {
    data: CardSetComponentData;
    showPage?: CardPage;
    onClick?: (cardId: string, cardPage?: CardPage) => void;
    handleEdit?: (cardId: string) => void;
    onDelete?: (id?: string) => void;
    handleDelete?: (id: string, organisation: string) => void;
    handleRemove?: (id: string) => void;
    handleCopy?: (cardId: string) => void;
    handleClose?: () => void;
    expanded?: boolean;
    multiType?: boolean;
}
export enum ViewType {
    GRID = 'grid',
    LIST = 'list',
    ANALYZE = 'analyze',
}

export enum SortActions {
    LAST_MODIFIED_RECENT_FIRST = 'Default - Last modified date (most recent first)',
    LAST_MODIFIED_OLDEST_FIRST = 'Last modified date (oldest first)',
    CREATION_RECENT_FIRST = 'Creation date (most recent first)',
    CREATION_OLDEST_FIRST = 'Creation date (oldest first)',
    ASCENDING = 'A-Z (ascending)',
    DESCENDING = 'A-Z (descending)',
}

export const sortFieldToResponseMap: Record<SortActions, string> = {
    [SortActions.LAST_MODIFIED_RECENT_FIRST]: 'UpdatedAt',
    [SortActions.LAST_MODIFIED_OLDEST_FIRST]: 'UpdatedAt',
    [SortActions.CREATION_RECENT_FIRST]: 'CreatedAt',
    [SortActions.CREATION_OLDEST_FIRST]: 'CreatedAt',
    [SortActions.ASCENDING]: 'Name',
    [SortActions.DESCENDING]: 'Name',
};

export enum PageIdentifier {
    CARDS = 'cards',
    CARD_SETS = 'card_sets',
    CARD_SET_INSIDE = 'cart_set_inside',
    WORKBOOKS = 'workbooks',
    WORKBOOK_INSIDE = 'workbook_inside',
    PLAYBOOKS = 'playbooks',
    WORKSHEET = 'worksheet',
    STRATEGY_BUILDER = 'strategy_builder',
    ADD_EXISTING_CARD_FORM_BY_CARD = 'add_existing_card_form_by_card',
    ADD_EXISTING_CARD_FORM_BY_CARD_SET = 'add_existing_card_form_by_card_set',
    CONNECT_WORKBOOK_TO_PLAYBOOK_FORM = 'connect_workbook_to_playbook_form',
}

export enum FilterOptions {
    CARD_TYPE = 'Card',
    CARD_SET = 'Card set',
    WORKBOOK = 'Workbook',
    OWNER = 'Owner',
}
export enum WorksheetFilterOptions {
    TOP_THREE = 'Top three',
    OWNER = 'Owner',
    CREATED_DATE = 'Created date',
}
export enum WorksheetFilterDateOptions {
    LAST_HOUR = 'last hour',
    TODAY = 'today',
    THIS_WEEK = 'this week',
    CUSTOM_DATE = 'custom date',
}

export enum CreateCardOptions {
    BY_PROCESS = 'by_process',
    BY_CATEGORY = 'by_category',
    TITLE_PAGE = 'title_page',
    BY_WORKBOOK = 'by_workbook',
}
