import {
    Box,
    Button,
    Paper,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AiRequest } from '../../actions/AIRequest';
import { Card } from '../../API';
import { ReactComponent as AiIcon } from '../../assets/icons/ai-icon.svg';
import TypingDots from '../forms/CreateCard/TypingDots';
import { AppContext } from '../contexts';
import Markdown from 'markdown-to-jsx';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface AiAnalysisModalProps {
    cards: Card[];
    worksheetName: string; 
    close: () => void;
}

interface Message {
    sender: 'user' | 'ai';
    content: string;
    error?: boolean;
}

const AiAnalysisModal = ({
    cards,
    worksheetName,
    close,
}: AiAnalysisModalProps) => {
    const { cardTypeObject } = useContext(AppContext);
    const [aiTyping, setAiTyping] = useState(false);
    const [aiError, setAiError] = useState(false);
    const [threadId, setThreadId] = useState<any>(undefined);
    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState<Message[]>([]);
    const [copyStates, setCopyStates] = useState<{ [key: number]: boolean }>(
        {}
    );

    const promptStarter = {
        display: 'Evaluate my data',
        value: `Analyze my data of ${worksheetName} and provide detailed insights, recommendations and a summary. If I dont provide any data, tell me to create more cards with relevant scores.`,
    };

    const transformArray = (originalArray: any) => {
        const transformedArray: any = [];

        originalArray?.forEach((item: any) => {
            const {
                name,
                description,
                toCardType,
                toScoreData,
                cardToCardTypeId,
            } = item;
            const scoreDefinitions =
                cardTypeObject[cardToCardTypeId]?.scoreDefinitions;
            const scores = toScoreData?.items?.map((scoreItem: any) => ({
                type:
                    scoreDefinitions.find(
                        (def) => def.id === scoreItem.scoreDefinitionId
                    )?.description || '',
                value: scoreItem.data[0].value,
            }));
            const existingTypeIndex = transformedArray.findIndex(
                (obj: any) => obj.type === toCardType.name
            );
            if (existingTypeIndex === -1) {
                transformedArray.push({
                    type: toCardType.name,
                    cards: [
                        {
                            name,
                            description,
                            scores,
                        },
                    ],
                });
            } else {
                transformedArray[existingTypeIndex].cards.push({
                    name,
                    description,
                    scores,
                });
            }
        });
        return transformedArray;
    };

    const formatCardForPrompt = (card: any) => {
        let prompt = `${card.name}: ${card.description}\n`;
        card?.scores?.forEach((score: any) => {
            prompt += ` - ${score.type}: ${score.value}\n`;
        });
        return prompt;
    };

    const analysisPrompt =
        `${promptStarter.value}:\n` +
        transformArray(cards)
            .map((section: any) => {
                return (
                    `${section.type}:\n` +
                    section.cards.map(formatCardForPrompt).join('\n')
                );
            })
            .join('\n\n');

    const makeAiRequest = async (input: string, currentThreadId?: any) => {
        setAiTyping(true);
        try {
            const response = await AiRequest(
                input,
                'asst_2lFKwKyEUM2BDrowAM3RHLWD',
                currentThreadId
            );
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: 'ai', content: response.content || '' },
            ]);
            setThreadId(response.threadId);
            setAiTyping(false);
        } catch (error) {
            console.error('Error fetching AI suggestion:', error);
            setMessages((prevMessages) =>
                prevMessages.map((message, index) =>
                    index === prevMessages.length - 1
                        ? { ...message, error: true }
                        : message
                )
            );
            setAiTyping(false);
        }
    };

    useEffect(() => {
        makeAiRequest(analysisPrompt);
    }, []);

    const handleSubmit = async () => {
        if (userInput.trim() === '') {
            return;
        }
        setUserInput('');
        setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'user', content: userInput },
        ]);
        await makeAiRequest(userInput, threadId);
    };

    const theme = useTheme();

    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    const handleRetry = (index: number) => {
        const message = messages[index];
        makeAiRequest(message.content, threadId);
    };

    const handleCopyToClipboard = async (content: string, index: number) => {
        const plainText = content
            .replace(/[*#]/g, '')
            .replace(/\n\s*\n/g, '\n');

        try {
            await navigator.clipboard.writeText(plainText);
            setCopyStates((prevState) => ({
                ...prevState,
                [index]: true,
            }));
            setTimeout(() => {
                setCopyStates((prevState) => ({
                    ...prevState,
                    [index]: false,
                }));
            }, 2000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <Box
            sx={{
                p: 2,
                height: '80vh',
                flexDirection: 'column',
                justifyContent: 'space-between',
                display: 'flex',
                position: 'relative',
            }}
        >
            <Box sx={{ position: 'absolute', top: '20px', right: '20px' }}>
                <IconButton onClick={() => close()}>
                    <CloseIcon sx={{ color: 'inherit' }} />
                </IconButton>
            </Box>
            <Typography variant="h5">Analyse worksheet with AI</Typography>
            <Box sx={{ flex: 1, overflowY: 'auto', mb: 2, mt: 3 }}>
                {messages?.map((message, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems:
                                message.sender === 'user'
                                    ? 'flex-end'
                                    : 'flex-start',
                            mb: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent:
                                    message.sender === 'user'
                                        ? 'flex-end'
                                        : 'flex-start',
                                width: '100%',
                            }}
                        >
                            {message.sender === 'ai' && (
                                <AiIcon
                                    width="35px"
                                    height="35px"
                                    style={{ marginRight: 8 }}
                                />
                            )}
                            <Paper
                                sx={{
                                    background:
                                        message.sender === 'user'
                                            ? '#dcf8c6'
                                            : theme.palette.background.paper,
                                    borderRadius: '16px',
                                    p: 1,
                                    mt: 4,
                                    maxWidth: '70%',
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                            >
                                {message.sender === 'ai' && (
                                    <Box
                                        sx={{
                                            width: '30px',
                                            height: '30px',
                                            position: 'absolute',
                                            top: 0,
                                            left: -15,
                                            borderLeft:
                                                '20px solid transparent',
                                            borderTop: `20px solid ${theme.palette.background.paper}`,
                                            borderRadius: '2px',
                                            transform: `rotate(20deg)`,
                                            zIndex: -1,
                                        }}
                                    ></Box>
                                )}
                                <Box sx={{ position: 'relative' }}>
                                    <Markdown>{message.content}</Markdown>
                                    {message.sender === 'ai' && (
                                        <Tooltip title="Copy to clipboard">
                                            <Button
                                                onClick={() =>
                                                    handleCopyToClipboard(
                                                        message.content,
                                                        index
                                                    )
                                                }
                                                sx={{
                                                    position: 'absolute',
                                                    top: '-20px',
                                                    right: 0,
                                                    minWidth: '30px',
                                                    padding: 0,
                                                }}
                                            >
                                                {copyStates[index] ? (
                                                    'Copied'
                                                ) : (
                                                    <ContentCopyIcon fontSize="small" />
                                                )}
                                            </Button>
                                        </Tooltip>
                                    )}
                                </Box>
                            </Paper>
                        </Box>
                        {message.error && (
                            <Typography
                                variant="body2"
                                sx={{
                                    color: theme.palette.error.main,
                                    mt: 1,
                                    fontSize: '12px',
                                    ml: message.sender === 'user' ? 'auto' : 0,
                                    mr: message.sender === 'user' ? 0 : 'auto',
                                }}
                            >
                                There was an error generating a response. Please{' '}
                                <span
                                    style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleRetry(index)}
                                >
                                    try again
                                </span>
                                .
                            </Typography>
                        )}
                    </Box>
                ))}

                {aiTyping && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            mb: 1,
                        }}
                    >
                        <AiIcon
                            width="35px"
                            height="35px"
                            style={{ marginRight: 8 }}
                        />
                        <Paper
                            sx={{
                                borderRadius: '16px',
                                px: 2,
                                mt: 4,
                                maxWidth: '60%',
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                                zIndex: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    width: '30px',
                                    height: '30px',
                                    position: 'absolute',
                                    top: 0,
                                    left: -15,
                                    borderLeft: '20px solid transparent',
                                    borderTop: `20px solid ${theme.palette.background.paper}`,
                                    borderRadius: '2px',
                                    transform: `rotate(20deg)`,
                                    zIndex: -1,
                                }}
                            ></Box>
                            <Typography variant="body1" sx={{ mr: 1, mt: 2 }}>
                                Typing
                            </Typography>
                            <TypingDots />
                        </Paper>
                    </Box>
                )}
                <div ref={messagesEndRef} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    padding: '8px',
                    boxSizing: 'border-box',
                }}
            >
                <TextField
                    fullWidth
                    multiline
                    maxRows={4}
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            handleSubmit();
                            e.preventDefault();
                        }
                    }}
                    variant="outlined"
                    placeholder="Type your message here..."
                    sx={{ marginRight: 1 }}
                />
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{ height: '55px', alignSelf: 'center' }}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default AiAnalysisModal;
