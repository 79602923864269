import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Category, getCategoryHexText } from '../../helpers/category';
import CardBase from './CardBase';
import { CardPage, CardSetComponentProps } from './cardTypes';
import { UserPermissions } from '../../globals';
import CardActions from './CardActions';
import { ReactComponent as ContentIcon } from '../../assets/icons/Content.svg';
import { ReactComponent as DetailsIcon } from '../../assets/icons/Details.svg';
import { ReactComponent as ActivityIcon } from '../../assets/icons/Event.svg';
import CardMenu from './CardMenu';
import { getUserPermissions } from '../../helpers/permissions';
import { ReactComponent as CommentsIcon } from '../../assets/icons/Comments.svg';
import { ReactComponent as RelationshipsIcon } from '../../assets/icons/Relationships.svg';
import { AppContext } from '../contexts';

const CardSetComponent = (props: PropsWithChildren<CardSetComponentProps>) => {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
    const [permissions, setPermissions] = useState<UserPermissions[]>([]);
    const {
        data,
        onClick,
        showPage,
        handleClose,
        expanded,
        handleDelete,
        handleCopy,
        multiType,
    } = props;
    const { user } = useContext(AppContext);

    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };

    const {
        id,
        link,
        name,
        organisation,
        cardSetToCardCategoryId,
        __typename,
    } = data;

    const textColor = getCategoryHexText(cardSetToCardCategoryId ?? '');

    const handleClick = (cardPage?: CardPage) => {
        if (!!onClick) {
            onClick(id, cardPage);
        }
    };
    useEffect(() => {
        if (user) {
            const userPermissions = getUserPermissions(
                user?.tokens?.idToken?.payload?.sub ?? '',
                data
            );

            setPermissions(userPermissions);
        }
    }, [data, user]);
    const actionItems = [
        {
            action: handleClick || link,
            value: CardPage.DETAILS,
            Icon: ContentIcon,
            label: 'Details',
            active: showPage === CardPage.DETAILS,
        },
        {
            action: handleClick || link,
            value: CardPage.INFO,
            Icon: DetailsIcon,
            label: 'Info',
            active: showPage === CardPage.INFO,
        },
    ];
    if (__typename !== 'Playbook') {
        actionItems.push({
            action: handleClick || link,
            value: CardPage.ACTIVITY,
            Icon: ActivityIcon,
            label: 'Activity',
            active: showPage === CardPage.ACTIVITY,
        });
    }
    if (__typename === 'Playbook') {
        actionItems.splice(1, 0, {
            action: handleClick,
            value: CardPage.COMMENTS,
            Icon: CommentsIcon,
            label: 'Comments',
            active: showPage === CardPage.COMMENTS,
        });
        actionItems.splice(3, 0, {
            action: handleClick,
            value: CardPage.RELATIONSHIPS,
            Icon: RelationshipsIcon,
            label: 'Relationships',
            active: showPage === CardPage.RELATIONSHIPS,
        });
    }
    return (
        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
            <CardBase
                data={{
                    id,
                    name,
                    category: multiType
                        ? (Category.CARDSET as Category)
                        : data.cardSetToCardCategoryId
                        ? (data.cardSetToCardCategoryId as Category)
                        : (Category.PLAYBOOK as Category),
                }}
                expanded={true}
                subHeader={showPage}
                handleClose={handleClose}
            >
                {props.children}
                <CardActions
                    textColor={textColor}
                    items={actionItems}
                    setMenu={setMenu}
                    expanded={expanded}
                />
                {menuAnchor && (
                    <CardMenu
                        permissions={permissions}
                        anchor={menuAnchor}
                        data={{
                            organisation: organisation || '',
                            id,
                        }}
                        handleDelete={handleDelete}
                        {...(!!handleCopy && {
                            handleCopy: () => handleCopy(id),
                        })}
                        setMenu={setMenu}
                    />
                )}
            </CardBase>
        </Box>
    );
};

export default CardSetComponent;
