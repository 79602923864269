import React from 'react';
import { StyledModal } from '../Modal';
import ModalContainer from '../ModalContainer';
import CardSetComponent from './CardSetComponent';
import StyledCardContent from '../../components/cards/CardContent';
import CardDetails from './CardDetails';
import CardInfo from './CardInfo';
import CardRelationships from './CardRelationships';
import { CardComponentData, CardPage, CardSetComponentData } from './cardTypes';
import { Box } from '@mui/material';
import { Card, CardSet, Playbook } from '../../API';
import CardComments from './CardComments';

interface EditCardModalProps {
    open: boolean;
    card: CardSet | Playbook;
    handleClose: () => void;
    data: CardSetComponentData;
    handleClick: (cardId: string, cardPage?: CardPage) => void;
    activeCardPage: CardPage;
    refreshCard: (id: string, organisation: string) => Promise<void>;
    handleDelete?: (id: string) => void;
    handleCopy?: () => Promise<void>;
}

const EditCardModal = ({
    open,
    card,
    handleClose,
    data,
    handleClick,
    activeCardPage,
    refreshCard,
    handleCopy,
    handleDelete,
}: EditCardModalProps) => {
    return (
        <StyledModal
            data-testid="edit-card-modal"
            key="edit-card-set-modal"
            open={open}
            onClose={handleClose}
            disableEnforceFocus
            sx={{
                zIndex: 1400,
            }}
        >
            <Box>
                {!!activeCardPage && (
                    <ModalContainer sx={{ maxWidth: '48rem' }}>
                        <CardSetComponent
                            data={data}
                            handleClose={handleClose}
                            showPage={activeCardPage}
                            onClick={(cardSetId: string, cardPage?: CardPage) =>
                                handleClick(cardSetId, cardPage)
                            }
                            handleDelete={handleDelete}
                            handleCopy={handleCopy}
                            expanded
                        >
                            <StyledCardContent expanded={true}>
                                {
                                    {
                                        [CardPage.DETAILS]: card && (
                                            <CardDetails
                                                card={card}
                                                onUpdate={refreshCard}
                                            />
                                        ),
                                        [CardPage.SCORES]: <Box></Box>,
                                        [CardPage.ACTIVITY]: <Box></Box>,
                                        [CardPage.COMMENTS]: (
                                            <CardComments card={card} />
                                        ),
                                        [CardPage.INFO]: card && (
                                            <CardInfo
                                                card={card}
                                                onUpdate={refreshCard}
                                            />
                                        ),
                                        [CardPage.RELATIONSHIPS]: card &&

                                                <CardRelationships
                                                    card={card}
                                                />
                                            
                                    }[activeCardPage]
                                }
                            </StyledCardContent>
                        </CardSetComponent>
                    </ModalContainer>
                )}
            </Box>
        </StyledModal>
    );
};

export default EditCardModal;
