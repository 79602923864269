import { Box, IconButton, Typography } from '@mui/material';
import { Card, PlaybookPageDataResponse } from '../../../API';
import AddIcon from '@mui/icons-material/Add';
import { truncateText } from '../../../helpers/utils';
import { AuthSession, UserPermissions, WorksheetSize } from '../../../globals';
import { PagePreview } from '../../workbooks/WorkbookDrawerPage';
import { useContext, useEffect, useRef, useState } from 'react';
import { CardContext } from '../../cards/context';
import { StyledModal } from '../../Modal';
import ModalContainer from '../../ModalContainer';
import CreateCard from '../../forms/CreateCard';
import AnalysisModal from './AnalysisModal';
import BusinessImpactLegend from '../../charts/BusinessImpactLegend';
import { colors } from '../../../helpers/scores';
import { AppContext, WorksheetContext } from '../../contexts';
import { OptionsValue } from './SwotGrid';
import { Field } from '../../../helpers/worksheets';
import { Chart } from 'chart.js';
import { BubbleDataPoint } from 'chart.js';
import { DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core';
import CardComponentSmall from '../../cards/CardComponentSmall';
import { cardToCardComponentProps } from '../../../pages/cards';
import CardMenu from '../../cards/CardMenu';
import { ReactComponent as MenuIcon } from '../../../assets/icons/Menu.svg';
import { getUserPermissions } from '../../../helpers/permissions';
import { PageIdentifier } from '../../cards/cardTypes';
import { ViewType } from '../../layouts/PageView';
import CardListView from '../../cards/views/CardListView';
import { Category, getCategoryHex } from '../../../helpers/category';

interface PestleAnalysisProps {
    cards: Card[];
    size: WorksheetSize;
    page: PlaybookPageDataResponse | PagePreview | undefined;
    workbookId?: string;
    pptView?: boolean | undefined;
    pageOptions?: any;
    selectedCard?: string | null;
    setSelectedCard?: React.Dispatch<React.SetStateAction<string | null>>;
    fullWidth?: boolean | undefined;
    addCardToWorkbook?: (card: Card, addToWorksheet: boolean) => Promise<void>;
    setActiveCardId?: React.Dispatch<React.SetStateAction<string | null>>;
    activeCardId?: string | null;
    workbookPermissions?: UserPermissions[] | undefined;
    setRightPanel?: React.Dispatch<React.SetStateAction<boolean>>;
    setInnerHeight?: React.Dispatch<React.SetStateAction<number>>;
    selectedViewType?: ViewType;
    cardsObject?: {
        [key: string]: Card;
    };
    pageIdentifier?: PageIdentifier;
    tableStyle?: any;
    handleTableRowClick?: (id: string) => void;
    onHover?: (id: string | null) => void;
}

interface GridRow {
    name: string;
    max_count: number;
    items: Card[][];
    start: number;
}
export const GhostGridCard = ({ top, onClick }: any) => {
    return (
        <Box
            className="hoverBox"
            sx={{
                border: 'dashed 2px #999',
                background: '#FFFFFF',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                width: '100%',
                top: top,
                left: 0,
                visibility: 'hidden',
                opacity: 0,
                transition: 'opacity 0.3s ease, visibility 0.3s ease',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <IconButton color="primary">
                <AddIcon sx={{ width: '15px', height: '15px' }} />
            </IconButton>
        </Box>
    );
};

const PestleAnalysis = ({
    cards,
    size,
    page,
    workbookId,
    pptView,
    pageOptions,
    selectedCard,
    setSelectedCard,
    fullWidth,
    addCardToWorkbook,
    setActiveCardId,
    activeCardId,
    workbookPermissions,
    setRightPanel,
    selectedViewType,
    cardsObject,
    pageIdentifier,
    tableStyle,
    handleTableRowClick,
    setInnerHeight,
    onHover,
}: PestleAnalysisProps) => {
    const {
        handleDelete,
        handleRemoveFromWorkbook,
        handleRemoveFromWorksheet,
        handleCopy,
        removeId,
    } = useContext(WorksheetContext);
    const borderRadius = size === WorksheetSize.SMALL ? '4px' : '6px';
    const { cardTypeObject, user } = useContext(AppContext);
    const { permissions, handleClose } = useContext(CardContext);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [selectedCategoryAttr, setSelectedCategoryAttr] = useState<
        string | null
    >(null);
    const [selectedTimescaleAttr, setSelectedTimescaleAttr] = useState<
        string | null
    >(null);
    const [menuCard, setMenuCard] = useState<Card | null>(null);
    const trend = Object.values(cardTypeObject).find(
        (type) => type.id === '11'
    );
    const category = trend?.attributeDefinitions?.find(
        (att) => att?.id === '11-A-2'
    );
    const time = trend?.attributeDefinitions?.find(
        (att) => att?.id === '11-A-3'
    );

    let categoryConfig: string = category?.configuration ?? '';
    categoryConfig = JSON.parse(categoryConfig);
    let categories: string[] = Array.isArray(categoryConfig)
        ? categoryConfig
        : JSON.parse(categoryConfig);

    let timeScalesConfig: string[] =
        JSON.parse(time?.configuration ?? '') ?? [];
    let timeScales: string[] = Array.isArray(timeScalesConfig)
        ? timeScalesConfig
        : JSON.parse(timeScalesConfig);

    const menuItems: any = [];
    if (
        handleRemoveFromWorkbook &&
        workbookPermissions?.includes(UserPermissions.EDIT)
    ) {
        menuItems.push({
            text: 'Remove from workbook',
            action: () => {
                menuCard && handleRemoveFromWorkbook(menuCard);
            },
        });
    }
    if (
        handleRemoveFromWorksheet &&
        workbookPermissions?.includes(UserPermissions.EDIT)
    ) {
        menuItems.push({
            text: 'Remove from worksheet',
            action: () => {
                menuCard && handleRemoveFromWorksheet(menuCard.id);
            },
        });
    }
    if (workbookPermissions?.includes(UserPermissions.DELETE)) {
        menuItems.push({
            text: 'Delete',
            action: () => {
                menuCard && handleDelete(menuCard);
            },
        });
    }
    if (handleCopy) {
        menuItems.push({
            text: 'Copy',
            action: () => {
                menuCard && handleCopy(menuCard, page?.id);
            },
        });
    }
    const getFilteredCards = (cards: Card[], subject: string, time: string) => {
        return cards.filter((card) => {
            let subjectAtt =
                card.attributes?.find(
                    (attribute) => attribute?.attributeDefinitionID === '11-A-2'
                )?.value ?? '';

            subjectAtt = subjectAtt.replace(/^"(.*)"$/, '$1');

            let timeAtt =
                card.attributes?.find(
                    (attribute) => attribute?.attributeDefinitionID === '11-A-3'
                )?.value ?? '';

            timeAtt = timeAtt.replace(/^"(.*)"$/, '$1');

            return subjectAtt === subject && timeAtt === time;
        });
    };

    const gridRows: GridRow[] = categories.map((category) => {
        return {
            name: category,
            items: timeScales.map((time) => {
                return getFilteredCards(removeId ? [] : cards, category, time);
            }),
            max_count: Math.max(
                ...timeScales
                    .map((time) => {
                        return getFilteredCards(
                            removeId ? [] : cards,
                            category,
                            time
                        );
                    })
                    .map((a) => a.length)
            ),
            start: 3,
        };
    });
    gridRows.forEach((gridRow, index) => {
        const prev = gridRows[index - 1];
        gridRow.start =
            index === 0
                ? 3
                : prev.start + (prev.max_count ? prev.max_count : 1) * 2;
    });
    const CardItem = ({
        item,
        index,
        gridRow,
        time,
        includes,
        scoreEnabled,
    }: {
        item: Card;
        index: number;
        gridRow: GridRow;
        time: string;
        includes: OptionsValue[];
        scoreEnabled: boolean;
    }) => {
        const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
        const { removeId } = useContext(WorksheetContext);

        const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
            if (e) {
                e.stopPropagation();
                setMenuAnchor(e.currentTarget);
                setMenuCard(item);
            } else {
                setMenuAnchor(null);
            }
        };

        const handleCardClick = (card: Card) => {
            if (setSelectedCard) {
                setSelectedCard(null);
                setTimeout(() => setSelectedCard(card.id), 500);
            }
            setRightPanel && setRightPanel(true);
            setActiveCardId && setActiveCardId(card.id);
        };

        let scoreData = item.toScoreData?.items.find(
            (scoreItem) => scoreItem?.scoreDefinitionId === '11-S-1'
        )?.data;
        let value = scoreData ? scoreData[scoreData.length - 1]?.value : 0;
        let color = colors.find((colorItem) => colorItem.score == value);

        return removeId && removeId === item.id ? (
            <DroppableCell gridRow={gridRow} time={time} background={'#fff'}>
                <Box />
            </DroppableCell>
        ) : (
            <Draggable id={item?.id} index={index} gridRow={gridRow}>
                <Box
                    {...(onHover && {
                        onMouseEnter: (
                            event: React.MouseEvent<HTMLElement>
                        ) => {
                            onHover(item.id);
                        },
                        onMouseLeave: (
                            event: React.MouseEvent<HTMLElement>
                        ) => {
                            onHover(null);
                        },
                    })}
                    sx={{
                        position: 'relative',
                        ...(index === 0 &&
                            workbookId &&
                            workbookPermissions?.includes(
                                UserPermissions.EDIT
                            ) && {
                                '&:hover': {
                                    mt: '18px',
                                    borderRadius: '0 0 6px 6px',
                                },
                                '&:hover .card-box': {
                                    fontSize: '0.8em',
                                },
                                '&:hover .hoverBox': {
                                    opacity: 1,
                                    visibility: 'visible',
                                },
                            }),
                        background: scoreEnabled
                            ? color?.value
                            : getCategoryHex(Category.MARKETS),
                        borderRadius: borderRadius,
                        padding: '0.5em',
                        cursor: !!workbookId ? 'pointer' : 'initial',
                        transition: 'border 2s, transform 0.5s',
                        borderColor: 'transparent',
                        borderWidth: index > 0 ? '3px' : 0,
                        borderStyle: 'solid',
                        ...(workbookId &&
                            (index > 0 ||
                                !workbookPermissions?.includes(
                                    UserPermissions.EDIT
                                )) && {
                                ':hover': {
                                    borderColor: '#5e7390',
                                    transform: 'scale(0.94)',
                                },
                            }),
                        ...(workbookId &&
                            index === 0 &&
                            workbookPermissions?.includes(
                                UserPermissions.EDIT
                            ) && {
                                ':hover': {
                                    height: '78%',
                                },
                            }),
                        height: '100%',
                        border:
                            item.id === activeCardId
                                ? 'solid 4px rgb(61, 116, 222)'
                                : 'inherit',
                    }}
                    onClick={() => {
                        if (workbookId) {
                            handleCardClick(item);
                        }
                    }}
                >
                    {index === 0 &&
                        workbookPermissions?.includes(UserPermissions.EDIT) && (
                            <GhostGridCard
                                top={-20}
                                onClick={() => {
                                    setSelectedType('11');
                                    setSelectedCategoryAttr(gridRow.name);
                                    setSelectedTimescaleAttr(time);
                                }}
                            />
                        )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: '100%',
                            height: '100%',
                            justifyContent: 'flex-start',
                            width: pptView ? '90%' : '100%',
                            color: '#fff',
                            pt: pptView ? 2 : 0,
                        }}
                        className="card-box"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                variant="body1"
                                fontSize={pptView ? '1em' : '0.8em'}
                                lineHeight="1.250em"
                                sx={{
                                    margin: !includes?.find(
                                        (includeItem) =>
                                            includeItem.value === 'score'
                                    )?.enabled
                                        ? '-5px 0 5px 0'
                                        : 'inherit',
                                }}
                                className="card-title"
                            >
                                <strong>
                                    {truncateText(
                                        item.name,
                                        size === WorksheetSize.CAROUSEL
                                            ? 40
                                            : 15
                                    )}
                                </strong>
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '100%',
                                    pl: 0.5,
                                    marginTop:
                                        size === WorksheetSize.SMALL
                                            ? '-4px'
                                            : '-10px',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    fontSize={pptView ? '1.4em' : '1.2em'}
                                >
                                    {value > 0 ? `+${value}` : value}
                                </Typography>
                            </Box>
                        </Box>
                        {!pptView && (
                            <Typography
                                variant="body2"
                                fontSize="0.775em"
                                className="card-description"
                            >
                                {truncateText(
                                    item?.description ?? '',
                                    size === WorksheetSize.CAROUSEL ? 110 : 40
                                )}
                            </Typography>
                        )}
                    </Box>
                    {workbookId && (
                        <IconButton
                            aria-label=""
                            id="menu-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setMenu(e);
                            }}
                            sx={{
                                ml: 2,
                                color: '#fff',
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                            }}
                        >
                            <MenuIcon width={'20px'} height={'20px'} />
                        </IconButton>
                    )}
                    {menuAnchor && (
                        <CardMenu
                            permissions={[
                                UserPermissions.EDIT,
                                UserPermissions.DELETE,
                            ]}
                            anchor={menuAnchor}
                            data={{
                                organisation: item.organisation || '',
                                id: item.id,
                            }}
                            menuItems={menuItems}
                            setMenu={setMenu}
                        />
                    )}
                </Box>
                {removeId && (
                    <Box
                        sx={{
                            width: '200px',
                            height: '600px',
                            background: 'red',
                        }}
                    />
                )}
            </Draggable>
        );
    };

    function Draggable({ children, gridRow, id, index }: any) {
        const { attributes, listeners, setNodeRef } = useDraggable({
            id: `remove_${id}`,
        });

        return (
            <Box
                ref={setNodeRef}
                {...listeners}
                {...attributes}
                style={{
                    width: '100%',
                    gridRowStart: gridRow.start + index * 2,
                    gridRowEnd: gridRow.start + index * 2 + 2,
                }}
            >
                {children}
            </Box>
        );
    }
    function DroppableCell({
        gridRow,
        children,
        time,
        background,
    }: {
        gridRow: any;
        children: React.ReactNode;
        time: string;
        background?: string;
    }) {
        const droppableId = `11_${gridRow.name}+${time}+`;
        const { isOver, setNodeRef } = useDroppable({ id: droppableId });
        const style = {
            gridRowStart: gridRow.start,
            gridRowEnd:
                gridRow.start + (gridRow.max_count ? gridRow.max_count : 1) * 2,
            background: background
                ? background
                : isOver
                ? '#99b7e4'
                : '#f2f2f2',
            borderRadius: borderRadius,
            position: 'relative',
            ...(workbookId &&
                workbookPermissions?.includes(UserPermissions.EDIT) && {
                    '&:hover .hoverBox': {
                        opacity: 1,
                        visibility: 'visible',
                    },
                }),
        };

        return (
            <Box
                ref={setNodeRef}
                sx={{
                    ...style,
                    background: isOver ? '#99b7e4' : style.background,
                }}
            >
                {children}
            </Box>
        );
    }
    const getBoxItems = (
        items: Card[],
        gridRow: GridRow,
        time: string,
        workbookPermissions: UserPermissions[] | undefined,
        user: AuthSession | null
    ) => {
        const includes: OptionsValue[] = pageOptions?.layout[0].include ?? [];
        const scoreEnabled: boolean = includes?.some(
            (includeItem) =>
                includeItem.value === 'score' && includeItem.enabled
        );

        return (
            <>
                {items.length > 0 ? (
                    <>
                        {items.map((item, index) => (
                            <CardItem
                                key={item.id}
                                item={item}
                                index={index}
                                gridRow={gridRow}
                                time={time}
                                includes={includes}
                                scoreEnabled={scoreEnabled}
                            />
                        ))}
                        {items.length !== gridRow.max_count && (
                            <Box
                                sx={{
                                    gridRowStart:
                                        gridRow.start + items.length * 2,
                                    gridRowEnd:
                                        gridRow.start + gridRow.max_count * 2,
                                    background: '#f2f2f2',
                                    borderRadius: borderRadius,
                                }}
                            />
                        )}
                    </>
                ) : (
                    <DroppableCell gridRow={gridRow} time={time}>
                        {workbookPermissions?.includes(
                            UserPermissions.EDIT
                        ) && (
                            <GhostGridCard
                                top={0}
                                onClick={() => {
                                    setSelectedType('11');
                                    setSelectedCategoryAttr(gridRow.name);
                                    setSelectedTimescaleAttr(time);
                                }}
                            />
                        )}
                    </DroppableCell>
                )}
            </>
        );
    };
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref && ref.current?.clientHeight) {
            setInnerHeight && setInnerHeight(ref.current.clientHeight);
        }
    }, [ref.current?.clientHeight]);

    return selectedViewType === ViewType.LIST ? (
        <Box sx={{ height: '100%' }}>
            <CardListView
                cardItems={
                    cards?.map((card) => cardToCardComponentProps(card)) || []
                }
                cardsObject={cardsObject}
                pageIdentifier={pageIdentifier}
                tableStyle={tableStyle}
                worksheetRowClick={handleTableRowClick}
                worksheetMenuItems={menuItems}
                setMenuCard={setMenuCard}
            />
        </Box>
    ) : (
        <Box ref={ref} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {workbookId && permissions?.includes(UserPermissions.EDIT) && (
                    <Box
                        sx={{
                            width: '45px%',
                            height: '45px',
                            marginBottom: '20px',
                        }}
                    >
                        <IconButton
                            color="primary"
                            sx={{
                                borderRadius: '50px',
                                border: 'dashed 2px blue',
                            }}
                            onClick={() => setSelectedType('11')}
                        >
                            <AddIcon
                                sx={{
                                    fontSize: '3rem',
                                    width: '25px',
                                    height: '23px',
                                }}
                            />
                        </IconButton>
                    </Box>
                )}
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gridTemplateRows: `repeat(${
                        gridRows.reduce(function (acc, obj) {
                            return (
                                acc + (obj.max_count ? obj.max_count * 2 : 2)
                            );
                        }, 0) + 2
                    }, 1fr)`,
                    gridGap: '3px',
                    width: '100%',
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        background: '#8a95a5',
                        gridColumnStart: 2,
                        gridColumnEnd: 6,
                        height: size === WorksheetSize.SMALL ? '18px' : '36px',
                        borderRadius: borderRadius,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            color: '#ffffff',
                            fontWeight: 'bold',
                            fontSize: pptView
                                ? '1.4rem'
                                : size === WorksheetSize.SMALL
                                ? '0.4rem'
                                : '.875rem',
                        }}
                    >
                        Timescales
                    </Typography>
                </Box>
                {timeScales.map((time, index) => {
                    return (
                        <Box
                            sx={{
                                background: '#9e9e9e',
                                gridColumnStart: index + 2,
                                gridColumnEnd: index + 3,
                                height:
                                    size === WorksheetSize.SMALL
                                        ? '18px'
                                        : '36px',
                                borderRadius: borderRadius,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    color: '#ffffff',
                                    fontWeight: 'bold',
                                    fontSize: pptView
                                        ? '1rem'
                                        : size === WorksheetSize.SMALL
                                        ? '0.4rem'
                                        : '.875rem',
                                }}
                            >
                                {time}
                            </Typography>
                        </Box>
                    );
                })}

                {gridRows.map((gridRow) => {
                    return (
                        <>
                            <Box
                                key={gridRow.name}
                                sx={{
                                    gridRowStart: gridRow.start,
                                    gridRowEnd:
                                        gridRow.start +
                                        (gridRow.max_count
                                            ? gridRow.max_count
                                            : 1) *
                                            2,
                                    background: '#8a95a5',
                                    borderRadius: borderRadius,
                                    display: 'flex',
                                    minHeight: pptView ? '50px' : 'inherit',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    color="#ffffff"
                                    sx={{
                                        fontSize: pptView
                                            ? '1.2rem'
                                            : size === WorksheetSize.SMALL
                                            ? '0.5rem'
                                            : '1rem',
                                        pl:
                                            size === WorksheetSize.SMALL
                                                ? 0.5
                                                : 1,
                                        pt:
                                            size === WorksheetSize.SMALL
                                                ? 0.5
                                                : 1,
                                    }}
                                >
                                    {gridRow.name}
                                </Typography>
                            </Box>
                            {gridRow.items.map((item, index) => {
                                return (
                                    <>
                                        {getBoxItems(
                                            item,
                                            gridRow,
                                            timeScales[index],
                                            workbookPermissions,
                                            user
                                        )}
                                    </>
                                );
                            })}
                        </>
                    );
                })}
            </Box>
            <BusinessImpactLegend size={size} colors={colors} />
            {!!workbookId && (
                <StyledModal
                    key="modal"
                    open={!!selectedType}
                    onClose={() => setSelectedType(null)}
                >
                    <Box>
                        {!!selectedType && (
                            <ModalContainer sx={{ maxWidth: '35rem' }}>
                                <CreateCard
                                    cardTypeFromDrawer={selectedType}
                                    cardSet={workbookId}
                                    handleClose={() => {
                                        handleClose();
                                        setSelectedType(null);
                                    }}
                                    selectedAttributes={[
                                        {
                                            __typename: 'Attribute',
                                            attributeDefinitionID: '11-A-2',
                                            value: selectedCategoryAttr,
                                        },
                                        {
                                            __typename: 'Attribute',
                                            attributeDefinitionID: '11-A-3',
                                            value: selectedTimescaleAttr,
                                        },
                                    ]}
                                    addCardToWorkbook={addCardToWorkbook}
                                    worksheet={true}
                                    close={() => setSelectedType(null)}
                                />
                            </ModalContainer>
                        )}
                    </Box>
                </StyledModal>
            )}
            {selectedCard && cards.find((card) => card.id === selectedCard) && (
                <AnalysisModal
                    card={
                        cards.find((card) => card.id === selectedCard) ?? null
                    }
                    handleClose={() => {
                        setSelectedCard && setSelectedCard(null);
                    }}
                    fullWidth={fullWidth}
                    setSelectedCard={setSelectedCard}
                />
            )}
            {removeId && (
                <DragOverlay>
                    {removeId && cards.find((card) => card.id === removeId) && (
                        <Box sx={{ zIndex: 9999 }}>
                            <CardComponentSmall
                                data={cardToCardComponentProps(
                                    cards.find(
                                        (card) => card.id === removeId
                                    ) as Card
                                )}
                                smallCard={true}
                                swotGrid={true}
                                swotColor=""
                            />
                        </Box>
                    )}
                </DragOverlay>
            )}
        </Box>
    );
};

export default PestleAnalysis;
