import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
    CardSet,
    GetCardSetWithCardsQuery,
    ModelSortDirection,
    Worksheet,
    WorksheetsByParentIdQuery,
} from '../../../API';
import { getUserOrganisation } from '../../../helpers/auth';
import { AppContext } from '../../contexts';
import { generateClient, GraphQLQuery } from 'aws-amplify/api';
import { worksheetsByParentId } from '../../../graphql/queries';
import { truncateText } from '../../../helpers/utils';
import { getWorksheetThumbnail } from '../../../helpers/worksheets';
import { worksheets } from '../worksheets';
import { processSteps } from '../processSteps';
import { CreateCardOptions } from '../../cards/cardTypes';
import { CardTypeIdsMap } from './PageTypesColumn';
import {
    ExtendedWorksheet,
    ExtendedWorksheetSetting,
} from '../CreateCard/types';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { getCardSetWithCards } from '../../../graphql/custom-queries';

interface WorkbookListProps {
    workbookIds: (string | null)[] | null | undefined;
    selectedWorkbook: CardSet | undefined;
    selectedWorksheetItems: Worksheet[];
    setSelectedWorksheetItems: React.Dispatch<
        React.SetStateAction<Worksheet[]>
    >;
    selectedProcessId: string | null;
    selectedCreateOption:
        | CreateCardOptions.BY_PROCESS
        | CreateCardOptions.BY_WORKBOOK;
    workbooksOfPlaybook: CardSet[] | undefined;
    setSelectedWorkbook: React.Dispatch<
        React.SetStateAction<CardSet | undefined>
    >;
    setSelectedProcessId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const Avatar = ({ worksheet }: { worksheet: string }) => {
    const WorksheetIcon = getWorksheetThumbnail(worksheet);
    if (!WorksheetIcon) {
        console.error(`Icon not found for worksheet: ${worksheet}`);
        return null;
    }
    return (
        <Box
            component="img"
            src={WorksheetIcon}
            sx={{
                width: '40px',
                height: '40px',
            }}
        />
    );
};
type StepWorksheetTypeMap = { [worksheetId: string]: string };

const WorksheetList = ({
    workbookIds,
    selectedWorkbook,
    selectedWorksheetItems,
    setSelectedWorksheetItems,
    selectedProcessId,
    selectedCreateOption,
    workbooksOfPlaybook,
    setSelectedWorkbook,
    setSelectedProcessId,
}: WorkbookListProps) => {
    const [cardTypeIds, setCardTypeIds] = useState<CardTypeIdsMap>({});
    const theme = useTheme();
    const { user } = useContext(AppContext);
    const organisation = getUserOrganisation(user);
    const client = generateClient();
    const [workbooks, setWorkbooks] = useState<CardSet[]>();
    const [groupedWorksheetItems, setGroupedWorksheetItems] = useState<{
        [typeId: string]: ExtendedWorksheet[];
    }>({});
    const [openGroups, setOpenGroups] = useState<{ [typeId: string]: boolean }>(
        {}
    );
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredGroupedItems, setFilteredGroupedItems] = useState<{
        [typeId: string]: ExtendedWorksheet[];
    }>({});
    const [searchOpen, setSearchOpen] = useState(false);
    const [allWorksheets, setAllWorksheets] = useState<any[]>([]);
    const [stepWorksheetTypes, setStepWorksheetTypes] =
        useState<StepWorksheetTypeMap | null>(null);

    useEffect(() => {
        const filterWorksheets = () => {
            if (!searchQuery) {
                setFilteredGroupedItems(groupedWorksheetItems);
                return;
            }

            const filteredItems = Object.entries(groupedWorksheetItems).reduce(
                (acc, [group, items]) => {
                    const filtered = items.filter((item) =>
                        item?.name
                            ?.toLowerCase()
                            .includes(searchQuery?.toLowerCase())
                    );
                    if (filtered.length > 0) {
                        acc[group] = filtered;
                    }
                    return acc;
                },
                {} as { [typeId: string]: ExtendedWorksheet[] }
            );
            setFilteredGroupedItems({ ...filteredItems });
        };

        filterWorksheets();
    }, [searchQuery, groupedWorksheetItems]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };
    useEffect(() => {
        let idsMap: CardTypeIdsMap = {};
        worksheets.forEach((worksheet) => {
            worksheet.auto.forEach((autoId) => {
                if (!idsMap[autoId]) {
                    idsMap[autoId] = [];
                }
                idsMap[autoId].push(worksheet.i);
            });
        });

        setCardTypeIds(idsMap);
    }, []);

    useEffect(() => {
        const initialState: { [typeId: string]: boolean } = {};
        Object.keys(groupedWorksheetItems).forEach((key) => {
            initialState[key] = true;
        });
        setOpenGroups(initialState);
    }, [groupedWorksheetItems]);

    const getWorkbook = async (id: string) => {
        const response = await client.graphql<
            GraphQLQuery<GetCardSetWithCardsQuery>
        >({
            query: getCardSetWithCards,
            variables: {
                organisation: organisation,
                id: id,
            },
        });
        return response;
    };

    useEffect(() => {
        const fetchWorkbooksAndWorksheets = async () => {
            if (workbookIds?.length) {
                try {
                    const fetchedWorkbooks = await Promise.all(
                        workbookIds.map(async (id) => {
                            if (id) {
                                const response = await getWorkbook(id);
                                return response?.data?.getCardSet;
                            }
                            return null;
                        })
                    );

                    const validWorkbooks = fetchedWorkbooks.filter(
                        Boolean
                    ) as CardSet[];
                    setWorkbooks(validWorkbooks);

                    const worksheets = await Promise.all(
                        validWorkbooks.map(async (workbook) => {
                            if (workbook?.id) {
                                return await getWorksheetsForWorkBook(
                                    workbook.id
                                );
                            }
                            return [];
                        })
                    );

                    const flattenedWorksheets = worksheets.flat();
                    setAllWorksheets(flattenedWorksheets);
                } catch (error) {
                    console.error(
                        'Error fetching workbooks or worksheets:',
                        error
                    );
                }
            }
        };

        fetchWorkbooksAndWorksheets();
    }, [workbookIds]);

    const getWorksheetsForWorkBook = async (workbookId: string) => {
        try {
            const response = (await client.graphql({
                query: worksheetsByParentId,
                variables: {
                    parentId: workbookId,
                    sortDirection: ModelSortDirection.ASC,
                },
            })) as { data: WorksheetsByParentIdQuery };

            const worksheets = response.data?.worksheetsByParentId?.items ?? [];
            return worksheets;
        } catch (e) {
            console.log(e);
            return [];
        }
    };
    const handleToggleGroup = (typeId: string) => {
        setOpenGroups((prev) => ({ ...prev, [typeId]: !prev[typeId] }));
    };
    const handleWorksheetData = (worksheetsList: ExtendedWorksheet[]) => {
        worksheetsList.forEach((w) => {
            const chartTypeWorksheet = worksheets.find(
                (item) => item.i === w.typeId
            );

            const updatedChartTypeWorksheet = chartTypeWorksheet
                ? {
                      ...chartTypeWorksheet,
                      parentWorksheetName: w.name,
                      parentWorksheetId: w.id,
                  }
                : null;

            const uniqueWorksheetIds = new Set<string>();

            chartTypeWorksheet?.auto?.forEach((item) => {
                const worksheetIds = cardTypeIds[item];
                worksheetIds?.forEach((id) => {
                    if (id !== chartTypeWorksheet?.i) {
                        uniqueWorksheetIds.add(id);
                    }
                });
            });

            const worksheetIdsArray = Array.from(uniqueWorksheetIds);
            const filteredWorksheets = worksheetIdsArray
                .map((item) => {
                    const worksheet = worksheets.find(
                        (worksheet) => item === worksheet?.i && worksheet?.e
                    );
                    if (worksheet) {
                        return {
                            ...worksheet,
                            parentWorksheetName: w.name,
                            parentWorksheetId: w.id,
                        };
                    }
                    return null;
                })
                .filter(Boolean);

            w.chartTypeWorksheet =
                updatedChartTypeWorksheet as ExtendedWorksheetSetting;
            w.suggestedWorksheets =
                filteredWorksheets as ExtendedWorksheetSetting[];
        });

        return worksheetsList;
    };
    useEffect(() => {
        const stepWorksheetTypeMap: { [key: string]: string } = {};
        processSteps.forEach((process) => {
            process.steps.forEach((step) => {
                step.worksheets.forEach((sw) => {
                    stepWorksheetTypeMap[sw.i] = process.id;
                });
            });
        });
        setStepWorksheetTypes(stepWorksheetTypeMap);
    }, [processSteps]);
    const fetchWorksheetsData = async (
        workbookIds: (string | null)[] | null | undefined,
        selectedProcessId: string | null
    ) => {
        if (selectedCreateOption === CreateCardOptions.BY_WORKBOOK) {
            const validWorksheets = selectedWorkbook
                ? await getWorksheetsForWorkBook(selectedWorkbook.id)
                : allWorksheets;
            const filteredWorksheets = validWorksheets.filter(
                (worksheet): worksheet is Worksheet => worksheet !== null
            );
            const updatedWorksheets = handleWorksheetData(filteredWorksheets);

            const groupedByProcess: {
                [processName: string]: ExtendedWorksheet[];
            } = {};
            processSteps.forEach((process) => {
                const processName = process.name || `Process ${process.id}`;
                const stepWorksheetTypeIds: string[] = [];
                process.steps.forEach((step) => {
                    step.worksheets.forEach((sw) => {
                        stepWorksheetTypeIds.push(sw.i);
                    });
                });
                groupedByProcess[processName] = updatedWorksheets.filter((uw) =>
                    stepWorksheetTypeIds.includes(uw.typeId)
                );
            });
            setGroupedWorksheetItems(groupedByProcess);
        } else if (selectedCreateOption === CreateCardOptions.BY_PROCESS) {
            const worksheetsArray =
                selectedProcessId && workbookIds && !searchQuery
                    ? await Promise.all(
                          workbookIds.map(async (workbookId: string | null) => {
                              if (!workbookId) return null;
                              return getWorksheetsForWorkBook(workbookId);
                          })
                      )
                    : allWorksheets;
            const worksheetList = worksheetsArray.flat().filter(Boolean);
            const processData = processSteps.find(
                (item) => item.id === selectedProcessId
            );
            const uniqueWorksheetTypeIds = processData?.steps
                ? Array.from(
                      new Set(
                          processData.steps.flatMap((step) =>
                              step.worksheets.map((w) => w.i)
                          )
                      )
                  )
                : [];

            const filteredData = worksheetList.filter(
                (w) => w !== null && uniqueWorksheetTypeIds.includes(w?.typeId)
            );
            const validWorksheets = filteredData.filter(
                (w): w is Worksheet => w !== null
            );
            const updatedWorksheets = selectedProcessId
                ? handleWorksheetData(validWorksheets)
                : allWorksheets;
            const groupedByWorkbook: {
                [workbookName: string]: ExtendedWorksheet[];
            } = {};

            updatedWorksheets.forEach((uw) => {
                const foundWorkbook = workbooks?.find(
                    (wb) => wb.id === uw?.parentId
                );
                const wbName = foundWorkbook?.name || '';

                if (!groupedByWorkbook[wbName]) {
                    groupedByWorkbook[wbName] = [];
                }
                if (uw) {
                    groupedByWorkbook[wbName].push(uw);
                }
            });
            setGroupedWorksheetItems(groupedByWorkbook);
        }
    };

    useEffect(() => {
        fetchWorksheetsData(workbookIds, selectedProcessId);
    }, [
        selectedWorkbook,
        selectedCreateOption,
        workbookIds,
        selectedProcessId,
        workbooks,
        searchQuery,
    ]);

    return (
        <Box
            sx={{
                borderRight: 'solid 1px #ccc',
                borderLeft: 'solid 1px #ccc',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                    display: 'flex',
                    alignItems: 'center',
                    height: '41px',
                }}
                onClick={() => setSearchOpen((prev) => !prev)}
            >
                {!searchOpen && (
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                    >
                        Worksheets
                    </Typography>
                )}
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        margin: '4px 2px',
                        width: '30px',
                        verticalAlign: 'bottom',
                        pl: '20px',
                    }}
                >
                    <form action="/search" method="get">
                        <input
                            className="search"
                            id="searchleft"
                            type="search"
                            name="q"
                            placeholder="Search by name"
                            onChange={handleSearchChange}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                }
                            }}
                            style={{
                                background: 'inherit',
                                width: searchOpen ? '200px' : '0px',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                transition: 'width 0.3s ease',
                                left: '30px',
                                top: '-5px',
                                padding: searchOpen ? '8px' : '0px',
                                opacity: searchOpen ? 1 : 0,
                            }}
                        />
                        <label
                            className="button searchbutton"
                            style={{
                                background: 'initial',
                                color: 'rgb(158, 158, 158)',
                                left: '10px',
                                top: '-22px',
                            }}
                            htmlFor="searchleft"
                            onClick={() => setSearchOpen((prev) => !prev)}
                        >
                            <span className="mglass">&#9906;</span>
                        </label>
                    </form>
                </Box>
            </Box>

            {(selectedProcessId || selectedWorkbook || searchQuery) && (
                <List sx={{ width: '100%', overflowY: 'auto', flex: 1 }}>
                    {Object.entries(filteredGroupedItems).map(
                        ([groupTitle, items]) => {
                            const selectedCount = items.filter((item) =>
                                selectedWorksheetItems.some(
                                    (w) => w.id === item.id
                                )
                            ).length;

                            const displayText =
                                items.length > 0
                                    ? `(${selectedCount}/${items.length})`
                                    : '(0)';

                            return (
                                <React.Fragment key={groupTitle}>
                                    <ListSubheader
                                        disableSticky
                                        sx={{
                                            cursor: 'pointer',
                                            position: 'static',
                                            borderBottom: '1px solid',
                                            borderColor:
                                                theme.palette.background.paper,
                                            pb: 1,
                                        }}
                                        onClick={() =>
                                            handleToggleGroup(groupTitle)
                                        }
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Tooltip
                                                title={groupTitle}
                                                placement="top"
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ fontWeight: 'bold' }}
                                                >
                                                    {truncateText(
                                                        groupTitle,
                                                        20
                                                    )}
                                                </Typography>
                                            </Tooltip>
                                            <Box
                                                sx={{
                                                    marginLeft: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ mr: 1 }}
                                                >
                                                    {displayText}
                                                </Typography>
                                                {openGroups[groupTitle] ? (
                                                    <ExpandLess />
                                                ) : (
                                                    <ExpandMore />
                                                )}
                                            </Box>
                                        </Box>
                                    </ListSubheader>

                                    {openGroups[groupTitle] &&
                                        (items.length === 0 ? (
                                            <ListItem>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{ ml: 1 }}
                                                        >
                                                            No worksheets
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ) : (
                                            items.map((item) => {
                                                const isSelected =
                                                    selectedWorksheetItems.some(
                                                        (w) => w.id === item.id
                                                    );
                                                const worksheetItem =
                                                    worksheets.find(
                                                        (ws) =>
                                                            ws.i ===
                                                            item?.typeId
                                                    );

                                                return (
                                                    <ListItem
                                                        key={item.id}
                                                        alignItems="center"
                                                        sx={{
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                bgcolor:
                                                                    'background.paper',
                                                            },
                                                            bgcolor: isSelected
                                                                ? 'background.paper'
                                                                : 'none',
                                                            py: 0,
                                                            mb: 0.2,
                                                        }}
                                                        onClick={() => {
                                                            setSelectedWorksheetItems(
                                                                (prev) =>
                                                                    prev.some(
                                                                        (w) =>
                                                                            w.id ===
                                                                            item.id
                                                                    )
                                                                        ? prev.filter(
                                                                              (
                                                                                  w
                                                                              ) =>
                                                                                  w.id !==
                                                                                  item.id
                                                                          )
                                                                        : [
                                                                              ...prev,
                                                                              item,
                                                                          ]
                                                            );
                                                            if (searchQuery) {
                                                                if (
                                                                    selectedCreateOption ===
                                                                    CreateCardOptions.BY_WORKBOOK
                                                                ) {
                                                                    const associatedWorkbook =
                                                                        workbooksOfPlaybook?.find(
                                                                            (
                                                                                wb
                                                                            ) =>
                                                                                wb.id ===
                                                                                item.parentId
                                                                        );
                                                                    setSelectedWorkbook(
                                                                        associatedWorkbook
                                                                    );
                                                                } else if (
                                                                    selectedCreateOption ===
                                                                    CreateCardOptions.BY_PROCESS
                                                                ) {
                                                                    const associatedProcessId =
                                                                        stepWorksheetTypes &&
                                                                        stepWorksheetTypes[
                                                                            item
                                                                                .typeId
                                                                        ];
                                                                    setSelectedProcessId(
                                                                        associatedProcessId ||
                                                                            null
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <ListItemAvatar
                                                            sx={{
                                                                marginTop: 0,
                                                            }}
                                                        >
                                                            {worksheetItem && (
                                                                <Box
                                                                    sx={{
                                                                        mr: 1,
                                                                        borderRadius:
                                                                            '8px',
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        worksheet={
                                                                            worksheetItem?.n
                                                                        }
                                                                    />
                                                                </Box>
                                                            )}
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            sx={{
                                                                padding: 1,
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                marginTop: 0,
                                                            }}
                                                            primaryTypographyProps={{
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                            primary={truncateText(
                                                                item?.name ||
                                                                    '',
                                                                45
                                                            )}
                                                        />
                                                    </ListItem>
                                                );
                                            })
                                        ))}
                                </React.Fragment>
                            );
                        }
                    )}
                </List>
            )}
        </Box>
    );
};

export default WorksheetList;
