import React, { useContext, useEffect, useState } from 'react';
import {
    Card,
    CardSet,
    CardsCardSetsByCardIdQuery,
    CreatePlaybookMutation,
    DeletePlaybookPageMutation,
    EntityType,
    ModelSortDirection,
    Playbook,
    PlaybookPage,
    Relationship,
    RelationshipType,
    RelationshipsBySourceIdQuery,
    RelationshipsByTargetIdQuery,
    UpdatePlaybookMutation,
    Worksheet,
    WorksheetsByParentIdQuery,
} from '../../../API';
import { getCategoryHex } from '../../../helpers/category';
import { ReactComponent as CardIcon } from '../../../assets/icons/Small-card.svg';
import { ReactComponent as CardSetIcon } from '../../../assets/icons/Small-card-set.svg';
import { ReactComponent as WorkbooksIcon } from '../../../assets/icons/Small-workbook.svg';
import RelationshipGridView from './RelationshipGridView';
import {
    cardsCardSetsByCardId,
    relationshipsBySourceId,
    relationshipsByTargetId,
    worksheetsByParentId,
} from '../../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import {
    CardComponentType,
    CardSetComponentData,
    ViewType,
} from '../cardTypes';
import { Alert, Box, IconButton, Snackbar, Tooltip } from '@mui/material';
import TileViewIcon from '../../../assets/images/menubar/grid-view-unselected.png';
import TileViewIconActive from '../../../assets/images/menubar/grid-view.png';
import ListViewIcon from '../../../assets/images/menubar/list-view-unselected.png';
import ListViewIconActive from '../../../assets/images/menubar/list-view.png';
import RelationshipListView from './RelationshipListView';
import { StyledModal } from '../../Modal';
import ModalContainer from '../../ModalContainer';
import AddExistingCards from '../../forms/AddExistingCard';
import CreatePlaybook, { NewPlayBook } from '../../forms/CreatePlaybook';
import { addCardToCardSet } from '../../../actions/CardSets';
import { UserPermissions } from '../../../globals';
import { getUserPermissions } from '../../../helpers/permissions';
import { AppContext } from '../../contexts';
import useSnackbar from '../../../hooks/useSnackbar';
import { getWorkbook } from '../../forms/CreatePlaybookPage/WorkbookList';
import { getUserOrganisation } from '../../../helpers/auth';
import { getWorksheetById } from '../../../pages/workbooks/[pid]';
import {
    addOrRemoveCardsFromSet,
    deletePlaybookPage,
    updatePlaybook,
} from '../../../graphql/mutations';
import { deleteRelationship } from '../../../helpers/relationships';
import CardDelete from '../CardDelete';
import RemoveWorkbook from './RemoveWorkbook';

export enum RelationshipFormType {
    CARD = 'card-link',
    CARD_SET = 'containing-card-set',
    WORKBOOK = 'containing-workbook',
    WORKSHEET = 'worksheet',
}
interface CardRelationshipsProps {
    card: Card | CardSet | Playbook;
    worksheetCard?: boolean | undefined;
}

const iconStyles = { width: 40, height: 40, display: 'inline' };

const client = generateClient();

const getRelationshipsByTargetId = async (targetId: string) => {
    try {
        const response = (await client.graphql({
            query: relationshipsByTargetId,
            variables: {
                targetId,
            },
        })) as { data: RelationshipsByTargetIdQuery };

        return (
            response.data?.relationshipsByTargetId?.items?.filter(
                (item): item is Relationship => item !== null
            ) ?? []
        );
    } catch (error) {
        console.error(
            `Error fetching relationships for targetId ${targetId}:`,
            error
        );
        return [];
    }
};

const getWorksheetsForWorkBook = async (
    workbookId: string,
    cardId: string
): Promise<Worksheet[]> => {
    if (!workbookId) return [];
    try {
        const response = (await client.graphql({
            query: worksheetsByParentId,
            variables: {
                parentId: workbookId,
                sortDirection: ModelSortDirection.ASC,
            },
        })) as { data: WorksheetsByParentIdQuery };

        const fetchedWorksheets =
            response.data?.worksheetsByParentId?.items ?? [];
        const nonNullWorksheets: Worksheet[] = fetchedWorksheets.filter(
            (w): w is Worksheet => w !== null
        );
        return nonNullWorksheets.filter(
            (worksheet: Worksheet) =>
                worksheet.toDataSources?.items?.some(
                    (item) => item?.targetCardId === cardId
                ) ?? false
        );
    } catch (e) {
        console.error(e);
        return [];
    }
};

const CardRelationships = ({ card, worksheetCard }: CardRelationshipsProps) => {
    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();
    const { id } = card;
    const { userProfileObject, user } = useContext(AppContext);
    const organisation = getUserOrganisation(user);
    const [permissions, setPermissions] = useState<UserPermissions[]>();
    const [copiedCardIds, setCopiedCardIds] = useState<string[]>();
    const [originalCard, setOriginalCard] = useState<Card>();
    const [allRelatedCards, setAllRelatedCards] = useState<Card[]>([]);
    const [containingCardSets, setContainingCardSets] = useState<CardSet[]>([]);
    const [containingWorkbooks, setContainingWorkbooks] = useState<CardSet[]>(
        []
    );
    const [containingPlaybooks, setContainingPlaybooks] =
        useState<Playbook[]>();
    const [cardListModalOpen, setCardListModalOpen] = useState(false);
    const [cardSetListModalOpen, setCardSetListModalOpen] = useState(false);
    const [workbookListModalOpen, setWorkbookListModalOpen] = useState(false);
    const [selectedViewType, setSelectedViewOption] = useState(ViewType.GRID);
    const [cardRelationships, setCardRelationships] =
        useState<Relationship[]>();
    const [workbooksOfPlaybook, setWorkbooksOfPlaybook] = useState<CardSet[]>(
        []
    );
    const [worksheetsOfPlaybook, setWorksheetsOfPlaybook] =
        useState<Worksheet[]>();

    const [
        workbookRemoveConfirmationModal,
        setWorkbookRemoveConfirmationModal,
    ] = useState(false);
    const [workbookToRemove, setWorkbookToRemove] = useState<CardSet | null>(
        null
    );
    const [playbookPagesToDelete, setPlaybookPagesToDelete] =
        useState<PlaybookPage[]>();
    const [worksheetsToRemove, setWorksheetsToRemove] = useState<Worksheet[]>();
    const [playbookPages, setPlaybookPages] =
        useState<(PlaybookPage | null)[]>();
    const [playbookObject, setPlayBookObject] = useState<NewPlayBook>({
        name: '',
        description: '',
        organisation: '',
    });
    useEffect(() => {
        if ('toPlaybookPages' in card && card.toPlaybookPages) {
            setPlaybookPages(card?.toPlaybookPages?.items);
            const playbookWorksheets = card.toPlaybookPages.items.flatMap(
                (item) => item?.worksheets || []
            );
            const fetchWorksheets = async () => {
                try {
                    const worksheetResponses = await Promise.all(
                        playbookWorksheets.map((worksheetId) =>
                            worksheetId
                                ? getWorksheetById(worksheetId, organisation)
                                : null
                        )
                    );

                    const validResponses = worksheetResponses.filter(
                        (response): response is Worksheet =>
                            response !== null && response !== undefined
                    );

                    if (validResponses.length > 0) {
                        setWorksheetsOfPlaybook(validResponses);
                    }
                } catch (error) {
                    console.error('Error fetching worksheets:', error);
                }
            };

            fetchWorksheets();
        }

        const fetchWorkbookData = async () => {
            if (card.__typename === 'Playbook') {
                const workbooks = card?.workbooks ?? [];
                const organisation = getUserOrganisation(user);
                const fetchedWorkbooks = await Promise.all(
                    workbooks.map(async (id) => {
                        if (id) {
                            const response = await getWorkbook(
                                id,
                                organisation
                            );
                            return response?.data?.getCardSet || null;
                        }
                        return null;
                    })
                );
                setWorkbooksOfPlaybook(
                    fetchedWorkbooks.filter(Boolean) as CardSet[]
                );
            }
        };

        fetchWorkbookData();
    }, [card]);

    useEffect(() => {
        const fetchWorksheetsAndRelationships = async () => {
            try {
                const worksheetsArray = await Promise.all(
                    containingWorkbooks.map((workbook: CardSet) =>
                        getWorksheetsForWorkBook(workbook.id, id)
                    )
                );
                const allWorksheetsContainingThisCard = worksheetsArray.flat();

                const relationshipsArray = await Promise.all(
                    allWorksheetsContainingThisCard.map(
                        (worksheet: Worksheet) =>
                            getRelationshipsByTargetId(worksheet.id)
                    )
                );

                const allRelationships = relationshipsArray.flat();
                setContainingPlaybooks(
                    Array.from(
                        new Map(
                            allRelationships
                                ?.map(
                                    (item) =>
                                        item.toSourcePlaybookPage?.toPlaybook
                                )
                                .filter(
                                    (playbook): playbook is Playbook =>
                                        playbook !== null &&
                                        playbook !== undefined
                                )
                                .map((playbook) => [playbook.id, playbook])
                        ).values()
                    )
                );
            } catch (e) {
                console.error(
                    'Error fetching worksheets and relationships:',
                    e
                );
            }
        };

        fetchWorksheetsAndRelationships();
    }, [containingWorkbooks]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = (await client.graphql({
                    query: cardsCardSetsByCardId,
                    variables: {
                        cardId: id,
                    },
                })) as { data: CardsCardSetsByCardIdQuery };
                const fetchedContainingCardSets =
                    response?.data?.cardsCardSetsByCardId?.items.map((item) => {
                        return item?.cardSet;
                    });
                setContainingCardSets(
                    fetchedContainingCardSets?.filter(
                        (item): item is CardSet =>
                            item !== undefined && item.type === 'CS'
                    ) || []
                );
                setContainingWorkbooks(
                    fetchedContainingCardSets?.filter(
                        (item): item is CardSet =>
                            item !== undefined && item.type === 'WB'
                    ) || []
                );
            } catch (err) {
                console.log(err);
                return [];
            }
        };
        const fetchCreatedFrom = async () => {
            try {
                const [targetResponse, sourceResponse] = (await Promise.all([
                    client.graphql({
                        query: relationshipsByTargetId,
                        variables: {
                            targetId: id,
                            filter: {
                                sourceType: { eq: EntityType.Card },
                            },
                        },
                    }),
                    client.graphql({
                        query: relationshipsBySourceId,
                        variables: {
                            sourceId: id,
                            filter: {
                                targetType: { eq: EntityType.Card },
                            },
                        },
                    }),
                ])) as [
                    { data: RelationshipsByTargetIdQuery },
                    { data: RelationshipsBySourceIdQuery }
                ];
                const allRelatedCards =
                    targetResponse.data.relationshipsByTargetId?.items
                        .map((item) => item?.toSourceCard as Card)
                        .filter((card): card is Card => card !== undefined) ||
                    [];
                const newCopiedCardIds =
                    targetResponse.data.relationshipsByTargetId?.items
                        .filter(
                            (item) =>
                                item &&
                                item.type === RelationshipType.CopiedFrom
                        )
                        .map((item) => item?.toSourceCard?.id)
                        .filter((id): id is string => id !== undefined);

                setCopiedCardIds(newCopiedCardIds);

                const createdToCards =
                    sourceResponse.data.relationshipsBySourceId?.items
                        .map((item) => item?.toTargetCard as Card)
                        .filter((card): card is Card => card !== undefined) ||
                    [];
                const originalCardItem =
                    sourceResponse.data.relationshipsBySourceId?.items.find(
                        (item) =>
                            item && item.type === RelationshipType.CopiedFrom
                    );
                if (originalCardItem) {
                    setOriginalCard(originalCardItem?.toTargetCard as Card);
                }
                const combinedCards = [...allRelatedCards, ...createdToCards];
                setAllRelatedCards(combinedCards);

                const combinedRelationships = [
                    ...(targetResponse.data.relationshipsByTargetId?.items ||
                        []),
                    ...(sourceResponse.data.relationshipsBySourceId?.items ||
                        []),
                ].filter((item): item is Relationship => item !== null);
                setCardRelationships(combinedRelationships);
            } catch (err) {
                console.error('Error fetching relationships:', err);
                setAllRelatedCards([]);
            }
        };
        if (card.__typename === 'Card') {
            fetchData();
        }

        fetchCreatedFrom();
    }, [card]);

    const handleNewWorkbookRelationships = async (
        clickedWorkbooks: CardSetComponentData[],
        allWorkbooks: CardSet[],
        playbookObject: any
    ) => {
        try {
            const newWorkbookIdsToRelate = clickedWorkbooks.map(
                (item) => item.id
            );
            const existingWorkbookIdsToRelate = workbooksOfPlaybook.map(
                (item) => item.id
            );
            const data = playbookObject;

            const res = (await client.graphql({
                query: updatePlaybook,
                variables: {
                    input: {
                        name: data.name ?? '',
                        ...(data.id && {
                            id: data.id,
                        }),
                        organisation: data?.organisation || 'x',
                        workbooks: [
                            ...newWorkbookIdsToRelate,
                            ...existingWorkbookIdsToRelate,
                        ],
                    },
                },
            })) as { data: UpdatePlaybookMutation };
            if (res.data.updatePlaybook) {
                const matchingItems = clickedWorkbooks
                    .map((clickedWorkbook) =>
                        allWorkbooks.find(
                            (workbook) => workbook.id === clickedWorkbook.id
                        )
                    )
                    .filter((item): item is CardSet => item !== undefined);
                setWorkbooksOfPlaybook([
                    ...matchingItems,
                    ...workbooksOfPlaybook,
                ]);
            }

            showSnackbar &&
                showSnackbar({
                    message: 'Workbook added successfully',
                    severity: 'success',
                });
            setWorkbookListModalOpen(false);
        } catch (error) {
            console.error('Error while adding the workbook:', error);
        }
    };
    const relationshipItemsData = [
        ...(card.__typename === 'Card'
            ? [
                  {
                      title: 'Cards',
                      items: allRelatedCards?.filter((item) => item),
                      Icon: CardIcon,
                      colorFn: getCategoryHex,
                      cardComponentType: CardComponentType.CARD,
                      addAction: () => setCardListModalOpen(true),
                      actionLabel: 'Relate another card',
                      setItems: setAllRelatedCards,
                  },
                  {
                      title: 'Card sets',
                      items: containingCardSets,
                      Icon: CardSetIcon,
                      colorFn: () => {
                          if ('toCardCategory' in card && card.toCardCategory) {
                              return getCategoryHex(
                                  card.toCardCategory.id ?? ''
                              );
                          }
                          return '';
                      },
                      cardComponentType: CardComponentType.CARD_SET,
                      addAction: () => setCardSetListModalOpen(true),
                      actionLabel: 'Add card to a card set',
                      setContainingItems: setContainingCardSets,
                  },
                  {
                      title: 'Workbooks',
                      items: containingWorkbooks,
                      Icon: WorkbooksIcon,
                      cardComponentType: CardComponentType.WORKBOOK,
                  },
                  {
                      title: 'Playbooks',
                      items: containingPlaybooks,
                      Icon: WorkbooksIcon,
                      cardComponentType: CardComponentType.PLAYBOOK,
                  },
              ]
            : []),
        ...(card.__typename === 'Playbook'
            ? [
                  {
                      title: 'Workbooks',
                      items: workbooksOfPlaybook,
                      Icon: WorkbooksIcon,
                      cardComponentType: CardComponentType.WORKBOOK,
                      addAction: () => setWorkbookListModalOpen(true),
                      actionLabel: 'Relate a workbook to your playbook',
                      setContainingItems: setWorkbooksOfPlaybook,
                  },
                  {
                      title: 'Worksheets',
                      items: worksheetsOfPlaybook,
                      Icon: WorkbooksIcon,
                      cardComponentType: CardComponentType.WORKSHEET,
                      colorFn: (category: any) => {
                          return getCategoryHex(category);
                      },
                  },
              ]
            : []),
    ];

    useEffect(() => {
        const permissions = getUserPermissions(
            userProfileObject?.getUserProfile.id ?? '',
            card
        );
        setPermissions(permissions);
    }, []);

    useEffect(() => {
        if (card.__typename === 'Playbook') {
            setPlayBookObject(card as NewPlayBook);
        }
    }, [card]);
    const handleRemoveCardSet = async (cardSet: CardSet) => {
        try {
            await client.graphql({
                query: addOrRemoveCardsFromSet,
                variables: {
                    cardSetId: cardSet.id,
                    cardIds: [id],
                },
            });
            setContainingCardSets((prevCardSets: CardSet[]) =>
                prevCardSets.filter((item) => item.id !== cardSet.id)
            );
            showSnackbar({
                message: 'Card removed succesfully',
                severity: 'success',
            });
        } catch (err) {
            console.error(err);
            showSnackbar({
                message: 'Error while removing the card',
                severity: 'error',
            });
        }
    };
    const confirmPageDelete = async (id: string, organisation: string) => {
        try {
            (await client.graphql({
                query: deletePlaybookPage,
                variables: {
                    input: {
                        organisation: organisation,
                        id: id,
                    },
                },
            })) as { data: DeletePlaybookPageMutation };
        } catch (err) {
            console.log(err);
        }
    };
    const handleRemoveWorkbook = async (cardSet: CardSet) => {
        setWorkbookRemoveConfirmationModal(true);
        setWorkbookToRemove(cardSet);
        const filteredWorksheets = worksheetsOfPlaybook?.filter(
            (item) => item?.parentId === cardSet?.id
        );
        setWorksheetsToRemove(filteredWorksheets);
        const filteredWorksheetIds = new Set(
            filteredWorksheets?.map((item) => item.id)
        );
        const filteredPages = playbookPages?.filter((page) =>
            page?.worksheets?.some((wsId) => {
                if (wsId == null) return false;
                return filteredWorksheetIds.has(wsId);
            })
        );
        const nonNullFilteredPages = (filteredPages ?? []).filter(
            (page): page is PlaybookPage => page !== null
        );

        setPlaybookPagesToDelete(nonNullFilteredPages);
    };
    const confirmRemoveWorkbook = async () => {
        if (card.__typename !== 'Playbook' || !workbookToRemove) return;
        try {
            const data = playbookObject as Playbook;
            const existingWorkbookIds = card?.workbooks;
            const res = (await client.graphql({
                query: updatePlaybook,
                variables: {
                    input: {
                        name: data.name ?? '',
                        id: data.id,
                        organisation: data?.organisation || 'x',
                        workbooks: existingWorkbookIds?.filter(
                            (item) => item !== workbookToRemove.id
                        ),
                    },
                },
            })) as { data: UpdatePlaybookMutation };
            setWorkbooksOfPlaybook((prevCardSets: CardSet[]) =>
                prevCardSets.filter((item) => item.id !== workbookToRemove.id)
            );
            if (
                Array.isArray(playbookPagesToDelete) &&
                playbookPagesToDelete.length > 0
            ) {
                for (const page of playbookPagesToDelete) {
                    await confirmPageDelete(page.id, page.organisation);
                }
                setPlaybookPagesToDelete([]);
                setWorkbookToRemove(null);
                setWorksheetsOfPlaybook((prevWorksheets) =>
                    prevWorksheets?.filter(
                        (worksheet) =>
                            !worksheetsToRemove?.some(
                                (item) => item.id === worksheet.id
                            )
                    )
                );
            }
            setWorkbookRemoveConfirmationModal(false);
            showSnackbar({
                message: 'Workbook removed succesfully',
                severity: 'success',
            });
        } catch (err) {
            console.error(err);
            showSnackbar({
                message: 'Error while removing the workbook',
                severity: 'error',
            });
        }
    };
    const handleRemoveCard = (item: Card) => {
        const relationshipToDeleteFromSource = cardRelationships?.find(
            (r) => r?.toSourceCard?.id === item.id
        );
        const relationshipToDeleteFromTarget = cardRelationships?.find(
            (r) => r?.toTargetCard?.id === item.id
        );
        if (
            relationshipToDeleteFromSource?.id ||
            relationshipToDeleteFromTarget?.id
        ) {
            deleteRelationship(
                relationshipToDeleteFromSource?.id ||
                    relationshipToDeleteFromTarget?.id
            );
            setAllRelatedCards((prevCards) =>
                prevCards.filter((card) => card.id !== item.id)
            );
            showSnackbar({
                message: 'Card removed succesfully',
                severity: 'success',
            });
        } else {
            showSnackbar({
                message: 'Error while removing the card',
                severity: 'error',
            });
        }
    };
    return (
        <Box sx={{ height: worksheetCard ? 'calc(100vh - 570px)' : '100%' }}>
            <Snackbar
                open={isOpen}
                autoHideDuration={duration}
                onClose={closeSnackbar}
                sx={{ zIndex: 1600, mb: 8 }}
            >
                <Alert
                    variant="filled"
                    severity={severity}
                    sx={{ width: '100%' }}
                    onClose={closeSnackbar}
                >
                    {message}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mt: -4,
                    mr: -5,
                }}
            >
                <Tooltip title="Grid view">
                    <IconButton
                        aria-label="Grid View"
                        onClick={() => setSelectedViewOption(ViewType.GRID)}
                    >
                        <img
                            src={
                                selectedViewType === ViewType.GRID
                                    ? TileViewIconActive
                                    : TileViewIcon
                            }
                            style={iconStyles}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title="List view">
                    <IconButton
                        aria-label="List View"
                        onClick={() => setSelectedViewOption(ViewType.LIST)}
                        sx={{ ml: -2 }}
                    >
                        <img
                            src={
                                selectedViewType === ViewType.LIST
                                    ? ListViewIconActive
                                    : ListViewIcon
                            }
                            style={iconStyles}
                        />
                    </IconButton>
                </Tooltip>
            </Box>
            {selectedViewType === ViewType.GRID
                ? relationshipItemsData.map((data, index) => (
                      <RelationshipGridView
                          key={index}
                          title={data.title}
                          items={data.items}
                          Icon={data.Icon}
                          colorFn={data.colorFn}
                          cardComponentType={data.cardComponentType}
                          addAction={data.addAction}
                          actionLabel={data.actionLabel}
                          copiedCardIds={copiedCardIds}
                          originalCard={originalCard}
                          permissions={permissions}
                          cardTypename={card.__typename}
                          card={card}
                          handleRemoveCardSet={handleRemoveCardSet}
                          handleRemoveWorkbook={handleRemoveWorkbook}
                          handleRemoveCard={handleRemoveCard}
                      />
                  ))
                : relationshipItemsData.map((data, index) => (
                      <RelationshipListView
                          key={index}
                          title={data.title}
                          items={data.items}
                          Icon={data.Icon}
                          colorFn={data.colorFn}
                          cardComponentType={data.cardComponentType}
                          cardId={id}
                          addAction={data.addAction}
                          copiedCardIds={copiedCardIds}
                          actionLabel={data.actionLabel}
                          card={card}
                          workbooksOfPlaybook={workbooksOfPlaybook}
                          permissions={permissions}
                          handleRemoveCardSet={handleRemoveCardSet}
                          handleRemoveWorkbook={handleRemoveWorkbook}
                          handleRemoveCard={handleRemoveCard}
                      />
                  ))}
            <StyledModal
                key="card-list-modal"
                open={cardListModalOpen}
                onClose={() => {
                    setCardListModalOpen(false);
                }}
                sx={{ zIndex: 1401 }}
                disablePortal={true}
            >
                <ModalContainer
                    sx={{
                        maxWidth: '48rem',
                        height: '650px',
                        overflow: 'auto',
                        p: 2,
                    }}
                >
                    <AddExistingCards
                        handleModalClose={() => setCardListModalOpen(false)}
                        targetCardId={card.id}
                        relatedCards={allRelatedCards}
                        setAllRelatedCards={setAllRelatedCards}
                        handleNewRelationshipModalClose={() =>
                            setCardListModalOpen(false)
                        }
                        relationshipForm={RelationshipFormType.CARD}
                        setCardRelationships={setCardRelationships}
                        showSnackbar={showSnackbar}
                    />
                </ModalContainer>
            </StyledModal>
            <StyledModal
                key="cardset-list-modal"
                open={cardSetListModalOpen}
                onClose={() => {
                    setCardSetListModalOpen(false);
                }}
                sx={{ zIndex: 1401 }}
                disablePortal={true}
            >
                <ModalContainer
                    sx={{
                        maxWidth: '48rem',
                        height: '650px',
                        overflow: 'auto',
                        p: 2,
                    }}
                >
                    <AddExistingCards
                        handleModalClose={() => setCardSetListModalOpen(false)}
                        targetCardId={card.id}
                        relatedCards={allRelatedCards}
                        containingCardSets={containingCardSets}
                        setContainingCardSets={setContainingCardSets}
                        handleNewRelationshipModalClose={() =>
                            setCardSetListModalOpen(false)
                        }
                        relationshipForm={RelationshipFormType.CARD_SET}
                        showSnackbar={showSnackbar}
                    />
                </ModalContainer>
            </StyledModal>
            <StyledModal
                key="workbook-list-modal"
                open={workbookListModalOpen}
                onClose={() => {
                    setWorkbookListModalOpen(false);
                }}
                sx={{ zIndex: 1401 }}
                disablePortal={true}
            >
                <ModalContainer
                    sx={{
                        maxWidth: '48rem',
                        height: '650px',
                        overflow: 'auto',
                        p: 2,
                    }}
                >
                    <CreatePlaybook
                        type="Relationship"
                        handleNewWorkbookRelationships={
                            handleNewWorkbookRelationships
                        }
                        containingWorkbooks={workbooksOfPlaybook}
                        handleClose={() => setWorkbookListModalOpen(false)}
                        playbook={card as Playbook}
                    />
                </ModalContainer>
            </StyledModal>

            <StyledModal
                key="workbook-list-modal"
                open={workbookRemoveConfirmationModal}
                onClose={() => {
                    setWorkbookRemoveConfirmationModal(false);
                }}
                sx={{ zIndex: 1401 }}
            >
                <ModalContainer sx={{ maxWidth: '40rem' }}>
                    <RemoveWorkbook
                        toggleDeleteWizard={setWorkbookRemoveConfirmationModal}
                        setCardToDelete={setWorkbookToRemove}
                        confirmDelete={confirmRemoveWorkbook}
                        cancel={() => setWorkbookRemoveConfirmationModal(false)}
                        playbookPages={playbookPagesToDelete}
                    />
                </ModalContainer>
            </StyledModal>
        </Box>
    );
};
export default CardRelationships;
