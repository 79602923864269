import { Box, Tooltip, Typography, useTheme, IconButton } from '@mui/material';
import { truncateText } from '../../../helpers/utils';
import {
    Category,
    categoryObject,
    getCategoryHexDark,
    getCategoryHexText,
} from '../../../helpers/category';
import { getMiniCardHeaderText } from '../../../helpers/card';
import { PaperClip } from '../CardBase';
import AddIcon from '@mui/icons-material/Add';
import { CardComponentType } from '../cardTypes';
import { ReactComponent as MenuIcon } from '../../../assets/icons/Menu.svg';
import CardMenu from '../CardMenu';
import { Card, CardSet, Playbook } from '../../../API';
import { generateClient } from 'aws-amplify/api';
import { UserPermissions } from '../../../globals';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts';
import { NewPlayBook } from '../../forms/CreatePlaybook';
import { getWorksheetBackgroundColor } from '../../../helpers/worksheets';

export interface MenuData {
    anchor: HTMLElement | null;
    item: any;
}
interface RelationshipGridViewProps {
    title: string;
    items: any[] | undefined;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    colorFn?: (id: string) => string;
    cardComponentType: CardComponentType;
    addAction?: () => void;
    actionLabel?: string;
    copiedCardIds: string[] | undefined;
    originalCard: Card | undefined;
    permissions: UserPermissions[] | undefined;

    cardTypename: string;
    card: Playbook | Card | CardSet;
    handleRemoveCardSet: (cardSet: CardSet) => Promise<void>;
    handleRemoveWorkbook: (cardSet: CardSet) => Promise<void>;
    handleRemoveCard: (item: Card) => void;
}

const RelationshipGridView = ({
    title,
    items,
    Icon,
    colorFn,
    cardComponentType,
    addAction,
    actionLabel,
    copiedCardIds,
    originalCard,
    permissions,
    cardTypename,
    card,
    handleRemoveCardSet,
    handleRemoveWorkbook,
    handleRemoveCard,
}: RelationshipGridViewProps) => {
    const theme = useTheme();
    const { cardTypeObject } = useContext(AppContext);
    const greyColor = theme.palette.grey[500];
    const [menuData, setMenuData] = useState<MenuData>({
        anchor: null,
        item: null,
    });
    const [playbookObject, setPlayBookObject] = useState<NewPlayBook>({
        name: '',
        description: '',
        organisation: '',
    });
    const setMenu = (
        e: React.MouseEvent<HTMLElement> | null,
        clickedItem?: Card | CardSet | Playbook
    ) => {
        if (e) {
            e.stopPropagation();
            setMenuData({
                anchor: e.currentTarget,
                item: clickedItem || null,
            });
        } else {
            setMenuData({ anchor: null, item: null });
        }
    };

    const client = generateClient();
    useEffect(() => {
        if (card.__typename === 'Playbook') {
            setPlayBookObject(card as NewPlayBook);
        }
    }, [card]);

    return (
        <Box sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {addAction && (
                        <Tooltip placement="top" title={actionLabel}>
                            <IconButton color="primary" onClick={addAction}>
                                <AddIcon
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Box
                        sx={{
                            background: theme.palette.background.paper,
                            height: '30px',
                            width: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '6px',
                        }}
                    >
                        <Typography variant="body1">
                            {items?.length || '0'}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    py: 0.5,
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative',
                    zIndex: 1200,
                    maxHeight:
                        cardComponentType === CardComponentType.WORKSHEET
                            ? '500px'
                            : '220px',
                    overflowY: 'auto',
                }}
            >
                {items && items.length > 0 ? (
                    items.map((item) => {
                        const { data, textColor } = getWorksheetBackgroundColor(
                            item,
                            cardTypeObject
                        );
                        const isWorkbook =
                            item.__typename === 'CardSet' && item.type === 'WB';
                        const isCardSet =
                            item.__typename === 'CardSet' && item.type === 'CS';
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor:
                                        cardComponentType ===
                                            CardComponentType.WORKSHEET &&
                                        colorFn
                                            ? colorFn(data.category)
                                            : cardComponentType ===
                                              CardComponentType.PLAYBOOK
                                            ? categoryObject[Category.PLAYBOOK]
                                                  .color
                                            : isWorkbook
                                            ? categoryObject[Category.WORKBOOK]
                                                  .color
                                            : !item?.toCardCategory?.id
                                            ? '#DB9C56'
                                            : colorFn
                                            ? colorFn(
                                                  item.toCardCategory?.id ?? ''
                                              )
                                            : greyColor,

                                    m: 1,
                                    height: '190px',
                                    width: '140px',
                                    borderRadius: '10px',
                                    boxShadow: theme.shadows[1],
                                    transition: 'box-shadow 0.3s',
                                    '&:hover': {
                                        boxShadow: theme.shadows[4],
                                        cursor: 'pointer',
                                    },
                                    color:
                                        cardComponentType ===
                                            CardComponentType.PLAYBOOK ||
                                        cardComponentType ===
                                            CardComponentType.WORKBOOK
                                            ? '#fff'
                                            : cardComponentType ===
                                              CardComponentType.WORKSHEET
                                            ? textColor
                                            : getCategoryHexText(
                                                  item.toCardCategory?.id ?? ''
                                              ),
                                    position: 'relative',
                                }}
                            >
                                {isCardSet && (
                                    <>
                                        <Box
                                            sx={{
                                                backgroundColor:
                                                    cardComponentType ===
                                                    CardComponentType.PLAYBOOK
                                                        ? categoryObject[
                                                              Category.PLAYBOOK
                                                          ].dark
                                                        : !item?.toCardCategory
                                                              ?.id
                                                        ? '#DB9C56'
                                                        : colorFn
                                                        ? colorFn(
                                                              item
                                                                  .toCardCategory
                                                                  ?.id ?? ''
                                                          )
                                                        : greyColor,
                                                height: '190px',
                                                width: '140px',
                                                borderRadius: '10px',
                                                boxShadow: theme.shadows[1],
                                                color: getCategoryHexText(
                                                    item.toCardCategory?.id ??
                                                        ''
                                                ),
                                                position: 'absolute',
                                                zIndex: -1,
                                                left: 4,
                                                top: 4,
                                            }}
                                        />
                                        <PaperClip mediumCard={true} />
                                    </>
                                )}
                                {isWorkbook && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '5px',
                                            left: '5px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '4px',
                                            pointerEvents: 'none',
                                        }}
                                        aria-hidden="true"
                                    >
                                        {Array.from({ length: 19 }).map(
                                            (_, idx) => (
                                                <Box
                                                    key={idx}
                                                    sx={{
                                                        width: '6px',
                                                        height: '6px',
                                                        borderRadius: '50%',
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                />
                                            )
                                        )}
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '35px',
                                        backgroundColor:
                                            cardComponentType ===
                                            CardComponentType.WORKSHEET
                                                ? getCategoryHexDark(
                                                      data.category
                                                  )
                                                : cardComponentType ===
                                                  CardComponentType.PLAYBOOK
                                                ? categoryObject[
                                                      Category.PLAYBOOK
                                                  ].dark
                                                : isWorkbook
                                                ? categoryObject[
                                                      Category.WORKBOOK
                                                  ].dark
                                                : !item?.toCardCategory?.id
                                                ? '#AD6A1F'
                                                : colorFn
                                                ? getCategoryHexDark(
                                                      item.toCardCategory?.id ??
                                                          ''
                                                  )
                                                : greyColor,
                                        borderRadius: `10px 10px 0 0`,
                                        p: 1,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        {' '}
                                        {getMiniCardHeaderText(item)}
                                    </Typography>
                                </Box>
                                <Tooltip title={item.name}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            width: '80%',
                                            fontWeight: 'bolder',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {truncateText(item.name, 45)}
                                    </Typography>
                                </Tooltip>
                                {!copiedCardIds?.includes(item.id) &&
                                    originalCard?.id !== item.id &&
                                    (cardComponentType ===
                                        CardComponentType.CARD ||
                                        cardComponentType ===
                                            CardComponentType.CARD_SET ||
                                        (cardComponentType ===
                                            CardComponentType.WORKBOOK &&
                                            cardTypename === 'Playbook')) && (
                                        <Tooltip
                                            title="Actions"
                                            placement="top"
                                        >
                                            <IconButton
                                                aria-label=""
                                                id="menu-button"
                                                onClick={(e) =>
                                                    setMenu(e, item)
                                                }
                                                sx={{
                                                    ml: 0,
                                                    width: '30px',
                                                    height: '30px',
                                                    padding: 0,
                                                    svg: {
                                                        height: '30px',
                                                        width: '30px',
                                                    },
                                                    zIndex: 1099,
                                                    color: 'grey',
                                                    position: 'absolute',
                                                    right: -10,
                                                    top: -10,
                                                    background: '#fff',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            theme.palette
                                                                .background
                                                                .paper,
                                                        color: 'grey',
                                                    },
                                                }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                {menuData.anchor && (
                                    <CardMenu
                                        permissions={permissions ?? []}
                                        anchor={menuData.anchor}
                                        data={{
                                            organisation:
                                                menuData?.item[0]
                                                    ?.organisation || '',
                                            id: menuData.item?.id,
                                        }}
                                        menuItems={[
                                            {
                                                text: isWorkbook
                                                    ? `Remove workbook`
                                                    : isCardSet
                                                    ? 'Remove card from card set'
                                                    : 'Remove card',
                                                action: () => {
                                                    if (isWorkbook) {
                                                        handleRemoveWorkbook(
                                                            menuData.item
                                                        );
                                                    } else if (isCardSet) {
                                                        handleRemoveCardSet(
                                                            menuData.item
                                                        );
                                                    } else {
                                                        handleRemoveCard(
                                                            menuData.item
                                                        );
                                                    }
                                                },
                                            },
                                        ]}
                                        setMenu={setMenu}
                                    />
                                )}
                            </Box>
                        );
                    })
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        No items
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default RelationshipGridView;
