import {
    Box,
    Button,
    Grid,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import TextField from '../../forms/input-fields/TextField';
import {
    AttributeType,
    CardSet,
    UpdateCardSetMutation,
    Worksheet,
} from '../../../API';
import { ReactComponent as PencilIcon } from '../../../assets/icons/Pencil.svg';
import ActivitiesSelected from '../../../assets/images/bigNumbers/Activities.png';
import ActivitiesUnselected from '../../../assets/images/bigNumbers/Activities-unselected.png';
import CommentsSelected from '../../../assets/images/bigNumbers/Comments.png';
import CommentsUnselected from '../../../assets/images/bigNumbers/Comments-unselected.png';
import InformationSelected from '../../../assets/images/bigNumbers/Information.png';
import InformationUnselected from '../../../assets/images/bigNumbers/Information-unselected.png';
import MembersSelected from '../../../assets/images/bigNumbers/Members.png';
import MembersUnselected from '../../../assets/images/bigNumbers/Members-unselected.png';
import WorksheetsSelected from '../../../assets/images/bigNumbers/Worksheets.png';
import WorksheetsUnselected from '../../../assets/images/bigNumbers/Worksheets-unslected.png';
import { AuthSession, UserPermissions } from '../../../globals';
import { getCardSetPermissions } from '../../../helpers/permissions';
import { GraphQLQuery, generateClient } from 'aws-amplify/api';
import { updateCardSet } from '../../../graphql/mutations';
import UserAvatar from '../../profile/UserAvatar';
import { NumbersCount, SelectionType } from '../../../pages/workbooks/[pid]';
import { truncateText } from '../../../helpers/utils';

interface WorkbookTopProps {
    workbook: CardSet;
    user: AuthSession;
    worksheets: Worksheet[];
    selectionType: SelectionType;
    setSelectionType: React.Dispatch<
        React.SetStateAction<SelectionType | null>
    >;
    counts: NumbersCount;
}
interface TopSelection {
    text: string;
    selectionType: SelectionType;
    image: string;
}

const WorkbookTop = ({
    workbook,
    user,
    worksheets,
    selectionType,
    setSelectionType,
    counts,
}: WorkbookTopProps) => {
    const [edit, setEdit] = useState(false);
    const [description, setDescription] = useState('');
    const [permissions, setPermissions] = useState<UserPermissions[]>([]);
    const [isExpanded, setIsExpanded] = useState(false);

    const client = generateClient();

    const theme = useTheme();

    const rightBoxes: TopSelection[] = [
        {
            text: `${counts?.worksheets ?? ''} worksheet${
                counts?.worksheets !== 1 ? 's' : ''
            }`,
            selectionType: SelectionType.WORKSHEETS,
            image:
                selectionType === SelectionType.WORKSHEETS
                    ? WorksheetsSelected
                    : WorksheetsUnselected,
        },
        {
            text: `${counts?.comments ?? ''} comment${
                workbook?.counts?.comments !== 1 ? 's' : ''
            }`,
            selectionType: SelectionType.COMMENTS,
            image:
                selectionType === SelectionType.COMMENTS
                    ? CommentsSelected
                    : CommentsUnselected,
        },
        {
            text: `${counts?.users ?? ''} member${
                counts?.users !== 1 ? 's' : ''
            }`,
            selectionType: SelectionType.MEMBERS,
            image:
                selectionType === SelectionType.MEMBERS
                    ? MembersSelected
                    : MembersUnselected,
        },
        {
            text: `Activities`,
            selectionType: SelectionType.ACTIVITIES,
            image:
                selectionType === SelectionType.ACTIVITIES
                    ? ActivitiesSelected
                    : ActivitiesUnselected,
        },
        {
            text: `Workbook info`,
            selectionType: SelectionType.INFO,
            image:
                selectionType === SelectionType.INFO
                    ? InformationSelected
                    : InformationUnselected,
        },
    ];

    const saveDescription = async () => {
        const data = {
            id: workbook?.id,
            name: workbook?.name,
            description: description,
            organisation: workbook?.organisation,
            type: workbook?.type,
            createdAt: workbook?.createdAt,
        };

        try {
            await client.graphql<GraphQLQuery<UpdateCardSetMutation>>({
                query: updateCardSet,
                variables: {
                    input: data,
                },
            });
        } catch (e) {}
        setEdit(false);
    };

    useEffect(() => {
        if (user) {
            if (workbook) {
                setDescription(workbook.description as string);
                const userPermissions = getCardSetPermissions(
                    user?.tokens?.idToken?.payload.sub ?? '',
                    workbook
                );

                setPermissions(userPermissions);
            }
        }
    }, [workbook, user]);

    return (
        <Grid container sx={{ pb: '10px' }}>
            <Grid
                item
                xs={12}
                md={7}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pr: '20px',
                }}
            >
                {edit ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '150px',
                            pb: '10px',
                            mt: -4.3,
                        }}
                    >
                        <TextField
                            type={AttributeType.MultilineText}
                            name="edit-description"
                            id="edit-description"
                            multiline
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Edit description..."
                            InputProps={{
                                style: {
                                    height: '100%',
                                    overflowY: 'auto',
                                    padding: '0',
                                    fontSize: '0.9rem',
                                },
                                disableUnderline: true,
                            }}
                            sx={{
                                height: '100%',
                                '& .MuiOutlinedInput-root': {
                                    margin: '0px',
                                    alignItems: 'flex-start',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: '100%',
                            '&:hover .edit-icon': {
                                visibility: 'visible',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                height: '150px',
                                overflowY: isExpanded ? 'auto' : 'hidden',
                                pr: isExpanded ? 1 : 0,
                                position: 'relative',
                                width: '100%',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    textAlign: 'justify',
                                    lineHeight: '1.5',
                                    whiteSpace: 'pre-wrap',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitLineClamp: isExpanded ? 'unset' : '6',
                                    WebkitBoxOrient: 'vertical',
                                    mt: description?.length === 0 ? '-22px' : 0,
                                }}
                            >
                                {isExpanded
                                    ? description
                                    : `${truncateText(description, 520)} `} 
                                {description?.length === 0 ? (
                                    <span
                                        onClick={() => setEdit(true)}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            paddingLeft: '10px',
                                            paddingTop: '10px',
                                            border: 'solid 1px',
                                            borderColor: theme.palette.divider,
                                            borderRadius: '12px',
                                            width: '100%',
                                            height: '90px',
                                            display: 'inline-block',
                                        }}
                                    >
                                        Add workbook description
                                    </span>
                                ) : description?.length > 520 ? (
                                    <span
                                        onClick={() =>
                                            setIsExpanded(!isExpanded)
                                        }
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        {isExpanded ? 'show less' : 'read more'}
                                    </span>
                                ) : null}
                            </Typography>
                            {permissions.includes(UserPermissions.EDIT) &&
                                !isExpanded && (
                                    <IconButton
                                        data-testid="edit-button"
                                        onClick={() => setEdit(true)}
                                        className="edit-icon"
                                        sx={{
                                            visibility: 'hidden',
                                            position: 'absolute',
                                            bottom: 20,
                                            right: 0,
                                            cursor: 'pointer',
                                            width: '34px',
                                            height: '34px',
                                            zIndex: 10,
                                        }}
                                    >
                                        <PencilIcon
                                            style={{
                                                width: '20px',
                                            }}
                                            color={theme.palette.primary.main}
                                        />
                                    </IconButton>
                                )}
                        </Box>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {edit ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '8px',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setDescription(
                                        workbook?.description as string
                                    );
                                    setEdit(false);
                                }}
                                variant="outlined"
                                size="small"
                                sx={{
                                    borderRadius: 2,
                                    px: 2,
                                    minWidth: '70px',
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={saveDescription}
                                variant="contained"
                                size="small"
                                sx={{
                                    borderRadius: 2,
                                    px: 2,
                                    minWidth: '70px',
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    ) : !edit && !isExpanded ? (
                        <Box
                            sx={{
                                mt: -3,
                            }}
                        >
                            <UserAvatar userId={workbook?.owner} />
                        </Box>
                    ) : (
                        <Box sx={{ height: '6px' }} />
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: 'flex' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    {rightBoxes.map((rightBox, index) => {
                        return (
                            <Box
                                data-testid={`rightbox-${index}`}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    setSelectionType(
                                        rightBox.selectionType !== selectionType
                                            ? rightBox.selectionType
                                            : null
                                    )
                                }
                            >
                                <Box
                                    component="img"
                                    src={rightBox.image}
                                    sx={{
                                        width: '100px',
                                        height: '100px',
                                        transform:
                                            selectionType ===
                                            rightBox.selectionType
                                                ? 'scale(0.90)'
                                                : 'inherit',
                                        borderRadius: '12px',
                                        transition:
                                            'transform 0.5s, border-radius 0.5s',
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '1.2rem',
                                    }}
                                >
                                    {rightBox.text}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Grid>
        </Grid>
    );
};

export default WorkbookTop;
