import { useEffect, useContext, useState } from 'react';
import { Typography } from '@mui/material';

import { generateClient } from 'aws-amplify/api';
import {
    AttributeType,
    CreatePlaybookPageMutation,
    PlaybookDataResponse,
    PlaybookPageDataResponse,
    PlaybookPageType,
} from '../../../API';
import { updatePlaybookPage } from '../../../graphql/mutations';
import Form from '../../forms/Form';
import FieldProps, {
    FieldType,
} from '../../forms/input-fields/inputFieldTypes';
import { PlaybookContext } from '../context';

interface PlaybookFormProps {
    updateValue: (name: string, value: string) => void;
    onUpdate?: (id: string, organisation: string) => void;
    playbook: PlaybookDataResponse;
    page: PlaybookPageDataResponse;
}

const PlaybookTitleTypeForm = ({
    playbook,
    page,
    updateValue,
    onUpdate,
}: PlaybookFormProps) => {
    const { handleClose } = useContext(PlaybookContext);

    const client = generateClient();

    const [pageObject, setPageObject] = useState<{
        title?: string | null;
        commentary?: string | null;
        titleconfig?: string | null;
    } | null>(null);

    const basePlaybookForm: Array<FieldProps | FieldProps[]> = [
        {
            name: 'title',
            label: 'Title',
            type: AttributeType.Text,
            required: true,
        },
        {
            name: 'commentary',
            label: 'Commentary',
            type: AttributeType.MultilineText,
        },
        {
            name: 'titleconfig',
            label: 'Title config',
            type: FieldType.TITLECONFIG,
        },
    ];

    useEffect(() => {
        const titleconfig = page?.options
            ? JSON.parse(page.options).titleconfig
            : JSON.stringify({
                  content: 'middle',
                  logo: 'top',
                  color: { text: '#ffffff', background: '#414B5A' },
              });
        setPageObject({
            ...pageObject,
            title: page.title,
            commentary: page.commentary,
            titleconfig: titleconfig,
        });
    }, [page]);

    const onChange = (name: string, value: string) => {
        setPageObject({
            ...pageObject,
            [name]: value,
        });
    };
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const query = updatePlaybookPage;

        try {
            const result = (await client.graphql({
                query,
                variables: {
                    input: {
                        playbookId: playbook.id,
                        playbookPageType: PlaybookPageType.Title,
                        organisation: playbook.organisation,
                        id: page?.id ?? '',
                        commentary: pageObject?.commentary,
                        options: pageObject?.titleconfig
                            ? JSON.stringify({
                                  titleconfig: pageObject?.titleconfig,
                              })
                            : null,
                        title: pageObject?.title,
                    },
                },
            })) as { data: CreatePlaybookPageMutation };

            if (result) {
                onUpdate && onUpdate(page.id, playbook.organisation);
            }

            !!handleClose && handleClose();
        } catch (err) {
            console.log(err);
            !!handleClose && handleClose();
        }
    };

    return (
        <>
            <Typography variant="h1" sx={{ mb: 4 }}>
                Title Page
            </Typography>
            <Form
                formConfiguration={basePlaybookForm}
                handleCancel={handleClose}
                handleSubmit={onSubmit}
                disableSubmit={false}
                onChange={updateValue}
                {...(pageObject && {
                    values: pageObject,
                })}
                titlePage={true}
            />
        </>
    );
};

export default PlaybookTitleTypeForm;
