import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { createPieChartData } from '../../helpers/charts';
import { PlaybookPageFormType } from '../playbooks/playbookTypes';
import { getPieChartOptions } from './PieChartOptions ';
import { EditablePageValues } from '../playbooks/EditablePlaybookPage';
ChartJS.register(ArcElement, Tooltip, Legend);

interface PieChartProps {
    labels: string[];
    data: number[];
    defaultLabel?: string;
    colors?: string[];
    title: string;
    playbookPageForm?: EditablePageValues;
    pptView?: boolean;
}

const PieChart = ({
    labels,
    data,
    defaultLabel,
    colors,
    title,
    playbookPageForm,
    pptView,
}: PieChartProps) => {
    const chartData = createPieChartData(labels, data, defaultLabel, colors);
    const options = getPieChartOptions(title, chartData, playbookPageForm);

    return <Pie data={chartData} options={options} />;
};

export default PieChart;
