import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { WorksheetFilterDateOptions } from '../../cardTypes';
import { SecondaryWorksheetFilterOptions } from '..';
import { dateFromISO, dateStringFromISO } from '../../../../helpers/utils';
import { DateTime } from 'luxon';

interface DateFilterProps {
    selectedSecondaryFilter: SecondaryWorksheetFilterOptions;
    setSelectedSecondaryFilter: React.Dispatch<
        React.SetStateAction<SecondaryWorksheetFilterOptions>
    >;
}

const dateOptions = Object.values(WorksheetFilterDateOptions);

const DateFilter = ({
    selectedSecondaryFilter,
    setSelectedSecondaryFilter,
}: DateFilterProps) => {
    return (
        <Box
            sx={{
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Typography variant="body1" sx={{ ml: 2, fontWeight: 'bold' }}>
                Select a date range
            </Typography>
            <Box sx={{ borderRight: '1px solid #f0f2f5', height: '100%' }}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="date-filter-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedSecondaryFilter.createdDate.value}
                        onChange={(event) =>
                            setSelectedSecondaryFilter(
                                (selectedSecondaryFilter) => {
                                    const filter = {
                                        ...selectedSecondaryFilter,
                                    };
                                    filter.createdDate.value = event.target
                                        .value as WorksheetFilterDateOptions;
                                    return filter;
                                }
                            )
                        }
                    >
                        {dateOptions.map((key, value) => {
                            return (
                                <Box
                                    key={value}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 1.8,
                                        cursor: 'pointer',
                                    }}
                                >
                                    <FormControlLabel
                                        value={key}
                                        control={<Radio />}
                                        label={key}
                                    />
                                </Box>
                            );
                        })}
                    </RadioGroup>
                    {selectedSecondaryFilter?.createdDate?.value ===
                        WorksheetFilterDateOptions.CUSTOM_DATE && (
                        <Box sx={{ display: 'flex', padding: '20px' }}>
                            <DatePicker
                                label="Start date"
                                slotProps={{
                                    popper: {
                                        sx: { zIndex: 1600 },
                                    },
                                }}
                                sx={{ marginRight: '10px' }}
                                value={dateFromISO(
                                    selectedSecondaryFilter.createdDate
                                        .start as string
                                )}
                                onChange={(date) =>
                                    setSelectedSecondaryFilter(
                                        (selectedSecondaryFilter) => {
                                            const filter = {
                                                ...selectedSecondaryFilter,
                                            };
                                            filter.createdDate.start =
                                                date?.toString() as string;

                                            return filter;
                                        }
                                    )
                                }
                            />
                            <DatePicker
                                label="End date"
                                slotProps={{
                                    popper: {
                                        sx: { zIndex: 1600 },
                                    },
                                }}
                                sx={{
                                    marginLeft: '10px',
                                }}
                                value={dateFromISO(
                                    selectedSecondaryFilter.createdDate
                                        .end as string
                                )}
                                onChange={(date) =>
                                    setSelectedSecondaryFilter(
                                        (selectedSecondaryFilter) => {
                                            const filter = {
                                                ...selectedSecondaryFilter,
                                            };
                                            filter.createdDate.end =
                                                date?.toString() as string;
                                            return filter;
                                        }
                                    )
                                }
                            />
                        </Box>
                    )}
                </FormControl>
            </Box>
        </Box>
    );
};

export default DateFilter;
