import { useContext, useEffect, useRef, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Dialog,
    IconButton,
    Menu,
    MenuItem,
    styled,
    SxProps,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { ReactComponent as MinimizeIcon } from '../../assets/icons/Minimize.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icons/Expand.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/Help.svg';
import AiIcon from '@mui/icons-material/AutoAwesome';
import SwotGrid from '../playbooks/analysis/SwotGrid';
import { PageConfig } from './WorkbookDrawer';
import {
    AttributeType,
    Card,
    CreatePlaybookPageMutation,
    DeleteWorksheetExtMutation,
    PlaybookPageType,
} from '../../API';
import Form from '../forms/Form';
import FieldProps from '../forms/input-fields/inputFieldTypes';
import SavePlaybookPage from './SavePlaybookPage';
import { CardContext } from '../cards/context';

import { generateClient } from 'aws-amplify/api';
import {
    createPlaybookPage,
    deleteWorksheetExt,
    updatePlaybookPage,
} from '../../graphql/mutations';
import { Link, useParams } from 'react-router-dom';
import { getUserOrganisation } from '../../helpers/auth';
import CardHelp from '../cards/CardHelp';
import { worksheets } from '../forms/worksheets';
import { UserPermissions, WorksheetSize } from '../../globals';
import BubblePage from '../playbooks/analysis/BubblePage';
import Retrospective from '../playbooks/analysis/Retrospective';
import {
    ChartType,
    Field,
    getChartSettings,
    getChartType,
    getRadarOptions,
} from '../../helpers/worksheets';
import CreateCard from '../forms/CreateCard';
import { StyledModal } from '../Modal';
import ModalContainer from '../ModalContainer';
import RiskAnalysis from '../playbooks/analysis/RiskAnalysis';
import RadarPage from '../playbooks/analysis/RadarPage';
import { RadarOption } from '../forms/input-fields/ChartSettings';
import { AppContext } from '../contexts';
import PestleAnalysis from '../playbooks/analysis/PestleAnalysis';
import PortersFiveForces from '../playbooks/analysis/PortersFivesForces';
import CreateCardSet from '../forms/CreateCardSet';
import { CardToDelete } from '../../pages/cards';
import AiAnalysisModal from './AiAnalysisModal';
import { colorChange, spin } from '../forms/input-fields/TextField';

export const RadioCheckboxBackrounds: SxProps = {
    backgroundColor: 'background.paper',
    px: 2,
    borderRadius: 2,
};

export const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                width: '30px',
                height: '37px',
                '.Mui-expanded & > .collapsIconWrapper': {
                    display: 'none',
                },
                '.expandIconWrapper': {
                    display: 'none',
                },
                '.Mui-expanded & > .expandIconWrapper': {
                    display: 'block',
                },
            }}
        >
            <Box
                className="expandIconWrapper"
                sx={{ marginLeft: '6px', marginTop: '6px' }}
            >
                <MinimizeIcon color="#ccc" height="30px" />
            </Box>
            <Box className="collapsIconWrapper">
                <ExpandIcon color="#ccc" height="30px" />
            </Box>
        </Box>
    );
};
export const AccordionComponent = styled(Accordion)(({ theme }) => {
    return {
        padding: '8px 0',
        boxShadow: 'none',
        '&.MuiAccordion-root ': {
            backgroundColor: 'initial',
        },
        '&.Mui-expanded': {
            margin: 0,
            '&:before': { opacity: 1 },
        },
        '&:last-of-type': {
            borderRadius: 0,
            borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        },
    };
});
export const AccordionSummaryComponent = styled(AccordionSummary)(
    ({ theme }) => {
        return {
            minHeight: '56px',
            '&.Mui-expanded': { minHeight: '56px' },
            '.MuiAccordionSummary-content': {
                display: 'flex',
                alignItems: 'center',
                margin: 0,
                '&.Mui-expanded': {
                    margin: '0',
                },
            },
        };
    }
);
const Lightbox = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(5px)',
    zIndex: 1300,
}));
export interface PagePreview {
    pageId?: string;
    worksheetId?: string;
    name: string;
    items: Card[];
    viewed: boolean;
    title: string;
    commentary: string;
    options: any;
    include: Field[];
    cardTypeIds: string[];
    order?: number;
    open?: boolean;
    ordinal?: number;
    id: string;
}
const WorkBookDrawerPage = ({
    page,
    single,
    updateItem,
    removeItem,
    items,
    pageConfig,
    fullWidth,
    workbookId,
    openDrawerItem,
    addSuggestedItem,
    setOpenDrawerItem,
    grab,
    updated,
    index,
    setPlaybookItems,
}: {
    addSuggestedItem: (item: PagePreview) => void;
    page?: PagePreview;
    single: boolean;
    index: number;
    items: PagePreview[];
    updateItem: (item: PagePreview, configuration?: boolean) => void;
    removeItem: (id: string) => void;
    pageConfig: PageConfig | null;
    fullWidth?: boolean;
    workbookId: string | undefined;
    openDrawerItem?: PagePreview | null;
    setOpenDrawerItem?: React.Dispatch<
        React.SetStateAction<PagePreview | null>
    >;
    grab?: string | null;
    updated: boolean;
    setPlaybookItems: React.Dispatch<React.SetStateAction<PagePreview[]>>;
}) => {
    const [selectedAttribute, setSelectedAttribute] = useState<any>(null);
    const worksheetSettings = worksheets.find(
        (item) => item.i === page?.worksheetId
    );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [helpTextAnchor, setHelpTextAnchor] = useState<HTMLElement | null>();
    const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>();
    const [cardType, setCardType] = useState<string | null>(null);
    const [chartType, setChartType] = useState(ChartType.BLANK);
    const { permissions, handleClose } = useContext(CardContext);
    const { cardTypeObject } = useContext(AppContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [savePlaybookOpen, setSavePlaybookOpen] = useState(false);
    const [pageOptions, setPageOptions] = useState<any>();
    const [radarOptions, setRadarOptions] = useState<RadarOption[] | null>();
    const [selectedCard, setSelectedCard] = useState<string | null>(null);
    const [cardSetModalOpen, setCardSetModalOpen] = useState<boolean>(false);
    const [aiModalOpen, setAiModalOpen] = useState(false);
    const theme = useTheme();
    const containerWidth = useRef<HTMLDivElement>(null);
    const [seen, setSeen] = useState(false);
    const [width, setWidth] = useState(0);
    const { id } = useParams();
    const client = generateClient();
    const { organisationProfile, user } = useContext(AppContext);
    const aiKeyExists = !!organisationProfile?.aiKey;

    const worksheetSize = fullWidth
        ? WorksheetSize.CAROUSEL
        : WorksheetSize.DEFAULT;

    const setMenu = (e: React.BaseSyntheticEvent) => {
        e.stopPropagation();

        if (e) {
            setAnchorEl(e.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };
    const setHelpTextModal = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setHelpTextAnchor(e.currentTarget);
        } else {
            setHelpTextAnchor(null);
        }
    };
    const setFilterModal = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setFilterAnchor(e.currentTarget);
        } else {
            setFilterAnchor(null);
        }
    };
    const basePlaybookForm: Array<FieldProps | FieldProps[]> = [
        {
            name: 'title',
            label: 'Title',
            type: AttributeType.Text,
            defaultValue: page?.title || '',
            required: true,
        },
        {
            name: 'commentary',
            label: 'Commentary',
            type: AttributeType.MultilineText,
            defaultValue: page?.commentary || '',
        },
    ];

    const openModal = () => {
        setAnchorEl(null);
        setModalOpen(true);
    };

    const updateValue = (name: string, value: string) => {
        let preview = { ...page };

        if (name === 'title' || name === 'commentary') {
            preview[name] = value ?? '';
        } else if (name === 'include') {
            if (!preview.include?.includes(value as Field)) {
                preview.include?.push(value as Field);
            } else {
                preview.include = preview.include.filter(
                    (item) => item !== value
                );
            }
        }

        updateItem(preview as PagePreview, true);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let ordinals = items.length
            ? (items
                  .filter(
                      (item) =>
                          item.ordinal !== null &&
                          item.ordinal !== undefined &&
                          !!item.id
                  )
                  .map((page) => page.ordinal) as number[])
            : [1];

        if (!ordinals.length) {
            ordinals = [1];
        }

        const lowest = Math.min(...ordinals);

        const ordinal =
            !!page?.id && page?.ordinal != null
                ? page.ordinal
                : lowest
                ? lowest - 1
                : 0;

        const currentOptions: any = pageOptions || {};
        const currentChartData: any = currentOptions.chartData || {};
        const updatedChartData = {
            ...currentChartData,
            radarOptions: radarOptions,
        };
        const combinedOptions = {
            ...currentOptions,
            chartData: updatedChartData,
        };
        setModalOpen(false);

        const userOrganisation = getUserOrganisation(user);
        const query = page?.id ? updatePlaybookPage : createPlaybookPage;
        const variables: any = {
            input: {
                ...(page?.id && {
                    id: page.id,
                }),
                ordinal: ordinal,
                organisation: userOrganisation ?? '',
                playbookPageType: PlaybookPageType.Chart,
                title: page?.title ?? '',
                include: page?.include ?? '',
                commentary: page?.commentary ?? '',
                worksheetId: page?.worksheetId,
                workbookId: id,
                options: JSON.stringify(combinedOptions),
            },
        };
        try {
            //Submit to here
            const res = (await client.graphql({
                query,
                variables: variables,
            })) as { data: CreatePlaybookPageMutation };

            if (page && !page.id) {
                page.id = res.data.createPlaybookPage?.id ?? '';
                page.ordinal = ordinal;
                addSuggestedItem(page);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        setWidth(containerWidth?.current?.offsetWidth ?? 0);
    }, [containerWidth.current?.offsetWidth]);

    useEffect(() => {
        setOpen(page?.open ?? false);
    }, [page]);

    useEffect(() => {
        const settings = getChartSettings(page?.name || '');

        const options = {
            chartData: {
                xAxis: settings?.options.chartData.xAxisOptions[0],
                yAxis: settings?.options.chartData.yAxisOptions[1],
                nodeSize: {
                    selection:
                        settings?.options.chartData.nodeSize.selection[0],
                },
                nodeColor: {
                    selection:
                        settings?.options.chartData.nodeColor.selection[0],
                },
                hideGaps: settings?.options.chartData.hideGaps,
                radarOptions:
                    worksheetSettings?.n === 'Competitive landscape'
                        ? getRadarOptions(cardTypeObject['3'])
                        : null,
            },
            layout: settings?.options.layout,
        };

        const pageOptions =
            typeof page?.options === 'string'
                ? JSON.parse(page.options)
                : page?.options;

        setPageOptions(pageOptions ?? options);
        setRadarOptions(
            pageOptions?.chartData.radarOptions ??
                options.chartData.radarOptions
        );
        const chartType = getChartType(page?.name ?? '');
        if (chartType) {
            setChartType(chartType);
        }
    }, [page?.name]);

    const setSelectedCategory = () => {
        const cardTypes = page?.cardTypeIds;

        if (cardTypes && cardTypes[0] != null) {
            setCardType(cardTypes[0]);
        }
    };

    useEffect(() => {
        if (page?.open) {
            setOpenDrawerItem && setOpenDrawerItem(page);
        }
    }, [page]);

    const handleSorting = () => {
        if (page?.id === openDrawerItem?.id) {
            setOpenDrawerItem && setOpenDrawerItem(null);
        } else {
            page && setOpenDrawerItem && setOpenDrawerItem(page);
        }
    };
    useEffect(() => {
        if (grab) {
            setOpen(false);
        }
    }, [grab]);

    useEffect(() => {
        if (single && !updated) {
            setOpen(true);
        }
    }, [index]);
    const convertCards = () => {
        const updatedCards: CardToDelete[] =
            page?.items?.map((item) => {
                const newCard = {
                    id: item.id,
                    organisation: item.organisation,
                    cardTypeId: item.toCardType.id,
                    cardCategoryId: item.toCardCategory.id,
                };
                return newCard;
            }) || [];
        return updatedCards || [];
    };
    const deleteWorksheet = async (id: string) => {
        const query = deleteWorksheetExt;
        try {
            //Submit to here
            const res = (await client.graphql({
                query,
                variables: {
                    id: id,
                },
            })) as { data: DeleteWorksheetExtMutation };
            if (res.data.deleteWorksheetExt) {
                const updatedWorksheets = items.filter(
                    (item) => item.id !== id
                );
                setPlaybookItems(updatedWorksheets);
            }
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <>
            {helpTextAnchor && <Lightbox />}

            <Box
                data-testid="workbook-drawer"
                ref={containerWidth}
                sx={{
                    pl: '10px',
                    background: grab === page?.id ? '#ccc' : 'inherit',
                    opacity: grab === page?.id ? 0.4 : 1,
                    overflow: 'hidden',
                    maxHeight: grab ? '73px' : 'auto',
                }}
            >
                <AccordionComponent
                    expanded={open}
                    onChange={(event) => {
                        setOpen(!open);
                        updateItem({
                            ...page,
                            viewed: true,
                            open: !open,
                        } as PagePreview);
                        !page?.viewed && setSeen(true);
                    }}
                >
                    <AccordionSummaryComponent
                        {...(width > 350 && {
                            expandIcon: <CustomExpandIcon />,
                        })}
                        aria-controls={`panel${index}a-content`}
                        data-testid={`panel-${index}-content`}
                        id={`panel${index}a-header`}
                        onClick={() => {
                            handleSorting();
                            setSelectedCard(null);
                        }}
                        sx={{
                            cursor: 'grabbing',
                            '.MuiButtonBase-root': {
                                cursor:
                                    grab === page?.id ? 'grabbing' : 'pointer',
                            },
                            '&:hover': {
                                cursor:
                                    grab === page?.id ? 'grabbing' : 'pointer',
                                '&:not(.Mui-disabled)': {
                                    cursor:
                                        grab === page?.id
                                            ? 'grabbing'
                                            : 'pointer',
                                },
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {!page?.viewed && !seen && (
                                    <Box
                                        data-testid={`viewed-${index}-box`}
                                        sx={{
                                            width: '8px',
                                            height: '8px',
                                            background: 'red',
                                            borderRadius: '50%',
                                            marginRight: '10px',
                                        }}
                                    />
                                )}
                                <Typography variant="h6">
                                    {!!page?.title ? page.title : page?.name}
                                </Typography>

                                <Tooltip title="Actions" placement="right">
                                    <IconButton
                                        data-testid={`icon-${index}-menu`}
                                        aria-label=""
                                        id="menu-button"
                                        onClick={setMenu}
                                        sx={{
                                            ml: 0.5,
                                            width: '50px',
                                            height: '50px',
                                            svg: { height: '50px' },
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>{' '}
                            {!page?.id && (
                                <Box>
                                    <Chip
                                        label="Suggested"
                                        size="small"
                                        sx={{
                                            ml: '15px',
                                            mt: '-15px',
                                            fontSize: '0.75rem',
                                            height: '20px',
                                            color: theme.palette.info.dark,
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        {page?.id && (
                            <Button
                                variant="text"
                                component={Link}
                                to={`/workbooks/${workbookId}/${page.id}`}
                            >
                                See details
                            </Button>
                        )}
                        {aiKeyExists && (
                            <Box
                                sx={{ mr: '10px' }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setAiModalOpen(true);
                                }}
                            >
                                <Tooltip
                                    title="Analyse worksheet with AI"
                                    placement="top"
                                >
                                    <AiIcon
                                        sx={{
                                            fontSize: '2rem',
                                            width: '25px',
                                            height: '25px',
                                            border: 'none',
                                            ml: 0.8,
                                            color: '#1976d2',
                                            animation: `${colorChange} 2s infinite, ${spin} 5s linear infinite`,
                                        }}
                                    />
                                </Tooltip>
                            </Box>
                        )}
                        <Box
                            sx={{ mr: '10px' }}
                            onClick={(event) => {
                                event.stopPropagation();
                                setHelpTextModal(event);
                            }}
                        >
                            <Tooltip title="Help" placement="top">
                                <HelpIcon
                                    width="35px"
                                    height="35px"
                                    color="#ccc"
                                />
                            </Tooltip>
                        </Box>
                    </AccordionSummaryComponent>

                    <AccordionDetails
                        sx={{
                            width: '97%',
                            padding: fullWidth
                                ? '40px 0px 16px 16px'
                                : '0px 0px 16px 16px',
                        }}
                    >
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            {page?.commentary}
                        </Typography>

                        {
                            {
                                [ChartType.GRID]: (
                                    <SwotGrid
                                        cards={page?.items ?? []}
                                        width={width}
                                        include={
                                            page?.include ?? ['title', 'score']
                                        }
                                        workbook={true}
                                        fullWidth={fullWidth}
                                        workbookId={workbookId}
                                        pageOptions={pageOptions}
                                        setPageOptions={setPageOptions}
                                        selectedAttribute={selectedAttribute}
                                        setSelectedAttribute={
                                            setSelectedAttribute
                                        }
                                        setFilterModal={setFilterModal}
                                        selectedCard={selectedCard}
                                        setSelectedCard={setSelectedCard}
                                        worksheetId={page?.id}
                                    />
                                ),
                                [ChartType.BUBBLES]: (
                                    <BubblePage
                                        cards={page?.items ?? []}
                                        page={undefined}
                                        worksheetId={page?.worksheetId}
                                        thumbnail={false}
                                        workbook={true}
                                        fullWidth={fullWidth}
                                        setCardType={setSelectedCategory}
                                        pageOptions={pageOptions}
                                        permissions={permissions}
                                    />
                                ),
                                [ChartType.RETROSPECTIVE]: (
                                    <Retrospective
                                        page={page}
                                        cards={page?.items ?? []}
                                        size={worksheetSize}
                                        workbookId={workbookId}
                                        pageOptions={pageOptions}
                                        selectedCard={selectedCard}
                                        setSelectedCard={setSelectedCard}
                                        fullWidth={fullWidth}
                                    />
                                ),
                                [ChartType.RISK_ANALYSIS]: (
                                    <RiskAnalysis
                                        size={worksheetSize}
                                        cards={page?.items ?? []}
                                        page={page}
                                        workbookId={workbookId}
                                        pageOptions={pageOptions}
                                        selectedCard={selectedCard}
                                        setSelectedCard={setSelectedCard}
                                        fullWidth={fullWidth}
                                    />
                                ),
                                [ChartType.RADAR]: (
                                    <RadarPage
                                        cards={page?.items ?? []}
                                        page={undefined}
                                        worksheetId={page?.worksheetId}
                                        thumbnail={false}
                                        workbook={true}
                                        fullWidth={fullWidth}
                                        setCardType={setSelectedCategory}
                                        pageOptions={pageOptions}
                                        permissions={permissions}
                                        radarOptions={radarOptions}
                                        setRadarOptions={setRadarOptions}
                                    />
                                ),
                                [ChartType.BLANK]: (
                                    <Box
                                        sx={{
                                            marginTop: '30px',
                                            marginLeft: '20px',
                                        }}
                                    >
                                        Worksheet coming soon
                                    </Box>
                                ),
                                [ChartType.PESTLE_ANALYSIS]: (
                                    <PestleAnalysis
                                        size={worksheetSize}
                                        cards={page?.items ?? []}
                                        page={page}
                                        workbookId={workbookId}
                                        pageOptions={pageOptions}
                                        selectedCard={selectedCard}
                                        setSelectedCard={setSelectedCard}
                                        fullWidth={fullWidth}
                                    />
                                ),
                                [ChartType.PORTERS_FIVE_FORCES]: (
                                    <PortersFiveForces
                                        size={worksheetSize}
                                        cards={page?.items ?? []}
                                        page={page}
                                        workbookId={workbookId}
                                        pageOptions={pageOptions}
                                        selectedCard={selectedCard}
                                        setSelectedCard={setSelectedCard}
                                        fullWidth={fullWidth}
                                    />
                                ),
                            }[chartType]
                        }
                    </AccordionDetails>
                </AccordionComponent>
                <Menu
                    data-testid={`drawer-menu`}
                    id="positioned-menu"
                    aria-labelledby="menu-button"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{
                        '& .MuiPaper-root': {
                            boxShadow: `1px 1px 1px 1px ${theme.palette.background.paper}`,
                            backgroundColor: theme.palette.background.default,
                        },
                    }}
                >
                    {permissions?.includes(UserPermissions.EDIT) && (
                        <MenuItem
                            data-testid="configure-menu"
                            onClick={() => {
                                setAnchorEl(null);
                                setSavePlaybookOpen(false);
                                setModalOpen(true);
                            }}
                        >
                            Configure
                        </MenuItem>
                    )}
                    {permissions?.includes(UserPermissions.EDIT) && (
                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                page && deleteWorksheet(page?.id ?? '');
                            }}
                        >
                            Delete
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            setSavePlaybookOpen(true);
                            setModalOpen(false);
                        }}
                    >
                        Save to playbook
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            setCardSetModalOpen(true);
                            setModalOpen(false);
                        }}
                    >
                        Create card set
                    </MenuItem>
                </Menu>

                <Dialog
                    PaperProps={{
                        sx: {
                            borderRadius: 6,
                            maxWidth: '40rem',
                            width: '40rem',
                            backgroundColor: 'background.default',
                            height: 'auto',
                            padding: 4,
                        },
                    }}
                    scroll={'paper'}
                    key="modal"
                    open={modalOpen || savePlaybookOpen}
                    onClose={() => {
                        setModalOpen(false);
                        setSavePlaybookOpen(false);
                    }}
                    aria-labelledby=""
                    aria-describedby=""
                >
                    {page && (
                        <>
                            {modalOpen ? (
                                <Box data-testid="configuration-modal">
                                    <Typography variant="h1" sx={{ mb: 4 }}>
                                        Configure worksheet
                                    </Typography>
                                    <Form
                                        chartType={chartType}
                                        formConfiguration={basePlaybookForm}
                                        handleCancel={() => setModalOpen(false)}
                                        handleSubmit={handleSubmit}
                                        disableSubmit={false}
                                        onChange={(name, value) =>
                                            updateValue(name, value)
                                        }
                                        values={{
                                            title: page?.title ?? '',
                                            commentary: page?.commentary ?? '',
                                        }}
                                        checkboxValues={
                                            page?.include ?? ['score', 'title']
                                        }
                                        submitText="Save"
                                        chartSettings={getChartSettings(
                                            page?.name ?? ''
                                        )}
                                        pageOptions={pageOptions}
                                        setPageOptions={setPageOptions}
                                        radarOptions={radarOptions}
                                        setRadarOptions={setRadarOptions}
                                    />
                                </Box>
                            ) : (
                                <SavePlaybookPage
                                    page={page}
                                    onCancel={() => {
                                        setModalOpen(false);
                                        setSavePlaybookOpen(false);
                                    }}
                                    pageOptions={pageOptions}
                                    radarOptions={radarOptions}
                                />
                            )}
                        </>
                    )}
                </Dialog>
                {helpTextAnchor && (
                    <CardHelp
                        anchor={helpTextAnchor}
                        setHelpTextModal={setHelpTextModal}
                        setHelpTextAnchor={setHelpTextAnchor}
                        worksheetSettings={worksheetSettings}
                    />
                )}
            </Box>
            <StyledModal
                key="create-card-modal"
                open={!!cardType}
                onClose={() => setCardType(null)}
            >
                <Box>
                    {!!cardType && pageOptions && (
                        <ModalContainer sx={{ maxWidth: '35rem' }}>
                            <CreateCard
                                cardTypeFromDrawer={cardType}
                                cardSet={workbookId}
                                handleClose={handleClose}
                                setCardType={setCardType}
                                pageOptions={pageOptions}
                                radarOptions={radarOptions}
                                close={() => handleClose()}
                            />
                        </ModalContainer>
                    )}
                </Box>
            </StyledModal>
            <StyledModal
                key="create-cardset-modal"
                open={cardSetModalOpen}
                onClose={() => setCardSetModalOpen(false)}
                sx={{ zIndex: 1401 }}
            >
                <Box>
                    {cardSetModalOpen && (
                        <ModalContainer sx={{ maxWidth: '35rem' }}>
                            <CreateCardSet
                                handleClose={() => setCardSetModalOpen(false)}
                                multiSelectedCards={convertCards()}
                            />
                        </ModalContainer>
                    )}
                </Box>
            </StyledModal>
            <StyledModal
                key="remove-card-modal"
                open={aiModalOpen}
                onClose={() => setAiModalOpen(false)}
                disableEnforceFocus
                sx={{
                    zIndex: 1500,
                }}
            >
                <Box>
                    {aiModalOpen && (
                        <ModalContainer sx={{ maxWidth: '65rem' }}>
                            <AiAnalysisModal
                                cards={page?.items || []}
                                worksheetName={page?.name || ''}
                                close={() => setCardSetModalOpen(false)}
                            />
                        </ModalContainer>
                    )}
                </Box>
            </StyledModal>
        </>
    );
};

export default WorkBookDrawerPage;
