import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    AuditEntry,
    AuditType,
    Card,
    CardSet,
    EntityType,
    GetAuditDataQuery,
    ListAuditEntriesQuery,
    RelationshipType,
    Worksheet,
} from '../../API';
import { generateClient } from 'aws-amplify/api';
import { getAuditData, listAuditEntries } from '../../graphql/queries';
import {
    Box,
    CircularProgress,
    Paper,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent,
    timelineItemClasses,
} from '@mui/lab';
import { shortDateStringFromISO, timeStringFromISO } from '../../helpers/utils';
import { ReactComponent as ContentIcon } from '../../assets/icons/Content.svg';
import { ReactComponent as ActivityIcon } from '../../assets/icons/Event.svg';
import { ReactComponent as ScoresIcon } from '../../assets/icons/Scores.svg';
import { ReactComponent as UpdateUserIcon } from '../../assets/icons/update-user.svg';
import { ReactComponent as RelationshipsIcon } from '../../assets/icons/Relationships.svg';
import { ReactComponent as FingerprintIcon } from '../../assets/icons/Fingerprint.svg';
import { ReactComponent as CommentsIcon } from '../../assets/icons/Comments.svg';
import UserAvatar from '../profile/UserAvatar';
import { AppContext } from '../contexts';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { StyledModal } from '../Modal';
import ModalContainer from '../ModalContainer';
import {
    createUpdateMessage,
    groupByMonthAndBatch,
} from '../../helpers/cardActivity';

import { CardAPITypeName } from './cardTypes';
import {
    getAllAttributeDefinitions,
    getAllScoreDefinitions,
    getCardScoreDefinitions,
} from '../../helpers/scores';

interface CardActivityProps {
    card: Card | CardSet | Worksheet;
    worksheet?: boolean;
    contextIds?: string[];
    worksheetCard?: any;
    context?: EntityType;
}

export interface BatchGroupedEntry {
    batchId: string;
    entries: AuditEntry[];
}

export interface MonthAndBatchGroup {
    month: string;
    batches: BatchGroupedEntry[];
}
export interface DiffText {
    old: string;
    new: string;
}
enum DiffMethod {
    CSS = 'diffCss',
}

const diffStyles = {
    variables: {
        light: {
            addedBackground: '#C0C0C0',
            removedBackground: '#D3D3D3',
            wordAddedBackground: '#acf2bd',
            wordRemovedBackground: '#ffcccc',
        },
        dark: {
            addedBackground: '#C0C0C0',
            removedBackground: '#D3D3D3',
            wordAddedBackground: '#acf2bd',
            wordRemovedBackground: '#ffcccc',
        },
    },
    diffContainer: {
        fontSize: '12px',
        pre: {
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        },
    },
};

const MonthSeparator = ({ month }: { month: string }) => (
    <TimelineItem sx={{ ml: 1.2 }}>
        <TimelineSeparator>
            <TimelineDot variant="filled" sx={{ width: 18, height: 18 }} />
            <TimelineConnector sx={{ mb: -12, mt: -3, zIndex: 3 }} />
        </TimelineSeparator>
        <TimelineContent>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                {month}
            </Typography>
        </TimelineContent>
    </TimelineItem>
);
const checkAuditType = (entry: AuditEntry) => {
    if (entry.operation === AuditType.ItemCreated) {
        return <ActivityIcon width={25} height={25} />;
    } else {
        return <ContentIcon width={25} height={25} />;
    }
};
export const getIconForEntityType = (entry: AuditEntry) => {
    switch (entry.subContextType) {
        case EntityType.Attribute:
            return <ContentIcon width={25} height={25} />;
        case EntityType.Comment:
            return <CommentsIcon width={25} height={25} />;
        case EntityType.ScoreData:
            return <ScoresIcon width={25} height={25} />;
        case EntityType.UserProfile:
        case EntityType.Organisation:
            return <UpdateUserIcon width={25} height={25} />;
        case EntityType.CardsCardSets:
        case EntityType.PlaybookPage:
            return <RelationshipsIcon width={25} height={25} />;

        default:
            return checkAuditType(entry);
    }
};

const CardActivity = ({
    card,
    worksheet,
    contextIds = [],
    worksheetCard,
    context,
}: CardActivityProps) => {
    const theme = useTheme();
    const [fingerprintOn, setFingerprintOn] = useState(false);
    const [entityTypes, setEntityTypes] = useState<EntityType[]>([]);
    const [diffModalOpen, setDiffModalOpen] = useState<boolean>(false);
    const [diffText, setDiffText] = useState<DiffText>({ old: '', new: '' });
    const [auditEntries, setAuditEntries] = useState<AuditEntry[]>([]);
    const [groupedEntries, setGroupedEntries] = useState<
        MonthAndBatchGroup[] | null
    >();
    const [cardTypeName, setCardTypeName] = useState<string>('');
    const { cardTypes, cardTypeObject, organisationProfile, user } =
        useContext(AppContext);
    const [names, setNames] = useState<{ [key: string]: string }>();
    const [token, setToken] = useState<string | null>('');
    const [loaded, setLoaded] = useState(false);

    const listInnerRef = useRef();

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                listInnerRef.current;

            if (scrollTop + clientHeight === scrollHeight) {
                token !== null && fetchActivity(true);
            }
        }
    };

    const scoreDefinitions = useMemo(
        () => getAllScoreDefinitions(cardTypes),
        [cardTypes]
    );

    const [expandedBatches, setExpandedBatches] = useState<
        Record<string, boolean>
    >({});

    const attributeDefinitions =
        card.__typename === 'Card'
            ? getAllAttributeDefinitions(cardTypeObject, groupedEntries, card)
            : null;
    const client = generateClient();
    const filterIcons = [
        {
            Component: ContentIcon,
            entityType: [EntityType.Attribute],
            tooltipText: 'Just details',
        },
        {
            Component: UpdateUserIcon,
            entityType: [EntityType.UserProfile, EntityType.Organisation],
            tooltipText: 'Just membership',
        },
        {
            Component: CommentsIcon,
            entityType: [EntityType.Comment],
            tooltipText: 'Just comments',
        },
    ];

    const toggleBatchExpansion = (batchId: string) => {
        setExpandedBatches((prev) => ({ ...prev, [batchId]: !prev[batchId] }));
    };
    const getColor = (array: EntityType[]): string => {
        return array.some((item) => entityTypes.includes(item))
            ? theme.palette.primary.main
            : 'gray';
    };
    useEffect(() => {
        switch (card.__typename) {
            case CardAPITypeName.Card:
                setCardTypeName('Card');
                break;
            case CardAPITypeName.CardSet:
                setCardTypeName(card.type === 'WB' ? 'Workbook' : 'Card set');
                break;
            case CardAPITypeName.Worksheet:
                setCardTypeName('Worksheet');
                break;
            default:
                break;
        }
    }, [card]);

    const createdFromCard = (item: AuditEntry, cardId: string) => {
        if (
            item.operation !== AuditType.ItemLinked ||
            item.relationshipType !== RelationshipType.CreatedFrom
        )
            return false;

        const newRelation = item?.new?.split('#') ?? '';

        const createdCard = newRelation[1].substring(
            0,
            newRelation[1].indexOf('_')
        );

        return createdCard === cardId;
    };
    const fetchActivity = async (loadMore?: boolean) => {
        let allEntries = loadMore ? [...auditEntries] : [];

        const response = (await client.graphql({
            query: getAuditData,
            variables: {
                contextIds: [card.id].concat(contextIds),
                limit: 30,
                ...(token && {
                    nextTokens: token,
                }),
                filter: {
                    startDate: card.createdAt,
                    ...(fingerprintOn && {
                        currentUser: true,
                    }),
                    ...(entityTypes.includes(EntityType.Attribute) && {
                        attributes: true,
                    }),
                    ...(entityTypes.includes(EntityType.Organisation) && {
                        membership: true,
                    }),
                    ...(entityTypes.includes(EntityType.Comment) && {
                        comments: true,
                    }),
                    ...(entityTypes.includes(EntityType.Relationship) && {
                        relationships: true,
                    }),
                    ...(entityTypes.includes(EntityType.ScoreData) && {
                        scores: true,
                    }),
                },
            },
        })) as { data: GetAuditDataQuery };

        setLoaded(true);

        const items = response?.data?.getAuditData?.items;
        if (items) {
            const filteredEntries = items.filter(
                (entry): entry is AuditEntry => entry !== null
            );
            allEntries.push(...filteredEntries);
        }
        let nextToken = response?.data?.getAuditData?.nextTokens;
        if (nextToken || nextToken === null || nextToken === 'e30=') {
            setToken(nextToken);
        }
        let names = response.data.getAuditData?.names
            ? JSON.parse(response.data.getAuditData.names)
            : [];
        setNames(names);

        //This ensures the duplicate will not appear in the worksheet activity audit
        const sortedEntries = allEntries.filter(
            (item) =>
                (item.operation !== AuditType.ItemLinked &&
                    item.operation !== AuditType.ItemUnlinked) ||
                item.contextType !== EntityType.Card ||
                (!worksheet && !createdFromCard(item, card.id))
        );
        setAuditEntries(sortedEntries);
        const grouped = groupByMonthAndBatch(sortedEntries);

        setGroupedEntries(grouped);
    };
    useEffect(() => {
        setAuditEntries([]);
        setToken('');

        if (user) {
            fetchActivity();
        }
    }, [entityTypes, fingerprintOn, user]);
    const handleAuditTypeClick = (entityType: EntityType) => {
        setEntityTypes((current) => {
            const isSelected = current.includes(entityType);
            if (isSelected) {
                return current.filter((type) => type !== entityType);
            } else {
                return [...current, entityType];
            }
        });
    };
    if (card.__typename === 'Card') {
        filterIcons.push({
            Component: ScoresIcon,
            entityType: [EntityType.ScoreData],
            tooltipText: 'Just scores',
        });
    }
    if (card.__typename !== 'Card') {
        filterIcons.push({
            Component: RelationshipsIcon,
            entityType: [
                EntityType.Relationship,
                EntityType.CardsCardSets,
                EntityType.PlaybookPage,
            ],
            tooltipText: 'Just relationships',
        });
    }

    return (
        <Box
            sx={{
                height: worksheetCard ? 'calc(100vh - 570px)' : '100%',
                overflow: 'auto',
                paddingRight: '8px',
                background:
                    context === EntityType.CardSet
                        ? theme.palette.background.default
                        : '',
                borderRadius: context === EntityType.CardSet ? '12px' : 0,
                p: context === EntityType.CardSet ? 2 : 0,
            }}
            ref={listInnerRef}
            onScroll={onScroll}
        >
            <Box
                sx={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Tooltip title="Just mine" placement="top">
                    <FingerprintIcon
                        width={35}
                        height={35}
                        fill={
                            fingerprintOn ? theme.palette.primary.main : 'gray'
                        }
                        style={{ marginRight: 10, cursor: 'pointer' }}
                        onClick={() => setFingerprintOn(!fingerprintOn)}
                    />
                </Tooltip>
                {filterIcons.map(
                    ({ Component, entityType, tooltipText }, index) => (
                        <Tooltip title={tooltipText} placement="top">
                            <Component
                                key={index}
                                width={35}
                                height={35}
                                color={getColor(entityType)}
                                style={{ marginRight: 10, cursor: 'pointer' }}
                                onClick={() =>
                                    entityType.forEach((type) =>
                                        handleAuditTypeClick(type)
                                    )
                                }
                            />
                        </Tooltip>
                    )
                )}
            </Box>
            {loaded ? (
                <Timeline
                    position="right"
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 2,
                        },
                        p: 0,
                        m: 0,
                    }}
                >
                    {groupedEntries?.map((group: MonthAndBatchGroup) => (
                        <Box key={group.month}>
                            <MonthSeparator month={group.month} />
                            {group.batches.map((batch: BatchGroupedEntry) => {
                                const items =
                                    batch.entries[0].operation ===
                                        AuditType.PermissionGranted ||
                                    batch.entries[0].operation ===
                                        AuditType.PermissionRevoked
                                        ? batch.entries.slice(
                                              0,
                                              expandedBatches[batch.batchId]
                                                  ? batch.entries.length
                                                  : 2
                                          )
                                        : batch.entries;
                                const firstEntry = batch?.entries[0];

                                const displayAvatar =
                                    (firstEntry?.operation ===
                                        AuditType.ItemCreated &&
                                        firstEntry?.subContextType ===
                                            EntityType.ScoreData) ||
                                    firstEntry.operation ===
                                        AuditType.ItemUpdated ||
                                    firstEntry.operation ===
                                        AuditType.AttributeUpdated ||
                                    firstEntry.operation ===
                                        AuditType.ItemAddedTo ||
                                    firstEntry.operation ===
                                        AuditType.ScoreUpdated ||
                                    firstEntry.operation ===
                                        AuditType.OwnerChanged ||
                                    firstEntry.operation ===
                                        AuditType.PermissionGranted ||
                                    firstEntry.operation ===
                                        AuditType.PermissionRevoked ||
                                    firstEntry.operation ===
                                        AuditType.CardTypeChanged;

                                return (
                                    <Paper
                                        key={batch.batchId}
                                        sx={{ mt: '20px', p: '10px' }}
                                    >
                                        {displayAvatar && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    ml: 6.5,
                                                    pt: 1,
                                                }}
                                            >
                                                <UserAvatar
                                                    userId={
                                                        firstEntry?.user?.split(
                                                            '::'
                                                        )[0]
                                                    }
                                                    fontSize={'14px'}
                                                    cardCategory={'default'}
                                                    column={
                                                        card.__typename ===
                                                        'Worksheet'
                                                            ? true
                                                            : false
                                                    }
                                                    avatarOnly
                                                />
                                                <Typography variant="body2">
                                                    Updated the{' '}
                                                    {firstEntry.contextType
                                                        ? firstEntry.contextType?.toLowerCase()
                                                        : firstEntry.subContext?.toLowerCase()}{' '}
                                                    with the following changes
                                                    on{' '}
                                                    <Tooltip
                                                        title={`${timeStringFromISO(
                                                            firstEntry?.createdAt
                                                        )}`}
                                                        placement="top"
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            {' '}
                                                            {shortDateStringFromISO(
                                                                firstEntry?.createdAt
                                                            )}
                                                        </span>
                                                    </Tooltip>
                                                    :
                                                </Typography>
                                            </Box>
                                        )}
                                        {items.map((entry: AuditEntry) => (
                                            <TimelineItem
                                                key={entry.id}
                                                sx={{ ml: '-10px' }}
                                            >
                                                <TimelineSeparator>
                                                    <TimelineDot
                                                        color={'primary'}
                                                        sx={{ zIndex: 10 }}
                                                    >
                                                        {getIconForEntityType(
                                                            entry
                                                        )}
                                                    </TimelineDot>
                                                    <TimelineConnector
                                                        sx={{
                                                            mt: -1.45,
                                                            mb: -13,
                                                            zIndex: 5,
                                                        }}
                                                    />
                                                </TimelineSeparator>
                                                <TimelineContent
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {createUpdateMessage(
                                                        entry,
                                                        attributeDefinitions,
                                                        setDiffText,
                                                        setDiffModalOpen,
                                                        organisationProfile,
                                                        scoreDefinitions,
                                                        cardTypeName,
                                                        cardTypes,
                                                        names
                                                    )}
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))}
                                        {batch.entries.length > 2 &&
                                            (batch.entries[0].operation ===
                                                AuditType.PermissionGranted ||
                                                batch.entries[0].operation ===
                                                    AuditType.PermissionRevoked) && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        cursor: 'pointer',
                                                        color: 'primary.main',
                                                        textAlign: 'right',
                                                        mr: 2,
                                                        mb: 2,
                                                        fontStyle: 'italic',
                                                    }}
                                                    onClick={() =>
                                                        toggleBatchExpansion(
                                                            batch.batchId
                                                        )
                                                    }
                                                >
                                                    {expandedBatches[
                                                        batch.batchId
                                                    ]
                                                        ? 'less'
                                                        : 'more'}
                                                </Typography>
                                            )}
                                    </Paper>
                                );
                            })}
                        </Box>
                    ))}
                </Timeline>
            ) : (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress size={100} />
                </Box>
            )}
            <StyledModal
                key="modal"
                open={diffModalOpen}
                onClose={() => {
                    setDiffModalOpen(false);
                    setDiffText({ old: '', new: '' });
                }}
                sx={{ zIndex: 1401 }}
            >
                <>
                    {diffModalOpen && (
                        <ModalContainer
                            sx={{
                                maxWidth: '48rem',
                                maxHeight: '500px',
                                overflow: 'auto',
                                p: 2,
                            }}
                        >
                            <ReactDiffViewer
                                oldValue={diffText.old}
                                newValue={diffText.new}
                                splitView={false}
                                compareMethod={DiffMethod.CSS}
                                hideLineNumbers={true}
                                styles={diffStyles}
                            />
                        </ModalContainer>
                    )}
                </>
            </StyledModal>
        </Box>
    );
};

export default CardActivity;
