import {
    Alert,
    Box,
    IconButton,
    InputAdornment,
    Paper,
    Snackbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    dateStringFromISO,
    timeStringFromISO,
    truncateText,
} from '../../helpers/utils';
import UserAvatar from '../profile/UserAvatar';
import { ReactComponent as PencilIcon } from '../../assets/icons/Pencil.svg';
import SendIcon from '@mui/icons-material/Send';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { InputField } from './CardComments';
import Picker from '@emoji-mart/react';
import DOMPurify from 'dompurify';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import LikeIcon from '@mui/icons-material/ThumbUpAltOutlined';
import BlueLikeIcon from '@mui/icons-material/ThumbUpAltRounded';
import { getUserOrganisation } from '../../helpers/auth';
import { generateClient } from 'aws-amplify/api';
import {
    createCommentReaction,
    deleteCommentReaction,
    updateComment,
} from '../../graphql/mutations';
import {
    Card,
    CardSet,
    CreateCommentReactionMutation,
    DeleteCommentReactionMutation,
    EntityType,
    Playbook,
    UpdateCommentMutation,
} from '../../API';
import { AppContext } from '../contexts';
import useSnackbar from '../../hooks/useSnackbar';

interface CommentProps {
    newCommentId: string | null;
    setNewCommentId: React.Dispatch<React.SetStateAction<string | null>>;
    expandedComments: Set<string>;
    setExpandedComments: React.Dispatch<React.SetStateAction<Set<string>>>;
    comment: any;
    editableCommentId: string | null;
    setEditableCommentId: React.Dispatch<React.SetStateAction<string | null>>;
    editableCommentMessage: string;
    setEditableCommentMessage: React.Dispatch<React.SetStateAction<string>>;
    setCommentToDelete: React.Dispatch<React.SetStateAction<string | null>>;
    card: Card | CardSet | Playbook;
    onUpdate: ((id: string, organisation: string) => void) | undefined;
    selected?: boolean;
    context?: EntityType;
    worksheetCarouselOpen?: boolean;
}

const Comment = ({
    newCommentId,
    setNewCommentId,
    expandedComments,
    setExpandedComments,
    comment,
    editableCommentId,
    setEditableCommentId,
    editableCommentMessage,
    setEditableCommentMessage,
    card,
    onUpdate,
    setCommentToDelete,
    selected,
    context,
    worksheetCarouselOpen,
}: CommentProps) => {
    const theme = useTheme();
    const { isGlobalAdmin, user } = useContext(AppContext);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [authorId, setAuthorId] = useState<string | null | undefined>(null);
    const pickerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const client = generateClient();
    const [isLiked, setIsLiked] = useState<boolean | null>(null);
    const [likeCount, setLikeCount] = useState<number | null>(null);
    const [likeUpdated, setLikeUpdated] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();

    useEffect(() => {
        if (authorId) {
            const usersWhoLiked =
                comment.reactions?.items?.map((item: any) => item?.owner) ?? [];

            if (!likeUpdated) {
                setIsLiked(usersWhoLiked.includes(authorId));
                setLikeCount(comment.reactions?.items?.length ?? 0);
            } else if (!isLiked && usersWhoLiked.includes(authorId)) {
                const count = comment.reactions.items.length - 1;

                setLikeCount(count);
            } else if (isLiked && !usersWhoLiked.includes(authorId)) {
                const count = comment.reactions?.items?.length + 1;

                setLikeCount(count);
            } else {
                setLikeCount(comment.reactions?.items?.length ?? 0);
            }
        }
    }, [comment, comment?.reactions?.items?.length, authorId]);

    const toggleExpandComment = (id: string) => {
        setExpandedComments((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };
    const handleCommentLike = async () => {
        setLikeUpdated(true);
        setIsLiked(true);
        setLikeCount((likeCount ?? 0) + 1);

        const userOrganisation = getUserOrganisation(user);
        try {
            const response = (await client.graphql({
                query: createCommentReaction,
                variables: {
                    input: {
                        commentContextId: comment.contextId,
                        ...(context && {
                            commentContextType: context,
                        }),
                        organisation: userOrganisation,
                        commentId: comment.id,
                        createdBy: user?.userSub || '',
                        commentCreatedAt: comment.createdAt,
                    },
                },
            })) as { data: CreateCommentReactionMutation };
            showSnackbar({
                message: 'Comment liked',
                severity: 'success',
            });
        } catch (err) {
            console.log(err);
        }
    };
    const handleDeleteLike = async () => {
        setLikeUpdated(true);
        setIsLiked(false);
        setLikeCount((likeCount ?? 0) - 1);

        try {
            const response = (await client.graphql({
                query: deleteCommentReaction,
                variables: {
                    input: {
                        commentId: comment.id,
                        createdBy: `${user?.userSub}::${user?.userSub}` || '',
                    },
                },
            })) as { data: DeleteCommentReactionMutation };
            showSnackbar({
                message: 'Comment unliked',
                severity: 'success',
            });
        } catch (err) {
            console.log(err);
        }
    };
    const handleEditClick = (comment: any) => {
        if (editableCommentId === comment.id) {
            setEditableCommentId(null);
            setEditableCommentMessage('');
        } else {
            setEditableCommentId(comment.id);
            setEditableCommentMessage(comment.message);
        }
    };
    const handleDeleteClick = (comment: any) => {
        setCommentToDelete(comment);
    };
    useEffect(() => {
        if (user) {
            setAuthorId(user.userSub);
        }
    }, [card, user]);

    const handleUpdateComment = async () => {
        const userOrganisation = getUserOrganisation(user);
        try {
            const response = (await client.graphql({
                query: updateComment,
                variables: {
                    input: {
                        contextId: comment.contextId,
                        organisation: userOrganisation,
                        id: comment.id,
                        createdAt: comment.createdAt,
                        message: editableCommentMessage,
                    },
                },
            })) as { data: UpdateCommentMutation };

            showSnackbar({
                message: 'Comment updated',
                severity: 'success',
            });

            const updatedComment = response?.data?.updateComment;
        } catch (err) {
            console.log(err);
        }

        setEditableCommentId(null);
        setEditableCommentMessage('');
    };
    const addEmoji = (emoji: any) => {
        if (inputRef.current) {
            const cursorPosition = inputRef.current.selectionStart || 0;
            const newText =
                editableCommentMessage.slice(0, cursorPosition) +
                emoji.native +
                editableCommentMessage.slice(cursorPosition);
            setEditableCommentMessage(newText);

            const newCursorPosition = cursorPosition + emoji.native.length;
            setTimeout(() => {
                inputRef.current!.setSelectionRange(
                    newCursorPosition,
                    newCursorPosition
                );
                inputRef.current!.focus();
            }, 0);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            pickerRef.current &&
            !pickerRef.current.contains(event.target as Node)
        ) {
            setShowEmojiPicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showEmojiPicker && pickerRef.current) {
            pickerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [showEmojiPicker]);

    const handleShowEmojiPicker = () => {
        setShowEmojiPicker((prev) => !prev);
        if (pickerRef.current) {
            pickerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    };
    return (
        <Box
            sx={{
                px: comment.id === newCommentId ? 1 : 0,
                pt: comment.id === newCommentId ? 1 : 0,
                pb: comment.id === newCommentId ? 3 : 0,
                mb: 4,
                borderRadius: '16px',
                '&:hover .comment-actions': {
                    opacity: 1,
                },
                '&:hover .comment-text': {
                    width:
                        editableCommentId === comment.id ||
                        (authorId !== comment.createdBy && !isGlobalAdmin)
                            ? 'inherit'
                            : '95%',
                },
                position: 'relative',
            }}
            onClick={() => setNewCommentId(null)}
            ref={containerRef}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative',
                }}
            >
                <UserAvatar
                    userId={comment.createdBy}
                    smallAvatar={worksheetCarouselOpen}
                />
                <Tooltip
                    title={`${timeStringFromISO(comment.createdAt)}`}
                    placement="top"
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: worksheetCarouselOpen ? '10px' : '12px',
                        }}
                    >
                        {dateStringFromISO(comment.createdAt)}
                    </Typography>
                </Tooltip>
            </Box>
            <Paper
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    ml: 5,
                    p: worksheetCarouselOpen ? 1 : 2,
                    borderRadius: '24px',
                    borderTopLeftRadius: '0px',
                    position: 'relative',
                    backgroundColor:
                        selected || comment.id === newCommentId
                            ? theme.palette.primary.light
                            : theme.palette.background.paper,
                }}
            >
                <Box
                    sx={{
                        width: '30px',
                        height: '30px',
                        position: 'absolute',
                        top: 0,
                        left: -15,
                        borderLeft: '20px solid transparent',
                        borderTop: `20px solid ${
                            selected || comment.id === newCommentId
                                ? theme.palette.primary.light
                                : theme.palette.mode === 'dark'
                                ? 'transparent'
                                : theme.palette.background.paper
                        }`,
                        borderRadius: '2px',
                        transform: `rotate(20deg)`,
                    }}
                ></Box>
                {editableCommentId !== comment.id ? (
                    <>
                        <Typography
                            variant="body2"
                            className="comment-text"
                            sx={{
                                color:
                                    selected || comment.id === newCommentId
                                        ? '#fff'
                                        : 'inherit',
                                zIndex: 5,
                                fontSize: worksheetCarouselOpen ? '12px' : '',
                            }}
                        >
                            {expandedComments.has(comment.id)
                                ? comment.message
                                : truncateText(comment.message, 230)}
                            {comment.message.length > 230 && (
                                <span
                                    onClick={() =>
                                        toggleExpandComment(comment.id)
                                    }
                                    style={{
                                        cursor: 'pointer',
                                        color: 'inherit',
                                        textAlign: 'right',
                                        marginRight: '8px',
                                        marginBottom: '8px',
                                        fontSize: '10px',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    {expandedComments.has(comment.id)
                                        ? 'less'
                                        : 'more'}
                                </span>
                            )}
                        </Typography>
                        <Paper
                            className="like-container"
                            sx={{
                                position: 'absolute',
                                bottom: -18,
                                left: 20,
                                background: '#fff',
                                height: worksheetCarouselOpen ? '8px' : '20px',
                                width: worksheetCarouselOpen ? '20px' : '45px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: worksheetCarouselOpen
                                    ? '8px'
                                    : '16px',
                                px: 2,
                                py: worksheetCarouselOpen ? 1.5 : 2,
                            }}
                        >
                            <Box>
                                {isLiked ? (
                                    <Tooltip title="Unlike" placement="top">
                                        <IconButton
                                            onClick={handleDeleteLike}
                                            data-testid="like-comment-button"
                                            sx={{
                                                cursor: 'pointer',
                                                width: '17px',
                                                height: '17px',
                                            }}
                                        >
                                            <BlueLikeIcon
                                                style={{
                                                    width: '17px',
                                                    color: theme.palette.primary
                                                        .main,
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Like" placement="top">
                                        <IconButton
                                            onClick={handleCommentLike}
                                            data-testid="like-comment-button"
                                            sx={{
                                                cursor: 'pointer',
                                                width: worksheetCarouselOpen
                                                    ? '13px'
                                                    : '17px',
                                                height: worksheetCarouselOpen
                                                    ? '13px'
                                                    : '17px',
                                            }}
                                        >
                                            <LikeIcon
                                                style={{
                                                    width: worksheetCarouselOpen
                                                        ? '13px'
                                                        : '17px',
                                                    color:
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? theme.palette
                                                                  .background
                                                                  .paper
                                                            : 'inherit',
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                            <Typography
                                variant="body2"
                                sx={{
                                    ml: 0.5,
                                    color:
                                        theme.palette.mode === 'dark'
                                            ? theme.palette.background.paper
                                            : 'inherit',
                                    fontSize: worksheetCarouselOpen
                                        ? '12px'
                                        : '',
                                }}
                            >
                                {likeCount}
                            </Typography>
                        </Paper>
                        <Box
                            className="comment-actions"
                            sx={{
                                opacity: 0,
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                transition: 'opacity 0.3s ease',
                            }}
                        >
                            {authorId === comment.createdBy && (
                                <Tooltip title="Edit comment" placement="top">
                                    <IconButton
                                        data-testid="edit-comment-button"
                                        onClick={() => handleEditClick(comment)}
                                        sx={{
                                            cursor: 'pointer',
                                            width: '40px',
                                            height: '40px',
                                            mt: 1,
                                        }}
                                    >
                                        <PencilIcon style={{ width: '40px' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {(authorId === comment.createdBy ||
                                isGlobalAdmin) && (
                                <Tooltip title="Delete comment" placement="top">
                                    <IconButton
                                        data-testid="delete-comment-button"
                                        onClick={() =>
                                            handleDeleteClick(comment)
                                        }
                                        sx={{
                                            cursor: 'pointer',
                                            width: '40px',
                                            height: '40px',
                                            mt: 1,
                                        }}
                                    >
                                        <DeleteIcon style={{ width: '25px' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </>
                ) : (
                    <>
                        <InputField
                            variant="outlined"
                            size="small"
                            placeholder="Edit your comment"
                            value={editableCommentMessage}
                            onChange={(e) =>
                                setEditableCommentMessage(e.target.value)
                            }
                            multiline
                            minRows={4}
                            maxRows={4}
                            inputRef={inputRef}
                            sx={{
                                background: theme.palette.background.paper,
                                borderRadius: '16px',
                                height: '100%',
                                width: '100%',
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Box
                                            sx={{
                                                height: '100px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <Tooltip
                                                title={'Cancel'}
                                                placement="top"
                                            >
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={() => {
                                                        setEditableCommentId(
                                                            null
                                                        );
                                                        setEditableCommentMessage(
                                                            ''
                                                        );
                                                    }}
                                                    size="small"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Box>
                                                <Tooltip title={'Add emoji'}>
                                                    <IconButton
                                                        color="default"
                                                        onClick={
                                                            handleShowEmojiPicker
                                                        }
                                                    >
                                                        <InsertEmoticonIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title={'Update comment'}
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        onClick={
                                                            handleUpdateComment
                                                        }
                                                    >
                                                        <SendIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    maxLength: 1000,
                                    style: { fontSize: '14px' },
                                },
                            }}
                        />
                        {showEmojiPicker && (
                            <Box
                                ref={pickerRef}
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: '10px',
                                    zIndex: 1500,
                                }}
                            >
                                <Picker onEmojiSelect={addEmoji} />
                            </Box>
                        )}
                    </>
                )}
            </Paper>
            <Snackbar
                open={isOpen}
                autoHideDuration={duration}
                onClose={closeSnackbar}
                sx={{ zIndex: 1600 }}
            >
                <Alert
                    variant="filled"
                    severity={severity}
                    sx={{ width: '100%' }}
                    onClose={closeSnackbar}
                >
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Comment;
