import React, { useEffect, useState } from 'react';
import {
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
    useTheme,
    IconButton,
    TextField,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ListWorksheetIcon from '../../../assets/images/process-thumbnails/Playbook-list-page.png';
import { getWorksheetThumbnail } from '../../../helpers/worksheets';
import { truncateText } from '../../../helpers/utils';
import { ExtendedWorksheet, SelectedWorksheet } from '../CreateCard/types';
import { v4 as uuidv4 } from 'uuid';

export interface NewPlaybookPage {
    id: string;
    sw: SelectedWorksheet;
    ew: ExtendedWorksheet;
    combinedName: string;
    pageTitle: string;
}

interface PageListColumnProps {
    selectedWorksheets: SelectedWorksheet[] | null;
    setSelectedWorksheets: React.Dispatch<
        React.SetStateAction<SelectedWorksheet[] | null>
    >;
    selectedWorksheetItems: ExtendedWorksheet[];
    setNewPlaybookPages: React.Dispatch<
        React.SetStateAction<NewPlaybookPage[]>
    >;
}

export const Avatar = ({ worksheet }: { worksheet: string }) => {
    const WorksheetIcon = getWorksheetThumbnail(worksheet) || ListWorksheetIcon;
    return (
        <Box
            component="img"
            src={WorksheetIcon}
            sx={{
                width: '30px',
                height: '30px',
            }}
        />
    );
};

const PageListColumn = ({
    selectedWorksheets,
    setSelectedWorksheets,
    selectedWorksheetItems,
    setNewPlaybookPages,
}: PageListColumnProps) => {
    const theme = useTheme();
    const [localPlaybookPages, setLocalPlaybookPages] = useState<
        NewPlaybookPage[]
    >([]);
    const [editingId, setEditingId] = useState<string | null>(null);
    const [editValue, setEditValue] = useState('');

    useEffect(() => {
        if (!selectedWorksheets) {
            setLocalPlaybookPages([]);
            return;
        }

        const result: NewPlaybookPage[] = [];
        selectedWorksheets.forEach((sw) => {
            selectedWorksheetItems.forEach((extended) => {
                if (extended?.chartTypeWorksheet?.i === sw.i) {
                    result.push({
                        id: uuidv4(),
                        sw,
                        ew: extended,
                        combinedName: `${extended.name} - ${sw.n}`,
                        pageTitle: sw.title ?? '',
                    });
                }
                extended.suggestedWorksheets?.forEach((suggested) => {
                    if (suggested.i === sw.i) {
                        result.push({
                            id: uuidv4(),
                            sw,
                            ew: extended,
                            combinedName: `${extended.name} - ${sw.n}`,
                            pageTitle: sw.title ?? '',
                        });
                    }
                });
            });
        });

        setLocalPlaybookPages(result);
    }, [selectedWorksheets, selectedWorksheetItems]);

    useEffect(() => {
        setNewPlaybookPages(localPlaybookPages);
    }, [localPlaybookPages, setNewPlaybookPages]);

    const handleEditClick = (itemId: string, currentTitle: string) => {
        setEditingId(itemId);
        setEditValue(currentTitle);
    };

    const handleSaveClick = () => {
        if (!editingId) return;
        setLocalPlaybookPages((prev) =>
            prev.map((page) =>
                page.id === editingId ? { ...page, pageTitle: editValue } : page
            )
        );
        setEditingId(null);
        setEditValue('');
    };

    return (
        <Box sx={{ height: '90%' }}>
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, fontSize: 16 }}
                >
                    Playbook pages
                </Typography>
            </Box>
            <Box sx={{ height: '92%', overflow: 'auto' }}>
                {localPlaybookPages.map((item) => {
                    const { id, sw, ew, combinedName, pageTitle } = item;
                    const isEditing = editingId === id;

                    return (
                        <ListItem
                            key={id}
                            alignItems="center"
                            sx={{
                                cursor: 'default',
                                bgcolor: theme.palette.primary.main,
                                p: 0.3,
                                display: 'flex',
                                alignItems: 'center',
                                mb: 0.5,
                            }}
                        >
                            <ListItemAvatar
                                sx={{
                                    margin: 0,
                                    padding: 0,
                                    minWidth: '30px',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mr: 0.5,
                                    }}
                                >
                                    <Avatar worksheet={sw.n} />
                                </Box>
                            </ListItemAvatar>

                            {isEditing ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <TextField
                                        variant="standard"
                                        value={editValue}
                                        onChange={(e) =>
                                            setEditValue(e.target.value)
                                        }
                                        autoFocus
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                fontSize: '14px',
                                                color: theme.palette.background
                                                    .default,
                                            },
                                        }}
                                        sx={{
                                            mr: 1,
                                            backgroundColor: 'transparent',
                                            width: 180,
                                        }}
                                    />
                                    <IconButton
                                        onClick={handleSaveClick}
                                        size="small"
                                        sx={{
                                            color: theme.palette.background
                                                .default,
                                        }}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </Box>
                            ) : (
                                <>
                                    <Tooltip
                                        title={combinedName}
                                        placement="top"
                                    >
                                        <ListItemText
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: theme.palette.background
                                                    .default,
                                                mr: 1,
                                            }}
                                            primaryTypographyProps={{
                                                fontSize: '14px',
                                            }}
                                            primary={truncateText(
                                                pageTitle,
                                                35
                                            )}
                                        />
                                    </Tooltip>
                                    <IconButton
                                        onClick={() =>
                                            handleEditClick(id, pageTitle)
                                        }
                                        size="small"
                                        sx={{
                                            color: theme.palette.background
                                                .default,
                                        }}
                                    >
                                        <EditOutlinedIcon
                                            sx={{ fontSize: 20 }}
                                        />
                                    </IconButton>
                                </>
                            )}
                        </ListItem>
                    );
                })}
            </Box>
        </Box>
    );
};

export default PageListColumn;
